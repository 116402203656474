import React, { useEffect, useState } from "react";
import HtmlEditor, { Toolbar, MediaResizing, ImageUpload, Item } from "devextreme-react/html-editor";
import { headersidebarfunction } from "../../pages/HeaderSideBar/headersidebarfunction";
import { useNavigate } from "react-router-dom";
import { LoadPanel } from "devextreme-react";
import "./help.css";

const tabs = [
  { name: "From This Device", value: ["file"] },
  { name: "From the Web", value: ["url"] },
  { name: "Both", value: ["file", "url"] },
];

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = [
  "Arial",
  "Courier New",
  "Georgia",
  "Impact",
  "Lucida Console",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];
const headerValues = [false, 1, 2, 3, 4, 5];
const fontSizeOptions = {
  inputAttr: {
    "aria-label": "Font size",
  },
};
const fontFamilyOptions = {
  inputAttr: {
    "aria-label": "Font family",
  },
};
const headerOptions = {
  inputAttr: {
    "aria-label": "Font family",
  },
};

export const Help = ({ helpPopUp, markup, markupid, markupsCaption, markupsScreenName, setHelpPopUp }) => {
  const clas = new headersidebarfunction();

  const [isMultiline, setIsMultiline] = useState(true);
  const [currentTab, setCurrentTab] = useState(tabs[2].value);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const [markup2, setmarkup] = useState(markup);

  const saveAs = () => {
    clas.editHelp(
      setloading,
      navigate,
      {
        GHelpId: markupid,
        GEntryUserId: localStorage.getItem("gUserId"),
        SScreenName: markupsScreenName,
        SComponentName: null,
        SCaption: markupsCaption,
        SSystemHelp: markup2,
        SCompanyHelp: null,
      },
      setHelpPopUp
    );
  };

  const printfunction = () => {
    var printWindow = window.open("", "", "width=600,height=600");

    // Write the HTML content to the new window
    printWindow.document.open();
    printWindow.document.write(markup2);
    printWindow.document.close();

    // Print the content
    printWindow.print();

    // Close the new window after printing
    printWindow.close();
  };

  return (
    <div className="widget-container">
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <HtmlEditor
        height="550px"
        defaultValue={markup2}
        valueType={"html"}
        onValueChanged={(e) => {
          setmarkup(e.value);
        }}
        readOnly={localStorage.getItem("user_designation") == "Administrator" ? false : true}
      >
        <MediaResizing enabled={true} />
        <ImageUpload tabs={currentTab} fileUploadMode="base64" />
        {localStorage.getItem("user_designation") == "Administrator" && (
          <Toolbar multiline={isMultiline}>
            <Item cssClass={"colortogeltheam"} name="undo" />
            <Item cssClass={"colortogeltheam"} name="redo" />
            <Item cssClass={"colortogeltheam"} name="separator" />
            <Item cssClass={"colortogeltheam"} name="size" acceptedValues={sizeValues} options={fontSizeOptions} />
            <Item cssClass={"colortogeltheam"} name="font" acceptedValues={fontValues} options={fontFamilyOptions} />

            <Item cssClass={"colortogeltheam"} name="italic" />
            <Item cssClass={"colortogeltheam"} name="strike" />
            <Item cssClass={"colortogeltheam"} name="underline" />
            <Item cssClass={"colortogeltheam"} name="bold" />
            <Item cssClass={"colortogeltheam"} name="alignLeft" />
            <Item cssClass={"colortogeltheam"} name="alignCenter" />
            <Item cssClass={"colortogeltheam"} name="alignRight" />
            <Item cssClass={"colortogeltheam"} name="alignJustify" />
            <Item cssClass={"colortogeltheam"} name="orderedList" />
            <Item cssClass={"colortogeltheam"} name="bulletList" />
            <Item cssClass={"colortogeltheam"} name="separator" />
            <Item cssClass={"colortogeltheam"} name="header" acceptedValues={headerValues} options={headerOptions} />
            <Item cssClass={"colortogeltheam"} name="color" />
            <Item cssClass={"colortogeltheam"} name="background" />
            <Item cssClass={"colortogeltheam"} name="link" />
            <Item cssClass={"colortogeltheam"} name="image" />
            <Item cssClass={"colortogeltheam"} name="clear" />
            <Item cssClass={"colortogeltheam"} name="codeBlock" />
            <Item cssClass={"colortogeltheam"} name="blockquote" />
            <Item cssClass={"colortogeltheam"} name="insertTable" />
            <Item cssClass={"colortogeltheam"} name="deleteTable" />
            <Item cssClass={"colortogeltheam"} name="insertRowAbove" />
            <Item cssClass={"colortogeltheam"} name="insertRowBelow" />
            <Item cssClass={"colortogeltheam"} name="deleteRow" />
            <Item cssClass={"colortogeltheam"} name="insertColumnLeft" />
            <Item cssClass={"colortogeltheam"} name="insertColumnRight" />
            <Item cssClass={"colortogeltheam"} name="deleteColumn" />
          </Toolbar>
        )}
      </HtmlEditor>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: "1%",
          paddingRight: "10px",
        }}
      >
        {localStorage.getItem("user_designation") == "Administrator" && (
          <button
            className="primary_btn"
            style={{ marginRight: "1%" }}
            onClick={() => {
              saveAs();
            }}
          >
            Save
          </button>
        )}
        <button
          className="btn"
          onClick={() => {
            setHelpPopUp(false);
          }}
        >
          Cancel
        </button>
        <button
          className="btn"
          style={{ marginLeft: "1%" }}
          onClick={() => {
            printfunction();
          }}
        >
          print
          <svg
            className="svg-blue-white"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clipPath="url(#clip0_5985_7014)">
              <path
                d="M19 8H5C3.34 8 2 9.34 2 11V15C2 16.1 2.9 17 4 17H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V17H20C21.1 17 22 16.1 22 15V11C22 9.34 20.66 8 19 8ZM15 19H9C8.45 19 8 18.55 8 18V14H16V18C16 18.55 15.55 19 15 19ZM19 12C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10C19.55 10 20 10.45 20 11C20 11.55 19.55 12 19 12ZM17 3H7C6.45 3 6 3.45 6 4V6C6 6.55 6.45 7 7 7H17C17.55 7 18 6.55 18 6V4C18 3.45 17.55 3 17 3Z"
                fill="#424167"
              />
            </g>
            <defs>
              <clipPath id="clip0_5985_7014">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    </div>
  );
};
