import { useEffect, useRef, useState } from "react";
import { detail_Payment_Tab_getData } from "../../../../reducer/ParticipateReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { handleGetLocal } from "../../../../services/auth-service";
import { DateBox, NumberBox, TextArea } from "devextreme-react";
import { ColumnDropdown } from "../../../../components/Dropdownn";

const DropDown = ({
  dropDownData,
  setfields,
  Detail_Payment_Tab_getData,
  setValue,
  reset,
  editable,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={detail_Payment_Tab_getData}
        fields={Detail_Payment_Tab_getData}
        fieldNmae={setValue}
        value={Detail_Payment_Tab_getData[setValue]?.name}
        // reset={reset}
        margin={"7px"}
        disabled={editable}
        redux={true}
      />
    </>
  );
};

export const Detail_Payment_Tab = ({ editable }) => {
  const { Detail_Payment_Tab_getData } = useSelector(
    (store) => store.participate
  );

  const dispatch = useDispatch();
  const pathname = handleGetLocal("pathURL");

  const [complianceLevel, setcomplianceLevel] = useState();

  const getalldropdown = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Person/masterDropDownValuesGet`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setcomplianceLevel(
          val.data.data.complianceLevel.map((vall) => {
            return { name: vall?.sName, id: vall?.gComplianceLevelId };
          })
        );
      });
  };

  useEffect(() => {
    getalldropdown();
  }, []);

  return (
    <>
      <div className="Detail_Payment_Tab">
        <div className="Detail_Payment_Tab_sub">
          <div className="input_block">
            <p>Payment Notes</p>
            <TextArea
              style={{ marginTop: "3%" }}
              height={140}
              disabled={!editable}
              value={Detail_Payment_Tab_getData?.PaymentNotes}
              onValueChange={(e) => {
                dispatch(
                  detail_Payment_Tab_getData({
                    ...Detail_Payment_Tab_getData,
                    PaymentNotes: e,
                    isChange: true,
                  })
                );
              }}
              placeholder={"Payment Notes"}
            />
          </div>
        </div>
        <div className="Detail_Payment_Tab_sub">
          <div className="Detail_Payment_Tab_sub_input_block_">
            <p>Frequency</p>
            <DropDown
              editable={!editable}
              Detail_Payment_Tab_getData={Detail_Payment_Tab_getData}
              setValue={"Frequency"}
              dropDownData={[
                { name: "Weekly", id: "" },
                { name: "Bi-weekly", id: "" },
                { name: "Monthly", id: "" },
                { name: "Other", id: "" },
              ]}
            />
          </div>
          <div className="Detail_Payment_Tab_sub_input_block_">
            <p>Amount</p>

            <NumberBox
              style={{ marginTop: "3%" }}
              onKeyDown={(e) => {
                const { event } = e;
                const str = event.key || String.fromCharCode(event.which);
                if (/^[.,e]$/.test(str)) {
                  event.preventDefault();
                }
              }}
              value={Detail_Payment_Tab_getData?.Amount}
              disabled={!editable}
              min={0}
              onValueChange={(e) => {
                dispatch(
                  detail_Payment_Tab_getData({
                    ...Detail_Payment_Tab_getData,
                    Amount: e,
                    isChange: true,
                  })
                );
              }}
              placeholder={`Amount`}
            />
          </div>
        </div>
        <div className="Detail_Payment_Tab_sub">
          <div className="Detail_Payment_Tab_sub_input_block_">
            <p>Next Payment Date (Override)</p>

            <DateBox
              value={Detail_Payment_Tab_getData?.NextPaymentDate}
              useMaskBehavior={true}
              onValueChange={(e) => {
                dispatch(
                  detail_Payment_Tab_getData({
                    ...Detail_Payment_Tab_getData,
                    NextPaymentDate: e
                      ? new Date(
                          new Date(e).toLocaleDateString()
                        ).toLocaleDateString("en-CA")
                      : null,
                    isChange: true,
                  })
                );
              }}
              style={{
                marginTop: "3.3%",
              }}
              disabled={!editable}
            />
          </div>
          <div className="Detail_Payment_Tab_sub_input_block_">
            <p>Compliance Level</p>

            <DropDown
              editable={!editable}
              Detail_Payment_Tab_getData={Detail_Payment_Tab_getData}
              setValue={"ComplianceLevel"}
              dropDownData={complianceLevel}
            />
          </div>
        </div>
        <div className="Detail_Payment_Tab_sub">
          <div className="input_block">
            <p>Action Plan</p>

            <TextArea
              style={{ marginTop: "3%" }}
              height={140}
              disabled={!editable}
              value={Detail_Payment_Tab_getData?.ActionPlan}
              onValueChange={(e) => {
                dispatch(
                  detail_Payment_Tab_getData({
                    ...Detail_Payment_Tab_getData,
                    ActionPlan: e,
                    isChange: true,
                  })
                );
              }}
              placeholder={"Action Plan"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
