import { DateBox, ScrollView, SelectBox } from "devextreme-react";
import { Button } from "devextreme-react/button";
import { useEffect, useState, useRef } from "react";
import "./editfilterPopup.css";
import cancelIcon from "../../assets/images/cancel.png";

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

const SetFilterValue = ({ val, operation, setshowDataArray, showDataArray, showOperationButton, index }) => {
  const [cloneoperationtogel, setcloneoperationtogel] = useState(false);
  const [cloneOperation, setcloneOperation] = useState({
    logo: val?.operationValue,
    name: val?.operationValueText,
  });
  const [value, setvalue] = useState(val?.value);

  const ref = useRef();
  useOutsideClick(ref, () => {
    setcloneoperationtogel(false);
  });

  useEffect(() => {
    setcloneOperation({
      logo: val?.operationValue,
      name: val?.operationValueText,
    });
    setvalue(val?.value);
  }, [val]);

  return (
    <div style={{ display: "flex" }}>
      <div className="showsetfilter">
        <label style={{ color: "var(--icons2)" }}>{val?.name}</label>
        {val?.operation && (
          <button
            ref={ref}
            onClick={() => {
              setcloneoperationtogel(!cloneoperationtogel);
            }}
          >
            <p style={{ marginTop: "-6px", fontSize: "20px" }}>{cloneOperation.logo}</p>
            {cloneoperationtogel && (
              <div
                style={{
                  left: "110px",
                  marginTop: "1%",
                  width: "160px",
                  height: "100px",
                  backgroundColor: "white",
                  position: "absolute",
                  zIndex: 10,
                }}
              >
                <ScrollView
                  style={{
                    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
                    borderRadius: 8,
                  }}
                >
                  <ul>
                    {operation.map((vall) => {
                      return (
                        <>
                          <li
                            onClick={() => {
                              setcloneOperation({
                                logo: vall.logo,
                                name: vall.name,
                              });
                              setshowDataArray(
                                showDataArray.map((item) => {
                                  if (item.name == val?.name) {
                                    return {
                                      ...item,
                                      operationValueText: showOperationButton ? vall.name : "",
                                      operationValue: showOperationButton ? vall.logo : "",
                                    };
                                  } else {
                                    return item;
                                  }
                                })
                              );
                            }}
                            style={{
                              marginTop: "7px",
                              cursor: "pointer",
                              textAlign: "left",
                              marginLeft: "2%",
                            }}
                          >
                            {vall.name}
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </ScrollView>
              </div>
            )}
          </button>
        )}
        {!val?.date ? (
          <input
            value={value}
            type={"text"}
            style={{
              width: "60%",
              border: "1px solid #bab8b8",
              borderRadius: "3px",
              height: "35px",
            }}
            onChange={(e) => {
              setvalue(e.target.value);
              setshowDataArray(
                showDataArray.map((item) => {
                  if (item.name == val?.name) {
                    return {
                      ...item,
                      value: e.target.value,
                      operationValueText: showOperationButton ? cloneOperation.name : "",
                      operationValue: showOperationButton ? cloneOperation.logo : "",
                    };
                  } else {
                    return item;
                  }
                })
              );
            }}
          />
        ) : (
          <DateBox
            style={{ width: "60%", height: "35px" }}
            useMaskBehavior={true}
            value={value}
            onValueChange={(e) => {
              if (e) {
                setvalue(new Date(new Date(e).toLocaleDateString()).toLocaleDateString("en-CA"));
                setshowDataArray(
                  showDataArray.map((item) => {
                    if (item.name == val?.name) {
                      return {
                        ...item,
                        value: new Date(new Date(e).toLocaleDateString()).toLocaleDateString("en-CA"),
                        operationValueText: showOperationButton ? cloneOperation.name : "",
                        operationValue: showOperationButton ? cloneOperation.logo : "",
                      };
                    } else {
                      return item;
                    }
                  })
                );
              }
            }}
          />
        )}
      </div>
      <img
        onClick={() => {
          setshowDataArray(showDataArray.filter((item) => item.name != val?.name));
        }}
        className="hoverable_icon"
        style={{ width: "15px", height: "15px", marginTop: "1.8%" }}
        src={cancelIcon}
      />
      {showDataArray.length > 1 && index != showDataArray.length - 1 ? (
        <p
          style={{
            fontSize: "10px",
            fontWeight: "700",
            marginTop: "1.4%",
            marginLeft: "2%",
          }}
        >
          AND
        </p>
      ) : (
        ""
      )}
    </div>
  );
};

export const AddFilterPopup = ({ setIsPopupVisible, Tablecoloum, setgetCustomFilterValue }) => {
  const operation = [
    {
      name: "Equals",
      logo: "=",
    },
    {
      name: "Not equals",
      logo: "≠",
    },
    {
      name: "Is greater than",
      logo: ">",
    },
    {
      name: "Is greater than equal",
      logo: "≥",
    },
    {
      name: "Is less than",
      logo: "<",
    },
    {
      name: "Is less than equal",
      logo: "≤",
    },
  ];

  const [operationtogel, setoperationtogel] = useState(false);
  const [Operation, setOperation] = useState(operation[0].name);
  const [fields, setfields] = useState({});
  const [showDataArray, setshowDataArray] = useState([]);
  const [showOperationButton, setshowOperationButton] = useState(Tablecoloum[2]?.operation);
  const [TogleFilterButton, setTogleFilterButton] = useState(false);
  const [FilterValue, setFilterValue] = useState(Tablecoloum[2]?.title);
  const [date, setData] = useState(FilterValue[0]?.date ? FilterValue[0]?.date : false);

  const ClickCencelButton = () => {
    setIsPopupVisible(false);
  };

  const OnClickCheckButton = () => {
    if (
      fields[FilterValue] &&
      fields[FilterValue]?.trim() &&
      !showDataArray.some((item) => item.name === FilterValue)
    ) {
      setshowDataArray([
        ...showDataArray,
        {
          value: fields[FilterValue]?.trim(),
          name: FilterValue,
          operation: showOperationButton,
          operationValueText: showOperationButton ? Operation : "",
          date,
          operationValue: showOperationButton ? operation.find((element) => element.name === Operation).logo : "",
        },
      ]);
      setfields({});
    }
  };

  const ref = useRef();
  useOutsideClick(ref, () => {
    setTogleFilterButton(false);
    setoperationtogel(false);
  });

  const ref2 = useRef();
  useOutsideClick(ref, () => {
    setoperationtogel(false);
  });

  return (
    <>
      <div className="editfilterPopup">
        <div className="admistrater_edit_popup_header">
          <div className="admistrater_edit_popup_header_sub">
            <svg
              width="15"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginTop: "17px", marginRight: "2%" }}
            >
              <path
                d="M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z"
                fill="white"
              />
            </svg>
            <p style={{ marginTop: "13px", color: "white" }}>Add Filter</p>
          </div>
          <svg
            onClick={() => {
              setIsPopupVisible(false);
            }}
            style={{
              cursor: "pointer",
              marginTop: "17px",
              marginRight: "2%",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="allshowfilter">
          <div
            style={{
              height: "159px",
              border: "1px solid #dbdbdb",
              width: "95%",
              overflowY: "scroll",
            }}
          >
            {showDataArray.map((val, index) => {
              return (
                <div key={index + 1}>
                  <SetFilterValue
                    showDataArray={showDataArray}
                    setshowDataArray={setshowDataArray}
                    val={val}
                    operation={operation}
                    showOperationButton={showOperationButton}
                    date={date}
                    index={index}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div ref={ref} style={{ display: "flex", marginTop: "2%" }}>
          <SelectBox
            dataSource={Tablecoloum?.filter((val) => {
              if (val?.show) {
                return val;
              }
            })}
            style={{ marginLeft: "6%" }}
            valueExpr="title"
            value={FilterValue}
            displayExpr="title"
            searchEnabled={true}
            searchExpr={"title"}
            dropDownOptions={{ height: "180px" }}
            onItemClick={(e) => {
              if (!showDataArray.some((item) => item.name === e?.itemData?.title)) {
                setFilterValue(e?.itemData?.title);
                setData(e?.itemData?.date);
                setshowOperationButton(e?.itemData?.operation);
              }
            }}
          />

          {showOperationButton && (
            <SelectBox
              dataSource={operation}
              valueExpr="name"
              value={Operation}
              style={{ marginLeft: "2%" }}
              displayExpr="name"
              searchEnabled={true}
              searchExpr={"name"}
              dropDownOptions={{ height: "180px" }}
              onItemClick={(e) => {
                setOperation(e?.itemData?.name);
              }}
            />
          )}
          <div style={{ marginLeft: "3%", display: "flex" }}>
            {!date ? (
              <input
                type={"text"}
                onChange={(e) => {
                  setfields({ ...fields, [FilterValue]: e.target.value });
                }}
                value={fields[FilterValue] ? fields[FilterValue] : ""}
                style={{
                  width: "60%",
                  border: "1px solid #bab8b8",
                  borderRadius: "3px",
                  height: "35px",
                }}
              />
            ) : (
              <DateBox
                style={{ width: "50%", height: "35px" }}
                useMaskBehavior={true}
                value={fields[FilterValue] ? fields[FilterValue] : ""}
                onValueChange={(e) => {
                  const initialDateString = e;
                  const initialDate = new Date(initialDateString);
                  const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                  const utcFormatted = new Date(utcTime).toISOString();

                  setfields({
                    ...fields,
                    [FilterValue]: e ? utcFormatted : null,
                  });
                }}
              />
            )}

            <Button
              style={{ marginLeft: "6px", marginTop: "-3px" }}
              icon="check"
              onClick={() => {
                OnClickCheckButton();
              }}
            />
            <Button
              style={{ marginLeft: "6px", marginTop: "-3px" }}
              icon="close"
              onClick={() => {
                setfields({});
              }}
            />
          </div>
        </div>
        <div className="popup_buttons" style={{ marginTop: "20px", marginRight: "20px" }}>
          <button
            onClick={() => {
              setgetCustomFilterValue(showDataArray);
              setIsPopupVisible(false);
              localStorage.setItem("personListFilter", JSON.stringify(showDataArray));
            }}
            className="btn primary_btn"
          >
            Apply
          </button>
          {showDataArray.length ? (
            <>
              <button
                onClick={() => {
                  setshowDataArray([]);
                  setFilterValue(Tablecoloum[2]?.title);
                  setshowOperationButton(Tablecoloum[2]?.operation);
                  setData(FilterValue[0]?.date ? FilterValue[0]?.date : false);
                  setgetCustomFilterValue([]);
                  setIsPopupVisible(false);
                  localStorage.setItem("personListFilter", JSON.stringify([]));
                }}
                className="btn"
              >
                Reset
              </button>
            </>
          ) : (
            ""
          )}
          <button
            onClick={() => {
              ClickCencelButton();
              setshowDataArray(
                localStorage.getItem("personListFilter") ? JSON.parse(localStorage.getItem("personListFilter")) : []
              );
            }}
            className="btn"
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};
