import { useEffect, useRef, useState } from "react";
import { DetailTab } from "./DetailTab/DetailTab";
import { Index } from "./Notes";
import { ReportDates } from "./ReportDates/ReportDates";
import { Financial } from "./Financial/Financial";
import { Attachment } from "./Attachment/Attachment";
import { OverviewTableExpand, setEmOverviewTableExpand } from "../../../reducer/AdminReadReducer";
import { useDispatch } from "react-redux";
import { getAuthToken, handleGetLocal } from "../../../services/auth-service";
import httpService from "../../../services/http-service";
import { Docket } from "./Dockect/Docket";
import axios from "axios";
import { logOut } from "../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { Task } from "./Task/Task";
import { setOverviewExpnadTableData, setOverviewTableData } from "./Financial/components/Data";
import { ParticipentChecklist } from "./Checklist/ParticipentChecklist";
import {
  detail_Notification_Tab_getData,
  detail_Payment_Tab_getData,
  detail_Tab_Name_getData,
  detail_Tab_address_getData,
  detail_co_obligor_Tab_getData,
  setcontectInfoTableCount,
  setsetChangesavedatapopup,
} from "../../../reducer/ParticipateReducer";
import { useSelector } from "react-redux";
import { DrugTestRandomizer } from "./DrugTestRandomizer/DrugTestRandomizer";
import { DocketManagement } from "./DocketManagement/DocketManagement";
import Em from "./EM/Em";
import { setEmExpnadTableData, setEmTableDatas } from "./EM/Data";

const getButtonProps = (tabIndex, commonTabSwitch, setcommonTabSwitch, TABS) => {
  const tab = TABS[tabIndex];
  const isCurrentTab = commonTabSwitch === tabIndex + 1;
  return {
    key: tabIndex,
    style: {
      backgroundColor: isCurrentTab ? "var(--button2)" : "var(--button-lightblue-lightblack)",
      color: isCurrentTab ? "white" : "var(--white-black)",
      cursor: "pointer",
      width: "auto",
      borderRadius: "5px 5px 0 0",
    },
    children: tab.label,
  };
};

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export const ParticipentSubTabs = ({
  editable,
  userinfo,
  editParticipant,
  reloadUserInfo,
  saveContact,
  setSaveContact,
  setGoodToSaveParticipant,
}) => {
  const [commonTabSwitch, setcommonTabSwitch] = useState(
    localStorage.getItem("atachmentTab") ? 9 : localStorage.getItem("emTab") ? 7 : 1
  );
  const [FinancialCount, setFinancialCount] = useState(0);
  const [data, setdata] = useState([]);
  const [errorhandling, seterrorhandling] = useState(false);
  const [docketmanagement, setdocketmanagement] = useState([]);
  const [notesDatacount, setnotesDatacount] = useState(0);
  const [emTableData, setEmTableData] = useState([]);
  const [data2, setdata2] = useState([]);
  const [drugTestRandomizer, setDrugTestRandomizer] = useState("false");
  const TABS = [
    { label: "Details" },
    { label: `Financial(${FinancialCount})` },
    { label: "Report Dates", width: "10%" },
    { label: `Notes (${notesDatacount})` },
    { label: `Dockets(${data.length})` },
    { label: `Docket Management(${docketmanagement.length})` },
    // { label: `EM (${emTableData.length})` },
    { label: `Attachments(${data2.length})` },
    { label: "Tasks" },
    { label: "Checklist" },
    { label: "Drug Test Randomizer" },
  ];
  const {
    dynamicParticipatTab,
    Detail_Notification_Tab_getData,
    Detail_Tab_Name_getData,
    Detail_Tab_address_getData,
    Detail_co_obligor_Tab_getData,
    Detail_Payment_Tab_getData,
    participantdata,
    headerCheckBoxChange,
    GoToDocketIsChange,
    DocketReload,
  } = useSelector((store) => store.participate);

  const [callApi, setCallApi] = useState(true);

  const dispatch = useDispatch();
  const [overviewData, setOverviewData] = useState([]);
  let domainName = (url) => url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^.]+)/i)[1];
  let domailURL = window.location.href;
  const pathname = handleGetLocal("pathURL");
  let pathURL = domainName(domailURL);
  const token = getAuthToken();
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [moreItemTogel, setmoreItemTogel] = useState(false);

  const setTableData = (dataa) => {
    const arr = [];
    dataa.map((val) => {
      arr.push({
        Filename: val?.filename,
        Category: val?.attachmentCateogoryName,
        Description: val?.description,
        ID: val?.recordId,
        "Entered By": val?.enteredByUserName,
        DOE: val?.doe,
        attachment: val?.attachment,
        attachmentCategoryId: val?.attachmentCategoryId,
        binaryDataId: val?.binaryDataId,
        image: val?.image,
      });
    });

    return arr;
  };

  const [loadingg2, setLoading2] = useState(false);

  const getTableData = async () => {
    setLoading2(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/attachments/get-attachments?recordId=${userinfo?.gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading2(false);
        setdata2(setTableData(val.data.data));
      })
      .catch((val) => {
        setLoading2(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    userinfo?.gPersonId && getTableData();
  }, [userinfo?.gPersonId]);

  const OverviewList = async () => {
    setLoad(true);
    await httpService
      .get(`api/Financial/getPaymentListByID?gPersonId=${userinfo?.gPersonId}&gObligationId=`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` },
      })
      .then((val) => {
        setLoad(false);
        setFinancialCount(val?.data.data.paymentGrid?.filter((payment) => payment?.bVoid === false).length);
        setOverviewData(setOverviewTableData(val?.data.data.paymentGrid));
        dispatch(
          OverviewTableExpand({
            tableExpandData: setOverviewExpnadTableData(val?.data.data.paymentSubGrid),
          })
        );
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };
  const getdocketData = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/get-dockets?personId=${userinfo?.gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          setdata(val.data.data);
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  const GoTonotesapi = async () => {
    if (!userinfo?.gPersonId) {
      return;
    }
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/activity/get?recordId=${
          userinfo?.gPersonId
        }&systemnotes=${true}&docketnotes=${false}&obligationnotes=${false}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setnotesDatacount(val?.data?.data?.length);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const getDockrtManagement = async () => {
    if (!userinfo?.gPersonId) {
      return;
    }
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/get-docket-management?gPersonId=${userinfo?.gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setdocketmanagement(val?.data?.data);
        }
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };
  const getEmDataApi = async () => {
    if (!userinfo?.gPersonId) {
      return;
    }
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/em/get-em-parent?gPersonId=${userinfo?.gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setEmTableData(setEmTableDatas(val?.data?.data?.parent));
          dispatch(
            setEmOverviewTableExpand({
              emTableExpandData: setEmExpnadTableData(val?.data?.data?.child),
            })
          );
        }
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    GoTonotesapi();
    getDrugTestRandomizerFlag();
  }, []);

  const ref = useRef();
  useOutsideClick(ref, async () => {
    setmoreItemTogel(false);
  });

  useEffect(() => {
    userinfo?.gPersonId && getdocketData();
    userinfo?.gPersonId && GoTonotesapi();
    userinfo?.gPersonId && getDockrtManagement();
    userinfo?.gPersonId && getEmDataApi();
  }, [userinfo?.gPersonId, DocketReload]);

  useEffect(() => {
    userinfo?.gPersonId && OverviewList();
  }, [callApi, userinfo?.gPersonId]);

  const updateDetailTabInfo = (userinfo) => {
    dispatch(
      detail_Tab_Name_getData({
        StatePreference:
          userinfo?.sOffenderNumberState !== null
            ? {
                name: userinfo?.sOffenderNumberState,
                id: userinfo?.sOffenderNumberState,
              }
            : { name: "", id: "" },
        LastName: userinfo?.sLastName !== null ? userinfo?.sLastName : "",
        Gender:
          userinfo?.sGenderName !== null
            ? { name: userinfo?.sGenderName, id: userinfo?.sGender }
            : { name: "", id: "" },
        EyeColor:
          userinfo?.sEyeColorName !== null
            ? { name: userinfo?.sEyeColorName, id: userinfo?.gEyeColorId }
            : { name: "", id: "" },
        AltID: userinfo?.sOffenderNumberPin !== null ? userinfo?.sOffenderNumberPin : "",
        SSN: userinfo?.sSsn !== null ? (userinfo?.sSsn ? String(userinfo?.sSsn)?.replace(/\*/g, "") : "") : "",
        Height: {
          feet: userinfo?.iHeightFoot !== null ? userinfo?.iHeightFoot : "0",
          inch: userinfo?.iHeightInch !== null ? userinfo?.iHeightInch : "0",
        },
        Language:
          userinfo?.sLanguageName !== null
            ? { name: userinfo?.sLanguageName, id: userinfo?.gLanguageId }
            : { name: "", id: "" },
        FirstName: userinfo?.sFirstName !== null ? userinfo?.sFirstName : "",
        DateOfBirth: userinfo?.dDob
          ? `${new Date(userinfo?.dDob)?.getFullYear()}-${(new Date(userinfo?.dDob)?.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${new Date(userinfo?.dDob).getDate().toString().padStart(2, "0")}`
          : "",
        Weight: userinfo?.iWeight !== null ? userinfo?.iWeight : "0",
        ComplianceLevel:
          userinfo?.sComplianceLevelName !== null
            ? {
                name: userinfo?.sComplianceLevelName,
                id: userinfo?.gComplianceLevelId,
              }
            : { name: "", id: "" },
        MiddleName: userinfo?.sMiddleName !== null ? userinfo?.sMiddleName : "",
        Race:
          userinfo?.sRaceName !== null ? { name: userinfo?.sRaceName, id: userinfo?.gRaceId } : { name: "", id: "" },
        HairColor:
          userinfo?.sHairName !== null
            ? { name: userinfo?.sHairName, id: userinfo?.gHairColorId }
            : { name: "", id: "" },
        EffectiveDate:
          userinfo?.dEffective !== "1900-01-01T00:00:00"
            ? userinfo?.dEffective
              ? `${new Date(userinfo?.dEffective)?.getFullYear()}-${(new Date(userinfo?.dEffective)?.getMonth() + 1)
                  .toString()
                  .padStart(2, "0")}-${new Date(userinfo?.dEffective).getDate().toString().padStart(2, "0")}`
              : new Date().toISOString().split("T")[0]
            : new Date().toISOString().split("T")[0],
        isChange: false,
      })
    );
    dispatch(
      detail_Tab_address_getData({
        MailingAddress: {
          AddressLine1: userinfo?.sMailingAddress1 !== null ? userinfo?.sMailingAddress1 : "",
          AddressLine2: userinfo?.sMailingAddress2 !== null ? userinfo?.sMailingAddress2 : "",
          City: userinfo?.sMailingCity !== null ? userinfo?.sMailingCity : "",
          State: userinfo?.sMailingState !== null ? userinfo?.sMailingState : "",
          ZIP: userinfo?.sMailingZip !== null ? userinfo?.sMailingZip : "",
          Country: userinfo?.gMailingCountyName !== null ? userinfo?.gMailingCountyName : "",
          Comment: userinfo?.sMailingAddressComment !== null ? userinfo?.sMailingAddressComment : "",
        },
        HomeAddress: {
          AddressLine1: userinfo?.sHomeAddress1 !== null ? userinfo?.sHomeAddress1 : "",
          AddressLine2: userinfo?.sHomeAddress2 !== null ? userinfo?.sHomeAddress2 : "",
          City: userinfo?.sHomeCity !== null ? userinfo?.sHomeCity : "",
          State: userinfo?.sHomeState !== null ? userinfo?.sHomeState : "",
          ZIP: userinfo?.sHomeZip !== null ? userinfo?.sHomeZip : "",
          Country: userinfo?.gHomeCountyName !== null ? userinfo?.gHomeCountyName : "",
          Comment: userinfo?.sHomeAddressComment !== null ? userinfo?.sHomeAddressComment : "",
        },
        WorkAddress: {
          Employer: userinfo?.sWorkEmployer !== null ? userinfo?.sWorkEmployer : "",
          AddressLine1: userinfo?.sWorkAddress1 !== null ? userinfo?.sWorkAddress1 : "",
          AddressLine2: userinfo?.sWorkAddress2 !== null ? userinfo?.sWorkAddress2 : "",
          City: userinfo?.sWorkCity !== null ? userinfo?.sWorkCity : "",
          State: userinfo?.sWorkState !== null ? userinfo?.sWorkState : "",
          ZIP: userinfo?.sWorkZip !== null ? userinfo?.sWorkZip : "",
          Country: userinfo?.gWorkCountyName !== null ? userinfo?.gWorkCountyName : "",
          Comment: userinfo?.sWorkAddressComment !== null ? userinfo?.sWorkAddressComment : "",
        },
        isChange: false,
      })
    );
    dispatch(
      detail_Payment_Tab_getData({
        PaymentNotes: userinfo?.sPaymentNote !== null ? userinfo?.sPaymentNote : "",
        Frequency:
          userinfo?.sPaymentFrequency !== null ? { name: userinfo?.sPaymentFrequency, id: "" } : { name: "", id: "" },
        Amount: userinfo?.sPaymentAmount !== null ? userinfo?.sPaymentAmount : "",
        NextPaymentDate: userinfo?.dNextPaymentOverride !== null ? userinfo?.dNextPaymentOverride?.split("T")[0] : "",
        ComplianceLevel:
          userinfo?.gComplianceLevelId !== null
            ? {
                name: userinfo?.sComplianceLevelName,
                id: userinfo?.gComplianceLevelId,
              }
            : { name: "", id: "" },
        ActionPlan: userinfo?.sActionPlan !== null ? userinfo?.sActionPlan : "",
        isChange: false,
      })
    );
    dispatch(
      detail_Notification_Tab_getData({
        AlertNote: userinfo?.sAlertNote !== null ? userinfo?.sAlertNote : "",
        email: "",
        phone: "",
        isChange: false,
      })
    );
    dispatch(
      detail_co_obligor_Tab_getData({
        FirstNmae: userinfo?.sGuarantorFirstName !== null ? userinfo?.sGuarantorFirstName : "",
        MiddleName: userinfo?.sGuarantorMiddleName !== null ? userinfo?.sGuarantorMiddleName : "",
        LastName: userinfo?.sGuarantorLastName !== null ? userinfo?.sGuarantorLastName : "",
        AddressLine1: userinfo?.sGuarantorAddress1 !== null ? userinfo?.sGuarantorAddress1 : "",
        AddressLine2: userinfo?.sGuarantorAddress2 !== null ? userinfo?.sGuarantorAddress2 : "",
        City: userinfo?.sGuarantorCity !== null ? userinfo?.sGuarantorCity : "",
        State: userinfo?.sGuarantorState !== null ? userinfo?.sGuarantorState : "",
        ZIP: userinfo?.sGuarantorZip !== null ? userinfo?.sGuarantorZip : "",
        SSN: userinfo?.sGuarantorSsn !== null ? userinfo?.sGuarantorSsn : "",

        DOB: userinfo?.dGuarantorDob
          ? `${new Date(userinfo?.dGuarantorDob).getFullYear()}-${(new Date(userinfo?.dGuarantorDob).getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${new Date(userinfo?.dGuarantorDob).getDate().toString().padStart(2, "0")}`
          : "",
        HomePhone:
          userinfo?.sGuarantorHomePhone !== null
            ? userinfo?.sGuarantorHomePhone
              ? String(userinfo?.sGuarantorHomePhone)
                  .replace(/[()\-]/g, "")
                  .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
              : null
            : "",
        WorkPhone:
          userinfo?.sGuarantorWorkPhone !== null
            ? userinfo?.sGuarantorWorkPhone
              ? String(userinfo?.sGuarantorWorkPhone)
                  .replace(/[()\-]/g, "")
                  .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
              : null
            : "",
        CellPhone:
          userinfo?.sGuarantorOtherPhone !== null
            ? userinfo?.sGuarantorOtherPhone
              ? String(userinfo?.sGuarantorOtherPhone)
                  .replace(/[()\-]/g, "")
                  .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
              : null
            : "",
        CardOnFile: userinfo?.bGuarantorCardOnFile !== null ? userinfo?.bGuarantorCardOnFile : false,

        isChange: false,
      })
    );

    userinfo?.gPersonId && getalldropdown(userinfo?.gPersonId);
  };

  const getalldropdown = async (gPersonId) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/User/personDetailContactInfo?gPersonId=${gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        seterrorhandling(false);
        dispatch(setcontectInfoTableCount(val.data.data.length));
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  const getDrugTestRandomizerFlag = () => {
    setLoad(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/Account/getCompanyOption?Name=Drug Test Randomizer Enabled`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoad(false);
        console.log(val?.data);
        setDrugTestRandomizer(val?.data?.data);
      })
      .catch((val) => {
        setLoad(false);
      });
  };

  useEffect(() => {
    if (editParticipant) {
      updateDetailTabInfo(userinfo);
    }
  }, [userinfo]);

  return (
    <>
      <div className="commonBlock">
        <div className="Buckner_block1_sub">
          <div style={{ display: "flex", position: "relative" }}>
            {TABS.filter((item) => {
              if(item.label === "Drug Test Randomizer" && (drugTestRandomizer.toLowerCase() === "false")) {
                return;
              }
              return item;
            }).map((_, i) => {
              if (i < 11) {
                return (
                  <button
                    onClick={() => {
                      if (
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange
                      ) {
                        if (i + 1 == 7) {
                          localStorage.setItem("emTab", true);
                        } else {
                          localStorage.removeItem("emTab");
                        }
                        setcommonTabSwitch(i + 1);
                      } else {
                        dispatch(setsetChangesavedatapopup(true));
                      }
                    }}
                    {...getButtonProps(i, commonTabSwitch, setcommonTabSwitch, TABS)}
                  />
                );
              }
            })}
          </div>
        </div>
        <div className="commonBlock_bottom_border"></div>
        {commonTabSwitch === 1 && (
          <DetailTab
            editParticipant={editParticipant}
            userinfo={userinfo}
            editable={editable}
            saveContact={saveContact}
            setSaveContact={setSaveContact}
            setGoodToSaveParticipant={setGoodToSaveParticipant}
          />
        )}
        {commonTabSwitch === 4 && (
          <Index
            editParticipant={editParticipant}
            userinfo={userinfo}
            showchecklist={true}
            setnotesDatacount={setnotesDatacount}
          />
        )}
        {commonTabSwitch === 3 && <ReportDates editParticipant={editParticipant} userinfo={userinfo} />}
        {commonTabSwitch === 2 && (
          <Financial
            load={load}
            setLoad={setLoad}
            editParticipant={editParticipant}
            userinfo={userinfo}
            setOverviewData={setOverviewData}
            overviewData={overviewData}
            setCallApi={setCallApi}
            callApi={callApi}
            OverviewList={OverviewList}
            reloadUserInfo={reloadUserInfo}
          />
        )}
        {commonTabSwitch === 7 && (
          <Attachment
            dataa={data2}
            loadinggg={loadingg2}
            editParticipant={editParticipant}
            userinfo={userinfo}
            attachmentfunction={getTableData}
          />
        )}
        {commonTabSwitch === 5 && (
          <Docket
            data={data}
            setLoading={setLoading}
            loading={loading}
            userinfo={userinfo}
            errorhandling={errorhandling}
          />
        )}
        {commonTabSwitch === 8 && <Task userinfo={userinfo} />}
        {commonTabSwitch === 9 && <ParticipentChecklist userinfo={userinfo} />}
        {commonTabSwitch === 10 && <DrugTestRandomizer userinfo={userinfo} />}
        {commonTabSwitch === 6 && (
          <DocketManagement
            docketmanagement={docketmanagement}
            userinfo={userinfo}
            getDockrtManagement={getDockrtManagement}
          />
        )}
        {commonTabSwitch === 7 && <Em userinfo={userinfo} emTableData={emTableData} getEmDataApi={getEmDataApi} />}
      </div>
    </>
  );
};
