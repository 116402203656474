import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import axios from "axios";
import errorIcon from "../../../assets/images/errorIcon.svg";
import { Toster } from "../../../components/Toster/Toster";

export default function ChangePsswd() {
  const navigate = useNavigate();
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState("");

  const [isDisable, setIsDisable] = useState(true);
  const [isEyeOpen, setIsEyeOpen] = useState({
    newPass: false,
    confirmNewPass: false,
  });

  const [FieldError, SetFieldError] = useState({
    password: "",
    rePassword: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);

  const [isPasswordCorrect, setIsPasswordCorrect] = useState({
    upper: false,
    lower: false,
    special: false,
    numeric: false,
    isValidPassword: false,
  });
  const [searchParams] = useSearchParams();
  const [checklink, setCheckLink] = useState(true);

  var passPattern = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%^&$*()_+\\-=[\\]{}|;':\",./<>?~`])(?=.{10,15})"
  );
  const upperCaseRegex = new RegExp("[A-Z]");
  const lowerCaseRegex = new RegExp("[a-z]");
  const specialCharRegex = new RegExp("[!@#%^&$*()_+\\-=[\\]{}|;':\",./<>?~`]");
  const numericRegex = new RegExp(`[0-9]`);

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const handleChange = (event) => {
    let field = fields;
    setErrors("");

    if (event.target.name == "newPass" && event.target.value) {
      setIsPasswordCorrect({
        upper: upperCaseRegex.test(event.target.value) ? true : false,
        lower: lowerCaseRegex.test(event.target.value) ? true : false,
        special: specialCharRegex.test(event.target.value) ? true : false,
        numeric: numericRegex.test(event.target.value) ? true : false,
        isValidPassword: passPattern.test(event.target.value) ? true : false,
      });

      if (!passPattern.test(event.target.value)) {
        SetFieldError({
          ...FieldError,
          password: "  ",
        });
      } else {
        SetFieldError({ ...FieldError, password: "" });
      }
    } else if (event.target.name == "confirmNewPass" && event.target.value) {
      if (field.newPass !== event.target.value) {
        SetFieldError({
          ...FieldError,
          rePassword: "Password doesn't match.",
        });
      } else {
        SetFieldError({ ...FieldError, rePassword: "" });
      }
    }
    field[event.target.name] = event.target.value;
    setFields({ ...field });
  };
  useEffect(() => {
    if (fields.confirmNewPass || fields.newPass) {
      if (
        fields.confirmNewPass === fields.newPass &&
        passPattern.test(fields.newPass)
      ) {
        SetFieldError({
          ...FieldError,
          rePassword: "",
          password: "",
        });
        setIsSubmit(true);
        setIsDisable(false);
      } else {
        if (fields.confirmNewPass !== fields.newPass && fields.confirmNewPass) {
          SetFieldError({
            ...FieldError,
            rePassword: "Password doesn't match.",
          });
        }
        setIsSubmit(false);
        setIsDisable(true);
      }
    }
  }, [fields.confirmNewPass, fields.newPass]);

  const smallTextStyle = {
    fontSize: "10px",
    lineHeight: "13px",
  };

  const validateLogin = () => {
    if (!fields["newPass"] || fields["newPass"].trim() == "") {
      SetFieldError({
        ...SetFieldError,
        password: "*Please enter new password",
      });
      setIsSubmit(false);
      setIsDisable(true);
    }
    if (typeof fields["newPass"] !== "undefined") {
      if (!passPattern.test(fields["newPass"])) {
        SetFieldError({
          ...SetFieldError,
          password: "*Please enter new password",
        });
        setIsSubmit(false);
        setIsDisable(true);
      }
    }
    if (!fields["confirmNewPass"] || fields["confirmNewPass"].trim() == "") {
      SetFieldError({
        ...SetFieldError,
        rePassword: "*Please enter confirm password",
      });
      setIsSubmit(false);
      setIsDisable(true);
    }
    if (fields["confirmNewPass"] !== fields["newPass"]) {
      SetFieldError({
        ...SetFieldError,
        rePassword: "*Please enter confirm password",
      });
      setIsSubmit(false);
      setIsDisable(true);
    }
  };

  const changePassData = async (e) => {
    let domainName = (url) =>
      url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
    let domailURL = window.location.href;
    let pathURL = domainName(domailURL);
    try {
      checklink &&
        (await axios
          .post(
            `${
              process.env.REACT_APP_API_URL
            }/api/Account/changePassword?username=${searchParams.get(
              "guserid"
            )}&password=${fields.newPass}`,
            null,
            {
              headers: {
                Domain: `${pathURL.split("change-password")[0]}`,
              },
            }
          )
          .then((response) => {
            if (response.data.isSuccess) {
              setToastConfig({
                ...toastConfig,
                isVisible: true,
                message: "Password changed successfully!",
                type: "success",
              });

              setTimeout(() => {
                setToastConfig({
                  ...toastConfig,
                  isVisible: false,
                  message: "Password changed successfully!",
                  type: "success",
                });
              }, 1400);

              navigate("/login");
            } else {
              setErrors(response.data.data.vcStatus);
            }
          }));
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateLogin();
    isSubmit && changePassData();
  };

  function togglePwd(key) {
    let eyeField = isEyeOpen;
    eyeField[key] = !eyeField[key];
    setIsEyeOpen({ ...eyeField });
  }

  const forgotPasswordLinkValidity = async () => {
    let domainName = (url) =>
      url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
    let domailURL = window.location.href;
    let pathURL = domainName(domailURL);
    searchParams.get("ticks") &&
      (await axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/api/Account/forgotPasswordLinkValidity?ticks=${searchParams.get(
            "ticks"
          )}`,
          {
            headers: {
              Domain: `${pathURL.split("change-password")[0]}`,
            },
          }
        )
        .then((val) => {
          if (!val.data) {
            setCheckLink(val.data);

            setToastConfig({
              ...toastConfig,
              isVisible: true,
              message: "Forgot password link has expired!",
              type: "error",
            });
            setTimeout(() => {
              setToastConfig({
                ...toastConfig,
                isVisible: false,
                message: "Forgot password link has expired!",
                type: "error",
              });
            }, 1400);
            setTimeout(() => {
              navigate("/login");
            }, 2000);
          } else {
            setCheckLink(val.data);
          }
        }));
  };

  useEffect(() => {
    if (!searchParams.get("guserid")) {
      navigate("/login");
    } else {
      forgotPasswordLinkValidity();
    }
  }, []);

  return (
    <>
      <div className="signPage">
        <Toster
          message={toastConfig.message}
          type={toastConfig.type}
          visible={toastConfig.isVisible}
        />
        <div
          style={{
            height: "100vh",
            backgroundImage:
              "linear-gradient(to bottom, #0077b7 0%, #004469 100%)",
          }}
        >
          <div style={{ backgroundColor: "white" }} className="signInner">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
            <h1>Change password</h1>
            <form>
              <div className={`fieldWrap`}>
                <label>
                  New password
                  {fields.newPass && FieldError.password ? (
                    <>
                      {
                        <img
                          className="errorIcon popup-label"
                          src={errorIcon}
                          alt=""
                          style={{ marginLeft: "2%", marginBottom: "-5px" }}
                        />
                      }
                      <div className="popup-content">
                        <span style={smallTextStyle}>
                          <p
                            style={{
                              fontSize: "10px",
                              lineHeight: "13px",
                              color: isPasswordCorrect.isValidPassword
                                ? "green"
                                : "red",
                            }}
                            className="small-text"
                          >
                            Password should be 10 digits alphanumeric with at
                            Least:
                          </p>
                          <li
                            style={{
                              color: isPasswordCorrect.upper ? "green" : "red",
                            }}
                            className="small-text"
                          >
                            1 Upper Case Character,
                          </li>
                          <li
                            style={{
                              color: isPasswordCorrect.lower ? "green" : "red",
                            }}
                            className="small-text"
                          >
                            1 Lower Case Character,
                          </li>
                          <li
                            style={{
                              color: isPasswordCorrect.numeric
                                ? "green"
                                : "red",
                            }}
                            className="small-text"
                          >
                            1 Numeric Character and
                          </li>
                          <li
                            style={{
                              color: isPasswordCorrect.special
                                ? "green"
                                : "red",
                            }}
                            className="small-text"
                          >
                            1 Special Character.
                          </li>
                        </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </label>
                <input
                  type={isEyeOpen.newPass ? "text" : "password"}
                  name="newPass"
                  placeholder="Enter your new password"
                  onChange={(e) => {
                    !e.target.value &&
                      SetFieldError({
                        ...FieldError,
                        password: "",
                      });
                    handleChange(e);
                  }}
                  value={fields?.newPass}
                  disabled={!checklink}
                  style={{ outline: FieldError.password && "1px solid red" }}
                />
                <span
                  onClick={() => togglePwd("newPass")}
                  className={`eyebtn ${!isEyeOpen.newPass ? " " : " eyeopen"}`}
                ></span>
              </div>
              <div className={`fieldWrap`}>
                <label>Confirm new password</label>
                <input
                  disabled={!checklink}
                  type={isEyeOpen.confirmNewPass ? "text" : "password"}
                  name="confirmNewPass"
                  placeholder="Re-enter your new password"
                  onChange={(e) => {
                    !e.target.value &&
                      SetFieldError({
                        ...FieldError,
                        rePassword: "",
                      });
                    handleChange(e);
                  }}
                  value={fields?.confirmNewPass}
                  style={{ outline: FieldError.rePassword && "1px solid red" }}
                />
                {(FieldError.rePassword || errors) && (
                  <p style={{ color: "red" }}>
                    {FieldError.rePassword ? FieldError.rePassword : errors}
                  </p>
                )}

                <span
                  onClick={() => togglePwd("confirmNewPass")}
                  className={`eyebtn ${
                    !isEyeOpen.confirmNewPass ? " " : " eyeopen"
                  }`}
                ></span>
              </div>
              <div className="fieldWrap">
                <button
                  className={`btn btn-default ${isDisable ? " disabled " : ""}`}
                  disabled={isDisable}
                  onClick={handleSubmit}
                >
                  Change password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
