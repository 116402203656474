

export const Tablecoloum = [
    {title:"", moreoption:true, customwidth:"50px"},
    { title: "Client Id" },
    { title: "Client Name" },
    { title: "Status"},
    { title: "Agency",},
    { title: "Billing", Boolean: true },
    { title: "Location" },
    { title: "Region" },
    { title: "0-30", currency:true},
    { title: "31-60",currency:true },
    { title: "61-90",currency:true },
    { title: "91-120",currency:true },
    { title: "121-150",currency:true },
    { title: "151-180",currency:true },
    { title: ">180",currency:true },
    { title: "Total Due",currency:true },
    { title: "Last Payment Date",date:true },
    { title: "Last Payment Days"},
  ];

  export const Increment = [
    { name: "10" },
    { name: "20" },
    { name: "30" },
    { name: "40" },
    { name: "50" },
    { name: "60" },
  ];
  