import { useEffect, useRef, useState } from "react";
import "./Subtab.css";
import { PaymentPlan } from "./GoToPaymentPlan/PaymentPlan";
import { GoToCommunityService } from "./GoToCommunityService/GoToCommunityService";
import { GoToWarrents } from "./GoToWarrents/GoToWarrents";
import { GoToDrugTest } from "./GoToDrugTest/GoToDrugTest";
import { GoToProgram } from "./GoToProgram/GoToProgram";
import { useNavigate } from "react-router-dom";
import { GoToDocketJailTime } from "./JailTime/GoToDocketJailTime";
import { GoToDocketSubTabFunction } from "./GoToDocketSubTabFunction";
import { GoToDocketTolling } from "./GoToDocketTolling/GoToDocketTolling";
import { GoToRevocation } from "./GoToRevocations/GoToRevocation";
import { GoTONote } from "./GoTONote/GoTONote";
import { GoToDocketSupervision } from "./GoToDocketSupervision/GoToDocketSupervision";
import { GOToDocketChecklist } from "./GOToDocketChecklist/GOToDocketChecklist";
import { GoToDocketObligation } from "./GoToDocketObligation/GoToDocketObligation";
import { GoToDocketDate } from "./GoToDocketDate/GoToDocketDate";
import { GoTODocketAttachment } from "./GoTODocketAttachment/GoTODocketAttachment";
import { GoToChildDocket } from "./GoToChildDocket/GoToChildDocket";
import axios from "axios";
import { handleGetLocal } from "../../../../../services/auth-service";
import GoToCasePlanNew from "./GoToCasePlan/GoToCasePlanNew";

const getButtonProps = (tabIndex, setselectedTab, selectedTab, tabList) => {
  const tab = tabList[tabIndex];
  const isCurrentTab = selectedTab === tabIndex + 1;
  return {
    key: tabIndex,
    style: {
      backgroundColor: isCurrentTab ? "var(--btn-blue-dark)" : "var(--button-lightblack-lightblue)",
      color: isCurrentTab ? "white" : "black",
    },
    children: tab.name,
  };
};

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [ref, callback]);
};

export const Subtab = ({
  dockerValue,
  toastConfig,
  setToastConfig,
  dockertDetail,
  dockerHeaderData,
  EditTogel,
  setdata2deopdown,
  data2deopdown,
  Docketid,
  DocketSupervisionDropdowndata,
  refreshsupervision,
}) => {
  const clas = new GoToDocketSubTabFunction();
  const [selectedTab, setselectedTab] = useState(1);
  const [countjailPlanned, setCountjailPlanned] = useState(0);
  const [countjailCompleted, setCountjailCompleted] = useState(0);
  const [communityCount, setCommunityCount] = useState(0);
  const [communityCompletedCount, setCommunityCompletedCount] = useState(0);
  const [countTolling, setCountTolling] = useState(0);
  const [countProgram, setCountProgram] = useState(0);
  const [countWarrant, setCountWarrant] = useState(0);
  const [drugTestCount, setDrugTestCount] = useState(0);
  const [Revocationserrorhandlig, setRevocationserrorhandlig] = useState(false);
  const [revocationsgData, setrevocationsgData] = useState([]);
  const [countrevocations, setCountrevocations] = useState();
  const [attachmentcount, setattachmentcount] = useState(0);
  const [checklistData, setchecklistData] = useState([]);
  const [notesDatacount, setnotesDatacount] = useState(0);
  const [obligationDatacount, setobligationDatacount] = useState(0);
  const [casePlanVisible, setCasePlanVisible] = useState(false);
  const [childDocketVisible, setChildDocketVisible] = useState(false);
  const [communityServiceVisible, setCommunityServiceVisible] = useState(false);
  const [drugTestVisible, setDrugTestVisible] = useState(false);
  const [jailTimeVisible, setJailTimeVisible] = useState(false);
  const [obligationVisible, setObligationVisible] = useState(false);
  const [paymentPlanVisible, setPaymentPlanVisible] = useState(false);
  const [programVisible, setProgramVisible] = useState(false);
  const [revocationVisible, setRevocationVisible] = useState(false);
  const [tollingVisible, setTollingVisible] = useState(false);
  const [warrantVisible, setWarrantVisible] = useState(false);

  const tabList = [
    { name: paymentPlanVisible ? "Payment Plan" : "", id: 0 },

    { name: obligationVisible ? `Obligations (${obligationDatacount})` : "", id: 1 },
    {
      name: communityServiceVisible ? `Community Services (${communityCount}/${communityCompletedCount})` : "",
      id: 2,
    },
    {
      name: jailTimeVisible ? `Jail Time (${countjailPlanned}/${countjailCompleted})` : "",
      id: 3,
    },
    { name: warrantVisible ? `Warrants (${countWarrant})` : "", id: 4 },
    { name: tollingVisible ? `Tolling (${countTolling})` : "", id: 5 },
    { name: drugTestVisible ? `Drug Test (${drugTestCount})` : "", id: 6 },
    { name: casePlanVisible ? "Case Plan" : "", id: 7 },
    { name: programVisible ? `Programs (${countProgram})` : "", id: 8 },
    { name: revocationVisible ? `Revocations(${countrevocations})` : "", id: 9 },
    { name: `Notes (${notesDatacount})`, id: 10 },
    { name: `Supervision`, id: 11 },
    { name: `Checklist`, id: 12 },
    { name: `Dates`, id: 13 },
    { name: `Attachment (${attachmentcount})`, id: 14 },
    { name: childDocketVisible ? `Child Docket (${checklistData.length})` : "", id: 15 },
  ];

  const [dropdownData, setDropdownData] = useState([]);
  const [docketDropdownData, setDocketDropdownData] = useState([]);
  const [tollingData, setTollingData] = useState([]);
  const navigate = useNavigate();
  const [jailPlannedData, setJailPlannedData] = useState([]);
  const [jailCompletedData, setJailCompletedData] = useState([]);
  const [JailtimelogDropdown, setJailtimelogDropdown] = useState([]);
  const [moreItemTogel, setmoreItemTogel] = useState(false);
  const [communityServiceJailtimelogDropdown, setCommunityServiceJailtimelogDropdown] = useState([]);
  const ref = useRef();
  useOutsideClick(ref, async () => {
    setmoreItemTogel(false);
  });

  const [communityPlannedServicesData, setCommunityPlannedServicesData] = useState([]);
  const [communityCompletedServiceData, setCommunityCompletedServiceData] = useState([]);
  const [jailPlannedErrorHandling, setJailPlannedErrorHandling] = useState(false);
  const [jailCompletedErorrHandling, setjailCompletedErrorHandling] = useState(false);
  const [TollingErorrHandling, setTollingErorrHandlingErrorHandling] = useState(false);
  const [communityPlannedErorrHandling, setCommunityPlannedErrorHandling] = useState(false);
  const [communityCompletedErorrHandling, setCommunityCompletedErrorHandling] = useState(false);
  const [Tollingreset, setTollingreset] = useState(false);
  const [warrantData, setWarrantData] = useState([]);
  const [warrentErrorHandling, setWarrentErrorHandling] = useState(false);
  const [programTableData, setProgramTableData] = useState([]);
  const [programErorrHandling, setProgramErrorHandling] = useState(false);
  const [drugTestData, setDrugTestData] = useState([]);
  const [drugTestErrorHandling, setDrugTestErrorHandling] = useState(false);

  const [attachmentTabledata, setattachmentTabledata] = useState([]);

  const [loadingg, setLoading] = useState(false);
  const [callObligationApi, setCallObligationApi] = useState(true);
  const [errorhandling, seterrorhandling] = useState(false);
  const [obligations, setobligations] = useState();
  const [loading, SetLoading] = useState(false);

  const pathname = handleGetLocal("pathURL");

  const getDocketTabVisibility = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/get-docket-tab-visibility`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((res) => {
        const {
          casePlan: casePlan,
          childDocket: childDocket,
          communityService: communityService,
          drugTest: drugTest,
          jailTime: jailTime,
          obligation: obligation,
          paymentPlan: paymentPlan,
          program: program,
          revocation: revocation,
          tolling: tolling,
          warrant: warrant,
        } = res.data.data;

        setCasePlanVisible(!casePlan);
        setChildDocketVisible(!childDocket);
        setCommunityServiceVisible(!communityService);
        setDrugTestVisible(!drugTest);
        setJailTimeVisible(!jailTime);
        setObligationVisible(!obligation);
        setPaymentPlanVisible(!paymentPlan);
        setProgramVisible(!program);
        setRevocationVisible(!revocation);
        setTollingVisible(!tolling);
        setWarrantVisible(!warrant);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDocketTabVisibility();
  }, []);

  useEffect(() => {
    if (dockerValue?.userinfo?.gPersonId) {
      clas.getDocketDropdownData(setDocketDropdownData, navigate, dockerValue);
      clas.getDropdownDatas(setDropdownData, navigate);
      clas.getJailCopletedServiceListApi(
        dockerValue,
        setJailCompletedData,
        setjailCompletedErrorHandling,
        navigate,
        setCountjailCompleted
      );
      clas.getJailPlannedServiceListApi(
        navigate,
        setCountjailPlanned,
        setJailPlannedData,
        setJailPlannedErrorHandling,
        dockerValue
      );
      clas.getJailtimelogDropdown(setJailtimelogDropdown, dockerValue, navigate);
      clas.getCommunityPlannedServiceListApi(
        setCommunityPlannedServicesData,
        setCommunityPlannedErrorHandling,
        setCommunityCount,
        navigate,
        dockerValue
      );
      clas.getCommunityCompletedServiceListApi(
        setCommunityCompletedServiceData,
        setCommunityCompletedErrorHandling,
        navigate,
        dockerValue,
        setCommunityCompletedCount
      );
      clas.getTollingDataApi(
        setCountTolling,
        setTollingData,
        navigate,
        dockerValue,
        setTollingErorrHandlingErrorHandling
      );
      clas.getRevocationsDataApi(
        setRevocationserrorhandlig,
        setCountrevocations,
        setrevocationsgData,
        navigate,
        dockerValue
      );
      clas.getWarrantsDataApi(dockerValue, navigate, setCountWarrant, setWarrantData, setWarrentErrorHandling);
      clas.getProgramListApi(dockerValue, navigate, setCountProgram, setProgramTableData, setProgramErrorHandling);
      clas.getrugTestDataApi(dockerValue, navigate, setDrugTestCount, setDrugTestData, setDrugTestErrorHandling);
      clas.getattachmentTableData(setattachmentTabledata, setLoading, navigate, dockerValue, setattachmentcount);

      clas.GoToChildDocketGetGride(setLoading, dockerValue, setchecklistData, navigate);
      clas.GoTonotesapi(dockerValue, navigate, setnotesDatacount);
    }
  }, [dockerValue?.getTopDynamicDocketid]);

  const getattachmentTableData = () => {
    dockerValue?.getTopDynamicDocketid &&
      clas.getattachmentTableData(setattachmentTabledata, setLoading, navigate, dockerValue, setattachmentcount);
  };

  useEffect(() => {
    clas.getbottomtabData(
      dockerValue,
      obligations,
      setobligations,
      seterrorhandling,
      SetLoading,
      navigate,
      setobligationDatacount
    );
  }, [callObligationApi, dockerValue?.getTopDynamicDocketid]);

  const getRevocationsDataApi = () => {
    clas.getRevocationsDataApi(
      setRevocationserrorhandlig,
      setCountrevocations,
      setrevocationsgData,
      navigate,
      dockerValue
    );
  };
  const getJailCopletedServiceListApi = () => {
    clas.getJailCopletedServiceListApi(
      dockerValue,
      setJailCompletedData,
      setjailCompletedErrorHandling,
      navigate,
      setCountjailCompleted
    );
  };
  const getJailPlannedServiceListApi = () => {
    clas.getJailPlannedServiceListApi(
      navigate,
      setCountjailPlanned,
      setJailPlannedData,
      setJailPlannedErrorHandling,
      dockerValue
    );
  };
  const getJailtimelogDropdown = () => {
    clas.getJailtimelogDropdown(setJailtimelogDropdown, dockerValue, navigate);
  };
  const getCommunityPlannedServiceListApi = () => {
    clas.getCommunityPlannedServiceListApi(
      setCommunityPlannedServicesData,
      setCommunityPlannedErrorHandling,
      setCommunityCount,
      navigate,
      dockerValue
    );
  };
  const getCommunityCompletedServiceListApi = () => {
    clas.getCommunityCompletedServiceListApi(
      setCommunityCompletedServiceData,
      setCommunityCompletedErrorHandling,
      navigate,
      dockerValue,
      setCommunityCompletedCount
    );
  };
  const getTollingDataApi = () => {
    clas.getTollingDataApi(
      setCountTolling,
      setTollingData,
      navigate,
      dockerValue,
      setTollingErorrHandlingErrorHandling
    );
  };
  const getCommunityServiceJailtimelogDropdown = () => {
    clas.getCommunityServiceJailtimelogDropdown(setCommunityServiceJailtimelogDropdown, dockerValue, navigate);
  };
  const getWarrantsDataApi = () => {
    clas.getWarrantsDataApi(dockerValue, navigate, setCountWarrant, setWarrantData, setWarrentErrorHandling);
  };
  const getProgramListApi = () => {
    clas.getProgramListApi(dockerValue, navigate, setCountProgram, setProgramTableData, setProgramErrorHandling);
  };
  const getrugTestDataApi = () => {
    clas.getrugTestDataApi(dockerValue, navigate, setDrugTestCount, setDrugTestData, setDrugTestErrorHandling);
  };

  useEffect(() => {
    dockerValue?.getTopDynamicDocketid && getTollingDataApi();
  }, [dockerValue?.getTopDynamicDocketid, Tollingreset]);

  return (
    <>
      <div className="commonBlock">
        <div className="Buckner_block1_sub">
          <div className="tab-buttons-conatiner">
            {tabList
              .filter((tab) => tab.name !== "")
              .map((tab, i) => {
                if (i <= 8) {
                  return (
                    <button
                      className="tab-button"
                      onClick={() => {
                        setselectedTab(tab.id + 1);
                      }}
                      {...getButtonProps(tab.id, setselectedTab, selectedTab, tabList)}
                    />
                  );
                }
              })}
            {tabList.filter((tab) => tab.name !== "").length > 8 && (
              <button
                ref={ref}
                style={{
                  backgroundColor: "var(--color2)",
                  color: "var(--white-black)",
                  border: "1px solid #D2D2D2",
                  width: "130px",
                  position: "relative",
                }}
                onClick={() => {
                  setmoreItemTogel(!moreItemTogel);
                }}
              >
                More items
                <svg
                  className="svg-blue-white"
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.11875 1.29006L5.99875 5.17006L9.87875 1.29006C10.2688 0.900059 10.8988 0.900059 11.2888 1.29006C11.6788 1.68006 11.6788 2.31006 11.2888 2.70006L6.69875 7.29006C6.30875 7.68006 5.67875 7.68006 5.28875 7.29006L0.69875 2.70006C0.30875 2.31006 0.30875 1.68006 0.69875 1.29006C1.08875 0.910059 1.72875 0.900059 2.11875 1.29006Z"
                    fill="black"
                    fillOpacity="0.6"
                  />
                </svg>
                {moreItemTogel && (
                  <div ref={ref} className="moreItem" style={{ height: "300px" }}>
                    {tabList
                      .filter((tab) => tab.name !== "")
                      .map((tab, i) => {
                        if (i > 8) {
                          return (
                            <p
                              onClick={() => {
                                setselectedTab(tab.id + 1);
                              }}
                              style={{
                                backgroundColor:
                                  selectedTab === tab.id + 1
                                    ? "var(--btn-blue-dark)"
                                    : "var(--button-lightblack-lightblue)",
                                color: selectedTab === tab.id + 1 ? "white" : "black",
                              }}
                            >
                              {tab.name}
                            </p>
                          );
                        }
                      })}
                  </div>
                )}
              </button>
            )}
          </div>
        </div>
        <div className="commonBlock_bottom_border"></div>
        <div className="goto-subtabs ">
          {selectedTab === 1 && <PaymentPlan docketValue={dockerValue} />}
          {selectedTab === 4 && (
            <GoToDocketJailTime
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              userinfo={dockerValue?.userinfo}
              dropdownData={dropdownData}
              docketDropdownData={docketDropdownData}
              jailPlannedData={jailPlannedData}
              jailCompletedData={jailCompletedData}
              getJailCopletedServiceListApi={getJailCopletedServiceListApi}
              getJailPlannedServiceListApi={getJailPlannedServiceListApi}
              JailtimelogDropdown={JailtimelogDropdown}
              getJailtimelogDropdown={getJailtimelogDropdown}
              jailPlannedErrorHandling={jailPlannedErrorHandling}
              jailCompletedErorrHandling={jailCompletedErorrHandling}
              dockertDetail={dockertDetail}
              dockerHeaderData={dockerHeaderData}
            />
          )}

          {selectedTab === 6 && (
            <GoToDocketTolling
              userinfo={dockerValue?.userinfo}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              dropdownData={dropdownData}
              docketDropdownData={docketDropdownData}
              getTollingDataApi={getTollingDataApi}
              tollingData={tollingData}
              Tollingreset={Tollingreset}
              setTollingreset={setTollingreset}
              TollingErorrHandling={TollingErorrHandling}
              dockertDetail={dockertDetail}
            />
          )}

          {selectedTab === 3 && (
            <GoToCommunityService
              userinfo={dockerValue?.userinfo}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              dropdownData={dropdownData}
              docketDropdownData={docketDropdownData}
              communityPlannedServicesData={communityPlannedServicesData}
              communityCompletedServiceData={communityCompletedServiceData}
              getCommunityCompletedServiceListApi={getCommunityCompletedServiceListApi}
              getCommunityPlannedServiceListApi={getCommunityPlannedServiceListApi}
              communityServiceJailtimelogDropdown={communityServiceJailtimelogDropdown}
              getCommunityServiceJailtimelogDropdown={getCommunityServiceJailtimelogDropdown}
              communityCompletedErorrHandling={communityCompletedErorrHandling}
              communityPlannedErorrHandling={communityPlannedErorrHandling}
              dockertDetail={dockertDetail}
            />
          )}
          {selectedTab === 5 && (
            <GoToWarrents
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              userinfo={dockerValue?.userinfo}
              dropdownData={dropdownData}
              docketDropdownData={docketDropdownData}
              warrantData={warrantData}
              getWarrantsDataApi={getWarrantsDataApi}
              warrentErrorHandling={warrentErrorHandling}
              dockertDetail={dockertDetail}
              dockerHeaderData={dockerHeaderData}
            />
          )}
          {selectedTab === 7 && (
            <GoToDrugTest
              userinfo={dockerValue?.userinfo}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              dropdownData={dropdownData}
              docketDropdownData={docketDropdownData}
              drugTestData={drugTestData}
              drugTestErrorHandling={drugTestErrorHandling}
              getrugTestDataApi={getrugTestDataApi}
              dockertDetail={dockertDetail}
            />
          )}
          {selectedTab === 9 && (
            <GoToProgram
              userinfo={dockerValue?.userinfo}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              dropdownData={dropdownData}
              docketDropdownData={docketDropdownData}
              programTableData={programTableData}
              getProgramListApi={getProgramListApi}
              programErorrHandling={programErorrHandling}
              dockertDetail={dockertDetail}
              dockerHeaderData={dockerHeaderData}
            />
          )}
          {selectedTab === 10 && (
            <GoToRevocation
              Revocationserrorhandlig={Revocationserrorhandlig}
              docketDropdownData={docketDropdownData}
              revocationsgData={revocationsgData}
              dropdownData={dropdownData}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              getRevocationsDataApi={getRevocationsDataApi}
              userinfo={dockerValue?.userinfo}
              dockertDetail={dockertDetail}
            />
          )}
          {selectedTab === 11 && (
            <GoTONote dockertDetail={dockertDetail} dockerValue={dockerValue} userinfo={dockerValue?.userinfo} />
          )}
          {selectedTab === 12 && (
            <GoToDocketSupervision
              userinfo={dockerValue?.userinfo}
              dockertDetail={dockerValue}
              EditTogel={EditTogel}
              DocketSupervisionDropdowndata={DocketSupervisionDropdowndata}
              setdata2deopdown={setdata2deopdown}
              data2deopdown={data2deopdown}
              Docketid={Docketid}
              refreshsupervision={refreshsupervision}
            />
          )}
          {selectedTab === 13 && <GOToDocketChecklist dockerValue={dockerValue} />}
          {selectedTab === 2 && (
            <GoToDocketObligation
              dockerValue={dockerValue}
              dockerHeaderData={dockerHeaderData}
              callObligationApi={callObligationApi}
              setCallObligationApi={setCallObligationApi}
              errorhandling={errorhandling}
              seterrorhandling={seterrorhandling}
              obligations={obligations}
              setobligations={setobligations}
              loading={loading}
              userinfo={dockerValue?.userinfo}
            />
          )}
          {selectedTab === 14 && <GoToDocketDate dockerValue={dockerValue} dockerHeaderData={dockerHeaderData} />}
          {selectedTab === 15 && (
            <GoTODocketAttachment
              dataa={attachmentTabledata}
              loadinggg={loadingg}
              userinfo={{ gPersonId: dockerValue?.getTopDynamicDocketid }}
              attachmentfunction={getattachmentTableData}
            />
          )}

          {selectedTab === 16 && <GoToChildDocket loadingg={loadingg} checklistData={checklistData} />}

          {selectedTab === 8 && <GoToCasePlanNew Docketid={Docketid} />}
        </div>
      </div>
    </>
  );
};
