import { useEffect, useState } from "react";
import { ReportFunction } from "../REportFunction";
import { Supervision } from "./Supervision";
import { useNavigate } from "react-router-dom";
import { ApiError } from "../../Error/ApiError";
import { LoadPanel, Popup } from "devextreme-react";
import { headersidebarfunction } from "../../HeaderSideBar/headersidebarfunction";
import HelpPopup from "../../HeaderSideBar/HelpPopup";
import question from "./../../../assets/images/question.png";

export const ReportDashboard = () => {
  const clas = new ReportFunction();
  const navigate = useNavigate();
  const [errorhandler, seterrorhandler] = useState();
  const [loading, setLoading] = useState();
  const [data, setdata] = useState([]);
  useEffect(() => {
    clas.getReportDashboardData(navigate, setLoading, seterrorhandler, setdata);
  }, []);

  const clas2 = new headersidebarfunction();
  const [markupid, setmarkupid] = useState();
  const [markup, setmarkup] = useState("");
  const [markupsCaption, setmarkupisCaption] = useState("");
  const [markupsScreenName, setmarkupissScreenNamu] = useState("");
  const [helpPopUp, setHelpPopUp] = useState(false);

  useEffect(() => {
    clas2.getHelp(
      setLoading,
      navigate,
      setmarkup,
      setmarkupid,
      "MainReports",
      setmarkupissScreenNamu,
      setmarkupisCaption
    );
  }, []);

  return (
    <>
      {errorhandler ? (
        <ApiError />
      ) : (
        <div>
          {markupid && (
            <Popup
              visible={markupid && helpPopUp}
              onHiding={() => {
                setHelpPopUp(false);
              }}
              showTitle={false}
              width={1200}
              height={670}
              showCloseButton={true}
              hideOnOutsideClick={true}
            >
              <HelpPopup
                markup={markup}
                markupid={markupid}
                helpPopUp={helpPopUp}
                setHelpPopUp={setHelpPopUp}
                markupsCaption={markupsCaption}
                markupsScreenName={markupsScreenName}
              />
            </Popup>
          )}
          <LoadPanel shadingColor="true" visible={loading} delay={10} />
          <div
            style={{
              display: "flex",
              padding: "10px",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <p className="page-info-number">RPT.1</p>
            {markupid && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28px"
                height="28px"
                viewBox="0 0 24 24"
                fill="none"
                style={{ cursor: "pointer" }}
                className="svg-blue-black"
                onClick={() => {
                  setHelpPopUp(true);
                }}
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 7.75C11.3787 7.75 10.875 8.25368 10.875 8.875C10.875 9.28921 10.5392 9.625 10.125 9.625C9.71079 9.625 9.375 9.28921 9.375 8.875C9.375 7.42525 10.5503 6.25 12 6.25C13.4497 6.25 14.625 7.42525 14.625 8.875C14.625 9.58584 14.3415 10.232 13.883 10.704C13.7907 10.7989 13.7027 10.8869 13.6187 10.9708C13.4029 11.1864 13.2138 11.3753 13.0479 11.5885C12.8289 11.8699 12.75 12.0768 12.75 12.25V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V12.25C11.25 11.5948 11.555 11.0644 11.8642 10.6672C12.0929 10.3733 12.3804 10.0863 12.6138 9.85346C12.6842 9.78321 12.7496 9.71789 12.807 9.65877C13.0046 9.45543 13.125 9.18004 13.125 8.875C13.125 8.25368 12.6213 7.75 12 7.75ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                  fill="white"
                />
              </svg>
            )}
          </div>
          <div style={{ marginTop: "1%" }} className="ChecklistTabbuttons">
            <button
              className="btn"
              onClick={() => {
                clas.getReportDashboardData(navigate, setLoading, seterrorhandler, setdata);
              }}
            >
              Refresh
              <svg
                className="svg-blue-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_5796_5486)">
                  <path
                    d="M17.6493 6.35097C16.0193 4.72097 13.7093 3.78097 11.1693 4.04097C7.49929 4.41097 4.47929 7.39097 4.06929 11.061C3.51929 15.911 7.26929 20.001 11.9993 20.001C15.1893 20.001 17.9293 18.131 19.2093 15.441C19.5293 14.771 19.0493 14.001 18.3093 14.001C17.9393 14.001 17.5893 14.201 17.4293 14.531C16.2993 16.961 13.5893 18.501 10.6293 17.841C8.40929 17.351 6.61929 15.541 6.14929 13.321C5.30929 9.44097 8.25929 6.00097 11.9993 6.00097C13.6593 6.00097 15.1393 6.69097 16.2193 7.78097L14.7093 9.29097C14.0793 9.92097 14.5193 11.001 15.4093 11.001H18.9993C19.5493 11.001 19.9993 10.551 19.9993 10.001V6.41097C19.9993 5.52097 18.9193 5.07097 18.2893 5.70097L17.6493 6.35097Z"
                    fill="#424167"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5796_5486">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
          {data?.map((val) => {
            return (
              <>
                <div style={{ margin: "1%" }}>
                  <Supervision name={val?.sName} Image={val?.Image} SupervisionData={val?.array} />
                </div>
                <div style={{ border: "1px solid #7a7a78" }}></div>
              </>
            );
          })}
        </div>
      )}
    </>
  );
};
