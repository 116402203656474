import React, { useEffect, useState, useRef } from "react";
import { LoadPanel, ScrollView } from "devextreme-react";
import { handleGetLocal } from "../../../../../../services/auth-service";
import axios from "axios";
import "../financialPop.css";
import { logOut } from "../../../../../../components/Logout";
import { useNavigate } from "react-router-dom";

export const ChangeCashDrawerPopup = ({
  setCashDrawerPopup,
  gPaymentId,
  setCallApi,
  callApi,
  listData,
  rowInfoData,
  toastConfig,
  setToastConfig,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [error, setError] = useState("");
  const componentRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setSelectedValue("");
        setError("");
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    setError("");
  };

  useEffect(() => {}, [selectedValue]);

  const navigate = useNavigate();

  const handleSave = async () => {
    if (selectedValue === "") {
      setError("Please select a cash drawer");
      return;
    }
    setError("");
    setLoading(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/financial/change-cash-drawer`,
        { PaymentId: gPaymentId, CashDrawerId: selectedValue },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        setSelectedValue("");
        setCashDrawerPopup(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: val.data.responseMessage,
          type: val.data.responseIcon?.toLowerCase(),
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: val.data.responseMessage,
            type: val.data.responseIcon?.toLowerCase(),
          });
        }, 1900);
        setCallApi(!callApi);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {

        navigate(`/error/500`);

      }else if (val?.response?.status == 401) {

        logOut();

      }
      });
  };

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div className="void_payment void_change_payment" ref={componentRef}>
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11.41 16.09V16.67C11.41 17.4 10.81 18 10.08 18H10.07C9.34 18 8.74 17.4 8.74 16.67V16.07C7.41 15.79 6.23 15.06 5.73 13.83C5.5 13.28 5.93 12.67 6.53 12.67H6.77C7.14 12.67 7.44 12.92 7.58 13.27C7.87 14.02 8.63 14.54 10.09 14.54C12.05 14.54 12.49 13.56 12.49 12.95C12.49 12.12 12.05 11.34 9.82 10.81C7.34 10.21 5.64 9.19 5.64 7.14C5.64 5.42 7.03 4.3 8.75 3.93V3.33C8.75 2.6 9.35 2 10.08 2H10.09C10.82 2 11.42 2.6 11.42 3.33V3.95C12.8 4.29 13.67 5.15 14.05 6.21C14.25 6.76 13.83 7.34 13.24 7.34H12.98C12.61 7.34 12.31 7.08 12.21 6.72C11.98 5.96 11.35 5.47 10.09 5.47C8.59 5.47 7.69 6.15 7.69 7.11C7.69 7.95 8.34 8.5 10.36 9.02C12.38 9.54 14.54 10.41 14.54 12.93C14.52 14.76 13.15 15.76 11.41 16.09Z"
                  fill="white"
                />
              </svg>
              <p>Select Cash Drawer</p>
            </div>
            <p class="popup_header_title_para">UT.5</p>
          </div>
          <svg
            onClick={() => {
              setCashDrawerPopup(false);
              setSelectedValue("");
              setError("");
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="select_Cash_drawer">
          <ScrollView style={{ height: "270px" }}>
            <div className="select_Cash_drawer_content">
              {listData?.map((val, index) => {
                return (
                  <div className="select_Cash_drawer_content_list" key={index}>
                    <label htmlFor="radio-button">{val.value}</label>
                    <input
                      type="radio"
                      id={val.key}
                      name={val.value}
                      value={val.key}
                      checked={selectedValue === val.key}
                      onChange={handleRadioChange}
                    />
                  </div>
                );
              })}
            </div>
          </ScrollView>
        </div>
        <p className="error_validation error_fix">{error}</p>
        <button
          className="void_payment_btn"
          onClick={() => {
            handleSave();
          }}
        >
          Done
        </button>
      </div>
    </>
  );
};
