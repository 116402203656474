import { useState } from "react";
import { detail_co_obligor_Tab_getData } from "../../../../reducer/ParticipateReducer";
import { useDispatch, useSelector } from "react-redux";
import { DateBox, NumberBox, TextBox } from "devextreme-react";

export const Detail_Co_Obligor_Tab = ({ editable }) => {
  const { Detail_co_obligor_Tab_getData } = useSelector(
    (store) => store.participate
  );

  const dispatch = useDispatch();

  const maskRules = {
    H: /[0-9]/,
  };

  return (
    <>
      <div className="Detail_Co_Obligor_Tab">
        <div className="Detail_Co_Obligor_Tab_line1">
          <div className="Detail_Co_Obligor_Tab_line1_input_block_">
            <p>First Name</p>

            <TextBox
              style={{ marginTop: "3%" }}
              value={Detail_co_obligor_Tab_getData.FirstNmae}
              onValueChange={(e) => {
                dispatch(
                  detail_co_obligor_Tab_getData({
                    ...Detail_co_obligor_Tab_getData,
                    FirstNmae: e,
                    isChange: true,
                  })
                );
              }}
              disabled={!editable}
              isValid={true}
              placeholder={"First Name"}
            />
          </div>
          <div className="Detail_Co_Obligor_Tab_line1_input_block_">
            <p>Middle Name</p>
            <TextBox
              style={{ marginTop: "3%" }}
              value={Detail_co_obligor_Tab_getData.MiddleName}
              onValueChange={(e) => {
                dispatch(
                  detail_co_obligor_Tab_getData({
                    ...Detail_co_obligor_Tab_getData,
                    MiddleName: e,
                    isChange: true,
                  })
                );
              }}
              disabled={!editable}
              isValid={true}
              placeholder={"Middle Name"}
            />
          </div>
          <div className="Detail_Co_Obligor_Tab_line1_input_block_">
            <p>Last Name</p>
            <TextBox
              style={{ marginTop: "3%" }}
              value={Detail_co_obligor_Tab_getData.LastName}
              onValueChange={(e) => {
                dispatch(
                  detail_co_obligor_Tab_getData({
                    ...Detail_co_obligor_Tab_getData,
                    LastName: e,
                    isChange: true,
                  })
                );
              }}
              disabled={!editable}
              isValid={true}
              placeholder={"Last Name"}
            />
          </div>
        </div>
        <div className="Detail_Co_Obligor_Tab_line1">
          <div className="Detail_Co_Obligor_Tab_line1_input_block_">
            <p>Address Line 1</p>

            <TextBox
              style={{ marginTop: "3%" }}
              value={Detail_co_obligor_Tab_getData.AddressLine1}
              onValueChange={(e) => {
                dispatch(
                  detail_co_obligor_Tab_getData({
                    ...Detail_co_obligor_Tab_getData,
                    AddressLine1: e,
                    isChange: true,
                  })
                );
              }}
              disabled={!editable}
              isValid={true}
              placeholder={"Address Line 1"}
            />
          </div>
          <div className="Detail_Co_Obligor_Tab_line1_input_block_">
            <p>Address Line 2</p>

            <TextBox
              style={{ marginTop: "3%" }}
              value={Detail_co_obligor_Tab_getData.AddressLine2}
              onValueChange={(e) => {
                dispatch(
                  detail_co_obligor_Tab_getData({
                    ...Detail_co_obligor_Tab_getData,
                    AddressLine2: e,
                    isChange: true,
                  })
                );
              }}
              disabled={!editable}
              isValid={true}
              placeholder={"Address Line 2"}
            />
          </div>
          <div className="Detail_Co_Obligor_Tab_line1_input_block_">
            <p>City</p>

            <TextBox
              style={{ marginTop: "3%" }}
              value={Detail_co_obligor_Tab_getData.City}
              onValueChange={(e) => {
                dispatch(
                  detail_co_obligor_Tab_getData({
                    ...Detail_co_obligor_Tab_getData,
                    City: e,
                    isChange: true,
                  })
                );
              }}
              disabled={!editable}
              isValid={true}
              placeholder={"City"}
            />
          </div>
        </div>
        <div className="Detail_Co_Obligor_Tab_line1">
          <div className="Detail_Co_Obligor_Tab_line1_input_block_">
            <p>State</p>

            <TextBox
              style={{ marginTop: "3%" }}
              value={Detail_co_obligor_Tab_getData.State}
              onValueChange={(e) => {
                dispatch(
                  detail_co_obligor_Tab_getData({
                    ...Detail_co_obligor_Tab_getData,
                    State: e,
                    isChange: true,
                  })
                );
              }}
              disabled={!editable}
              isValid={true}
              placeholder={"State"}
            />
          </div>
          <div className="Detail_Co_Obligor_Tab_line1_input_block_">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2%",
              }}
            >
              <div
                className="Detail_Co_Obligor_Tab_line1_input_block_"
                style={{ width: "48%" }}
              >
                <p>ZIP</p>

                <NumberBox
                  onKeyDown={(e) => {
                    const { event } = e;
                    const str = event.key || String.fromCharCode(event.which);
                    if (/^[.,e]$/.test(str)) {
                      event.preventDefault();
                    }
                  }}
                  value={Detail_co_obligor_Tab_getData.ZIP}
                  disabled={!editable}
                  min={0}
                  onValueChange={(e) => {
                    dispatch(
                      detail_co_obligor_Tab_getData({
                        ...Detail_co_obligor_Tab_getData,
                        ZIP: e,
                        isChange: true,
                      })
                    );
                  }}
                  placeholder={"ZIP"}
                />
              </div>
              <div style={{ width: "48%" }}>
                <p>SSN</p>

                <TextBox
                  style={{ marginTop: "3%" }}
                  showMaskMode="always"
                  maskRules={{ H: /^[0-9*]$/ }}
                  mask="HHH-HH-HHHH"
                  value={
                    editable
                      ? Detail_co_obligor_Tab_getData.SSN
                      : String(Detail_co_obligor_Tab_getData.SSN).length <= 5
                      ? Detail_co_obligor_Tab_getData.SSN.replace(/[0-9]/g, "*")
                      : "*****" + Detail_co_obligor_Tab_getData.SSN?.slice(5)
                  }
                  onValueChange={(e) => {
                    dispatch(
                      detail_co_obligor_Tab_getData({
                        ...Detail_co_obligor_Tab_getData,
                        SSN: e,
                        isChange: true,
                      })
                    );
                  }}
                  disabled={!editable}
                  isValid={true}
                />
              </div>
            </div>
          </div>
          <div className="Detail_Co_Obligor_Tab_line1_input_block_">
            <p>Date of birth</p>

            <DateBox
              value={Detail_co_obligor_Tab_getData.DOB}
              useMaskBehavior={true}
              style={{ marginTop: "3%" }}
              onValueChange={(e) => {
                dispatch(
                  detail_co_obligor_Tab_getData({
                    ...Detail_co_obligor_Tab_getData,
                    DOB: e
                      ? new Date(
                          new Date(e).toLocaleDateString()
                        ).toLocaleDateString("en-CA")
                      : null,
                    isChange: true,
                  })
                );
              }}
              disabled={!editable}
            />
          </div>
        </div>
        <div className="Detail_Co_Obligor_Tab_line1">
          <div className="Detail_Co_Obligor_Tab_line1_input_block_">
            <p>Home Phone</p>
            <TextBox
              style={{ marginTop: "3%" }}
              validationError={false}
              showMaskMode="always"
              maskRules={{ H: /^[0-9*]$/ }}
              mask="(HHH) HHH-HHHH"
              isValid={true}
              value={String(Detail_co_obligor_Tab_getData.HomePhone).replace(
                /\s/g,
                ""
              )}
              onValueChange={(e) => {
                dispatch(
                  detail_co_obligor_Tab_getData({
                    ...Detail_co_obligor_Tab_getData,
                    HomePhone: e.replace(/\s/g, ""),
                    isChange: true,
                  })
                );
              }}
              disabled={!editable}
            />
          </div>
          <div className="Detail_Co_Obligor_Tab_line1_input_block_">
            <p>Work Phone</p>

            <TextBox
              style={{ marginTop: "3%" }}
              validationError={false}
              showMaskMode="always"
              maskRules={{ H: /^[0-9*]$/ }}
              mask="(HHH) HHH-HHHH"
              isValid={true}
              value={String(Detail_co_obligor_Tab_getData.WorkPhone).replace(
                /\s/g,
                ""
              )}
              onValueChange={(e) => {
                dispatch(
                  detail_co_obligor_Tab_getData({
                    ...Detail_co_obligor_Tab_getData,
                    WorkPhone: e.replace(/\s/g, ""),
                    isChange: true,
                  })
                );
              }}
              disabled={!editable}
            />
          </div>
          <div className="Detail_Co_Obligor_Tab_line1_input_block_">
            <p>Cell Phone</p>

            <TextBox
              style={{ marginTop: "3%" }}
              validationError={false}
              showMaskMode="always"
              maskRules={{ H: /^[0-9*]$/ }}
              mask="(HHH) HHH-HHHH"
              isValid={true}
              value={String(Detail_co_obligor_Tab_getData.CellPhone).replace(
                /\s/g,
                ""
              )}
              onValueChange={(e) => {
                dispatch(
                  detail_co_obligor_Tab_getData({
                    ...Detail_co_obligor_Tab_getData,
                    CellPhone: e.replace(/\s/g, ""),
                    isChange: true,
                  })
                );
              }}
              disabled={!editable}
            />
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "2%" }}>
          <input
            type="checkbox"
            checked={Detail_co_obligor_Tab_getData.CardOnFile}
            disabled={!editable}
            onChange={(e) => {
              dispatch(
                detail_co_obligor_Tab_getData({
                  ...Detail_co_obligor_Tab_getData,
                  CardOnFile: !Detail_co_obligor_Tab_getData.CardOnFile,
                  isChange: true,
                })
              );
            }}
          />
          <p style={{ marginLeft: "1%" }}>Card On File ?</p>
        </div>
      </div>
    </>
  );
};
