export const getAccessPermission = (userAccessData, sForm, sAccess) => {
  const permission = userAccessData.some((userAccess) => {
    if (userAccess.sForm.toLowerCase() === sForm.toLowerCase()) {
      if (userAccess.sAccess === sAccess) return true;
    }
  });
  return permission;
};

export const getAccessData = (userAccessData, sForm) => {
  const permission = userAccessData.find(
    (userAccess) => userAccess.sForm.toLowerCase() === sForm.toLowerCase()
  )?.sAccess;
  switch (permission) {
    case "Full":
      return {
        Add: true,
        Edit: true,
        View: true,
        Delete: true,
      };
    case "Add":
      return {
        Add: true,
        Edit: true,
        View: true,
        Delete: false,
      };
    case "Edit":
      return {
        Add: false,
        Edit: true,
        View: true,
        Delete: false,
      };
    case "View":
      return {
        Add: false,
        Edit: false,
        View: true,
        Delete: false,
      };
    default:
      return {
        Add: false,
        Edit: false,
        View: false,
        Delete: false,
      };
  }
};
