

export const Tablecoloum = [
    {title:"", moreoption:true, customwidth:"50px"},
    { title: "Category" },
    { title: "Start Date", date: true },
    { title: "End Date", date: true },
    { title: "Client Id" },
    { title: "Client Name" },
    { title: "Days"},
    { title: "Rate", currency: true },
    { title: "Revenue", currency: true },
    { title: "Collections"},
    { title: "Fee Name" },
    { title: "Region" },
    { title: "Location" },
    { title: "Court/Agency" },
    { title: "Billing Agency", Boolean: true },
    { title: "Fee Type" }
  ];

  export const SchedulingDateRange = [
    { name: "Last 3 Days" },
    { name: "Last 7 Days" },
    { name: "Month to Date" },
    { name: "Previous Month" },
    { name: "Next Month from Date" },
    { name: "Next 3 Month from Date" },
    { name: "last 3 Month to Date" },
    { name: "last 6 Month to Date" },
    { name: "Year to Date" },
    { name: "Previous Year" },
    { name: "Today" },
  ];
  