import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleGetLocal } from "../../../../../../../services/auth-service";
import axios from "axios";
import { logOut } from "../../../../../../../components/Logout";

export const VoidCashPayments = ({
  voidCashPaymentPopup,
  setVoidCashPaymentPopup,
  rowInfoData,
  toastConfig,
  setToastConfig,
  setLoading,
  refreshGetPaymentCreditsTableData,
}) => {
  const [detail, setDetail] = useState("");
  const [commentValidation, setCommentValidation] = useState("");
  const navigate = useNavigate();

  const formattedPrice = (price) => {
    let formatPrice = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
    }).format(Math.abs(price));
    return price >= 0 ? formatPrice : `(${formatPrice})`;
  };

  useEffect(() => {
    setDetail("");
    setCommentValidation("");
  }, [voidCashPaymentPopup]);

  const data = { PaymentId: rowInfoData?.gPaymentId, comment: detail };

  const handleSave = async () => {
    let isValid = true;
    if (!detail || detail?.charAt(0) === " ") {
      setCommentValidation("Please enter comment");
      isValid = false;
    }
    if (isValid) {
      setLoading(true);
      const pathname = handleGetLocal("pathURL");
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/financial/void-cash-payment`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              Domain: `${pathname}`,
            },
          }
        )
        .then((val) => {
          setLoading(false);
          setVoidCashPaymentPopup(false);
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: val?.data?.message,
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: val?.data?.message,
              type: "success",
            });
          }, 1900);
          refreshGetPaymentCreditsTableData();
        })
        .catch((val) => {
          setLoading(false);
          if (!val?.response) {
            navigate(`/error/500`);
          } else if (val?.response?.status === 401) {
            logOut();
          }
        });
    }
  };

  return (
    <>
      <div className="void_payment">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg
                style={{
                  cursor: "pointer",
                }}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C13.91 14.69 13.28 14.69 12.89 14.3L10 11.41L7.11 14.3C6.72 14.69 6.09 14.69 5.7 14.3C5.31 13.91 5.31 13.28 5.7 12.89L8.59 10L5.7 7.11C5.31 6.72 5.31 6.09 5.7 5.7C6.09 5.31 6.72 5.31 7.11 5.7L10 8.59L12.89 5.7C13.28 5.31 13.91 5.31 14.3 5.7C14.69 6.09 14.69 6.72 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z"
                  fill="white"
                ></path>
              </svg>
              <p>Void Payment</p>
            </div>
            <p class="popup_header_title_para">VD.1</p>
          </div>
          <svg
            onClick={() => {
              setVoidCashPaymentPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="void_payment_content">
          <div className="void_payment_content_infromations">
            <div className="void_payment_content_infromation">
              <p className="void_payment_content_infromation_heading">
                Void Payment
              </p>
              <p className="void_payment_content_infromation_highlight">
                This can not be undone
              </p>
            </div>
            <p className="void_payment_content_paras">
              This should only be used to correct error
            </p>
            <p className="void_payment_content_paras">
              Void payment of {formattedPrice(rowInfoData?.Amount)} entered on
              {rowInfoData?.[`Date of Receipt`]}
            </p>
            <div>
              <p className="void_payment_content_textarea_heading">
                Please enter a detailed comment
                <span className="marks_mandatary">*</span>
              </p>
              <textarea
                name=""
                id=""
                value={detail}
                placeholder="Write comment here"
                className="void_payment_content_textarea"
                onChange={(e) => {
                  const value = e.target.value;
                  setDetail(value);
                  if (value.charAt(0) === " ") {
                    setCommentValidation("Please enter comment");
                  } else {
                    setCommentValidation("");
                  }
                }}
              ></textarea>
              <p className="error_validation">{commentValidation}</p>
            </div>
          </div>
        </div>
        <div
          className="popup_buttons"
          style={{ marginTop: "10px", marginRight: "15px" }}
        >
          <button className="btn primary_btn" onClick={handleSave}>
            Save
          </button>
          <button
            className="btn"
            onClick={() => {
              setVoidCashPaymentPopup(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};
