import React, { useCallback, useState } from "react";
import { DateBox, DropDownBox, ScrollView } from "devextreme-react";
import DataGrid, {
  Column,
  FilterRow,
  Selection,
} from "devextreme-react/data-grid";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import { useEffect } from "react";
import { logOut } from "../../../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { handleGetLocal } from "../../../../../services/auth-service";
import axios from "axios";
import { useSelector } from "react-redux";

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  fieldNmae,
  reset,
  disabled,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

export const DoNotCreateObligation = ({
  doNotCreateObligation,
  setDoNotCreateObligation,
  toastConfig,
  setToastConfig,
  userinfo,
  docketDropdownData,
  setLoading,
  drugTestRowData,
  drugTestType,
  drugDropdownData,
  getrugTestDataApi,
  amount,
  setAddDrugTest,
  gotoDrugTest,
}) => {
  const [fields, setFields] = useState({
    drugTestType: { name: "", id: "" },
    testDate: "",
    testReason: { name: "", id: "" },
    testResult: { name: "", id: "" },
    testResultDate: "",
    testBy: { name: "", id: "" },
    requisitionNumber: "",
    notes: "",
    missedTest: false,
    diluted: false,
  });
  const [selectedValue, setSelectedValue] = useState({
    Court: "",
    "Docket #": "",
    "Sentence Date": "",
    "Supervision Status": "",
    gDocketId: "",
  });
  const [checkTestResultType, SetCheckTestResultType] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const navigate = useNavigate();
  const [docketvalidation, setDocketvalidation] = useState("");
  const [drugTestTypeValidation, setDrugTestTypeValidation] = useState("");
  const [testDatevalidation, setTestDatevalidation] = useState("");
  const [testReasonvalidation, setTestReasonvalidation] = useState("");
  const [testResultvalidation, setTestResultvalidation] = useState("");
  const [testResultDatevalidation, setTestResultDatevalidation] = useState("");
  const { drugAddDocket } = useSelector((store) => store.AdminTable);
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);

  const fetchedDataSource = docketDropdownData?.map((val) => {
    const convertDateFormat = (dateString) => {
      const dateObj = new Date(dateString);
      return dateObj.toLocaleDateString("en-US");
    };
    return {
      Court: val?.sCourtName,
      "Docket #": val?.sDocketNumberCourtCounts,
      "Sentence Date": convertDateFormat(val?.dSentence),
      "Supervision Status": val?.sSupervisionStatusName,
      gDocketId: val?.gDocketId,
    };
  });

  useEffect(() => {
    if (drugTestType === "ADD") {
      SetCheckTestResultType("NotReported");
      setDocketvalidation("");
      setTestDatevalidation("");
      setDrugTestTypeValidation("");
      setTestReasonvalidation("");
      setTestResultvalidation("");
      setTestResultDatevalidation();
      setFields({
        drugTestType: { name: "", id: "" },
        testDate: "",
        testReason: { name: "", id: "" },
        testResult: { name: "", id: "" },
        testResultDate: "",
        testBy: { name: "", id: "" },
        requisitionNumber: "",
        notes: "",
        missedTest: false,
        diluted: false,
      });
      setSelectedValue({
        Court: "",
        "Docket #": drugAddDocket?.[`Docket #`],
        "Sentence Date": "",
        "Supervision Status": "",
        gDocketId: drugAddDocket?.gDocketId,
      });
      setDataSource(fetchedDataSource);
    } else {
      // SetCheckTestResultType("NotReported");
      setFields({
        drugTestType: {
          name: drugTestRowData?.[`Drug Test Type`],
          id: drugTestRowData?.gDrugTestTypeId,
        },
        testDate: drugTestRowData?.Served,
        testReason: {
          name: drugTestRowData?.[`Test Reason`],
          id: drugTestRowData?.gDrugTestReasonId,
        },
        testResult: {
          name: drugTestRowData?.[`Test Result`],
          id: drugTestRowData?.gDrugTestResultId,
        },
        testResultDate: drugTestRowData?.[`Result Date`],
        testBy: {
          name: drugTestRowData?.[`Test By`],
          id: drugTestRowData?.gDrugTestById,
        },
        requisitionNumber: drugTestRowData?.Requisition || "",
        notes: drugTestRowData?.Note || "",
        missedTest: drugTestRowData?.bMissedTest,
        diluted: drugTestRowData?.bDiluted,
      });
      const selectedDocket = dataSource?.find(
        (item) => item.gDocketId === drugTestRowData?.gDocketId
      );
      setSelectedValue(selectedDocket);
      setDataSource(fetchedDataSource);
      const selectedDropdownItem = drugDropdownData?.testResult?.find(
        (item) => {
          return item.key?.toLowerCase() === fields.testResult.id;
        }
      );
      if (
        selectedDropdownItem?.value?.includes("+") ||
        selectedDropdownItem?.value?.includes("Positive")
      ) {
        SetCheckTestResultType("positive");
      } else if (selectedDropdownItem?.value === "Negative") {
        SetCheckTestResultType("negative");
      } else {
        SetCheckTestResultType("NotReported");
      }
    }
  }, [doNotCreateObligation, drugTestRowData]);

  const handleTestResultTypeChange = (type) => {
    SetCheckTestResultType(type);

    let selectedTestResult;
    if (type === "negative") {
      selectedTestResult = drugDropdownData?.testResult?.find(
        (vall) => vall?.value === "Negative"
      );
    } else {
      selectedTestResult = { name: "", id: "" };
    }

    setFields((prevFields) => ({
      ...prevFields,
      testResult: {
        name: selectedTestResult?.value || "",
        id: selectedTestResult?.key || "",
      },
    }));
  };

  const dropdownOptions = drugDropdownData?.testResult
    ?.filter((vall) => {
      if (checkTestResultType === "positive") {
        return vall?.value?.includes("+") || vall?.value?.includes("Positive");
      } else if (checkTestResultType === "negative") {
        return vall?.value === "Negative";
      }
      return true;
    })
    .map((vall) => {
      return {
        name: vall?.value,
        id: vall?.key,
      };
    });

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const formattedPrice = (price) => {
    let formatPrice = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
    }).format(parseFloat(price));
    return price >= 0 ? formatPrice : `(${formatPrice.slice(1)})`;
  };

  const data = {
    gDrugTestId:
      drugTestType === "ADD"
        ? "00000000-0000-0000-0000-000000000000"
        : drugTestRowData?.gDrugTestId,
    gEntryUserId: drugTestRowData?.gEntryUserId,
    gDocketId: selectedValue?.gDocketId,
    gPersonId:
      drugTestType === "ADD" ? userinfo?.gPersonId : userinfo?.gPersonId,
    gDrugTestTypeId: fields?.drugTestType?.id,
    dTest: fields.testDate,
    gDrugTestResultId: fields?.testResult?.id,
    dResult: fields?.testResultDate,
    gDrugTestReasonId: fields?.testReason?.id,
    gDrugTestById: fields?.testBy?.id,
    sDrugTestIdPin: null,
    sRequisitionNumber: fields.requisitionNumber,
    bMissedTest: fields.missedTest,
    gEntityId: drugTestRowData?.gEntityId,
    bDiluted: fields.diluted,
    sNote: fields.notes,
  };

  const handleSave = async () => {
    let isValid = true;
    if (!selectedValue.gDocketId) {
      setDocketvalidation("Please Select Docket");
      isValid = false;
    }
    if (!fields.drugTestType.id) {
      setDrugTestTypeValidation("Please Select Drug Test Type");
      isValid = false;
    }
    if (!fields.testReason.id) {
      setTestReasonvalidation("Please Select Test Reason ");
      isValid = false;
    }
    if (!fields.testDate) {
      setTestDatevalidation("Please Select Test Date");
      isValid = false;
    }
    if (!fields.testResult.id && checkTestResultType === "positive") {
      setTestResultvalidation("Please Select Test Result");
      isValid = false;
    }
    if (!fields.testResultDate && checkTestResultType === "negative") {
      setTestResultDatevalidation("Please Select Result Date");
      isValid = false;
    }
    if (isValid) {
      setLoading(true);
      const pathname = handleGetLocal("pathURL");
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/DrugTest/addEditDrugTest`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              Domain: `${pathname}`,
            },
          }
        )
        .then((val) => {
          setLoading(false);
          setDoNotCreateObligation(false);
          if (drugTestType === "ADD") {
            setAddDrugTest(false);
          }
          getrugTestDataApi();
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message:
              drugTestType === "ADD"
                ? "Successfully Added!"
                : "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message:
                drugTestType === "ADD"
                  ? "Successfully Added!"
                  : "Successfully Updated!",
              type: "success",
            });
          }, 1900);
        })
        .catch((error) => {
          setLoading(false);
          if (!error?.response) {
            navigate(`/error/500`);
          } else if (error?.response?.status === 401) {
            logOut();
          }
        });
    }
  };
  const onGridBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }, []);

  return (
    <>
      <div className="do_Not_CreateObligation">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <p>Drug Test</p>
            </div>
            <p class="popup_header_title_para">DT.1</p>
          </div>
          <svg
            onClick={() => {
              setDoNotCreateObligation(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div
          className="do_Not_CreateObligation_content"
          style={{ padding: "0" }}
        >
          <ScrollView style={{ height: "600px" }}>
            <div
              className="add_community_service_content"
              style={{ padding: "20px" }}
            >
              <div className="edit_container_first">
                <div className="edit_content_flex">
                  <p className="edit_container_label">
                    Docket <span className="marks_mandatary">*</span>
                  </p>
                  <DropDownBox
                    placeholder="Select..."
                    disabled={gotoDrugTest ? true : false}
                    dataSource={dataSource}
                    value={selectedValue?.[`Docket #`]}
                    valueExpr={"Docket #"}
                    displayExpr={"Docket #"}
                    opened={isGridBoxOpened}
                    onOptionChanged={onGridBoxOpened}
                    contentRender={(value, element) => (
                      <DataGrid
                        dataSource={dataSource}
                        hoverStateEnabled={true}
                        height={180}
                        onRowClick={(e) => {
                          setSelectedValue(e?.data);
                          setDocketvalidation("");
                          setIsGridBoxOpened(false);
                        }}
                      >
                        <Selection mode="single" />
                        <FilterRow visible={true} />
                        <Column
                          dataField="Court"
                          caption="Court"
                          width={"auto"}
                        />
                        <Column
                          dataField="Docket #"
                          caption="Docket #"
                          width={"auto"}
                        />
                        <Column
                          dataField="Sentence Date"
                          caption="Sentence Date"
                          width={"auto"}
                        />
                        <Column
                          dataField="Supervision Status"
                          caption="Supervision Status"
                          width={"auto"}
                        />
                      </DataGrid>
                    )}
                  />
                  {docketvalidation && (
                    <p
                      className="error_validation"
                      style={{ position: "absolute", top: "12.5%" }}
                    >
                      {docketvalidation}
                    </p>
                  )}
                </div>
                <div className="edit_content_flex edit_content_space">
                  <p className="edit_container_label">
                    Drug Test Type <span className="marks_mandatary">*</span>
                  </p>
                  <DropDown
                    setfields={setFields}
                    fields={fields}
                    fieldNmae={"drugTestType"}
                    dropDownData={drugDropdownData?.drugTestType?.map(
                      (vall) => {
                        return {
                          name: vall?.value,
                          id: vall?.key,
                        };
                      }
                    )}
                    reset={setDrugTestTypeValidation}
                  />
                  {drugTestTypeValidation && (
                    <p
                      className="error_validation"
                      style={{ position: "absolute", top: "12.5%" }}
                    >
                      {drugTestTypeValidation}
                    </p>
                  )}
                </div>
              </div>
              <div className="edit_container_first">
                <div className="edit_content_flex">
                  <p className="edit_container_label">
                    Test Date <span className="marks_mandatary">*</span>
                  </p>
                  <DateBox
                    useMaskBehavior={true}
                    value={fields.testDate}
                    onValueChange={(e) => {
                      setFields((prev) => ({
                        ...prev,
                        testDate: e,
                      }));
                      setTestDatevalidation("");
                    }}
                  />
                  {testDatevalidation && (
                    <p
                      className="error_validation"
                      style={{ position: "absolute", top: "24.2%" }}
                    >
                      {testDatevalidation}
                    </p>
                  )}
                </div>
                <div className="edit_content_flex edit_content_space">
                  <p className="edit_container_label">
                    Test Reason <span className="marks_mandatary">*</span>
                  </p>
                  <DropDown
                    setfields={setFields}
                    fields={fields}
                    fieldNmae={"testReason"}
                    dropDownData={drugDropdownData?.testReason?.map((vall) => {
                      return {
                        name: vall?.value,
                        id: vall?.key,
                      };
                    })}
                    reset={setTestReasonvalidation}
                  />
                  {testReasonvalidation && (
                    <p
                      className="error_validation"
                      style={{ position: "absolute", top: "24.5%" }}
                    >
                      {testReasonvalidation}
                    </p>
                  )}
                </div>
              </div>
              <div className="edit_container_first">
                <div className="edit_content_flex">
                  <p className="edit_container_label">Test Result Type</p>
                  <div className="add_drug_flex">
                    <input
                      type="radio"
                      name="testResult"
                      id="NotReported"
                      checked={checkTestResultType === "NotReported"}
                      onChange={() => {
                        handleTestResultTypeChange("NotReported");
                        setTestResultDatevalidation();
                        setTestResultvalidation();
                      }}
                    />
                    <label
                      className="add_drug_userinfo_para"
                      htmlFor="NotReported"
                    >
                      Not Reported
                    </label>
                    <input
                      type="radio"
                      name="testResult"
                      id="negative"
                      checked={checkTestResultType === "negative"}
                      onChange={() => {
                        handleTestResultTypeChange("negative");
                        setTestResultvalidation();
                      }}
                    />
                    <label
                      className="add_drug_userinfo_para"
                      htmlFor="negative"
                    >
                      Negative
                    </label>
                    <input
                      type="radio"
                      name="testResult"
                      id="positive"
                      checked={checkTestResultType === "positive"}
                      onChange={() => {
                        handleTestResultTypeChange("positive");
                        setTestResultDatevalidation();
                      }}
                    />
                    <label
                      className="add_drug_userinfo_para"
                      htmlFor="positive"
                    >
                      Positive
                    </label>
                  </div>
                </div>
              </div>
              <div className="edit_container_first">
                <div className="add_drug_flex">
                  <input
                    type="checkbox"
                    name="diluted"
                    id="diluted"
                    checked={fields.diluted}
                    onChange={handleInputChange}
                  />
                  <label className="add_drug_userinfo_para" htmlFor="diluted">
                    Diluted
                  </label>
                  <input
                    type="checkbox"
                    name="missedTest"
                    id="missedTest"
                    checked={fields.missedTest}
                    onChange={handleInputChange}
                  />
                  <label
                    className="add_drug_userinfo_para"
                    htmlFor="missedTest"
                  >
                    Missed Test
                  </label>
                </div>
              </div>
              <div className="edit_container_first">
                <div className="edit_content_flex">
                  <p className="edit_container_label">
                    Test Result
                    {checkTestResultType === "positive" && (
                      <span className="marks_mandatary">*</span>
                    )}
                  </p>
                  <DropDown
                    disabled={checkTestResultType === "NotReported"}
                    setfields={setFields}
                    fields={fields}
                    fieldNmae={"testResult"}
                    dropDownData={dropdownOptions}
                    reset={setTestResultvalidation}
                  />
                  {testResultvalidation && (
                    <p
                      className="error_validation"
                      style={{ position: "absolute", top: "52.5%" }}
                    >
                      {testResultvalidation}
                    </p>
                  )}
                </div>
                <div className="edit_content_flex edit_content_space">
                  <p className="edit_container_label">
                    Test Result Date
                    {checkTestResultType === "negative" && (
                      <span className="marks_mandatary">*</span>
                    )}
                  </p>
                  <DateBox
                    useMaskBehavior={true}
                    value={fields.testResultDate}
                    onValueChange={(e) => {
                      setFields((prev) => ({
                        ...prev,
                        testResultDate: e,
                      }));
                      setTestResultDatevalidation("");
                    }}
                  />
                  {testResultDatevalidation && (
                    <p
                      className="error_validation"
                      style={{ position: "absolute", top: "52.5%" }}
                    >
                      {testResultDatevalidation}
                    </p>
                  )}
                </div>
              </div>
              <div className="edit_container_first">
                <div className="edit_content_flex">
                  <p className="edit_container_label">Test BY</p>
                  <DropDown
                    setfields={setFields}
                    fields={fields}
                    fieldNmae={"testBy"}
                    dropDownData={drugDropdownData?.testBy?.map((vall) => {
                      return {
                        name: vall?.value,
                        id: vall?.key,
                      };
                    })}
                  />
                </div>
                <div className="edit_content_flex">
                  <p className="edit_container_label">
                    Requisition / Coc Number
                  </p>
                  <input
                    id="requisitionNumber"
                    name="requisitionNumber"
                    type="text"
                    placeholder="Enter Requisition / Coc Number"
                    className="edit_container_input"
                    value={fields.requisitionNumber}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              {drugTestType === "ADD" && !isNaN(parseFloat(amount)) && (
                <div className="edit_container_first">
                  <p
                    className="edit_container_label"
                    style={{ color: "red", fontSize: "16px" }}
                  >
                    A new obligation for {formattedPrice(amount)} will be
                    created.
                  </p>
                </div>
              )}
              <div className="edit_container_second edit_content_flex">
                <p className="edit_container_label">Notes</p>
                <textarea
                  name="notes"
                  id="notes"
                  placeholder="Write Notes"
                  value={fields.notes}
                  onChange={handleInputChange}
                  className="dark-mode-textarea"
                ></textarea>
              </div>
              <div className="popup_buttons">
                <button className="btn primary_btn" onClick={handleSave}>
                  Save
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    setDoNotCreateObligation(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </ScrollView>
        </div>
      </div>
    </>
  );
};
