import axios from "axios";
import { logOut } from "../../../../../components/Logout";
import { handleGetLocal } from "../../../../../services/auth-service";
import {
  setDrugTestTableData,
  setPlannedservicesTableData,
  setProgramTableRowData,
  setWarrantTableData,
  setcompletedServiceTableData,
} from "../../Dockect/Data";

export class GoToDocketSubTabFunction {
  constructor() {
    this.pathname = handleGetLocal("pathURL");
  }

  async getJailPlannedServiceListApi(
    navigate,
    setCountjailPlanned,
    setJailPlannedData,
    setJailPlannedErrorHandling,
    dockerValue
  ) {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/jail-time/get-jailtimes?personId=${dockerValue?.userinfo?.gPersonId}&jailTimeId=&docketId=${dockerValue?.getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setCountjailPlanned(val?.data?.data?.length);
          setJailPlannedData(this.setJailPlannedDatas(val?.data?.data));
        } else {
          setJailPlannedErrorHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setJailPlannedErrorHandling(true);
        }
      });
  }

  async getJailCopletedServiceListApi(
    dockerValue,
    setJailCompletedData,
    setjailCompletedErrorHandling,
    navigate,
    setCountjailCompleted
  ) {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/jail-time/get-jailtime-logs?personId=${dockerValue?.userinfo?.gPersonId}&jailTimeId=&docketId=${dockerValue?.getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setCountjailCompleted(val?.data?.data?.length);
          setJailCompletedData(this.setJailCompletedDatas(val?.data?.data));
        } else {
          setjailCompletedErrorHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setjailCompletedErrorHandling(true);
        }
      });
  }

  async getJailtimelogDropdown(setJailtimelogDropdown, dockerValue, navigate) {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/jail-time/get-jailsites?personId=${dockerValue?.userinfo?.gPersonId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setJailtimelogDropdown(val?.data?.data);
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        }
      });
  }

  async getDropdownDatas(setDropdownData, navigate) {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/docket-dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setDropdownData(val?.data?.data);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }
  async getDocketDropdownData(setDocketDropdownData, navigate, dockerValue) {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/get-dockets?personId=${dockerValue?.userinfo?.gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setDocketDropdownData(val?.data?.data);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }
  convertDateFormat(dateString) {
    if (dateString) {
      const dateObj = new Date(dateString);
      return dateObj.toLocaleDateString("en-US");
    } else {
      return "";
    }
  }

  setJailPlannedDatas(data) {
    const arr = [];
    data.map((val, i) => {
      arr.push({
        Docket: val?.sDocketNumberCourtCounts,
        "Reason for JT": val?.jailTimeReason,
        "Days Ordered": val?.daysOrdered,
        "Days Completed": val?.daysCompleted,
        "Days Remaining": val?.daysRemaining,
        "Jail Site": val?.sJailSite,
        "Start Date": this.convertDateFormat(val?.start),
        "Completed By Date": this.convertDateFormat(val?.completeBy),
        "Completion Date": this.convertDateFormat(val?.dComplete),
        Void: val?.bVoid,
        ID: i,
        jailTimeReasonId: val?.jailTimeReasonId,
        entityId: val?.entityId,
        docketId: val?.docketId,
        jailTimeId: val?.jailTimeId,
        workSiteId: val?.workSiteId,
      });
    });
    return arr;
  }

  setJailCompletedDatas(data) {
    const arr = [];
    data.map((val, i) => {
      arr.push({
        "Days Completed": val?.daysCompleted,
        "Date Completed": this.convertDateFormat(val?.dComplete),
        "Jail Site": val?.sJailSite,
        Reason: val?.sJailTimeReasonName,
        DOE: this.convertDateFormat(val?.dDoe),
        User: val?.sEntryUserName,
        Void: val?.bVoid,
        "Write Off Reason": val?.sWriteOffReason,
        ID: i,
        docketId: val?.docketId,
        gJailTimeReasonId: val?.gJailTimeReasonId,
        gEntityId: val?.gEntityId,
        jailTimeLogId: val?.jailTimeLogId,
        workSiteId: val?.workSiteId,
      });
    });
    return arr;
  }
  setrevocationsTableData(data) {
    const arr = [];
    data.map((val, i) => {
      arr.push({
        "Appearance Type": val?.sCourtAppearanceName,
        Docket: val?.sDocketNumberCourtCounts,
        "Hearing Date": val?.dHearing,
        Court: val?.sCourtName,
        Judge: val?.sJudgeName,
        Disposition: val?.sDispositionName,
        Violation: val?.sViolation,
        Void: val?.bVoid,
        gCourtAppearanceTypeId: val?.gCourtAppearanceTypeId,
        gDocketId: val?.gDocketId,
        gCourtEntityId: val?.gCourtEntityId,
        gJudgeContactId: val?.gJudgeContactId,
        gDispositionId: val?.gDispositionId,
        gRevocationId: val?.gRevocationId,
        ID: i,
      });
    });
    return arr;
  }
  async getTollingDataApi(
    setCountTolling,
    setTollingData,
    navigate,
    dockerValue,
    setTollingErorrHandlingErrorHandling
  ) {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/tolling/get-tollings?personId=${dockerValue?.userinfo?.gPersonId}&tollingId=&docketId=${dockerValue?.getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setTollingErorrHandlingErrorHandling(false);
          setCountTolling(val?.data?.data?.length);
          setTollingData(this.setTollingTableData(val?.data?.data));
        } else {
          setTollingErorrHandlingErrorHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setTollingErorrHandlingErrorHandling(true);
        }
      });
  }
  setTollingTableData(data) {
    const arr = [];
    data.map((val, i) => {
      arr.push({
        Docket: val?.sDocketNumberCourtCounts,
        Type: val?.sTollingTypeName,
        Start: this.convertDateFormat(val?.dStart),
        End: this.convertDateFormat(val?.dEnd),
        Active: val?.bActive,
        Days: val?.iDays,
        Void: val?.bVoid,
        ID: i,
        gDocketId: val?.gDocketId,
        gEntryUserId: val?.gEntryUserId,
        gTollingTypeId: val?.gTollingTypeId,
        gTollingId: val?.gTollingId,
      });
    });
    return arr;
  }
  async getCommunityPlannedServiceListApi(
    setCommunityPlannedServicesData,
    setCommunityPlannedErrorHandling,
    setCommunityCount,
    navigate,
    dockerValue
  ) {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/communityservice/getplanneddata?personId=${dockerValue?.userinfo?.gPersonId}&docketId=${dockerValue?.getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setCommunityCount(val?.data?.data?.length);
          setCommunityPlannedServicesData(setPlannedservicesTableData(val?.data?.data));
        } else {
          setCommunityPlannedErrorHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setCommunityPlannedErrorHandling(true);
        }
      });
  }
  async getCommunityCompletedServiceListApi(
    setCommunityCompletedServiceData,
    setCommunityCompletedErrorHandling,
    navigate,
    dockerValue,
    setCommunityCompletedCount
  ) {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/communityservice/getcompleteddata?personId=${dockerValue?.userinfo?.gPersonId}&docketId=${dockerValue?.getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setCommunityCompletedCount(val?.data?.data?.length);
          setCommunityCompletedServiceData(setcompletedServiceTableData(val?.data?.data));
        } else {
          setCommunityCompletedErrorHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setCommunityCompletedErrorHandling(true);
        }
      });
  }
  async getCommunityServiceJailtimelogDropdown(setCommunityServiceJailtimelogDropdown, dockerValue, navigate) {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/CommunityService/get-jailsites?personId=${dockerValue?.userinfo?.gPersonId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setCommunityServiceJailtimelogDropdown(val?.data?.data);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }
  async getRevocationsDataApi(
    setRevocationserrorhandlig,
    setCountrevocations,
    setrevocationsgData,
    navigate,
    dockerValue
  ) {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/revocation/get-revocations?personId=${dockerValue?.userinfo?.gPersonId}&docketId=${dockerValue?.getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setRevocationserrorhandlig(false);
          setCountrevocations(val?.data?.data?.length);
          setrevocationsgData(this.setrevocationsTableData(val?.data?.data));
        } else {
          setRevocationserrorhandlig(true);
        }
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          setRevocationserrorhandlig(true);
        }
      });
  }
  async getProgramListApi(dockerValue, navigate, setCountProgram, setProgramTableData, setProgramErrorHandling) {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Programs/getPrograms?personId=${dockerValue?.userinfo?.gPersonId}&docketId=${dockerValue?.getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setCountProgram(val?.data?.data?.length);
          setProgramTableData(setProgramTableRowData(val?.data?.data));
        } else {
          setProgramErrorHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setProgramErrorHandling(true);
        }
      });
  }
  async getWarrantsDataApi(dockerValue, navigate, setCountWarrant, setWarrantData, setWarrentErrorHandling) {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Warrants/GetWarrantData?personId=${dockerValue?.userinfo?.gPersonId}&docketId=${dockerValue?.getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setCountWarrant(val?.data?.data?.length);
          setWarrantData(setWarrantTableData(val?.data?.data));
        } else {
          setWarrentErrorHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setWarrentErrorHandling(true);
        }
      });
  }
  async getrugTestDataApi(dockerValue, navigate, setDrugTestCount, setDrugTestData, setDrugTestErrorHandling) {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/DrugTest/GetDrugTest?personId=${dockerValue?.userinfo?.gPersonId}&docketId=${dockerValue?.getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setDrugTestCount(val?.data?.data?.length);
          setDrugTestData(setDrugTestTableData(val?.data?.data));
        } else {
          setDrugTestErrorHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setDrugTestErrorHandling(true);
        }
      });
  }

  getbottomtabData = async (
    dockerValue,
    obligations,
    setobligations,
    seterrorhandling,
    setLoading,
    navigate,
    setobligationDatacount
  ) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/docket/get-docket-tabs?personId=${dockerValue?.userinfo?.gPersonId}&docketId=${dockerValue?.getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          setobligationDatacount(val?.data?.data?.obligations?.length);
          setobligations(this.SetTableData2(val?.data?.data?.obligations));
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  SetTableData2 = (dataa) => {
    const arr = [];
    dataa?.map((val, i) => {
      arr.push({
        ID: i,
        "Obligation Start": val?.dDoe,
        "Pay Plan Start": val?.dPaymentPlanStart,
        "Obligation Type": val?.sObligationTypeName,
        "Fin Status": val?.sFinancialStatusName,
        "!": val?.bHasPaymentPlan,
        "Recipient Category": val?.sRecipientCategoryName,
        "Fee Code": val?.sFeeName,
        Priority: val?.iPayPriority,
        Amount: val?.fAmount,
        Paid: val?.fPaid,
        "On Hand": val?.fDisburseEligible,
        "Written Off": val?.fWriteOff,
        "Balance Owed": val?.fBalance,
        Disbursed: val?.fDisburseEligible,
        "Obligation Ref#": val?.sReference,
        Arrears: val?.fArrears,
        DOE: val?.dDoe,
        "Entered by": val?.sEnteredBy,
        gPersonId: val?.gPersonId,
        sFinancialStatusName: val?.sFinancialStatusName,
        gObligationId: val?.gObligationId,
        sDocketNumberCourtCounts: val?.sDocketNumberCourtCounts,
        userinfo: val,
      });
    });

    return arr;
  };

  getattachmentTableData = async (setattachmentTabledata, setLoading, navigate, dockerValue, setattachmentcount) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/attachments/get-attachments?recordId=${dockerValue?.getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        setattachmentcount(val?.data?.data?.length);
        setattachmentTabledata(this.setTableData(val?.data?.data));
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  setTableData = (dataa) => {
    const arr = [];
    dataa.map((val) => {
      arr.push({
        Filename: val?.filename,
        Category: val?.attachmentCateogoryName,
        Description: val?.description,
        ID: val?.recordId,
        "Entered By": val?.enteredByUserName,
        DOE: val?.doe,
        attachment: val?.attachment,
        attachmentCategoryId: val?.attachmentCategoryId,
        binaryDataId: val?.binaryDataId,
        image: val?.image,
      });
    });

    return arr;
  };

  GoToChildDocketGetGride = async (setLoading, dockerValue, setData, navigate) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/docket/get-child-docket?docketId=${dockerValue?.getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        setData(this.setTableDataGoToChildDocketGetGride(val.data.data));
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  async GoTonotesapi(dockerValue, navigate, setnotesDatacount) {
    if (!dockerValue?.getTopDynamicDocketid) {
      return;
    }
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/activity/get?recordId=${
          dockerValue?.getTopDynamicDocketid
        }&systemnotes=${true}&docketnotes=${true}&obligationnotes=${false}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setnotesDatacount(val?.data?.data?.length);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  setTableDataGoToChildDocketGetGride = (dataa) => {
    const arr = [];
    dataa.map((val, id) => {
      arr.push({
        "Docket #": val?.sDocketNumberCourtCounts,
        ID: id,
        userinfo: val,
      });
    });

    return arr;
  };
}
