export const feeScheduleTableColumn = [
  { title: "Local Legal Code", LinkFeeSchedule: true },
  { title: "Active Date" },
  { title: "Local Violation Description" },
  { title: "DDS Legal Code" },
];

const convertDateFormat = (dateString) => {
  if (dateString) {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleDateString("en-US");
  } else {
    return "";
  }
};

export const setFeeScheduleTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Local Legal Code": `${val?.sLocalLegalCode} ${val?.sLocalViolationDescription}`,
      "Active Date": convertDateFormat(val?.dActive),
      "Local Violation Description": val?.sLocalViolationDescription,
      "DDS Legal Code": val?.sDdslegalCode,
      gFeeScheduleId: val?.gFeeScheduleId,
      sLocalLegalCode: val?.sLocalLegalCode,
      ID: i,
    });
  });
  return arr;
};
