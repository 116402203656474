import "./polyfills";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./Root";
import reportWebVitals from "./reportWebVitals";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import ParticipateReducer from "./reducer/ParticipateReducer";
import HomePageReducer from "./reducer/HomePageReducer";
import AdminReadReducer from "./reducer/AdminReadReducer";
import ParticipateReducerNotes from "./reducer/participantNoteReducer";
import ReportWizardReducer from "./reducer/ReportWizardReducer";
import AddParticipantWizardReducer from "./reducer/AddParticipantWizardReducer";
import "./index.css";
import "./devextreme-license";

const store = configureStore({
  reducer: {
    participate: ParticipateReducer,
    participateNotes: ParticipateReducerNotes,
    Home: HomePageReducer,
    AdminTable: AdminReadReducer,
    ReportWizardReducer: ReportWizardReducer,
    AddParticipantWizardReducer: AddParticipantWizardReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
