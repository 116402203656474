import { Revocations } from "../../../Dockect/Revocations/Revocations";

export const GoToRevocation = ({
  Revocationserrorhandlig,
  docketDropdownData,
  revocationsgData,
  dropdownData,
  toastConfig,
  setToastConfig,
  getRevocationsDataApi,
  userinfo,
  dockertDetail,
}) => {
  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <Revocations
          Revocationserrorhandlig={Revocationserrorhandlig}
          docketDropdownData={docketDropdownData}
          revocationsgData={revocationsgData}
          dropdownData={dropdownData}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          getRevocationsDataApi={getRevocationsDataApi}
          userinfo={userinfo}
          dockertDetail={dockertDetail}
          GoToRevocationDocketDisable={true}
        />
      </div>
    </>
  );
};
