import React from "react";
import { SelectBox } from "devextreme-react";
import { useDispatch } from "react-redux";
import { setstateparticipent } from "../reducer/ParticipateReducer";

export const ColumnDropdown = ({
  data,
  value,
  setfields,
  fields,
  fieldNmae,
  reset,
  margin,
  width,
  disabled,
  changedate,
  redux,
  stateparticipent,
  resetErrorHandling,
  onChange,
  IsChangefunction,
  QuickAddObligationFeeCode,
  setgObligationTypeID,
  setgFeeScheduleCategoryID,
  setgDisburseEntityID,
  setgDisburseMethodID,
  setgDisburseFrequencyID,
  setgBankAccountID,
  setiDisbursePercent,
  setGoToobligationIsChange,
  setxmldata,
  setisdefault,
  setsLocalLegalCodeExtra,
  setfFee,
  setfilterdata,
  setFeeDecription,
  searchMode,
}) => {
  const dispatch = useDispatch();
  const handleRowClick = (e) => {
    if (reset) {
      reset("");
    }
    if (QuickAddObligationFeeCode) {
      setfields({
        ...fields,
        [fieldNmae]: { name: e.itemData.name, id: e.itemData.id },
      });
      setgObligationTypeID(e?.itemData?.gObligationTypeID);
      setgFeeScheduleCategoryID(e?.itemData?.gFeeScheduleCategoryID);
      setgDisburseEntityID(e?.itemData?.gDisburseEntityID);
      setgDisburseMethodID(e?.itemData?.gDisburseMethodID);
      setgDisburseFrequencyID(e?.itemData?.gDisburseFrequencyID);
      setgBankAccountID(e?.itemData?.gBankAccountID);
      setiDisbursePercent(e?.itemData?.iDisbursePercent);
      setsLocalLegalCodeExtra(e?.itemData?.sLocalLegalCodeExtra);
      setfFee(e?.itemData?.fFee);
      setFeeDecription(e?.itemData?.sDDSViolationDescription);
    }
    if (changedate) {
      const dateString = fields?.["Start Date Of Payment Plan"];
      const date = new Date(dateString);
      Number(e.itemData.name) > 0 && date.setDate(e.itemData.name);
      date.setMonth(
        e.itemData.id == 1
          ? new Date(fields?.["Start Date Of Payment Plan"]).getMonth() + 1
          : new Date(fields?.["Start Date Of Payment Plan"]).getMonth()
      );

      const updatedDateString = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      setfields({
        ...fields,
        [fieldNmae]: { name: e.itemData.name, id: e.itemData.id },
        "Start Date Of Payment Plan": updatedDateString
          ? updatedDateString.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$1-$2T00:00:00")
          : fields?.["Start Date Of Payment Plan"],
      });
    } else {
      if (redux) {
        if (resetErrorHandling) {
          dispatch(setstateparticipent(""));
        }
        dispatch(
          setfields({
            ...fields,
            [fieldNmae]: { name: e.itemData.name, id: e.itemData.id },
            isChange: true,
          })
        );
      } else if (onChange) {
        dispatch(IsChangefunction(true));
        setfields({
          ...fields,
          [fieldNmae]: { name: e.itemData.name, id: e.itemData.id },
        });
      } else if (setxmldata) {
        setxmldata(e.itemData?.sData);
        setisdefault(e.itemData?.bDefault);
        setfields({
          ...fields,
          [fieldNmae]: { name: e.itemData.name, id: e.itemData.id },
        });
      } else if (setfilterdata) {
        setfilterdata(e.itemData?.data);
        setfields({
          ...fields,
          [fieldNmae]: { name: e.itemData.name, id: e.itemData.id },
        });
      } else {
        setfields({
          ...fields,
          [fieldNmae]: { name: e.itemData.name, id: e.itemData.id },
        });
      }
    }
  };

  return (
    <SelectBox
      dataSource={data}
      valueExpr="name"
      disabled={disabled}
      value={value}
      displayExpr="name"
      searchEnabled={true}
      searchExpr={"name"}
      dropDownOptions={{ height: "180px" }}
      onItemClick={handleRowClick}
      style={{
        marginTop: margin ? margin : "3.5%",
        width: width && width,
        border: stateparticipent && "3px solid red",
      }}
      searchMode={searchMode || "contains"}
    />
  );
};
