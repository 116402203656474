import { useEffect, useState } from "react";
import { AllAttachmentFiles } from "./AllAttachmentFiles";
import "./Attachment.css";
import { Photos } from "./Photos";
import { Popup } from "devextreme-react";
import { AttachFiles } from "./AttachFiles";
import { useRef } from "react";
import axios from "axios";
import { handleGetLocal } from "../../../../services/auth-service";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setresetReportDates } from "../../../../reducer/participantNoteReducer";
import { Toster } from "../../../../components/Toster/Toster";
import { logOut } from "../../../../components/Logout";
import { useNavigate } from "react-router-dom";

const AttachFilesPopUp = ({
  AttachFielPopup,
  setAttachFielPopup,
  fielsName,
  inputRef,
  setfielsName,
  attachmentCategories,
  userinfo,
  toastConfig,
  setToastConfig,
  attachmentfunction,
  getphotoTableData
}) => {
  const dispatch = useDispatch();
  const { resetReportDates } = useSelector((store) => store.participateNotes);
  return (
    <>
      <Popup
        visible={userinfo?.gPersonId && AttachFielPopup}
        onHiding={() => {
          dispatch(setresetReportDates(!resetReportDates));
          inputRef.current.value = "";
          setAttachFielPopup(false);
          setfielsName([]);
        }}
        showTitle={false}
        width={700}
        height={650}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AttachFiles
          AttachFielPopup={AttachFielPopup}
          setAttachFielPopup={setAttachFielPopup}
          fielsName={fielsName}
          inputRef={inputRef}
          setfielsName={setfielsName}
          attachmentCategories={attachmentCategories}
          userinfo={userinfo}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          attachmentfunction={attachmentfunction}
          getphotoTableData={getphotoTableData}
        />
      </Popup>
    </>
  );
};

export const Attachment = ({
  userinfo,
  dataa,
  loadinggg,
  attachmentfunction,
}) => {
  const [tabSwitch, settabSwitch] = useState(
    localStorage.getItem("atachmentTab")
      ? () => {
          localStorage.removeItem("atachmentTab");
          return 2;
        }
      : 1
  );
  const [AttachFielPopup, setAttachFielPopup] = useState(false);
  const [fielsName, setfielsName] = useState([]);
  const inputRef = useRef(null);
  const pathname = handleGetLocal("pathURL");
  const [attachmentCategories, setattachmentCategories] = useState([]);
  const dispatch = useDispatch();
  const { on_Save_Edit, resetReportDates } = useSelector(
    (store) => store.participateNotes
  );
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const [loadingg, setLoadingg] = useState(false);
  const [allimages, setallimages] = useState([]);
  const navigate = useNavigate();

  const getalldropdown = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Person/masterDropDownValuesGet`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setattachmentCategories(
          val?.data.data.attachmentCategories.map((vall) => {
            return { name: vall?.value, id: vall?.key };
          })
        );
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const getphotoTableData = async () => {
    setLoadingg(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/attachments/get-attachments?recordId=${userinfo?.gPersonId}&imagesOnly=true`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoadingg(false);
        setallimages(
          val.data.data.map((val) => {
            return { image: val?.attachment, id: val?.binaryDataId };
          })
        );
      })
      .catch((val) => {
        setLoadingg(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    if (userinfo?.gPersonId) {
      getalldropdown();
    }
  }, [userinfo?.gPersonId]);

  useEffect(() => {
    if (userinfo?.gPersonId) {
      getphotoTableData();
    }
  }, [userinfo?.gPersonId]);

  return (
    <>
      <Toster
        message={toastConfig.message}
        type={toastConfig.type}
        visible={toastConfig.isVisible}
      />
      <AttachFilesPopUp
        AttachFielPopup={AttachFielPopup}
        setAttachFielPopup={setAttachFielPopup}
        fielsName={fielsName}
        inputRef={inputRef}
        setfielsName={setfielsName}
        attachmentCategories={attachmentCategories}
        userinfo={userinfo}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        attachmentfunction={attachmentfunction}
        getphotoTableData={getphotoTableData}
      />
      <div className="attachment">
        <div className="AllAttachmentFiles_component_button">
          <button
            onClick={() => {
              dispatch(setresetReportDates(!resetReportDates));
              setAttachFielPopup(true);
            }}
            style={{ position: "relative" }}
          >
            {userinfo?.gPersonId && (
              <input
                type="file"
                multiple
                ref={inputRef}
                id="fileInput"
                onChange={(e) => {
                  if (
                    e.target.files[0]?.name?.includes(".exe") ||
                    e.target.files[0]?.name?.includes(".7z") ||
                    e.target.files[0]?.name?.includes(".rar") ||
                    e.target.files[0]?.name?.includes(".tar") ||
                    e.target.files[0]?.name?.includes(".zip") ||
                    e.target.files[0]?.name?.includes(".jar")
                  ) {
                    return;
                  }
                  const files = Array.from(e.target.files);

                  Promise.all(
                    files.map((file) => {
                      return new Promise((resolve, reject) => {
                        const reader = new FileReader();

                        reader.onload = () => {
                          const base64String = reader.result.split(",")[1];
                          resolve(base64String);
                        };

                        reader.onerror = (error) => {
                          reject(error);
                        };

                        reader.readAsDataURL(file);
                      });
                    })
                  )
                    .then((base64Array) => {
                      setfielsName(
                        base64Array.map((val, i) => {
                          return {
                            fileName: files[i].name,
                            doc: val,
                            isImage:
                              files[i].name.includes("jpg") ||
                              files[i].name.includes("png") ||
                              files[i].name.includes("jpeg")
                                ? true
                                : false,
                          };
                        })
                      );
                    })
                    .catch((error) => {
                      console.error(error);
                    });

                  if (inputRef.current) {
                    inputRef.current.value = "";
                  }
                }}
                className="inputFieleHide"
              />
            )}
            <p style={{ cursor: "pointer" }}>Attach files</p>
            <svg
              className="svg-black-white"
              width="8"
              height="16"
              viewBox="0 0 8 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.9974 4.49977V11.5531C6.9974 12.9464 5.9774 14.1864 4.59073 14.3198C2.9974 14.4731 1.66406 13.2264 1.66406 11.6664V3.42644C1.66406 2.55311 2.29073 1.75977 3.1574 1.67311C4.1574 1.57311 4.9974 2.35311 4.9974 3.33311V10.3331C4.9974 10.6998 4.6974 10.9998 4.33073 10.9998C3.96406 10.9998 3.66406 10.6998 3.66406 10.3331V4.49977C3.66406 4.22644 3.4374 3.99977 3.16406 3.99977C2.89073 3.99977 2.66406 4.22644 2.66406 4.49977V10.2398C2.66406 11.1131 3.29073 11.9064 4.1574 11.9931C5.1574 12.0931 5.9974 11.3131 5.9974 10.3331V3.44644C5.9974 2.05311 4.9774 0.813105 3.59073 0.679772C2.00406 0.526438 0.664062 1.77311 0.664062 3.33311V11.5131C0.664062 13.4264 2.06406 15.1398 3.97073 15.3198C6.16406 15.5198 7.9974 13.8131 7.9974 11.6664V4.49977C7.9974 4.22644 7.77073 3.99977 7.4974 3.99977C7.22406 3.99977 6.9974 4.22644 6.9974 4.49977Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
        <div className="attachment_sub_tabs">
          <div
            onClick={() => {
              settabSwitch(1);
            }}
            style={{
              backgroundColor: tabSwitch === 1 && "var(--tab-light-dark)",
            }}
            className="attachment_sub_tabsAll_Attachments"
          >
            <svg
              className="svg-black-white"
              width="8"
              height="16"
              viewBox="0 0 8 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.9974 4.49977V11.5531C6.9974 12.9464 5.9774 14.1864 4.59073 14.3198C2.9974 14.4731 1.66406 13.2264 1.66406 11.6664V3.42644C1.66406 2.55311 2.29073 1.75977 3.1574 1.67311C4.1574 1.57311 4.9974 2.35311 4.9974 3.33311V10.3331C4.9974 10.6998 4.6974 10.9998 4.33073 10.9998C3.96406 10.9998 3.66406 10.6998 3.66406 10.3331V4.49977C3.66406 4.22644 3.4374 3.99977 3.16406 3.99977C2.89073 3.99977 2.66406 4.22644 2.66406 4.49977V10.2398C2.66406 11.1131 3.29073 11.9064 4.1574 11.9931C5.1574 12.0931 5.9974 11.3131 5.9974 10.3331V3.44644C5.9974 2.05311 4.9774 0.813105 3.59073 0.679772C2.00406 0.526438 0.664062 1.77311 0.664062 3.33311V11.5131C0.664062 13.4264 2.06406 15.1398 3.97073 15.3198C6.16406 15.5198 7.9974 13.8131 7.9974 11.6664V4.49977C7.9974 4.22644 7.77073 3.99977 7.4974 3.99977C7.22406 3.99977 6.9974 4.22644 6.9974 4.49977Z"
                fill="black"
              />
            </svg>
            <p>All Attachments ({dataa.length})</p>
          </div>
          <div
            style={{
              backgroundColor: tabSwitch === 2 && "var(--tab-light-dark)",
            }}
            onClick={() => {
              settabSwitch(2);
            }}
            className="attachment_sub_tabs_photo"
          >
            <svg
              className="svg-black-white "
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.66927 2.33301C1.66927 1.96634 1.96927 1.66634 2.33594 1.66634H5.66927C6.03594 1.66634 6.33594 1.36634 6.33594 0.999674C6.33594 0.633008 6.03594 0.333008 5.66927 0.333008H1.66927C0.935938 0.333008 0.335938 0.933008 0.335938 1.66634V5.66634C0.335938 6.03301 0.635938 6.33301 1.0026 6.33301C1.36927 6.33301 1.66927 6.03301 1.66927 5.66634V2.33301ZM5.40927 7.99301L3.43594 10.4597C3.2626 10.6797 3.41594 10.9997 3.69594 10.9997H10.3359C10.6093 10.9997 10.7693 10.6863 10.6026 10.4663L9.26927 8.68634C9.13594 8.50634 8.86927 8.50634 8.73594 8.68634L7.64927 10.1397L5.92927 7.99301C5.79594 7.82634 5.5426 7.82634 5.40927 7.99301ZM10.3359 4.66634C10.3359 4.11301 9.88927 3.66634 9.33594 3.66634C8.7826 3.66634 8.33594 4.11301 8.33594 4.66634C8.33594 5.21967 8.7826 5.66634 9.33594 5.66634C9.88927 5.66634 10.3359 5.21967 10.3359 4.66634ZM12.3359 0.333008H8.33594C7.96927 0.333008 7.66927 0.633008 7.66927 0.999674C7.66927 1.36634 7.96927 1.66634 8.33594 1.66634H11.6693C12.0359 1.66634 12.3359 1.96634 12.3359 2.33301V5.66634C12.3359 6.03301 12.6359 6.33301 13.0026 6.33301C13.3693 6.33301 13.6693 6.03301 13.6693 5.66634V1.66634C13.6693 0.933008 13.0693 0.333008 12.3359 0.333008ZM12.3359 11.6663C12.3359 12.033 12.0359 12.333 11.6693 12.333H8.33594C7.96927 12.333 7.66927 12.633 7.66927 12.9997C7.66927 13.3663 7.96927 13.6663 8.33594 13.6663H12.3359C13.0693 13.6663 13.6693 13.0663 13.6693 12.333V8.33301C13.6693 7.96634 13.3693 7.66634 13.0026 7.66634C12.6359 7.66634 12.3359 7.96634 12.3359 8.33301V11.6663ZM1.0026 7.66634C0.635938 7.66634 0.335938 7.96634 0.335938 8.33301V12.333C0.335938 13.0663 0.935938 13.6663 1.66927 13.6663H5.66927C6.03594 13.6663 6.33594 13.3663 6.33594 12.9997C6.33594 12.633 6.03594 12.333 5.66927 12.333H2.33594C1.96927 12.333 1.66927 12.033 1.66927 11.6663V8.33301C1.66927 7.96634 1.36927 7.66634 1.0026 7.66634Z"
                fill="black"
              />
            </svg>

            <p>Photos ({allimages.length})</p>
          </div>
        </div>
        <div>
          {tabSwitch == 1 ? (
            <AllAttachmentFiles
              data={dataa}
              loading={loadinggg}
              userinfo={userinfo}
              attachmentCategories={attachmentCategories}
              attachmentfunction={attachmentfunction}
              getphotoTableData={getphotoTableData}
            />
          ) : (
            <Photos
              loadingg={loadingg}
              allimages={allimages}
              userinfo={userinfo}
              attachmentfunction={attachmentfunction}
              getphotoTableData={getphotoTableData}
            />
          )}
        </div>
      </div>
    </>
  );
};
