import React, { useEffect, useState } from 'react'
import { DynamicTabb } from '../../components/DynamicTab/DynamicTab';
import { Table2 } from '../../components/Table2/Table2';
import { Tablecoloum } from "../revenue-report/Data";
import { useNavigate } from 'react-router-dom';
import { setTableData } from "../revenue-report/Function";
import { logOut } from "../../components/Logout";
import { handleGetLocal } from '../../services/auth-service';
import axios from 'axios';

function RevenueSummary() {
    const pathname = handleGetLocal("pathURL");
    const [exportPopupOpen, setexportPopupOpen] = useState(false);
    const [data, setdata] = useState([]);
    const [loading, setloading] = useState(false);
    const [revenueRowData, setRevenueRowData] = useState([]);
    const [date, setdate] = useState({
        startDate: new Date(new Date().setDate(1)).toISOString().slice(0, 10),
        endDate: new Date().toISOString().slice(0, 10),
    });
    const navigate = useNavigate();

    const getRevenueReportList = async () => {
        setloading(true);
        await axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/Revenue/get-revenue-report?startDate=${date.startDate}&endDate=${date.endDate}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                        Domain: `${pathname}`,
                    },
                }
            )
            .then((val) => {
                setdata(setTableData(val.data.data));
                setloading(false);
            })
            .catch((val) => {
                setloading(false);
                if (!val?.response) {
                    navigate(`/error/500`);
                } else if (val?.response?.status == 401) {
                    logOut();
                }
            });
    };

    useEffect(() => {
        getRevenueReportList();
    }, []);

    return (
        <>
            <div className="arrearageminderMainTabWrapper">
                <div className="tabwapper">
                    <DynamicTabb />
                    <div className="tab-main-box">
                        <div className="tab-box" id="tab-2" style={{ display: "block" }}>
                            <div className="tabcontent">
                                <div className="screen_num">
                                    <p>EM.30</p>
                                </div>
                            </div>
                        </div>
                        <div className="Detail_Contact_Tab">
                            <div style={{ marginTop: "1.5%" }}>
                                <Table2
                                    Tablecoloum={Tablecoloum}
                                    tableRow={data}
                                    load={loading}
                                    coloumWidthText={"RevenueReport"}
                                    height={750}
                                    editTable={false}
                                    mandatoryColoum={"Category"}
                                    showTotalBlock={true}
                                    exportInDifferentFormate={true}
                                    exportPopupOpen={exportPopupOpen}
                                    setexportPopupOpen={setexportPopupOpen}
                                    exportwidth={16.3}
                                    exporttitle={"revenue report"}
                                    docketlisthiperline={true}
                                    revenueReportMenu={true}
                                    revenueReportTotal={true}
                                    revenueRowData={revenueRowData}
                                    setRevenueRowData={setRevenueRowData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RevenueSummary;