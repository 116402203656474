import { useEffect } from "react";
import { detail_Notification_Tab_getData } from "../../../../reducer/ParticipateReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { handleGetLocal } from "../../../../services/auth-service";
import { useState } from "react";
import { useRef } from "react";
import LoadPanel from "devextreme-react/load-panel";
import { TextArea, Toast } from "devextreme-react";
import { MultiColumnDropdown } from "./Dropdown";
import { Toster } from "../../../../components/Toster/Toster";
import { logOut } from "../../../../components/Logout";

export const Detail_Notification_Tab = ({ editable, userinfo }) => {
  const { Detail_Notification_Tab_getData } = useSelector(
    (store) => store.participate
  );

  const [loading, setLoading] = useState(false);

  const idd = useParams();
  const pathname = handleGetLocal("pathURL");

  const dispatch = useDispatch();

  const [email, setEmail] = useState([]);
  const [text, settext] = useState([]);

  const [emailsend, setemailsend] = useState({});
  const [textsend, settextsend] = useState({});
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const navigate = useNavigate();

  const getalldropdown = async (persionId) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Person/getEmailandCellNoListForNotification?gPersonId=${persionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        setEmail(val?.data?.data?.email);
        settext(
          val?.data?.data?.text.map((val) => {
            return {
              sInformation: val.sInformation
                .replace(/^\+1/, "")
                .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"),
              sComment: val.sComment,
              gPersonContactInfoID: val?.gPersonContactInfoID,
            };
          })
        );
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const SendMessage = async (mediam) => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Notification/ParticipantsDetailsNotification`,
        {
          OffenderNumber: idd.id,
          AlertNote: "",
          EmailAddress: mediam === "email" ? emailsend?.Email : "",
          CellNumber:
            mediam === "phone" ? textsend?.Cell?.replace(/\D/g, "") : "",
          Medium: mediam,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        if (val.data.isSuccess) {
          setLoading(false);
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: `Message send Successfully!`,
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: `Message send Successfully!`,
              type: "success",
            });
          }, 1400);
        } else {
          setLoading(false);
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: `Please Select Correct Email!`,
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: `Please Select Correct Email!`,
              type: "error",
            });
          }, 1400);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    getalldropdown(userinfo?.gPersonId);
  }, [idd.id, userinfo?.gPersonId]);

  return (
    <>
      <div>
        <LoadPanel shadingColor="true" visible={loading} delay={10} />
        <Toster
          message={toastConfig.message}
          type={toastConfig.type}
          visible={toastConfig.isVisible}
        />
        <div className="Detail_Notification_Tab_sub">
          <div className="Detail_Notification_Tab_sub_">
            <div style={{ marginTop: "-10px" }} className="input_block">
              <p>Alert Note</p>

              <TextArea
                style={{ marginTop: "3%" }}
                height={210}
                disabled={!editable}
                value={Detail_Notification_Tab_getData?.AlertNote}
                onValueChange={(e) => {
                  dispatch(
                    detail_Notification_Tab_getData({
                      ...Detail_Notification_Tab_getData,
                      AlertNote: e,
                      isChange: true,
                    })
                  );
                }}
                placeholder={"  Alert Note"}
              />
            </div>
          </div>
          <div className="Detail_Notification_Tab_sub_">
            <div
              style={{ width: "100%", marginTop: "5%" }}
              className="Detail_Address_Tab_mail_address"
            >
              <div
                style={{
                  display: "flex",
                  background: "var(--btn-grey-black)",
                  height: "48px",
                }}
              >
                <svg
                  className="svg-black-white"
                  style={{ marginTop: "14px", marginLeft: "7%" }}
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM17.6 4.25L11.06 8.34C10.41 8.75 9.59 8.75 8.94 8.34L2.4 4.25C2.15 4.09 2 3.82 2 3.53C2 2.86 2.73 2.46 3.3 2.81L10 7L16.7 2.81C17.27 2.46 18 2.86 18 3.53C18 3.82 17.85 4.09 17.6 4.25Z"
                    fill="black"
                  />
                </svg>
                <p style={{ marginTop: "12px", marginLeft: "5%" }}>
                  Email Notification
                </p>
              </div>
              <div className="new_data">
                <p>Email Address</p>
                <div>
                  <MultiColumnDropdown
                    editable={editable}
                    text={"Email"}
                    email={email}
                    value={userinfo?.gPersonEmailContactInfoId}
                    setsend={setemailsend}
                  />
                </div>
                <div>
                  <button
                    style={{
                      width: "100%",
                      backgroundColor: "var(--button2)",
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      emailsend?.Email && SendMessage("email");
                    }}
                  >
                    Send Test Email Message
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="Detail_Notification_Tab_sub_">
            <div
              style={{ width: "100%", marginTop: "5%" }}
              className="Detail_Address_Tab_mail_address"
            >
              <div
                style={{
                  display: "flex",
                  background: "var(--btn-grey-black)",
                  height: "48px",
                }}
              >
                <svg
                  className="svg-black-white"
                  style={{ marginTop: "14px", marginLeft: "7%" }}
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM17.6 4.25L11.06 8.34C10.41 8.75 9.59 8.75 8.94 8.34L2.4 4.25C2.15 4.09 2 3.82 2 3.53C2 2.86 2.73 2.46 3.3 2.81L10 7L16.7 2.81C17.27 2.46 18 2.86 18 3.53C18 3.82 17.85 4.09 17.6 4.25Z"
                    fill="black"
                  />
                </svg>
                <p style={{ marginTop: "12px", marginLeft: "5%" }}>
                  Text Notification
                </p>
              </div>
              <div className="new_data">
                <p>Cell Number</p>
                <div>
                  <MultiColumnDropdown
                    editable={editable}
                    text={"Cell"}
                    email={text}
                    value={userinfo?.gPersonTextContactInfoId}
                    setsend={settextsend}
                  />
                </div>
                <div>
                  <button
                    style={{
                      width: "100%",
                      backgroundColor: "var(--button2)",
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      textsend?.Cell && SendMessage("phone");
                    }}
                  >
                    Send Test Text Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
