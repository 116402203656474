import { Popup } from "devextreme-react";
import { Table2 } from "../../../../../components/Table2/Table2";
import { useState } from "react";
import { AddRevocationsPopup } from "./AddRevocationsPopup";
import { EditRevocationsPopup } from "./EditRevocationsPopup";
import { ApiError } from "../../../../Error/ApiError";
import { VoidPopup } from "../VoidPopup";
import { ReactComponent as AddIcon } from "../../../../../assets/images/icons/add.svg";

const Tablecoloum = [
  { title: "", moreoption: true, customwidth: "50px" },
  {
    title: "Appearance Type",
  },
  { title: "Docket" },
  {
    title: "Hearing Date",
    date: true,
  },
  { title: "Court" },
  {
    title: "Judge",
  },
  {
    title: "Disposition",
  },
  {
    title: "Violation",
  },
  {
    title: "Void",
    bool: true,
  },
];

const AddRevocations = ({
  AddRevocationspopup,
  setAddRevocationspopup,
  docketDropdownData,
  dropdownData,
  toastConfig,
  setToastConfig,
  getRevocationsDataApi,
  dockertDetail,
  setReset,
  reset,
  GoToRevocationDocketDisable,
}) => {
  return (
    <Popup
      visible={AddRevocationspopup}
      onHiding={() => {
        setReset(!reset);
        setAddRevocationspopup(false);
      }}
      showTitle={false}
      width={730}
      height={620}
      showCloseButton={true}
      hideOnOutsideClick={true}
    >
      <AddRevocationsPopup
        AddRevocationspopup={AddRevocationspopup}
        setAddRevocationspopup={setAddRevocationspopup}
        docketDropdownData={docketDropdownData}
        reset={reset}
        setReset={setReset}
        dropdownData={dropdownData}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        getRevocationsDataApi={getRevocationsDataApi}
        dockertDetail={dockertDetail}
        GoToRevocationDocketDisable={GoToRevocationDocketDisable}
      />
    </Popup>
  );
};

const EditRevocations = ({
  editRevocationspopup,
  seteditRevocationspopup,
  docketDropdownData,
  dropdownData,
  toastConfig,
  setToastConfig,
  getRevocationsDataApi,
  getEDitdataRevocations,
  GoToRevocationDocketDisable,
}) => {
  const [reset, setReset] = useState(false);

  if (!getEDitdataRevocations) {
    return;
  }

  return (
    <Popup
      visible={editRevocationspopup}
      onHiding={() => {
        setReset(!reset);
        seteditRevocationspopup(false);
      }}
      showTitle={false}
      width={730}
      height={620}
      showCloseButton={true}
      hideOnOutsideClick={true}
    >
      <EditRevocationsPopup
        editRevocationspopup={editRevocationspopup}
        seteditRevocationspopup={seteditRevocationspopup}
        docketDropdownData={docketDropdownData}
        reset={reset}
        setReset={setReset}
        dropdownData={dropdownData}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        getRevocationsDataApi={getRevocationsDataApi}
        getEDitdataRevocations={getEDitdataRevocations}
        GoToRevocationDocketDisable={GoToRevocationDocketDisable}
      />
    </Popup>
  );
};

const Void = ({
  setVoidPopup,
  voidPopup,
  toastConfig,
  setToastConfig,
  setLoad,
  getTollingDataApi,
  userinfo,
  getData,
}) => {
  return (
    <Popup
      visible={getData?.gRevocationId && voidPopup}
      onHiding={() => {
        setVoidPopup(false);
      }}
      showTitle={false}
      width={800}
      height={450}
      showCloseButton={true}
      hideOnOutsideClick={true}
    >
      <VoidPopup
        setVoidPopup={setVoidPopup}
        voidHeading="Void Revocation"
        voidSubHeading="Void Revocation"
        setLoad={setLoad}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        filedId={getData?.gRevocationId}
        fieldName="recordId"
        apiURl="/revocation/void-revocation"
        refreshApi={getTollingDataApi}
        // Tollingheading={`Void Tolling ${getData?.Start} - ${getData?.End}`}
        userinfo={userinfo}
        comment="Comments"
      />
    </Popup>
  );
};

export const Revocations = ({
  docketDropdownData,
  revocationsgData,
  Revocationserrorhandlig,
  dropdownData,
  toastConfig,
  setToastConfig,
  getRevocationsDataApi,
  userinfo,
  dockertDetail,
  GoToRevocationDocketDisable,
}) => {
  const [AddRevocationspopup, setAddRevocationspopup] = useState(false);
  const [editRevocationspopup, seteditRevocationspopup] = useState(false);
  const [getEDitdataRevocations, setgetEDitdataRevocations] = useState({});
  const [load, setLoad] = useState(false);
  const [reset, setReset] = useState();
  const [voidPopup, setVoidPopup] = useState(false);
  return (
    <>
      <AddRevocations
        AddRevocationspopup={AddRevocationspopup}
        setAddRevocationspopup={setAddRevocationspopup}
        docketDropdownData={docketDropdownData}
        dropdownData={dropdownData}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        getRevocationsDataApi={getRevocationsDataApi}
        dockertDetail={dockertDetail}
        setReset={setReset}
        reset={reset}
        GoToRevocationDocketDisable={GoToRevocationDocketDisable}
      />
      <EditRevocations
        editRevocationspopup={editRevocationspopup}
        seteditRevocationspopup={seteditRevocationspopup}
        docketDropdownData={docketDropdownData}
        dropdownData={dropdownData}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        getRevocationsDataApi={getRevocationsDataApi}
        getEDitdataRevocations={getEDitdataRevocations}
        GoToRevocationDocketDisable={GoToRevocationDocketDisable}
      />
      <Void
        setVoidPopup={setVoidPopup}
        voidPopup={voidPopup}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        setLoad={setLoad}
        getTollingDataApi={getRevocationsDataApi}
        userinfo={userinfo}
        getData={getEDitdataRevocations}
      />
      {Revocationserrorhandlig ? (
        <ApiError />
      ) : (
        <div style={{ marginTop: "1%" }}>
          <div className="buttons">
            <button
              className="btn"
              onClick={() => {
                setReset(!reset);
                setAddRevocationspopup(true);
              }}
            >
              Add Revocation
              <AddIcon className="svg-blue-white" />
            </button>
          </div>
          <div className="Revocationstable">
            <Table2
              Tablecoloum={Tablecoloum}
              tableRow={revocationsgData}
              editTable={false}
              load={false}
              coloumWidthText={"Revocationstable"}
              height={620}
              mandatoryColoum={"Hearing Date"}
              showTotalBlock={false}
              Revocationscontextmenu={true}
              seteditRevocationspopup={seteditRevocationspopup}
              setgetEDitdataRevocations={setgetEDitdataRevocations}
              setVoidPopup={setVoidPopup}
              setVoideFalse={true}
            />
          </div>
        </div>
      )}
    </>
  );
};
