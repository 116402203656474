import { useState } from "react";
import { Table1 } from "../../../../Administration/Table1/Table1";
import "../../contacts.css";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { setContectEntityListTableAddData2 } from "../../../../../reducer/AdminReadReducer";
import { useNavigate, useParams } from "react-router-dom";
import { ContactsFunction } from "../../ContactsFunction";

const entityListLeftTableColumn = [
  {
    title: "Entity",
    editable: true,
    dropdown: true,
    requiredColoumWhileEdit: true,
  },
  {
    title: "Position",
    editable: true,
    dropdown1: true,
    requiredColoumWhileEdit: true,
  },
  { title: "Active", bool: true, editable: true },
  { title: "Start", date: true, editable: true },
  { title: "End", date: true, editable: true },
  { title: "Primary", bool: true, editable: true },
];

const entityListRightTableColumn = [
  { title: "Select", bool: true, entityListReportCatalog: true },
  { title: "Report" },
  { title: "Report Type" },
];

const setTableDataa = (data) => {
  const arr = [];
  data?.map((val, i) => {
    arr.push({
      Entity: val?.Entity,
      Position: val?.Position,
      Active: val?.Active,
      Start: val?.Start,
      End: val?.End,
      Primary: val?.Primary ? val?.Primary : false,
      ID: val?.ID,
      gEntityContactId: val?.gEntityContactId,
      gEntityId: val?.gEntityId,
      gContactId: val?.gContactId,
      gContactPositionId: val?.gContactPositionId,
      sEntityName: val?.sEntityName,
    });
  });
  return arr;
};

const EntityList = ({
  isEditableButton,
  contactData,
  contactsEntityListDropdown,
  SetContactsEntityListDropdown,
  entityListRightData,
  setEntityListRightData,
  entityListLeftData,
  setEntityListLeftData,
  typeFeeSchedule,
  reportCatalogData,
}) => {
  const [loading, setLoading] = useState(false);
  const [tabledata, newrowData] = useState({ data: [], name: "" });
  const dispatch = useDispatch();
  const { ContectEntityListTableAddData2 } = useSelector(
    (store) => store.AdminTable
  );
  const [data, setdata] = useState([]);
  const [findContactEntitylistID, setFindContactEntitylistID] = useState("");
  const params = useParams();
  const apiClass = new ContactsFunction();

  useEffect(() => {
    const primaryEntity = ContectEntityListTableAddData2.find(
      (item) => item.Primary === true
    );
    setdata(setTableDataa(ContectEntityListTableAddData2));
    if (typeFeeSchedule === "Edit" && primaryEntity) {
      setFindContactEntitylistID(primaryEntity?.gEntityContactId);
    }
  }, [ContectEntityListTableAddData2]);

  return (
    <>
      <div className="contacts-entity-list">
        <div className="contacts-entity-list-left-table">
          <Table1
            height={400}
            Tablecoloum={entityListLeftTableColumn}
            tableRow={data}
            load={loading}
            coloumWidthText={"Entity"}
            mandatoryColoum={"Entity"}
            editTable={isEditableButton}
            contactsListEntity={contactsEntityListDropdown?.entity}
            contactsListPosition={contactsEntityListDropdown?.position}
            contactsEntityList={true}
            newrowData={newrowData}
            tabledata={tabledata}
            editParticipant={false}
            jumpContactsListToEntityList={true}
            setEntityListRightData={setEntityListRightData}
          />
        </div>
        <div className="contacts-entity-list-right-table">
          {typeFeeSchedule === "Edit" && findContactEntitylistID && (
            <Table1
              editTable={false}
              height={400}
              Tablecoloum={entityListRightTableColumn}
              tableRow={reportCatalogData?.map((val, i) => {
                return { ...val, ID: i };
              })}
              load={loading}
              coloumWidthText={"Report"}
              mandatoryColoum={"Report"}
              entityListRightData={entityListRightData}
              setEntityListRightData={setEntityListRightData}
              editEntiyListLeftData={isEditableButton}
              typeFeeSchedule={typeFeeSchedule}
              findContactEntitylistID={findContactEntitylistID}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EntityList;
