import { useState } from "react";
import { useEffect } from "react";

export const Block1 = ({ header }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="Block1">
      <div className="Block1_list">
        <p className="Block1_label">Case/Docket #</p>
        <div className="Block1_content" style={{position: 'relative'}}>
          <p className="Block1_value">
            {header?.sDocketNumberCourtCounts
              ? header?.sDocketNumberCourtCounts
              : -1}
          </p>
          <svg
            className="Block1_icon svg-blue-white"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <g id="info_outline">
              <path
                id="Vector"
                d="M7.33594 4.66732H8.66927V6.00065H7.33594V4.66732ZM8.0026 11.334C8.36927 11.334 8.66927 11.034 8.66927 10.6673V8.00065C8.66927 7.63398 8.36927 7.33398 8.0026 7.33398C7.63594 7.33398 7.33594 7.63398 7.33594 8.00065V10.6673C7.33594 11.034 7.63594 11.334 8.0026 11.334ZM8.0026 1.33398C4.3226 1.33398 1.33594 4.32065 1.33594 8.00065C1.33594 11.6807 4.3226 14.6673 8.0026 14.6673C11.6826 14.6673 14.6693 11.6807 14.6693 8.00065C14.6693 4.32065 11.6826 1.33398 8.0026 1.33398ZM8.0026 13.334C5.0626 13.334 2.66927 10.9407 2.66927 8.00065C2.66927 5.06065 5.0626 2.66732 8.0026 2.66732C10.9426 2.66732 13.3359 5.06065 13.3359 8.00065C13.3359 10.9407 10.9426 13.334 8.0026 13.334Z"
                fill="#424167"
              />
            </g>
          </svg>
          {isHovered && (
        <div
          style={{
            position: "absolute",
            top: "-60%", 
            left: "96%",
            transform: "translateX(-50%)",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "#fff",
            padding: "5px 10px",
            borderRadius: "4px",
            fontSize: "14px",
            whiteSpace: "nowrap",
          }}
        >
          {header?.sSentenceNotes}
        </div>
      )}
        </div>
      </div>
      <div className="Block1_list">
        <p className="Block1_label">Docket Ref #</p>
        <p className="Block1_value">{header?.sReferenceNumber}</p>
      </div>
      <div className="Block1_list">
        <p className="Block1_label">Supervisor</p>
        <p className="Block1_value">{header?.sPrimarySupervisor}</p>
      </div>
      <div className="Block1_list">
        <p className="Block1_label">Docket type</p>
        <p className="Block1_value">{header?.sDocketTypeName}</p>
      </div>
      
    </div>
  );
};

export const Block2 = ({ header }) => {
  return (
    <div className="Block2">
      <div className="Block1_list">
        <p className="Block2_label">Obligation Amount</p>
        <p className="Block2_value">
          {header.fAmount !== null
            ? Number(header.fAmount).toFixed(2) >= 0
              ? `$${Number(header.fAmount).toFixed(2)}`
              : `($${Math.abs(header.fAmount).toFixed(2)})`
            : "-"}
        </p>
      </div>
      <div className="Block1_list">
        <p className="Block2_label">Obligation Balance</p>
        <p className="Block2_value">
          {header.fBalance !== null
            ? Number(header.fBalance).toFixed(2) >= 0
              ? `$${Number(header.fBalance).toFixed(2)}`
              : `($${Math.abs(header.fBalance).toFixed(2)})`
            : "-"}
        </p>
      </div>
      <div className="Block1_list">
        <p className="Block2_label">Arrears</p>
        <p className="Block2_value">
          {header.fArrearsAdjusted !== null
            ? Number(header.fArrearsAdjusted).toFixed(2) >= 0
              ? `$${Number(header.fArrearsAdjusted).toFixed(2)}`
              : `($${Math.abs(header.fArrearsAdjusted).toFixed(2)})`
            : "-"}
        </p>
      </div>
      <div className="Block1_list">
        <p className="Block2_label">Court sentence date</p>
        <p className="Block2_value">
          {header.dCourtSentence
            ? new Date(header.dCourtSentence)
                .toLocaleDateString("en-US")
                .replace(/\//g, "/")
            : ""}
        </p>
      </div>
    </div>
  );
};

export const Block3 = ({ header }) => {
  return (
    <div className="Block3">
      <div className="Block1_list">
        <p className="Block1_label">Report Date</p>
        <p className="Block1_value">{header?.dReportDateTime}</p>
      </div>
      <div className="Block1_list">
        <p className="Block1_label">Location</p>
        <p className="Block1_value">{header?.sGpmlocationEntityName}</p>
      </div>
      <div className="Block1_list">
        <p className="Block1_label">Supervision Status</p>
        <p className="Block1_value">{header?.sSupervisionStatusName}</p>
      </div>
      <div className="Block1_list">
        <p className="Block1_label">as at</p>
        <p className="Block1_value">
          {header?.dSupervisionStatus
            ? new Date(header?.dSupervisionStatus)
                .toLocaleDateString("en-US")
                .replace(/\//g, "/")
            : ""}
        </p>
      </div>
    </div>
  );
};

export const Block4 = ({ header }) => {
  return (
    <div className="Block4">
      <div className="Block1_list">
        <p style={{ width: "80px" }}>Judge</p>
        <p
          style={{
            color: "var(--TextColor)",
            fontWeight: "600",
            marginLeft: "6px",
            textAlign: "left",
          }}
        >
          {header?.sJudgeName}
        </p>
      </div>
      <div className="Block1_list">
        <p style={{ width: "80px" }}>Court</p>
        <p
          style={{
            color: "var(--TextColor)",
            fontWeight: "600",
            marginLeft: "6px",
            textAlign: "left",
            width: "150px",
          }}
        >
          {header?.sCourtName}
        </p>
      </div>
      <div className="Block1_list">
        <p style={{ width: "80px" }}>offense</p>
        <p
          style={{
            color: "var(--TextColor)",
            fontWeight: "600",
            marginLeft: "6px",
            textAlign: "left",
          }}
        >
          {header?.sOffenseDescription}
        </p>
      </div>
      <div className="Block1_list">
        <p style={{ width: "80px" }}>Supervision</p>
        <p
          style={{
            color: "var(--TextColor)",
            fontWeight: "600",
            marginLeft: "6px",
            textAlign: "left",
          }}
        >
          {header?.sSentenceDateRange}
        </p>
      </div>
    </div>
  );
};

export const HeaderTab = ({ data, setbottomdynamictab }) => {
  const [tab, setTab] = useState(0);
  useEffect(() => {
    setbottomdynamictab(0);
  }, []);
  return (
    <>
      <div className="topTabb">
        {data.length
          ? data.map((val, i) => {
              return (
                <>
                  <div
                    onClick={() => {
                      setTab(i);
                      setbottomdynamictab(i);
                    }}
                    style={{
                      borderBottom: tab == i && "4px solid rgb(66, 65, 103)",
                    }}
                  >
                    <p
                      style={{
                        color: tab == i ? "var(--tab-blue-white)" : "",
                        padding: "0 1px",
                        margin: "16px",
                      }}
                    >
                      {val}
                    </p>
                  </div>
                </>
              );
            })
          : ""}
      </div>
    </>
  );
};

export const TopDynamicDocket = ({
  data,
  setTopDynamicDocketid,
  tab,
  setTab,
}) => {
  useEffect(() => {
    if (data.length) {
      setTopDynamicDocketid(
        data.sort((a, b) => new Date(a.dDoe) - new Date(b.dDoe))[tab].gDocketId
      );
    }
  }, [data]);
  return (
    <>
      <div className="topTabbb">
        <div className="TopDynamicDocket">
          {data
            .sort((a, b) => new Date(a.dDoe) - new Date(b.dDoe))
            .map((val, i) => {
              return (
                <>
                  <div
                    onClick={() => {
                      setTab(i);
                      setTopDynamicDocketid(val?.gDocketId);
                    }}
                    style={{
                      backgroundColor: tab === i && "var(--tab-light-dark)",
                    }}
                  >
                    <svg
                      className="svg-black-white"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="folder">
                        <path
                          id="Vector"
                          d="M6.66927 2.66602H2.66927C1.92927 2.66602 1.33594 3.25935 1.33594 3.99935V11.9993C1.33594 12.353 1.47641 12.6921 1.72646 12.9422C1.97651 13.1922 2.31565 13.3327 2.66927 13.3327H13.3359C13.6896 13.3327 14.0287 13.1922 14.2787 12.9422C14.5288 12.6921 14.6693 12.353 14.6693 11.9993V5.33268C14.6693 4.59268 14.0693 3.99935 13.3359 3.99935H8.0026L6.66927 2.66602Z"
                          fill="black"
                        />
                      </g>
                    </svg>

                    <p>
                      {val.sOffenseDescription
                        ? val.sOffenseDescription
                        : val?.gDocketLinkId
                        ? val?.sDocketNumberCourtCounts
                        : "Other"}
                      {" : "}
                      {val.dCourtSentence &&
                        new Date(val.dCourtSentence)
                          .toLocaleDateString("en-US")
                          .replace(/\//g, "/")}
                    </p>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};
