import { SelectBox, TextBox } from "devextreme-react";
import { useDispatch } from "react-redux";
import {
  setProntactEntitiesSubTabinvoicingData,
  setProntactEntitieschange,
} from "../../../../../reducer/AdminReadReducer";
import { useSelector } from "react-redux";

const InvoiceTemplate = [
  { name: "Standard", id: "" },
  { name: "Suppress Names", id: "" },
  { name: "Separate Client Pages", id: "" },
  { name: "Separate Client PDF Files", id: "" },
];

export const ProntactEntitiesSubTabInvoice = ({ EditTogel }) => {
  const { ProntactEntitiesSubTabinvoicingData } = useSelector(
    (store) => store.AdminTable
  );

  const dispatch = useDispatch();
  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "30%",
            marginTop: "10px",
          }}
        >
          <p style={{ fontWeight: "bold" }}>EMail Address List</p>
          <TextBox
            style={{ width: "60%" }}
            value={ProntactEntitiesSubTabinvoicingData.EMailAddressList}
            onValueChange={(e) => {
              dispatch(setProntactEntitieschange(true));
              dispatch(
                setProntactEntitiesSubTabinvoicingData({
                  ...ProntactEntitiesSubTabinvoicingData,
                  EMailAddressList: e,
                })
              );
            }}
            isValid={true}
            disabled={!EditTogel}
            placeholder={"EMail Address List"}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "30%",
            marginTop: "10px",
          }}
        >
          <p style={{ fontWeight: "bold" }}>BCC Address List</p>
          <TextBox
            style={{ width: "60%" }}
            disabled={!EditTogel}
            value={ProntactEntitiesSubTabinvoicingData.BCCAddressList}
            onValueChange={(e) => {
              dispatch(setProntactEntitieschange(true));
              dispatch(
                setProntactEntitiesSubTabinvoicingData({
                  ...ProntactEntitiesSubTabinvoicingData,
                  BCCAddressList: e,
                })
              );
            }}
            isValid={true}
            placeholder={"BCC Address List"}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "30%",
            marginTop: "10px",
          }}
        >
          <p style={{ fontWeight: "bold" }}>Invoice Template 1</p>

          <SelectBox
            dataSource={InvoiceTemplate}
            valueExpr="name"
            disabled={!EditTogel}
            value={ProntactEntitiesSubTabinvoicingData.InvoiceTemplet1?.name}
            displayExpr="name"
            searchEnabled={true}
            searchExpr={"name"}
            dropDownOptions={{ height: "180px" }}
            onItemClick={(e) => {
              dispatch(setProntactEntitieschange(true));
              dispatch(
                setProntactEntitiesSubTabinvoicingData({
                  ...ProntactEntitiesSubTabinvoicingData,
                  InvoiceTemplet1: { name: e.itemData.name, id: "" },
                })
              );
            }}
            placeholder={"Invoice Templet 1"}
            style={{ width: "60%" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "30%",
            marginTop: "10px",
          }}
        >
          <p style={{ fontWeight: "bold" }}>Invoice Template 2</p>

          <SelectBox
            dataSource={InvoiceTemplate}
            valueExpr="name"
            disabled={!EditTogel}
            value={ProntactEntitiesSubTabinvoicingData.InvoiceTemplet2?.name}
            displayExpr="name"
            searchEnabled={true}
            searchExpr={"name"}
            dropDownOptions={{ height: "180px" }}
            onItemClick={(e) => {
              dispatch(setProntactEntitieschange(true));
              dispatch(
                setProntactEntitiesSubTabinvoicingData({
                  ...ProntactEntitiesSubTabinvoicingData,
                  InvoiceTemplet2: { name: e?.itemData?.name, id: "" },
                })
              );
            }}
            placeholder={"Invoice Templet 2"}
            style={{ width: "60%" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "30%",
            marginTop: "10px",
          }}
        >
          <p style={{ fontWeight: "bold" }}>Invoice Template 3</p>

          <SelectBox
            dataSource={InvoiceTemplate}
            valueExpr="name"
            disabled={!EditTogel}
            value={ProntactEntitiesSubTabinvoicingData.InvoiceTemplet3?.name}
            displayExpr="name"
            searchEnabled={true}
            searchExpr={"name"}
            dropDownOptions={{ height: "180px" }}
            onItemClick={(e) => {
              dispatch(setProntactEntitieschange(true));
              dispatch(
                setProntactEntitiesSubTabinvoicingData({
                  ...ProntactEntitiesSubTabinvoicingData,
                  InvoiceTemplet3: { name: e?.itemData?.name, id: "" },
                })
              );
            }}
            placeholder={"Invoice Templet 3"}
            style={{ width: "60%" }}
          />
        </div>
      </div>
    </>
  );
};
