import React, { useEffect, useState } from "react";
import "./style/userData.css";
import AdminCrumbs from "./components/AdminCrumbs";
import UserData from "./UserData";
import { DynamicTabb } from "../../components/DynamicTab/DynamicTab";

const UserEdit = () => {
  const title = "Users";
  const [hit, setHit] = useState(true);

  useEffect(() => {
    setHit(!hit);
  }, []);

  return (
    <>
      <AdminCrumbs title={title} />
      <div className="admin_userdata">
        <DynamicTabb />
        <UserData type={"Edit"} hit={hit} />
      </div>
    </>
  );
};

export default UserEdit;
