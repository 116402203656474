import React, { useEffect, useState, useRef } from "react";
import "./user.css";
import image5 from "../../assets/images/image5.jpg";
import ResetPassword from "./ResetPassword/ResetPassword";
import httpService from "../../services/http-service";
import { getAuthToken, handleGetLocal } from "../../services/auth-service";
import { useSelector, useDispatch } from "react-redux";
import { set_user_name, set_user_img, updatedynamicTab } from "../../reducer/HomePageReducer";
import axios from "axios";
import { DynamicTabb } from "../../components/DynamicTab/DynamicTab";
import { Popup, TextBox } from "devextreme-react";
import { Toster } from "../../components/Toster/Toster";

const User = () => {
  const [isEditable, setisEditable] = useState(false);
  const { user_data, user_img, DynamicTab } = useSelector((store) => store.Home);
  const [file, setFile] = useState(user_img ? user_img : "");
  const [fields, setFields] = useState({
    image: "",
    userId: "",
    fName: "",
    mName: "",
    lName: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    twoFactAuth: null,
    TwoFactorAuthenticationCode: {
      mobile: null,
      mail: null,
    },
  });
  const [errors, setErrors] = useState({});
  const [editStatus, setEditStatus] = useState(true);
  const [isResetPass, setIsResetPass] = useState(false);
  const [image, setImage] = useState();
  const inputRef = useRef(null);
  const [imageUploadValidation, setimageUploadValidation] = useState("");
  const emailRegex = new RegExp("^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+)\\.([a-zA-Z]{2,})$");
  const [emailvalidaton, setemailvalidatoon] = useState("");

  const functionnn = async () => {
    const pathname = handleGetLocal("pathURL");
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Account/getUserInfoDetail?username=${localStorage.getItem("username")}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setFields({
          ...fields,
          address: val.data.data.sAddress1 ? val.data.data.sAddress1 : "",
          city: val.data.data.sCity ? val.data.data.sCity : "",
          email: val.data.data.sEmail,
          fName: val.data.data.sFirstName ? val.data.data.sFirstName : "",
          lName: val.data.data.sLastName ? val.data.data.sLastName : "",
          mName: val.data.data.sMiddleName ? val.data.data.sMiddleName : "",
          state: val.data.data.sState ? val.data.data.sState : "",
          twoFactAuth: val.data.data.bTwoFactorEnabled,
          userId: val.data.data.sUserId,
          zipcode: val.data.data.sZip ? val.data.data.sZip : "",
          TwoFactorAuthenticationCode: {
            mail: val.data.data.sTwoFactorEmail ? val.data.data.sTwoFactorEmail : "",
            mobile: val.data.data.sTwoFactorTextNumber ? val.data.data.sTwoFactorTextNumber : "",
          },
        });
      });
  };

  const SetValue = () => {
    setFields({
      ...fields,
      address: user_data.sAddress1,
      city: user_data.sCity,
      email: user_data.sEmail,
      fName: user_data.sFirstName,
      lName: user_data.sLastName,
      mName: user_data.sMiddleName,
      state: user_data.sState,
      twoFactAuth: user_data.bTwoFactorEnabled,
      userId: user_data.sUserId,
      zipcode: user_data.sZip,
      TwoFactorAuthenticationCode: {
        mail: user_data.sTwoFactorEmail ? user_data.sTwoFactorEmail : "",
        mobile: user_data.sTwoFactorTextNumber ? user_data.sTwoFactorTextNumber : "",
      },
    });
  };

  const cancelEdit = () => {
    setEditStatus(!editStatus);
    setisEditable(!isEditable);
    setErrors({});
    functionnn();
    getImg();
    setImage("");
    setFile("");
    setimageUploadValidation("");
    inputRef.current.value = "";
  };

  function handleImgChange(e) {
    const File = e;
    if (!e) {
      return;
    }
    let a = e.name;
    if (!(a.includes("jpg") || a.includes("png") || a.includes("jpeg"))) {
      setimageUploadValidation("Upload valid image");
      return;
    } else {
      var fileSize = e.size;

      // if (fileSize > 60000) {
      //   setimageUploadValidation("Image size must be 60 KB or smaller.");
      //   return false;
      // }

      setimageUploadValidation("");
      setImage(e);
    }
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      function () {
        setFile(reader.result);
      },
      false
    );

    if (File) {
      reader.readAsDataURL(File);
    }
  }

  const handleChange = (e) => {
    let field = fields,
      namePattern = /^[a-zA-Z,.'-]+$/,
      cityPattern = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;
    if (e.target.name == "userId") {
      if (field["userId"] == "") {
        errors["userId"] = "*Please enter valid user id";
      } else {
        errors["userId"] = "";
      }
    }
    if (e.target.name == "fName") {
      if (!namePattern.test(e.target.value)) {
        errors["fName"] = "*Please enter valid first name";
      } else {
        errors["fName"] = "";
      }
    }
    if (e.target.name == "lName") {
      if (!namePattern.test(e.target.value)) {
        errors["lName"] = "*Please enter valid last name";
      } else {
        errors["lName"] = "";
      }
    }
    if (e.target.name == "email") {
      var mailPattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (!mailPattern.test(e.target.value)) {
        errors["email"] = "*Please enter valid email";
      } else {
        errors["email"] = "";
      }
    }
    if (e.target.name == "address") {
      var addPattern = /^[a-zA-Z0-9\s,'-]*$/;
      if (!addPattern.test(e.target.value)) {
        errors["address"] = "Please enter valid address";
      } else {
        errors["address"] = "";
      }
    }
    if (e.target.name == "city") {
      if (!cityPattern.test(e.target.value)) {
        errors["city"] = "Please enter valid city name";
      } else {
        errors["city"] = "";
      }
    }
    if (e.target.name == "state") {
      if (!cityPattern.test(e.target.value)) {
        errors["state"] = "Please enter valid state name";
      } else {
        errors["state"] = "";
      }
    }
    if (e.target.name == "zipcode") {
      var zipcPattern = /[0-9]$/;
      if (!zipcPattern.test(e.target.value)) {
        errors["zipcode"] = "Please enter valid zip code";
      } else if (e.target.value.length < 5) {
        errors["zipcode"] = "Please enter valid zip code";
      } else {
        errors["zipcode"] = "";
      }
    }
    field[e.target.name] = e.target.value;
    setFields({ ...field });
  };

  const dispatch = useDispatch();

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "success",
    message: "Successfully Updated!",
  });

  const handleSaveDetails = async (e) => {
    const token = getAuthToken();
    const data = {
      gUserId: user_data.gUserId,
      dDoe: "0001-01-01T00:00:00",
      gEntryUserId: null,
      sUserId: fields.userId,
      sPassword: "Jaishiv@108",
      sFirstName: fields.fName,
      sLastName: fields.lName,
      sMiddleName: fields.mName,
      bActive: null,
      sProfileSkin: null,
      iColor: null,
      mPhoto: null,
      bShowCookie: false,
      gRoleId: null,
      bSysop: false,
      bResource: false,
      sEmployeeId: null,
      sEmail: fields.email,
      dLastLogin: null,
      sAddress1: fields.address,
      sAddress2: null,
      sCity: fields.city,
      sState: fields.state,
      sZip: fields.zipcode,
      sPhone: null,
      sNote: null,
      bCourt: null,
      gCashDrawerId: null,
      sReceiptPrinter: null,
      sTwoFactorEmail: fields.TwoFactorAuthenticationCode.mail,
      sTwoFactorTextNumber: fields.TwoFactorAuthenticationCode.mobile,
      bTwoFactorEnabled: fields.twoFactAuth,
      sTwoFactorVc: null,
      sTwoFactorVccreated: null,
      sTwoFactorVcexpired: null,
    };
    const pathname = handleGetLocal("pathURL");
    try {
      const response = await httpService.post("api/Account/saveUserInfoDetail", data, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      });
      if (response.data.isSuccess) {
        setToastConfig({
          ...toastConfig,
          isVisible: true,
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
          });
        }, 1400);

        dispatch(
          updatedynamicTab(
            DynamicTab.map((vall) => {
              if (vall.link === "/user-information") {
                return {
                  link: "/user-information",
                  title: `${fields.fName ? fields.fName : ""} ${fields.lName ? fields.lName : ""}`,
                };
              } else {
                return vall;
              }
            })
          )
        );

        dispatch(set_user_name(`${fields.fName ? fields.fName : ""} ${fields.lName ? fields.lName : ""}`));
      }
    } catch (error) {}
  };

  const uploadImg = async (e) => {
    const token = getAuthToken();
    const pathname = handleGetLocal("pathURL");
    const formData = new FormData();
    formData.append("image", e);
    try {
      const response = await httpService.post("api/Account/UploadImage", formData, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      });
      if (response.data) {
        dispatch(set_user_img(file));
      }
    } catch (error) {}
  };

  const getImg = async (e) => {
    const token = getAuthToken();
    const pathname = handleGetLocal("pathURL");
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Account/GetImage`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
        responseType: "blob",
      })
      .then((response) => {
        const reader = new FileReader();
        const imageBlob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        reader.onloadend = () => setFile(reader.result);
        reader.readAsDataURL(imageBlob);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    SetValue();
  }, []);

  const validateLogin = () => {
    let error = {};
    let formIsValid = true;
    let namePattern = /^[a-zA-Z,.'-]+$/;

    if (!fields["fName"] || fields["fName"].trim() == "") {
      formIsValid = false;
      error["fName"] = "*Please enter your email";
    }
    if (!namePattern.test(fields["fName"])) {
      formIsValid = false;
      error["fName"] = "*Please enter valid first name";
    }
    if (!fields["lName"] || fields["lName"].trim() == "") {
      formIsValid = false;
      error["lName"] = "*Please enter your last name";
    }
    if (!namePattern.test(fields["lName"])) {
      formIsValid = false;
      error["lName"] = "*Please enter valid last name";
    }

    return {
      errors: error,
      formIsValid: formIsValid,
    };
  };

  const validateLoginForm = () => {
    let response = validateLogin();
    setErrors(response.errors);
    return response.formIsValid;
  };

  const handleSubmit = async (e) => {
    if (!fields.twoFactAuth) {
      if (!emailvalidaton && !imageUploadValidation && validateLoginForm()) {
        setEditStatus(!editStatus);
        setisEditable(!isEditable);
        setErrors({});
        handleSaveDetails();
        image && uploadImg(image);
      }
    } else {
      if (fields.TwoFactorAuthenticationCode.mail && !emailvalidaton && !imageUploadValidation && validateLoginForm()) {
        setEditStatus(!editStatus);
        setisEditable(!isEditable);
        setErrors({});
        handleSaveDetails();
        image && uploadImg(image);
      }
    }
  };

  const handleReset = (data) => {
    setIsResetPass(data);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    handleImgChange(event.dataTransfer.files[0]);
  };

  return (
    <div>
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <div className="mainTabWrapper">
        <div className="tabwapper" style={{ marginTop: "23px" }}>
          <DynamicTabb />
          <div className="tab-main-box">
            <div className="tab-box" id="tab-2" style={{ display: "block" }}>
              <div className="tabcontent">
                <div
                  className="interaction flex"
                  style={{
                    alignItems: "center",
                  }}
                >
                  <div className="leftAction">
                    <p
                      style={{
                        marginBottom: "10px",
                        padding: "1px 12px",
                        gap: "8px",
                        width: "52px",
                        height: "20px",
                        background: "rgb(254, 242, 242)",
                        borderRadius: "4px",
                        color: "red",
                        fontSize: "10px",
                      }}
                    >
                      US.10
                    </p>
                    {!isEditable ? (
                      <button
                        id="myBtn"
                        className="btn btnlightblue"
                        style={{
                          width: "100%",
                        }}
                        onClick={() => setIsResetPass(!isResetPass)}
                      >
                        Reset Password
                        <svg
                          className="svg-blue-dark"
                          width="16"
                          height="21"
                          viewBox="0 0 16 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12ZM14 7H13V5C13 2.24 10.76 0 8 0C5.72 0 3.73 1.54 3.16 3.75C3.02 4.29 3.34 4.83 3.88 4.97C4.41 5.11 4.96 4.79 5.1 4.25C5.44 2.93 6.63 2 8 2C9.65 2 11 3.35 11 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM14 18C14 18.55 13.55 19 13 19H3C2.45 19 2 18.55 2 18V10C2 9.45 2.45 9 3 9H13C13.55 9 14 9.45 14 10V18Z"
                            fill="#424167"
                          />
                        </svg>
                      </button>
                    ) : (
                      <h3
                        style={{
                          fontSize: "24px",
                          color: "var(--TextColor)",
                        }}
                      >
                        Edit Details
                      </h3>
                    )}
                  </div>
                  <div className="rightAction">
                    {isEditable ? (
                      <div className="buttons">
                        <button className="btn" onClick={() => cancelEdit()}>
                          Cancel
                        </button>
                        <button className="primary_btn" onClick={handleSubmit}>
                          Save
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M16 10V15C16 15.55 15.55 16 15 16H3C2.45 16 2 15.55 2 15V10C2 9.45 1.55 9 1 9C0.45 9 0 9.45 0 10V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V10C18 9.45 17.55 9 17 9C16.45 9 16 9.45 16 10ZM10 9.67L11.88 7.79C12.27 7.4 12.9 7.4 13.29 7.79C13.68 8.18 13.68 8.81 13.29 9.2L9.7 12.79C9.31 13.18 8.68 13.18 8.29 12.79L4.7 9.2C4.31 8.81 4.31 8.18 4.7 7.79C5.09 7.4 5.72 7.4 6.11 7.79L8 9.67V1C8 0.45 8.45 0 9 0C9.55 0 10 0.45 10 1V9.67Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </div>
                    ) : (
                      <button
                        className="primary_btn"
                        onClick={() => {
                          setEditStatus(!editStatus);
                          setisEditable(!isEditable);
                          setErrors({});
                        }}
                      >
                        Edit
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M0 15.4601V18.5001C0 18.7801 0.22 19.0001 0.5 19.0001H3.54C3.67 19.0001 3.8 18.9501 3.89 18.8501L14.81 7.94006L11.06 4.19006L0.15 15.1001C0.0500001 15.2001 0 15.3201 0 15.4601ZM17.71 5.04006C18.1 4.65006 18.1 4.02006 17.71 3.63006L15.37 1.29006C14.98 0.900059 14.35 0.900059 13.96 1.290F06L12.13 3.12006L15.88 6.87006L17.71 5.04006Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
                <div className="userDetails">
                  <div className="profilePic">
                    <div className="avatar-upload">
                      <div className="avatar-edit">
                        <input
                          disabled={!isEditable}
                          type="file"
                          onChange={(e) => {
                            handleImgChange(e.target.files[0]);
                          }}
                          id="imageUpload"
                          ref={inputRef}
                          name="image"
                          accept=".png, .jpg, .jpeg"
                        />
                        {isEditable && <label for="imageUpload"></label>}
                      </div>
                      <div
                        onDragOver={(e) => {
                          isEditable && handleDragOver(e);
                        }}
                        onDrop={(e) => {
                          isEditable && handleDrop(e);
                        }}
                        className="avatar-preview"
                      >
                        {fields.image !== "" ? (
                          <div id="imagePreview">{file && <img src={file} />}</div>
                        ) : (
                          <div
                            id="imagePreview"
                            style={{
                              backgroundImage: `url(${file ? file : image5})`,
                              border: "1px solid #dbdbdb",
                              backgroundColor: "#dbdbdb",
                            }}
                          ></div>
                        )}
                        {imageUploadValidation && <p style={{ color: "red" }}>{imageUploadValidation}</p>}
                      </div>
                    </div>
                  </div>

                  <div className="infodetails userInfoDetails">
                    <div className={`fieldWrap ${errors?.userId ? " error " : ""} `}>
                      <label className="go-to-label-filed">
                        User ID <span style={{ fontSize: "17px" }}>*</span>
                      </label>
                      <input
                        disabled
                        type="text"
                        name="userId"
                        placeholder="John123"
                        onChange={(e) => handleChange(e)}
                        value={fields.userId}
                      />
                    </div>
                    <div className="fieldWrap">
                      <label className="go-to-label-filed">
                        First Name <span style={{ fontSize: "17px" }}>*</span>
                      </label>
                      <input
                        disabled={!isEditable}
                        type="text"
                        name="fName"
                        placeholder="First Name"
                        onChange={(e) => handleChange(e)}
                        value={fields.fName}
                      />
                      <p className="error" style={{ color: "red" }}>
                        {fields.fName ? errors.fName : "*Please enter the first name"}
                      </p>
                    </div>
                    <div className="fieldWrap">
                      <label className="go-to-label-filed">Middle Name</label>
                      <input
                        disabled={!isEditable}
                        type="text"
                        name="mName"
                        placeholder="Middle Name"
                        onChange={(e) => handleChange(e)}
                        value={fields.mName}
                      />
                      <p className="error" style={{ color: "red" }}>
                        {errors.mName}
                      </p>
                    </div>
                    <div className="fieldWrap">
                      <label className="go-to-label-filed">
                        Last Name <span style={{ fontSize: "17px" }}>*</span>
                      </label>
                      <input
                        disabled={!isEditable}
                        type="text"
                        name="lName"
                        placeholder="Last Name"
                        onChange={(e) => handleChange(e)}
                        value={fields.lName}
                      />
                      <p className="error" style={{ color: "red" }}>
                        {fields.lName ? errors.lName : "*Please enter the last name"}
                      </p>
                    </div>
                    <div className="fieldWrap">
                      <label className="go-to-label-filed">
                        Email <span style={{ fontSize: "17px" }}>*</span>
                      </label>
                      <input
                        disabled
                        type="text"
                        name="email"
                        placeholder="john@gmail.com"
                        onChange={(e) => handleChange(e)}
                        value={fields.email}
                      />
                      <p className="error" style={{ color: "red" }}>
                        {fields.email && errors.email}
                      </p>
                    </div>
                    <div className="fieldWrap">
                      <label className="go-to-label-filed">Address</label>
                      <input
                        disabled={!isEditable}
                        type="text"
                        name="address"
                        placeholder="Address"
                        onChange={(e) => handleChange(e)}
                        value={fields.address}
                      />
                      <p className="error" style={{ color: "red" }}>
                        {fields.address && errors.address}
                      </p>
                    </div>
                    <div className="fieldWrap">
                      <label className="go-to-label-filed">City</label>
                      <input
                        disabled={!isEditable}
                        type="text"
                        name="city"
                        placeholder="City"
                        onChange={(e) => handleChange(e)}
                        value={fields.city}
                      />
                      <p className="error" style={{ color: "red" }}>
                        {fields.city && errors.city}
                      </p>
                    </div>
                    <div className="fieldWrap">
                      <label className="go-to-label-filed">State</label>
                      <input
                        disabled={!isEditable}
                        type="text"
                        name="state"
                        placeholder="State"
                        onChange={(e) => handleChange(e)}
                        value={fields.state}
                      />
                      <p className="error" style={{ color: "red" }}>
                        {fields.state && errors.state}
                      </p>
                    </div>
                    <div className="fieldWrap">
                      <label className="go-to-label-filed">Zip</label>
                      <input
                        disabled={!isEditable}
                        type="text"
                        name="zipcode"
                        placeholder="Zip"
                        onChange={(e) => handleChange(e)}
                        value={fields.zipcode}
                      />
                      {fields.zipcode && (
                        <p className="error" style={{ color: "red" }}>
                          {errors.zipcode}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                {fields.twoFactAuth && (
                  <div className="authentication">
                    <div className="fieldWrap">
                      <div
                        className="checkBox"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          style={{
                            marginRight: "10px",
                            accentColor: "black",
                            height: "15px",
                            width: "15px",
                          }}
                          disabled={true}
                          defaultChecked={fields.twoFactAuth}
                          name="twoFactAuth"
                          type="checkbox"
                          id="factorauthentication"
                          placeholder=""
                        />
                        <label
                          style={{
                            color: "var(--TextColor)",
                            fontWeight: "500",
                          }}
                          for="factorauthentication"
                        >
                          Enable Two factor Authentication
                        </label>
                      </div>
                    </div>
                    <div className="selectMethod">
                      <div className="method">
                        <ul className="radioWithField">
                          <li className="option">
                            <label className="go-to-label-filed">
                              Mail <span style={{ fontSize: "17px" }}>*</span>
                            </label>
                            <input
                              disabled={!isEditable}
                              type="text"
                              placeholder="Email address"
                              value={fields.TwoFactorAuthenticationCode.mail}
                              className={"option-control"}
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  TwoFactorAuthenticationCode: {
                                    ...fields.TwoFactorAuthenticationCode,
                                    mail: e.target.value,
                                  },
                                });
                                setemailvalidatoon(emailRegex.test(e.target.value) ? "" : "Please enter valid email");
                              }}
                            />
                            <p style={{ color: "red" }}>
                              {fields.TwoFactorAuthenticationCode.mail ? emailvalidaton : "Please enter the email"}
                            </p>
                          </li>
                          <li className="option">
                            <label className="go-to-label-filed">Mobile</label>

                            <TextBox
                              style={{ marginTop: "5.5%", height: "43px" }}
                              validationError={false}
                              showMaskMode="always"
                              maskRules={{ H: /^[0-9*]$/ }}
                              mask="(HHH) HHH-HHHH"
                              isValid="true"
                              value={String(fields.TwoFactorAuthenticationCode.mobile).replace(/\s/g, "")}
                              onValueChange={(e) => {
                                setFields({
                                  ...fields,
                                  TwoFactorAuthenticationCode: {
                                    ...fields.TwoFactorAuthenticationCode,
                                    mobile: e,
                                  },
                                });
                              }}
                              disabled={!isEditable}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Popup
          visible={isResetPass}
          onHiding={() => {
            handleReset(false);
          }}
          showTitle={false}
          width={650}
          height={455}
          showCloseButton={true}
          hideOnOutsideClick={true}
        >
          <ResetPassword
            setToastConfig={setToastConfig}
            toastConfig={toastConfig}
            isResetPass={isResetPass}
            handleReset={handleReset}
          />
        </Popup>
      </div>
    </div>
  );
};

export default User;
