import { useEffect, useState } from "react";
import { ColumnDropdown } from "../../../../../../components/Dropdownn";
import { Table1 } from "../../../../../Administration/Table1/Table1";
import "./feePaymentPlan.css";
import { feepaymentTablecoloumn } from "../Data";
import { Popup } from "devextreme-react";
import { ChangeFeePopup } from "./ChangeFeePopup";
import { GeneratePaymentPopUp } from "./GeneratePaymentPopUp";
import { FeeScheduleSelect } from "./FeeScheduleSelect";
import { GoTOObligationFunction } from "../../GoTOObligationFunction";
import { useNavigate } from "react-router-dom";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled, selectedFeeCode }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name || selectedFeeCode}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

export const FeePaymentPlan = ({
  data,
  toastConfig,
  setToastConfig,
  addobligation,
  EditTogel,
  feeViolationDropdown,
  paymentAgencyEntity,
  generatePaymentPlanTableData,
  refreshGetGeneratePaymentPlanTableData,
  gObligationID,
  setFeePaymentPlanData,
  feePaymentPlanData,
  setGeneratePaymentPlanTableData,
  getObligation,
  monthStartDropdown,
  selectedFeeCode,
  feecodedropdown,
}) => {
  const tabTitles = ["Payment Alert", "Notes/Remarks"];
  const clas = new GoTOObligationFunction();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const [changeFeePopUp, setChangeFeePopUp] = useState(false);
  const [feeScheduleSelectPopUp, setFeeScheduleSelectPopUp] = useState(false);
  const [generatePaymentPlanPopUp, setGeneratePaymentPlanPopUp] = useState(false);
  const [fields, setFields] = useState({
    ObligationStartDate: "",
    feeCode: { name: "", id: "" },
    paymentAgencyEntity: "",
    feeDescription: "",
    adjustmentAmount: 0,
    balanceOwed: "",
    disbursed: "",
    writtenOff: "",
    paymentAgency: { name: "", id: "" },
    paymentPlanSentenceDate: "",
    paymentPlanObligationStartDate: "",
    paymentPlanStartDate: "",
    paymentPlanMaxDuration: 0,
    paymentPlanPaymentDuration: 0,
    paymentPlanMonthlyPayment: 0,
    paymentPlanMonthStart: 0,
    paymentPlanDayofMonth: "",
    paymentAlertInput: "",
    noteRemarksInput: "",
  });

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const formattedPrice = (price) => {
    let formatPrice = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
    }).format(Math.abs(price));
    return price >= 0 ? formatPrice : `(${formatPrice})`;
  };

  const convertDateFormat = (dateString) => {
    if (dateString) {
      const dateObj = new Date(dateString);
      return dateObj.toLocaleDateString("en-US");
    } else {
      return "";
    }
  };

  useEffect(() => {
    const selectedAgency = feeViolationDropdown.find(
      (item) => item?.id?.toLowerCase() === data?.gFeeScheduleFeeId?.toLowerCase()
    );
    setFields((prev) => ({
      ...prev,
      ObligationStartDate: data?.dActive,
      feeCode: { name: selectedAgency?.name, id: selectedAgency?.id },
      feeDescription: data?.sLocalViolationDescription ? `${data.sLocalViolationDescription} ` : "",
      // feeDescription: `${data?.sLocalViolationDescription} `,
      adjustmentAmount: data?.fAmount,
      balanceOwed: data?.fBalance ? data?.fBalance : 0,
      disbursed: data?.fAmountDisbursed ? data?.fAmountDisbursed : 0,
      writtenOff: data?.fWriteOff ? data?.writtenOff : 0,
      paymentAgency: {
        name: data?.sBillingEntityName,
        id: data?.gBillingEntityId,
      },
      paymentPlanSentenceDate: data?.dSentence,
      paymentPlanObligationStartDate: data?.dActive,
      paymentPlanStartDate: data?.dPaymentPlanStart,
      paymentPlanMaxDuration: data?.iProbationMonths ? data?.iProbationMonths : 0,
      paymentPlanPaymentDuration: data?.iPaymentPlanDurationMonths ? data?.iPaymentPlanDurationMonths : 0,
      paymentPlanMonthlyPayment: data?.fAmount
        ? data?.iPaymentPlanDurationMonths === 0
          ? 0
          : data?.fAmount / data?.iPaymentPlanDurationMonths
        : 0,
      paymentPlanMonthStart: data?.sPaymentPlanStartMonthOffsetName ? data?.sPaymentPlanStartMonthOffsetName : 0,
      paymentPlanDayofMonth: data?.iPaymentPlanStartDay ? data?.iPaymentPlanStartDay : "",
      paymentAlertInput: data?.sPaymentAlert ? data?.sPaymentAlert : "",
      noteRemarksInput: data?.sFeeRemarks ? data?.sFeeRemarks : "",
    }));
  }, [data]);

  useEffect(() => {
    setFeePaymentPlanData({
      feeCode: fields?.feeCode?.id,
      feeDescription: fields?.feeDescription,
      paymentAlertInput: fields?.paymentAlertInput,
      noteRemarksInput: fields?.noteRemarksInput,
      paymentAgenciesID: fields?.paymentAgency?.id,
      paymentAgencyEntityId: "",
      amount: fields?.adjustmentAmount,
    });
  }, [fields]);

  useEffect(() => {
    if (addobligation) {
      setFields({
        ObligationStartDate: "",
        feeCode: { name: "", id: "" },
        paymentAgencyEntity: "",
        feeDescription: "",
        adjustmentAmount: 0,
        balanceOwed: "",
        Disbursed: "",
        writtenOff: "",
        paymentAgency: { name: "", id: "" },
        paymentPlanSentenceDate: "",
        paymentPlanObligationStartDate: "",
        paymentPlanStartDate: "",
        paymentPlanMaxDuration: 0,
        paymentPlanPaymentDuration: 0,
        paymentPlanMonthlyPayment: 0,
        paymentPlanMonthStart: 0,
        paymentPlanDayofMonth: "",
        paymentAlertInput: "",
        noteRemarksInput: "",
      });
      setGeneratePaymentPlanTableData([]);
    }
  }, [!addobligation]);

  useEffect(() => {
    if (fields?.feeCode?.id) {
      clas.getFeeCodeChangeData(fields?.feeCode?.id, setLoading, navigate, setFields);
    }
  }, [fields?.feeCode?.id]);

  return (
    <>
      <Popup
        visible={changeFeePopUp}
        onHiding={() => {
          setChangeFeePopUp(false);
        }}
        showTitle={false}
        width={550}
        height={700}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <ChangeFeePopup
          changeFeePopUp={changeFeePopUp}
          setChangeFeePopUp={setChangeFeePopUp}
          data={data}
          feeViolationDropdown={feeViolationDropdown}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          gObligationID={gObligationID}
          getObligation={getObligation}
        />
      </Popup>
      <Popup
        visible={generatePaymentPlanPopUp}
        onHiding={() => {
          setGeneratePaymentPlanPopUp(false);
        }}
        showTitle={false}
        width={680}
        height={550}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <GeneratePaymentPopUp
          generatePaymentPlanPopUp={generatePaymentPlanPopUp}
          setGeneratePaymentPlanPopUp={setGeneratePaymentPlanPopUp}
          rowData={data}
          refreshGetGeneratePaymentPlanTableData={refreshGetGeneratePaymentPlanTableData}
          gObligationID={gObligationID}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          monthStartDropdown={monthStartDropdown}
        />
      </Popup>
      <Popup
        visible={feeScheduleSelectPopUp}
        onHiding={() => {
          setFeeScheduleSelectPopUp(false);
        }}
        showTitle={false}
        width={850}
        height={600}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <FeeScheduleSelect
          feeScheduleSelectPopUp={feeScheduleSelectPopUp}
          setFeeScheduleSelectPopUp={setFeeScheduleSelectPopUp}
        />
      </Popup>
      <div className="fee-paymentplan-section">
        {/* <div className="fee-paymentplan-content">
          <div className="fee-paymentplan-content-header">Fee</div>
          <div className="fee-payment-content-info">
            <label className="fee-payment-content-info-para">Obligation Start Date</label>
            <span className="fee-payment-content-info-span">{convertDateFormat(fields?.ObligationStartDate)}</span>
          </div>
          <div className="goto-obligation-svg-content">
            <div className="go-to-inputs-field edit_content_space1">
              <label htmlFor="" className="go-to-label-filed">
                Fee Code
              </label>
              <div className="flex" style={{ position: "relative" }}>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"feeCode"}
                  dropDownData={feecodedropdown}
                  disabled={!addobligation}
                  selectedFeeCode={selectedFeeCode}
                />
              </div>
            </div>
            <span
              className="fee-payment-content-info-svg"
              onClick={() => {
                !EditTogel && setChangeFeePopUp(!changeFeePopUp);
              }}
            >
              <svg
                className="svg-blue-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_6624_62037)">
                  <path
                    d="M19.4328 12.98C19.4728 12.66 19.5028 12.34 19.5028 12C19.5028 11.66 19.4728 11.34 19.4328 11.02L21.5428 9.37C21.7328 9.22 21.7828 8.95 21.6628 8.73L19.6628 5.27C19.5428 5.05 19.2728 4.97 19.0528 5.05L16.5628 6.05C16.0428 5.65 15.4828 5.32 14.8728 5.07L14.4928 2.42C14.4628 2.18 14.2528 2 14.0028 2H10.0028C9.75277 2 9.54277 2.18 9.51277 2.42L9.13277 5.07C8.52277 5.32 7.96277 5.66 7.44277 6.05L4.95277 5.05C4.72277 4.96 4.46277 5.05 4.34277 5.27L2.34277 8.73C2.21277 8.95 2.27277 9.22 2.46277 9.37L4.57277 11.02C4.53277 11.34 4.50277 11.67 4.50277 12C4.50277 12.33 4.53277 12.66 4.57277 12.98L2.46277 14.63C2.27277 14.78 2.22277 15.05 2.34277 15.27L4.34277 18.73C4.46277 18.95 4.73277 19.03 4.95277 18.95L7.44277 17.95C7.96277 18.35 8.52277 18.68 9.13277 18.93L9.51277 21.58C9.54277 21.82 9.75277 22 10.0028 22H14.0028C14.2528 22 14.4628 21.82 14.4928 21.58L14.8728 18.93C15.4828 18.68 16.0428 18.34 16.5628 17.95L19.0528 18.95C19.2828 19.04 19.5428 18.95 19.6628 18.73L21.6628 15.27C21.7828 15.05 21.7328 14.78 21.5428 14.63L19.4328 12.98ZM12.0028 15.5C10.0728 15.5 8.50277 13.93 8.50277 12C8.50277 10.07 10.0728 8.5 12.0028 8.5C13.9328 8.5 15.5028 10.07 15.5028 12C15.5028 13.93 13.9328 15.5 12.0028 15.5Z"
                    fill="#424167"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6624_62037">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </div>
          <div className="goto-obligation-svg-content">
            <div className="go-to-inputs-field">
              <label htmlFor="" className="go-to-label-filed">
                Original Amount
              </label>
              <div className="flex" style={{ position: "relative" }}>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Original Amount"
                  className="fee-payment-content-info-input"
                  style={{ width: "310px" }}
                  value={
                    !addobligation && paymentAgencyEntity
                      ? paymentAgencyEntity
                      : fields?.paymentAgencyEntity
                      ? fields?.paymentAgencyEntity
                      : feePaymentPlanData?.fRate
                      ? feePaymentPlanData?.fRate
                      : ""
                  }
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="fee-payment-content-info">
            <label className="fee-payment-content-info-para" style={{ width: "208px" }}>
              Fee Description
            </label>
            <span className="fee-payment-content-info-span">{fields?.feeDescription}</span>
          </div>
          <div className="goto-docket-svg-content">
            <div className="go-to-inputs-field">
              <label htmlFor="" className="go-to-label-filed">
                Adjustment Amount
              </label>
              <input
                type="number"
                name="adjustmentAmount"
                id="adjustmentAmount"
                value={data?.fAmount || fields?.adjustmentAmount}
                placeholder="Original Amount"
                className="fee-payment-content-info-input"
                style={{ width: "310px" }}
                disabled={!addobligation}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="fee-payment-content-info">
            <label className="fee-payment-content-info-para">Balance Owed</label>
            <span className="fee-payment-content-info-span">{formattedPrice(fields?.balanceOwed)}</span>
          </div>
          <div className="fee-payment-content-info">
            <label className="fee-payment-content-info-para">Disbursed</label>
            <span className="fee-payment-content-info-span">
              {formattedPrice(fields?.disbursed ? fields?.disbursed : "")}
            </span>
          </div>
          <div className="fee-payment-content-info">
            <label className="fee-payment-content-info-para">Written Off</label>
            <span className="fee-payment-content-info-span">{formattedPrice(fields?.writtenOff)}</span>
          </div>
          <div className="right-obligation-text">
            <p className="right-obligation-text-para">For non accrued obligation</p>
          </div>
        </div> */}
        <div className="fee-paymentplan-content fee-paymentplan-second-content">
          <div className="fee-paymentplan-content-header">Payment Plan</div>
          <div className="fee-payment-content-info">
            <label className="fee-payment-content-info-para">Sentence Date</label>
            <span className="fee-payment-content-info-span">{convertDateFormat(fields?.paymentPlanSentenceDate)}</span>
          </div>
          <div className="fee-payment-content-info">
            <label className="fee-payment-content-info-para">Obligation Start Date</label>
            <span className="fee-payment-content-info-span">
              {convertDateFormat(fields?.paymentPlanObligationStartDate)}
            </span>
          </div>
          <div className="fee-payment-content-info">
            <label className="fee-payment-content-info-para">Start Date of Payment Plan</label>
            <span className="fee-payment-content-info-span">{convertDateFormat(fields?.paymentPlanStartDate)}</span>
          </div>
          <div className="fee-payment-content-info">
            <label className="fee-payment-content-info-para">Max Duration (in months)</label>
            <span className="fee-payment-content-info-span">{fields?.paymentPlanMaxDuration}</span>
          </div>
          <div className="fee-payment-content-info">
            <label className="fee-payment-content-info-para">Payment Duration</label>
            <span className="fee-payment-content-info-span">{fields?.paymentPlanPaymentDuration}</span>
          </div>
          <div className="fee-payment-content-info">
            <label className="fee-payment-content-info-para">Monthly Payment</label>
            <span className="fee-payment-content-info-span">{formattedPrice(fields?.paymentPlanMonthlyPayment)}</span>
          </div>
          <div className="fee-payment-content-info">
            <label className="fee-payment-content-info-para">Month Start</label>
            <span className="fee-payment-content-info-span">{fields?.paymentPlanMonthStart}</span>
          </div>
          <div className="fee-payment-content-info">
            <label className="fee-payment-content-info-para">Day of Month</label>
            <span className="fee-payment-content-info-span">{fields?.paymentPlanDayofMonth}</span>
          </div>
          <div className="right-obligation-button">
            <button
              className="right-obligation-text-button"
              onClick={() => {
                !EditTogel && setGeneratePaymentPlanPopUp(!generatePaymentPlanPopUp);
              }}
            >
              Generate
              <svg
                className="svg-blue-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_6661_33866)">
                  <path
                    d="M5 12.9987H16.17L11.29 17.8787C10.9 18.2687 10.9 18.9087 11.29 19.2987C11.68 19.6887 12.31 19.6887 12.7 19.2987L19.29 12.7087C19.68 12.3187 19.68 11.6888 19.29 11.2988L12.71 4.69875C12.32 4.30875 11.69 4.30875 11.3 4.69875C10.91 5.08875 10.91 5.71875 11.3 6.10875L16.17 10.9988H5C4.45 10.9988 4 11.4488 4 11.9988C4 12.5487 4.45 12.9987 5 12.9987Z"
                    fill="#424167"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6661_33866">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        </div>
        {/* <div className="fee-paymentplan-content-table"> */}
          {/* <div className="left-obligation-button">
            <button
              className="right-obligation-text-button"
              onClick={() => {
                setexportPopupOpen(true);
              }}
            >
              Export
              <svg
                className="svg-blue-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_6661_17219)">
                  <path
                    d="M16.59 9H15V4C15 3.45 14.55 3 14 3H10C9.45 3 9 3.45 9 4V9H7.41C6.52 9 6.07 10.08 6.7 10.71L11.29 15.3C11.68 15.69 12.31 15.69 12.7 15.3L17.29 10.71C17.92 10.08 17.48 9 16.59 9ZM5 19C5 19.55 5.45 20 6 20H18C18.55 20 19 19.55 19 19C19 18.45 18.55 18 18 18H6C5.45 18 5 18.45 5 19Z"
                    fill="#424167"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6661_17219">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button> */}
          {/* </div> */}
          {/* <div className="fee-payment-table">
            <Table1
              editTable={false}
              height={400}
              Tablecoloum={feepaymentTablecoloumn}
              tableRow={generatePaymentPlanTableData?.map((val, i) => {
                return { ...val, ID: i };
              })}
              load={loading}
              coloumWidthText={"FeePaymentPlan"}
              mandatoryColoum={"Pay Date"}
              exportInDifferentFormate={true}
              exportPopupOpen={exportPopupOpen}
              setexportPopupOpen={setexportPopupOpen}
              exportwidth={27}
              exporttitle={"Fee-Payment-Plan"}
            />
          </div> */}
        {/* </div> */}
      </div>
      <div className="payment-alert">
        <div className="goto-obligation-sub-bottom-tab">
          {tabTitles.map((title, index) => (
            <button
              key={index}
              className={`goto-obligation-button ${activeTab === index + 1 ? "goto-obligation-button-active" : ""}`}
              onClick={() => handleTabClick(index + 1)}
            >
              {title}
            </button>
          ))}
        </div>
        <div className="commonBlock_bottom_border"></div>
        <div className="goto-obligation-subtabs">
          {activeTab === 1 && (
            <>
              <textarea
                type="text"
                className="dark-mode-textarea"
                name="paymentAlertInput"
                id="paymentAlertInput"
                style={{ width: "100%" }}
                placeholder="Payment Alert"
                value={fields?.paymentAlertInput}
                disabled={!EditTogel}
                onChange={handleInputChange}
              />
            </>
          )}
          {activeTab === 2 && (
            <>
              <textarea
                type="text"
                className="dark-mode-textarea"
                name="noteRemarksInput"
                id="noteRemarksInput"
                style={{ width: "100%" }}
                placeholder="Notes/Remarks"
                value={fields?.noteRemarksInput}
                disabled={!EditTogel}
                onChange={handleInputChange}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
