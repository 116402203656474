const convertDateFormat = (dateString) => {
  if (dateString) {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleDateString("en-US");
  } else {
    return "";
  }
};

export const docketColumnTitle = [
  { title: "Reference", underLine: true },
  { title: "Location" },
  { title: "Docket #" },
  { title: "Offender" },
  { title: "Offender #" },
  { title: "SSN" },
  { title: "Alt ID" },
  { title: "Court / Agency" },
  { title: "Sent Date" },
];

export const setDocketFindTable = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      Reference: val?.sReferenceNumber,
      Location: val?.sGPMLocation_EntityName,
      "Docket #": val?.sDocketNumberCourtCounts,
      Offender: val?.sPersonNameLastFirst,
      "Offender #": val?.sOffenderNumber,
      SSN: val?.sSSNx4,
      "Alt ID": val?.s,
      "Court / Agency": val?.s,
      "Sent Date": val?.s,
      ID: i,
      gDocketID: val?.gDocketID,
      gPersonId: val?.gPersonID,
    });
  });
  return arr;
};
