import React, { useEffect, useState } from "react";
import { ReactComponent as Sun } from "../../assets/images/icons/Sun.svg";
import { ReactComponent as Moon } from "../../assets/images/icons/Moon.svg";
import "./DarkMode.css";

const DarkMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("selectedTheme") === "dark"
  );

  useEffect(() => {
    document
      .querySelector("body")
      .setAttribute("data-theme", isDarkMode ? "dark" : "light");
    localStorage.setItem("selectedTheme", isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  const toggleTheme = () => {
    setIsDarkMode((prevIsDarkMode) => !prevIsDarkMode);
  };

  return (
    <div className="dark_mode">
      <input
        className="dark_mode_input"
        type="checkbox"
        id="darkmode-toggle"
        onChange={toggleTheme}
        checked={isDarkMode}
      />
      <label className="dark_mode_label" htmlFor="darkmode-toggle">
        <Sun />
        <Moon />
      </label>
    </div>
  );
};

export default DarkMode;
