import { useEffect, useState } from "react";
import "../drugTestRandomizer.css";
import { DateBox, LoadPanel, Scheduler, ScrollView } from "devextreme-react";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { logOut } from "../../../../../components/Logout";
import { handleGetLocal } from "../../../../../services/auth-service";
import { Editing } from "devextreme-react/data-grid";
import AppointmentTooltip from "./AppointmentTooltip";

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  fieldNmae,
  reset,
  disabled,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

export const Calendar = ({
  calendarPopup,
  setCalendarPopup,
  locationDropdownValues,
}) => {
  const pathname = handleGetLocal("pathURL");
  const currentDate = new Date();
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const year = lastDayOfMonth.getFullYear();
  const month = (lastDayOfMonth.getMonth() + 1).toString().padStart(2, "0");
  const day = lastDayOfMonth.getDate().toString().padStart(2, "0");
  const formattedEndDate = `${year}-${month}-${day}`;
  const [date, setdate] = useState({
    startDate: new Date(new Date().setDate(1)).toISOString().slice(0, 10),
    endDate: formattedEndDate,
  });
  const [fields, setFields] = useState({
    location: { name: "", id: "" },
  });
  const [calendarData, setCalendarData] = useState([]);
  const views = ["month"];
  const [loading, setLoading] = useState(false);
  const [schedulerOptions, setSchedulerOptions] = useState({
    currentView: "month",
    currentDate: date.startDate, // Set the initial currentDate to startDate
  });

  useEffect(() => {
    setCalendarData([]);
    setFields({
      location: { name: "", id: "" },
    });
    setdate({
      startDate: new Date(new Date().setDate(1)).toISOString().slice(0, 10),
      endDate: formattedEndDate,
    });
    setSchedulerOptions((prevOptions) => ({
      ...prevOptions,
      currentDate: date.startDate,
    }));
  }, [calendarPopup]);

  const getCalendarDataTests = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/RandomDrugTesting/get-test-date?Location=${fields.location.id}&dStart=${date.startDate}&dEnd=${date.endDate}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        setCalendarData(
          val?.data?.data?.map((item) => {
            return {
              text: `${item?.sTestTime} (${item?.sGender}) - Test: ${item?.iCount}`,
              title: `(${item?.sGender}) - Tests: ${item?.iCount} - Appointment`,
              startDate: item?.dTest,
              endDate: item?.dTest,
              location: "",
              description: "",
              toolTipName: `(${item?.sGender}) - Tests: ${item?.iCount}`,
              toolTipTime: item?.sTestTime,
            };
          })
        );
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          Navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    if (fields.location.id) {
      if (
        date.endDate !== "Invalid Date" &&
        date.startDate !== "Invalid Date"
      ) {
        getCalendarDataTests();
        setSchedulerOptions((prevOptions) => ({
          ...prevOptions,
          currentDate: date.startDate,
        }));
      }
    }
  }, [date, fields.location?.id]);

  const onAppointmentFormOpening = (e) => {
    e.popup.option("showTitle", true);
    e.popup.option(
      "title",
      e.appointmentData.title ? e.appointmentData.title : "Appointment"
    );

    const form = e.form;
    let formItems = form.option("items");
    if (!formItems.find((i) => i.dataField === "location")) {
      formItems.push({
        colSpan: 2,
        label: { text: "Location" },
        editorType: "dxTextBox",
        dataField: "location",
      });
      form.option("items", formItems);
    }
  };

  const handleCurrentViewChange = (newView) => {
    setSchedulerOptions((prevOptions) => ({
      ...prevOptions,
      currentView: newView,
    }));
  };

  const handleCurrentDateChange = (newDate) => {
    setSchedulerOptions((prevOptions) => ({
      ...prevOptions,
      currentDate: newDate,
    }));
  };

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div className="calendar-popup">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <p>Testing Dates</p>
            </div>
            <p class="popup_header_title_para">DT.3</p>
          </div>
          <svg
            onClick={() => {
              setCalendarPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <ScrollView style={{ height: "590px" }}>
          <div className="calendar-content">
            <div className="page-details-search-content">
              <div className="display-flex-div dropdown-space">
                <label className="fee-payment-content-info-para">
                  Location
                </label>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"location"}
                  dropDownData={locationDropdownValues?.location?.map(
                    (vall) => {
                      return {
                        name: vall?.value,
                        id: vall?.key,
                      };
                    }
                  )}
                />
              </div>
              <div className="display-flex-div">
                <label className="fee-payment-content-info-para">
                  Period Start
                </label>
                <DateBox
                  useMaskBehavior={true}
                  value={date.startDate}
                  onValueChange={(e) => {
                    if (e) {
                      const initialDateString = e;
                      const initialDate = new Date(initialDateString);
                      const utcTime =
                        initialDate.getTime() -
                        initialDate.getTimezoneOffset() * 60000;
                      const utcFormatted = new Date(utcTime).toISOString();
                      setdate({
                        ...date,
                        startDate: e ? utcFormatted : null,
                      });
                    }
                  }}
                />
              </div>
              <div className="display-flex-div">
                <label className="fee-payment-content-info-para">
                  Period End
                </label>
                <DateBox
                  useMaskBehavior={true}
                  value={date.endDate}
                  onValueChange={(e) => {
                    if (e) {
                      const initialDateString = e;
                      const initialDate = new Date(initialDateString);
                      const utcTime =
                        initialDate.getTime() -
                        initialDate.getTimezoneOffset() * 60000;
                      const utcFormatted = new Date(utcTime).toISOString();
                      setdate({
                        ...date,
                        endDate: e ? utcFormatted : null,
                      });
                    }
                  }}
                />
              </div>
            </div>
            <Scheduler
              views={views}
              dataSource={calendarData}
              height={600}
              showAllDayPanel={false}
              allDayExpr={false}
              defaultCurrentView={schedulerOptions.currentView}
              currentDate={schedulerOptions.currentDate}
              onCurrentViewChange={handleCurrentViewChange}
              onCurrentDateChange={handleCurrentDateChange}
              onAppointmentFormOpening={onAppointmentFormOpening}
              appointmentTooltipComponent={AppointmentTooltip}
            >
              <Editing allowAdding={false} />
            </Scheduler>
          </div>
        </ScrollView>
      </div>
    </>
  );
};
