import React, { useCallback, useEffect, useRef, useState } from "react";
import DropDownBox from "devextreme-react/drop-down-box";
import DataGrid, { Selection, Paging, FilterRow, Scrolling } from "devextreme-react/data-grid";

const gridColumns = ["text"];
const gridColumns1 = ["Entity", "Region", "Company"];

function DropDownDataGrid({ gridDataSource, setValue, initialKeys }) {
  const [gridBoxValue, setGridBoxValue] = useState([]);

  const getColumns = () => {
    if (!gridDataSource?.length) return gridColumns;
    if (Object.keys(gridDataSource[0]).length > 3) {
      return gridColumns1;
    }
    return gridColumns;
  };

  const displayExpr = () => {
    if (getColumns().length > 2) return "Entity";
    else return "text";
  };

  const dataGrid = useRef();

  const syncDataGridSelection = useCallback((e) => {
    setGridBoxValue(e.value || []);
    if (e.value === null) {
      dataGrid.current.instance().clearSelection();
      setValue([]);
    }
  }, []);

  useEffect(() => {
    if (initialKeys?.length) {
      setGridBoxValue(initialKeys);
    }
  }, [initialKeys]);

  return (
    <div>
      <DropDownBox
        value={gridBoxValue}
        valueExpr="value"
        deferRendering={false}
        displayExpr={displayExpr()}
        placeholder="Select a value..."
        showClearButton={true}
        dataSource={gridDataSource}
        onValueChanged={syncDataGridSelection}
        contentRender={() => (
          <DataGrid
            height={345}
            ref={dataGrid}
            dataSource={gridDataSource}
            columns={getColumns()}
            hoverStateEnabled={true}
            keyExpr={"value"}
            loadPanel={null}
            onSelectionChanged={async (e) => {
              const result = await dataGrid.current.instance().getSelectedRowKeys();
              setGridBoxValue(result);
              setValue(result);
            }}
          >
            <Selection mode="multiple" deferred={true} />
            <Scrolling mode="virtual" />
            <Paging enabled={true} pageSize={10} />
            <FilterRow visible={true} />
          </DataGrid>
        )}
      />
    </div>
  );
}

export default DropDownDataGrid;
