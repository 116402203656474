import React, { useEffect, useRef, useState } from "react";
import DataGrid, {
  Column,
  HeaderFilter,
  Pager,
  Summary,
  TotalItem,
  StateStoring,
  Editing,
  Lookup,
  RequiredRule,
  MasterDetail,
  Selection,
} from "devextreme-react/data-grid";
import axios from "axios";
import LoadPanel from "devextreme-react/load-panel";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../style/table.css";
import {
  AdminUserListID,
  removeAdminTab,
  removeProntactContactsdynamicTab,
  removeProntactEntitiesdynamicTab,
  setContectEntityListTableAddData,
  setContectEntityListTableAddData2,
  setFinancialTabs,
  setaddcreatetestdate,
  setdisbursementCheckObligationListDate,
} from "../../../reducer/AdminReadReducer";
import { ContextMenu, Popup } from "devextreme-react";
import { ExportFile } from "../../../components/ExportGride/ExportFile";
import ReceiptIcon from "../../../assets/images/icons/Receipt.svg";
import PauseIcon from "../../../assets/images/icons/pause.svg";
import { DetailTemplate } from "../../Participants/ParticipantSubTabs/Financial/components/DetailTemplate";
import { useSelector } from "react-redux";
import { setdockerValue, setgotooblogationValue } from "../../../reducer/participantNoteReducer";
import EmDetailTemplate from "../../Participants/ParticipantSubTabs/EM/EmDetailTemplate";
import ThreeDotMenu from "../../../components/Common/Icons/ThreeDotMenu";
import { setDynamicTab } from "../../../reducer/HomePageReducer";
import { handleGetLocal } from "../../../services/auth-service";

let rowDataCheck = [];
let financialid = 0;
let selectedOp3 = null;


const Exports = ({ setexportPopupOpen, exportPopupOpen, data, exportwidth, exporttitle }) => {
  return (
    <>
      <Popup
        visible={exportPopupOpen}
        onHiding={() => {
          setexportPopupOpen(false);
        }}
        showTitle={false}
        width={500}
        height={300}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <ExportFile
          alldata={data}
          setexportPopupOpen={setexportPopupOpen}
          exportwidth={exportwidth}
          exporttitle={exporttitle}
        />
      </Popup>
    </>
  );
};

export const Table1 = ({
  pendingStatusFilter,
  Tablecoloum,
  tableRow,
  load,
  coloumWidthText,
  height,
  editTable,
  newrowData,
  tabledata,
  mandatoryColoum,
  setRecivePaymentModel,
  ExternalPendingPaymentsContextMenu,
  setGroupResult,
  groupResult,
  setEntityResult,
  entityResult,
  exportInDifferentFormate,
  exportPopupOpen,
  setexportPopupOpen,
  exportwidth,
  exporttitle,
  amountPayment,
  contextMenu,
  setVoidPaymentPopup,
  setVoidProntoCashConfirmationPopup,
  setExternalPaymentId,
  setAmountData,
  setGPaymentId,
  setEditPopup,
  setRowInfoData,
  setrefundCCPaymentPopup,
  showTotalBlock,
  selection,
  contextMenus,
  obilgationTable,
  setWriteOffAmount,
  setWriteOffMultipleObligation,
  setObligationData,
  setSelectWriteOffData,
  obligationMenu,
  wordWrapEnabled,
  programMenu,
  setGPersonId,
  setGPersonOffenderId,
  setVoidPopup,
  setProgramEditPopup,
  setEditProgramData,
  plannedMenu,
  setAddCommunityServicePopup,
  completedMenu,
  setAddCommunityServiceLogPopup,
  setPlannedRowData,
  setCompletedRowData,
  setAddCommunityServiceLogType,
  setAmount,
  warrantMenu,
  setAddWarrentsPopup,
  setRowWarrentData,
  setWarrantType,
  tollingMenu,
  setEditpopupTogall,
  setgeteditdata,
  setJailRowData,
  jailPlannedMenu,
  jailCompletedMenu,
  setAddJailServiceLogPopup,
  setAddJailServicePopup,
  setJailPlainType,
  setJailCompletedType,
  drugTestMenu,
  setDoNotCreateObligation,
  setDrugTestRowData,
  setDrugTestType,
  checkEditable,
  setVoideFalse,
  setGotoAddCommunity,
  setTtypeProgram,
  setFindFeeSchedulePopup,
  formsPageMenu,
  setAddExistingPersonPopup,
  setFormsPageMenu,
  formRowData,
  setFormRowData,
  setCreateNewPersonPopup,
  setFormsRowType,
  viewFormPdfApi,
  reportDateRange,
  reportDateRowData,
  seReportDateRowData,
  getCheckInApi,
  drugTestScheduleMenu,
  setDrugTestScheduleRowData,
  drugTestScheduleRowData,
  setPaymentDates,
  setAddEditDrugTestSchedulePopup,
  setDrugTestScheduleType,
  randmoizerSummaryMenu,
  randomizerSummaryRowData,
  setRandomizerSummaryRowData,
  setRandomizerSummaryPopUpshow,
  createTestFieldData,
  setRemovePersonListPopup,
  setFindContactPopup,
  contactsListEntity,
  contactsListPosition,
  emailSentData,
  setEmailSentData,
  editParticipant,
  contactsEntityList,
  jumpContactsListToEntityList,
  editEntiyListLeftData,
  entityListRightData,
  setEntityListRightData,
  typeFeeSchedule,
  findContactEntitylistID,
  recipientsMenu,
  setAddRecipientsPopup,
  setRecipientsType,
  setRecipientsRowData,
  PaymentCreditsMenu,
  setVoidCashPaymentPopup,
  setEditVoidCashPaymentPopup,
  setVoidRefundCCPaymentPopup,
  setRecipientsClickRowData,
  setReallocatePaymentPopup,
  preview,
  prewviewtogal,
  prewviewfontsize,
  documentWizardDocketsSelection,
  setDocumentWizardDocketsRowTableData,
  disableTable,
  setDocumentWizardDiable,
  SharequeryTableselectall,
  sharequeryshow,
  documentWizardConditionsViolationsSelection,
  setConditionsViolationsRowTableData,
  disbursementCheckListMenu,
  setChangeCheckNumberPopup,
  setDisbursementRowCheckData,
  disbursementObligationCheckListMenu,
  setDisbursementObligationRowListData,
  disbursementObligationRowListData,
  addEquipmentMenu,
  setAddEquipmentPopup,
  disbursementRowCheckData,
  vendorInventoryMenu,
  monitoringReferralMenu,
  setReferralPreviewPopup,
  setMonitoringReferralRowData,
  monitoringReferralRowData,
  vendorInventoryRowData,
  setVendorInventoryRowData,
  setAddEquipmentPopupType,
  setEquimentRowData,
  setRemoveFromListPopup,
  setLinkPersonReferral,
  monitoringAgencyInvoicesMenu,
  monitoringRateEditorMenu,
  setRateEditorRowData,
  setAddNewRatePopupType,
  setAddNewRatePopup,
  ProcessScheduleChangeapi,
  createPersonVendorInventoryAPi,
  processEndMonitoringApi,
  setAgencyInvoicesRowData,
  setConfirmPopup,
  setConfirmPopupType,
  exportInvoiceToExcelApi,
  selectedRowCheckbox,
  setSelectedRowCheckbox,
  setPrintCheckPopup,
  emSubTabMenu,
  eMFirstSubTabMenu,
  setEMFirstSubTabMenu,
  setEMPriodpopshow,
  setrowleveldata,
  setrowlevelchilddata,
  rowleveldata,
  setEmPopupType,
  setParentRowData,
  EMObligationMenu,
  EMEquipmentLogMenu,
  SetTypeTab,
  setEMAddEqupmentLogPopup,
  setEMAddObligationPopup,
  setRowLevelData,
  EMEquipmentLogObligationMenu,
  obligationMontoringEmPeriodMenu,
  setAddEditEMPeriodPopup,
  setVoidAlertPopup,
  createInvoiceMenu,
  handleReceiptNumber,
  schedulingListControl,
  selectedPersonIds,
  setSelectedPersonIds,
  showSendEmailPopup,
  setPayment,
  setMovePaymentPopup,
  setUnAllocatePopup,
  setPaymentIdForUnallocate,
  setPaymentIdForMovePayment,
  printInvoice
}) => {
  const orders = tableRow;
  const { resetcreatetestdate, ContectEntityListTableAddData, ContectEntityListTableAddData2 } = useSelector(
    (store) => store.AdminTable
  );

  const dataGrid = useRef(null);

  const [count, setCount] = useState(0);
  const [voidCheck, setVoidCheck] = useState(false);
  const [obligationId, setObligationId] = useState([]);
  const [enableWriteOffMulti, setEnableWriteOffMulti] = useState(false);
  const [writeOffCheck, setWriteOffCheck] = useState();
  const [jumpRowData, setJumpRowData] = useState([]);
  const [viewAttachment, setViewAttachment] = useState("");
  const [checkPersonId, setCheckPersonId] = useState("");

  const customizeDate = (data) => {
    setCount(data.value);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    if (selection) {
      setSelectWriteOffData(selectedRowsData);
      setObligationId(selectedRowsData);
    }
    if (documentWizardDocketsSelection) {
      setDocumentWizardDocketsRowTableData(selectedRowsData);
    }
    if (documentWizardConditionsViolationsSelection) {
      setConditionsViolationsRowTableData(selectedRowsData);
    }
  };

  const [tabledataa, settabledataa] = useState([...ContectEntityListTableAddData]);
  useEffect(() => {
    if (!editParticipant) {
      dispatch(setContectEntityListTableAddData(tabledataa));
    }
  }, [tabledataa]);

  const printTableData = (dataGrid) => {
    const dataGridInstance = dataGrid.current.instance();

    const visibleColumns = dataGridInstance.getVisibleColumns();
    const headers = [];
    visibleColumns.map((column) => {
      if (column.caption !== "Edit" && column.caption !== "Action") {
        headers.push(column.caption);
      }
    });
    const rows = [];

    const visibleRows = dataGridInstance.getVisibleRows();
    visibleRows.forEach((row) => {
      const rowData = [];
      visibleColumns.map((column) => {
        if (column.dataField !== "Edit" && column.caption !== "Action") {
          if (column.dataField === "DOE") {
            rowData.push(
              new Date(row.data[column.dataField]).toLocaleString("en-US", {
                month: "numeric",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })
            );
          } else {
            rowData.push(row.data[column.dataField]);
          }
        }
      });
      rows.push(rowData);
    });

    // Create an HTML page with the table for printing

    var printWindow = window.open("", "", "width=600,height=600");
    printWindow.document.open();
    printWindow.document.write(`
      <html>
      <head>
        <title>Table Data</title>
        <style>
          table {
            border-collapse: collapse;
            width: 100%;
          }
          th, td {
            border: 1px solid #000;
            padding: 1px;
            font-size:${prewviewfontsize ? prewviewfontsize : 12}px;
            text-align: left;
          }
          tr:nth-child(even) {
            background-color: #f2f2f2;
          }
        </style>
      </head>
      <body>
        <table>
          <thead>
            <tr>
              ${headers.map((header) => `<th>${header}</th>`).join("")}
            </tr>
          </thead>
          <tbody>
            ${rows
        .map((rowData) => `<tr>${rowData.map((cell) => `<td>${cell ? cell : ""}</td>`).join("")}</tr>`)
        .join("")}
          </tbody>
        </table>
      </body>
      </html>
    `);
    printWindow.document.close();

    // Print the newly created page
    printWindow.print();
    printWindow.close();
  };

  const saveCookieCrum = async (perdonId) => {
    const pathname = handleGetLocal("pathURL");
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/cookie-crumbs/save-cookie-crumb?recordId=${perdonId}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then(() => { })
      .catch((val) => { });
  };

  useEffect(() => {
    setTimeout(() => {
      if (preview && prewviewtogal !== undefined) {
        printTableData(dataGrid);
      }
    }, 100);
  }, [prewviewtogal]);

  useEffect(() => {
    if (setVoideFalse) {
      const coloum = JSON.parse(localStorage.getItem(coloumWidthText))?.columns?.map((val) => {
        if (val?.dataField === "Void") {
          return {
            ...val,
            filterValues: [false],
            filterType: "include",
          };
        } else {
          return val;
        }
      });
      const data = {
        ...JSON.parse(localStorage.getItem(coloumWidthText)),
        columns: coloum,
      };
      localStorage.setItem(coloumWidthText, JSON.stringify(data));
    }
    if (pendingStatusFilter) {
      const coloum = JSON.parse(localStorage.getItem(coloumWidthText))?.columns?.map((val) => {
        if (val?.dataField === "Status") {
          return {
            ...val,
            filterValues: ['Pending'],
            filterType: "include",
          };
        } else {
          return val;
        }
      });
      const data = {
        ...JSON.parse(localStorage.getItem(coloumWidthText)),
        columns: coloum,
      };
      localStorage.setItem(coloumWidthText, JSON.stringify(data));
    }
  }, []);

  const [data2, setdata2] = useState();

  const updateCheckboxData = (checkrowData, slot, checked) => {
    const rowIndex = rowDataCheck.findIndex((item) => item?.rowId === checkrowData?.ID);
    const updatedRowData = [...rowDataCheck];
    if (rowIndex !== -1) {
      // updatedRowData[rowIndex][slot] = checked;
      updatedRowData[rowIndex] = {
        ...updatedRowData[rowIndex],
        [slot]: checked,
      };
    } else {
      const newRowData = {
        rowId: checkrowData?.ID,
        [slot]: checked,
        dTest: checkrowData?.Date,
        gEntityID: createTestFieldData?.location?.id,
        Gender: createTestFieldData?.gender?.id,
        iSlotLimit: createTestFieldData?.iSlotLimit ? createTestFieldData?.iSlotLimit : 0,
      };
      updatedRowData.push(newRowData);
    }
    const filteredRowData = updatedRowData.filter((rowData) => {
      return Object.keys(rowData).some((key) => key !== "rowId" && rowData[key] === true);
    });
    rowDataCheck = [...filteredRowData];
    let totalCount = 0;
    rowDataCheck.forEach((rowData) => {
      Object.keys(rowData).forEach((key) => {
        if (key !== "rowId" && rowData[key] === true) {
          totalCount++;
        }
      });
    });
    const storageData = {
      rowDataCheck: filteredRowData,
      totalCount: totalCount,
    };
    setdata2(storageData);
  };

  useEffect(() => {
    dispatch(setaddcreatetestdate(data2));
  }, [data2]);

  useEffect(() => {
    rowDataCheck = [];
    setdata2();
  }, [resetcreatetestdate]);

  useEffect(() => {
    if (SharequeryTableselectall) {
      setTimeout(() => {
        dataGrid?.current?.instance()?.clearSelection();
      }, 10);
    }
  }, [sharequeryshow, tableRow.length]);

  const [data, setdata] = useState({});

  const [selectrowleveloperation, setselectrowleveloperation] = useState("");

  return (
    <div>
      <LoadPanel shadingColor="true" visible={load} delay={10} />
      {exportInDifferentFormate && (
        <Exports
          exportPopupOpen={exportPopupOpen}
          setexportPopupOpen={setexportPopupOpen}
          data={dataGrid}
          exportwidth={exportwidth}
          exporttitle={exporttitle}
        />
      )}

      <DataGrid
        wordWrapEnabled={wordWrapEnabled}
        disabled={setDocumentWizardDiable && disableTable ? true : false}
        id="gridContainer"
        ref={dataGrid}
        dataSource={orders}
        keyExpr="ID"
        showBorders={true}
        height={height}
        loadPanel={false}
        allowColumnResizing={true}
        columnResizingMode={"nextColumn"}
        rowAlternationEnabled={true}
        onCellPrepared={(e) => {
          if (e.isAltRow) {
            e.cellElement.style.backgroundColor = "var(--table-second-row-light-black)";
            e.cellElement.style.color = "var(--white-black)";
          }
          if (e.rowType === "data" && e?.data?.bStrikeOut) {
            if (!e.column.dataField || e.column.dataField !== "Balance") {
              e.cellElement.style.textDecoration = "line-through";
            }
          }
        }}
        onContextMenuPreparing={(e) => {
          if (setrowleveldata) {
            setrowleveldata(e?.row?.data);
          }
          setselectrowleveloperation(e?.row?.data);
          if (amountPayment) {
            setRowInfoData(e?.row?.data);
            setGPaymentId(e?.row?.data?.gPaymentId);
            setAmountData(e?.row?.data?.Amount);
            setVoidCheck(e?.row?.data?.Void);
          } else if (obilgationTable) {
            setEnableWriteOffMulti(obligationId.find((status) => status?.ID === e?.row?.data?.ID));
            setObligationData(e?.row?.data);
            setWriteOffCheck(e?.row?.data?.Balance);
          } else if (programMenu) {
            setVoidCheck(e?.row?.data?.Void);
            setEditProgramData(e?.row?.data);
            setTtypeProgram("Edit");
          } else if (plannedMenu) {
            setVoidCheck(e?.row?.data?.Void);
            setPlannedRowData(e?.row?.data);
            setGotoAddCommunity("Edit");
          } else if (completedMenu) {
            setVoidCheck(e?.row?.data?.Void);
            setCompletedRowData(e?.row?.data);
            setAddCommunityServiceLogType("EDIT");
          } else if (warrantMenu) {
            setVoidCheck(e?.row?.data?.Void);
            setRowWarrentData(e?.row?.data);
            setWarrantType("EDIT");
          } else if (setgeteditdata) {
            setgeteditdata(e?.row?.data);
            setVoidCheck(e?.row?.data?.["Void"]);
          } else if (jailPlannedMenu) {
            setJailPlainType("EDIT");
            setJailRowData(e?.row?.data);
            setVoidCheck(e?.row?.data?.Void);
          } else if (jailCompletedMenu) {
            setJailCompletedType("EDIT");
            setJailRowData(e?.row?.data);
            setVoidCheck(e?.row?.data?.Void);
          } else if (drugTestMenu) {
            setDrugTestType("EDIT");
            setDrugTestRowData(e?.row?.data);
            setVoidCheck(e?.row?.data?.["Void"]);
          } else if (formsPageMenu) {
            setFormRowData(e?.row?.data);
          } else if (reportDateRange) {
            seReportDateRowData(e?.row?.data);
          } else if (drugTestScheduleMenu) {
            setDrugTestScheduleRowData(e?.row?.data);
            setVoidCheck(e?.row?.data?.["Void"]);
          } else if (randmoizerSummaryMenu) {
            setRandomizerSummaryRowData(e?.row?.data);
          } else if (jumpContactsListToEntityList) {
            setJumpRowData(e?.row?.data);
          } else if (recipientsMenu) {
            setRecipientsRowData(e?.row?.data);
            setVoidCheck(e?.row?.data?.Void);
          } else if (PaymentCreditsMenu) {
            setRowInfoData(e?.row?.data);
            setVoidCheck(e?.row?.data?.Void);
          } else if (disbursementCheckListMenu) {
            setDisbursementRowCheckData(e?.row?.data);
            setVoidCheck(e?.row?.data?.Void);
          } else if (disbursementObligationCheckListMenu) {
            setDisbursementObligationRowListData(e?.row?.data);
          } else if (monitoringReferralMenu) {
            setMonitoringReferralRowData(e?.row?.data);
            setCheckPersonId(e?.row?.data?.gPersonId);
            setViewAttachment(e?.row?.data?.sAttachmentUrl);
          } else if (vendorInventoryMenu) {
            setVendorInventoryRowData(e?.row?.data);
            setCheckPersonId(e?.row?.data?.gPersonId);
          } else if (addEquipmentMenu) {
            setEquimentRowData(e?.row?.data);
          } else if (monitoringRateEditorMenu) {
            setRateEditorRowData(e?.row?.data);
          } else if (monitoringAgencyInvoicesMenu) {
            setAgencyInvoicesRowData(e?.row?.data);
          } else if (EMEquipmentLogMenu) {
            setRowLevelData(e?.row?.data);
          } else if (EMObligationMenu) {
            setRowLevelData(e?.row?.data);
          } else if (EMEquipmentLogObligationMenu) {
            setRowLevelData(e?.row?.data);
          } else if (obligationMontoringEmPeriodMenu) {
            setRowLevelData((prev) => ({ ...prev, ...e?.row?.data }));
          }
          setdata(e?.row?.data);
        }}
        onSelectionChanged={onSelectionChanged}
        showColumnLines={true}
        showRowLines={true}
        allowColumnReordering={true}
        // columnMinWidth={100}
        onContentReady={(e) => {
          e.component.columnOption("command:edit", "visibleIndex", -1);
        }}
        onRowInserting={(e) => {
          if (tabledata && editParticipant) {
            newrowData({ data: e, name: "insert" });
          }
        }}
        onSaved={(e) => {
          if (!editParticipant) {
            if (e?.changes[0]?.type === "insert") {
              if (contactsEntityList) {
                const newPrimary = e.changes[0]?.data.Primary === true;
                const updatedList = ContectEntityListTableAddData2.map((val) => ({
                  ...val,
                  Primary: newPrimary ? false : val.Primary,
                }));
                updatedList.push(e.changes[0].data);
                dispatch(setContectEntityListTableAddData2(updatedList));
              } else {
                settabledataa([...tabledataa, e?.changes[0]?.data]);
              }
            } else if (e?.changes[0]?.type === "update") {
              if (contactsEntityList) {
                const previousDataWithTruePrimary = ContectEntityListTableAddData2.find((val) => val.Primary === true);
                const updatedList = ContectEntityListTableAddData2.map((val) => {
                  if (val.ID === e.changes[0].data.ID) {
                    return {
                      ...e.changes[0].data,
                      Primary: e.changes[0].data.Primary,
                    };
                  } else {
                    if (previousDataWithTruePrimary && val.ID === previousDataWithTruePrimary.ID) {
                      return {
                        ...val,
                        Primary: false,
                      };
                    }
                    return val;
                  }
                });
                const newPrimary = e.changes[0].data.Primary;
                if (newPrimary === true) {
                  updatedList.forEach((val) => {
                    if (val.ID === e.changes[0].data.ID) {
                      val.Primary = true;
                      setEntityListRightData([]);
                    } else if (previousDataWithTruePrimary && val.ID === previousDataWithTruePrimary.ID) {
                      val.Primary = false;
                    }
                  });
                } else if (newPrimary === false) {
                  updatedList.forEach((val) => {
                    if (val.ID === e.changes[0].data.ID) {
                      val.Primary = false;
                    }
                  });
                } else {
                  updatedList.forEach((val) => {
                    if (previousDataWithTruePrimary && val.ID === previousDataWithTruePrimary.ID) {
                      val.Primary = true;
                    }
                  });
                }
                dispatch(setContectEntityListTableAddData2(updatedList));
                return;
              } else {
                settabledataa(
                  tabledataa.map((val) => {
                    if (val.ID === e.changes[0].data.ID) {
                      return e.changes[0].data;
                    } else {
                      return val;
                    }
                  })
                );
              }
            }
          }
        }}
        onRowUpdating={(e) => {
          if (tabledata && editParticipant) {
            newrowData({ data: e, name: "edit" });
          }
        }}
        onRowRemoving={(e) => {
          if (tabledata && editParticipant) {
            newrowData({ data: e, name: "delete" });
          }
          if (!editParticipant) {
            if (contactsEntityList) {
              dispatch(
                setContectEntityListTableAddData2(
                  ContectEntityListTableAddData2?.filter((val) => {
                    if (val.ID !== e.data.ID) {
                      return val;
                    }
                  })
                )
              );
              return;
            }
            settabledataa(
              tabledataa.filter((val) => {
                if (val.ID !== e.data.ID) {
                  return val;
                }
              })
            );
          }
        }}
      >
        <HeaderFilter visible={true} allowSearch={true} />

        <Editing
          mode="row"
          allowAdding={editTable}
          allowDeleting={editTable && !contactsEntityList}
          allowUpdating={editTable}
        />
        <StateStoring savingTimeout={10} enabled={true} type="localStorage" storageKey={coloumWidthText} />
        {selection && <Selection mode="multiple" showCheckBoxesMode="always" />}
        {documentWizardDocketsSelection && <Selection mode="multiple" showCheckBoxesMode="always" />}
        {documentWizardConditionsViolationsSelection && <Selection mode="multiple" showCheckBoxesMode="always" />}
        <Summary>
          <TotalItem column={mandatoryColoum} summaryType="count" customizeText={customizeDate} />
          {amountPayment && (
            <TotalItem
              column="Amount"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {PaymentCreditsMenu && (
            <TotalItem
              column="Amount"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {showTotalBlock && (
            <TotalItem
              column="Arrears"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {showTotalBlock && (
            <TotalItem
              column="Amount"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {showTotalBlock && (
            <TotalItem
              column="Paid"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {showTotalBlock && (
            <TotalItem
              column="Write off"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {showTotalBlock && (
            <TotalItem
              column="Balance"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {showTotalBlock && (
            <TotalItem
              column="On hand"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {plannedMenu && (
            <TotalItem
              column="Hours Ordered"
              summaryType="sum"
              valueFormat={{ type: "fixedPoint", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {plannedMenu && (
            <TotalItem
              column="Hours Completed"
              summaryType="sum"
              valueFormat={{ type: "fixedPoint", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {plannedMenu && (
            <TotalItem
              column="Hours Remaining"
              summaryType="sum"
              valueFormat={{ type: "fixedPoint", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {completedMenu && (
            <TotalItem
              column="Hours Completed"
              summaryType="sum"
              valueFormat={{ type: "fixedPoint", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {recipientsMenu && (
            <TotalItem
              column="Amount on Hand"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {recipientsMenu && (
            <TotalItem
              column="Amount to Disburse"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {recipientsMenu && (
            <TotalItem
              column="Eligible to Disburse"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {recipientsMenu && (
            <TotalItem
              column="Amount Disbursed"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {recipientsMenu && (
            <TotalItem
              column="Receipt Balance"
              summaryType="sum"
              valueFormat={{ type: "fixedPoint", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {disbursementCheckListMenu && (
            <TotalItem
              column="Amount"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {disbursementObligationCheckListMenu && (
            <TotalItem
              column="Amount on Hand"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {disbursementObligationCheckListMenu && (
            <TotalItem
              column="Amount in Check"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {monitoringAgencyInvoicesMenu && (
            <TotalItem
              column="Amount"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {monitoringAgencyInvoicesMenu && (
            <TotalItem
              column="Amount Paid"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {monitoringAgencyInvoicesMenu && (
            <TotalItem
              column="Balance"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}

          {createInvoiceMenu && (
            <TotalItem
              column="Rate"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {createInvoiceMenu && (
            <TotalItem
              column="Agency Amount"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
              displayFormat="{0}"
            />
          )}
          {createInvoiceMenu && (
            <TotalItem
              column="Days"
              summaryType="sum"
              valueFormat={{ type: "fixedpoint", precision: 0 }}
              displayFormat="{0}"
            />
          )}
        </Summary>

        {Tablecoloum.map((val, i) => {
          if (val?.dropdown) {
            return (
              <Column dataField={val.title} caption={val.title} width={170}>
                <RequiredRule />
                <Lookup dataSource={contactsListEntity} displayExpr="value" valueExpr={"key"} />
              </Column>
            );
          } else if (val?.dropdown1) {
            return (
              <Column dataField={val.title} caption={val.title} width={170}>
                <RequiredRule />
                <Lookup dataSource={contactsListPosition} displayExpr="value" valueExpr={"key"} />
              </Column>
            );
          } else if (val?.requiredColoumWhileEdit) {
            return (
              <Column dataField={val.title} caption={val.title} width={170}>
                <RequiredRule />
              </Column>
            );
          } else {
            return (
              <Column
                type={val.action || val.moreoption ? "buttons" : ""}
                width={val.customwidth ? val.customwidth : "auto"}
                alignment={val?.bool ? "center" : "left"}
                dataField={val.title}
                caption={val.title}
                format={
                  (val?.currency && { type: "currency", precision: 2 }) ||
                  (val?.decimalFormat && { type: "fixedPoint", precision: 2 })
                }
                resizable={true}
                dataType={
                  val?.date ? "date" : val?.number ? "number" : val?.bool ? "boolean" : val?.datetime ? "datetime" : ""
                }
                cellRender={
                  val?.action
                    ? (rowData) => {
                      return (
                        <>
                          <div className="adminTableButtons">
                            <div
                              className="adminTableButton"
                              onClick={() => {
                                navigate(`/administration/users/${rowData.data.GuserID}`);
                                dispatch(
                                  setDynamicTab({
                                    title: rowData.data["User ID"],
                                    link: `/administration/users/${rowData.data.GuserID}`,
                                  })
                                );
                                dispatch(
                                  AdminUserListID({
                                    userListID: rowData.data.GuserID,
                                  })
                                );
                              }}
                            >
                              <svg
                                class="svg-black-white"
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                              >
                                <path
                                  d="M0.25 11.093V13.373C0.25 13.583 0.415 13.748 0.625 13.748H2.905C3.0025 13.748 3.1 13.7105 3.1675 13.6355L11.3575 5.45297L8.545 2.64047L0.3625 10.823C0.2875 10.898 0.25 10.988 0.25 11.093ZM13.5325 3.27797C13.825 2.98547 13.825 2.51297 13.5325 2.22047L11.7775 0.465469C11.485 0.172969 11.0125 0.172969 10.72 0.465469L9.3475 1.83797L12.16 4.65047L13.5325 3.27797Z"
                                  fill="black"
                                />
                              </svg>
                            </div>
                            <div className="adminTableButton">
                              <svg
                                class="svg-black-white"
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="14"
                                viewBox="0 0 22 14"
                                fill="none"
                              >
                                <path
                                  d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"
                                  fill="black"
                                />
                              </svg>
                            </div>
                          </div>
                        </>
                      );
                    }
                    : val.select
                      ? (rowData) => {
                        const handleCheckboxChange = (event) => {
                          const checkboxChecked = event.target.checked;
                          const updatedGroupResult = [...groupResult];

                          if (checkboxChecked) {
                            updatedGroupResult.push({
                              groupId: rowData.data.groupId,
                            });
                          } else {
                            const index = updatedGroupResult.findIndex((item) => item.groupId === rowData.data.groupId);
                            if (index !== -1) {
                              updatedGroupResult.splice(index, 1);
                            }
                          }
                          setGroupResult(updatedGroupResult);
                        };
                        return (
                          <>
                            <input
                              type="checkbox"
                              name=""
                              id=""
                              disabled={typeof checkEditable === "boolean" ? !checkEditable : false}
                              checked={groupResult.some((item) => item.groupId === rowData.data.groupId)}
                              onChange={handleCheckboxChange}
                            />
                          </>
                        );
                      }
                      : val.entity
                        ? (rowData) => {
                          const handleCheckboxChange = (event) => {
                            const checkboxChecked = event.target.checked;
                            const updatedResult = [...entityResult];

                            if (checkboxChecked) {
                              updatedResult.push({
                                entityId: rowData.data.entityId,
                              });
                            } else {
                              const index = updatedResult.findIndex((item) => item.entityId === rowData.data.entityId);
                              if (index !== -1) {
                                updatedResult.splice(index, 1);
                              }
                            }
                            setEntityResult(updatedResult);
                          };
                          return (
                            <>
                              <input
                                type="checkbox"
                                name="select"
                                id="select"
                                disabled={typeof checkEditable === "boolean" ? !checkEditable : false}
                                checked={entityResult.some((item) => item.entityId === rowData.data.entityId)}
                                onChange={handleCheckboxChange}
                              />
                            </>
                          );
                        }
                        : val.default
                          ? (rowData) => {
                            return (
                              <>
                                <input
                                  type="checkbox"
                                  name="isDefault"
                                  id="isDefault"
                                  disabled={typeof checkEditable === "boolean" ? !checkEditable : false}
                                  checked={entityResult?.some((item) => {
                                    return item.entityId === rowData.data.entityId && item.isDefault;
                                  })}
                                  onChange={() => {
                                    setEntityResult((prev) => {
                                      return prev.map((entity) => {
                                        if (entity.entityId) {
                                          if (entity.isDefault) {
                                            return { ...entity, isDefault: false };
                                          } else {
                                            return { ...entity, isDefault: true };
                                          }
                                        }
                                        return entity;
                                      });
                                    });
                                  }}
                                />
                              </>
                            );
                          }
                          : val.receipt
                            ? (rowData) => {
                              return (
                                <>
                                  <img
                                    src={ReceiptIcon}
                                    alt=""
                                    onClick={() => {
                                      handleReceiptNumber(rowData?.data?.["Receipt #"]);
                                    }}
                                  />
                                </>
                              );
                            }
                            : val.pause
                              ? (rowData) => {
                                return <>{rowData?.data?.Status && <img src={PauseIcon} alt="" />}</>;
                              }
                              : val.pause1
                                ? (rowData) => {
                                  return <>{rowData?.data?.bIsTolled && <img src={PauseIcon} alt="" />}</>;
                                }
                                : val.writeOFF
                                  ? (rowData) => {
                                    return (
                                      <>
                                        <input
                                          type="checkbox"
                                          name=""
                                          id=""
                                          defaultChecked={rowData?.data?.[`Write Off Reason`]?.length}
                                          readOnly
                                          style={{ pointerEvents: "none" }}
                                        />
                                      </>
                                    );
                                  }
                                  : val.LinkFeeSchedule
                                    ? (rowData) => {
                                      return (
                                        <div
                                          style={{
                                            width: "100%",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            textDecoration: val?.LinkFeeSchedule && "underline",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setFindFeeSchedulePopup(false);
                                            dispatch(removeAdminTab("Fee Schedule"));
                                            saveCookieCrum(rowData?.data?.gFeeScheduleId);
                                            navigate(`/administration/users/feeschedule/${rowData?.data?.gFeeScheduleId}`);
                                            dispatch(
                                              setDynamicTab({
                                                title: `Fee Schedule: ${rowData?.data?.sLocalLegalCode}`,
                                                link: `/administration/users/feeschedule/${rowData?.data?.gFeeScheduleId}`,
                                              })
                                            );
                                          }}
                                        >
                                          {rowData?.data?.[`Local Legal Code`]}
                                        </div>
                                      );
                                    }
                                    : val.linkContacts
                                      ? (rowData) => {
                                        return (
                                          <div
                                            style={{
                                              width: "100%",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              textDecoration: val?.linkContacts && "underline",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              setFindContactPopup(false);
                                              dispatch(removeProntactContactsdynamicTab("Contacts"));
                                              navigate(`/prontact/contacts/${rowData?.data?.gContactID}`);
                                              dispatch(
                                                setDynamicTab({
                                                  title: `Contacts: ${rowData?.data?.Name}`,
                                                  link: `/prontact/contacts/${rowData?.data?.gContactID}`,
                                                })
                                              );
                                            }}
                                          >
                                            {rowData?.data?.Name}
                                          </div>
                                        );
                                      }
                                      : val.testDateCheckBox1
                                        ? (rowData) => {
                                          let check = false;
                                          data2?.rowDataCheck?.map?.((val) => {
                                            if (val?.["Slot 1"] && val?.rowId == rowData?.data?.ID) {
                                              check = true;
                                            }
                                          });
                                          return (
                                            <>
                                              <input
                                                type="checkbox"
                                                name="Slot 1"
                                                id="Slot 1"
                                                checked={check}
                                                onChange={(e) => {
                                                  updateCheckboxData(rowData?.data, "Slot 1", e.target.checked);
                                                }}
                                              />
                                            </>
                                          );
                                        }
                                        : val.testDateCheckBox2
                                          ? (rowData) => {
                                            let check = false;
                                            data2?.rowDataCheck?.map?.((val) => {
                                              if (val?.["Slot 2"] && val?.rowId == rowData?.data?.ID) {
                                                check = true;
                                              }
                                            });
                                            return (
                                              <>
                                                <input
                                                  type="checkbox"
                                                  name="Slot 2"
                                                  id="Slot 2"
                                                  checked={check}
                                                  onChange={(e) => {
                                                    updateCheckboxData(rowData?.data, "Slot 2", e.target.checked);
                                                  }}
                                                />
                                              </>
                                            );
                                          }
                                          : val.testDateCheckBox3
                                            ? (rowData) => {
                                              let check = false;
                                              data2?.rowDataCheck?.map?.((val) => {
                                                if (val?.["Slot 3"] && val?.rowId == rowData?.data?.ID) {
                                                  check = true;
                                                }
                                              });
                                              return (
                                                <>
                                                  <input
                                                    type="checkbox"
                                                    name="Slot 3"
                                                    id="Slot 3"
                                                    checked={check}
                                                    onChange={(e) => {
                                                      updateCheckboxData(rowData?.data, "Slot 3", e.target.checked);
                                                    }}
                                                  />
                                                </>
                                              );
                                            }
                                            : val.testDateCheckBox4
                                              ? (rowData) => {
                                                let check = false;
                                                data2?.rowDataCheck?.map?.((val) => {
                                                  if (val?.["Slot 4"] && val?.rowId == rowData?.data?.ID) {
                                                    check = true;
                                                  }
                                                });
                                                return (
                                                  <>
                                                    <input
                                                      type="checkbox"
                                                      name="Slot 4"
                                                      id="Slot 4"
                                                      checked={check}
                                                      onChange={(e) => {
                                                        updateCheckboxData(rowData?.data, "Slot 4", e.target.checked);
                                                      }}
                                                    />
                                                  </>
                                                );
                                              }
                                              : val.sendEmailCheckData
                                                ? (rowData) => {
                                                  const handleCheckboxChange = (rowData) => (e) => {
                                                    const isChecked = e.target.checked;
                                                    if (isChecked) {
                                                      setEmailSentData((prevSelectedData) => [...prevSelectedData, rowData.data]);
                                                    } else {
                                                      setEmailSentData((prevSelectedData) =>
                                                        prevSelectedData.filter(
                                                          (item) => item.gPersonContactInfoId !== rowData.data.gPersonContactInfoId
                                                        )
                                                      );
                                                    }
                                                  };
                                                  return (
                                                    <>
                                                      <input
                                                        type="checkbox"
                                                        name={rowData?.data?.gPersonContactInfoId}
                                                        id={rowData?.data?.gPersonContactInfoId}
                                                        checked={emailSentData.some(
                                                          (selectedItem) =>
                                                            selectedItem.gPersonContactInfoId === rowData.data.gPersonContactInfoId
                                                        )}
                                                        onChange={handleCheckboxChange(rowData)}
                                                      />
                                                    </>
                                                  );
                                                }
                                                : val.entityListReportCatalog
                                                  ? (rowData) => {
                                                    const handleCheckboxChange = (event) => {
                                                      const checkboxChecked = event.target.checked;
                                                      const updatedResult = [...entityListRightData];
                                                      if (checkboxChecked) {
                                                        updatedResult.push({
                                                          gEntityContactReportCatalogId:
                                                            typeFeeSchedule === "Add"
                                                              ? "00000000-0000-0000-0000-000000000000"
                                                              : rowData?.data?.gEntityContactReportCatalogId || typeFeeSchedule === "Edit"
                                                                ? "00000000-0000-0000-0000-000000000000"
                                                                : rowData?.data?.gEntityContactReportCatalogId,
                                                          gEntryUserId: localStorage.getItem("gUserId"),
                                                          gReportCatalogId: rowData?.data?.GReportCatalogId,
                                                          gEntityContactId: findContactEntitylistID ? findContactEntitylistID : "",
                                                        });
                                                      } else {
                                                        const index = updatedResult.findIndex(
                                                          (item) =>
                                                            item?.gReportCatalogId?.toLowerCase() === rowData?.data?.GReportCatalogId?.toLowerCase()
                                                        );
                                                        if (index !== -1) {
                                                          updatedResult.splice(index, 1);
                                                        }
                                                      }
                                                      setEntityListRightData(updatedResult);
                                                    };
                                                    return (
                                                      <>
                                                        <input
                                                          type="checkbox"
                                                          name="select"
                                                          id="select"
                                                          disabled={!editEntiyListLeftData}
                                                          checked={entityListRightData.some(
                                                            (item) =>
                                                              item?.gReportCatalogId?.toLowerCase() ===
                                                              rowData?.data?.GReportCatalogId?.toLowerCase()
                                                          )}
                                                          onChange={handleCheckboxChange}
                                                        />
                                                      </>
                                                    );
                                                  }
                                                  : val.rowClickData
                                                    ? (rowData) => {
                                                      const getRowData = () => {
                                                        setRecipientsClickRowData(rowData?.data);
                                                      };
                                                      return (
                                                        <>
                                                          <p onClick={getRowData} style={{ cursor: "pointer" }}>
                                                            {rowData?.data?.[`Disburse Frequency`]}
                                                          </p>
                                                        </>
                                                      );
                                                    }
                                                    : val.selectAllCustomCheckBox
                                                      ? (rowData) => {
                                                        const handleRowClick = (row) => {
                                                          const isSelected = selectedRowCheckbox?.some((selectedRow) => selectedRow?.ID === row?.ID);
                                                          if (isSelected) {
                                                            setSelectedRowCheckbox(
                                                              selectedRowCheckbox?.filter((selectedRow) => selectedRow?.ID !== row?.ID)
                                                            );
                                                          } else {
                                                            setSelectedRowCheckbox([...selectedRowCheckbox, row]);
                                                          }
                                                        };
                                                        return (
                                                          <>
                                                            <input
                                                              type="checkbox"
                                                              checked={selectedRowCheckbox?.some(
                                                                (selectedRow) => selectedRow?.ID === rowData?.data?.ID
                                                              )}
                                                              onChange={() => handleRowClick(rowData?.data)}
                                                            />
                                                          </>
                                                        );
                                                      }
                                                      : val?.moreoption && formsPageMenu
                                                        ? (rowData) => {
                                                          return (
                                                            <>
                                                              <div className="add_navBar_hamburger" id="threedots" onClick={() => { }}>
                                                                <ThreeDotMenu />
                                                                <ContextMenu
                                                                  showEvent="dxclick"
                                                                  dataSource={[
                                                                    {
                                                                      text: "Add to Existing Person",
                                                                      icon: "add",
                                                                    },
                                                                    {
                                                                      text: "Create New Person",
                                                                      icon: "user",
                                                                    },
                                                                    {
                                                                      text: "View Form (PDF)",
                                                                      icon: "pdffile",
                                                                    },
                                                                    {
                                                                      text: "Remove From List",
                                                                      icon: "trash",
                                                                    },
                                                                  ]}
                                                                  target="#threedots"
                                                                  onItemClick={(e) => {
                                                                    if (e.itemData.text === "Add to Existing Person") {
                                                                      setFormsPageMenu(false);
                                                                      setAddExistingPersonPopup(true);
                                                                      setFormsRowType("Existing Person");
                                                                    } else if (e.itemData.text === "Create New Person") {
                                                                      setCreateNewPersonPopup(true);
                                                                      setFormsRowType("New Person");
                                                                    } else if (e.itemData.text === "View Form (PDF)") {
                                                                      viewFormPdfApi(formRowData?.gFormId, formRowData?.["File Name"]);
                                                                    } else if (e.itemData.text === "Remove From List") {
                                                                      // removeFormsListApi(formRowData?.gFormId);
                                                                      // getFeeScheduleTableDataApi();
                                                                      setRemovePersonListPopup(true);
                                                                    }
                                                                  }}
                                                                />
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                        : val?.moreoption && obligationMontoringEmPeriodMenu
                                                          ? (rowData) => {
                                                            return (
                                                              <>
                                                                <div className="add_navBar_hamburger" id="threedots" onClick={() => { }}>
                                                                  <ThreeDotMenu />
                                                                  <ContextMenu
                                                                    showEvent="dxclick"
                                                                    dataSource={[
                                                                      {
                                                                        text: "Edit EM Period",
                                                                        icon: "edit",
                                                                      },
                                                                      {
                                                                        text: "Void",
                                                                        icon: "remove",
                                                                      },
                                                                    ]}
                                                                    target=".dx-data-row"
                                                                    onItemClick={(e) => {
                                                                      if (e.itemData.text === "Edit EM Period") {
                                                                        setRowLevelData((prev) => ({ ...prev, ...rowData.data }));
                                                                        SetTypeTab("Edit");
                                                                        setAddEditEMPeriodPopup(true);
                                                                      }
                                                                      if (e.itemData.text === "Void") {
                                                                        setVoidAlertPopup(true);
                                                                      }
                                                                    }}
                                                                  />
                                                                </div>
                                                              </>
                                                            );
                                                          }
                                                          : val?.moreoption && addEquipmentMenu
                                                            ? (rowData) => {
                                                              return (
                                                                <>
                                                                  <div
                                                                    className="add_navBar_hamburger"
                                                                    id="threedots"
                                                                    onClick={() => {
                                                                      setEquimentRowData(rowData.data);
                                                                    }}
                                                                  >
                                                                    <ThreeDotMenu />
                                                                    <ContextMenu
                                                                      showEvent="dxclick"
                                                                      dataSource={[
                                                                        {
                                                                          text: "Edit Equipment",
                                                                          icon: "edit",
                                                                        },
                                                                      ]}
                                                                      target=".dx-data-row"
                                                                      onItemClick={(e) => {
                                                                        if (e.itemData.text === "Edit Equipment") {
                                                                          setAddEquipmentPopupType("Edit");
                                                                          setAddEquipmentPopup(true);
                                                                        }
                                                                      }}
                                                                    />
                                                                  </div>
                                                                </>
                                                              );
                                                            }
                                                            : val?.moreoption && monitoringRateEditorMenu
                                                              ? (rowData) => {
                                                                return (
                                                                  <>
                                                                    <div
                                                                      className="add_navBar_hamburger"
                                                                      id="threedots"
                                                                      onClick={() => {
                                                                        setRateEditorRowData(rowData.data);
                                                                      }}
                                                                    >
                                                                      <ThreeDotMenu />
                                                                      <ContextMenu
                                                                        showEvent="dxclick"
                                                                        dataSource={[
                                                                          {
                                                                            text: "Edit Rate",
                                                                            icon: "edit",
                                                                          },
                                                                        ]}
                                                                        target=".dx-data-row"
                                                                        onItemClick={(e) => {
                                                                          if (e.itemData.text === "Edit Rate") {
                                                                            setAddNewRatePopupType("Edit");
                                                                            setAddNewRatePopup(true);
                                                                          }
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </>
                                                                );
                                                              }
                                                              : val?.moreoption && vendorInventoryMenu
                                                                ? (rowData) => {
                                                                  return (
                                                                    <>
                                                                      <div
                                                                        className="add_navBar_hamburger"
                                                                        id="threedots"
                                                                        onClick={() => {
                                                                          setVendorInventoryRowData(rowData.data);
                                                                        }}
                                                                      >
                                                                        <ThreeDotMenu />
                                                                        <ContextMenu
                                                                          showEvent="dxclick"
                                                                          dataSource={[
                                                                            {
                                                                              text: "Jump to Person",
                                                                              icon: "search",
                                                                              disabled: checkPersonId === null,
                                                                            },
                                                                            {
                                                                              text: "Create Person",
                                                                              icon: "add",
                                                                            },
                                                                          ]}
                                                                          target=".dx-data-row"
                                                                          onItemClick={(e) => {
                                                                            if (e.itemData.text === "Jump to Person") {
                                                                              if (!vendorInventoryRowData || !vendorInventoryRowData?.sOffenderNumber) {
                                                                                return;
                                                                              }
                                                                              dispatch(
                                                                                setDynamicTab({
                                                                                  Offender: vendorInventoryRowData?.sOffenderNumber,
                                                                                  title: vendorInventoryRowData?.["sLastFirst"]?.split(",")[0],
                                                                                })
                                                                              );
                                                                              navigate(`/participant/${vendorInventoryRowData?.sOffenderNumber}`);
                                                                            } else if (e.itemData.text === "Create Person") {
                                                                              createPersonVendorInventoryAPi();
                                                                            }
                                                                          }}
                                                                        />
                                                                      </div>
                                                                    </>
                                                                  );
                                                                }
                                                                : val?.moreoption && monitoringAgencyInvoicesMenu
                                                                  ? (rowData) => {
                                                                    return (
                                                                      <>
                                                                        <div
                                                                          className="add_navBar_hamburger"
                                                                          id="threedots"
                                                                          onClick={() => {
                                                                            setAgencyInvoicesRowData(rowData.data);
                                                                          }}
                                                                        >
                                                                          <ThreeDotMenu />
                                                                          <ContextMenu
                                                                            showEvent="dxclick"
                                                                            dataSource={[
                                                                              {
                                                                                text: "Print Invoice",
                                                                              },
                                                                              {
                                                                                text: "Void Invoice",
                                                                              },
                                                                              {
                                                                                text: "Reimport Obligations",
                                                                              },
                                                                              {
                                                                                text: "Jump to Agency Person",
                                                                                icon: "search",
                                                                              },
                                                                              {
                                                                                text: "Reallocate Payment",
                                                                              },
                                                                              {
                                                                                text: "Export Invoice to Excel",
                                                                              },
                                                                            ]}
                                                                            target=".dx-data-row"
                                                                            onItemClick={(e) => {
                                                                              if (e.itemData.text === "Print Invoice") {
                                                                                printInvoice();
                                                                              } else if (e.itemData.text === "Void Invoice") {
                                                                                setConfirmPopupType("Void Invoice");
                                                                                setConfirmPopup(true);
                                                                              } else if (e.itemData.text === "Reimport Obligations") {
                                                                              } else if (e.itemData.text === "Jump to Agency Person") {
                                                                              } else if (e.itemData.text === "Reallocate Payment") {
                                                                                setConfirmPopupType("Reallocate Payment");
                                                                                setConfirmPopup(true);
                                                                              } else if (e.itemData.text === "Export Invoice to Excel") {
                                                                                exportInvoiceToExcelApi();
                                                                              }
                                                                            }}
                                                                          />
                                                                        </div>
                                                                      </>
                                                                    );
                                                                  }
                                                                  : val?.moreoption && contextMenu
                                                                    ? (rowData) => {
                                                                      return (
                                                                        <>
                                                                          <div
                                                                            className="add_navBar_hamburger"
                                                                            id="threedots"
                                                                            onClick={() => {
                                                                              console.log("rowData inside click", rowData.data);
                                                                              financialid = rowData.data;
                                                                            }}
                                                                          >
                                                                            <ThreeDotMenu />
                                                                            <ContextMenu
                                                                              showEvent="dxclick"
                                                                              dataSource={[
                                                                                {
                                                                                  text: "Void Pronto/Cash Payment",
                                                                                  icon: "remove",
                                                                                  disabled: voidCheck === true,
                                                                                },
                                                                                { text: "Reallocate Payment", icon: "refresh", },
                                                                                { text: "Edit Payment", icon: "edit" },
                                                                                { text: "Void/Refund CC Payment" },
                                                                                { text: "Move Payment" },
                                                                                { text: "Un-allocate Payment" },
                                                                              ]}
                                                                              target="#threedots"
                                                                              onItemClick={(e) => {
                                                                                setRowInfoData(financialid);
                                                                                if (e.itemData.text === "Void Pronto/Cash Payment") {
                                                                                  setVoidPaymentPopup(true);
                                                                                } else if (e.itemData.text === "Reallocate Payment") {
                                                                                  setReallocatePaymentPopup(true);
                                                                                } else if (e.itemData.text === "Edit Payment") {
                                                                                  setEditPopup(true);
                                                                                } else if (e.itemData.text === "Void/Refund CC Payment") {
                                                                                  setrefundCCPaymentPopup(true);
                                                                                } else if (e.itemData.text === "Move Payment") {
                                                                                  setPaymentIdForMovePayment(financialid?.gPaymentId);
                                                                                  setMovePaymentPopup(true);
                                                                                } else if (e.itemData.text === "Un-allocate Payment") {
                                                                                  setPaymentIdForUnallocate(financialid?.gPaymentId);
                                                                                  setUnAllocatePopup(true);
                                                                                }
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        </>
                                                                      );
                                                                    } : val?.moreoption && contextMenus
                                                                      ? (rowData) => {
                                                                        return (
                                                                          <>
                                                                            <div
                                                                              className="add_navBar_hamburger"
                                                                              id="threedots"
                                                                              onClick={() => {
                                                                                financialid = rowData?.data;
                                                                              }}
                                                                            >
                                                                              <ThreeDotMenu />
                                                                              <ContextMenu
                                                                                showEvent="dxclick"
                                                                                dataSource={[
                                                                                  {
                                                                                    text: "Allocate Payment", icon: "refresh", disabled: rowData.data?.fAmountToAllocate <= 0 // Enable only if fAmountToAllocate > 0
                                                                                  },
                                                                                  { text: "Edit Payment", icon: "edit" },
                                                                                  { text: "Jump to Person", icon: "search" },
                                                                                  {
                                                                                    text: "Void Pronto/Cash Payment",
                                                                                    icon: "remove",
                                                                                    disabled: voidCheck === true,
                                                                                  },
                                                                                ]}
                                                                                target="#threedots"
                                                                                onItemClick={(e) => {
                                                                                  setRowInfoData(financialid);
                                                                                  if (e.itemData.text === "Edit Payment") {
                                                                                    setEditPopup(true);
                                                                                    setPaymentDates(financialid?.Date)
                                                                                    setGPaymentId(financialid?.gPaymentId)
                                                                                  } else if (e.itemData.text === "Allocate Payment") {
                                                                                    setGPersonOffenderId(financialid?.["Offender #"]);
                                                                                    setGPersonId(financialid?.gPersonId);
                                                                                    setRecivePaymentModel(true);
                                                                                  } else if (e.itemData.text === "Void Pronto/Cash Payment") {
                                                                                    setVoidProntoCashConfirmationPopup(true);
                                                                                    setAmount(financialid?.Amount);
                                                                                    setExternalPaymentId(financialid?.gExternalPaymentId);
                                                                                  } else if (e.itemData.text === "Jump to Person") {
                                                                                    dispatch(
                                                                                      setDynamicTab({
                                                                                        Offender: financialid?.["Offender #"],
                                                                                        link: `/participant/${financialid?.["Offender #"]}`,
                                                                                        title: financialid?.["Offender Name"]?.split(",")[0],
                                                                                      })
                                                                                    );
                                                                                    navigate(`/participant/${financialid?.["Offender #"]}`);
                                                                                  }
                                                                                }}
                                                                              />
                                                                            </div>
                                                                          </>
                                                                        );
                                                                      }
                                                                      : val?.moreoption && ExternalPendingPaymentsContextMenu
                                                                        ? (rowData) => {
                                                                          return (
                                                                            <>
                                                                              <div
                                                                                className="add_navBar_hamburger"
                                                                                id="threedots"
                                                                                onClick={() => {
                                                                                  selectedOp3 = rowData.data;
                                                                                }}
                                                                              >
                                                                                <ThreeDotMenu />
                                                                                <ContextMenu
                                                                                  showEvent="dxclick"
                                                                                  dataSource={[
                                                                                    { text: "Accept Payment" },
                                                                                    { text: "Jump to Person", icon: "search" },
                                                                                    {
                                                                                      text: "Reject Payment",
                                                                                      icon: "remove"
                                                                                    }
                                                                                  ]}
                                                                                  target="#threedots"
                                                                                  onItemClick={(e) => {
                                                                                    console.log('selecte', selectedOp3);
                                                                                    if (e.itemData.text === "Jump to Person" &&
                                                                                      selectedOp3["Participant Id"]) {
                                                                                      dispatch(
                                                                                        setDynamicTab({
                                                                                          link: `/participant/${selectedOp3?.["Participant Id"]}`,
                                                                                          title: selectedOp3?.["Last Name"],
                                                                                        })
                                                                                      );
                                                                                      navigate(`/participant/${selectedOp3?.["Participant Id"]}`);
                                                                                    }
                                                                                    else if (e.itemData.text === "Accept Payment") {
                                                                                      setPayment({ ...selectedOp3, mode: 'accept' });
                                                                                    }
                                                                                    else if (e.itemData.text === "Reject Payment") {
                                                                                      setPayment({ ...selectedOp3, mode: 'reject' });
                                                                                    } else {
                                                                                      alert("Please select person with participant id");
                                                                                    }
                                                                                  }
                                                                                  }
                                                                                />
                                                                              </div>
                                                                            </>
                                                                          );
                                                                        }
                                                                        : val?.moreoption && obligationMenu
                                                                          ? (rowData) => {
                                                                            return (
                                                                              <>
                                                                                <div className="add_navBar_hamburger" id="threedots">
                                                                                  <ThreeDotMenu />
                                                                                  <ContextMenu
                                                                                    showEvent="dxclick"
                                                                                    dataSource={[
                                                                                      { text: "Write Off Amount", disabled: writeOffCheck <= 0 },
                                                                                      {
                                                                                        text: "Write Off Multiple Obilgation",
                                                                                        disabled: !enableWriteOffMulti,
                                                                                      },
                                                                                      {
                                                                                        text: "Jump to Obligation",
                                                                                      },
                                                                                    ]}
                                                                                    target="#threedots"
                                                                                    onItemClick={(e) => {
                                                                                      if (e.itemData.text === "Write Off Amount") {
                                                                                        setWriteOffAmount(true);
                                                                                      } else if (e.itemData.text === "Write Off Multiple Obilgation") {
                                                                                        setWriteOffMultipleObligation(true);
                                                                                      } else if (e.itemData.text === "Jump to Obligation") {
                                                                                        const data = rowData?.data;
                                                                                        dispatch(
                                                                                          setgotooblogationValue({
                                                                                            gObligationId: data?.obligation?.gObligationId,
                                                                                            userinfo: {
                                                                                              gPersonId: data?.obligation?.gPersonId,
                                                                                            },
                                                                                          })
                                                                                        );
                                                                                        navigate(
                                                                                          `/obligation/${`${data?.obligation?.["Docket/CT"]} - ${data?.obligation?.["iCourtCounts"]}`?.replace(
                                                                                            "/",
                                                                                            "-"
                                                                                          )}/${"tempdata"}`
                                                                                        );
                                                                                      }
                                                                                    }}
                                                                                  />
                                                                                </div>
                                                                              </>
                                                                            );
                                                                          }
                                                                          : val?.moreoption && drugTestMenu
                                                                            ? (rowData) => {
                                                                              return (
                                                                                <>
                                                                                  <div className="add_navBar_hamburger" id="threedots">
                                                                                    <ThreeDotMenu />
                                                                                    <ContextMenu
                                                                                      showEvent="dxclick"
                                                                                      dataSource={[
                                                                                        {
                                                                                          text: "Edit Drug Test",
                                                                                          icon: "edit",
                                                                                        },
                                                                                        {
                                                                                          text: "Void Drug Test",
                                                                                          icon: "remove",
                                                                                          disabled: voidCheck === true,
                                                                                        },
                                                                                      ]}
                                                                                      target="#threedots"
                                                                                      onItemClick={(e) => {
                                                                                        if (e.itemData.text === "Edit Drug Test") {
                                                                                          setDoNotCreateObligation(true);
                                                                                        } else if (e.itemData.text === "Void Drug Test") {
                                                                                          setVoidPopup(true);
                                                                                        }
                                                                                      }}
                                                                                    />
                                                                                  </div>
                                                                                </>
                                                                              );
                                                                            }
                                                                            : val?.moreoption && programMenu
                                                                              ? (rowData) => {
                                                                                return (
                                                                                  <>
                                                                                    <div className="add_navBar_hamburger" id="threedots">
                                                                                      <ThreeDotMenu />
                                                                                      <ContextMenu
                                                                                        showEvent="dxclick"
                                                                                        dataSource={[
                                                                                          {
                                                                                            text: "Edit Program",
                                                                                            icon: "edit",
                                                                                            disabled: voidCheck === true,
                                                                                          },
                                                                                          {
                                                                                            text: "Void",
                                                                                            icon: "remove",
                                                                                            disabled: voidCheck === true,
                                                                                          },
                                                                                        ]}
                                                                                        target="#threedots"
                                                                                        onItemClick={(e) => {
                                                                                          if (e.itemData.text === "Edit Program") {
                                                                                            setProgramEditPopup(true);
                                                                                            setVoidCheck(rowData?.data?.Void);
                                                                                            setEditProgramData(rowData?.data);
                                                                                            setTtypeProgram("Edit");
                                                                                          } else if (e.itemData.text === "Void") {
                                                                                            setVoidPopup(true);
                                                                                          }
                                                                                        }}
                                                                                      />
                                                                                    </div>
                                                                                  </>
                                                                                );
                                                                              }
                                                                              : val?.moreoption && plannedMenu
                                                                                ? (rowData) => {
                                                                                  return (
                                                                                    <>
                                                                                      <div className="add_navBar_hamburger" id="threedots">
                                                                                        <ThreeDotMenu />
                                                                                        <ContextMenu
                                                                                          showEvent="dxclick"
                                                                                          dataSource={[
                                                                                            {
                                                                                              text: "Edit Planned",
                                                                                              icon: "edit",
                                                                                              disabled: voidCheck === true,
                                                                                            },
                                                                                            {
                                                                                              text: "Void",
                                                                                              icon: "remove",
                                                                                              disabled: voidCheck === true,
                                                                                            },
                                                                                          ]}
                                                                                          target="#threedots"
                                                                                          onItemClick={(e) => {
                                                                                            if (e.itemData.text === "Edit Planned") {
                                                                                              setAddCommunityServicePopup(true);
                                                                                            } else if (e.itemData.text === "Void") {
                                                                                              setVoidPopup(true);
                                                                                            }
                                                                                          }}
                                                                                        />
                                                                                      </div>
                                                                                    </>
                                                                                  );
                                                                                }
                                                                                : val?.moreoption && completedMenu
                                                                                  ? (rowData) => {
                                                                                    return (
                                                                                      <>
                                                                                        <div className="add_navBar_hamburger" id="threedots">
                                                                                          <ThreeDotMenu />
                                                                                          <ContextMenu
                                                                                            showEvent="dxclick"
                                                                                            dataSource={[
                                                                                              {
                                                                                                text: "Edit Completed",
                                                                                                icon: "edit",
                                                                                                disabled: voidCheck === true,
                                                                                              },
                                                                                              {
                                                                                                text: "Void",
                                                                                                icon: "remove",
                                                                                                disabled: voidCheck === true,
                                                                                              },
                                                                                            ]}
                                                                                            target="#threedots"
                                                                                            onItemClick={(e) => {
                                                                                              if (e.itemData.text === "Edit Completed") {
                                                                                                setAddCommunityServiceLogPopup(true);
                                                                                              } else if (e.itemData.text === "Void") {
                                                                                                setVoidPopup(true);
                                                                                              }
                                                                                            }}
                                                                                          />
                                                                                        </div>
                                                                                      </>
                                                                                    );
                                                                                  }
                                                                                  : val?.moreoption && tollingMenu
                                                                                    ? (rowData) => {
                                                                                      return (
                                                                                        <>
                                                                                          <div className="add_navBar_hamburger" id="threedots">
                                                                                            <ThreeDotMenu />
                                                                                            <ContextMenu
                                                                                              showEvent="dxclick"
                                                                                              dataSource={[
                                                                                                {
                                                                                                  text: "Edit Tolling",
                                                                                                  icon: "edit",
                                                                                                  disabled: voidCheck === true,
                                                                                                },
                                                                                                {
                                                                                                  text: "Void Tolling",
                                                                                                  icon: "remove",
                                                                                                  disabled: voidCheck === true,
                                                                                                },
                                                                                              ]}
                                                                                              target="#threedots"
                                                                                              onItemClick={(e) => {
                                                                                                if (e.itemData.text === "Edit Tolling") {
                                                                                                  setEditpopupTogall(true);
                                                                                                } else if (e.itemData.text === "Void Tolling") {
                                                                                                  setVoidPopup(true);
                                                                                                }
                                                                                              }}
                                                                                            />
                                                                                          </div>
                                                                                        </>
                                                                                      );
                                                                                    }
                                                                                    : val?.moreoption && jailPlannedMenu
                                                                                      ? (rowData) => {
                                                                                        return (
                                                                                          <>
                                                                                            <div className="add_navBar_hamburger" id="threedots">
                                                                                              <ThreeDotMenu />
                                                                                              <ContextMenu
                                                                                                showEvent="dxclick"
                                                                                                dataSource={[
                                                                                                  {
                                                                                                    text: "Edit Jail Planned",
                                                                                                    icon: "edit",
                                                                                                    disabled: voidCheck === true,
                                                                                                  },
                                                                                                  {
                                                                                                    text: "Void Jail Planned",
                                                                                                    icon: "remove",
                                                                                                    disabled: voidCheck === true,
                                                                                                  },
                                                                                                ]}
                                                                                                target="#threedots"
                                                                                                onItemClick={(e) => {
                                                                                                  if (e.itemData.text === "Edit Jail Planned") {
                                                                                                    setAddJailServicePopup(true);
                                                                                                  } else if (e.itemData.text === "Void Jail Planned") {
                                                                                                    setVoidPopup(true);
                                                                                                  }
                                                                                                }}
                                                                                              />
                                                                                            </div>
                                                                                          </>
                                                                                        );
                                                                                      }
                                                                                      : val?.moreoption && jailCompletedMenu
                                                                                        ? (rowData) => {
                                                                                          return (
                                                                                            <>
                                                                                              <div className="add_navBar_hamburger" id="threedots">
                                                                                                <ThreeDotMenu />
                                                                                                <ContextMenu
                                                                                                  showEvent="dxclick"
                                                                                                  dataSource={[
                                                                                                    {
                                                                                                      text: "Edit Jail Completed",
                                                                                                      icon: "edit",
                                                                                                      disabled: voidCheck === true,
                                                                                                    },
                                                                                                    {
                                                                                                      text: "Void Jail Completed",
                                                                                                      icon: "remove",
                                                                                                      disabled: voidCheck === true,
                                                                                                    },
                                                                                                  ]}
                                                                                                  target="#threedots"
                                                                                                  onItemClick={(e) => {
                                                                                                    if (e.itemData.text === "Edit Jail Completed") {
                                                                                                      setAddJailServiceLogPopup(true);
                                                                                                    } else if (e.itemData.text === "Void Jail Completed") {
                                                                                                      setVoidPopup(true);
                                                                                                    }
                                                                                                  }}
                                                                                                />
                                                                                              </div>
                                                                                            </>
                                                                                          );
                                                                                        }
                                                                                        : val?.moreoption && warrantMenu
                                                                                          ? (rowData) => {
                                                                                            return (
                                                                                              <>
                                                                                                <div className="add_navBar_hamburger" id="threedots">
                                                                                                  <ThreeDotMenu />
                                                                                                  <ContextMenu
                                                                                                    showEvent="dxclick"
                                                                                                    dataSource={[
                                                                                                      {
                                                                                                        text: "Edit Warrant",
                                                                                                        icon: "edit",
                                                                                                        disabled: voidCheck === true,
                                                                                                      },
                                                                                                      {
                                                                                                        text: "Void Warrant",
                                                                                                        icon: "remove",
                                                                                                        disabled: voidCheck === true,
                                                                                                      },
                                                                                                    ]}
                                                                                                    target="#threedots"
                                                                                                    onItemClick={(e) => {
                                                                                                      if (e.itemData.text === "Edit Warrant") {
                                                                                                        setAddWarrentsPopup(true);
                                                                                                      } else if (e.itemData.text === "Void Warrant") {
                                                                                                        setVoidPopup(true);
                                                                                                      }
                                                                                                    }}
                                                                                                  />
                                                                                                </div>
                                                                                              </>
                                                                                            );
                                                                                          }
                                                                                          : val?.moreoption && schedulingListControl
                                                                                            ? (rowData) => {
                                                                                              return (
                                                                                                <>
                                                                                                  <div className="add_navBar_hamburger" id="threedots">
                                                                                                    <ThreeDotMenu />
                                                                                                    <ContextMenu
                                                                                                      target="#threedots"
                                                                                                      showEvent="dxclick"
                                                                                                      dataSource={[
                                                                                                        {
                                                                                                          text: "Jump to Perosn",
                                                                                                          icon: "search",
                                                                                                        },
                                                                                                        {
                                                                                                          text: "Check In",
                                                                                                          icon: "check",
                                                                                                        },
                                                                                                        {
                                                                                                          text: "Send Email",
                                                                                                          icon: "email",
                                                                                                        },
                                                                                                      ]}
                                                                                                      onItemClick={(e) => {
                                                                                                        if (e.itemData.text === "Jump to Perosn") {
                                                                                                          dispatch(
                                                                                                            setDynamicTab({
                                                                                                              Offender: rowData?.data?.["Offender Number"],
                                                                                                              title: rowData?.data?.["Offender Name"],
                                                                                                            })
                                                                                                          );
                                                                                                          navigate(`/participant/${rowData?.data?.["Offender Number"]}`);
                                                                                                        } else if (e.itemData.text === "Check In") {
                                                                                                          getCheckInApi();
                                                                                                        } else if (e.itemData.text === "Send Email") {
                                                                                                          showSendEmailPopup();
                                                                                                        }
                                                                                                      }}
                                                                                                    />
                                                                                                  </div>
                                                                                                </>
                                                                                              );
                                                                                            }
                                                                                            : val?.schedulingListControl && val?.bool
                                                                                              ? (rowData) => {
                                                                                                return (
                                                                                                  <>
                                                                                                    <input
                                                                                                      type="checkbox"
                                                                                                      checked={selectedPersonIds?.includes(rowData?.data?.gPersonId)}
                                                                                                      onChange={(e) => {
                                                                                                        if (!selectedPersonIds?.includes(rowData?.data?.gPersonId)) {
                                                                                                          setSelectedPersonIds([...selectedPersonIds, rowData?.data?.gPersonId]);
                                                                                                        } else {
                                                                                                          setSelectedPersonIds(
                                                                                                            selectedPersonIds?.filter((id) => {
                                                                                                              if (id !== rowData?.data?.gPersonId) return id;
                                                                                                            })
                                                                                                          );
                                                                                                        }
                                                                                                      }}
                                                                                                    />
                                                                                                  </>
                                                                                                );
                                                                                              }
                                                                                              : ""
                }
              />
            );
          }
        })}
        {amountPayment && <MasterDetail enabled={true} component={DetailTemplate} />}
        {emSubTabMenu && (
          <MasterDetail
            enabled={true}
            component={(p) => {
              return (
                <EmDetailTemplate
                  props={p}
                  setrowlevelchilddata={setrowlevelchilddata}
                  setParentRowData={setParentRowData}
                />
              );
            }}
          />
        )}
        <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 30, 50]} displayMode="left" />
      </DataGrid>

      {contextMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Void Pronto/Cash Payment",
              icon: "remove",
              disabled: voidCheck === true,
            },
            { text: "Reallocate Payment", icon: "refresh" },
            { text: "Edit Payment", icon: "edit" },
            { text: "Void/Refund CC Payment" },
            { text: "Move Payment" },
            { text: "Un-allocate Payment" },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Void Pronto/Cash Payment") {
              setVoidPaymentPopup(true);
            } else if (e.itemData.text === "Reallocate Payment") {
              setReallocatePaymentPopup(true);
            } else if (e.itemData.text === "Edit Payment") {
              setEditPopup(true);
            } else if (e.itemData.text === "Void/Refund CC Payment") {
              setrefundCCPaymentPopup(true);
            } else if (e.itemData.text === "Move Payment") {
              setPaymentIdForMovePayment(data?.gPaymentId);
              setMovePaymentPopup(true);
            } else if (e.itemData.text === "Un-allocate Payment") {
              setPaymentIdForUnallocate(data?.gPaymentId);
              setUnAllocatePopup(true);
            }
          }}
        />
      )}
      {contextMenus && (
        <ContextMenu
          dataSource={[
            {
              text: "Allocate Payment", icon: "refresh", disabled: data?.fAmountToAllocate <= 0 // Enable only if fAmountToAllocate > 0
            },
            { text: "Edit Payment", icon: "edit" },
            { text: "Jump to Person", icon: "search" },
            {
              text: "Void Pronto/Cash Payment",
              icon: "remove",
              disabled: voidCheck === true,
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit Payment") {
              setEditPopup(true);
              setPaymentDates(data?.Date)
            } else if (e.itemData.text === "Allocate Payment") {
              setGPersonOffenderId(data?.["Offender #"]);
              setGPersonId(data?.gPersonId);
              setRecivePaymentModel(true);
            }
            else if (e.itemData.text === "Void Pronto/Cash Payment") {
              setVoidProntoCashConfirmationPopup(true);
              setAmount(data?.Amount);
              setExternalPaymentId(data?.gExternalPaymentId);
            }
            else if (e.itemData.text === "Jump to Person") {
              dispatch(
                setDynamicTab({
                  Offender: data?.["Offender #"],
                  link: `/participant/${data?.["Offender #"]}`,
                  title: data?.["Offender Name"]?.split(",")[0],
                })
              );
              navigate(`/participant/${data?.["Offender #"]}`);
            }
          }}
        />
      )}
      {ExternalPendingPaymentsContextMenu && (
        <ContextMenu
          dataSource={[
            { text: "Accept Payment" },
            { text: "Jump to Person", icon: "search" },
            {
              text: "Reject Payment",
              icon: "remove"
            }
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            console.log('data op3', data, e);
            if (e.itemData.text === "Jump to Person" &&
              data["Participant Id"]) {
              dispatch(
                setDynamicTab({
                  link: `/participant/${data?.["Participant Id"]}`,
                  title: data?.["Last Name"],
                })
              );
              navigate(`/participant/${data?.["Participant Id"]}`);
            }
            else if (e.itemData.text === "Accept Payment") {
              setPayment({ ...data, mode: 'accept' });
            }
            else if (e.itemData.text === "Reject Payment") {
              setPayment({ ...data, mode: 'reject' });
            } else {
              alert("Please select person with participant id");
            }
          }}
        />
      )}
      {PaymentCreditsMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Void Pronto/Cash Payment",
              icon: "remove",
              disabled: voidCheck === true,
            },
            { text: "Reallocate Payment", icon: "refresh" },
            { text: "Edit Payment", icon: "edit" },
            { text: "Void/Refund CC Payment" },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Void Pronto/Cash Payment") {
              setVoidCashPaymentPopup(true);
            } else if (e.itemData.text === "Reallocate Payment") {
              setReallocatePaymentPopup(true);
            } else if (e.itemData.text === "Edit Payment") {
              setEditVoidCashPaymentPopup(true);
            } else if (e.itemData.text === "Void/Refund CC Payment") {
              setVoidRefundCCPaymentPopup(true);
            }
          }}
        />
      )}
      {obligationMenu && (
        <ContextMenu
          dataSource={[
            { text: "Write Off Amount", disabled: writeOffCheck <= 0 },
            {
              text: "Write Off Multiple Obilgation",
              disabled: !enableWriteOffMulti,
            },
            {
              text: "Jump to Obligation",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Write Off Amount") {
              setWriteOffAmount(true);
            } else if (e.itemData.text === "Write Off Multiple Obilgation") {
              setWriteOffMultipleObligation(true);
            } else if (e.itemData.text === "Jump to Obligation") {
              dispatch(
                setgotooblogationValue({
                  gObligationId: data?.obligation?.gObligationId,
                  userinfo: {
                    gPersonId: data?.obligation?.gPersonId,
                  },
                })
              );
              navigate(
                `/obligation/${`${data?.obligation?.["Docket/CT"]} - ${data?.obligation?.["iCourtCounts"]}`?.replace(
                  "/",
                  "-"
                )}/${"tempdata"}`
              );
            }
          }}
        />
      )}
      {programMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit Program",
              icon: "edit",
              disabled: voidCheck === true,
            },
            {
              text: "Void",
              icon: "remove",
              disabled: voidCheck === true,
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit Program") {
              setProgramEditPopup(true);
            } else if (e.itemData.text === "Void") {
              setVoidPopup(true);
            }
          }}
        />
      )}
      {plannedMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit Planned",
              icon: "edit",
              disabled: voidCheck === true,
            },
            {
              text: "Void",
              icon: "remove",
              disabled: voidCheck === true,
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit Planned") {
              setAddCommunityServicePopup(true);
            } else if (e.itemData.text === "Void") {
              setVoidPopup(true);
            }
          }}
        />
      )}
      {completedMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit Completed",
              icon: "edit",
              disabled: voidCheck === true,
            },
            {
              text: "Void",
              icon: "remove",
              disabled: voidCheck === true,
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit Completed") {
              setAddCommunityServiceLogPopup(true);
            } else if (e.itemData.text === "Void") {
              setVoidPopup(true);
            }
          }}
        />
      )}
      {warrantMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit Warrant",
              icon: "edit",
              disabled: voidCheck === true,
            },
            {
              text: "Void Warrant",
              icon: "remove",
              disabled: voidCheck === true,
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit Warrant") {
              setAddWarrentsPopup(true);
            } else if (e.itemData.text === "Void Warrant") {
              setVoidPopup(true);
            }
          }}
        />
      )}
      {tollingMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit Tolling",
              icon: "edit",
              disabled: voidCheck === true,
            },
            {
              text: "Void Tolling",
              icon: "remove",
              disabled: voidCheck === true,
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit Tolling") {
              setEditpopupTogall(true);
            } else if (e.itemData.text === "Void Tolling") {
              setVoidPopup(true);
            }
          }}
        />
      )}
      {jailPlannedMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit Jail Planned",
              icon: "edit",
              disabled: voidCheck === true,
            },
            {
              text: "Void Jail Planned",
              icon: "remove",
              disabled: voidCheck === true,
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit Jail Planned") {
              setAddJailServicePopup(true);
            } else if (e.itemData.text === "Void Jail Planned") {
              setVoidPopup(true);
            }
          }}
        />
      )}
      {jailCompletedMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit Jail Completed",
              icon: "edit",
              disabled: voidCheck === true,
            },
            {
              text: "Void Jail Completed",
              icon: "remove",
              disabled: voidCheck === true,
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit Jail Completed") {
              setAddJailServiceLogPopup(true);
            } else if (e.itemData.text === "Void Jail Completed") {
              setVoidPopup(true);
            }
          }}
        />
      )}
      {drugTestMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit Drug Test",
              icon: "edit",
            },
            {
              text: "Void Drug Test",
              icon: "remove",
              disabled: voidCheck === true,
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit Drug Test") {
              setDoNotCreateObligation(true);
            } else if (e.itemData.text === "Void Drug Test") {
              setVoidPopup(true);
            }
          }}
        />
      )}
      {formsPageMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Add to Existing Person",
              icon: "add",
            },
            {
              text: "Create New Person",
              icon: "user",
            },
            {
              text: "View Form (PDF)",
              icon: "pdffile",
            },
            {
              text: "Remove From List",
              icon: "trash",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Add to Existing Person") {
              setFormsPageMenu(false);
              setAddExistingPersonPopup(true);
              setFormsRowType("Existing Person");
            } else if (e.itemData.text === "Create New Person") {
              setCreateNewPersonPopup(true);
              setFormsRowType("New Person");
            } else if (e.itemData.text === "View Form (PDF)") {
              viewFormPdfApi(formRowData?.gFormId, formRowData?.["File Name"]);
            } else if (e.itemData.text === "Remove From List") {
              // removeFormsListApi(formRowData?.gFormId);
              // getFeeScheduleTableDataApi();
              setRemovePersonListPopup(true);
            }
          }}
        />
      )}
      {reportDateRange && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump to Perosn",
              icon: "search",
            },
            {
              text: "Check In",
              icon: "check",
            },
            {
              text: "Send Email",
              icon: "email",
            },
          ]}
          target=".dx-data-row"

          onItemClick={(e) => {
            if (e.itemData.text === "Jump to Perosn") {

              dispatch(
                setDynamicTab({
                  Offender: reportDateRowData?.["Offender Number"],
                  title: reportDateRowData?.["Offender Name"],
                })
              );
              navigate(`/participant/${reportDateRowData?.["Offender Number"]}`);
            } else if (e.itemData.text === "Check In") {
              getCheckInApi();
            } else if (e.itemData.text === "Send Email") {
              showSendEmailPopup();
            }
          }}
        />
      )}
      {drugTestScheduleMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit Test Date",
              icon: "edit",
            },
            {
              text: "Jump to Perosn",
              icon: "search",
            },
            {
              text: "Void This Date",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit Test Date") {
              setDrugTestScheduleType("Edit");
              setAddEditDrugTestSchedulePopup(true);
            } else if (e.itemData.text === "Jump to Perosn") {
              dispatch(
                setDynamicTab({
                  Offender: drugTestScheduleRowData?.["Offender Number"],
                  title: drugTestScheduleRowData?.["Person Name"].split(",")[0],
                })
              );
              navigate(`/participant/${drugTestScheduleRowData?.["Offender Number"]}`);
            } else if (e.itemData.text === "Void This Date") {
              setVoidPopup(true);
            }
          }}
        />
      )}
      {jumpContactsListToEntityList && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump to Entity",
              icon: "search",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump to Entity") {
              console.log('jumpRowData?.Entity', jumpRowData?.Entity);
              dispatch(
                setDynamicTab({
                  title: `Entity : ${jumpRowData?.sEntityName}`,
                  Offender: `/prontact/entity/${jumpRowData?.Entity}`,
                  entityid: jumpRowData?.Entity,
                })
              );
              // saveCookieCrum(jumpRowData?.Entity)
              navigate(`/prontact/entity/${jumpRowData?.Entity}`);
              dispatch(removeProntactEntitiesdynamicTab(`/prontact/entity`));
            }
          }}
        />
      )}
      {randmoizerSummaryMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump to Perosn",
              icon: "search",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump to Perosn") {
              setRandomizerSummaryPopUpshow(false);
              dispatch(
                setDynamicTab({
                  Offender: randomizerSummaryRowData?.["Offender Number"],
                  title: randomizerSummaryRowData?.sLastName,
                })
              );
              navigate(`/participant/${randomizerSummaryRowData?.sOffenderNumber}`);
            }
          }}
        />
      )}
      {recipientsMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit Recipients",
              icon: "edit",
            },
            {
              text: "Void Recipients",
              icon: "remove",
              disabled: voidCheck === true,
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit Recipients") {
              setRecipientsType("Edit");
              setAddRecipientsPopup(true);
            } else if (e.itemData.text === "Void Recipients") {
              setVoidPopup(true);
            }
          }}
        />
      )}
      {disbursementCheckListMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump to Check Obligation List",
              icon: "search",
            },
            {
              text: "Print Checks",
              icon: "print",
            },
            {
              text: "Change Check Number",
              icon: "edit",
              disabled: voidCheck === true,
            },
            {
              text: "Void Recipients",
              icon: "remove",
              disabled: voidCheck === true,
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump to Check Obligation List") {
              dispatch(
                setdisbursementCheckObligationListDate({
                  startDate: disbursementRowCheckData?.[`Check Date`],
                  endDate: disbursementRowCheckData?.Created,
                })
              );
              dispatch(
                setFinancialTabs({
                  title: "Disbursement Check Obligation List",
                  link: "/financial/disbursement/obligation-list",
                })
              );
              navigate("/financial/disbursement/obligation-list");
            } else if (e.itemData.text === "Print Checks") {
              setPrintCheckPopup(true);
            } else if (e.itemData.text === "Change Check Number") {
              setChangeCheckNumberPopup(true);
            } else if (e.itemData.text === "Void Recipients") {
              setVoidPopup(true);
            }
          }}
        />
      )}
      {disbursementObligationCheckListMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump to Obligation",
              icon: "search",
            },
            {
              text: "Jump to Person",
              icon: "search",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump to Obligation") {
              if (!disbursementObligationRowListData?.gObligationID) {
                return;
              }
              dispatch(
                setDynamicTab({
                  Offender: `/obligation/${`${disbursementObligationRowListData?.["sReference"]} - ${disbursementObligationRowListData?.["sReference"]}`?.replace(
                    "/",
                    "-"
                  )}/${"tempdata"}`,
                  title: `Obligation : ${`${disbursementObligationRowListData?.["sReference"]} - ${disbursementObligationRowListData?.["sReference"]}`} / ${"tempdata"}`,
                  sDocketNumberCourtCounts: `${disbursementObligationRowListData?.["sReference"]} - ${disbursementObligationRowListData?.["sReference"]}`,
                  ObligationRef: "tempdata",
                  gObligationId: disbursementObligationRowListData?.gObligationID,
                  userinfo: {
                    ...disbursementObligationRowListData?.userinfo,
                    gPersonId: disbursementObligationRowListData?.userinfo?.gPersonID,
                  },
                })
              );
              dispatch(
                setgotooblogationValue({
                  gObligationId: disbursementObligationRowListData?.gObligationID,
                  userinfo: {
                    ...disbursementObligationRowListData?.userinfo,
                    gPersonId: disbursementObligationRowListData?.userinfo?.gPersonID,
                  },
                })
              );
              navigate(
                `/obligation/${`${disbursementObligationRowListData?.["sReference"]} - ${disbursementObligationRowListData?.["sReference"]}`?.replace(
                  "/",
                  "-"
                )}/${"tempdata"}`
              );
            } else if (e.itemData.text === "Jump to Person") {
              if (!disbursementObligationRowListData || !disbursementObligationRowListData?.["Offender #"]) {
                return;
              }
              dispatch(
                setDynamicTab({
                  Offender: disbursementObligationRowListData?.["Offender #"],
                  title: disbursementObligationRowListData?.["Person"]?.split(",")[0],
                })
              );
              navigate(`/participant/${disbursementObligationRowListData?.["Offender #"]}`);
            }
          }}
        />
      )}
      {addEquipmentMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit Equipment",
              icon: "edit",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit Equipment") {
              setAddEquipmentPopupType("Edit");
              setAddEquipmentPopup(true);
            }
          }}
        />
      )}
      {vendorInventoryMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump to Person",
              icon: "search",
              disabled: checkPersonId === null,
            },
            {
              text: "Create Person",
              icon: "add",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump to Person") {
              if (!vendorInventoryRowData || !vendorInventoryRowData?.sOffenderNumber) {
                return;
              }
              dispatch(
                setDynamicTab({
                  Offender: vendorInventoryRowData?.sOffenderNumber,
                  title: vendorInventoryRowData?.["sLastFirst"]?.split(",")[0],
                })
              );
              navigate(`/participant/${vendorInventoryRowData?.sOffenderNumber}`);
            } else if (e.itemData.text === "Create Person") {
              createPersonVendorInventoryAPi();
            }
          }}
        />
      )}
      {monitoringReferralMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Create New Person",
              icon: "add",
            },
            {
              text: "Jump to Person",
              icon: "search",
              disabled: checkPersonId === null,
            },
            {
              text: "View Referral",
            },
            {
              text: "View Attachment",
              disabled: viewAttachment === "",
            },
            {
              text: "Link To Person",
            },
            {
              text: "Remove From List",
              icon: "trash",
            },
            {
              text: "Process Schedule Change",
            },
            {
              text: "Process End Monitoring",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Create New Person") {
              setCreateNewPersonPopup(true);
            } else if (e.itemData.text === "Jump to Person") {
              if (!monitoringReferralRowData || !monitoringReferralRowData?.["Offender No"]) {
                return;
              }
              dispatch(
                setDynamicTab({
                  Offender: monitoringReferralRowData?.["Offender No"],
                  title: monitoringReferralRowData?.["sLastFirst"]?.split(",")[0],
                })
              );
              navigate(`/participant/${monitoringReferralRowData?.["Offender No"]}`);
            } else if (e.itemData.text === "View Referral") {
              setReferralPreviewPopup(true);
            } else if (e.itemData.text === "View Attachment") {
              window.open(viewAttachment, "_blank");
            } else if (e.itemData.text === "Link To Person") {
              setFormsPageMenu(false);
              setLinkPersonReferral(true);
            } else if (e.itemData.text === "Remove From List") {
              setRemoveFromListPopup(true);
            } else if (e.itemData.text === "Process Schedule Change") {
              ProcessScheduleChangeapi(
                data?.gReferralId,
                data?.["Form Id"],
                data?.["Entry No"],
                data?.Form,
                data?.["Last Name"],
                data?.["First Name"],
                data?.["Middle Name"],
                data?.DOB,
                data?.SAddress1,
                data?.sAddress2,
                data?.sCity,
                data?.sState,
                data?.sZip,
                data?.sHomePhone,
                data?.sCellPhone,
                data?.dDoe,
                data?.sStatus,
                data?.gPersonId,
                data?.sEntryData,
                data?.sAttachmentName,
                data?.sAttachmentUrl,
                data?.sRecordType,
                data?.sAgent,
                data?.sAgentEmail,
                data?.sAgentPhone,
                data?.sOffenderNumber,
                data?.sLastFirst,
                data?.sGpmEntityName,
                data?.Supervisor,
                data?.Company,
                data?.Attachment
              );
            } else if (e.itemData.text === "Process End Monitoring") {
              processEndMonitoringApi();
            }
          }}
        />
      )}
      {monitoringRateEditorMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit Rate",
              icon: "edit",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit Rate") {
              setAddNewRatePopupType("Edit");
              setAddNewRatePopup(true);
            }
          }}
        />
      )}
      {monitoringAgencyInvoicesMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Print Invoice",
            },
            {
              text: "Void Invoice",
            },
            {
              text: "Reimport Obligations",
            },
            {
              text: "Jump to Agency Person",
              icon: "search",
            },
            {
              text: "Reallocate Payment",
            },
            {
              text: "Export Invoice to Excel",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Print Invoice") {
              printInvoice();
            } else if (e.itemData.text === "Void Invoice") {
              setConfirmPopupType("Void Invoice");
              setConfirmPopup(true);
            } else if (e.itemData.text === "Reimport Obligations") {
            } else if (e.itemData.text === "Jump to Agency Person") {
            } else if (e.itemData.text === "Reallocate Payment") {
              setConfirmPopupType("Reallocate Payment");
              setConfirmPopup(true);
            } else if (e.itemData.text === "Export Invoice to Excel") {
              exportInvoiceToExcelApi();
            }
          }}
        />
      )}
      {eMFirstSubTabMenu && (
        <ContextMenu
          dataSource={
            !rowleveldata
              ? [
                {
                  text: "Edit Period",
                  icon: "edit",
                },
                {
                  text: "Add Period",
                  icon: "add",
                },
                {
                  text: "Void",
                  icon: "remove",
                },
              ]
              : [
                {
                  text: "Jump to Docket",
                  icon: "search",
                },
                {
                  text: "Add Period",
                  icon: "add",
                },
              ]
          }
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump to Docket") {
              dispatch(
                setDynamicTab({
                  Offender: `/docket/${`${selectrowleveloperation?.["sDocketNumber"]} - ${selectrowleveloperation?.["iCourtCounts"]}`?.replace(
                    "/",
                    "-"
                  )}`,
                  title: `Docket ${`${selectrowleveloperation?.["sDocketNumber"]} - ${selectrowleveloperation?.["iCourtCounts"]}`}`,
                  sDocketNumberCourtCounts: `${selectrowleveloperation?.["sDocketNumber"]} - ${selectrowleveloperation?.["iCourtCounts"]}`,
                  userinfo: {
                    ...selectrowleveloperation?.userinfo,
                    gPersonId: selectrowleveloperation?.userinfo?.gPersonId,
                  },
                  getTopDynamicDocketid: selectrowleveloperation?.userinfo?.gDocketId,
                })
              );
              dispatch(
                setdockerValue({
                  getTopDynamicDocketid: selectrowleveloperation?.userinfo?.gDocketId,
                  userinfo: {
                    ...selectrowleveloperation?.userinfo,
                    gPersonId: selectrowleveloperation?.userinfo?.gPersonId,
                  },
                })
              );
              navigate(
                `/docket/${`${selectrowleveloperation?.["sDocketNumber"]} - ${selectrowleveloperation?.["iCourtCounts"]}`?.replace(
                  "/",
                  "-"
                )}`
              );
            } else if (e.itemData.text === "Add Period") {
              setEMFirstSubTabMenu(false);
              setEmPopupType("Add");
              setrowleveldata(selectrowleveloperation);
              setEMPriodpopshow(true);
            } else if (e.itemData.text === "Edit Period") {
              setEMFirstSubTabMenu(false);
              setEmPopupType("Edit");
              setEMPriodpopshow(true);
            } else if (e.itemData.text === "Void") {
              setConfirmPopup(true);
            }
          }}
        />
      )}
      {EMObligationMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit Obligation",
              icon: "edit",
            },
            {
              text: "Void Obligation",
              icon: "remove",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit Obligation") {
              SetTypeTab("Edit");
              setEMAddObligationPopup(true);
            } else if (e.itemData.text === "Void Obligation") {
              setConfirmPopup(true);
            }
          }}
        />
      )}
      {EMEquipmentLogMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Add Equipment",
              icon: "add",
            },
            {
              text: "Edit Equipment Log",
              icon: "edit",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Add Equipment") {
              SetTypeTab("Add");
              setEMAddEqupmentLogPopup(true);
            } else if (e.itemData.text === "Edit Equipment Log") {
              SetTypeTab("Edit");
              setEMAddEqupmentLogPopup(true);
            }
          }}
        />
      )}
      {EMEquipmentLogObligationMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit Equipment Log",
              icon: "edit",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit Equipment Log") {
              SetTypeTab("Edit");
              setEMAddEqupmentLogPopup(true);
            }
          }}
        />
      )}
      {obligationMontoringEmPeriodMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit EM Period",
              icon: "edit",
            },
            {
              text: "Void",
              icon: "remove",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit EM Period") {
              SetTypeTab("Edit");
              setAddEditEMPeriodPopup(true);
            }
            if (e.itemData.text === "Void") {
              setVoidAlertPopup(true);
            }
          }}
        />
      )}
      <p className="tableCount">Count: {count}</p>
    </div>
  );
};
