import React from "react";
import { Table1 } from "../Table1/Table1";
import { entitiesColumn } from "../Data";

export const Entities = ({
  entityData,
  entityResult,
  setEntityResult,
  checkEditable,
}) => {
  localStorage.removeItem("groupsList");
  return (
    <>
      <div className="admin_tabs_content_data">
        <Table1
          editTable={false}
          height={400}
          Tablecoloum={["Select", ...entitiesColumn].map((val) => {
            if (val === "Select") {
              return { title: val, entity: true };
            } else if (val === "Default") {
              return { title: val, default: true };
            } else {
              return { title: val };
            }
          })}
          tableRow={entityData.map((val, i) => {
            return { ...val, ID: i };
          })}
          coloumWidthText={"entitiesList"}
          mandatoryColoum={"Entity"}
          setEntityResult={setEntityResult}
          entityResult={entityResult}
          checkEditable={checkEditable}
        />
      </div>
    </>
  );
};
