import { useEffect, useState } from "react";
import { handleGetLocal } from "../../../../../../services/auth-service";
import { logOut } from "../../../../../../components/Logout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LoadPanel } from "devextreme-react";

const ReallocatePaymentPopup = ({
  reallocatePaymentPopup,
  setReallocatePaymentPopup,
  toastConfig,
  setToastConfig,
  fieldID,
  refreshApi,
  setCallApi,
  callApi,
  typePopup,
}) => {
  const [notes, setNotes] = useState("");
  const [notesValidation, setNotesValidation] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setNotes("");
    setNotesValidation("");
  }, [reallocatePaymentPopup]);

  const data = {
    PaymentId: fieldID,
    Note: notes,
  };

  const handleSave = async () => {
    let isValid = true;
    if (!notes || notes.charAt(0) === " ") {
      setNotesValidation("Please Enter Note.");
      isValid = false;
    }
    if (isValid) {
      setLoading(true);
      const pathname = handleGetLocal("pathURL");
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/Financial/reallocate-payment`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        })
        .then((val) => {
          setReallocatePaymentPopup(false);
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: val?.data?.responseMessage,
            type: val?.data?.responseIcon?.toLowerCase(),
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: val?.data?.responseMessage,
              type: val?.data?.responseIcon?.toLowerCase(),
            });
          }, 1900);
          if (typePopup === "Overview") {
            setCallApi(!callApi);
          }
          if (refreshApi) refreshApi();

          setLoading(false);
        })
        .catch((val) => {
          setLoading(false);
          if (!val?.response) {
            navigate(`/error/500`);
          } else if (val?.response?.status === 401) {
            logOut();
          }
        });
    }
  };

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div className="reallocate-payment-popup">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <p>Reallocate Payment</p>
            </div>
            <p class="popup_header_title_para">FN.13</p>
          </div>
          <svg
            onClick={() => {
              setReallocatePaymentPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="program_void_content">
          <p className="void_payment_content_infromation_heading">
            Are you sure you want to remove the current allocations for this payment?
          </p>
          <div className="program_void_content_textarea">
            <label htmlFor="comments">
              Please Enter a Detailed Note
              <span className="marks_mandatary">*</span>
            </label>
            <textarea
              name="notes"
              id="notes"
              value={notes}
              placeholder="Write comment here"
              onChange={(e) => {
                const value = e.target.value;
                setNotes(value);
                if (value.charAt(0) === " ") {
                  setNotesValidation("Please Enter Valid Note");
                } else {
                  setNotesValidation("");
                }
              }}
              className="dark-mode-textarea"
            ></textarea>
            <p className="error_validation">{notesValidation}</p>
          </div>
        </div>
        <div className="popup_buttons" style={{ marginTop: "10px", marginRight: "15px" }}>
          <button className="btn primary_btn" onClick={handleSave}>
            Save
          </button>
          <button
            className="btn"
            onClick={() => {
              setReallocatePaymentPopup(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default ReallocatePaymentPopup;
