import { LoadPanel, ScrollView, TextBox } from "devextreme-react";
import { useEffect, useState } from "react";
import { ReportWizaerdFuncition } from "../ReportWizaerdFuncition";
import { useNavigate } from "react-router-dom";
import { Toster } from "../../../components/Toster/Toster";

export const CretaeNewFilter = ({
  showCretaeNewFilterpopup,
  setshowCretaeNewFilterpopup,
  tablename,
  filterName,
  getQueryFilterReportWizard,
  filtername,
  filterid,
  editname,
  setfield,
  field,
  setfilterdata,
}) => {
  const [name, setname] = useState(filtername && editname ? filtername : "");

  const clas = new ReportWizaerdFuncition();
  const [loading, setLoading] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const [validation, setvalidation] = useState("");

  const navigate = useNavigate();

  const save = () => {
    if (name?.trim()) {
      setvalidation("");
      clas.saveAndEditFilterReportWizard(
        setLoading,
        navigate,
        {
          gStoredFilterId:
            filterid && editname
              ? filterid
              : "00000000-0000-0000-0000-000000000000",
          sName: name,
          dDoe: new Date().toISOString(),
          gEntryUserId: localStorage.getItem("gUserId"),
          gUserId: localStorage.getItem("gUserId"),
          sData: localStorage.getItem(tablename),
          sGridViewName: filterName,
          bDefault: true,
        },
        setshowCretaeNewFilterpopup,
        getQueryFilterReportWizard,
        setToastConfig,
        toastConfig,
        setfield,
        field,
        setfilterdata
      );
    } else {
      setvalidation("Please Enter Stored Filter Name");
    }
  };

  useEffect(() => {
    setvalidation("");
    setname(filtername && editname ? filtername : "");
  }, [showCretaeNewFilterpopup]);

  return (
    <div>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster
        message={toastConfig.message}
        type={toastConfig.type}
        visible={toastConfig.isVisible}
      />
      <ScrollView style={{ width: "100%", height: "350px" }}>
        <div style={{ height: "248px" }} className="notes_edit_popup">
          <div className="RecivePaymentModel_header">
            <div
              style={{ width: "190px" }}
              className="RecivePaymentModel_header_name"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
              >
                <path
                  d="M0 15.4586V18.4986C0 18.7786 0.22 18.9986 0.5 18.9986H3.54C3.67 18.9986 3.8 18.9486 3.89 18.8486L14.81 7.93859L11.06 4.18859L0.15 15.0986C0.0500001 15.1986 0 15.3186 0 15.4586ZM17.71 5.03859C18.1 4.64859 18.1 4.01859 17.71 3.62859L15.37 1.28859C14.98 0.898594 14.35 0.898594 13.96 1.28859L12.13 3.11859L15.88 6.86859L17.71 5.03859Z"
                  fill="white"
                />
              </svg>
              <p>Enter Information </p>
              <span
                style={{
                  padding: "2px 9px",
                  gap: "8px",
                  width: "45px",
                  height: "24px",
                  background: "#FEF2F2",
                  borderRadius: "4px",
                }}
              >
                <p
                  style={{
                    color: "#DC2626",
                    fontFamily: "Readex Pro",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "10px",
                    lineHeight: "20px",
                    width: "21px",
                    height: "20px",
                  }}
                >
                  UT.3
                </p>
              </span>
            </div>
            <svg
              onClick={() => {
                setshowCretaeNewFilterpopup(false);
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          <div style={{ width: "96%", marginLeft: "2%", marginTop: "2%" }}>
            <p className="go-to-label-filed">Stored Filter Name</p>
            <TextBox
              style={{ marginTop: "3%" }}
              validationError={false}
              showMaskMode="always"
              maskRules={{ H: /^[0-9*]$/ }}
              isValid={true}
              value={name}
              onValueChange={(e) => {
                setname(e);
              }}
            />
            <p style={{ color: "red", position: "absolute" }}>{validation}</p>
            <div className="popup_buttons" style={{ marginTop: "15%" }}>
              <button
                className="btn primary_btn"
                onClick={() => {
                  save();
                }}
              >
                Save
              </button>
              <button
                className="btn"
                onClick={() => {
                  setshowCretaeNewFilterpopup(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ScrollView>
    </div>
  );
};
