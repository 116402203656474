export const Tablecoloumn = [
  {
    title: "Action",
    action: true,
  },
  {
    title: "User ID",
  },
  { title: "Name" },
  {
    title: "Role",
  },
  {
    title: "Employee ID",
  },
  {
    title: "Active",
    bool: true,
  },
  {
    title: "Resource",
    bool: true,
  },
  {
    title: "Email",
  },
  {
    title: "Default Location",
  },
];

export const groupsColumn = ["Group Name"];

export const entitiesColumn = ["Entity", "Default"];

export const userAccessListColumn = ["Form", "Element", "Access"];
