import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { TopDynamicTab } from "../../pages/Participants/TopDynamicTab";

export const ImageViewer = () => {
  const [tabSwitch, setTabSwitch] = useState(2);

  const { dynamicParticipatTab } = useSelector((store) => store.participate);
  const [image, setImage] = useState(0);
  useEffect(() => {
    dynamicParticipatTab.map((val) => {
      if (val.Offender === "/image-viewer") {
        setImage(val?.image);
      }
    });
  }, []);
  return (
    <>
      <div
        style={{
          marginTop: "1.1%",
          backgroundColor: "var(--dynamicTabHeader)",
          borderRadius: "5px",
          overflow: "hidden",
          height: "87vh",
        }}
      >
        <TopDynamicTab
          setTabSwitch={setTabSwitch}
          tabSwitch={tabSwitch}
          text={dynamicParticipatTab}
        />
        <div className="screen_num" style={{ margin: ".5%" }}>
          <p>IV.1</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "82vh",
          }}
        >
          <img
            src={"data:image/jpeg;base64," + encodeURI(image)}
            style={{
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
      </div>
    </>
  );
};
