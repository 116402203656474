import { useEffect, useState } from "react";
import { ColumnDropdown } from "../../../components/Dropdownn";
import { DocumentWizardFunction } from "./DocumentWizardFunction";
import DocumentWizardSubTab from "./DocumentWizardSubTab";
import "./documentWizard.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { TopDynamicTab } from "../TopDynamicTab";
import { LoadPanel, Popup } from "devextreme-react";
import DocumentWizardOutput from "./DocumentWizardOutput";
import { Toster } from "../../../components/Toster/Toster";
import { SendEmail } from "../HeaderPopupComponent/SendEmail";
import AddAsAttachment from "./DocumentWizardPopup/AddAsAttachment";
import DWRichEditComponent from "./DWRichEditComponent";
import "devextreme/dist/css/dx.light.css";
import "devexpress-richedit/dist/dx.richedit.css";
import axios from "axios";
import { logOut } from "../../../components/Logout";
import { handleGetLocal } from "../../../services/auth-service";
import { DynamicTabb } from "../../../components/DynamicTab/DynamicTab";
// import { htmlToBase64 } from "../../../utils/base64-conversion";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

const DocumentWizard = () => {
  const { dynamicParticipatTab, documentWizardInfo } = useSelector((store) => store.participate);
  const ApiClass = new DocumentWizardFunction();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    reports: { name: "", id: "" },
    supervisors: { name: "", id: "" },
    showCourt: false,
  });
  const [reportsDropdownData, setReportsDropdownData] = useState([]);
  const [supervisorsDropdownData, setSupervisorsDropdownData] = useState([]);
  const [documentWizardOutputData, setDocumentWizardOutputData] = useState([]);
  const [childDocumentWizardOutputData, setChildDocumentWizardOutputData] = useState([]);
  const navigate = useNavigate();
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [prewviewtogal, setprewviewtogal] = useState(undefined);
  const [sendEmailPopup, setSendEmailPopup] = useState(false);
  const userinfo = documentWizardInfo?.userinfo;
  const personId = userinfo?.gPersonId;
  const supervisorID = fields?.supervisors?.id;
  const [addAsAttachmentPopup, setAddAsAttachmentPopup] = useState(false);
  const [editToggle, setEditToggle] = useState(false);
  const [htmlData, setHtmlData] = useState();
  const [printhtmlData, setprintHtmlData] = useState();
  const [childHtmlData, setChildHtmlData] = useState();
  const [parametersFields, setParametersFields] = useState({});
  const rtfFileName = `${documentWizardInfo?.sOffenderNumber ? documentWizardInfo?.sOffenderNumber : ""} ${
    documentWizardInfo?.sLastFirst ? documentWizardInfo?.sLastFirst : ""
  }`;
  const [sharequeryshow, setsharequeryshow] = useState(false);
  const [selectedDocketsValidation, setSelectedDocketsValidation] = useState("");

  const defaultBase64 =
    "PCFET0NUWVBFIGh0bWw+PGh0bWwgbGFuZz0iZW4iPjxoZWFkPjxtZXRhIGNoYXJzZXQ9IlVURi04Ij48bWV0YSBuYW1lPSJ2aWV3cG9ydCIgY29udGVudD0id2lkdGg9ZGV2aWNlLXdpZHRoLCBpbml0aWFsLXNjYWxlPTEuMCI+PC9oZWFkPjxib2R5PjxwIHN0eWxlPSJ0ZXh0LWFsaWduOiBsZWZ0OyBtYXJnaW4tdG9wOiAwaW47IG1hcmdpbi1ib3R0b206IDBpbjsgYm9yZGVyLXdpZHRoOiBpbml0aWFsOyBib3JkZXItc3R5bGU6IG5vbmU7IGJvcmRlci1jb2xvcjogaW5pdGlhbDsgZm9udC1mYW1pbHk6IENhbGlicmksIHNhbnMtc2VyaWY7IGZvbnQtc2l6ZTogMTQuNjY3cHg7IGNvbG9yOiByZ2IoMCwgMCwgMCk7Ij4mbmJzcDs8L3A+PC9ib2R5PjwvaHRtbD4=";
  const [documentAsBase64, setdocumentAsBase64] = useState(defaultBase64);
  const [childdocumentAsBase64, setchilddocumentAsBase64] = useState();
  const pathname = handleGetLocal("pathURL");

  function htmlToBase64(html) {
    const utf8Bytes = new TextEncoder().encode(html);
    let binaryString = "";
    utf8Bytes.forEach((byte) => {
      binaryString += String.fromCharCode(byte);
    });
    return btoa(binaryString);
  }

  const rtfToBase64 = (rtf) => {
    // Convert RTF string to a Uint8Array (binary data)
    const byteArray = new Uint8Array(new TextEncoder().encode(rtf));

    // Convert the Uint8Array to a Base64 string
    let binaryString = "";
    const len = byteArray.byteLength;
    for (let i = 0; i < len; i++) {
      binaryString += String.fromCharCode(byteArray[i]);
    }

    return btoa(binaryString);
  };

  const handlePrint = async () => {
    setprewviewtogal(false);
    await base64tohtml(rtfToBase64(htmlData));
    setprewviewtogal(true);
  };

  useEffect(() => {
    if (documentWizardOutputData?.sName && prewviewtogal && printhtmlData) {
      printTableData();
      setprewviewtogal(false);
    }
  }, [printhtmlData, documentWizardOutputData?.sName, prewviewtogal]);

  // function base64ToHtml(base64) {
  //   if (isValidBase64(base64)) {
  //     const binaryString = atob(base64);
  //     const utf8Bytes = Uint8Array.from(binaryString, char => char.charCodeAt(0));
  //     return new TextDecoder().decode(utf8Bytes);
  //   }
  //   else {
  //     console.error("Invalid base64 string for documentAsBase64");
  //   }

  // }

  useEffect(() => {
    ApiClass.getDocumentWizardSupervisorDropdown(navigate, setSupervisorsDropdownData, setLoading);
    setFields((prev) => ({
      ...prev,
      supervisors: {
        name: userinfo?.sPrimarySupervisorName,
        id: userinfo?.gPrimarySupervisorId,
      },
    }));
    setsharequeryshow(true);
    setSelectedDocketsValidation("");
  }, []);

  useEffect(() => {
    if (fields?.showCourt === false) {
      ApiClass.getDocumentWizardDropdown(navigate, setReportsDropdownData, setLoading);
    }
  }, [fields?.showCourt]);

  const getDocumentWizardUpdateRtfDocument = (htmlData) => {
    ApiClass.getDocumentWizardUpdateRtfDocument(
      navigate,
      { personID: personId, rtf: htmlData, selectedDockets: [], supervisorID: supervisorID },
      setLoading,
      setParametersFields,
      replaceFieldsWithValues,
      parametersFields
    );
  };

  useEffect(() => {
    if (fields?.reports?.id) {
      ApiClass.getDocumentWizardData(
        fields?.reports?.id,
        navigate,
        setDocumentWizardOutputData,
        setChildDocumentWizardOutputData,
        setHtmlData,
        setChildHtmlData,
        setLoading,
        getDocumentWizardUpdateRtfDocument,
        documentWizardOutputData
      );
      setParametersFields({});
    }
  }, [fields?.reports?.id]);

  useEffect(() => {
    if (fields?.supervisors?.id) {
      setParametersFields((prev) => ({
        ...prev,
        "[SupervisorName]": fields?.supervisors?.name,
      }));
    }
  }, [fields?.supervisors?.id]);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const documentWizardSaveData = {
    gReportCatalogID: fields?.reports?.id,
    // report: base64ToHtml(documentAsBase64),
    report: documentAsBase64,
  };

  const base64tohtml = async (base64data) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/document-wizard/base64tohtml-conversion`,
        { base64: base64data },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setprintHtmlData(val?.data?.data);
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  };
  const printTableData = () => {
    var printWindow = window.open("", "", "width=600,height=600");
    printWindow.document.open();

    printWindow.document.write(`
      <html>
      <head>
        <title>Document Wizard</title>
        <style>
          /* Add your CSS styles here */
        </style>
      </head>
      <body>
        <div>${printhtmlData}</div>
      </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  const findParameterHtmlData = () => {
    var regex = /\[([^\]]*)\]/g;
    var matches = [];
    var match;
    var uniqueNames = new Set();
    const currentDate = new Date();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const year = currentDate.getFullYear();
    const currentDateString = `${month}/${day}/${year}`;
    const fieldNamesWithValues = {
      "[UpperOffenderName]": userinfo?.sFirstLast || "",
      "[UpperOffenderAddressList]": userinfo?.sHomeAddressBlock || "",
      "[SupervisorName]": fields?.supervisors?.name || "",
      "[CurrentYear]": year.toString(),
      "[CurrentDate]": currentDateString,
      "[Currentdate]": currentDateString,
    };
    while ((match = regex.exec(documentWizardOutputData?.sReportRtf)) !== null) {
      var name = match[1];
      if (!uniqueNames.has(name)) {
        matches.push(match[0]);
        uniqueNames.add(name);
      }
    }
    const parametersFieldsObject = matches.reduce((acc, item) => {
      acc[item] = "";
      return acc;
    }, {});
    for (let key in fieldNamesWithValues) {
      if (parametersFieldsObject.hasOwnProperty(key)) {
        parametersFieldsObject[key] = fieldNamesWithValues[key];
      }
    }
    setParametersFields((prev) => ({
      ...prev,
      ...parametersFieldsObject,
    }));
  };

  useEffect(() => {
    findParameterHtmlData();
  }, [documentWizardOutputData]);

  const replaceFieldsWithValues = (fieldValues) => {
    var html2 = documentWizardOutputData?.sReportRtf;
    for (const fieldName in fieldValues) {
      const fieldValue = fieldValues[fieldName];
      var replacement = fieldValue ? fieldValue : fieldName;
      var escapedPattern = fieldName?.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
      var regex = new RegExp(escapedPattern, "g");
      html2 = html2?.replace(regex, replacement);
    }
    setHtmlData(html2);
    if (childHtmlData) {
      changeChildDocument(fieldValues);
    }
    setToastConfig({
      ...toastConfig,
      isVisible: true,
      message: "Successfully Updated!",
      type: "success",
    });
    setTimeout(() => {
      setToastConfig({
        ...toastConfig,
        isVisible: false,
        message: "Successfully Updated!",
        type: "success",
      });
    }, 1900);
  };

  const changeChildDocument = (fieldValues) => {
    const modifiedItems = [];
    childDocumentWizardOutputData?.map((item) => {
      let modifiedItem = {
        gParentId: item?.gParentId,
        sName: item?.sName,
        sReportRtf: item?.sReportRtf,
      };
      for (const fieldName in fieldValues) {
        const fieldValue = fieldValues[fieldName];
        var replacement = fieldValue ? fieldValue : fieldName;
        var escapedPattern = fieldName?.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
        var regex = new RegExp(escapedPattern, "g");
        modifiedItem.sReportRtf = modifiedItem.sReportRtf?.replace(regex, replacement);
      }
      modifiedItems.push(modifiedItem);
    });
    setChildHtmlData(modifiedItems);
  };

  // const handleExportToPDF = async () => {
  //   if (htmlData) {
  //     const data = await base64tohtml(rtfToBase64(htmlData));
  //     console.log("data", data);
  //     const convHtmlData = {
  //       Document: data,
  //     };
  //     ApiClass.docConversion(convHtmlData, setLoading, navigate, setToastConfig, toastConfig, rtfFileName);
  //   }
  // };
  const handleExportToPDF = async () => {
    if (htmlData) {
        const base64HtmlData = rtfToBase64(htmlData); // Ensure this is the correct conversion method
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/document-wizard/base64tohtml-conversion`,
            { base64: base64HtmlData },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Domain: `${pathname}`,
                },
            }
        );
        
        if (response.data && response.data.data) {
            const convHtmlData = { Document: response.data.data };
            ApiClass.docConversion(convHtmlData, setLoading, navigate, setToastConfig, toastConfig, rtfFileName);
        } else {
            console.error("No data returned from conversion.");
        }
    } else {
        console.error("HTML data is empty or undefined.");
    }
};

  return (
    <>
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Popup
        visible={sendEmailPopup}
        onHiding={() => {
          setSendEmailPopup(false);
        }}
        showTitle={false}
        width={1180}
        height={860}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <SendEmail
          recivePaymentModel={sendEmailPopup}
          setRecivePaymentModel={setSendEmailPopup}
          userinfo={userinfo}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          documentWizrard={true}
        />
      </Popup>
      <Popup
        visible={addAsAttachmentPopup}
        onHiding={() => {
          setAddAsAttachmentPopup(false);
        }}
        showTitle={false}
        width={650}
        height={200}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddAsAttachment
          addAsAttachmentPopup={addAsAttachmentPopup}
          setAddAsAttachmentPopup={setAddAsAttachmentPopup}
          userinfo={userinfo}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          setLoading={setLoading}
          navigate={navigate}
          fields={fields}
          htmlData={htmlData}
          documentWizardOutputData={documentWizardOutputData}
        />
      </Popup>
      <div className="particcipentPage">
        {/* <TopDynamicTab text={dynamicParticipatTab} /> */}
        {/* <DynamicTabb/> */}
        <DynamicTabb />
        <div className="document-wizard-page-section">
          <div className="document-wizard-info-section">
            <div className="document-wizard-info-part">
              <div className="page-content document-wizard-page">
                <p className="page-info-text-detail">
                  {documentWizardInfo?.sOffenderNumber ? documentWizardInfo?.sOffenderNumber : ""}
                  &#160;-&#160;
                  {documentWizardInfo?.sLastFirst ? documentWizardInfo?.sLastFirst : ""}
                </p>
                <div className="document-wizard-content">
                  <div className="document-wizard-content-left">
                    <div className="display-flex-div">
                      <input
                        type="checkbox"
                        name="showCourt"
                        id="showCourt"
                        value={fields?.showCourt}
                        onChange={handleChange}
                      />
                      <label htmlFor="showCourt" className="fee-payment-content-info-para">
                        Show Court Specific Documents Only
                      </label>
                    </div>
                    <div className="display-flex-div">
                      <DropDown
                        setfields={setFields}
                        fields={fields}
                        fieldNmae={"reports"}
                        dropDownData={reportsDropdownData}
                        // disabled={fields?.showCourt}
                      />
                    </div>
                    <p className="error_validation">{selectedDocketsValidation}</p>
                    <div className="display-flex-div">
                      <DropDown
                        setfields={setFields}
                        fields={fields}
                        fieldNmae={"supervisors"}
                        dropDownData={supervisorsDropdownData}
                      />
                    </div>
                  </div>
                  <div className="document-wizard-content-right">
                    <div className="buttons document-wizard-buttons">
                      <button
                        className="btn"
                        onClick={() => {
                          if (htmlData) {
                            replaceFieldsWithValues(parametersFields);
                          }
                        }}
                      >
                        Update
                      </button>
                      {/* {!editToggle ? (
                        <button
                          className="btn"
                          onClick={() => {
                            setEditToggle(true);
                          }}
                        >
                          Edit
                        </button>
                      ) : (
                        <button
                          className="btn"
                          onClick={() => {
                            setEditToggle(false);
                            if (fields?.reports?.id) {
                              ApiClass.saveDocumentWizardData(
                                documentWizardSaveData,
                                setLoading,
                                navigate,
                                setToastConfig,
                                toastConfig
                              );
                            }
                          }}
                        >
                          Save
                        </button>
                      )} */}
                      {/* <button
                        className="btn"
                        onClick={() => {
                          // setEditToggle(false);
                          if (fields?.reports?.id) {
                            ApiClass.saveDocumentWizardData(
                              documentWizardSaveData,
                              setLoading,
                              navigate,
                              setToastConfig,
                              toastConfig
                            );
                          }
                        }}
                      >
                        Save
                      </button> */}
                      <button
                        className="btn"
                        onClick={() => {
                          if (htmlData) {
                            handleExportToPDF();
                          }
                        }}
                      >
                        Export
                      </button>
                      <button
                        className="btn"
                        onClick={() => {
                          setAddAsAttachmentPopup(!addAsAttachmentPopup);
                        }}
                      >
                        Add as Attachment
                      </button>
                      <button
                        className="btn"
                        onClick={() => {
                          setSendEmailPopup(true);
                        }}
                      >
                        Send as Email
                      </button>
                      <button className="btn" onClick={handlePrint}>
                        Print
                        <svg
                          className="svg-blue-white"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_5985_7014)">
                            <path
                              d="M19 8H5C3.34 8 2 9.34 2 11V15C2 16.1 2.9 17 4 17H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V17H20C21.1 17 22 16.1 22 15V11C22 9.34 20.66 8 19 8ZM15 19H9C8.45 19 8 18.55 8 18V14H16V18C16 18.55 15.55 19 15 19ZM19 12C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10C19.55 10 20 10.45 20 11C20 11.55 19.55 12 19 12ZM17 3H7C6.45 3 6 3.45 6 4V6C6 6.55 6.45 7 7 7H17C17.55 7 18 6.55 18 6V4C18 3.45 17.55 3 17 3Z"
                              fill="#424167"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_5985_7014">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <DocumentWizardSubTab
                documentWizardInfo={documentWizardInfo}
                loading={loading}
                setLoading={setLoading}
                editToggle={editToggle}
                documentWizardOutputData={documentWizardOutputData}
                htmlData={htmlData}
                setHtmlData={setHtmlData}
                parametersFields={parametersFields}
                setParametersFields={setParametersFields}
                sharequeryshow={sharequeryshow}
                personId={personId}
                setSelectedDocketsValidation={setSelectedDocketsValidation}
                fields={fields}
                changeChildDocument={changeChildDocument}
                childHtmlData={childHtmlData}
                setReportsDropdownData={setReportsDropdownData}
              />
            </div>
            <div className="document-wizard-output">
              {/* <DocumentWizardOutput
                documentWizardOutputData={documentWizardOutputData}
                htmlData={htmlData}
                setHtmlData={setHtmlData}
                childDocumentWizardOutputData={childDocumentWizardOutputData}
                childHtmlData={childHtmlData}
                setChildHtmlData={setChildHtmlData}
                editToggle={editToggle}
              /> */}
              {htmlData && (
                <DWRichEditComponent
                  documentWizardOutputData={documentWizardOutputData}
                  documentAsBase64={rtfToBase64(htmlData)}
                  setdocumentAsBase64={setdocumentAsBase64}
                  childDocumentWizardOutputData={childDocumentWizardOutputData}
                  // childdocumentAsBase64={childdocumentAsBase64}
                  // setchilddocumentAsBase64={setchilddocumentAsBase64}
                  childHtmlData={childHtmlData}
                  setChildHtmlData={setChildHtmlData}
                  editToggle={editToggle}
                  setHtmlData={setHtmlData}
                  // updatedocumentAsBase64={updatedocumentAsBase64}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentWizard;
