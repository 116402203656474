import React from "react";
import "./style/userData.css";
import AdminCrumbs from "./components/AdminCrumbs";
import UserData from "./UserData";
import { DynamicTabb } from "../../components/DynamicTab/DynamicTab";

const UserAdd = () => {
  const title = "Users";

  return (
    <>
      <AdminCrumbs title={title} />
      <div className="admin_userdata">
        <DynamicTabb />
        <UserData type={"Add"}/>
      </div>
    </>
  );
};

export default UserAdd;
