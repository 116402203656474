import { ScrollView } from "devextreme-react";
import { useEffect, useState } from "react";
import { DetailTab } from "./DetailTab";
import { ObligationTab } from "./ObligationTab";
import { EquipmentLogTab } from "./EquipmentLogTab";
import { useNavigate } from "react-router-dom";
import { EmFunction } from "../EmFunction";

export const EMPriod = ({
  EMPriodpopshow,
  setEMPriodpopshow,
  rowleveldata,
  getEmDataApi,
  setToastConfig,
  toastConfig,
  emPopupType,
  parentRowData,
}) => {
  const [quickAddTaskTab, setQuickAddTaskTab] = useState(1);
  const [emperiodsucces, setemperiodsucces] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const ApiClass = new EmFunction();
  const [frequencyDropdownData, setFrequencyDropdownData] = useState([]);
  const [paymentAgencyDropdownData, setPaymentAgencyDropdownData] = useState(
    []
  );
  const [paymentTypeDropdownData, setPaymentTypeDropdownData] = useState([]);
  const [returnStatusDropdown, setReturnStatusDropdown] = useState([]);
  const [equipmentStatusDropdown, setEquipmentStatusDropdown] = useState([]);

  useEffect(() => {
    ApiClass.getEmFrequencyDropdownApi(
      setLoading,
      navigate,
      setFrequencyDropdownData
    );
    ApiClass.getEmPaymentAgencyDropdownApi(
      setLoading,
      navigate,
      setPaymentAgencyDropdownData
    );
    ApiClass.getEmPaymentTypeDropdownApi(
      setLoading,
      navigate,
      setPaymentTypeDropdownData
    );
    ApiClass.getEqipmentDropdownApi(
      setLoading,
      navigate,
      setReturnStatusDropdown,
      setEquipmentStatusDropdown
    );
  }, []);

  const [fields, setfields] = useState({
    "Start Date": "",
    days: "",
    "End Date": "",
    "EM Type": { name: "", id: "" },
    "End Reason": { name: "", id: "" },
    Comment: "",
  });

  useEffect(() => {
    setQuickAddTaskTab(1);
    if (emPopupType === "Add") {
      setemperiodsucces(false);
      setfields({
        "Start Date": "",
        days: 0,
        "End Date": "",
        "EM Type": { name: "", id: "" },
        "End Reason": { name: "", id: "" },
        Comment: "",
      });
    } else {
      if (rowleveldata?.gEmPeriodId) {
        setemperiodsucces(true);
        setfields((prev) => ({
          ...prev,
          "Start Date": rowleveldata?.[`Start Date`],
          days: 0,
          "End Date": rowleveldata?.[`End Date`],
          "EM Type": {
            name: rowleveldata?.[`EM Type`],
            id: rowleveldata?.gEmTypeId,
          },
          "End Reason": {
            name: rowleveldata?.[`End Reason`],
            id: rowleveldata?.gEmEndReasonId,
          },
          Comment: rowleveldata?.Comment ? rowleveldata?.Comment : "",
        }));
      }
    }
  }, [EMPriodpopshow]);

  return (
    <>
      <div className="em-period-popup">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <p>EM Period</p>
            </div>
            <p class="popup_header_title_para">EM.1</p>
          </div>
          <svg
            onClick={() => {
              setEMPriodpopshow(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <ScrollView style={{ height: "580px" }}>
          <div className="popup-container-data">
            <div className="tabs_container">
              <p
                className={
                  quickAddTaskTab === 1
                    ? "tabs_container_tab tabs_container_tab_active"
                    : "tabs_container_tab"
                }
                onClick={() => {
                  setQuickAddTaskTab(1);
                }}
              >
                Details
              </p>
              {emperiodsucces && rowleveldata?.gEmPeriodId && (
                <p
                  className={
                    quickAddTaskTab === 2
                      ? "tabs_container_tab tabs_container_tab_active"
                      : "tabs_container_tab"
                  }
                  onClick={() => {
                    setQuickAddTaskTab(2);
                  }}
                >
                  Obligation
                </p>
              )}
              {emperiodsucces && rowleveldata?.gEmPeriodId && (
                <p
                  className={
                    quickAddTaskTab === 3
                      ? "tabs_container_tab tabs_container_tab_active"
                      : "tabs_container_tab"
                  }
                  onClick={() => {
                    setQuickAddTaskTab(3);
                  }}
                >
                  Equipment Log
                </p>
              )}
            </div>
            {quickAddTaskTab === 1 && (
              <DetailTab
                rowleveldata={rowleveldata}
                setEMPriodpopshow={setEMPriodpopshow}
                getEmDataApi={getEmDataApi}
                setemperiodsucces={setemperiodsucces}
                emperiodsucces={emperiodsucces}
                EMPriodpopshow={EMPriodpopshow}
                fields={fields}
                setfields={setfields}
                setQuickAddTaskTab={setQuickAddTaskTab}
                parentRowData={parentRowData}
                emPopupType={emPopupType}
              />
            )}

            {emperiodsucces && rowleveldata?.gEmPeriodId && (
              <>
                {quickAddTaskTab === 2 && (
                  <ObligationTab
                    setToastConfig={setToastConfig}
                    toastConfig={toastConfig}
                    setLoading={setLoading}
                    loading={loading}
                    navigate={navigate}
                    paymentTypeDropdownData={paymentTypeDropdownData}
                    paymentAgencyDropdownData={paymentAgencyDropdownData}
                    frequencyDropdownData={frequencyDropdownData}
                    rowleveldata={rowleveldata}
                    setEMPriodpopshow={setEMPriodpopshow}
                    parentRowData={parentRowData}
                  />
                )}
                {quickAddTaskTab === 3 && (
                  <EquipmentLogTab
                    setToastConfig={setToastConfig}
                    toastConfig={toastConfig}
                    setLoading={setLoading}
                    loading={loading}
                    navigate={navigate}
                    setEMPriodpopshow={setEMPriodpopshow}
                    equipmentStatusDropdown={equipmentStatusDropdown}
                    returnStatusDropdown={returnStatusDropdown}
                    rowleveldata={rowleveldata}
                  />
                )}
              </>
            )}
          </div>
        </ScrollView>
      </div>
    </>
  );
};
