import { Attachment } from "../../../../Participants/ParticipantSubTabs/Attachment/Attachment";

export const ProntactEntitiesSubTabAttachment = ({
  dataa,
  loadinggg,
  userinfo,
  attachmentfunction,
}) => {
  return (
    <>
      <Attachment
        dataa={dataa}
        loadinggg={loadinggg}
        userinfo={userinfo}
        attachmentfunction={attachmentfunction}
      />
    </>
  );
};
