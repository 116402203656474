import { NumberBox } from "devextreme-react";
import {
  setProntactEntitiesSubTabAddresData,
  setProntactEntitieschange,
} from "../../../../../reducer/AdminReadReducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const Detail_Address_Tab_home_address = ({
  Detail_Tab_address_getData,
  editable,
}) => {
  const dispatch = useDispatch();

  return (
    <div className="Detail_Address_Tab_mail_address">
      <div style={{ background: "var(--btn-grey-black)", height: "48px" }}>
        <svg
          className="svg-black-white"
          style={{ marginTop: "14px", marginLeft: "7%" }}
          width="20"
          height="17"
          viewBox="0 0 20 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.00254 16.0002V11.0002H12.0025V16.0002C12.0025 16.5502 12.4525 17.0002 13.0025 17.0002H16.0025C16.5525 17.0002 17.0025 16.5502 17.0025 16.0002V9.00021H18.7025C19.1625 9.00021 19.3825 8.43022 19.0325 8.13022L10.6725 0.600215C10.2925 0.260215 9.71254 0.260215 9.33254 0.600215L0.972539 8.13022C0.632539 8.43022 0.842539 9.00021 1.30254 9.00021H3.00254V16.0002C3.00254 16.5502 3.45254 17.0002 4.00254 17.0002H7.00254C7.55254 17.0002 8.00254 16.5502 8.00254 16.0002Z"
            fill="black"
          />
        </svg>
        <p style={{ marginTop: "14px", marginLeft: "5%", fontWeight: "bold" }}>
          Physical Address
        </p>
      </div>
      <div className="input_block">
        <p style={{ fontWeight: 600 }}>Address Line 1</p>
        <input
          disabled={!editable}
          value={Detail_Tab_address_getData.HomeAddress.AddressLine1}
          onChange={(e) => {
            dispatch(setProntactEntitieschange(true));
            dispatch(
              setProntactEntitiesSubTabAddresData({
                ...Detail_Tab_address_getData,
                HomeAddress: {
                  ...Detail_Tab_address_getData.HomeAddress,
                  AddressLine1: e.target.value,
                },
                isChange: true,
              })
            );
          }}
          placeholder={"Address Line 1"}
        />
      </div>
      <div className="input_block">
        <p style={{ fontWeight: 600 }}>Address Line 2</p>
        <input
          disabled={!editable}
          value={Detail_Tab_address_getData.HomeAddress.AddressLine2}
          onChange={(e) => {
            dispatch(setProntactEntitieschange(true));
            dispatch(
              setProntactEntitiesSubTabAddresData({
                ...Detail_Tab_address_getData,
                HomeAddress: {
                  ...Detail_Tab_address_getData.HomeAddress,
                  AddressLine2: e.target.value,
                },
                isChange: true,
              })
            );
          }}
          placeholder={"Address Line 2"}
        />
      </div>
      <div style={{ display: "flex" }}>
        <div className="input_block">
          <p style={{ fontWeight: 600 }}>City</p>
          <input
            disabled={!editable}
            value={Detail_Tab_address_getData.HomeAddress.City}
            onChange={(e) => {
              dispatch(setProntactEntitieschange(true));
              dispatch(
                setProntactEntitiesSubTabAddresData({
                  ...Detail_Tab_address_getData,
                  HomeAddress: {
                    ...Detail_Tab_address_getData.HomeAddress,
                    City: e.target.value,
                  },
                  isChange: true,
                })
              );
            }}
            placeholder={"City"}
          />
        </div>
        <div className="input_block">
          <p style={{ fontWeight: 600 }}>State</p>
          <input
            disabled={!editable}
            value={Detail_Tab_address_getData.HomeAddress.State}
            onChange={(e) => {
              dispatch(setProntactEntitieschange(true));
              dispatch(
                setProntactEntitiesSubTabAddresData({
                  ...Detail_Tab_address_getData,
                  HomeAddress: {
                    ...Detail_Tab_address_getData.HomeAddress,
                    State: e.target.value,
                  },
                  isChange: true,
                })
              );
            }}
            placeholder={"State"}
          />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div className="input_block_">
          <p style={{ fontWeight: 600 }}>ZIP</p>

          <NumberBox
            style={{ marginTop: "2%", height: "40px" }}
            onKeyDown={(e) => {
              const { event } = e;
              const str = event.key || String.fromCharCode(event.which);
              if (/^[.,e]$/.test(str)) {
                event.preventDefault();
              }
            }}
            value={Detail_Tab_address_getData.HomeAddress.ZIP}
            disabled={!editable}
            min={0}
            onValueChange={(e) => {
              dispatch(
                setProntactEntitiesSubTabAddresData({
                  ...Detail_Tab_address_getData,
                  HomeAddress: {
                    ...Detail_Tab_address_getData.HomeAddress,
                    ZIP: e,
                  },
                  isChange: true,
                })
              );
            }}
            placeholder={"ZIP"}
          />
        </div>
        <div className="input_block">
          <p style={{ fontWeight: 600 }}>County</p>
          <input
            disabled={!editable}
            value={Detail_Tab_address_getData.HomeAddress.Country}
            onChange={(e) => {
              dispatch(setProntactEntitieschange(true));
              dispatch(
                setProntactEntitiesSubTabAddresData({
                  ...Detail_Tab_address_getData,
                  HomeAddress: {
                    ...Detail_Tab_address_getData.HomeAddress,
                    Country: e.target.value,
                  },
                  isChange: true,
                })
              );
            }}
            placeholder={"Country"}
          />
        </div>
      </div>
      <div className="input_block">
        <p style={{ fontWeight: 600 }}>Comment/Phone</p>
        <textarea
          disabled={!editable}
          value={Detail_Tab_address_getData.HomeAddress.Comment}
          onChange={(e) => {
            dispatch(setProntactEntitieschange(true));
            dispatch(
              setProntactEntitiesSubTabAddresData({
                ...Detail_Tab_address_getData,
                HomeAddress: {
                  ...Detail_Tab_address_getData.HomeAddress,
                  Comment: e.target.value,
                },
                isChange: true,
              })
            );
          }}
          style={{
            width: "100%",
            height: "80px",
            borderRadius: "3px",
            marginTop: "2%",
          }}
          placeholder={"Comment/Phone"}
        />
      </div>
    </div>
  );
};

const Detail_Address_Tab_mail_address = ({
  Detail_Tab_address_getData,
  editable,
}) => {
  const dispatch = useDispatch();

  return (
    <div className="Detail_Address_Tab_mail_address">
      <div style={{ background: "var(--btn-grey-black)", height: "48px" }}>
        <svg
          className="svg-black-white"
          style={{ marginTop: "14px", marginLeft: "7%" }}
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM17.6 4.25L11.06 8.34C10.41 8.75 9.59 8.75 8.94 8.34L2.4 4.25C2.15 4.09 2 3.82 2 3.53C2 2.86 2.73 2.46 3.3 2.81L10 7L16.7 2.81C17.27 2.46 18 2.86 18 3.53C18 3.82 17.85 4.09 17.6 4.25Z"
            fill="black"
          />
        </svg>
        <p style={{ marginTop: "12px", marginLeft: "5%", fontWeight: "bold" }}>
          Mailing Address
        </p>
      </div>
      <div className="input_block">
        <p style={{ fontWeight: 600 }}>Address Line 1</p>
        <input
          disabled={!editable}
          value={Detail_Tab_address_getData.MailingAddress.AddressLine1}
          onChange={(e) => {
            dispatch(setProntactEntitieschange(true));
            dispatch(
              setProntactEntitiesSubTabAddresData({
                ...Detail_Tab_address_getData,
                MailingAddress: {
                  ...Detail_Tab_address_getData.MailingAddress,
                  AddressLine1: e.target.value,
                },
                isChange: true,
              })
            );
          }}
          placeholder={"Address Line 1"}
        />
      </div>
      <div className="input_block">
        <p style={{ fontWeight: 600 }}>Address Line 2</p>
        <input
          disabled={!editable}
          value={Detail_Tab_address_getData.MailingAddress.AddressLine2}
          onChange={(e) => {
            dispatch(setProntactEntitieschange(true));
            dispatch(
              setProntactEntitiesSubTabAddresData({
                ...Detail_Tab_address_getData,
                MailingAddress: {
                  ...Detail_Tab_address_getData.MailingAddress,
                  AddressLine2: e.target.value,
                },
                isChange: true,
              })
            );
          }}
          placeholder={"Address Line 2"}
        />
      </div>
      <div style={{ display: "flex" }}>
        <div className="input_block">
          <p style={{ fontWeight: 600 }}>City</p>
          <input
            disabled={!editable}
            value={Detail_Tab_address_getData.MailingAddress.City}
            onChange={(e) => {
              dispatch(setProntactEntitieschange(true));
              dispatch(
                setProntactEntitiesSubTabAddresData({
                  ...Detail_Tab_address_getData,
                  MailingAddress: {
                    ...Detail_Tab_address_getData.MailingAddress,
                    City: e.target.value,
                  },
                  isChange: true,
                })
              );
            }}
            placeholder={"City"}
          />
        </div>{" "}
        <div className="input_block">
          <p style={{ fontWeight: 600 }}>State</p>
          <input
            disabled={!editable}
            value={Detail_Tab_address_getData.MailingAddress.State}
            onChange={(e) => {
              dispatch(setProntactEntitieschange(true));
              dispatch(
                setProntactEntitiesSubTabAddresData({
                  ...Detail_Tab_address_getData,
                  MailingAddress: {
                    ...Detail_Tab_address_getData.MailingAddress,
                    State: e.target.value,
                  },
                  isChange: true,
                })
              );
            }}
            placeholder={"State"}
          />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div className="input_block_">
          <p style={{ fontWeight: 600 }}>ZIP</p>

          <NumberBox
            style={{ marginTop: "2%", height: "40px" }}
            onKeyDown={(e) => {
              const { event } = e;
              const str = event.key || String.fromCharCode(event.which);
              if (/^[.,e]$/.test(str)) {
                event.preventDefault();
              }
            }}
            value={Detail_Tab_address_getData.MailingAddress.ZIP}
            disabled={!editable}
            min={0}
            onValueChange={(e) => {
              dispatch(
                setProntactEntitiesSubTabAddresData({
                  ...Detail_Tab_address_getData,
                  MailingAddress: {
                    ...Detail_Tab_address_getData.MailingAddress,
                    ZIP: e,
                  },
                  isChange: true,
                })
              );
            }}
            placeholder={"ZIP"}
          />
        </div>
        <div className="input_block">
          <p style={{ fontWeight: 600 }}>County</p>
          <input
            disabled={!editable}
            value={Detail_Tab_address_getData.MailingAddress.Country}
            onChange={(e) => {
              dispatch(setProntactEntitieschange(true));
              dispatch(
                setProntactEntitiesSubTabAddresData({
                  ...Detail_Tab_address_getData,
                  MailingAddress: {
                    ...Detail_Tab_address_getData.MailingAddress,
                    Country: e.target.value,
                  },
                  isChange: true,
                })
              );
            }}
            placeholder={"Country"}
          />
        </div>
      </div>
      <div className="input_block">
        <p style={{ fontWeight: 600 }}>Comment/Phone</p>
        <textarea
          disabled={!editable}
          value={Detail_Tab_address_getData.MailingAddress.Comment}
          style={{
            width: "100%",
            height: "80px",
            borderRadius: "3px",
            marginTop: "2%",
          }}
          onChange={(e) => {
            dispatch(setProntactEntitieschange(true));
            dispatch(
              setProntactEntitiesSubTabAddresData({
                ...Detail_Tab_address_getData,
                MailingAddress: {
                  ...Detail_Tab_address_getData.MailingAddress,
                  Comment: e.target.value,
                },
                isChange: true,
              })
            );
          }}
          placeholder={"Comment/Phone"}
        />
      </div>
    </div>
  );
};

const Detail_Address_Tab_work_address = ({
  Detail_Tab_address_getData,
  editable,
}) => {
  const dispatch = useDispatch();
  return (
    <div className="Detail_Address_Tab_mail_address">
      <div style={{ background: "var(--btn-grey-black)", height: "48px" }}>
        <svg
          className="svg-black-white"
          style={{ marginTop: "14px", marginLeft: "7%" }}
          width="20"
          height="19"
          viewBox="0 0 20 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 4H14V2C14 0.89 13.11 0 12 0H8C6.89 0 6 0.89 6 2V4H2C0.89 4 0.00999999 4.89 0.00999999 6L0 17C0 18.11 0.89 19 2 19H18C19.11 19 20 18.11 20 17V6C20 4.89 19.11 4 18 4ZM12 4H8V2H12V4Z"
            fill="black"
          />
        </svg>
        <p style={{ marginTop: "14px", marginLeft: "5%", fontWeight: "bold" }}>
          Account Payable Address
        </p>
      </div>

      <div className="input_block">
        <p style={{ fontWeight: 600 }}>Address Line 1</p>
        <input
          disabled={!editable}
          value={Detail_Tab_address_getData.WorkAddress.AddressLine1}
          onChange={(e) => {
            dispatch(setProntactEntitieschange(true));
            dispatch(
              setProntactEntitiesSubTabAddresData({
                ...Detail_Tab_address_getData,
                WorkAddress: {
                  ...Detail_Tab_address_getData.WorkAddress,
                  AddressLine1: e.target.value,
                },
                isChange: true,
              })
            );
          }}
          placeholder={"Address Line 1"}
        />
      </div>
      <div className="input_block">
        <p style={{ fontWeight: 600 }}>Address Line 2</p>
        <input
          disabled={!editable}
          value={Detail_Tab_address_getData.WorkAddress.AddressLine2}
          onChange={(e) => {
            dispatch(setProntactEntitieschange(true));
            dispatch(
              setProntactEntitiesSubTabAddresData({
                ...Detail_Tab_address_getData,
                WorkAddress: {
                  ...Detail_Tab_address_getData.WorkAddress,
                  AddressLine2: e.target.value,
                },
                isChange: true,
              })
            );
          }}
          placeholder={"Address Line 2"}
        />
      </div>
      <div style={{ display: "flex" }}>
        <div className="input_block">
          <p style={{ fontWeight: 600 }}>City</p>
          <input
            disabled={!editable}
            value={Detail_Tab_address_getData.WorkAddress.City}
            onChange={(e) => {
              dispatch(setProntactEntitieschange(true));
              dispatch(
                setProntactEntitiesSubTabAddresData({
                  ...Detail_Tab_address_getData,
                  WorkAddress: {
                    ...Detail_Tab_address_getData.WorkAddress,
                    City: e.target.value,
                  },
                  isChange: true,
                })
              );
            }}
            placeholder={"City"}
          />
        </div>
        <div className="input_block">
          <p style={{ fontWeight: 600 }}>State</p>
          <input
            disabled={!editable}
            value={Detail_Tab_address_getData.WorkAddress.State}
            onChange={(e) => {
              dispatch(setProntactEntitieschange(true));
              dispatch(
                setProntactEntitiesSubTabAddresData({
                  ...Detail_Tab_address_getData,
                  WorkAddress: {
                    ...Detail_Tab_address_getData.WorkAddress,
                    State: e.target.value,
                  },
                  isChange: true,
                })
              );
            }}
            placeholder={"State"}
          />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div className="input_block_">
          <p style={{ fontWeight: 600 }}>ZIP</p>

          <NumberBox
            style={{ marginTop: "2%", height: "40px" }}
            onKeyDown={(e) => {
              const { event } = e;
              const str = event.key || String.fromCharCode(event.which);
              if (/^[.,e]$/.test(str)) {
                event.preventDefault();
              }
            }}
            value={Detail_Tab_address_getData.WorkAddress.ZIP}
            disabled={!editable}
            min={0}
            onValueChange={(e) => {
              dispatch(
                setProntactEntitiesSubTabAddresData({
                  ...Detail_Tab_address_getData,
                  WorkAddress: {
                    ...Detail_Tab_address_getData.WorkAddress,
                    ZIP: e,
                  },
                  isChange: true,
                })
              );
            }}
            placeholder={"ZIP"}
          />
        </div>
        <div className="input_block">
          <p style={{ fontWeight: 600 }}>County</p>
          <input
            disabled={!editable}
            value={Detail_Tab_address_getData.WorkAddress.Country}
            onChange={(e) => {
              dispatch(setProntactEntitieschange(true));
              dispatch(
                setProntactEntitiesSubTabAddresData({
                  ...Detail_Tab_address_getData,
                  WorkAddress: {
                    ...Detail_Tab_address_getData.WorkAddress,
                    Country: e.target.value,
                  },
                  isChange: true,
                })
              );
            }}
            placeholder={"Country"}
          />
        </div>
      </div>
      <div className="input_block">
        <p style={{ fontWeight: 600 }}>Comment/Phone</p>
        <textarea
          disabled={!editable}
          value={Detail_Tab_address_getData.WorkAddress.Comment}
          onChange={(e) => {
            dispatch(setProntactEntitieschange(true));
            dispatch(
              setProntactEntitiesSubTabAddresData({
                ...Detail_Tab_address_getData,
                WorkAddress: {
                  ...Detail_Tab_address_getData.WorkAddress,
                  Comment: e.target.value,
                },
                isChange: true,
              })
            );
          }}
          style={{
            width: "100%",
            height: "80px",
            borderRadius: "3px",
            marginTop: "2%",
          }}
          placeholder={"Comment/Phone"}
        />
      </div>
    </div>
  );
};

export const ProntactEntitiesSubTabAddres = ({ EditTogel }) => {
  const { ProntactEntitiesSubTabAddresData } = useSelector(
    (store) => store.AdminTable
  );

  return (
    <>
      <div className="Detail_Address_Tab">
        <Detail_Address_Tab_home_address
          Detail_Tab_address_getData={ProntactEntitiesSubTabAddresData}
          editable={EditTogel}
        />
        <Detail_Address_Tab_mail_address
          Detail_Tab_address_getData={ProntactEntitiesSubTabAddresData}
          editable={EditTogel}
        />

        <Detail_Address_Tab_work_address
          Detail_Tab_address_getData={ProntactEntitiesSubTabAddresData}
          editable={EditTogel}
        />
      </div>
    </>
  );
};
