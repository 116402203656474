import axios from "axios";
import { handleGetLocal } from "../../services/auth-service";
import { logOut } from "../../components/Logout";
import image from "../../assets/images/Screenshot from 2023-09-08 11-07-05.png";
import image2 from "../../assets/images/Screenshot from 2023-09-08 11-08-13.png";
import image3 from "../../assets/images/Screenshot from 2023-09-08 11-08-49.png";
import image4 from "../../assets/images/Screenshot from 2023-09-08 11-09-52.png";
import image5 from "../../assets/images/Screenshot from 2023-09-08 11-11-23.png";

export class ReportFunction {
  constructor() {
    this.pathname = handleGetLocal("pathURL");
  }

  async getReportDashboardData(navigate, setLoading, seterrorhandler, setdata) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/ReportDashboard/getReportDashboardData`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandler(false);
          if (val?.data?.data?.reportDashboardTypes?.length) {
            let data = [];
            val?.data?.data?.reportDashboardTypes.map((vall) => {
              let arr = [];
              val?.data?.data?.reportDashboardReportDtos?.map((valll) => {
                if (valll?.sCategoryName === vall?.sName) {
                  arr.push(valll);
                }
              });
              let Image;
              if (vall?.sName === "Supervision") {
                Image = image;
              } else if (vall?.sName === "Financial") {
                Image = image2;
              } else if (vall?.sName === "Administration") {
                Image = image5;
              } else if (vall?.sName === "Monitoring") {
                Image = image4;
              } else {
                Image = image3;
              }
              data.push({ ...vall, Image, array: [...arr] });
            });
            setdata(data);
          }
        } else {
          seterrorhandler(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandler(true);
        }
      });
  }

  async getparamete(
    navigate,
    setLoading,
    id,
    setdata,
    setSelectReportCriteriaPopupopen
  ) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/ReportDashboard/get-parameter?gReportId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        // console.log(val.data.data);
        setdata(val?.data?.data);
        setSelectReportCriteriaPopupopen(true);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  async print(navigate, setLoading, body, setToastConfig, toastConfig) {
    setLoading(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .post(
        `${process.env.REACT_APP_CRYSTAL_API_URL}/reports-api/reports/report-dashboard`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
          responseType: "blob",
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.size > 20) {
          const pdfBlob = new Blob([val.data], { type: "application/pdf" });
          const pdfUrl = URL.createObjectURL(pdfBlob);
          const downloadLink = document.createElement("a");
          downloadLink.href = pdfUrl;
          downloadLink.download = body?.sReportName?.split(".")[0] + ".pdf";
          downloadLink.style.display = "none";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "No Report Found!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "No Report Found!",
              type: "error",
            });
          }, 1400);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }
}
