import { useEffect, useRef, useState } from "react";
import {
  detail_Tab_Name_getData,
  detail_name_tab_Validation,
  setefffectiveData,
  setfirstnamevalidation,
  setstateparticipent,
} from "../../../../reducer/ParticipateReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { handleGetLocal } from "../../../../services/auth-service";
import { DateBox, NumberBox, TextBox } from "devextreme-react";
import { logOut } from "../../../../components/Logout";
import { ApiError } from "../../../Error/ApiError";
import { ColumnDropdown } from "../../../../components/Dropdownn";

const DropDown = ({
  dropDownData,
  setfields,
  Detail_Tab_Name_getData,
  setValue,
  reset,
  editable,
  stateparticipent,
  searchMode,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={detail_Tab_Name_getData}
        fields={Detail_Tab_Name_getData}
        fieldNmae={setValue}
        value={Detail_Tab_Name_getData[setValue]?.name}
        // reset={reset}
        margin={"7px"}
        disabled={!editable}
        redux={true}
        resetErrorHandling={true}
        stateparticipent={stateparticipent}
        searchMode={searchMode}
      />
    </>
  );
};

export const Detail_Name_Tab = ({ editable, editParticipant }) => {
  const pathname = handleGetLocal("pathURL");
  const { Detail_Tab_Name_getData, Detail_name_tab_Validation, efffectiveData, stateparticipent, firstnamevalidation } =
    useSelector((store) => store.participate);

  const dispatch = useDispatch();
  const [gender, setgender] = useState([]);
  const [eyeColor, seteyeColor] = useState([]);
  const [hairColor, sethairColor] = useState([]);
  const [languages, setlanguage] = useState([]);
  const [race, setRace] = useState([]);
  const [complianceLevel, setcomplianceLevel] = useState();
  const [statePreferences, setstatePreferences] = useState([]);
  const navigate = useNavigate();
  const [errorhandling, seterrorhandling] = useState(false);

  const getalldropdown = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Person/masterDropDownValuesGet`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        seterrorhandling(false);
        const genderData = val?.data?.data?.gender;
        genderData?.reverse();
        setgender(
          genderData.map((vall) => {
            return { name: vall?.sName, id: vall?.sAbbr };
          })
        );
        seteyeColor(
          val?.data?.data?.eyeColor?.map((vall) => {
            return { name: vall?.sName, id: vall?.gEyeColorId };
          })
        );
        sethairColor(
          val?.data?.data?.hairColor?.map((vall) => {
            return { name: vall?.sName, id: vall?.gHairColorId };
          })
        );
        setlanguage(
          val?.data?.data?.language?.map((vall) => {
            return { name: vall?.sName, id: vall?.gLanguageId };
          })
        );
        setRace(
          val?.data?.data?.race?.map((vall) => {
            return { name: vall?.sName, id: vall?.gRaceId };
          })
        );
        setcomplianceLevel(
          val?.data?.data?.complianceLevel?.map((vall) => {
            return { name: vall?.sName, id: vall?.gComplianceLevelId };
          })
        );
        setstatePreferences(
          val?.data?.data?.statePreferences?.map((vall) => {
            return { name: vall?.sState, id: vall?.sState };
          })
        );
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  const { user_data } = useSelector((store) => store.Home);

  useEffect(() => {
    if (editable) {
      statePreferences.forEach((state) => {
        if (state.name === user_data.sState) {
          dispatch(
            detail_Tab_Name_getData({
              ...Detail_Tab_Name_getData,
              StatePreference: state,
            })
          );
        }
      });
    }
  }, [statePreferences]);

  useEffect(() => {
    getalldropdown();
  }, []);

  const validationChcek = (pattern, text) => {
    var passPattern = new RegExp(pattern);
    return passPattern.test(text);
  };

  const idd = useParams();
  const maskRules = {
    H: /^[0-9*]$/,
  };

  return (
    <>
      {errorhandling ? (
        <ApiError />
      ) : (
        <div className="commonBlock_Details_nav_tab">
          <div className="commonBlock_Details_nav_tab_uper">
            <button
              style={{
                background: "var(--button-lightblack-lightblue)",
                color: "var(--icons2)",
                fontWeight: "600",
              }}
            >
              Person Summary
              <svg
                className="svg-blue-white"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V15C0 15.55 0.45 16 1 16H15C15.55 16 16 15.55 16 15V14C16 11.34 10.67 10 8 10Z"
                  fill="#424167"
                />
              </svg>
            </button>
            <p style={{ color: "var(--TextColor)", fontWeight: "600" }}>Offender #</p>
            <p style={{ color: "var(--TextColor)", fontWeight: "700" }}>{idd?.id}</p>
          </div>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div style={{ width: "25%" }}>
              <div className="Detail_Home_Tab_input_block_">
                <p>Alt ID</p>

                <TextBox
                  style={{
                    fontWeight: 600,
                    color: "rgba(0, 0, 0, 0.6)",
                    marginTop: "3%",
                  }}
                  value={Detail_Tab_Name_getData.AltID?Detail_Tab_Name_getData.AltID:""}
                  onValueChange={(e) => {
                    dispatch(
                      detail_Tab_Name_getData({
                        ...Detail_Tab_Name_getData,
                        AltID: e,
                        isChange: true,
                      })
                    );
                  }}
                  disabled={!editable}
                  isValid={true}
                  placeholder={`Alt ID`}
                />
              </div>

              <div className="Detail_Home_Tab_input_block_">
                <p>
                  First Name <span className="mendatrystrick">*</span>
                </p>

                <TextBox
                  style={{
                    fontWeight: 600,
                    color: "rgba(0, 0, 0, 0.6)",
                    marginTop: "3%",
                  }}
                  value={Detail_Tab_Name_getData.FirstName}
                  onValueChange={(e) => {
                    dispatch(
                      detail_Tab_Name_getData({
                        ...Detail_Tab_Name_getData,
                        FirstName: e,
                        isChange: true,
                      })
                    );
                    if (!e) {
                      dispatch(setfirstnamevalidation("Please Enter First Name"));
                    } else {
                      dispatch(setfirstnamevalidation(""));
                    }
                  }}
                  disabled={!editable}
                  isValid={true}
                  placeholder={`First Name`}
                />

                {firstnamevalidation && (
                  <p style={{ color: "red" }} className="Error">
                    {firstnamevalidation}
                  </p>
                )}
              </div>

              <div className="Detail_Home_Tab_input_block_">
                <p>Middle Name</p>

                <TextBox
                  style={{
                    fontWeight: 600,
                    color: "rgba(0, 0, 0, 0.6)",
                    marginTop: "3%",
                  }}
                  value={Detail_Tab_Name_getData.MiddleName}
                  onValueChange={(e) => {
                    dispatch(
                      detail_Tab_Name_getData({
                        ...Detail_Tab_Name_getData,
                        MiddleName: e,
                        isChange: true,
                      })
                    );
                  }}
                  disabled={!editable}
                  isValid={true}
                  placeholder={`Middle Name`}
                />
              </div>

              <div className="Detail_Home_Tab_input_block_">
                <p>
                  Last Name <span className="mendatrystrick">*</span>
                </p>

                <TextBox
                  style={{
                    fontWeight: 600,
                    color: "rgba(0, 0, 0, 0.6)",
                    marginTop: "3%",
                  }}
                  value={Detail_Tab_Name_getData.LastName}
                  onValueChange={(e) => {
                    dispatch(
                      detail_Tab_Name_getData({
                        ...Detail_Tab_Name_getData,
                        LastName: e,
                        isChange: true,
                      })
                    );
                    if (validationChcek("^[^0-9]*$", e)) {
                      dispatch(
                        detail_name_tab_Validation({
                          ...Detail_name_tab_Validation,
                          LastName: "",
                        })
                      );
                    } else {
                      dispatch(
                        detail_name_tab_Validation({
                          ...Detail_name_tab_Validation,
                          LastName: "Please Enter Valid Last Name",
                        })
                      );
                    }

                    if (!e) {
                      dispatch(
                        detail_name_tab_Validation({
                          ...Detail_name_tab_Validation,
                          LastName: "Please Enter Last Name",
                        })
                      );
                    }
                  }}
                  disabled={!editable}
                  isValid={true}
                  placeholder={`Last Name`}
                />

                {Detail_name_tab_Validation.LastName && (
                  <p style={{ color: "red" }} className="Error">
                    {Detail_name_tab_Validation.LastName}
                  </p>
                )}
              </div>
            </div>
            <div style={{ width: "25%" }}>
              <div className="Detail_Home_Tab_input_block_">
                <p>
                  Date of birth <span className="mendatrystrick">*</span>
                </p>

                <DateBox
                  value={Detail_Tab_Name_getData.DateOfBirth}
                  useMaskBehavior={true}
                  onValueChange={(e) => {
                    dispatch(
                      detail_Tab_Name_getData({
                        ...Detail_Tab_Name_getData,
                        DateOfBirth: e ? e : null,
                        isChange: true,
                      })
                    );

                    if (!e) {
                      dispatch(
                        detail_name_tab_Validation({
                          ...Detail_name_tab_Validation,
                          DateOfBirth: "Please select date of birth",
                        })
                      );
                    } else {
                      dispatch(
                        detail_name_tab_Validation({
                          ...Detail_name_tab_Validation,
                          DateOfBirth: "",
                        })
                      );
                    }
                  }}
                  style={{
                    marginTop: "3.3%",
                    fontWeight: 600,
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                  disabled={!editable}
                />
                {Detail_name_tab_Validation.DateOfBirth && (
                  <p style={{ color: "red" }} className="Error">
                    {Detail_name_tab_Validation.DateOfBirth}
                  </p>
                )}
              </div>
              <div className="Detail_Home_Tab_input_block_">
                <p>SSN</p>
                <TextBox
                  style={{ marginTop: "3%" }}
                  showMaskMode="always"
                  maskRules={{ H: /^[0-9*]$/ }}
                  mask="HHH-HH-HHHH"
                  value={
                    editable
                      ? Detail_Tab_Name_getData.SSN
                      : String(Detail_Tab_Name_getData.SSN).length <= 5
                      ? Detail_Tab_Name_getData.SSN.replace(/[0-9]/g, "*")
                      : "*****" + Detail_Tab_Name_getData.SSN?.slice(5)
                  }
                  onValueChange={(e) => {
                    dispatch(
                      detail_Tab_Name_getData({
                        ...Detail_Tab_Name_getData,
                        SSN: e,
                        isChange: true,
                      })
                    );
                  }}
                  disabled={!editable}
                  isValid={true}
                />
              </div>
              <div className="Detail_Home_Tab_input_block_">
                <p>Gender</p>
                <DropDown
                  editable={editable}
                  Detail_Tab_Name_getData={Detail_Tab_Name_getData}
                  setValue={"Gender"}
                  dropDownData={gender}
                  searchMode={"startswith"}
                />
              </div>

              <div className="Detail_Home_Tab_input_block_">
                <p>Language</p>
                <DropDown
                  editable={editable}
                  Detail_Tab_Name_getData={Detail_Tab_Name_getData}
                  setValue={"Language"}
                  dropDownData={languages}
                  searchMode={"startswith"}
                />
              </div>
            </div>
            <div style={{ width: "25%" }}>
              <div className="Detail_Home_Tab_input_block_">
                <p>
                  Effective Date <span className="mendatrystrick">*</span>
                </p>
                <DateBox
                  value={Detail_Tab_Name_getData.EffectiveDate}
                  style={{
                    marginTop: "3.3%",
                  }}
                  useMaskBehavior={true}
                  onValueChange={(e) => {
                    if (e) {
                      dispatch(
                        detail_Tab_Name_getData({
                          ...Detail_Tab_Name_getData,
                          EffectiveDate: e ? e : null,
                          isChange: true,
                        })
                      );
                      if (!e) {
                        dispatch(setefffectiveData("Please select Effective Date"));
                      } else {
                        dispatch(setefffectiveData(""));
                      }
                    }
                  }}
                  disabled={!editable}
                />
                {efffectiveData && (
                  <p style={{ color: "red" }} className="Error">
                    {efffectiveData}
                  </p>
                )}
              </div>
              <div className="Detail_Home_Tab_input_block_">
                <p>Compliance Level</p>
                <DropDown
                  editable={editable}
                  Detail_Tab_Name_getData={Detail_Tab_Name_getData}
                  setValue={"ComplianceLevel"}
                  dropDownData={complianceLevel}
                  searchMode={"startswith"}
                />
              </div>
              <div className="Detail_Home_Tab_input_block_">
                <p>
                  State Preference <span className="mendatrystrick">*</span>
                </p>
                <DropDown
                  editable={editable}
                  Detail_Tab_Name_getData={Detail_Tab_Name_getData}
                  setValue={"StatePreference"}
                  dropDownData={statePreferences}
                  stateparticipent={stateparticipent}
                  searchMode={"startswith"}
                />
                {stateparticipent && (
                  <p style={{ color: "red" }} className="Error">
                    {stateparticipent}
                  </p>
                )}
              </div>
            </div>
            <div style={{ width: "25%" }}>
              <div className="Detail_Home_Tab_input_block_">
                <p>Height</p>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <NumberBox
                    style={{ marginTop: "2%", height: "40px" }}
                    onKeyDown={(e) => {
                      const { event } = e;
                      const str = event.key || String.fromCharCode(event.which);
                      if (/^[.,e]$/.test(str)) {
                        event.preventDefault();
                      }
                    }}
                    value={Detail_Tab_Name_getData.Height.feet}
                    disabled={!editable}
                    min={0}
                    onValueChange={(e) => {
                      dispatch(
                        detail_Tab_Name_getData({
                          ...Detail_Tab_Name_getData,
                          Height: {
                            ...Detail_Tab_Name_getData.Height,
                            feet: e,
                          },
                          isChange: true,
                        })
                      );
                    }}
                    placeholder={`Feet`}
                  />
                  <p style={{ marginTop: "15px" }}>ft</p>

                  <NumberBox
                    style={{ marginTop: "2%", height: "40px" }}
                    onKeyDown={(e) => {
                      const { event } = e;
                      const str = event.key || String.fromCharCode(event.which);
                      if (/^[.,e]$/.test(str)) {
                        event.preventDefault();
                      }
                    }}
                    value={Detail_Tab_Name_getData.Height.inch}
                    disabled={!editable}
                    min={0}
                    onValueChange={(e) => {
                      dispatch(
                        detail_Tab_Name_getData({
                          ...Detail_Tab_Name_getData,
                          Height: {
                            ...Detail_Tab_Name_getData.Height,
                            inch: e,
                          },
                          isChange: true,
                        })
                      );
                    }}
                    placeholder={`Inch`}
                  />
                  <p style={{ marginTop: "15px" }}>in</p>
                </div>
              </div>
              <div className="Detail_Home_Tab_input_block_">
                <p>Weight</p>
                <div style={{ display: "flex", justifyContent: "" }}>
                  <NumberBox
                    style={{ marginTop: "2%", height: "40px" }}
                    onKeyDown={(e) => {
                      const { event } = e;
                      const str = event.key || String.fromCharCode(event.which);
                      if (/^[.,e]$/.test(str)) {
                        event.preventDefault();
                      }
                    }}
                    value={Detail_Tab_Name_getData.Weight}
                    disabled={!editable}
                    min={0}
                    onValueChange={(e) => {
                      dispatch(
                        detail_Tab_Name_getData({
                          ...Detail_Tab_Name_getData,
                          Weight: e,
                          isChange: true,
                        })
                      );
                    }}
                    placeholder={`Weight`}
                  />
                  <p style={{ marginTop: "15px" }}>lbs</p>
                </div>
              </div>
              <div className="Detail_Home_Tab_input_block_">
                <p>Race</p>
                <DropDown
                  editable={editable}
                  Detail_Tab_Name_getData={Detail_Tab_Name_getData}
                  setValue={"Race"}
                  dropDownData={race}
                  searchMode={"startswith"}
                />
              </div>
              <div className="Detail_Home_Tab_input_block_">
                <p>Hair Color</p>
                <DropDown
                  editable={editable}
                  Detail_Tab_Name_getData={Detail_Tab_Name_getData}
                  setValue={"HairColor"}
                  dropDownData={hairColor}
                  searchMode={"startswith"}
                />
              </div>
              <div className="Detail_Home_Tab_input_block_">
                <p>Eye Color</p>
                <DropDown
                  editable={editable}
                  Detail_Tab_Name_getData={Detail_Tab_Name_getData}
                  setValue={"EyeColor"}
                  dropDownData={eyeColor}
                  searchMode={"startswith"}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
