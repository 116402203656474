import { useEffect, useState } from "react";
import { Detail_Address_Tab } from "./Detail_Address_Tab";
import { Detail_Contact_Tab } from "./Detail_Contact_Tab";
import { Detail_Name_Tab } from "./Detail_Name_Tab";
import { Detail_Payment_Tab } from "./Detail_Payment_Tab";
import { Detail_Notification_Tab } from "./Detail_Notification_Tab";
import { Detail_Co_Obligor_Tab } from "./Detail_Co_Obligor_Tab";
import { Popup } from "devextreme-react/popup";
import "devextreme/dist/css/dx.light.css";
import {
  detail_Notification_Tab_getData,
  detail_Payment_Tab_getData,
  detail_Tab_Name_getData,
  detail_Tab_address_getData,
  detail_co_obligor_Tab_getData,
  setAltertPopup,
  setAltertPopupoblig,
  setcontectInfoTableCount,
} from "../../../../reducer/ParticipateReducer";
import { useDispatch, useSelector } from "react-redux";
import { ScrollView } from "devextreme-react";
import axios from "axios";
import { handleGetLocal } from "../../../../services/auth-service";
import { useNavigate, useParams } from "react-router-dom";
import { logOut } from "../../../../components/Logout";

export const DetailTab = ({
  editable,
  userinfo,
  editParticipant,
  saveContact,
  setSaveContact,
  setGoodToSaveParticipant,
}) => {
  const [tab, setTab] = useState(1);
  const [errorhandling, seterrorhandling] = useState(false);

  useEffect(() => {
    if (saveContact === true) {
      if (tab !== 3) setGoodToSaveParticipant(true);
    }
  }, [saveContact]);

  const tabs = [
    {
      number: 1,
      component: <Detail_Name_Tab editParticipant={editParticipant} userinfo={userinfo} editable={editable} />,
    },
    {
      number: 2,
      component: <Detail_Address_Tab userinfo={userinfo} editable={editable} />,
    },
    {
      number: 3,
      component: (
        <Detail_Contact_Tab
          editParticipant={editParticipant}
          userinfo={userinfo}
          editable={editable}
          errorhandling={errorhandling}
          saveContact={saveContact}
          setSaveContact={setSaveContact}
          setGoodToSaveParticipant={setGoodToSaveParticipant}
        />
      ),
    },
    {
      number: 4,
      component: <Detail_Payment_Tab userinfo={userinfo} editable={editable} />,
    },
    {
      number: 5,
      component: <Detail_Notification_Tab userinfo={userinfo} editable={editable} />,
    },
    {
      number: 6,
      component: <Detail_Co_Obligor_Tab userinfo={userinfo} editable={editable} />,
    },
  ];

  const style = (tabb) => {
    return {
      backgroundColor: tab === tabb ? "var(--btnColor)" : "var(--btn-grey-black)",
      color: tab === tabb ? "white" : "var(--TextColor)",
    };
  };

  const dispatch = useDispatch();

  const { showalertPopup, contectInfoTableCount, showalertPopupcount } = useSelector((store) => store.participate);

  const pathname = handleGetLocal("pathURL");

  const navigate = useNavigate();

  const getalldropdown = async (gPersonId) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/User/personDetailContactInfo?gPersonId=${gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        seterrorhandling(false);
        dispatch(setcontectInfoTableCount(val.data.data.length));
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  const updateDetailTabInfo = (userinfo) => {
    dispatch(
      detail_Tab_Name_getData({
        StatePreference:
          userinfo?.sOffenderNumberState !== null
            ? {
                name: userinfo?.sOffenderNumberState,
                id: userinfo?.sOffenderNumberState,
              }
            : { name: "", id: "" },
        LastName: userinfo?.sLastName !== null ? userinfo?.sLastName : "",
        Gender:
          userinfo?.sGenderName !== null
            ? { name: userinfo?.sGenderName, id: userinfo?.sGender }
            : { name: "", id: "" },
        EyeColor:
          userinfo?.sEyeColorName !== null
            ? { name: userinfo?.sEyeColorName, id: userinfo?.gEyeColorId }
            : { name: "", id: "" },
        AltID: userinfo?.sOffenderNumberPin !== null ? userinfo?.sOffenderNumberPin : "",
        SSN: userinfo?.sSsn !== null ? (userinfo?.sSsn ? String(userinfo?.sSsn)?.replace(/\*/g, "") : "") : "",
        Height: {
          feet: userinfo?.iHeightFoot !== null ? userinfo?.iHeightFoot : "0",
          inch: userinfo?.iHeightInch !== null ? userinfo?.iHeightInch : "0",
        },
        Language:
          userinfo?.sLanguageName !== null
            ? { name: userinfo?.sLanguageName, id: userinfo?.gLanguageId }
            : { name: "", id: "" },
        FirstName: userinfo?.sFirstName !== null ? userinfo?.sFirstName : "",
        DateOfBirth: userinfo?.dDob
          ? `${new Date(userinfo?.dDob)?.getFullYear()}-${(new Date(userinfo?.dDob)?.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${new Date(userinfo?.dDob).getDate().toString().padStart(2, "0")}`
          : "",
        Weight: userinfo?.iWeight !== null ? userinfo?.iWeight : "0",
        ComplianceLevel:
          userinfo?.sComplianceLevelName !== null
            ? {
                name: userinfo?.sComplianceLevelName,
                id: userinfo?.gComplianceLevelId,
              }
            : { name: "", id: "" },
        MiddleName: userinfo?.sMiddleName !== null ? userinfo?.sMiddleName : "",
        Race:
          userinfo?.sRaceName !== null ? { name: userinfo?.sRaceName, id: userinfo?.gRaceId } : { name: "", id: "" },
        HairColor:
          userinfo?.sHairName !== null
            ? { name: userinfo?.sHairName, id: userinfo?.gHairColorId }
            : { name: "", id: "" },
        EffectiveDate:
          userinfo?.dEffective !== "1900-01-01T00:00:00"
            ? userinfo?.dEffective
              ? `${new Date(userinfo?.dEffective)?.getFullYear()}-${(new Date(userinfo?.dEffective)?.getMonth() + 1)
                  .toString()
                  .padStart(2, "0")}-${new Date(userinfo?.dEffective).getDate().toString().padStart(2, "0")}`
              : new Date().toISOString().split("T")[0]
            : new Date().toISOString().split("T")[0],
        isChange: false,
      })
    );
    dispatch(
      detail_Tab_address_getData({
        MailingAddress: {
          AddressLine1: userinfo?.sMailingAddress1 !== null ? userinfo?.sMailingAddress1 : "",
          AddressLine2: userinfo?.sMailingAddress2 !== null ? userinfo?.sMailingAddress2 : "",
          City: userinfo?.sMailingCity !== null ? userinfo?.sMailingCity : "",
          State: userinfo?.sMailingState !== null ? userinfo?.sMailingState : "",
          ZIP: userinfo?.sMailingZip !== null ? userinfo?.sMailingZip : "",
          Country: userinfo?.gMailingCountyName !== null ? userinfo?.gMailingCountyName : "",
          Comment: userinfo?.sMailingAddressComment !== null ? userinfo?.sMailingAddressComment : "",
        },
        HomeAddress: {
          AddressLine1: userinfo?.sHomeAddress1 !== null ? userinfo?.sHomeAddress1 : "",
          AddressLine2: userinfo?.sHomeAddress2 !== null ? userinfo?.sHomeAddress2 : "",
          City: userinfo?.sHomeCity !== null ? userinfo?.sHomeCity : "",
          State: userinfo?.sHomeState !== null ? userinfo?.sHomeState : "",
          ZIP: userinfo?.sHomeZip !== null ? userinfo?.sHomeZip : "",
          Country: userinfo?.gHomeCountyName !== null ? userinfo?.gHomeCountyName : "",
          Comment: userinfo?.sHomeAddressComment !== null ? userinfo?.sHomeAddressComment : "",
        },
        WorkAddress: {
          Employer: userinfo?.sWorkEmployer !== null ? userinfo?.sWorkEmployer : "",
          AddressLine1: userinfo?.sWorkAddress1 !== null ? userinfo?.sWorkAddress1 : "",
          AddressLine2: userinfo?.sWorkAddress2 !== null ? userinfo?.sWorkAddress2 : "",
          City: userinfo?.sWorkCity !== null ? userinfo?.sWorkCity : "",
          State: userinfo?.sWorkState !== null ? userinfo?.sWorkState : "",
          ZIP: userinfo?.sWorkZip !== null ? userinfo?.sWorkZip : "",
          Country: userinfo?.gWorkCountyName !== null ? userinfo?.gWorkCountyName : "",
          Comment: userinfo?.sWorkAddressComment !== null ? userinfo?.sWorkAddressComment : "",
        },
        isChange: false,
      })
    );
    dispatch(
      detail_Payment_Tab_getData({
        PaymentNotes: userinfo?.sPaymentNote !== null ? userinfo?.sPaymentNote : "",
        Frequency:
          userinfo?.sPaymentFrequency !== null ? { name: userinfo?.sPaymentFrequency, id: "" } : { name: "", id: "" },
        Amount: userinfo?.sPaymentAmount !== null ? userinfo?.sPaymentAmount : "",
        NextPaymentDate: userinfo?.dNextPaymentOverride !== null ? userinfo?.dNextPaymentOverride?.split("T")[0] : "",
        ComplianceLevel:
          userinfo?.gComplianceLevelId !== null
            ? {
                name: userinfo?.sComplianceLevelName,
                id: userinfo?.gComplianceLevelId,
              }
            : { name: "", id: "" },
        ActionPlan: userinfo?.sActionPlan !== null ? userinfo?.sActionPlan : "",
        isChange: false,
      })
    );
    dispatch(
      detail_Notification_Tab_getData({
        AlertNote: userinfo?.sAlertNote !== null ? userinfo?.sAlertNote : "",
        email: "",
        phone: "",
        isChange: false,
      })
    );
    dispatch(
      detail_co_obligor_Tab_getData({
        FirstNmae: userinfo?.sGuarantorFirstName !== null ? userinfo?.sGuarantorFirstName : "",
        MiddleName: userinfo?.sGuarantorMiddleName !== null ? userinfo?.sGuarantorMiddleName : "",
        LastName: userinfo?.sGuarantorLastName !== null ? userinfo?.sGuarantorLastName : "",
        AddressLine1: userinfo?.sGuarantorAddress1 !== null ? userinfo?.sGuarantorAddress1 : "",
        AddressLine2: userinfo?.sGuarantorAddress2 !== null ? userinfo?.sGuarantorAddress2 : "",
        City: userinfo?.sGuarantorCity !== null ? userinfo?.sGuarantorCity : "",
        State: userinfo?.sGuarantorState !== null ? userinfo?.sGuarantorState : "",
        ZIP: userinfo?.sGuarantorZip !== null ? userinfo?.sGuarantorZip : "",
        SSN: userinfo?.sGuarantorSsn !== null ? userinfo?.sGuarantorSsn : "",

        DOB: userinfo?.dGuarantorDob
          ? `${new Date(userinfo?.dGuarantorDob).getFullYear()}-${(new Date(userinfo?.dGuarantorDob).getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${new Date(userinfo?.dGuarantorDob).getDate().toString().padStart(2, "0")}`
          : "",
        HomePhone:
          userinfo?.sGuarantorHomePhone !== null
            ? userinfo?.sGuarantorHomePhone
              ? String(userinfo?.sGuarantorHomePhone)
                  .replace(/[()\-]/g, "")
                  .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
              : null
            : "",
        WorkPhone:
          userinfo?.sGuarantorWorkPhone !== null
            ? userinfo?.sGuarantorWorkPhone
              ? String(userinfo?.sGuarantorWorkPhone)
                  .replace(/[()\-]/g, "")
                  .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
              : null
            : "",
        CellPhone:
          userinfo?.sGuarantorOtherPhone !== null
            ? userinfo?.sGuarantorOtherPhone
              ? String(userinfo?.sGuarantorOtherPhone)
                  .replace(/[()\-]/g, "")
                  .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
              : null
            : "",
        CardOnFile: userinfo?.bGuarantorCardOnFile !== null ? userinfo?.bGuarantorCardOnFile : false,

        isChange: false,
      })
    );
    setTab(1);

    userinfo?.gPersonId && getalldropdown(userinfo?.gPersonId);
  };

  useEffect(() => {
    if (editParticipant) {
      // updateDetailTabInfo(userinfo);
      setTab(1);
    }
  }, [userinfo]);

  return (
    <>
      <Popup
        visible={showalertPopupcount[userinfo?.sOffenderNumber] && showalertPopup}
        onHiding={() => {
          dispatch(setAltertPopup(false));
          dispatch(setAltertPopupoblig(userinfo?.sOffenderNumber));
        }}
        showTitle={false}
        width={310}
        height={210}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <div className="alertpopup">
          <div className="alertpopup_head">
            <div className="alertpopup_head_title">
              <div className="alertpopup_head_title_data">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g id="warning">
                    <path
                      id="Vector"
                      d="M4.47012 20.9997H19.5301C21.0701 20.9997 22.0301 19.3297 21.2601 17.9997L13.7301 4.98969C12.9601 3.65969 11.0401 3.65969 10.2701 4.98969L2.74012 17.9997C1.97012 19.3297 2.93012 20.9997 4.47012 20.9997ZM12.0001 13.9997C11.4501 13.9997 11.0001 13.5497 11.0001 12.9997V10.9997C11.0001 10.4497 11.4501 9.99969 12.0001 9.99969C12.5501 9.99969 13.0001 10.4497 13.0001 10.9997V12.9997C13.0001 13.5497 12.5501 13.9997 12.0001 13.9997ZM13.0001 17.9997H11.0001V15.9997H13.0001V17.9997Z"
                      fill="#ffff"
                    />
                  </g>
                </svg>
                <p>Alert Note</p>
              </div>
            </div>
            <svg
              onClick={() => {
                dispatch(setAltertPopup(false));
                dispatch(setAltertPopupoblig(userinfo?.sOffenderNumberWithPin));
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          <div style={{ marginTop: "-15px", width: "95%", marginLeft: "3%" }}>
            <ScrollView style={{ width: "100%", height: "139px" }}>
              <p style={{ width: "100%", wordWrap: "break-word" }}>{userinfo?.sAlertNote}</p>
            </ScrollView>
          </div>
        </div>
      </Popup>

      <div className="commonBlock_Details">
        <div className="commonBlock_Details_nav">
          <button
            onClick={() => {
              setTab(1);
            }}
            style={style(1)}
          >
            <svg
              className={tab === 1 ? "svg-white" : "svg-black-white"}
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 6C7.6575 6 9 4.6575 9 3C9 1.3425 7.6575 0 6 0C4.3425 0 3 1.3425 3 3C3 4.6575 4.3425 6 6 6ZM6 7.5C3.9975 7.5 0 8.505 0 10.5V11.25C0 11.6625 0.3375 12 0.75 12H11.25C11.6625 12 12 11.6625 12 11.25V10.5C12 8.505 8.0025 7.5 6 7.5Z"
                fill={tab == 1 ? "white" : "black"}
              />
            </svg>
            Name
          </button>
          <button
            onClick={() => {
              setTab(2);
            }}
            style={style(2)}
          >
            <svg
              className={tab === 2 ? "svg-white" : "svg-black-white"}
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.25 7.24986V3.37236C9.25 2.97486 9.0925 2.59236 8.8075 2.31486L7.525 1.03236C7.2325 0.739863 6.76 0.739863 6.4675 1.03236L5.1925 2.30736C4.9075 2.59236 4.75 2.97486 4.75 3.37236V4.24986H1.75C0.925 4.24986 0.25 4.92486 0.25 5.74986V13.2499C0.25 14.0749 0.925 14.7499 1.75 14.7499H12.25C13.075 14.7499 13.75 14.0749 13.75 13.2499V8.74986C13.75 7.92486 13.075 7.24986 12.25 7.24986H9.25ZM3.25 13.2499H1.75V11.7499H3.25V13.2499ZM3.25 10.2499H1.75V8.74986H3.25V10.2499ZM3.25 7.24986H1.75V5.74986H3.25V7.24986ZM7.75 13.2499H6.25V11.7499H7.75V13.2499ZM7.75 10.2499H6.25V8.74986H7.75V10.2499ZM7.75 7.24986H6.25V5.74986H7.75V7.24986ZM7.75 4.24986H6.25V2.74986H7.75V4.24986ZM12.25 13.2499H10.75V11.7499H12.25V13.2499ZM12.25 10.2499H10.75V8.74986H12.25V10.2499Z"
                fill={tab == 2 ? "white" : "black"}
              />
            </svg>
            Address
          </button>
          <button
            onClick={() => {
              setTab(3);
            }}
            style={style(3)}
          >
            <svg
              className={tab === 3 ? "svg-white" : "svg-black-white"}
              width="11"
              height="18"
              viewBox="0 0 11 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.625 0.75H2.625C1.59 0.75 0.75 1.59 0.75 2.625V15.375C0.75 16.41 1.59 17.25 2.625 17.25H8.625C9.66 17.25 10.5 16.41 10.5 15.375V2.625C10.5 1.59 9.66 0.75 8.625 0.75ZM5.625 16.5C5.0025 16.5 4.5 15.9975 4.5 15.375C4.5 14.7525 5.0025 14.25 5.625 14.25C6.2475 14.25 6.75 14.7525 6.75 15.375C6.75 15.9975 6.2475 16.5 5.625 16.5ZM9 13.5H2.25V3H9V13.5Z"
                fill={tab == 3 ? "white" : "black"}
              />
            </svg>
            Contact Info {editParticipant ? `(${contectInfoTableCount})` : `(0)`}
          </button>
          <button
            onClick={() => {
              setTab(4);
            }}
            style={style(4)}
          >
            <svg
              className={tab === 4 ? "svg-white" : "svg-black-white"}
              width="18"
              height="12"
              viewBox="0 0 18 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.25 3V10.5C17.25 11.325 16.575 12 15.75 12H3.75C3.3375 12 3 11.6625 3 11.25C3 10.8375 3.3375 10.5 3.75 10.5H15.75V3C15.75 2.5875 16.0875 2.25 16.5 2.25C16.9125 2.25 17.25 2.5875 17.25 3ZM3 9C1.755 9 0.75 7.995 0.75 6.75V2.25C0.75 1.005 1.755 0 3 0H12C13.245 0 14.25 1.005 14.25 2.25V7.5C14.25 8.325 13.575 9 12.75 9H3ZM5.25 4.5C5.25 5.745 6.255 6.75 7.5 6.75C8.745 6.75 9.75 5.745 9.75 4.5C9.75 3.255 8.745 2.25 7.5 2.25C6.255 2.25 5.25 3.255 5.25 4.5Z"
                fill={tab == 4 ? "white" : "black"}
              />
            </svg>
            Payment Info
          </button>
          <button
            onClick={() => {
              setTab(5);
            }}
            style={style(5)}
          >
            <svg
              className={tab === 5 ? "svg-white" : "svg-black-white"}
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.00236 15.5C6.82736 15.5 7.50236 14.825 7.50236 14H4.50236C4.50236 14.825 5.16986 15.5 6.00236 15.5ZM10.5024 11V7.25C10.5024 4.9475 9.27236 3.02 7.12736 2.51V2C7.12736 1.3775 6.62486 0.875 6.00236 0.875C5.37986 0.875 4.87736 1.3775 4.87736 2V2.51C2.72486 3.02 1.50236 4.94 1.50236 7.25V11L0.534858 11.9675C0.0623579 12.44 0.392358 13.25 1.05986 13.25H10.9374C11.6049 13.25 11.9424 12.44 11.4699 11.9675L10.5024 11Z"
                fill={tab == 5 ? "white" : "black"}
              />
            </svg>
            Notifications
          </button>
          <button
            onClick={() => {
              setTab(6);
            }}
            style={style(6)}
          >
            <svg
              className={tab === 6 ? "svg-white" : "svg-black-white"}
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.25 9H12.5C12.9125 9 13.25 8.6625 13.25 8.25V3.75C13.25 3.3375 12.9125 3 12.5 3H10.25C9.8375 3 9.5 3.3375 9.5 3.75V8.25C9.5 8.6625 9.8375 9 10.25 9ZM11 4.5H11.75V7.5H11V4.5ZM5.75 9H8C8.4125 9 8.75 8.6625 8.75 8.25V3.75C8.75 3.3375 8.4125 3 8 3H5.75C5.3375 3 5 3.3375 5 3.75V8.25C5 8.6625 5.3375 9 5.75 9ZM6.5 4.5H7.25V7.5H6.5V4.5ZM3.5 3C3.0875 3 2.75 3.3375 2.75 3.75V8.25C2.75 8.6625 3.0875 9 3.5 9C3.9125 9 4.25 8.6625 4.25 8.25V3.75C4.25 3.3375 3.9125 3 3.5 3ZM0.5 1.5V10.5C0.5 11.325 1.175 12 2 12H14C14.825 12 15.5 11.325 15.5 10.5V1.5C15.5 0.675 14.825 0 14 0H2C1.175 0 0.5 0.675 0.5 1.5ZM13.25 10.5H2.75C2.3375 10.5 2 10.1625 2 9.75V2.25C2 1.8375 2.3375 1.5 2.75 1.5H13.25C13.6625 1.5 14 1.8375 14 2.25V9.75C14 10.1625 13.6625 10.5 13.25 10.5Z"
                fill={tab == 6 ? "white" : "black"}
              />
            </svg>
            Co-Obligor
          </button>
        </div>
        <div style={{ width: "85%" }}>{tabs.map((tabb) => tabb.number === tab && tabb.component)}</div>
      </div>
    </>
  );
};
