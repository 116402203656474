import { useState, useEffect } from "react";
import image1 from "../../../../assets/images/image5.jpg";
import uploadimage from "../../../../assets/images/icons/upload.svg";
import "./../../HeaderPopupComponent/models.css";
import { useDispatch, useSelector } from "react-redux";
import LoadPanel from "devextreme-react/load-panel";
import question from "./../../../../assets/images/question.png";
import { Popup } from "devextreme-react/popup";
import {
  changeCancel,
  detail_Notification_Tab_getData,
  detail_Payment_Tab_getData,
  detail_Tab_Name_getData,
  detail_Tab_address_getData,
  detail_co_obligor_Tab_getData,
  detail_name_tab_Validation,
  headerCheckBoc,
  remove_dynamicParticipatTab,
  setAddParticipateDockets,
  setAddParticipateStep,
  setAddParticipateUserInfo,
  setAltertPopup,
  setShowAddParticipatePopUp,
  set_dynamicParticipatTab,
  setcontectInfoTabWhileAddParticipent,
  setcontectTableAddDataaa,
  setefffectiveData,
  setfirstnamevalidation,
  setheaderCheckBoxChange,
  setparticipantdata,
  setsetChangesavedatapopup,
  setstateparticipent,
  updateset_dynamicParticipatTab,
} from "../../../../reducer/ParticipateReducer";
import {
  AddBody,
  SaveBody,
  checkValidation_Detail_name_tab,
  getCustomFlag,
  setCustomFlag,
} from "../../ParticipantsValidationFunction";
import { useNavigate, useParams } from "react-router-dom";
import { handleGetLocal } from "../../../../services/auth-service";
import axios from "axios";
import { DetailData, DetailData1, DetailData2, DetailData3 } from "../../ParticipantHeaderComponent";
import { useRef } from "react";
import { ParticipentHeaderPopup } from "../../ParticipentHeaderPopup";
import { Toster } from "../../../../components/Toster/Toster";
import { logOut } from "../../../../components/Logout";
import { ApiError } from "../../../Error/ApiError";
import { ParticipentSubTabs } from "../..//ParticipantSubTabs/ParticipentSubTabs";
import { headersidebarfunction } from "../../../HeaderSideBar/headersidebarfunction";
import HelpPopup from "../../../HeaderSideBar/HelpPopup";
import { Detail_Name_Tab } from "../../ParticipantSubTabs/DetailTab/Detail_Name_Tab";
import { Detail_Contact_Tab } from "../../ParticipantSubTabs/DetailTab/Detail_Contact_Tab";
import { Detail_Address_Tab } from "../../ParticipantSubTabs/DetailTab/Detail_Address_Tab";
import { AddDocketInfo } from "../AddDocket/AddDocketInfo";
import { AddObligationInfo } from "../AddObligation/AddObligationInfo";
import { setAddObligation, setgotooblogationValue } from "../../../../reducer/participantNoteReducer";
import { setDynamicTab } from "../../../../reducer/HomePageReducer";

export const AddPersonalInfo = ({ setEditable, editable, editParticipant }) => {
  const pathname = handleGetLocal("pathURL");

  const {
    Detail_name_tab_Validation,
    Detail_Tab_Name_getData,
    Detail_Tab_address_getData,
    cancel,
    PaymentBlock,
    OnlineReporting,
    RecurringCreditCard,
    Feeblock,
    dynamicParticipatTab,
    Detail_co_obligor_Tab_getData,
    Detail_Payment_Tab_getData,
    Detail_Notification_Tab_getData,
    contectInfoTabWhileAddParticipent,
    addworentREfresh,
    entityperson,
    GPersonTextContactInfoId,
    GPersonEmailContactInfoId,
    AddParticipateStep,
    AddParticipateUserInfo,
  } = useSelector((store) => store.participate);

  const [showNoObligationPopup, setShowNoObligationPopup] = useState(false);
  const { recentDocket, recentObligation } = useSelector((store) => store.AddParticipantWizardReducer);

  const dispatch = useDispatch();

  const [loading, setLoding] = useState(false);

  const [obligationStatus, setObligationStatus] = useState(null);

  const navigate = useNavigate();

  const saveCookieCrum = async (perdonId) => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/cookie-crumbs/save-cookie-crumb?recordId=${perdonId}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        //console.log(val.data.data);
      })
      .catch(() => {
        console.log("kjh");
      });
  };

  const [saveContact, setSaveContact] = useState(false);

  const [goodToSaveParticipant, setGoodToSaveParticipant] = useState(false);

  const AddParticipant = async (next) => {
    setLoding(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Person/SaveParticipant`,
        AddBody(
          Detail_Tab_Name_getData,
          null, // userinfo?.gPersonId
          null, // userinfo?.sOffenderNumber
          Detail_Tab_address_getData,
          PaymentBlock,
          OnlineReporting,
          RecurringCreditCard,
          Feeblock,
          Detail_co_obligor_Tab_getData,
          Detail_Payment_Tab_getData,
          Detail_Notification_Tab_getData,
          contectInfoTabWhileAddParticipent
        ),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        dispatch(setheaderCheckBoxChange(false));
        if (val.data.isSuccess) {
          saveCookieCrum(val.data.data.id);
          dispatch(
            setAddParticipateUserInfo({
              sLastFirst: `${Detail_Tab_Name_getData.LastName} ${Detail_Tab_Name_getData.FirstName}`,
              gPersonId: val.data.data.id,
              sOffenderNo: val.data.data.offenderNo,
              next: true,
            })
          );
          dispatch(setShowAddParticipatePopUp(true));
          setLoding(false);
          if (!next) dispatch(remove_dynamicParticipatTab("/add-participant"));
          dispatch(
            headerCheckBoc({
              PaymentBlock: false,
              OnlineReporting: false,
              RecurringCreditCard: false,
              Feeblock: false,
            })
          );
          dispatch(
            detail_Tab_Name_getData({
              StatePreference: { name: "", id: "" },
              LastName: "",
              Gender: { name: "", id: "" },
              EyeColor: { name: "", id: "" },
              AltID: "",
              SSN: "",
              Height: { feet: "", inch: "" },
              Language: { name: "", id: "" },
              FirstName: "",
              DateOfBirth: "",
              Weight: "",
              ComplianceLevel: { name: "", id: "" },
              MiddleName: "",
              Race: { name: "", id: "" },
              HairColor: { name: "", id: "" },
              EffectiveDate: "",
              isChange: false,
            })
          );
          dispatch(
            detail_Tab_address_getData({
              MailingAddress: {
                AddressLine1: "",
                AddressLine2: "",
                City: "",
                State: "",
                ZIP: "",
                Country: "",
                Comment: "",
              },
              HomeAddress: {
                AddressLine1: "",
                AddressLine2: "",
                City: "",
                State: "",
                ZIP: "",
                Country: "",
                Comment: "",
              },
              WorkAddress: {
                Employer: "",
                AddressLine1: "",
                AddressLine2: "",
                City: "",
                State: "",
                ZIP: "",
                Country: "",
                Comment: "",
              },
              isChange: false,
            })
          );
          dispatch(
            detail_Payment_Tab_getData({
              PaymentNotes: "",
              Frequency: "",
              Amount: "",
              NextPaymentDate: "",
              ComplianceLevel: "",
              ActionPlan: "",
              isChange: false,
            })
          );
          dispatch(
            detail_Notification_Tab_getData({
              AlertNote: "",
              email: "",
              phone: "",
              isChange: false,
            })
          );
          dispatch(
            detail_co_obligor_Tab_getData({
              FirstNmae: "",
              MiddleName: "",
              LastName: "",
              AddressLine1: "",
              AddressLine2: "",
              City: "",
              State: "",
              ZIP: "",
              SSN: "",

              DOB: "",
              HomePhone: "",
              WorkPhone: "",
              CellPhone: "",
              CardOnFile: "",

              isChange: false,
            })
          );
          if (!next) {
            dispatch(
              setDynamicTab({
                Offender: val.data.data.offenderNo,
                title: `${Detail_Tab_Name_getData?.LastName}`,
              })
            );
            navigate(`/participant/${val.data.data.offenderNo}`);
            dispatch(setcontectInfoTabWhileAddParticipent([]));
            dispatch(setcontectTableAddDataaa([]));
          } else {
            dispatch(setAddParticipateStep(2));
            dispatch(setcontectInfoTabWhileAddParticipent([]));
            dispatch(setcontectTableAddDataaa([]));
          }
        }
      })
      .catch((val) => {
        setLoding(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
        }
      });
  };

  const OnSaveParticipant = async (next) => {
    if (
      checkValidation_Detail_name_tab(Detail_Tab_Name_getData, Detail_name_tab_Validation) &&
      !imageUploadValidation
    ) {
      if (
        Detail_Tab_Name_getData?.EffectiveDate &&
        Detail_Tab_Name_getData?.StatePreference?.id &&
        Detail_Tab_Name_getData?.FirstName
      ) {
        AddParticipant(next);
        setEditable(false);
      } else {
        if (!editParticipant) {
          !Detail_Tab_Name_getData?.EffectiveDate
            ? dispatch(setefffectiveData("Please select Effective Date"))
            : dispatch(setefffectiveData(""));
          !Detail_Tab_Name_getData?.StatePreference?.id
            ? dispatch(setstateparticipent("Please select State"))
            : dispatch(setstateparticipent(""));
          !Detail_Tab_Name_getData?.FirstName
            ? dispatch(setfirstnamevalidation("Please Enter First Name"))
            : dispatch(setfirstnamevalidation(""));
        }
      }
    } else {
      setSaveContact(false);
      setGoodToSaveParticipant(false);
      dispatch(
        detail_name_tab_Validation({
          ...Detail_name_tab_Validation,
          DateOfBirth: !Detail_Tab_Name_getData.DateOfBirth ? "Please select date of birth" : "",
          LastName: !Detail_Tab_Name_getData.LastName ? "Please Enter Last Name" : "",
        })
      );
      if (true) {
        !Detail_Tab_Name_getData?.EffectiveDate && dispatch(setefffectiveData("Please select Effective Date"));
        !Detail_Tab_Name_getData?.StatePreference?.name && dispatch(setstateparticipent("Please select State"));
        !Detail_Tab_Name_getData?.FirstName && dispatch(setfirstnamevalidation("Please Enter First Name"));
      }
    }
  };

  const [flag, setFlag] = useState(0);

  const OnFinish = () => {
    setFlag(0);
    AddParticipateStep === 1 && setSaveContact(true);

    if (AddParticipateStep === 2) {
      // dispatch(remove_dynamicParticipatTab("/add-participant"));
      // dispatch(setAddParticipateStep(1));
      // dispatch(setAddParticipateDockets([]));
      // dispatch(setAddParticipateUserInfo({}));
      // navigate(-1);
      onCancel(true);
    }
  };

  const OnNextToDocket = async () => {
    setFlag(1);
    if (AddParticipateStep === 1) {
      // OnSaveParticipant(1);
      setSaveContact(true);
    }
  };

  const OnNextToPerson = async () => {
    onCancel(false);
    let lastName = AddParticipateUserInfo?.sLastFirst.split(" ")[0];
    dispatch(
      setDynamicTab({
        Offender: AddParticipateUserInfo?.sOffenderNo,
        title: lastName,
      })
    );
    navigate(`/participant/${AddParticipateUserInfo?.sOffenderNo}`);
  };

  // const OnNextToRecentObligation = () => {
  //   if (recentObligation?.gObligationId) {
  //     onCancel(false);
  //     dispatch(setAddObligation(false));
  //     dispatch(
  //       set_dynamicParticipatTab({
  //         Offender: `/obligation/${recentDocket?.sDocketNumberCourtCounts?.replace(/\//g, "-")}/${
  //           recentObligation?.sReference
  //         }`,
  //         title: `Obligation : ${recentDocket?.sDocketNumberCourtCounts} / ${recentObligation?.sReference}`,
  //         sDocketNumberCourtCounts: recentDocket?.sDocketNumberCourtCounts,
  //         ObligationRef: recentObligation?.sReference,
  //         gObligationId: recentObligation?.gObligationId,
  //         userinfo: recentObligation?.userinfo,
  //       })
  //     );
  //     dispatch(
  //       setgotooblogationValue({
  //         gObligationId: recentObligation?.gObligationId,
  //         userinfo: recentObligation?.userinfo,
  //       })
  //     );
  //     navigate(
  //       `/obligation/${recentDocket?.sDocketNumberCourtCounts?.replace(/\//g, "-")}/${recentObligation?.sReference}`
  //     );
  //   } else {
  //     setShowNoObligationPopup(true);
  //   }
  // };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Obligations/get-obligation-status?gPersonId=${AddParticipateUserInfo?.gPersonId}`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setObligationStatus(val?.data?.data?.sObligations);
      })
      .catch((val) => {
        setLoding(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
        }
      });
  }, [recentObligation]);

  const { contectTableAddData, contectTableAddData2 } = useSelector((store) => store.participate);

  useEffect(() => {
    // console.log(contectTableAddData);
    // console.log(contectInfoTabWhileAddParticipent);
    if (goodToSaveParticipant === true) {
      AddParticipateStep === 1 && OnSaveParticipant(flag);
    }
  }, [goodToSaveParticipant]);

  const [imageUploadValidation, setimageUploadValidation] = useState("");
  const onCancel = (isNavigating) => {
    dispatch(remove_dynamicParticipatTab("/add-participant"));
    dispatch(setAddParticipateStep(1));
    if (isNavigating) navigate(-1);
    dispatch(setheaderCheckBoxChange(false));
    dispatch(
      detail_name_tab_Validation({
        LastName: "",
        DateOfBirth: "",
      })
    );
    dispatch(setefffectiveData(""));
    dispatch(setstateparticipent(""));
    dispatch(setcontectInfoTabWhileAddParticipent([]));
    setimageUploadValidation("");
    setEditable(false);
    //   inputRef.current.value = "";
    dispatch(
      headerCheckBoc({
        PaymentBlock: false,
        OnlineReporting: false,
        RecurringCreditCard: false,
        Feeblock: false,
      })
    );
    dispatch(
      detail_Tab_Name_getData({
        StatePreference: { name: "", id: "" },
        LastName: "",
        Gender: { name: "", id: "" },
        EyeColor: { name: "", id: "" },
        AltID: "",
        SSN: "",
        Height: { feet: "", inch: "" },
        Language: { name: "", id: "" },
        FirstName: "",
        DateOfBirth: "",
        Weight: "",
        ComplianceLevel: { name: "", id: "" },
        MiddleName: "",
        Race: { name: "", id: "" },
        HairColor: { name: "", id: "" },
        EffectiveDate: "",
        isChange: false,
      })
    );
    dispatch(
      detail_Tab_address_getData({
        MailingAddress: {
          AddressLine1: "",
          AddressLine2: "",
          City: "",
          State: "",
          ZIP: "",
          Country: "",
          Comment: "",
        },
        HomeAddress: {
          AddressLine1: "",
          AddressLine2: "",
          City: "",
          State: "",
          ZIP: "",
          Country: "",
          Comment: "",
        },
        WorkAddress: {
          Employer: "",
          AddressLine1: "",
          AddressLine2: "",
          City: "",
          State: "",
          ZIP: "",
          Country: "",
          Comment: "",
        },
        isChange: false,
      })
    );
    dispatch(
      detail_Payment_Tab_getData({
        PaymentNotes: "",
        Frequency: "",
        Amount: "",
        NextPaymentDate: "",
        ComplianceLevel: "",
        ActionPlan: "",
        isChange: false,
      })
    );
    dispatch(
      detail_Notification_Tab_getData({
        AlertNote: "",
        email: "",
        phone: "",
        isChange: false,
      })
    );
    dispatch(
      detail_co_obligor_Tab_getData({
        FirstNmae: "",
        MiddleName: "",
        LastName: "",
        AddressLine1: "",
        AddressLine2: "",
        City: "",
        State: "",
        ZIP: "",
        SSN: "",

        DOB: "",
        HomePhone: "",
        WorkPhone: "",
        CellPhone: "",
        CardOnFile: "",

        isChange: false,
      })
    );
  };

  useEffect(() => {
    dispatch(
      headerCheckBoc({
        PaymentBlock: false,
        OnlineReporting: false,
        RecurringCreditCard: false,
        Feeblock: false,
      })
    );
    dispatch(
      detail_Tab_Name_getData({
        StatePreference: { name: "", id: "" },
        LastName: "",
        Gender: { name: "", id: "" },
        EyeColor: { name: "", id: "" },
        AltID: "",
        SSN: "",
        Height: { feet: "", inch: "" },
        Language: { name: "", id: "" },
        FirstName: "",
        DateOfBirth: "",
        Weight: "",
        ComplianceLevel: { name: "", id: "" },
        MiddleName: "",
        Race: { name: "", id: "" },
        HairColor: { name: "", id: "" },
        EffectiveDate: new Date().toISOString().split("T")[0],
        isChange: false,
      })
    );
    dispatch(
      detail_Tab_address_getData({
        MailingAddress: {
          AddressLine1: "",
          AddressLine2: "",
          City: "",
          State: "",
          ZIP: "",
          Country: "",
          Comment: "",
        },
        HomeAddress: {
          AddressLine1: "",
          AddressLine2: "",
          City: "",
          State: "",
          ZIP: "",
          Country: "",
          Comment: "",
        },
        WorkAddress: {
          Employer: "",
          AddressLine1: "",
          AddressLine2: "",
          City: "",
          State: "",
          ZIP: "",
          Country: "",
          Comment: "",
        },
        isChange: false,
      })
    );
    dispatch(
      detail_Payment_Tab_getData({
        PaymentNotes: "",
        Frequency: "",
        Amount: "",
        NextPaymentDate: "",
        ComplianceLevel: "",
        ActionPlan: "",
        isChange: false,
      })
    );
    dispatch(
      detail_Notification_Tab_getData({
        AlertNote: "",
        email: "",
        phone: "",
        isChange: false,
      })
    );
    dispatch(
      detail_co_obligor_Tab_getData({
        FirstNmae: "",
        MiddleName: "",
        LastName: "",
        AddressLine1: "",
        AddressLine2: "",
        City: "",
        State: "",
        ZIP: "",
        SSN: "",

        DOB: "",
        HomePhone: "",
        WorkPhone: "",
        CellPhone: "",
        CardOnFile: "",

        isChange: false,
      })
    );
  }, [cancel]);

  useEffect(() => {
    if (AddParticipateUserInfo.gPersonId) getDockrtManagement(AddParticipateUserInfo.gPersonId);
  }, [AddParticipateUserInfo]);

  const getDockrtManagement = async (gPersonId) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/get-docket-management?gPersonId=${gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          dispatch(
            setAddParticipateDockets(
              val?.data?.data?.map((val, i) => {
                return {
                  Alerts: val?.sDocketAlert,
                  Reference: val?.sReferenceNumber,
                  "Docket/Count": val?.sDocketNumberCourtCounts,
                  Count: val?.sCourtName,
                  Offense: val?.sOffenseDescription,
                  Supervisor: val?.sSupervisorNameLastFirst,
                  "Supervisor Status": val?.sSupervisionStatusName,
                  "Court Sentence": val?.dCourtSentence,
                  "Sentence Date Range": val?.sSentenceDateRange,
                  "Days Tolled": val?.iSentence_AdditionalDays,
                  Balance: val?.fBalance,
                  "Docket Link": val?.sDocketLinkTypeName,
                  "Total Days": val?.iSentence_TotalDays,
                  sDocketNumberCourtCounts: val?.sDocketNumberCourtCounts,
                  userinfo: val,
                  ID: i,
                };
              })
            )
          );
        }
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Popup
        visible={showNoObligationPopup}
        onHiding={() => {
          setShowNoObligationPopup(false);
        }}
        showTitle={false}
        width={310}
        height={210}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <div className="alertpopup">
          <div className="alertpopup_head">
            <div className="alertpopup_head_title">
              <div className="alertpopup_head_title_data">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="24"
                  height="24"
                  viewBox="0 0 128 128"
                  fill="white"
                >
                  <path d="M 64 6 C 32 6 6 32 6 64 C 6 96 32 122 64 122 C 96 122 122 96 122 64 C 122 32 96 6 64 6 z M 64 12 C 92.7 12 116 35.3 116 64 C 116 92.7 92.7 116 64 116 C 35.3 116 12 92.7 12 64 C 12 35.3 35.3 12 64 12 z M 64 30 A 9 9 0 0 0 64 48 A 9 9 0 0 0 64 30 z M 64 59 C 59 59 55 63 55 68 L 55 92 C 55 97 59 101 64 101 C 69 101 73 97 73 92 L 73 68 C 73 63 69 59 64 59 z"></path>
                </svg>
                <p>Info</p>
              </div>
            </div>
            <svg
              onClick={() => {
                setShowNoObligationPopup(false);
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          <div
            style={{
              marginTop: "-15px",
              width: "95%",
              marginLeft: "3%",
              height: "139px",
            }}
          >
            <p style={{ width: "100%" }}>Please Add Obligation.</p>
            <button
              onClick={() => {
                setShowNoObligationPopup(false);
              }}
              style={{
                backgroundColor: "var(--button2)",
                color: "white",
                marginTop: "25%",
                width: "80px",
                float: "right",
                cursor: "pointer",
              }}
            >
              OK
            </button>
          </div>
        </div>
      </Popup>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "97%",
          marginLeft: "1.4%",
          marginTop: "1.4%",
        }}
      >
        <div style={{ display: "flex", width: "60%" }}>
          <p
            style={{
              fontWeight: "400",
              fontSize: "24px",
              color: "var(--TextColor)",
              marginTop: "1.4%",
            }}
          >
            {AddParticipateStep === 1 && "Fill Personal Information"}
            {AddParticipateStep === 2 && "Add Docket"}
          </p>
        </div>
        <div className="buttons">
          <button onClick={() => onCancel(true)} className="btn">
            Cancel
          </button>
          <button onClick={OnFinish} className="primary_btn">
            Finish
          </button>
          {AddParticipateStep === 1 && (
            <button onClick={OnNextToDocket} className="primary_btn">
              Next
            </button>
          )}
          {AddParticipateStep === 2 && (
            <button onClick={OnNextToPerson} className="primary_btn">
              Next
            </button>
          )}
        </div>
      </div>
      {AddParticipateStep === 1 && (
        <div className="fix_participant_header" style={{ overflowY: "scroll" }}>
          <Detail_Name_Tab editable={editable} />
          <div>
            <button
              style={{
                background: "var(--button-lightblack-lightblue)",
                color: "var(--icons2)",
                fontWeight: "600",
                marginLeft: "2%",
                width: "17%",
                display: "flex",
                alignItems: "center",
              }}
            >
              Contact Information
            </button>
            <Detail_Contact_Tab
              editable={editable}
              saveContact={saveContact}
              setSaveContact={setSaveContact}
              setGoodToSaveParticipant={setGoodToSaveParticipant}
              AddParticipant={true}
            />
          </div>
          <div style={{ marginBottom: "1.4%" }}>
            <button
              style={{
                background: "var(--button-lightblack-lightblue)",
                color: "var(--icons2)",
                fontWeight: "600",
                marginLeft: "2%",
                width: "17%",
                display: "flex",
                alignItems: "center",
              }}
            >
              Address Information
            </button>
            <Detail_Address_Tab editable={editable} />
          </div>
        </div>
      )}
      {AddParticipateStep === 2 && (
        <AddDocketInfo
          userinfo={AddParticipateUserInfo}
          updateDocketManagement={() => {
            getDockrtManagement(AddParticipateUserInfo.gPersonId);
          }}
          obligationStatus={obligationStatus}
        />
      )}
      {/* {step === 3 && <AddObligationInfo />} */}
    </>
  );
};
