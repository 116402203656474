export const setTableData = (data) => {
  const arr = [];
  data.map((val) => {
    arr.push({
      ID: val?.gPersonID,
      Status: val?.sPersonSupervisionStatusName,
      Text: val?.bText,
      Email: val?.bEmail,
      "Split Fee": val?.bAgencySplit,
      "Client Id": val.sOffenderNumber,
      "Client Name": val.sLastFirst,
      Amount: val.fAmount,
      Balance: val?.fBalance,
      Arrears: val.fArrears,
      "Pending CC": val.fCreditBalance,
      "Court/Agency": val.sCourtName,
      "Days Ahead/Behind": val.fDaysBehind,
      Company: val.sCompanyName,
      Region: val?.sRegionName,
      "Last Payment": val?.dLastPayment,
      "Compliance Level": val?.sComplianceLevelName,
      Supervisor: val.sPrimarySupervisorName,
      "Last Arrearage Note": val.sLastArrearageNote,
      gPersonId: val?.gPersonID,
    });
  });
  return arr;
};
