import { List, LoadPanel, ScrollView, Toast } from "devextreme-react";
import { ColumnDropdown } from "../../../../components/Dropdownn";
import { useEffect, useRef, useState } from "react";
import { handleGetLocal } from "../../../../services/auth-service";
import axios from "axios";
import { useSelector } from "react-redux";
import { seton_Save_Edit } from "../../../../reducer/participantNoteReducer";
import { useDispatch } from "react-redux";
import { logOut } from "../../../../components/Logout";
import { useNavigate } from "react-router-dom";

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  setCategoryValidaition,
  fieldNmae,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        reset={setCategoryValidaition}
      />
    </>
  );
};

const NameDisplay = ({ fielsName, setFileNames, FileNames }) => {
  return (
    <>
      <div className="AttachFiles_Popup_fileNameDisplay">
        <div style={{ height: "168px" }}>
          <List
            onItemDeleting={(e) => {
              setFileNames(
                FileNames.filter((val) => {
                  return val.name !== String(e.itemData.name);
                })
              );
            }}
            allowItemDeleting={true}
            dataSource={fielsName}
            displayExpr="fileName"
            id="list"
          />
        </div>
      </div>
    </>
  );
};

export const AttachFiles = ({
  setAttachFielPopup,
  fielsName,
  inputRef,
  setfielsName,
  attachmentCategories,
  userinfo,
  toastConfig,
  setToastConfig,
  attachmentfunction,
  getphotoTableData,
}) => {
  const [FileNames, setFileNames] = useState([]);
  const inputRef2 = useRef(null);
  const pathname = handleGetLocal("pathURL");
  const [loading, setLoading] = useState(false);
  const [fileValidaition, setfileValidaition] = useState("");
  const [CategoryValidaition, setCategoryValidaition] = useState("");
  const [fields, setFields] = useState({
    Category: {
      name: "",
      id: "",
    },
    Description: "",
  });
  const { resetReportDates, on_Save_Edit } = useSelector(
    (store) => store.participateNotes
  );
  const navigate = useNavigate();

  const OnSave = async (body) => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/attachments/save-attachments`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val.data.isSuccess) {
          attachmentfunction();
          getphotoTableData();
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Added!",
            type: "success",
          });

          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Added!",
              type: "success",
            });
          }, 1400);
        }

        Reset();
        clear();
        setAttachFielPopup(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const save = () => {
    let isimaget = false;
    fielsName.map((val) => {
      if (!val?.isImage) {
        isimaget = true;
        return;
      }
    });
    if (fields.Category.id && FileNames.length > 0) {
      if (isimaget && fields.Category.name == "Photo") {
        setCategoryValidaition("Please Select Correct Category");
        return;
      }
      setCategoryValidaition("");
      setfileValidaition("");
      OnSave({
        binaryDataId: "",
        recordId: userinfo?.gPersonId,
        doe: new Date().toISOString(),
        filename: "",
        description: fields.Description,
        attachmentCategoryId: fields.Category.id,
        attachedFiles: FileNames,
        attachment: "",
        enteredByUserName: "",
        attachmentCateogoryName: "",
      });
    } else {
      !fields.Category.id
        ? setCategoryValidaition("Please select the Category")
        : setCategoryValidaition("");
      FileNames.length <= 0
        ? setfileValidaition("Please pick files")
        : setfileValidaition("");
    }
  };

  const clear = () => {
    setfielsName([]);
    inputRef.current.value = "";
    inputRef2.current.value = "";
  };

  const Reset = () => {
    setCategoryValidaition("");
    setfileValidaition("");
    setFields({
      Category: {
        name: "",
        id: "",
      },
      Description: "",
    });
  };

  useEffect(() => {
    setFileNames(fielsName);
    if (fielsName.length > 0) {
      setfileValidaition("");
    }
  }, [fielsName]);

  useEffect(() => {
    Reset();
  }, [resetReportDates]);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />

      <ScrollView
        style={{
          width: "100%",
          height: "647px",
          border: "3px solid rgb(67, 66, 102)",
        }}
      >
        <div className="CreateAppointmentPopup">
          <div className="RecivePaymentModel_header">
            <div
              style={{ width: "150px" }}
              className="RecivePaymentModel_header_name"
            >
              <svg
                width="8"
                height="16"
                viewBox="0 0 8 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.9974 4.49977V11.5531C6.9974 12.9464 5.9774 14.1864 4.59073 14.3198C2.9974 14.4731 1.66406 13.2264 1.66406 11.6664V3.42644C1.66406 2.55311 2.29073 1.75977 3.1574 1.67311C4.1574 1.57311 4.9974 2.35311 4.9974 3.33311V10.3331C4.9974 10.6998 4.6974 10.9998 4.33073 10.9998C3.96406 10.9998 3.66406 10.6998 3.66406 10.3331V4.49977C3.66406 4.22644 3.4374 3.99977 3.16406 3.99977C2.89073 3.99977 2.66406 4.22644 2.66406 4.49977V10.2398C2.66406 11.1131 3.29073 11.9064 4.1574 11.9931C5.1574 12.0931 5.9974 11.3131 5.9974 10.3331V3.44644C5.9974 2.05311 4.9774 0.813105 3.59073 0.679772C2.00406 0.526438 0.664062 1.77311 0.664062 3.33311V11.5131C0.664062 13.4264 2.06406 15.1398 3.97073 15.3198C6.16406 15.5198 7.9974 13.8131 7.9974 11.6664V4.49977C7.9974 4.22644 7.77073 3.99977 7.4974 3.99977C7.22406 3.99977 6.9974 4.22644 6.9974 4.49977Z"
                  fill="white"
                />
              </svg>
              <p style={{ marginLeft: "-10px" }}>Attach File</p>
              <span
                style={{
                  padding: "2px 9px",
                  gap: "8px",
                  width: "45px",
                  height: "24px",
                  background: "#FEF2F2",
                  borderRadius: "4px",
                }}
              >
                <p
                  style={{
                    color: "#DC2626",
                    fontFamily: "Readex Pro",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "10px",
                    lineHeight: "20px",
                    width: "21px",
                    height: "20px",
                  }}
                >
                  AF.1
                </p>
              </span>
            </div>
            <svg
              onClick={() => {
                setAttachFielPopup(false);
                clear();
                Reset();
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="AttachFiles_Popup">
            <div className="AttachFiles_Popup_button">
              <button style={{ position: "relative" }}>
                <input
                  type="file"
                  multiple
                  ref={inputRef2}
                  id="fileInput"
                  onChange={(e) => {
                    if (
                      e.target.files[0]?.name?.includes(".exe") ||
                      e.target.files[0]?.name?.includes(".zip")
                    ) {
                      return;
                    }
                    const files = Array.from(e.target.files);

                    Promise.all(
                      files.map((file) => {
                        return new Promise((resolve, reject) => {
                          const reader = new FileReader();

                          reader.onload = () => {
                            const base64String = reader.result.split(",")[1];
                            resolve(base64String);
                          };

                          reader.onerror = (error) => {
                            reject(error);
                          };

                          reader.readAsDataURL(file);
                        });
                      })
                    )
                      .then((base64Array) => {
                        // base64Array contains the Base64 encoded strings of the files
                        setfielsName(
                          base64Array.map((val, i) => {
                            return {
                              fileName: files[i].name,
                              doc: val,
                              isImage:
                                files[i].name.includes("jpg") ||
                                files[i].name.includes("png") ||
                                files[i].name.includes("jpeg")
                                  ? true
                                  : false,
                            };
                          })
                        );
                      })
                      .catch((val) => {
                        if (val?.response?.status == 401) {
                          logOut();
                        } else {
                          if (val?.response?.status) {
                            navigate(`/error/500`);
                          } else {
                            navigate("/error/500");
                          }
                        }
                      });

                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                  className="inputFieleHide"
                />
                <p>Add Files</p>
                <svg
                  className="svg-black-white"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
                    fill="#424167"
                  />
                </svg>
              </button>
              <button
                onClick={() => {
                  clear();
                }}
                style={{ backgroundColor: "#FEE2E2" }}
              >
                <p>Clear list</p>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.2987 0.709727C12.9087 0.319727 12.2787 0.319727 11.8887 0.709727L6.99875 5.58973L2.10875 0.699727C1.71875 0.309727 1.08875 0.309727 0.69875 0.699727C0.30875 1.08973 0.30875 1.71973 0.69875 2.10973L5.58875 6.99973L0.69875 11.8897C0.30875 12.2797 0.30875 12.9097 0.69875 13.2997C1.08875 13.6897 1.71875 13.6897 2.10875 13.2997L6.99875 8.40973L11.8887 13.2997C12.2787 13.6897 12.9087 13.6897 13.2987 13.2997C13.6887 12.9097 13.6887 12.2797 13.2987 11.8897L8.40875 6.99973L13.2987 2.10973C13.6787 1.72973 13.6787 1.08973 13.2987 0.709727Z"
                    fill="#E11D48"
                  />
                </svg>
              </button>
            </div>
            <NameDisplay
              FileNames={FileNames}
              setFileNames={setFileNames}
              fielsName={fielsName}
            />
            <p style={{ position: "absolute", color: "red" }}>
              {fileValidaition}
            </p>
            <div className="AttachFiles_Popup_dropdown">
              <p>
                Category <span style={{ fontSize: "14px" }}>*</span>
              </p>
              <div style={{ marginTop: "-15px" }}>
                <DropDown
                  dropDownData={attachmentCategories}
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"Category"}
                  setCategoryValidaition={setCategoryValidaition}
                />
                <p
                  style={{
                    position: "absolute",
                    color: "red",
                    fontWeight: "500",
                  }}
                >
                  {CategoryValidaition}
                </p>
              </div>
            </div>
            <div className="AttachFiles_Popup_discription">
              <p>Description</p>
              <textarea
                value={fields.Description}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    Description: e.target.value,
                  });
                }}
                style={{ width: "100%", height: "160px" }}
              />
            </div>
            <div
              className="popup_buttons"
              style={{ marginTop: "14px" }}
            >
              <button
                className="btn primary_btn"
                onClick={() => {
                  save();
                }}
              >
                Save
              </button>

              <button
                onClick={() => {
                  setAttachFielPopup(false);
                  clear();
                  Reset();
                }}
                className="btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ScrollView>
    </>
  );
};
