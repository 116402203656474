import { Table2 } from "../../../../../components/Table2/Table2";

const Tablecoloum = [
  {
    title: "Select",
  },
  {
    title: "Surcharge Name",
  },
  {
    title: "Long Name",
  },
  {
    title: "Start Range",
  },
  {
    title: "Fee Type",
  },
  {
    title: "Charge Method",
  },
];

export const Surcharge = () => {
  return (
    <>
      <Table2
        Tablecoloum={Tablecoloum}
        tableRow={[]}
        editTable={false}
        load={false}
        coloumWidthText={"feesheduleTable"}
        height={620}
        mandatoryColoum={"DOE"}
        showTotalBlock={false}
      />
    </>
  );
};
