import { ContextMenu, LoadPanel, Popup } from "devextreme-react";
import { useEffect, useState } from "react";

import { ColumnDropdown } from "../../../components/Dropdownn";
import { Table2 } from "../../../components/Table2/Table2";
import { ReportWizaerdFuncition } from "../ReportWizaerdFuncition";
import { useNavigate } from "react-router-dom";
import { ApiError } from "../../Error/ApiError";
import { PreviewPopup } from "../commonpopup/PreviewPopup";
import { Customizequerypopup } from "../commonpopup/Customizequerypopup";
import { SharequeryPopup } from "../commonpopup/SharequeryPopup";
import { CretaeNewFilter } from "../commonpopup/CretaeNewFilter";
import { Toster } from "../../../components/Toster/Toster";

const Tablecoloum = [
  {
    title: "Date",
    date: true,
  },
  { title: "Check #" },
  {
    title: "Recipient Name",
  },
  { title: "Bank Acct" },
  {
    title: "Offender #",
  },
  {
    title: "Offender Name",
  },
  {
    title: "Company",
  },
  {
    title: "Fee Code",
  },
  {
    title: "Fee Name",
  },
  {
    title: "Docket",
  },
  {
    title: "Amount",
    currency: true,
  },
  {
    title: "Void",
    bool: true,
  },
  {
    title: "Disburse Frequency",
  },
  {
    title: "Recipient Category",
  },
  {
    title: "Void Date",
  },
  {
    title: "Court",
  },
  {
    title: "Address 1",
  },
  {
    title: "Address 2",
  },
  {
    title: "City",
  },
  {
    title: "State",
  },
  {
    title: "Zip",
  },
];
const Sharequerypopups = ({ setsharequeryshow, sharequeryshow, ItemType }) => {
  return (
    <>
      <Popup
        visible={sharequeryshow}
        onHiding={() => {
          setsharequeryshow(false);
        }}
        showTitle={false}
        width={700}
        height={700}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <SharequeryPopup setsharequeryshow={setsharequeryshow} sharequeryshow={sharequeryshow} ItemType={ItemType} />
      </Popup>
    </>
  );
};

const Customizequery = ({
  Customizequerypopupshow,
  setCustomizequerypopupshow,
  defaultquery,
  xmlextrectdata,
  SLogicalUnitName,
  getQueryReportWizard,
  isdefault,
}) => {
  return (
    <>
      <Popup
        visible={Customizequerypopupshow}
        onHiding={() => {
          setCustomizequerypopupshow(false);
        }}
        showTitle={false}
        width={730}
        height={500}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <Customizequerypopup
          Customizequerypopupshow={Customizequerypopupshow}
          setCustomizequerypopupshow={setCustomizequerypopupshow}
          defaultquery={defaultquery}
          fielddynamicdata={[
            { name: "Start Date", type: "date", fieldName: "Start Date" },
            { name: "End Date", type: "date", fieldName: "End Date" },
            {
              name: "Recipient Name",
              type: "string",
              fieldName: "Recipient Name",
            },
          ]}
          xmlextrectdata={xmlextrectdata}
          SLogicalUnitName={SLogicalUnitName}
          getQueryReportWizard={getQueryReportWizard}
          isdefault={isdefault}
        />
      </Popup>
    </>
  );
};

const Preview = ({ Previewpopupshow, setPreviewpopupshow, setexportPopupOpen, setprewviewtogal, prewviewtogal }) => {
  return (
    <>
      <Popup
        visible={Previewpopupshow}
        onHiding={() => {
          setPreviewpopupshow(false);
        }}
        showTitle={false}
        width={1100}
        height={700}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <PreviewPopup
          Previewpopupshow={Previewpopupshow}
          setPreviewpopupshow={setPreviewpopupshow}
          setexportPopupOpen={setexportPopupOpen}
          setprewviewtogal={setprewviewtogal}
          prewviewtogal={prewviewtogal}
        />
      </Popup>
    </>
  );
};
const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, setxmldata, setisdefault, setfilterdata }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        setxmldata={setxmldata}
        setisdefault={setisdefault}
        setfilterdata={setfilterdata}
        // reset={reset}
      />
    </>
  );
};
const CretaeNewFilterpopup = ({
  setshowCretaeNewFilterpopup,
  showCretaeNewFilterpopup,
  tablename,
  filterName,
  getQueryFilterReportWizard,
  name,
  id,
  editname,
  setfield,
  field,
  setfilterdata,
}) => {
  return (
    <>
      <Popup
        visible={showCretaeNewFilterpopup}
        onHiding={() => {
          setshowCretaeNewFilterpopup(false);
        }}
        showTitle={false}
        width={500}
        height={250}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <CretaeNewFilter
          showCretaeNewFilterpopup={showCretaeNewFilterpopup}
          setshowCretaeNewFilterpopup={setshowCretaeNewFilterpopup}
          tablename={tablename}
          filterName={filterName}
          getQueryFilterReportWizard={getQueryFilterReportWizard}
          filtername={name}
          filterid={id}
          editname={editname}
          setfield={setfield}
          field={field}
          setfilterdata={setfilterdata}
        />
      </Popup>
    </>
  );
};

export const Disbursement = () => {
  const [Previewpopupshow, setPreviewpopupshow] = useState(false);
  const [Customizequerypopupshow, setCustomizequerypopupshow] = useState(false);
  const [xmlextrectdata, setxmlextrectdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorhandler, seterrorhandler] = useState(false);
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [xmldata, setxmldata] = useState("");
  const [field, setfield] = useState({
    query: { name: "", id: "" },
    filter: { name: "", id: "" },
  });
  const clas = new ReportWizaerdFuncition();
  const [querydropdown, setquerydropdown] = useState([]);
  const [isdefault, setisdefault] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const [prewviewtogal, setprewviewtogal] = useState(undefined);
  const [sharequeryshow, setsharequeryshow] = useState(false);
  const [filterdropdown, setfilterdropdown] = useState([]);
  const [tablereload, settablereload] = useState(false);
  const [filterdata, setfilterdata] = useState("");
  const [editname, seteditname] = useState(false);
  const [showCretaeNewFilterpopup, setshowCretaeNewFilterpopup] = useState(false);
  const [ItemTypeswitch, setItemTypeswitch] = useState(true);

  useEffect(() => {
    clas.getQueryReportWizard(
      setLoading,
      navigate,
      setquerydropdown,
      "DisbursementSQ",
      setfield,
      field,
      setxmldata,
      setisdefault
    );
    clas.getQueryFilterReportWizard(
      setLoading,
      navigate,
      "DisbursementSQ",
      setfilterdropdown,
      false,
      setfield,
      field,
      setfilterdata
    );
  }, []);
  const getQueryFilterReportWizard = (temp = false) => {
    clas.getQueryFilterReportWizard(
      setLoading,
      navigate,
      "DisbursementSQ",
      setfilterdropdown,
      temp,
      setfield,
      field,
      setfilterdata
    );
  };

  const getQueryReportWizard = () => {
    clas.getQueryReportWizard(
      setLoading,
      navigate,
      setquerydropdown,
      "DisbursementSQ",
      setfield,
      field,
      setxmldata,
      setisdefault
    );
  };

  useEffect(() => {
    if (xmldata) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmldata, "text/xml");
      const parameters = xmlDoc.querySelectorAll("Parameter");

      const extractedData = {};

      parameters.forEach((param) => {
        const name = param.querySelector("sName").textContent;
        const type = param.querySelector("sType").textContent;
        const value = param.querySelector("sValue").textContent;

        extractedData[name] = {
          type,
          value,
        };
      });

      var startdate = extractedData?.["Start Date"]?.value;
      var enddate = extractedData?.["End Date"]?.value;
      var RecipientName = extractedData?.["Recipient Name"]?.value;

      setxmlextrectdata([
        { "Start Date": extractedData?.["Start Date"]?.value },
        { "End Date": extractedData?.["End Date"]?.value },
        { "Recipient Name": extractedData?.["Recipient Name"]?.value },
      ]);

      clas.DisbursementReportWizard(setLoading, seterrorhandler, navigate, setdata, startdate, enddate, RecipientName);
    }
  }, [xmldata, refresh]);

  useEffect(() => {
    if (field?.filter?.id) {
      localStorage.setItem("ReportWizardDisbursementTable", filterdata);
      settablereload(!tablereload);
    }
  }, [field?.filter?.id]);

  useEffect(() => {
    localStorage.removeItem("ReportWizardDisbursementTable");
    settablereload(!tablereload);
  }, []);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  return (
    <>
      {errorhandler ? (
        <ApiError />
      ) : (
        <div>
          <LoadPanel shadingColor="true" visible={loading} delay={10} />
          <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
          <Customizequery
            Customizequerypopupshow={Customizequerypopupshow}
            setCustomizequerypopupshow={setCustomizequerypopupshow}
            defaultquery={field?.query}
            xmlextrectdata={xmlextrectdata}
            SLogicalUnitName={"DisbursementSQ"}
            getQueryReportWizard={getQueryReportWizard}
            isdefault={isdefault}
          />
          <Preview
            Previewpopupshow={Previewpopupshow}
            setPreviewpopupshow={setPreviewpopupshow}
            setexportPopupOpen={setexportPopupOpen}
            setprewviewtogal={setprewviewtogal}
            prewviewtogal={prewviewtogal}
          />
          <Sharequerypopups
            setsharequeryshow={setsharequeryshow}
            sharequeryshow={sharequeryshow}
            ItemType={ItemTypeswitch ? "Query" : "Filter"}
          />
          <CretaeNewFilterpopup
            setshowCretaeNewFilterpopup={setshowCretaeNewFilterpopup}
            showCretaeNewFilterpopup={showCretaeNewFilterpopup}
            tablename={"ReportWizardDisbursementTable"}
            filterName={"DisbursementSQ"}
            getQueryFilterReportWizard={getQueryFilterReportWizard}
            name={field?.filter?.name}
            id={field?.filter?.id}
            editname={editname}
            setfield={setfield}
            field={field}
            setfilterdata={setfilterdata}
          />

          <div style={{ margin: "1%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: ".3%",
              }}
            >
              <p
                style={{
                  color: "var(--input-color-black-white)",
                  fontWeight: "bold",
                  marginTop: "10px",
                }}
              >
                Query
              </p>
              <div style={{ width: "25%", marginLeft: "1%" }} id="Query">
                <DropDown
                  setfields={setfield}
                  fields={field}
                  fieldNmae={"query"}
                  dropDownData={querydropdown}
                  setxmldata={setxmldata}
                  setisdefault={setisdefault}
                />
                <ContextMenu
                  dataSource={[{ text: "Custom Query" }, { text: "Share Query" }]}
                  width={200}
                  target={`#Query`}
                  onItemClick={(e) => {
                    if (e.itemData.text === "Custom Query") {
                      setCustomizequerypopupshow(true);
                    } else if (e.itemData.text === "Share Query") {
                      setItemTypeswitch(true);
                      setsharequeryshow(true);
                    }
                  }}
                />
              </div>
              <p
                style={{
                  color: "var(--input-color-black-white)",
                  fontWeight: "bold",
                  marginTop: "10px",
                  marginLeft: "2%",
                }}
              >
                Filter
              </p>
              <div
                style={{
                  width: "25%",
                  marginLeft: "1%",
                }}
                id="Filter"
              >
                <DropDown
                  setfields={setfield}
                  fields={field}
                  fieldNmae={"filter"}
                  dropDownData={filterdropdown}
                  setfilterdata={setfilterdata}
                />
                <ContextMenu
                  dataSource={[
                    {
                      text: "Save Change",
                      disabled: field?.filter?.id ? false : true,
                    },
                    { text: "Create New Filter" },
                    { text: "Reset Filters" },
                    {
                      text: "Rename Filter",
                      disabled: field?.filter?.id ? false : true,
                    },
                    {
                      text: "Delete Filter",
                      disabled: field?.filter?.id ? false : true,
                    },

                    {
                      text: "Share Filter",
                      disabled: field?.filter?.id ? false : true,
                    },
                  ]}
                  width={200}
                  target={`#Filter`}
                  onItemClick={(e) => {
                    if (e.itemData.text === "Create New Filter") {
                      seteditname(false);
                      setshowCretaeNewFilterpopup(true);
                    } else if (e.itemData.text === "Save Change") {
                      clas.saveAndEditFilterReportWizard(
                        setLoading,
                        navigate,
                        {
                          gStoredFilterId: field?.filter?.id,
                          sName: field?.filter?.name,
                          dDoe: new Date().toISOString(),
                          gEntryUserId: localStorage.getItem("gUserId"),
                          gUserId: localStorage.getItem("gUserId"),
                          sData: localStorage.getItem("ReportWizardDisbursementTable"),
                          sGridViewName: "DisbursementSQ",
                          bDefault: true,
                        },
                        setshowCretaeNewFilterpopup,
                        getQueryFilterReportWizard,
                        setToastConfig,
                        toastConfig,
                        setfield,
                        field,
                        setfilterdata
                      );
                    } else if (e.itemData.text === "Rename Filter") {
                      seteditname(true);
                      setshowCretaeNewFilterpopup(true);
                    } else if (e.itemData.text === "Reset Filters") {
                      localStorage.removeItem("ReportWizardDisbursementTable");
                      settablereload(!tablereload);
                    } else if (e.itemData.text === "Share Filter") {
                      setItemTypeswitch(false);
                      setsharequeryshow(true);
                    } else if (e.itemData.text === "Delete Filter") {
                      clas.deleteFilterFilterReportWizard(
                        setLoading,
                        navigate,
                        field?.filter?.id,
                        getQueryFilterReportWizard
                      );
                    }
                  }}
                />
              </div>

              <div className="ChecklistTabbuttons">
                <button
                  className="btn"
                  onClick={() => {
                    setrefresh(!refresh);
                  }}
                >
                  Refresh
                  <svg
                    className="svg-blue-white"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_5796_5486)">
                      <path
                        d="M17.6493 6.35097C16.0193 4.72097 13.7093 3.78097 11.1693 4.04097C7.49929 4.41097 4.47929 7.39097 4.06929 11.061C3.51929 15.911 7.26929 20.001 11.9993 20.001C15.1893 20.001 17.9293 18.131 19.2093 15.441C19.5293 14.771 19.0493 14.001 18.3093 14.001C17.9393 14.001 17.5893 14.201 17.4293 14.531C16.2993 16.961 13.5893 18.501 10.6293 17.841C8.40929 17.351 6.61929 15.541 6.14929 13.321C5.30929 9.44097 8.25929 6.00097 11.9993 6.00097C13.6593 6.00097 15.1393 6.69097 16.2193 7.78097L14.7093 9.29097C14.0793 9.92097 14.5193 11.001 15.4093 11.001H18.9993C19.5493 11.001 19.9993 10.551 19.9993 10.001V6.41097C19.9993 5.52097 18.9193 5.07097 18.2893 5.70097L17.6493 6.35097Z"
                        fill="#424167"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5796_5486">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                <button
                  onClick={() => {
                    setexportPopupOpen(true);
                  }}
                  id="myBtn"
                  className="btn btnlightblue"
                >
                  Export
                  <svg
                    className="svg-blue-white"
                    width="14"
                    height="17"
                    viewBox="0 0 14 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                      fill="#424167"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div style={{ marginTop: "1%" }}>
              <Table2
                Tablecoloum={Tablecoloum}
                tableRow={data}
                editTable={false}
                load={false}
                coloumWidthText={"ReportWizardDisbursementTable"}
                height={620}
                mandatoryColoum={"Offender #"}
                showTotalBlock={true}
                DisbursementReportWizard={true}
                exportInDifferentFormate={true}
                exportPopupOpen={exportPopupOpen}
                setexportPopupOpen={setexportPopupOpen}
                exportwidth={25}
                exportFont={8}
                exporttitle={"Disbursement"}
                preview={true}
                prewviewtogal={prewviewtogal}
                Disbursementrightclick={true}
                settablereload={settablereload}
                tablereload={tablereload}
                coloumchosser={true}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
