export const setTableData = (data) => {
  const arr = [];
  data.map((val) => {
    const date = new Date(val.dDOB);
    const DOE = new Date(val.dDOE);
    arr.push({
      "First Name": val.sFirstName,
      "Last Name": val.sLastName,
      "Agency/Court": val.sAgencyName,
      Supervisor: val.sPrimarySupervisorName,
      "Full Name": val.sLastNameSpaceFirstName,
      DOB: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
      SSN: val?.sSSN ? `***-**-${val?.sSSN?.slice(5)}` : "",
      Compliance: val.sComplianceLevelName,
      "Payment Frequency": val.paymentFrequency,
      "Payment Amount": val.paymentAmount ? Number(val.paymentAmount) : null,
      "F/S": val.fS,
      "S/S": val.sS,
      "Person ID": val.gPersonID,
      ID: val?.gPersonID,
      gPersonID: val?.gPersonID,
      "Person Status": val?.personStatusName,
      "Credit Balance": val?.fCreditBalance,
      "Offender Number": val?.sOffenderNumber,
      "Primary Location": val?.primaryLocation,
      "EM Periods": val?.bEM,
      "Offender #": val?.sOffenderNumber,
      personlistfullName: val?.sLastName,
      "Entered": `${DOE.getMonth() + 1}/${DOE.getDate()}/${DOE.getFullYear()}`
    });
  });

  return arr;
};

const FindValueFromCustomFilter = (name, getCustomFilterValue) => {
  return getCustomFilterValue?.find((items) => items?.name === name);
};

export const PersonListBody = (getCustomFilterValue) => {
  return {
    sFirstName: FindValueFromCustomFilter("First Name", getCustomFilterValue)
      ?.value
      ? FindValueFromCustomFilter("First Name", getCustomFilterValue)?.value
      : "",
    sLastName: FindValueFromCustomFilter("Last Name", getCustomFilterValue)
      ?.value
      ? FindValueFromCustomFilter("Last Name", getCustomFilterValue)?.value
      : "",
    sAgencyName: FindValueFromCustomFilter("Agency/Court", getCustomFilterValue)
      ?.value
      ? FindValueFromCustomFilter("Agency/Court", getCustomFilterValue)?.value
      : "",
    sPrimarySupervisorName: FindValueFromCustomFilter(
      "Supervisor",
      getCustomFilterValue
    )?.value
      ? FindValueFromCustomFilter("Supervisor", getCustomFilterValue)?.value
      : "",
    sLastNameSpaceFirstName: FindValueFromCustomFilter(
      "Full Name",
      getCustomFilterValue
    )?.value
      ? FindValueFromCustomFilter("Full Name", getCustomFilterValue)?.value
      : "",
    dDOB: FindValueFromCustomFilter("DOB", getCustomFilterValue)?.value
      ? FindValueFromCustomFilter("DOB", getCustomFilterValue)?.value
      : "1900-01-01",
    sSSN: FindValueFromCustomFilter("SSN", getCustomFilterValue)?.value
      ? FindValueFromCustomFilter("SSN", getCustomFilterValue)?.value
      : "",
    fAmount: FindValueFromCustomFilter("Payment Amount", getCustomFilterValue)
      ?.value
      ? FindValueFromCustomFilter("Payment Amount", getCustomFilterValue)?.value
      : 0.0,
    fCreditBalance: FindValueFromCustomFilter(
      "Credit Balance",
      getCustomFilterValue
    )?.value
      ? FindValueFromCustomFilter("Credit Balance", getCustomFilterValue)?.value
      : 0.0,
    sComplianceLevelName: FindValueFromCustomFilter(
      "Compliance",
      getCustomFilterValue
    )?.value
      ? FindValueFromCustomFilter("Compliance", getCustomFilterValue)?.value
      : "",
    primaryLocation: FindValueFromCustomFilter(
      "Primary Location",
      getCustomFilterValue
    )?.value
      ? FindValueFromCustomFilter("Primary Location", getCustomFilterValue)
          ?.value
      : "",
    sS: FindValueFromCustomFilter("S/S", getCustomFilterValue)?.value
      ? FindValueFromCustomFilter("S/S", getCustomFilterValue)?.value
      : "",
    fS: FindValueFromCustomFilter("F/S", getCustomFilterValue)?.value
      ? FindValueFromCustomFilter("F/S", getCustomFilterValue)?.value
      : "",
    fullName: FindValueFromCustomFilter("Full Name", getCustomFilterValue)
      ?.value
      ? FindValueFromCustomFilter("Full Name", getCustomFilterValue)?.value
      : "",
    sSNOperator: FindValueFromCustomFilter("SSN", getCustomFilterValue)
      ?.operationValueText
      ? FindValueFromCustomFilter("SSN", getCustomFilterValue)
          ?.operationValueText
      : "",
    dOBOperator: FindValueFromCustomFilter("DOB", getCustomFilterValue)
      ?.operationValueText
      ? FindValueFromCustomFilter("DOB", getCustomFilterValue)
          ?.operationValueText
      : "",
    sOffenderNumber: "",
  };
};
