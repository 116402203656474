import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "../../../../../assets/images/icons/download.svg";
import { handleGetLocal } from "../../../../../services/auth-service";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { LoadPanel, Popup } from "devextreme-react";
import { WriteOffAmount } from "./ObligationPopup/WriteOffAmount";
import { WriteOffMultipleObligation } from "./ObligationPopup/WriteOffMultipleObligation";
import { logOut } from "../../../../../components/Logout";
import { ApiError } from "../../../../Error/ApiError";
import { ObligationTablecoloumn, setObligationTableData } from "./Data";
import "./financialtab.css";

export const ObligationManagement = ({ userinfo, reasonListData, financialStatus, toastConfig, setToastConfig }) => {
  const [data, setData] = useState([]);
  const [exportPopupOpen, setExportPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [writeOffAmount, setWriteOffAmount] = useState(false);
  const [writeOffMultipleObligation, setWriteOffMultipleObligation] = useState(false);
  const [obligationData, setObligationData] = useState([]);
  const [callObligationApi, setCallObligationApi] = useState(true);
  const [selectWriteOffData, setSelectWriteOffData] = useState([]);
  const [errorHandling, setErrorHandling] = useState(false);

  const pathname = handleGetLocal("pathURL");
  const navigate = useNavigate();

  const getTableData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/obligations/getObligations?gPersonId=${userinfo?.gPersonId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      );
      setLoading(false);
      if (response?.data?.isSuccess) {
        setErrorHandling(false);
        setData(setObligationTableData(response.data.data));
      } else {
        setErrorHandling(true);
      }
    } catch (error) {
      setLoading(false);
      if (!error.response) {
        navigate(`/error/500`);
      } else if (error.response.status === 401) {
        logOut();
      } else {
        setErrorHandling(true);
      }
    }
  };

  useEffect(() => {
    getTableData();
  }, [callObligationApi, userinfo?.gPersonId]);

  return (
    <>
      <LoadPanel shadingColor={true} visible={loading} delay={10} />
      <Popup
        visible={writeOffAmount}
        onHiding={() => setWriteOffAmount(false)}
        showTitle={false}
        width={768}
        height={572}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <WriteOffAmount
          writeOffAmount={writeOffAmount}
          setWriteOffAmount={setWriteOffAmount}
          obligationData={obligationData}
          financialStatus={financialStatus}
          reasonListData={reasonListData}
          setCallObligationApi={setCallObligationApi}
          callObligationApi={callObligationApi}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
        />
      </Popup>
      <Popup
        visible={writeOffMultipleObligation}
        onHiding={() => setWriteOffMultipleObligation(false)}
        showTitle={false}
        width={768}
        height={560}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <WriteOffMultipleObligation
          writeOffMultipleObligation={writeOffMultipleObligation}
          setWriteOffMultipleObligation={setWriteOffMultipleObligation}
          obligationData={obligationData}
          financialStatus={financialStatus}
          reasonListData={reasonListData}
          setCallObligationApi={setCallObligationApi}
          callObligationApi={callObligationApi}
          selectWriteOffData={selectWriteOffData}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
        />
      </Popup>
      <div className="obligation_tab">
        <button className="financial_overview_tab_button" onClick={() => setExportPopupOpen(true)}>
          Export <DownloadIcon className="svg-blue-white" />
        </button>
        {errorHandling ? (
          <ApiError />
        ) : (
          <div className="financial_tab_table">
            <Table1
              editTable={false}
              height={400}
              Tablecoloum={ObligationTablecoloumn}
              tableRow={data}
              load={loading}
              coloumWidthText={"ObligationTable"}
              mandatoryColoum={"Docket/CT"}
              exportInDifferentFormate={true}
              exportPopupOpen={exportPopupOpen}
              setexportPopupOpen={setExportPopupOpen}
              exportwidth={27}
              exporttitle={"Obligation"}
              showTotalBlock={true}
              selection={true}
              obilgationTable={true}
              obligationMenu={true}
              setWriteOffMultipleObligation={setWriteOffMultipleObligation}
              setWriteOffAmount={setWriteOffAmount}
              setObligationData={setObligationData}
              selectWriteOffData={selectWriteOffData}
              setSelectWriteOffData={setSelectWriteOffData}
            />
          </div>
        )}
      </div>
    </>
  );
};
