import axios from "axios";
import { handleGetLocal } from "../../../../../services/auth-service";
import { logOut } from "../../../../../components/Logout";

export class EditCheckistPopupFunction {
  constructor() {
    this.pathname = handleGetLocal("pathURL");
  }

  async addChicklist(
    setLoading,
    setToastConfig,
    toastConfig,
    navigate,
    body,
    setpopup,
    getDRopDown
  ) {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/checklist/add-checklist`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        getDRopDown();
        setpopup(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Successfully Added!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Successfully Added!",
            type: "success",
          });
        }, 1900);
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        }
      });
  }

  async addEditChecklist(
    setLoading,
    setToastConfig,
    toastConfig,
    navigate,
    body,
    setpopup,
    table
  ) {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/checklist/save-checklist-item`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        setpopup(false);
        table();
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Successfully Updated!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Successfully Updated!",
            type: "success",
          });
        }, 1900);
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        }
      });
  }

  Tablecoloum = [
    {
      title: "Sequence",
    },
    {
      title: "Allow Check",
      bool: true,
    },
    {
      title: "Activity",
    },
  ];

  async addeditcollection(
    setLoading,
    navigate,
    body,
    setCollectionpopup,
    setToastConfig,
    toastConfig
  ) {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/docket/add-edit-collection`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Successfully Added!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Successfully Added!",
            type: "success",
          });
        }, 1400);
        setCollectionpopup(false);
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        }
      });
  }

  async deletecollection(
    setLoading,
    navigate,
    body,
    setCollectionpopup,
    setToastConfig,
    toastConfig,
    setDeletepopupshow
  ) {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/docket/remove-collection`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.data) {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully deleted!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully deleted!",
              type: "success",
            });
          }, 1400);
          setCollectionpopup(false);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "No collection exists!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "No collection exists!",
              type: "error",
            });
          }, 1400);
          setDeletepopupshow(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        }
      });
  }

  async getcollection(
    setLoading,
    navigate,
    gPersonId,
    getTopDynamicDocketid,
    setdata,
    setCollectionpopup,
    setFields,
    fields
  ) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/docket/get-collection?gDocketId=${getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setdata(val?.data?.data[0] ? true : false);
        setCollectionpopup(val?.data?.data[0] ? true : false);

        setFields({
          ...fields,
          "Level/Tier": {
            name: val?.data?.data[0]?.tier ? val?.data?.data[0]?.tier : "",
            id: val?.data?.data[0]?.tier ? val?.data?.data[0]?.tier : "",
          },
          "Delinquent Date": val?.data?.data[0]?.dDelinquent
            ? val?.data?.data[0]?.dDelinquent
            : "",
          "Collection Status": {
            name: val?.data?.data[0]?.sStatus
              ? val?.data?.data[0]?.sStatus
              : "",
            id: val?.data?.data[0]?.sStatus ? val?.data?.data[0]?.sStatus : "",
          },
          "Service Date": val?.data?.data[0]?.dService
            ? val?.data?.data[0]?.dService
            : "",
          "Amount Due": val?.data?.data[0]?.fAmount
            ? val?.data?.data[0]?.fAmount
            : "",

          "Offender Address 1": val?.data?.data[0]?.sHomeAddress1,
          "Offender Address 2": val?.data?.data[0]?.sHomeAddress2,
          "Offender City": val?.data?.data[0]?.sHomeCity,
          "Offender State": val?.data?.data[0]?.sHomeState,
          "Offender Zip": val?.data?.data[0]?.sHomeZip,
          "Offender Home Phone": val?.data?.data[0]?.sHomePhone,
          "Offender Work Phone": val?.data?.data[0]?.sWorkPhone,
          "Offender Other Phone": val?.data?.data[0]?.sOtherPhone,
          "Guarantor Name": val?.data?.data[0]?.sGuarantorName,
          "Guarantor DOB": val?.data?.data[0]?.dGuarantorDob,
          "Guarantor SSN": val?.data?.data[0]?.sGuarantorSsn,
          "Guarantor City": val?.data?.data[0]?.sGuarantorCity,
          "Guarantor State": val?.data?.data[0]?.sGuarantorState,
          "Guarantor ZIP": val?.data?.data[0]?.sGuarantorZip,
          "Guarantor Home Phone": val?.data?.data[0]?.sGuarantorHomePhone,
          "Guarantor Work Phone": val?.data?.data[0]?.sGuarantorWorkPhone,
          "Guarantor Other Phone": val?.data?.data[0]?.sGuarantorOtherPhone,
          "Guarantor Address1": val?.data?.data[0]?.sGuarantorAddress1,
          "Guarantor Address2": val?.data?.data[0]?.sGuarantorAddress2,
        });
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        }
      });
  }
}
