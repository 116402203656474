const DocumentWizardParameters = ({ parametersFields, setParametersFields, htmlData }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setParametersFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="document-wizard-tabs-content">
        {htmlData &&
          Object.entries(parametersFields).map(([item, value], index) => {
            return (
              <>
                <div className="go-to-inputs-field" key={index}>
                  <label htmlFor="Currentdate" className="go-to-label-filed">
                    {item}
                  </label>
                  <input
                    type="text"
                    name={item}
                    id={item}
                    value={value}
                    className="fee-payment-content-info-input"
                    placeholder={`Enter ${item}`}
                    onChange={handleInputChange}
                  />
                </div>
              </>
            );
          })}
      </div>
    </>
  );
};

export default DocumentWizardParameters;
