import axios from "axios";
import { useEffect, useState } from "react";
import { handleGetLocal } from "../../../services/auth-service";

export const FormsConfirmation = ({
  confirmationPopup,
  setConfirmationPopup,
  confirmationData,
  formRowData,
  setLoading,
  toastConfig,
  setToastConfig,
  setAddExistingPersonPopup,
  setNoteConfirmationPopup,
}) => {
  const [checkBox, setCheckBox] = useState(false);
  const pathname = handleGetLocal("pathURL");

  useEffect(() => {
    setCheckBox(false);
  }, [confirmationPopup]);

  const data = {
    gFormID: formRowData?.gFormId,
    gPersonID: confirmationData?.gPersonId,
  };

  const handleSave = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Forms/attach-formtoperson`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setConfirmationPopup(false);
          setAddExistingPersonPopup(false);
          setNoteConfirmationPopup(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <>
      <div className="confirmation-forms">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <p>Confirmation</p>
            </div>
            <p class="popup_header_title_para">MF.2</p>
          </div>
          <svg
            onClick={() => {
              setConfirmationPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="confirmation-forms-container">
          <p className="edit_container_label">
            {confirmationData?.Name} {confirmationData?.["Offender #"]}
          </p>
          <p className="edit_container_label">
            This operation will overwrite the existing client's demographic
            information, such as Name, Address, Contact Numbers, etc. with
            information from the new form. Please acknowlege this action before
            proceeding.
          </p>
          <div className="input-flex">
            <input
              type="checkbox"
              name="checkBox"
              id="checkBox"
              checked={checkBox}
              onChange={() => {
                setCheckBox((prev) => !prev);
              }}
            />
            <label htmlFor="checkBox" className="fee-payment-content-info-para">
              I understand the impact of this action and acknowledge I choose to
              proceed.
            </label>
          </div>
          <div className="popup_buttons">
            {checkBox && (
              <button className="btn primary_btn" onClick={handleSave}>
                Save
              </button>
            )}
            <button
              className="btn"
              onClick={() => {
                setConfirmationPopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
