import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ReportWizardDynamicTab: [],
};

//slice

const slice = createSlice({
  name: "ParticipateReducer",
  initialState,
  reducers: {
    setReportWizardDynamicTab(state, action) {
      if (
        !state.ReportWizardDynamicTab.some(
          (obj) => obj?.Offender === action.payload.Offender
        )
      ) {
        state.ReportWizardDynamicTab = [
          ...state.ReportWizardDynamicTab,
          action.payload,
        ];
      }
    },
    remove_ReportWizardDynamicTab(state, action) {
      state.ReportWizardDynamicTab = state.ReportWizardDynamicTab.filter(
        (item) => item.Offender !== action.payload
      );
    },
  },
});

export const { setReportWizardDynamicTab, remove_ReportWizardDynamicTab } =
  slice.actions;

export default slice.reducer;
