import { Toast } from "devextreme-react";

import errorIcon from "../../assets/images/close.png";
import questionIcon from "../../assets/images/question.png";
import informationIcon from "../../assets/images/information.png";
import warningIcon from "../../assets/images/warning.png";
import successIcon from "../../assets/images/success.png";
import { useState } from "react";
import { useEffect } from "react";

export const Toster = ({ type, message, visible }) => {
  const [Visible, setvisible] = useState(visible);
  function getToastContent() {
    const Type = {
      message,
      success: {
        icons: successIcon,
        color: "white",
        backgroundColor: "#5cb85c",
      },
      error: {
        icons: errorIcon,
        color: "white",
        backgroundColor: "#d9534f",
      },
      information: {
        icons: informationIcon,
        color: "white",
        backgroundColor: "#337ab7",
      },
      warning: {
        icons: warningIcon,
        color: "black",
        backgroundColor: "#f0ad4e",
      },
      question: {
        icons: questionIcon,
        color: "black",
        backgroundColor: "#337ab7",
      },
    };

    return (
      <div
        style={{
          width: "auto",
          backgroundColor: Type[type]?.backgroundColor,
          borderRadius: "6px",
          margin: "0 auto",
          display: "flex",
          alignItems: "center",
          padding: "5px",
          gap: "5px",
        }}
      >
        <img
          style={{
            width: "30px",
            height: "30px",
          }}
          src={Type[type]?.icons}
          alt="toast"
        />
        <p
          style={{
            color: Type[type]?.color,
            fontWeight: "bold",
          }}
        >
          {message}
        </p>
      </div>
    );
  }
  
  return (
    <>
      <Toast visible={visible} duration={9000} position={"top"} type="custom" contentRender={getToastContent} />
    </>
  );
};
