import axios from "axios";
import { LoadPanel, ScrollView, Toast } from "devextreme-react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { handleGetLocal } from "../../../../services/auth-service";
import { seton_Save_Edit } from "../../../../reducer/participantNoteReducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ColumnDropdown } from "../../../../components/Dropdownn";
import { logOut } from "../../../../components/Logout";
import { useNavigate } from "react-router-dom";

const DropDown = ({ dropDownData, setFields, fields, fieldNmae, rest }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setFields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        reset={rest}
      />
    </>
  );
};

export const EnterAttendence = ({
  setenterAttendencePopup,
  userinfo,
  reportDataId,
  sentanceNote,
  PostReportNote,
  toastConfig,
  setToastConfig,
}) => {
  const componentRef = useRef(null);

  const [fields, setFields] = useState({
    Attendance: {
      name: "Reported",
      id: "e593a60f-3ff6-e011-98aa-00508d98a9ee",
    },
    ActivityNoteType: { name: "", id: "" },
    ArrearageReport: false,
    PreReportNote: "",
    SentenceNotes: sentanceNote,
    ActivityNoteTypeBottomText: "",
    BottomText: "",
    Tickets: false,
    Alcohol: false,
    Arrest: false,
    Firearms: false,
    Drugs: false,
    Weapons: false,
    Address: false,
    Phone: false,
    Employment: false,
  });
  const [AttendanceValidation, setAttendanceValidation] = useState("");
  const [ActivityNoteTypeValidation, setActivityNoteTypeValidation] = useState("");
  const [postnoteValidation, setpostnoteValidation] = useState("");

  const [addTest, setAddtest] = useState([]);
  const [addTestNoChangeof, setaddTestNoChangeof] = useState([]);
  const [activityNoteType, setactivityNoteType] = useState([]);
  const [attendance, setattendance] = useState([]);
  const [loading, setLoading] = useState(false);
  const { on_Save_Edit, resetReportDates } = useSelector((store) => store.participateNotes);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createAppointment = async (body) => {
    setLoading(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/PersonReportDates/enter-attendance`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        dispatch(seton_Save_Edit(!on_Save_Edit));
        ResetValue();
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Successfully Added!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Successfully Added!",
            type: "success",
          });
        }, 1400);
        setLoading(false);
        setenterAttendencePopup(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const onSave = () => {
    if (fields.ActivityNoteType && fields.Attendance && fields.ActivityNoteTypeBottomText) {
      setActivityNoteTypeValidation("");
      setAttendanceValidation("");
      setpostnoteValidation("");
      createAppointment({
        gReportDateScheduleId: reportDataId,
        attendanceTypeId: fields.Attendance.id,
        activityNoteTypeId: fields.ActivityNoteType.id,
        note:
          `${fields.ActivityNoteTypeBottomText}` +
          "," +
          addTest.slice(1).join(",") +
          "," +
          addTestNoChangeof.slice(1).join(","),
        arrearageRelated: fields.ArrearageReport,
      });
    } else {
      !fields.ActivityNoteType.id
        ? setActivityNoteTypeValidation("Please Select Activity Note Type")
        : setActivityNoteTypeValidation("");
      !fields.Attendance.id ? setAttendanceValidation("Please Select Attendance") : setAttendanceValidation("");
      !fields.ActivityNoteTypeBottomText ? setpostnoteValidation("Please Enter Note") : setpostnoteValidation("");
    }
  };

  const getDropDown = async () => {
    setLoading(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/PersonReportDates/masterDropdownValues`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setactivityNoteType(
          val.data.data.activityNoteType.map((vall) => {
            return { name: vall?.value, id: vall?.key };
          })
        );

        setattendance(
          val.data.data.attendance.map((vall) => {
            return { name: vall?.value, id: vall?.key };
          })
        );

        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const ResetValue = () => {
    setFields({
      Attendance: {
        name: "Reported",
        id: "e593a60f-3ff6-e011-98aa-00508d98a9ee",
      },
      ActivityNoteType: { name: "", id: "" },
      ArrearageReport: false,
      PreReportNote: "",
      SentenceNotes: sentanceNote ? sentanceNote : "",
      ActivityNoteTypeBottomText: "",
      BottomText: "",
      Tickets: false,
      Alcohol: false,
      Arrest: false,
      Firearms: false,
      Drugs: false,
      Weapons: false,
      Address: false,
      Phone: false,
      Employment: false,
    });
    setActivityNoteTypeValidation("");
    setAttendanceValidation("");
    setpostnoteValidation("");
    setAddtest([]);
    setaddTestNoChangeof([]);
  };

  useEffect(() => {
    ResetValue();
  }, [resetReportDates]);

  useEffect(() => {
    getDropDown();
  }, []);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />

      <ScrollView
        style={{
          width: "100%",
          height: "897px",
          border: "3px solid var(--button2)",
        }}
      >
        <div ref={componentRef} className="CreateAppointmentPopup">
          <div className="RecivePaymentModel_header">
            <div style={{ width: "200px" }} className="RecivePaymentModel_header_name">
              <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18 2H17V1C17 0.45 16.55 0 16 0C15.45 0 15 0.45 15 1V2H5V1C5 0.45 4.55 0 4 0C3.45 0 3 0.45 3 1V2H2C0.9 2 0 2.9 0 4V20C0 21.1 0.9 22 2 22H18C19.1 22 20 21.1 20 20V4C20 2.9 19.1 2 18 2ZM17 20H3C2.45 20 2 19.55 2 19V7H18V19C18 19.55 17.55 20 17 20Z"
                  fill="white"
                />
              </svg>
              <p className="go-to-label-filed">Enter Attendance</p>
              <span
                style={{
                  padding: "2px 9px",
                  gap: "8px",
                  width: "45px",
                  height: "24px",
                  background: "#FEF2F2",
                  borderRadius: "4px",
                }}
              >
                <p
                  style={{
                    color: "#DC2626",
                    fontFamily: "Readex Pro",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "10px",
                    lineHeight: "20px",
                    width: "21px",
                    height: "20px",
                  }}
                >
                  RD.2
                </p>
              </span>
            </div>
            <svg
              onClick={() => {
                setenterAttendencePopup(false);
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>

          <div className="CreateAppointment_lower_block">
            <div className="CreateAppointment_lower_block_left">
              <div className="drop_downn_block">
                <p className="go-to-label-filed">Attendance</p>
                <DropDown
                  setFields={setFields}
                  fields={fields}
                  fieldNmae={"Attendance"}
                  dropDownData={attendance}
                  rest={setAttendanceValidation}
                />
                <p
                  style={{
                    color: "red",
                    fontWeight: "400",
                    position: "absolute",
                  }}
                >
                  {AttendanceValidation}
                </p>
              </div>
              <div style={{ display: "flex", marginTop: "7%" }}>
                <input
                  checked={fields.ArrearageReport}
                  onClick={() => {
                    setFields({
                      ...fields,
                      ArrearageReport: !fields.ArrearageReport,
                    });
                  }}
                  type="checkbox"
                />
                <p className="go-to-label-filed">Arrearage Report</p>
              </div>
              <div style={{ marginTop: "6%" }} className="drop_downn_block">
                <p className="go-to-label-filed">Pre-Report Note</p>
                <textarea
                  value={PostReportNote ? PostReportNote : ""}
                  disabled={true}
                  placeholder="Write note"
                  style={{ height: "106px" }}
                />
              </div>
            </div>
            <div className="CreateAppointment_lower_block_right">
              <div className="drop_downn_block">
                <p className="go-to-label-filed">
                  Activity Note Type <span>*</span>
                </p>
                <DropDown
                  setFields={setFields}
                  fields={fields}
                  fieldNmae={"ActivityNoteType"}
                  dropDownData={activityNoteType}
                  rest={setActivityNoteTypeValidation}
                />
                <p
                  style={{
                    color: "red",
                    fontWeight: "400",
                    position: "absolute",
                  }}
                >
                  {ActivityNoteTypeValidation}
                </p>
              </div>
              <div style={{ marginTop: "9%" }} className="drop_downn_block">
                <textarea
                  value={fields.ActivityNoteTypeBottomText}
                  onChange={(e) => {
                    setpostnoteValidation("");
                    setFields({
                      ...fields,
                      ActivityNoteTypeBottomText: e.target.value,
                    });
                  }}
                  placeholder="Write note *"
                  style={{ height: "157px" }}
                />
                <p
                  style={{
                    color: "red",
                    fontWeight: "400",
                    position: "absolute",
                  }}
                >
                  {postnoteValidation}
                </p>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "4%" }} className="enterattendencesentencenode">
            <div className="drop_downn_block">
              <p className="go-to-label-filed">Sentence Notes</p>
              <textarea value={sentanceNote} disabled={true} placeholder="Write note" />
            </div>
          </div>
          <div style={{ marginTop: "2.5%" }} className="enterattendencesentencenode">
            <p className="go-to-label-filed">Denies</p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "-10px",
              }}
            >
              <div className="flex-attendence">
                <input
                  checked={fields.Tickets}
                  onClick={() => {
                    setFields({
                      ...fields,
                      Tickets: !fields.Tickets,
                    });
                    if (!fields.Tickets) {
                      if (addTest[0] === "Denies") {
                        const add = [...addTest, "Tickets"];
                        setAddtest(add);
                      } else {
                        const add = [...addTest, "Denies", "Tickets"];
                        setAddtest(add);
                      }
                    } else {
                      const add = [...addTest].filter((item) => item !== "Tickets");
                      setAddtest(add);
                    }
                  }}
                  type="checkbox"
                />
                <p className="go-to-label-filed">Tickets</p>
              </div>
              <div className="flex-attendence">
                <input
                  checked={fields.Alcohol}
                  onClick={() => {
                    setFields({
                      ...fields,
                      Alcohol: !fields.Alcohol,
                    });
                    if (!fields.Alcohol) {
                      if (addTest[0] === "Denies") {
                        const add = [...addTest, "Alcohol"];
                        setAddtest(add);
                      } else {
                        const add = [...addTest, "Denies", "Alcohol"];
                        setAddtest(add);
                      }
                    } else {
                      const add = [...addTest].filter((item) => item !== "Alcohol");
                      setAddtest(add);
                    }
                  }}
                  type="checkbox"
                />
                <p className="go-to-label-filed">Alcohol</p>
              </div>
              <div className="flex-attendence">
                <input
                  checked={fields.Arrest}
                  onClick={() => {
                    setFields({
                      ...fields,
                      Arrest: !fields.Arrest,
                    });
                    if (!fields.Arrest) {
                      if (addTest[0] === "Denies") {
                        const add = [...addTest, "Arrest"];
                        setAddtest(add);
                      } else {
                        const add = [...addTest, "Denies", "Arrest"];
                        setAddtest(add);
                      }
                    } else {
                      const add = [...addTest].filter((item) => item !== "Arrest");
                      setAddtest(add);
                    }
                  }}
                  type="checkbox"
                />
                <p className="go-to-label-filed">Arrest</p>
              </div>
              <div className="flex-attendence">
                <input
                  checked={fields.Firearms}
                  onClick={() => {
                    setFields({
                      ...fields,
                      Firearms: !fields.Firearms,
                    });
                    if (!fields.Firearms) {
                      if (addTest[0] === "Denies") {
                        const add = [...addTest, "Firearms"];
                        setAddtest(add);
                      } else {
                        const add = [...addTest, "Denies", "Firearms"];
                        setAddtest(add);
                      }
                    } else {
                      const add = [...addTest].filter((item) => item !== "Firearms");
                      setAddtest(add);
                    }
                  }}
                  type="checkbox"
                />
                <p className="go-to-label-filed">Firearms</p>
              </div>
              <div className="flex-attendence">
                <input
                  checked={fields.Drugs}
                  onClick={() => {
                    setFields({
                      ...fields,
                      Drugs: !fields.Drugs,
                    });
                    if (!fields.Drugs) {
                      if (addTest[0] === "Denies") {
                        const add = [...addTest, "Drugs"];
                        setAddtest(add);
                      } else {
                        const add = [...addTest, "Denies", "Drugs"];
                        setAddtest(add);
                      }
                    } else {
                      const add = [...addTest].filter((item) => item !== "Drugs");
                      setAddtest(add);
                    }
                  }}
                  type="checkbox"
                />
                <p className="go-to-label-filed">Drugs</p>
              </div>
              <div className="flex-attendence">
                <input
                  checked={fields.Weapons}
                  onClick={() => {
                    setFields({
                      ...fields,
                      Weapons: !fields.Weapons,
                    });
                    if (!fields.Weapons) {
                      if (addTest[0] === "Denies") {
                        const add = [...addTest, "Weapons"];
                        setAddtest(add);
                      } else {
                        const add = [...addTest, "Denies", "Weapons"];
                        setAddtest(add);
                      }
                    } else {
                      const add = [...addTest].filter((item) => item !== "Weapons");
                      setAddtest(add);
                    }
                  }}
                  type="checkbox"
                />
                <p className="go-to-label-filed">Weapons</p>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "2.5%" }} className="enterattendencesentencenode">
            <p className="go-to-label-filed">No Change of</p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "49%",
              }}
            >
              <div className="flex-attendence">
                <input
                  checked={fields.Address}
                  onClick={() => {
                    setFields({
                      ...fields,
                      Address: !fields.Address,
                    });
                    if (!fields.Address) {
                      if (addTestNoChangeof[0] === "No Change of") {
                        const add = [...addTestNoChangeof, "Address"];
                        setaddTestNoChangeof(add);
                      } else {
                        const add = [...addTestNoChangeof, "No Change of", "Address"];
                        setaddTestNoChangeof(add);
                      }
                    } else {
                      const add = [...addTestNoChangeof].filter((item) => item !== "Address");
                      setaddTestNoChangeof(add);
                    }
                  }}
                  type="checkbox"
                />
                <p className="go-to-label-filed">Address</p>
              </div>
              <div className="flex-attendence">
                <input
                  checked={fields.Phone}
                  onClick={() => {
                    setFields({
                      ...fields,
                      Phone: !fields.Phone,
                    });
                    if (!fields.Phone) {
                      if (addTestNoChangeof[0] === "No Change of") {
                        const add = [...addTestNoChangeof, "Phone"];
                        setaddTestNoChangeof(add);
                      } else {
                        const add = [...addTestNoChangeof, "No Change of", "Phone"];
                        setaddTestNoChangeof(add);
                      }
                    } else {
                      const add = [...addTestNoChangeof].filter((item) => item !== "Phone");
                      setaddTestNoChangeof(add);
                    }
                  }}
                  type="checkbox"
                />
                <p className="go-to-label-filed">Phone</p>
              </div>
              <div className="flex-attendence">
                <input
                  checked={fields.Employment}
                  onClick={() => {
                    setFields({
                      ...fields,
                      Employment: !fields.Employment,
                    });
                    if (!fields.Employment) {
                      if (addTestNoChangeof[0] === "No Change of") {
                        const add = [...addTestNoChangeof, "Employment"];
                        setaddTestNoChangeof(add);
                      } else {
                        const add = [...addTestNoChangeof, "No Change of", "Employment"];
                        setaddTestNoChangeof(add);
                      }
                    } else {
                      const add = [...addTestNoChangeof].filter((item) => item !== "Employment");
                      setaddTestNoChangeof(add);
                    }
                  }}
                  type="checkbox"
                />
                <p className="go-to-label-filed">Employment</p>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "4%" }} className="enterattendencesentencenode">
            <div className="drop_downn_block">
              <textarea
                value={
                  (addTest.length > 1 ? "Denies " + addTest.slice(1).join(", ") + "." : "") +
                  (addTestNoChangeof.length > 1 ? " No Change of " + addTestNoChangeof.slice(1).join(", ") + "." : "")
                }
                disabled={true}
                placeholder="Write note"
              />
            </div>
          </div>
          <div className="popup_buttons" style={{ margin: "20px 20px 10px 0px" }}>
            <button
              onClick={() => {
                onSave();
              }}
              className="btn primary_btn"
            >
              Save
            </button>
            <button
              onClick={() => {
                ResetValue();
                setenterAttendencePopup(false);
              }}
              className="btn"
            >
              Cancel
            </button>
          </div>
        </div>
      </ScrollView>
    </>
  );
};
