import { logOut } from "../../../../../components/Logout";
import { handleGetLocal } from "../../../../../services/auth-service";
import axios from "axios";

export class EMFunction {
  constructor() {
    this.pathname = handleGetLocal("pathURL");
  }

  async getDropdownApi(setLoading, navigate, setEndReasondropdown, text) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=${text}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        val?.data?.data?.length &&
          setEndReasondropdown(
            val?.data?.data?.map((val) => {
              return {
                name: val?.value,
                id: val?.id,
              };
            })
          );
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }

  async getmasterdropdown(setLoading, navigate, setEMTypedropdown) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/em/dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        val?.data?.data?.emType?.length &&
          setEMTypedropdown(
            val?.data?.data?.emType?.map((val) => {
              return {
                name: val?.value,
                id: val?.key,
              };
            })
          );
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }

  async saveEMPeriod(
    setLoading,
    navigate,
    body,
    setToastConfig,
    toastConfig,
    getEmDataApi,
    setEMPriodpopshow,
    setConformationPopupshow
  ) {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/em/add-edit-emperiod`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Successfully Added!",
          type: "success",
        });

        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Successfully Added!",
            type: "success",
          });
        }, 1400);

        setEMPriodpopshow(false);
        setConformationPopupshow(false);
        getEmDataApi();
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }

  async createPeriods(
    setLoading,
    navigate,
    body,
    setemperiodsucces,
    setConformationPopupshow,
    setQuickAddTaskTab,
    rowleveldata
  ) {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/em/emperiod-create`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setemperiodsucces(true);
        setConformationPopupshow(false);
        if (rowleveldata?.gEmPeriodId) {
          setQuickAddTaskTab(2);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }
}
