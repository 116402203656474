import React, { useEffect, useRef, useState } from 'react';
import { create, createOptions, ViewType, RichEditUnit, DocumentFormat, RichEdit } from 'devexpress-richedit';

const RichEditComponent = ({ documentAsBase64,
    editToggle,
    reportname,
    setdocumentAsBase64,
    SaveData,
    clone,
    updatedocumentAsBase64,

}) => {
    const richRef = useRef(null);
    const containerRef = useRef(null);
    const [field, setField] = useState("");

    // console.log('SaveData',SaveData);
    useEffect(() => {
        if (richRef.current) return;

        const options = createOptions();
        options.bookmarks.visibility = true;
        options.bookmarks.color = '#ff0000';
        options.confirmOnLosingChanges.enabled = false;
        // options.confirmOnLosingChanges.message = 'Are you sure you want to perform the action? All unsaved document data will be lost.';
        options.fields.updateFieldsBeforePrint = true;
        options.fields.updateFieldsOnPaste = true;
        options.mailMerge.activeRecord = 2;
        options.mailMerge.viewMergedData = true;
        options.mailMerge.dataSource = [
            { Name: 'Indy', age: 32 },
            { Name: 'Andy', age: 28 },
        ];
        options.events.documentChanged = () => {
            if (richRef.current) {
                const content = richRef.current.saveDocument(DocumentFormat.Rtf);
                if (content && typeof content.then === 'function') {
                    content.then((result) => {
                        console.log('Document content after change:', result);
                        setField(result);
                    }).catch((error) => {
                        console.log('Error saving document:', error);
                    });
                }
            }
        };

        // Events setup
        options.events.lostFocus = (s, e) => {
            console.log("Focus lost");
            richRef.current.saveDocument();
        };

        options.events.saving = (s, e) => {
            console.log("From event.saving: ", e.base64);
            setField(e.base64);            
        };

        options.events.saved = (s, e) => {
            console.log("From event.saved: ", e.base64);

        }
        // options.events.headers = (s, e) => {
        //     console.log("From event.headers: ", e.base64);
        // }

        options.unit = RichEditUnit.Inch;
        options.view.viewType = ViewType.PrintLayout;
        options.view.simpleViewSettings.paddings = { left: 15, top: 15, right: 15, bottom: 15 };
        options.exportUrl = 'https://siteurl.com/api/';
        options.readOnly = false;
        options.width = '100%';
        options.height = '900px';

        richRef.current = create(containerRef.current, options);
        richRef.current.openDocument(documentAsBase64, reportname, DocumentFormat.Rtf);

        return () => {
            if (richRef.current) {
                richRef.current.dispose();
                richRef.current = null;
            }
        };


    }, []);

    //     return () => {
    //         if (richRef.current) {
    //             richRef.current.dispose();
    //             richRef.current = null;
    //         }
    //     };
    // }, [editToggle]);

    useEffect(() => {
        if (richRef.current) {
            richRef.current.openDocument(documentAsBase64, reportname, DocumentFormat.Rtf);
        }
    }, [documentAsBase64]);

    useEffect(() => {
        updatedocumentAsBase64(documentAsBase64);
    }, [clone, editToggle]);

    useEffect(() => {
        if (field !== documentAsBase64) {
            setdocumentAsBase64(field);
        }
    }, [field]);

    console.log('field', field)
    console.log('documentAsBase64', documentAsBase64)
    return (
        <div>
            <div id="richEdit" ref={containerRef}></div>
        </div>
    );
};

export default RichEditComponent;


