import React, { useState, createContext, useContext, useCallback } from "react";
import { SignIn as sendSignInRequest } from "../pages";

function AuthProvider(props) {
  const [setUser] = useState();
  let loading = true;

  const SignIn = useCallback(async (email, password) => {
    const result = sendSignInRequest(email, password);
    if (result.isOk) {
      setUser(result.data);
    }

    return result;
  }, []);

  const signOut = useCallback(() => {
    setUser(undefined);
  }, []);

  return (
    <AuthContext.Provider value={{ SignIn, signOut, loading }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
