import { ContextMenu, DataGrid, LoadPanel } from "devextreme-react";
import { Column, HeaderFilter } from "devextreme-react/data-grid";
import { Toster } from "../../../components/Toster/Toster";
import { ApiError } from "../../Error/ApiError";
import { useEffect, useState } from "react";
import { ProntactEntityFunction } from "./ProntactEntityFunction";
import { useNavigate } from "react-router-dom";
import ThreeDotMenu from "../../../components/Common/Icons/ThreeDotMenu";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setDynamicTab } from "../../../reducer/HomePageReducer";
import {DynamicTabb} from '../../../components/DynamicTab/DynamicTab';
import { handleGetLocal } from "../../../services/auth-service";

export const ProntactEntitiesList = () => {
  const apiClass = new ProntactEntityFunction();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [erorhandling, seterorhandling] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const [entityList, setEntityList] = useState([]);

  const [selectedEntity, setSelectedEntity] = useState({});

  const saveCookieCrum = async (perdonId) => {
    const pathname = handleGetLocal("pathURL");
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/cookie-crumbs/save-cookie-crumb?recordId=${perdonId}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then(() => {})
      .catch((val) => {});
  };

  useEffect(() => {
    apiClass.getEntityList(setLoading, seterorhandling, navigate, setEntityList);
  }, []);

  return (
    <>
      <div className="particcipentPage">
        <LoadPanel shadingColor="true" visible={loading} delay={10} />
        <DynamicTabb />
        <div style={{ display: "flex" }}>
          <p class="popup_header_title_para2"></p>
        </div>
        {erorhandling ? (
          <ApiError />
        ) : (
          <div>
            <LoadPanel shadingColor="true" visible={false} delay={10} />
            <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
            <div className="go-to-header-section">
              <div style={{}} className="entity-list">
                <DataGrid
                  dataSource={entityList}
                  columnAutoWidth
                  showBorders={true}
                  loadPanel={false}
                  allowColumnResizing={true}
                  showColumnLines={true}
                  showRowLines={true}
                  allowColumnReordering={true}
                  onContextMenuPreparing={(e) => {
                    setSelectedEntity(e.row?.data);
                  }}
                >
                  <HeaderFilter visible={true} allowSearch={true} />
                  <Column
                    width={50}
                    type="buttons"
                    cellRender={(rowData) => {
                      return (
                        <div
                          className="add_navBar_hamburger"
                          id="threedots"
                          onClick={() => {
                            setSelectedEntity(rowData.data);
                          }}
                        >
                          <ThreeDotMenu />
                          <ContextMenu
                            showEvent="dxclick"
                            target="#threedots"
                            dataSource={[
                              {
                                text: "Jump to Entity",
                                icon: "search",
                              },
                            ]}
                            onItemClick={(e) => {
                              if (e.itemData.text === "Jump to Entity") {
                                console.log('selectedEntity', selectedEntity);
                                saveCookieCrum(selectedEntity?.gEntityId);
                                dispatch(
                                  setDynamicTab({
                                    title: "Entity",
                                    link: `/prontact/entity/${selectedEntity?.gEntityId}`,
                                    Offender: "/prontact/entity",
                                  })
                                );
                                navigate(`/prontact/entity/${selectedEntity?.gEntityId}`);
                              }
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                  <Column dataField="sName" caption="Name" />
                  <Column dataField="sCompany" caption="Company" />
                  <Column dataField="bIsBilling" caption="Is Billing Agency" type="boolean" />
                  <Column dataField="sEntityTypeName" caption="EntityType" />
                  <Column dataField="sEmail" caption="Email" />
                  <Column dataField="sRegionName" caption="Region" />
                  <Column dataField="sCityName" caption="City" />
                  <Column dataField="sZip" caption="Zip" />
                  <Column dataField="sZip3" caption="3-Zip" />
                  <Column dataField="sCountyName" caption="County" />
                  <Column dataField="sReportingEntityName" caption="Reporting Entity" />
                </DataGrid>
                <ContextMenu
                  dataSource={[
                    {
                      text: "Jump to Entity",
                      icon: "search",
                    },
                  ]}
                  target=".dx-data-row"
                  onItemClick={(e) => {
                    if (e.itemData.text === "Jump to Entity") {
                      console.log('selectedEntity', selectedEntity);
                      saveCookieCrum(selectedEntity?.gEntityId);
                      dispatch(
                        setDynamicTab({
                          title: "Entity",
                          link:`/prontact/entity/${selectedEntity?.gEntityId}`,
                          Offender: "/prontact/entity",
                        })
                      );
                      navigate(`/prontact/entity/${selectedEntity?.gEntityId}`);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
