import { useState } from "react";
import "../../contacts.css";
import DetailsAddress from "./DetailsAddress";
import DetailsName from "./DetailsName";
import DetailsContactInfo from "./DetailsContactInfo";

const Details = ({
  isEditableButton,
  toastConfig,
  setToastConfig,
  contactData,
  fields,
  setFields,
  lastNamevalidation,
  setLastNamevalidation,
  firstNamevalidation,
  setFirstNamevalidation,
  titleDropdown,
  contactInfoType,
  data,
  setdata,
  contactInfoTypeCount,
  setContactInfoTypeCount,
  typeFeeSchedule,
  allData,
  setAllData,
}) => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="financial_tab">
        <div className="financial_tab_nav">
          <button
            className={
              activeTab === "tab1"
                ? "financial_tab_active financial_tab_nav_button"
                : "financial_tab_nav_button"
            }
            onClick={() => handleTabClick("tab1")}
          >
            Address
          </button>
          <button
            className={
              activeTab === "tab2"
                ? "financial_tab_active financial_tab_nav_button"
                : "financial_tab_nav_button"
            }
            onClick={() => handleTabClick("tab2")}
          >
            Name
          </button>
          <button
            className={
              activeTab === "tab3"
                ? "financial_tab_active financial_tab_nav_button"
                : "financial_tab_nav_button"
            }
            onClick={() => {
              handleTabClick("tab3");
            }}
          >
            Contact Info ({contactInfoTypeCount})
          </button>
        </div>
        <div className="financial_tab_data">
          {activeTab === "tab1" && (
            <DetailsAddress
              isEditableButton={isEditableButton}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              contactData={contactData}
              fields={fields}
              setFields={setFields}
            />
          )}
          {activeTab === "tab2" && (
            <DetailsName
              isEditableButton={isEditableButton}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              contactData={contactData}
              fields={fields}
              setFields={setFields}
              lastNamevalidation={lastNamevalidation}
              setLastNamevalidation={setLastNamevalidation}
              firstNamevalidation={firstNamevalidation}
              setFirstNamevalidation={setFirstNamevalidation}
              titleDropdown={titleDropdown}
            />
          )}
          {activeTab === "tab3" && (
            <DetailsContactInfo
              isEditableButton={isEditableButton}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              contactData={contactData}
              contactInfoType={contactInfoType}
              data={data}
              setdata={setdata}
              contactInfoTypeCount={contactInfoTypeCount}
              setContactInfoTypeCount={setContactInfoTypeCount}
              typeFeeSchedule={typeFeeSchedule}
              allData={allData}
              setAllData={setAllData}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Details;
