import { logOut } from "../../components/Logout";
import { handleGetLocal } from "../../services/auth-service";
import axios from "axios";

const pathname = handleGetLocal("pathURL");

export const deleteChecklistItemApi = async (id) => {
    try {
        let body = {
            "gCheckListItemID": id,
            "comments": "test"
          }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/checklist/delete-checklist-item`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        console.log('something went wrong');
      } else if (error.response.status === 401) {
        logOut();
      }
      throw error;
    }
  };