import React from "react";
import "../style/adminCrumbs.css";
const leftSVg = (
  <svg
    className="svg-black-white"
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
  >
    <path
      d="M0.59375 10.58L5.17375 6L0.59375 1.41L2.00375 0L8.00375 6L2.00375 12L0.59375 10.58Z"
      fill="black"
      fillOpacity="0.5"
    />
  </svg>
);

const AdminCrumbs = ({ title, user }) => {
  return (
    <>
      <div className="admin_crumbs">
        <p>Administration</p>
        {leftSVg}
        <p>{user} Maintenance</p>
        {leftSVg}
        <p className="active_crumbs">{title}</p>
      </div>
    </>
  );
};

export default AdminCrumbs;
