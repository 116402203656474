import { useState, useEffect } from "react";
import axios from "axios";
import { GoTOObligationHeader } from "./GoTOObligationHeader";
import { DynamicTabb } from "../../../../components/DynamicTab/DynamicTab";
import { useNavigate } from "react-router-dom";

export const G0ToObligation = () => {
  const [data, setData] = useState(null);
  const [feeViolationDropdown, setFeeViolationDropdown] = useState([]);
  const [feeViolationData, setFeeViolationData] = useState([])

  const navigate = useNavigate();


  const getFeeViolationDropdown = async (navigate, setFeeViolationDropdown) => {
    console.log("hh")
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=Fee Schedule Fee`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: window.location.pathname,
          },
        }
      );
      console.log("API Response:", response.data.data)
      const dropdownData = response?.data?.data.map((item) => ({
        name: item?.value,
        id: item?.id,
      }));

      setFeeViolationData(dropdownData);
   
    } catch (error) {
      if (!error?.response) {
        // navigate(`/error/500`);
      } else if (error?.response?.status === 401) {
        // Implement your logOut function or redirect logic here
      }
    }
  };
  useEffect(() => {
    getFeeViolationDropdown();
   
  }, []);
  

  return (
    <div className="particcipentPage">
      <DynamicTabb />
      <GoTOObligationHeader
        data={data}
        feeViolationDropdown={feeViolationData}
        setFeeViolationDropdown={setFeeViolationDropdown}
      
      />
    </div>
  );
};
