import { useState } from "react";

export const DetailData = ({ data, count }) => {
  const [state, setState] = useState(true);

  const handleClick = () => {
    navigator.clipboard.writeText(data[0]?.text?.split("(")[0]);
    setState(false);
    setTimeout(() => {
      setState(true);
    }, 5000);
  };
  return (
    <div className="participate_block1_sub1_div_blo">

      {data.map((val, id) => {
        return (
          <p
            key={id}
            style={{
              color: id == count ? "#DC2626" : "var(--input-color-black-white)",
              fontWeight: "600",
              marginTop: "10px",
              textDecoration: val?.underLine && "underLine",
            }}
          >
            {val?.text !== null ? val?.text : "-"}{" "}
            {val?.copyIcon &&
              (state ? (
                <svg
                  style={{ marginLeft: "5px" }}
                  width="13"
                  height="16"
                  viewBox="0 0 13 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <path
                    d="M9.0026 0.666504H1.66927C0.935938 0.666504 0.335938 1.2665 0.335938 1.99984V10.6665C0.335938 11.0332 0.635938 11.3332 1.0026 11.3332C1.36927 11.3332 1.66927 11.0332 1.66927 10.6665V2.6665C1.66927 2.29984 1.96927 1.99984 2.33594 1.99984H9.0026C9.36927 1.99984 9.66927 1.69984 9.66927 1.33317C9.66927 0.966504 9.36927 0.666504 9.0026 0.666504ZM11.6693 3.33317H4.33594C3.6026 3.33317 3.0026 3.93317 3.0026 4.6665V13.9998C3.0026 14.7332 3.6026 15.3332 4.33594 15.3332H11.6693C12.4026 15.3332 13.0026 14.7332 13.0026 13.9998V4.6665C13.0026 3.93317 12.4026 3.33317 11.6693 3.33317ZM11.0026 13.9998H5.0026C4.63594 13.9998 4.33594 13.6998 4.33594 13.3332V5.33317C4.33594 4.9665 4.63594 4.6665 5.0026 4.6665H11.0026C11.3693 4.6665 11.6693 4.9665 11.6693 5.33317V13.3332C11.6693 13.6998 11.3693 13.9998 11.0026 13.9998Z"
                    fill="var(--input-color-black-white)"
                  />
                </svg>
              ) : (
                <svg
                  width="14"
                  height="16"
                  style={{ marginLeft: "5px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                    fill="#26d12a"
                  ></path>
                </svg>
              ))}
          </p>
        );
      })}
    </div>
  );
};

export const DetailData1 = ({ data1, data2 }) => {
  return (
    <>
      <div className="participate_block1_sub1_div_blo1">
        {data1.map((val, i) => {
          return (
            <div key={i}>
              <p style={{ width: "145px" }}>{val}</p>
              <p
                style={{
                  color: data2[i]?.perticularcolor
                    ? `${data2[i].text ? data2[i].text.toLowerCase() : ""}`
                    : data2[i]?.color
                      ? "red"
                      : "var(--input-color-black-white)",
                  fontWeight: "600",
                  marginTop: "10px",
                  textDecoration: data2[i].underLine && "underLine",
                  width: "250px",
                }}
              >
                {data2[i].text !== null ? data2[i].text : "-"}
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export const DetailData2 = ({ data1, data2 }) => {
  return (
    <>
      <div className="participate_block1_sub1_div_blo2">
        {data1.map((val, i) => {
          return (
            <div key={i}>
              <p style={{ width: "110px" }}>{val}</p>
              <p
                style={{
                  color: data2[i]?.color ? "red" : "var(--input-color-black-white)",
                  fontWeight: "600",
                  marginTop: "10px",
                  textDecoration: data2[i].underLine && "underLine",
                  marginLeft: "15px",
                  width: "80px",
                }}
              >
                {data2[i].text !== null
                  ? Number(data2[i].text).toFixed(2) >= 0
                    ? `$${Number(data2[i].text).toFixed(2)}`
                    : `($${Math.abs(data2[i].text).toFixed(2)})`
                  : "-"}
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export const DetailData3 = ({ data1, data2 }) => {
  return (
    <>
      <div style={{ marginTop: "10px" }} className="participate_block1_sub1_div_blo3">
        <p style={{ color: "var(--input-color-black-white)" }}>Derived Element</p>
        {data1.map((val, i) => {
          return (
            <div key={i}>
              <p>{val}</p>
              <p
                style={{
                  color: "var(--input-color-black-white)",
                  fontWeight: "600",
                  marginTop: "10px",
                  textDecoration: data2[i].underLine && "underLine",
                }}
              >
                {data2[i].text !== null ? data2[i].text : "-"}
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
};
