export const Tablecoloum = [
  {
    title: "Name",
    underLine: true,
  },
  { title: "Supervisor" },
  { title: "DOB",date:true },
  {
    title: "SSN",number:true
  },
  {
    title: "Offender #",
  },
  {
    title: "Offender # PIN",
  },
  {
    title: "Person Status",
  },
  {
    title: "Primary Location",
  },
  {
    title: "Balance",currency:true
  },
  {
    title: "Co-Obligor",
  },
  {
    title: "Phone",
  },
  {
    title: "Type",
  },
  {
    title: "Home Address",
  }
];
