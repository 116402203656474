import React, { useEffect, useState } from "react";
import { DateBox, SelectBox } from "devextreme-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { handleGetLocal } from "../../../../services/auth-service";
import { GoTOObligationFunction } from "../G0ToObligation/GoTOObligationFunction";

const ChangeFinancialStatus = ({ showModal, setShowModal, dockets, setToastConfig, setLoading }) => {
  const [financialStatusErrorValidation, setFinancialStatusErrorValidation] = useState("");
  const [changeReasonErrorValidation, setChangeReasonErrorValidation] = useState("");
  const [detailedCommentErrorValidation, setDetailedCommentErrorValidation] = useState("");
  const navigate = useNavigate();
  const [financialStatuses, setFinancialStatuses] = useState([]);
  const [financialStatusChangeReasons, setFinancialStatusChangeReasons] = useState([]);
  const [status, setStatus] = useState(null);
  const [reason, setReason] = useState(null);
  const [date, setDate] = useState(null);
  const [comment, setComment] = useState("");
  const apiClass = new GoTOObligationFunction();

  useEffect(() => {
    const pathname = handleGetLocal("pathURL");
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/docket-dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((res) => {
        const { financialStatusChangeReason, financialStatuses } = res.data.data;
        financialStatusChangeReason && setFinancialStatusChangeReasons(financialStatusChangeReason);
        financialStatuses && setFinancialStatuses(financialStatuses);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setStatus(null);
    setReason(null);
    setDate(null);
    setComment("");
  }, [showModal]);

  const validation = () => {
    const isValid = true;
    if (!status) {
      setFinancialStatusErrorValidation("Select Financial Status");
      isValid = false;
    }
    if (!reason) {
      setChangeReasonErrorValidation("Select Financial Status Change Reason");
      isValid = false;
    }
    if (!date) {
      //   setChangeReasonErrorValidation("Select Effective Date");
      isValid = false;
    }
    if (comment === "") {
      isValid = false;
      setDetailedCommentErrorValidation("Input Comment");
    }
    return isValid;
  };

  const onDone = () => {
    if (validation()) {
      //Change Financial Status for Dockets Api Call
      const body = {
        DocketIds: dockets.map((docket) => docket.gDocketId),
        StatusId: status,
        StatusChangeReasonId: reason,
        EffectiveDate: date,
        Comment: comment,
      };
      // console.log(body);
      apiClass.changeDocketFinancialStatus(navigate, setLoading, setToastConfig, {}, body, setShowModal);
    }
  };

  return (
    <>
      <div className="goto_obligation_change_financialStatus">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_974_363096)">
                  <path d="M0 0H24V24H0V0Z" fill="var(--button2)" />
                  <path
                    d="M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_974_363096">
                    <rect width="24" height="24" fill="rgb(67, 66, 102)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Change Financial Status</p>
            </div>
            <p class="popup_header_title_para">OB.12</p>
          </div>
          <svg
            onClick={() => {
              setShowModal(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="popup-container-data">
          <div className="edit_container_first">
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">
                Financial Status <span className="marks_mandatary">*</span>
              </p>
              <SelectBox
                dataSource={financialStatuses}
                displayExpr="value"
                valueExpr="key"
                value={status}
                onValueChange={(value) => {
                  setStatus(value);
                  setFinancialStatusErrorValidation(null);
                }}
              />
              {financialStatusErrorValidation && (
                <p className="error_validation" style={{ position: "absolute", top: "140px" }}>
                  {financialStatusErrorValidation}
                </p>
              )}
            </div>
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">Effective Date</p>
              <DateBox
                useMaskBehavior={true}
                onValueChange={(e) => {
                  if (e) {
                    const initialDateString = e;
                    const initialDate = new Date(initialDateString);
                    const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                    const utcFormatted = new Date(utcTime).toISOString();
                    setDate(utcFormatted);
                  }
                }}
                value={date}
              />
            </div>
          </div>
          <div className="edit_container_first">
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">
                Change Reason <span className="marks_mandatary">*</span>
              </p>
              <SelectBox
                dataSource={financialStatusChangeReasons}
                displayExpr="value"
                valueExpr="key"
                value={reason}
                onValueChange={(value) => {
                  setReason(value);
                  setChangeReasonErrorValidation(null);
                }}
              />
              {changeReasonErrorValidation && (
                <p className="error_validation" style={{ position: "absolute", top: "233px" }}>
                  {changeReasonErrorValidation}
                </p>
              )}
            </div>
          </div>
          <div className="edit_container_second edit_content_flex">
            <p className="edit_container_label">
              Please add a detailed comment
              <span className="marks_mandatary">*</span>
            </p>
            <textarea
              name="detailedComment"
              id="detailedComment"
              placeholder="Add your comment here"
              className="dark-mode-textarea"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
                setDetailedCommentErrorValidation(null);
              }}
            ></textarea>
            {detailedCommentErrorValidation && (
              <p className="error_validation" style={{ position: "absolute", top: "415px" }}>
                {detailedCommentErrorValidation}
              </p>
            )}
          </div>
          <div className="popup_buttons">
            <button className="btn primary_btn" onClick={onDone}>
              Done
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeFinancialStatus;
