import { useEffect, useState } from "react";
import { DrugTestSchedule } from "./SubTabs/DrugTestSchedule";
import { CreatesTestDates } from "./SubTabs/CreatesTestDates";
import { Toster } from "../../../../components/Toster/Toster";
import { LoadPanel, Popup } from "devextreme-react";
import "./drugTestRandomizer.css";
import axios from "axios";
import { handleGetLocal } from "../../../../services/auth-service";
import { Navigate } from "react-router-dom";
import { logOut } from "../../../../components/Logout";
import { RandomizerSummary } from "./popup/RandomizerSummary";
import { Calendar } from "./popup/Calendar";
import { VoidTestDates } from "./SubTabs/Popups/VoidTestDates";

const RandomizerSummaryPopUp = ({
  RandomizerSummaryPopUpshow,
  setRandomizerSummaryPopUpshow,
  setDrugTestScheduleMenu,
}) => {
  return (
    <>
      <Popup
        visible={RandomizerSummaryPopUpshow}
        onHiding={() => {
          setRandomizerSummaryPopUpshow(false);
          setDrugTestScheduleMenu(true);
        }}
        showTitle={false}
        width={730}
        height={520}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <RandomizerSummary
          RandomizerSummaryPopUpshow={RandomizerSummaryPopUpshow}
          setRandomizerSummaryPopUpshow={setRandomizerSummaryPopUpshow}
        />
      </Popup>
    </>
  );
};

export const DrugTestRandomizer = ({ userinfo }) => {
  const [activeTab, setActiveTab] = useState(1);
  const tabTitles = ["Drug Test Schedule", "Creates Test Dates"];
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [locationDropdownValues, setLocationDropdownValues] = useState([]);
  const pathname = handleGetLocal("pathURL");
  const [drugTestScheduleData, setDrugTestScheduleData] = useState([]);
  const [fields, setFields] = useState({
    location: { name: "", id: "" },
  });
  const [date, setdate] = useState({
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10),
  });
  const [drugTestScheduleMenu, setDrugTestScheduleMenu] = useState(true);
  const [RandomizerSummaryPopUpshow, setRandomizerSummaryPopUpshow] = useState(false);
  const [calendarPopup, setCalendarPopup] = useState(false);
  const [voidTestDatePopup, setVoidTestDatePopup] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const getLocationDropdownValues = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/RandomDrugTesting/dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLocationDropdownValues(val?.data?.data);
      })
      .catch((val) => {
        if (!val?.response) {
          Navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    getLocationDropdownValues();
  }, []);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <RandomizerSummaryPopUp
        RandomizerSummaryPopUpshow={RandomizerSummaryPopUpshow}
        setRandomizerSummaryPopUpshow={setRandomizerSummaryPopUpshow}
        setDrugTestScheduleMenu={setDrugTestScheduleMenu}
      />
      <Popup
        visible={calendarPopup}
        onHiding={() => {
          setCalendarPopup(false);
        }}
        showTitle={false}
        width={1150}
        height={650}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <Calendar
          calendarPopup={calendarPopup}
          setCalendarPopup={setCalendarPopup}
          locationDropdownValues={locationDropdownValues}
        />
      </Popup>
      <Popup
        visible={voidTestDatePopup}
        onHiding={() => {
          setVoidTestDatePopup(false);
        }}
        showTitle={false}
        width={660}
        height={380}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <VoidTestDates
          voidTestDatePopup={voidTestDatePopup}
          setVoidTestDatePopup={setVoidTestDatePopup}
          locationDropdownValues={locationDropdownValues}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
        />
      </Popup>
      <div className="page-section" style={{ marginTop: "0px" }}>
        <div className="page-content">
          <div className="buttons">
            <button
              className="btn"
              onClick={() => {
                setCalendarPopup(!calendarPopup);
              }}
            >
              Drug Test Calendar
              <svg
                className="svg-blue-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_7045_74694)">
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM16 13H13V16C13 16.55 12.55 17 12 17C11.45 17 11 16.55 11 16V13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H11V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V11H16C16.55 11 17 11.45 17 12C17 12.55 16.55 13 16 13Z"
                    fill="#424167"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7045_74694">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
            <button
              onClick={() => {
                setDrugTestScheduleMenu(false);
                setRandomizerSummaryPopUpshow(true);
              }}
              className="btn"
            >
              Randomizer Summary
              <svg
                className="svg-blue-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M21.04 13.13C21.18 13.13 21.31 13.19 21.42 13.3L22.7 14.58C22.92 14.79 22.92 15.14 22.7 15.35L21.7 16.35L19.65 14.3L20.65 13.3C20.76 13.19 20.9 13.13 21.04 13.13ZM19.07 14.88L21.12 16.93L15.06 23H13V20.94L19.07 14.88ZM3 7V5H5V4C5 2.89 5.9 2 7 2H13V9L15.5 7.5L18 9V2H19C20.05 2 21 2.95 21 4V10L11 20V22H7C5.95 22 5 21.05 5 20V19H3V17H5V13H3V11H5V7H3ZM5 7H7V5H5V7ZM5 11V13H7V11H5ZM5 17V19H7V17H5Z"
                  fill="black"
                />
              </svg>
            </button>
            <button
              className="btn"
              onClick={() => {
                setVoidTestDatePopup(true);
              }}
            >
              Void Test Dates
              <svg
                className="svg-blue-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_7045_54128)">
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM4 12C4 7.58 7.58 4 12 4C13.85 4 15.55 4.63 16.9 5.69L5.69 16.9C4.63 15.55 4 13.85 4 12ZM12 20C10.15 20 8.45 19.37 7.1 18.31L18.31 7.1C19.37 8.45 20 10.15 20 12C20 16.42 16.42 20 12 20Z"
                    fill="#424167"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7045_54128">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
          <div>
            <div className="goto-obligation-sub-bottom-tab">
              {tabTitles.map((title, index) => (
                <button
                  key={index}
                  className={`goto-obligation-button ${activeTab === index + 1 ? "goto-obligation-button-active" : ""}`}
                  onClick={() => handleTabClick(index + 1)}
                >
                  {title}
                </button>
              ))}
            </div>
            <div className="commonBlock_bottom_border"></div>
            <div className="goto-obligation-subtabs">
              {activeTab === 1 && (
                <DrugTestSchedule
                  userinfo={userinfo}
                  locationDropdownValues={locationDropdownValues}
                  setToastConfig={setToastConfig}
                  toastConfig={toastConfig}
                  setDrugTestScheduleData={setDrugTestScheduleData}
                  drugTestScheduleData={drugTestScheduleData}
                  setFields={setFields}
                  fields={fields}
                  setdate={setdate}
                  date={date}
                  drugTestScheduleMenu={drugTestScheduleMenu}
                />
              )}
              {activeTab === 2 && (
                <CreatesTestDates
                  userinfo={userinfo}
                  locationDropdownValues={locationDropdownValues}
                  setToastConfig={setToastConfig}
                  toastConfig={toastConfig}
                  activeTab={activeTab}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
