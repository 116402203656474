import { DateBox, DropDownBox, LoadPanel, ScrollView, SelectBox, TextBox } from "devextreme-react";
import { useEffect, useState } from "react";
import { ReportWizaerdFuncition } from "../ReportWizaerdFuncition";
import { useNavigate } from "react-router-dom";

export const Customizequerypopup = ({
  Customizequerypopupshow,
  setCustomizequerypopupshow,
  fielddynamicdata,
  defaultquery,
  xmlextrectdata,
  SLogicalUnitName,
  getQueryReportWizard,
  isdefault,
}) => {
  const clas = new ReportWizaerdFuncition();
  const navigate = useNavigate();
  const [Fields, setFields] = useState({
    name: "",
    default: false,
  });
  const [loading, setLoading] = useState(false);
  const [companyEntites, setCompanyEntities] = useState([]);
  const [supervisors, setSupervisors] = useState([]);

  useEffect(() => {
    let data = {};

    xmlextrectdata?.map((val) => {
      data = {
        ...data,
        name: defaultquery?.name,
        default: isdefault ? true : false,
        ...val,
      };
    });

    setFields(data);
  }, [Customizequerypopupshow]);

  const SaveASNew = () => {
    let jsonData = {};
    fielddynamicdata?.map((val) => {
      jsonData = {
        ...jsonData,
        [val?.fieldName]: {
          type: val?.type,
          value: Fields?.[val?.fieldName],
        },
      };
    });

    const xmlData = clas.convertJsonToXml(jsonData);

    clas.saveAndEditQueryReportWizard(
      setLoading,
      navigate,
      "00000000-0000-0000-0000-000000000000",
      Fields?.name,
      new Date().toISOString().slice(0, 19).replace("T", " ") + ".000",
      localStorage.getItem("gUserId"),
      xmlData,
      SLogicalUnitName,
      getQueryReportWizard,
      setCustomizequerypopupshow,
      Fields?.default
    );
  };

  const edit = () => {
    let jsonData = {};
    fielddynamicdata?.map((val) => {
      jsonData = {
        ...jsonData,
        [val?.fieldName]: {
          type: val?.type,
          value: Fields?.[val?.fieldName] ? Fields?.[val?.fieldName] : "",
        },
      };
    });

    const xmlData = clas.convertJsonToXml(jsonData);

    clas.saveAndEditQueryReportWizard(
      setLoading,
      navigate,
      defaultquery?.id,
      Fields?.name,
      new Date().toISOString().slice(0, 19).replace("T", " ") + ".000",
      localStorage.getItem("gUserId"),
      xmlData,
      SLogicalUnitName,
      getQueryReportWizard,
      setCustomizequerypopupshow,
      Fields?.default
    );
  };

  useEffect(() => {
    console.log(1);
    clas.getCompanyEntities(setLoading, navigate, setCompanyEntities);
    clas.getSupervisors(setLoading, navigate, setSupervisors);
  }, []);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />

      <ScrollView style={{ width: "100%" }}>
        <div style={{ height: "498px" }} className="notes_edit_popup">
          <div className="RecivePaymentModel_header">
            <div style={{ width: "200px" }} className="RecivePaymentModel_header_name">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                <path
                  d="M0 15.4586V18.4986C0 18.7786 0.22 18.9986 0.5 18.9986H3.54C3.67 18.9986 3.8 18.9486 3.89 18.8486L14.81 7.93859L11.06 4.18859L0.15 15.0986C0.0500001 15.1986 0 15.3186 0 15.4586ZM17.71 5.03859C18.1 4.64859 18.1 4.01859 17.71 3.62859L15.37 1.28859C14.98 0.898594 14.35 0.898594 13.96 1.28859L12.13 3.11859L15.88 6.86859L17.71 5.03859Z"
                  fill="white"
                />
              </svg>
              <p style={{ marginLeft: "-10px" }}>Query Parameters </p>
              <span
                style={{
                  padding: "2px 9px",
                  gap: "8px",
                  width: "45px",
                  height: "24px",
                  background: "#FEF2F2",
                  borderRadius: "4px",
                }}
              >
                <p
                  style={{
                    color: "#DC2626",
                    fontFamily: "Readex Pro",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "10px",
                    lineHeight: "20px",
                    width: "21px",
                    height: "20px",
                  }}
                >
                  CR.3
                </p>
              </span>
            </div>
            <svg
              onClick={() => {
                setCustomizequerypopupshow(false);
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="editTable_notes">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="collectionpopupBLOCK2input">
                <p style={{ fontWeight: "bold" }}>Name</p>
                <div>
                  <TextBox
                    style={{ marginTop: "2%" }}
                    value={Fields?.name}
                    onValueChange={(e) => {
                      setFields({
                        ...Fields,
                        name: e,
                      });
                    }}
                    isValid={true}
                  />
                </div>
              </div>
              <input
                style={{ marginTop: "21px", marginLeft: "20px" }}
                type="checkbox"
                checked={Fields?.default}
                onClick={() => {
                  setFields({
                    ...Fields,
                    default: !Fields?.default,
                  });
                }}
              />{" "}
              <p style={{ marginTop: "17px", fontWeight: "bold" }}>Default</p>
            </div>
            <div style={{ marginLeft: "-20px", marginTop: "10px" }} className="ChecklistTabbuttons">
              <button
                style={{
                  width: "14%",
                  background: "var(--button-lightblack-lightblue)",
                  color: "var(--TextColor)",
                }}
                onClick={() => {
                  edit();
                }}
              >
                Run
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g clipPath="url(#clip0_6946_14136)">
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM15.88 8.29L10 14.17L8.12 12.29C7.73 11.9 7.1 11.9 6.71 12.29C6.32 12.68 6.32 13.31 6.71 13.7L9.3 16.29C9.69 16.68 10.32 16.68 10.71 16.29L17.3 9.7C17.69 9.31 17.69 8.68 17.3 8.29C16.91 7.9 16.27 7.9 15.88 8.29Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6946_14136">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button
                onClick={() => {
                  SaveASNew();
                }}
                style={{
                  width: "25%",
                  background: "var(--button-lightblack-lightblue)",
                  color: "var(--TextColor)",
                }}
              >
                Save As New
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g clipPath="url(#clip0_6946_78350)">
                    <path
                      d="M17.59 3.59C17.21 3.21 16.7 3 16.17 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V7.83C21 7.3 20.79 6.79 20.41 6.42L17.59 3.59ZM12 19C10.34 19 9 17.66 9 16C9 14.34 10.34 13 12 13C13.66 13 15 14.34 15 16C15 17.66 13.66 19 12 19ZM13 9H7C5.9 9 5 8.1 5 7C5 5.9 5.9 5 7 5H13C14.1 5 15 5.9 15 7C15 8.1 14.1 9 13 9Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6946_78350">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
            <div style={{ marginTop: "1%", marginTop: "20px" }}>
              {fielddynamicdata?.map((val) => {
                if (val?.type === "date") {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "2%",

                          width: "60%",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>{val?.name}</p>
                        <DateBox
                          style={{ width: "60%" }}
                          value={Fields[val?.fieldName]}
                          onValueChange={(e) => {
                            const initialDateString = e;
                            const initialDate = new Date(initialDateString);
                            const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                            const utcFormatted = new Date(utcTime).toISOString();
                            setFields({
                              ...Fields,
                              [val?.fieldName]: e ? utcFormatted : null,
                            });
                          }}
                          useMaskBehavior={true}
                        />
                      </div>
                    </>
                  );
                } else if (val?.type === "location") {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "2%",

                        width: "60%",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>{val?.name}</p>
                      <SelectBox
                        dataSource={[...companyEntites, "All"]}
                        dropDownOptions={{ height: "180px" }}
                        value={Fields[val?.fieldName]}
                        onValueChange={(e) => {
                          setFields({
                            ...Fields,
                            [val?.fieldName]: e,
                          });
                        }}
                        searchEnabled
                      />
                    </div>
                  );
                } else if (val?.type === "supervisor") {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "2%",

                        width: "60%",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>{val?.name}</p>
                      <SelectBox
                        dataSource={[...supervisors, "All"]}
                        dropDownOptions={{ height: "180px" }}
                        value={Fields[val?.fieldName]}
                        onValueChange={(e) => {
                          setFields({
                            ...Fields,
                            [val?.fieldName]: e,
                          });
                        }}
                        searchEnabled
                      />
                    </div>
                  );
                } else if (val?.type === "string") {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "2%",

                          width: "60%",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>{val?.name}</p>
                        <TextBox
                          style={{ width: "60%" }}
                          value={Fields[val?.fieldName]}
                          onValueChange={(e) => {
                            setFields({
                              ...Fields,
                              [val?.fieldName]: e,
                            });
                          }}
                          useMaskBehavior={true}
                        />
                      </div>
                    </>
                  );
                } else if (val?.type === "bool") {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "2%",
                        }}
                      >
                        <p>{val?.name}</p>
                        <input
                          type="checkbox"
                          style={{ marginLeft: "2%" }}
                          checked={
                            Fields[val?.fieldName] == "Yes" || String(Fields[val?.fieldName]) == "true" ? true : false
                          }
                          onClick={(e) => {
                            setFields({
                              ...Fields,
                              [val?.fieldName]: !(Fields[val?.fieldName] == "Yes" ||
                              String(Fields[val?.fieldName]) == "true"
                                ? true
                                : false),
                            });
                          }}
                        />
                      </div>
                    </>
                  );
                }
              })}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "2%",
                marginBottom: "2%",
              }}
            >
              <button
                style={{
                  backgroundColor: "var(--button2)",
                  color: "white",
                  width: "15%",
                  cursor: "pointer",
                  marginRight: "1%",
                }}
                onClick={() => {
                  edit();
                }}
              >
                Save
              </button>
              <button
                style={{
                  color: "var(--TextColor)",
                  width: "15%",
                  cursor: "pointer",
                  background: "var(--button-lightblack-lightblue)",
                }}
                onClick={() => {
                  setCustomizequerypopupshow(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ScrollView>
    </>
  );
};
