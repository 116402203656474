import axios from "axios";
import { logOut } from "../../../../components/Logout";
import { handleGetLocal } from "../../../../services/auth-service";
import {
  setEmEquipmentDropdownData,
  setEmEquipmentTableData,
  setEmObligationTableData,
} from "./Data";

export class EmFunction {
  constructor() {
    this.pathname = handleGetLocal("pathURL");
  }
  async getEmPaymentTypeDropdownApi(
    setLoading,
    navigate,
    setPaymentTypeDropdownData
  ) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=EM Payment Type`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        val?.data?.data?.length &&
          setPaymentTypeDropdownData(
            val?.data?.data?.map((val) => {
              return {
                name: val?.value,
                id: val?.id,
              };
            })
          );
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }
  async getEmPaymentAgencyDropdownApi(
    setLoading,
    navigate,
    setPaymentAgencyDropdownData
  ) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=Billing Agency`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        val?.data?.data?.length &&
          setPaymentAgencyDropdownData(
            val?.data?.data?.map((val) => {
              return {
                name: val?.value,
                id: val?.id,
              };
            })
          );
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }
  async getEmFrequencyDropdownApi(
    setLoading,
    navigate,
    setFrequencyDropdownData
  ) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=Rate Frequency`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        val?.data?.data?.length &&
          setFrequencyDropdownData(
            val?.data?.data?.map((val) => {
              return {
                name: val?.value,
                id: val?.id,
              };
            })
          );
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }
  async getEmObligationDropdownApi(
    setLoading,
    navigate,
    setObligationDataSource,
    gDocketId
  ) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/em/obligation-dropdown-EM?gDocketId=${gDocketId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setObligationDataSource(
          val?.data?.data?.map((val) => {
            return {
              Fee: val?.sFeeName,
              Court: val?.sCourtName,
              Reference: val?.sReference,
              "Fin Status": val?.sFinancialStatusName,
              GObligationId: val?.gObligationId,
            };
          })
        );
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }
  async getEmObligationData(
    setLoading,
    navigate,
    setEmObligationData,
    setEmObligationErrorhandling,
    gPersonId,
    gEmPeriodId
  ) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/em/emperiod-obligation-get?gPersonId=${gPersonId}&gEMPeriodId=${gEmPeriodId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setEmObligationData(setEmObligationTableData(val?.data?.data));
        } else {
          setEmObligationErrorhandling(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setEmObligationErrorhandling(true);
        }
      });
  }
  async addEditEmPeriodObligation(
    navigate,
    setLoading,
    setToastConfig,
    toastConfig,
    data,
    typeTab,
    setAddObligationPopup,
    refreshApi
  ) {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/em/emperiod-obligation-add-edit`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setAddObligationPopup(false);
          refreshApi();
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message:
              typeTab === "Add"
                ? "Successfully Added!"
                : "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message:
                typeTab === "Add"
                  ? "Successfully Added!"
                  : "Successfully Updated!",
              type: "success",
            });
          }, 1900);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Error",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Error",
              type: "error",
            });
          }, 1900);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  }
  async getEqipmentDropdownApi(
    setLoading,
    navigate,
    setReturnStatusDropdown,
    setEquipmentStatusDropdown
  ) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/em/dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        val?.data?.data?.returnedCondition?.length &&
          setReturnStatusDropdown(
            val?.data?.data?.returnedCondition?.map((val) => {
              return {
                name: val?.value,
                id: val?.key,
              };
            })
          );
        val?.data?.data?.equipmentStatus?.length &&
          setEquipmentStatusDropdown(
            val?.data?.data?.equipmentStatus?.map((val) => {
              return {
                name: val?.value,
                id: val?.key,
              };
            })
          );
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }
  async getEquimentLogData(
    setLoading,
    navigate,
    setEmEquipmentLogTableData,
    setErrorhandling,
    gPersonId,
    gEmPeriodId
  ) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/monitoring/get-equipment-log-EM?gPersonId=${gPersonId}&gEMPeriodId=${gEmPeriodId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setEmEquipmentLogTableData(setEmEquipmentTableData(val?.data?.data));
        } else {
          setErrorhandling(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setErrorhandling(true);
        }
      });
  }
  async getEquimentLogDropdownData(
    setLoading,
    navigate,
    setErrorhandling,
    setEquipmentDropdown
  ) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/monitoring/get-equipment-log`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setEquipmentDropdown(setEmEquipmentDropdownData(val?.data?.data));
        } else {
          setErrorhandling(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setErrorhandling(true);
        }
      });
  }
  async addEditEmPeriodEquipment(
    navigate,
    setLoading,
    setToastConfig,
    toastConfig,
    data,
    typeTab,
    setAddEqupmentLogPopup,
    refreshApi
  ) {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/em/equipment-log-add-edit`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setAddEqupmentLogPopup(false);
          refreshApi();
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message:
              typeTab === "Add"
                ? "Successfully Added!"
                : "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message:
                typeTab === "Add"
                  ? "Successfully Added!"
                  : "Successfully Updated!",
              type: "success",
            });
          }, 1900);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Error",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Error",
              type: "error",
            });
          }, 1900);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  }
}
