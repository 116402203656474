import React from "react";
import { Outlet } from "react-router-dom";
import HeaderSideBar from "./HeaderSideBar/headerSideBar";

export const Main = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          height: "100vh",
          width: "100%",
        }}
      >
        <HeaderSideBar />
        <div className="innerPage homePage">
          <Outlet />
        </div>
      </div>
    </>
  );
};
