import React, { useState } from "react";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { TransactionsTablecoloumn, setTransactionsTableData } from "./Data";
import "./financialtab.css";
import { LoadPanel } from "devextreme-react";
import { useEffect } from "react";
import { handleGetLocal } from "../../../../../services/auth-service";
import axios from "axios";
import { logOut } from "../../../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { ApiError } from "../../../../Error/ApiError";

export const Transactions = ({ userinfo }) => {
  const [load, setLoad] = useState(false);
  const [transactionTableData, setTransactionTableData] = useState([]);
  const navigate = useNavigate();
  const [errorhandling, seterrorhandling] = useState(false);

  const getTransactionList = async () => {
    setLoad(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Financial/getTransactions?gPersonId=${userinfo?.gPersonId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoad(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          setTransactionTableData(setTransactionsTableData(val?.data?.data));
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoad(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  useEffect(() => {
    getTransactionList();
  }, [userinfo?.gPersonId]);

  return (
    <>
      <LoadPanel shadingColor="true" visible={load} delay={10} />
      {errorhandling ? (
        <ApiError />
      ) : (
        <div className="payment_plan_tab">
          <div className="obligation_tab_table">
            <Table1
              editTable={false}
              height={400}
              Tablecoloum={TransactionsTablecoloumn}
              tableRow={transactionTableData?.map((val, i) => {
                return { ...val, ID: i };
              })}
              load={load}
              coloumWidthText={"Transactions"}
              mandatoryColoum={"Docket"}
            />
          </div>
        </div>
      )}
    </>
  );
};
