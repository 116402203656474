import axios from "axios";
import { handleGetLocal } from "../../../../../../services/auth-service";
import { logOut } from "../../../../../../components/Logout";

export class GoTOCaseApiFunction {
  constructor() {
    this.pathname = handleGetLocal("pathURL");
  }

  getCheckboxValues = async (setLoading, seterrorhandling, setCriminogenicNeeds, setResponsivityFactors, navigate) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/caseplan-dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          setCriminogenicNeeds(val?.data?.data.criminogenicNeeds || []);
          setResponsivityFactors(val?.data?.data.responsivityFactors || []);
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };
  getCasePlanData = async (setLoading, Docketid, seterrorhandling, setFields, navigate) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/docket/caseplan-get?gDocketId=${Docketid.Docketid}`,
        //`${process.env.REACT_APP_API_URL}/api/docket/caseplan-get?gDocketId=8EA78096-8C77-44BB-95B2-7016FA79DA08`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {          
          seterrorhandling(false);
          const mappedCriminogenicNeed = val?.data?.data?.criminogenicNeed?.map((value) => ({
            id: value.gCriminogenicNeedId,
            active: true,
          }));
        
          const mappedResponsivityFactor = val?.data?.data?.responsivityFactor?.map((value) => ({
            id: value.gResponsivityFactorId,
            active: true,
          }));
          setFields((prev) => ({
            ...prev,
            gCasePlanID: val?.data.data?.gCasePlanID || "",
            overallRiskLevel: val?.data.data?.sRiskLevel || "",
            dateInitialAssessment: val?.data.data?.dInitialAssessment || "",
            reassessmentDate: val?.data.data?.dReassessment || "",
            responsivityFactorList:val?.data?.data?.responsivityFactor || [],
            criminogenicNeed:
              // val?.data?.data?.criminogenicNeed?.map((value) => {
              //   return { id: value.gCriminogenicNeedId, active: true };
              // }) || [],
              mappedCriminogenicNeed || [],
            responsivityFactor:
              // val?.data?.data?.responsivityFactor?.map((value) => {
              //   return { id: value.gResponsivityFactorId, active: true };
              // }) || [],
              mappedResponsivityFactor || [],
          }));
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  getCasePlanInterventionsData = async (setLoading, Docketid, seterrorhandling, setCriminogenicData, navigate) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/docket/caseplan-interventions-get?gDocketId=${Docketid.Docketid}`,
        //`${process.env.REACT_APP_API_URL}/api/Docket/caseplan-interventions-get?gDocketId=8EA78096-8C77-44BB-95B2-7016FA79DA08`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          const updatedCriminogenicData = val?.data.data.map((item) => {
            const updatedItem = {
              ...item,
              actionStep: item.actionStep.map((step) => ({
                ...step,
                isDisable: true,
              })),
              factors: item.factors.map((factor) => ({
                ...factor,
                isDisable: true,
              })),
              goals: item.goals.map((goal) => ({ ...goal, isDisable: true })),
            };
            return updatedItem;
          });
          setCriminogenicData(updatedCriminogenicData);
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  editGoals = async (setLoading, seterrorhandling, navigate, goalData) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/docket/edit-caseplan-goal`, goalData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  editResponsivityFactors = async (setLoading, seterrorhandling, navigate, responsivityData) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/docket/edit-caseplan-responsivityfactor`, responsivityData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  editActionSteps = async (setLoading, seterrorhandling, navigate, actionStepsData) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/docket/edit-caseplan-actionstep`, actionStepsData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  SaveCasePlan = async (
    setLoading,
    seterrorhandling,
    navigate,
    body,
    setToastConfig,
    toastConfig,
    getCasePlanData,
    getCasePlanInterventionsData
  ) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/docket/edit-caseplan`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Case plan added successfully",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Case plan added successfully",
              type: "success",
            });
          }, 1900);
          getCasePlanData();
          getCasePlanInterventionsData();
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  SaveCasePlanAll = async (
    setLoading,
    seterrorhandling,
    navigate,
    body,
    setToastConfig,
    toastConfig,
    getCasePlanData,
    getCasePlanInterventionsData
  ) => {
    console.log('inside save case plan all')
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/docket/add-edit-caseplan`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Case plan added successfully",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Case plan added successfully",
              type: "success",
            });
          }, 1900);
          getCasePlanData();
          getCasePlanInterventionsData();
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  GetCasePlanGoalList = async (setLoading, navigate, seterrorhandling, setGoalListDropdown,gCriminogenicNeedID) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/get-caseplan-goallist?gCriminogenicNeedId=${gCriminogenicNeedID}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.isSuccess) {
          setGoalListDropdown(res.data.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (!err?.response) {
          navigate(`/error/500`);
        } else if (err?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  GetCasePlanActionStepList = async (setLoading, navigate, seterrorhandling, setActionStepListDropdown,gCriminogenicNeedID) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/get-caseplan-actionsteplist?gCriminogenicNeedId=${gCriminogenicNeedID}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.isSuccess) {
          setActionStepListDropdown(res.data.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (!err?.response) {
          navigate(`/error/500`);
        } else if (err?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };
}
