import React from 'react';

export default function CaseloadReview(props) {
  const closeWidgets = (name) => {
    return props.hideWidgets(name)
  },
  userWidget = () => {
    const element = document.getElementById("Caseload");
    props.saveHeightWidth({ name: 'caseloadReview', width: element.offsetWidth, height: element.offsetHeight })
  }
  return (
    <div id="Caseload" className="tableWrapNew"  style={{ width: props.width, height: props.height }} onMouseUp={() => userWidget()}>
      <div className="headingSection">
        <h3>
          <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_24_300459)">
              <path fillRule="evenodd" clipRule="evenodd" d="M16.6719 13.13C18.0419 14.06 19.0019 15.32 19.0019 17V20H22.0019C22.5519 20 23.0019 19.55 23.0019 19V17C23.0019 14.82 19.4319 13.53 16.6719 13.13Z" fill="white" />
              <path d="M9 12C11.2091 12 13 10.2091 13 8C13 5.79086 11.2091 4 9 4C6.79086 4 5 5.79086 5 8C5 10.2091 6.79086 12 9 12Z" fill="white" />
              <path fillRule="evenodd" clipRule="evenodd" d="M15.0019 12C17.2119 12 19.0019 10.21 19.0019 8C19.0019 5.79 17.2119 4 15.0019 4C14.5319 4 14.0919 4.1 13.6719 4.24C14.5019 5.27 15.0019 6.58 15.0019 8C15.0019 9.42 14.5019 10.73 13.6719 11.76C14.0919 11.9 14.5319 12 15.0019 12Z" fill="white" />
              <path fillRule="evenodd" clipRule="evenodd" d="M9 13C6.33 13 1 14.34 1 17V19C1 19.55 1.45 20 2 20H16C16.55 20 17 19.55 17 19V17C17 14.34 11.67 13 9 13Z" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_24_300459">
                <rect width={24} height={24} fill="white" />
              </clipPath>
            </defs>
          </svg>
          <em>
            Caseload Review
          </em>
        </h3>
        <div className="rightSide widget_head">
          <ul>
            <li><svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1174_385365)">
                <path d="M8.25 13.5C8.25 14.325 7.575 15 6.75 15C5.925 15 5.25 14.325 5.25 13.5C5.25 12.675 5.925 12 6.75 12C7.575 12 8.25 12.675 8.25 13.5ZM6.75 7.5C5.925 7.5 5.25 8.175 5.25 9C5.25 9.825 5.925 10.5 6.75 10.5C7.575 10.5 8.25 9.825 8.25 9C8.25 8.175 7.575 7.5 6.75 7.5ZM6.75 3C5.925 3 5.25 3.675 5.25 4.5C5.25 5.325 5.925 6 6.75 6C7.575 6 8.25 5.325 8.25 4.5C8.25 3.675 7.575 3 6.75 3ZM11.25 6C12.075 6 12.75 5.325 12.75 4.5C12.75 3.675 12.075 3 11.25 3C10.425 3 9.75 3.675 9.75 4.5C9.75 5.325 10.425 6 11.25 6ZM11.25 7.5C10.425 7.5 9.75 8.175 9.75 9C9.75 9.825 10.425 10.5 11.25 10.5C12.075 10.5 12.75 9.825 12.75 9C12.75 8.175 12.075 7.5 11.25 7.5ZM11.25 12C10.425 12 9.75 12.675 9.75 13.5C9.75 14.325 10.425 15 11.25 15C12.075 15 12.75 14.325 12.75 13.5C12.75 12.675 12.075 12 11.25 12Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_1174_385365">
                  <rect width={18} height={18} fill="white" />
                </clipPath>
              </defs>
            </svg>
            </li>
            <li>
              <svg width={12} height={13} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.2409 2.76249C9.01837 1.53999 7.28587 0.834994 5.38087 1.02999C2.62837 1.30749 0.363373 3.54249 0.0558729 6.29499C-0.356627 9.93249 2.45587 13 6.00337 13C8.39587 13 10.4509 11.5975 11.4109 9.57999C11.6509 9.07749 11.2909 8.49999 10.7359 8.49999C10.4584 8.49999 10.1959 8.64999 10.0759 8.89749C9.22837 10.72 7.19587 11.875 4.97587 11.38C3.31087 11.0125 1.96837 9.65499 1.61587 7.98999C0.985873 5.07999 3.19837 2.49999 6.00337 2.49999C7.24837 2.49999 8.35837 3.01749 9.16837 3.83499L8.03587 4.96749C7.56337 5.43999 7.89337 6.24999 8.56087 6.24999H11.2534C11.6659 6.24999 12.0034 5.91249 12.0034 5.49999V2.80749C12.0034 2.13999 11.1934 1.80249 10.7209 2.27499L10.2409 2.76249Z" fill="white" />
              </svg>
            </li>
            <li onClick={() => closeWidgets('caseloadReview')}>
              <svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.2987 0.709971C12.9087 0.319971 12.2787 0.319971 11.8887 0.709971L6.99875 5.58997L2.10875 0.699971C1.71875 0.309971 1.08875 0.309971 0.69875 0.699971C0.30875 1.08997 0.30875 1.71997 0.69875 2.10997L5.58875 6.99997L0.69875 11.89C0.30875 12.28 0.30875 12.91 0.69875 13.3C1.08875 13.69 1.71875 13.69 2.10875 13.3L6.99875 8.40997L11.8887 13.3C12.2787 13.69 12.9087 13.69 13.2987 13.3C13.6887 12.91 13.6887 12.28 13.2987 11.89L8.40875 6.99997L13.2987 2.10997C13.6787 1.72997 13.6787 1.08997 13.2987 0.709971Z" fill="white" fillOpacity={1} />
              </svg>
            </li>
          </ul>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>
              <div className="link">
                User
                <svg width={6} height={4} viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.807026 1.80663L2.53369 3.53329C2.79369 3.79329 3.21369 3.79329 3.47369 3.53329L5.20036 1.80663C5.62036 1.38663 5.32036 0.666626 4.72703 0.666626H1.27369C0.68036 0.666626 0.387027 1.38663 0.807026 1.80663Z" fill="black" fillOpacity="0.6" />
                </svg>
              </div>
            </th>
            <th>Loaction</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ashford, Daren</td>
            <td>Pronto</td>
            <td>2</td>
          </tr>
          <tr>
            <td>Ashford, Daren</td>
            <td>Pronto</td>
            <td>2</td>
          </tr>
          <tr>
            <td>Ashford, Daren</td>
            <td>Pronto</td>
            <td>2</td>
          </tr>
          <tr>
            <td>Ashford, Daren</td>
            <td>Pronto</td>
            <td>2</td>
          </tr>
          <tr>
            <td>Ashford, Daren</td>
            <td>Pronto</td>
            <td>2</td>
          </tr>
          <tr>
            <td>Ashford, Daren</td>
            <td>Pronto</td>
            <td>2</td>
          </tr>
          <tr>
            <th colSpan={2}>2/10/23 3:45 PM</th>
            <th>465</th>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
