import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import httpService from "../../../services/http-service";
import { generateGuid } from "../../../services/uuid-service";
import { getAuthToken } from "../../../services/auth-service";
import { useDispatch } from "react-redux";
import { set_admin_table } from "../../../reducer/AdminReadReducer";
import { ScrollView } from "devextreme-react";
import { Toast } from "devextreme-react";

export const Add = ({ setIsPopupVisible, tableDataApi }) => {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const {
    table_name,
    table_id,
    replace_headers_title,
    headers_title,
    transformedData,
    foreignKeyData,
  } = useSelector((store) => store.AdminTable);
  let domainName = (url) =>
    url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
  let domailURL = window.location.href;
  let pathURL = domainName(domailURL);
  const token = getAuthToken();
  const componentRef = useRef(null);
  let tableData = [transformedData[0]];
  const transformedDatas = {};
  const fields = [];

  const keyWithId = Object.keys(tableData[0]).find(
    (key) => !replace_headers_title.includes(key)
  );

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setFormErrors({});
        setFormData({});
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const dispatch = useDispatch();
  const tableDataId = table_id;

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
      [keyWithId]: generateGuid(),
    });
    setFormErrors({
      ...formErrors,
      [name]: undefined,
    });
  };
  const dataChange = () => {
    const caption = replace_headers_title;
    const columns = headers_title;
    Object.keys(formData).forEach((key) => {
      const captionIndex = caption.indexOf(key);
      if (captionIndex !== -1) {
        transformedDatas[columns[captionIndex]] = formData[key];
      } else {
        transformedDatas[key] = formData[key];
      }
    });
    delete transformedDatas.ID;
    for (const key in transformedDatas) {
      fields.push({
        FieldName: key,
        FieldValue: transformedDatas[key],
      });
    }
  };

  const submitData = async () => {
    const errors = {};
    let hasErrors = false;
    replace_headers_title.forEach((type) => {
      if (!formData[type]) {
        errors[type] = `Please Enter ${type}`;
        hasErrors = true;
      } else if (typeof formData[type] === "string" && !formData[type].trim()) {
        errors[type] = `Please Enter valid ${type}`;
        hasErrors = true;
      } else if (
        typeof formData[type] === "string" &&
        formData[type].startsWith(" ")
      ) {
        errors[type] = `${type} cannot start with a white space`;
        hasErrors = true;
      } else if (!formData[type === "text"]) {
        hasErrors = false;
      }
    });

    if (hasErrors) {
      setFormErrors(errors);
      return;
    }

    dataChange();
    await httpService
      .post(
        `api/Administration/BDECRUD`,
        {
          TableName: table_name,
          EntityState: 1,
          Fields: fields,
        },
        {
          headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` },
        }
      )
      .then((response) => {
        if (!response.data.isSuccess === false) {
          setIsPopupVisible(false);
          setFormData({});
          tableDataApi(tableDataId);
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Added!",
            type: "success",
          });
        } else {
          // setToastConfig({
          //   ...toastConfig,
          //   isVisible: true,
          //   message: response.data.errors,
          //   type: "Error",
          // });
          console.error(response.data.errors);
        }
      })
      .catch((error) => {
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: error,
          type: "Error",
        });
        console.error(error);
      });
  };

  return (
    <>
      <Toast
        visible={toastConfig.isVisible}
        position={"top"}
        width={"auto"}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
          });
        }}
        displayTime={600}
      />
      <div className="admin_Add_Table">
        <div ref={componentRef}>
          <div className="admin_Add_Table_head">
            <div className="admin_Add_Table_head_title">
              <div className="admin_Add_Table_head_title_data">
                <svg
                  width="15"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginTop: "17px", marginRight: "2%" }}
                >
                  <path
                    d="M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z"
                    fill="white"
                  />
                </svg>
                <p>Add</p>
              </div>
              <p className="admin_Add_Table_head_para">UT.9</p>
            </div>
            <svg
              onClick={() => {
                setFormData({});
                setIsPopupVisible(false);
                setFormErrors({});
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          <ScrollView style={{ height: "295px" }}>
            <div className="admin_Add_Table_Inputs">
              {tableData?.map((row, rowIndex) => (
                <div key={rowIndex}>
                  {replace_headers_title?.map((column, columnIndex) => {
                    const value = formData[column] || "";
                    return (
                      <>
                        <div
                          className="admin_Add_Table_Inputs_field"
                          key={columnIndex}
                        >
                          {typeof row[column] === "boolean" ? (
                            <>
                              <label>{column}</label>
                              <input
                                className="input_checkbox"
                                type="checkbox"
                                name={column}
                                id={column}
                                onChange={handleChange}
                                checked={value}
                              />
                            </>
                          ) : (
                            <>
                              {!foreignKeyData?.length ? (
                                <>
                                  <label>{column}</label>
                                  <input
                                    className="inputs"
                                    placeholder={`${column}`}
                                    name={column}
                                    id={column}
                                    onChange={handleChange}
                                    value={value}
                                    type={typeof row[column]}
                                  />
                                  {formErrors[column] && (
                                    <p className="error_Message">
                                      {formErrors[column]}
                                    </p>
                                  )}
                                </>
                              ) : (
                                <>
                                  {!foreignKeyData
                                    .map((val) => {
                                      return val.captionname;
                                    })
                                    .includes(column) && (
                                    <>
                                      <label>{column}</label>
                                      <input
                                        className="inputs"
                                        placeholder={`${column}`}
                                        name={column}
                                        id={column}
                                        onChange={handleChange}
                                        value={value}
                                        type={typeof row[column]}
                                      />
                                      {formErrors[column] && (
                                        <p className="error_Message">
                                          {formErrors[column]}
                                        </p>
                                      )}
                                    </>
                                  )}
                                  {foreignKeyData.map((val) => {
                                    return (
                                      <>
                                        {val.captionname === column && (
                                          <>
                                            <label>{column}</label>
                                            <select
                                              name={column}
                                              onChange={handleChange}
                                              className="inputs"
                                            >
                                              <option value="">Select</option>
                                              {val.data.map((item, idx) => {
                                                const dynamicKey =
                                                  Object.keys(item)[0];
                                                const dynamicValue =
                                                  item[dynamicKey];
                                                const dynamicLabel =
                                                  Object.values(item)[1];
                                                return (
                                                  <option
                                                    key={idx}
                                                    value={dynamicValue}
                                                  >
                                                    {dynamicLabel}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                            {formErrors[column] && (
                                              <p className="error_Message">
                                                {formErrors[column]}
                                              </p>
                                            )}
                                          </>
                                        )}
                                      </>
                                    );
                                  })}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
              ))}
              {transformedData.length === 0 ? (
                ""
              ) : (
                <>
                  <div className="popup_buttons" style={{ marginTop: "20px" }}>
                    <button
                      className="btn"
                      onClick={() => {
                        setFormData({});
                        setIsPopupVisible(false);
                        setFormErrors({});
                      }}
                    >
                      Cancel
                    </button>
                    <button className="primary_btn" onClick={submitData}>
                      Save
                    </button>
                  </div>
                </>
              )}
            </div>
          </ScrollView>
        </div>
      </div>
    </>
  );
};
