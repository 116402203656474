import { useState } from "react";
import { Table2 } from "../../../../components/Table2/Table2";
import "./Notes.css";
import { EditTable } from "./Edit_AddTable";
import { LoadPanel, Popup } from "devextreme-react";
import { handleGetLocal } from "../../../../services/auth-service";
import { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { setresetValue } from "../../../../reducer/participantNoteReducer";
import { useDispatch } from "react-redux";
import { ExportFile } from "../../../../components/ExportGride/ExportFile";
import { Toster } from "../../../../components/Toster/Toster";
import { logOut } from "../../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { ApiError } from "../../../Error/ApiError";

const Tablecoloum = [
  {
    title: "Edit",
    Action: true,
  },
  { title: "Note", customwidth: 600, note: true },
  {
    title: "Type",
  },
  { title: "DOE" },
  {
    title: "Entered by",
  },
  {
    title: "Category",
  },
  {
    title: "Arrearage ?",
    bool: true,
  },
  {
    title: "On call ?",
    bool: true,
  },
];

const setTableData = (dataa) => {
  const arr = [];
  dataa.map((val) => {
    arr.push({
      Note: val?.sMemo,
      Type: val?.sActivityTypeName,
      DOE: new Date(val?.doe).toLocaleString("en-US", {
        month: "numeric",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
      "Entered by": val?.enteredBy,
      Category: val?.category,
      "Arrearage ?": val?.bArrearageRelated,
      "On call ?": val?.bOnCall,
      ID: val?.gRecordId,
      gActivityTypeId: val?.gActivityTypeId,
      gActivityId: val?.gActivityId,
      bSystem: val?.bSystem,
      showedit: val?.doe ? val?.doe?.split("T")[0] : "",
    });
  });

  return arr;
};

const PopUp = ({
  NoteEditTable,
  setNoteEditTable,
  addDataIngTable,
  userinfo,
  getEditData,
  setGetEditData,
  setToastConfig,
  toastConfig,
  GoTONote,
  dockerValue,
}) => {
  const { resetValue } = useSelector((store) => store.participateNotes);
  const dispatch = useDispatch();

  return (
    <>
      <Popup
        visible={(dockerValue?.getTopDynamicDocketid || userinfo?.gPersonId) && NoteEditTable}
        onHiding={() => {
          dispatch(setresetValue(!resetValue));
          setGetEditData({});
          setNoteEditTable(false);
        }}
        showTitle={false}
        width={730}
        height={500}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <EditTable
          addDataIngTable={addDataIngTable}
          setNoteEditTable={setNoteEditTable}
          userinfo={userinfo}
          getEditData={getEditData}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          GoTONote={GoTONote}
          dockerValue={dockerValue}
        />
      </Popup>
    </>
  );
};

export const Index = ({ userinfo, dockerValue, GoTONote, GoTONotee, showchecklist, feeshudule, setnotesDatacount }) => {
  const [NoteEditTable, setNoteEditTable] = useState(false);
  const [addDataIngTable, setaddDataIngTable] = useState(false);
  const pathname = handleGetLocal("pathURL");
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getEditData, setGetEditData] = useState({});
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const { on_Save_Edit, resetValue } = useSelector((store) => store.participateNotes);
  const [serch, setserch] = useState("");
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const [checkbox, setcheckbox] = useState({
    obligationnotes: GoTONotee ? true : false,
    docketnotes: GoTONote ? true : false,
    systemnotes: true,
  });

  const navigate = useNavigate();
  const [errorhandling, seterrorhandling] = useState(false);
  const [prewviewtogal, setprewviewtogal] = useState(undefined);

  const getTableData = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/activity/get?recordId=${
          GoTONote || GoTONotee ? dockerValue?.getTopDynamicDocketid : userinfo?.gPersonId
        }&systemnotes=${checkbox?.systemnotes}&docketnotes=${checkbox?.docketnotes}&obligationnotes=${
          checkbox?.obligationnotes
        }${serch ? `&search=${serch}` : ""}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          setdata(setTableData(val.data.data));
          // val?.data?.data?.length && setnotesDatacount(val?.data?.data?.length);
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  const getTableData2 = async () => {
    if (!dockerValue?.getTopDynamicDocketid) {
      return;
    }
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/activity/get?recordId=${dockerValue?.getTopDynamicDocketid}${
          serch ? `&search=${serch}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          setdata(setTableData(val.data.data));
          // val?.data?.data?.length && setnotesDatacount(val?.data?.data?.length);
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  useEffect(() => {
    if (dockerValue?.getTopDynamicDocketid || userinfo?.gPersonId) {
      if (feeshudule) {
        getTableData2();
      } else {
        getTableData();
      }
    }
  }, [on_Save_Edit, checkbox.docketnotes, checkbox.obligationnotes, checkbox.systemnotes, userinfo]);

  const dispatch = useDispatch();

  const resetvalueonedit = () => {
    dispatch(setresetValue(!resetValue));
  };

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />

      <PopUp
        NoteEditTable={NoteEditTable}
        setNoteEditTable={setNoteEditTable}
        addDataIngTable={addDataIngTable}
        userinfo={userinfo}
        getEditData={getEditData}
        setGetEditData={setGetEditData}
        setToastConfig={setToastConfig}
        toastConfig={toastConfig}
        GoTONote={GoTONote}
        dockerValue={dockerValue}
      />

      {errorhandling ? (
        <ApiError />
      ) : (
        <div className="Notes">
          <div className="Notes_buttons">
            <button
              className="btn"
              onClick={() => {
                setNoteEditTable(true);
                setaddDataIngTable(true);
              }}
            >
              Add Note
              <svg
                className="svg-blue-white"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z"
                  fill="#424167"
                />
              </svg>
            </button>
            <button
              className="btn"
              onClick={() => {
                setprewviewtogal(!prewviewtogal);
              }}
            >
              Print Notes
              <svg
                className="svg-blue-white"
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 5H3C1.34 5 0 6.34 0 8V12C0 13.1 0.9 14 2 14H4V16C4 17.1 4.9 18 6 18H14C15.1 18 16 17.1 16 16V14H18C19.1 14 20 13.1 20 12V8C20 6.34 18.66 5 17 5ZM13 16H7C6.45 16 6 15.55 6 15V11H14V15C14 15.55 13.55 16 13 16ZM17 9C16.45 9 16 8.55 16 8C16 7.45 16.45 7 17 7C17.55 7 18 7.45 18 8C18 8.55 17.55 9 17 9ZM15 0H5C4.45 0 4 0.45 4 1V3C4 3.55 4.45 4 5 4H15C15.55 4 16 3.55 16 3V1C16 0.45 15.55 0 15 0Z"
                  fill="#424167"
                />
              </svg>
            </button>
            <button
              className="btn"
              onClick={() => {
                setexportPopupOpen(true);
              }}
            >
              Export
              <svg
                className="svg-blue-white"
                width="14"
                height="17"
                viewBox="0 0 14 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                  fill="#424167"
                />
              </svg>
            </button>
            <div className="Notes_buttons_checkbox">
              <input
                checked={checkbox?.systemnotes}
                onClick={() => {
                  setcheckbox({
                    ...checkbox,
                    systemnotes: !checkbox?.systemnotes,
                  });
                }}
                type="checkbox"
                id="includeSystemNotes"
              />
              <label htmlFor="includeSystemNotes" className="go-to-label-filed">
                Include System Notes
              </label>
            </div>
            <div className="Notes_buttons_checkbox">
              {showchecklist && (
                <>
                  <input
                    checked={checkbox?.obligationnotes}
                    onClick={() => {
                      setcheckbox({
                        ...checkbox,
                        obligationnotes: !checkbox?.obligationnotes,
                      });
                    }}
                    type="checkbox"
                  />
                  <label className="go-to-label-filed">Include Obligation Notes</label>
                </>
              )}
            </div>
            <div className="Notes_buttons_checkbox">
              {showchecklist && (
                <>
                  <input
                    checked={checkbox?.docketnotes}
                    onClick={() => {
                      setcheckbox({
                        ...checkbox,
                        docketnotes: !checkbox?.docketnotes,
                      });
                    }}
                    type="checkbox"
                  />
                  <label className="go-to-label-filed">Include Docket Notes</label>
                </>
              )}
            </div>
          </div>
          <div className="Notes_serch_block">
            <p style={{ fontWeight: "bold" }}>Search Note</p>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (feeshudule) {
                  getTableData2();
                } else {
                  getTableData();
                }
              }}
            >
              <input
                type="text"
                onChange={(e) => {
                  setserch(e.target.value);
                }}
              />
            </form>

            <button
              style={{
                backgroundColor: "var(--button2)",
                color: "white",
                justifyContent: "space-around",
                display: "flex",
                width: "95px",
                height: "40px",
              }}
              onClick={() => {
                if (feeshudule) {
                  getTableData2();
                } else {
                  getTableData();
                }
              }}
            >
              <p
                style={{
                  marginTop: "-2px",
                  color: "white",
                }}
              >
                Search
              </p>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.5006 11.0006H11.7106L11.4306 10.7306C12.6306 9.33063 13.2506 7.42063 12.9106 5.39063C12.4406 2.61063 10.1206 0.390626 7.32063 0.0506256C3.09063 -0.469374 -0.469374 3.09063 0.0506256 7.32063C0.390626 10.1206 2.61063 12.4406 5.39063 12.9106C7.42063 13.2506 9.33063 12.6306 10.7306 11.4306L11.0006 11.7106V12.5006L15.2506 16.7506C15.6606 17.1606 16.3306 17.1606 16.7406 16.7506C17.1506 16.3406 17.1506 15.6706 16.7406 15.2606L12.5006 11.0006ZM6.50063 11.0006C4.01063 11.0006 2.00063 8.99063 2.00063 6.50063C2.00063 4.01063 4.01063 2.00063 6.50063 2.00063C8.99063 2.00063 11.0006 4.01063 11.0006 6.50063C11.0006 8.99063 8.99063 11.0006 6.50063 11.0006Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
          <div className="Note_Table">
            <Table2
              Tablecoloum={Tablecoloum}
              tableRow={data}
              editTable={false}
              load={false}
              coloumWidthText={"NotesTable"}
              height={620}
              mandatoryColoum={"DOE"}
              showTotalBlock={false}
              setNoteEditTable={setNoteEditTable}
              setaddDataIngTable={setaddDataIngTable}
              setGetEditData={setGetEditData}
              resetvalueonedit={resetvalueonedit}
              exportInDifferentFormate={true}
              exportPopupOpen={exportPopupOpen}
              setexportPopupOpen={setexportPopupOpen}
              exportwidth={43}
              exportFont={30}
              exporttitle={"Notes"}
              preview={true}
              prewviewtogal={prewviewtogal}
            />
          </div>
        </div>
      )}
    </>
  );
};
