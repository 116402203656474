import { LoadPanel, TextBox } from "devextreme-react";
import { EditCheckistPopupFunction } from "./EditCheckistPopupFunction";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const AddChecklistPOpup = ({ setpopup, popup, setToastConfig, toastConfig, getDRopDown, AdCHeckLIst }) => {
  const clas = new EditCheckistPopupFunction();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [textvalidation, settextvalidation] = useState("");
  const onSave = () => {
    if (Fields?.text) {
      settextvalidation("");
      clas.addChicklist(
        setLoading,
        setToastConfig,
        toastConfig,
        navigate,
        {
          name: Fields?.text,
          table: AdCHeckLIst,
        },
        setpopup,
        getDRopDown
      );
    } else {
      settextvalidation("Please Enter Name");
    }
  };

  const [Fields, setFields] = useState({
    text: "",
  });

  useEffect(() => {
    settextvalidation("");
    setFields({
      text: "",
    });
  }, [popup]);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div
        style={{
          width: "100%",
          height: "248px",
          border: "3px solid rgb(67, 66, 102)",
        }}
        className="CreateAppointmentPopup"
      >
        <div className="RecivePaymentModel_header">
          <div style={{ width: "190px" }} className="RecivePaymentModel_header_name">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clipPath="url(#clip0_5796_5656)">
                <path
                  d="M3 17.4605V20.5005C3 20.7805 3.22 21.0005 3.5 21.0005H6.54C6.67 21.0005 6.8 20.9505 6.89 20.8505L17.81 9.94055L14.06 6.19055L3.15 17.1005C3.05 17.2005 3 17.3205 3 17.4605ZM20.71 7.04055C21.1 6.65055 21.1 6.02055 20.71 5.63055L18.37 3.29055C17.98 2.90055 17.35 2.90055 16.96 3.29055L15.13 5.12055L18.88 8.87055L20.71 7.04055Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_5796_5656">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p style={{ marginLeft: "-10px" }}>Enter Information</p>
            <span
              style={{
                padding: "2px 9px",
                gap: "8px",
                width: "45px",
                height: "24px",
                background: "#FEF2F2",
                borderRadius: "4px",
              }}
            >
              <p
                style={{
                  color: "#DC2626",
                  fontFamily: "Readex Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "10px",
                  lineHeight: "20px",
                  width: "21px",
                  height: "20px",
                }}
              >
                UT.3
              </p>
            </span>
          </div>
          <svg
            onClick={() => {
              setpopup(false);
            }}
            style={{
              cursor: "pointer",
              marginTop: "17px",
              marginRight: "2%",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div style={{ width: "96%", marginLeft: "2%" }}>
          <div style={{ marginTop: "2%" }}>
            <p style={{ fontWeight: "bold" }}>
              Enter Checklist Name <span>*</span>
            </p>
            <TextBox
              style={{ marginTop: "2%" }}
              value={Fields?.text}
              onValueChange={(e) => {
                settextvalidation("");
                setFields({
                  ...Fields,
                  text: e,
                });
              }}
              isValid={true}
            />
            <p style={{ color: "red", position: "absolute" }}>{textvalidation}</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "13%",
              marginBottom: "2%",
            }}
          >
            <button
              style={{
                backgroundColor: "var(--button2)",
                color: "white",
                width: "15%",
                cursor: "pointer",
              }}
              onClick={() => {
                onSave();
              }}
            >
              Done
            </button>
            <button
              style={{
                width: "15%",
                marginLeft: "1%",
                cursor: "pointer",
                background: "var(--button-lightblack-lightblue)",
                color: "var(--TextColor)",
              }}
              onClick={() => {
                setpopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
