import React, { useEffect, useState } from "react";
import { DynamicTabb } from "../../components/DynamicTab/DynamicTab";
import "./adminUser.css";
import { LoadPanel, Popup, TextBox, Toast } from "devextreme-react";
import {
  getAuthToken,
  handleGetLocal,
  logout,
} from "../../services/auth-service";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import image5 from "../../assets/images/image5.jpg";
import {
  AddAuthenticationPhone,
  setAdminSavePopup,
} from "../../reducer/AdminReadReducer";
import { Groups } from "../Administration/components/Groups";
import { Entities } from "../Administration/components/Entities";
import { logOut } from "../../components/Logout";
import axios from "axios";
import httpService from "../../services/http-service";
import { set_user_img, set_user_name } from "../../reducer/HomePageReducer";
import ResetPassword from "./ResetPassword/ResetPassword";
import { useRef } from "react";

export const AdminUser = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [imageUploadValidation, setimageUploadValidation] = useState("");
  const [image, setImage] = useState();
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState({
    image: "",
    userID: "",
    firstName: "",
    middleName: "",
    lastName: "",
    password: "",
    email: "",
    employeeID: "",
    roleId: "",
    userRole: "Select",
    activeUser: false,
    resourceUser: false,
    courtUser: false,
    addressUser: "",
    cityUser: "",
    stateUser: "",
    zipUser: "",
    enableAuth: false,
    twoFactorAuthenticationEmail: null,
    twoFactorAuthenticationPhone: null,
  });
  const [isEditable, setisEditable] = useState(false);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gropuData, setGroupData] = useState([]);
  const [entityData, setEntityData] = useState([]);
  const [entityResult, setEntityResult] = useState([]);
  const [groupResult, setGroupResult] = useState([]);
  const [isResetPass, setIsResetPass] = useState(false);
  const [previousRoleName, setpreviousRoleName] = useState("");
  // #endregion

  const token = getAuthToken();
  const pathname = handleGetLocal("pathURL");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { AuthenticationPhone } = useSelector((store) => store.AdminTable);
  const gUserId = localStorage.getItem("gUserId");
  const { user_img } = useSelector((store) => store.Home);
  const [file, setFile] = useState(user_img ? user_img : "");
  const inputRef = useRef(null);

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      let a = file.name;
      if (!(a.includes("jpg") || a.includes("png") || a.includes("jpeg"))) {
        setimageUploadValidation("Upload valid image");
        return;
      }
      setimageUploadValidation("");
      setImage(file);
      const url = URL.createObjectURL(file);
      document.querySelector(".participate_block1_sub1_div_imag").src = url;
    }
  };

  function handleImgChange(e) {
    const File = e;
    if (!e) {
      return;
    }
    let a = e.name;
    if (!(a.includes("jpg") || a.includes("png") || a.includes("jpeg"))) {
      setimageUploadValidation("Upload valid image");
      return;
    } else {
      setimageUploadValidation("");
      setImage(e);
    }
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      function () {
        setFile(reader.result);
      },
      false
    );
    if (File) {
      reader.readAsDataURL(File);
    }
  }

  const handleChange = (e) => {
    let field = fields,
      namePattern = /^[a-zA-Z,.'-]+$/,
      cityPattern = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;
    var mailPattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    if (e.target.name === "userID") {
      if (field["userID"] === "" || fields.userID.trim() === "") {
        errors["userID"] = "Please enter valid user ID";
      } else {
        errors["userID"] = "";
      }
    }
    if (e.target.name === "firstName") {
      if (!namePattern.test(e.target.value)) {
        errors["firstName"] = "Please enter valid first name";
      } else {
        errors["firstName"] = "";
      }
    }
    if (e.target.name === "lastName") {
      if (!namePattern.test(e.target.value)) {
        errors["lastName"] = "Please enter valid last name";
      } else {
        errors["lastName"] = "";
      }
    }
    if (e.target.name === "email") {
      if (!mailPattern.test(e.target.value)) {
        errors["email"] = "Please enter valid email";
      } else {
        errors["email"] = "";
      }
    }
    if (e.target.name === "twoFactorAuthenticationEmail") {
      if (!mailPattern.test(e.target.value)) {
        errors["twoFactorAuthenticationEmail"] = "Please enter valid email";
      } else {
        errors["twoFactorAuthenticationEmail"] = "";
      }
    }
    if (e.target.name === "employeeID") {
      if (!namePattern.test(e.target.value)) {
        errors["employeeID"] = "Please enter employee ID";
      } else {
        errors["employeeID"] = "";
      }
    }
    if (e.target.name === "addressUser") {
      var addPattern = /^[a-zA-Z0-9\s,'-]*$/;
      if (!addPattern.test(e.target.value)) {
        errors["addressUser"] = "Please enter valid address";
      } else {
        errors["addressUser"] = "";
      }
    }
    if (e.target.name === "cityUser") {
      if (!cityPattern.test(e.target.value)) {
        errors["cityUser"] = "Please enter valid city name";
      } else {
        errors["cityUser"] = "";
      }
    }
    if (e.target.name === "stateUser") {
      if (!cityPattern.test(e.target.value)) {
        errors["stateUser"] = "Please enter valid state name";
      } else {
        errors["stateUser"] = "";
      }
    }
    if (e.target.name === "zipUser") {
      var zipcPattern = /[0-9]$/;
      if (!zipcPattern.test(e.target.value)) {
        errors["zipUser"] = "Please enter valid zip code";
      } else if (e.target.value.length < 5) {
        errors["zipUser"] = "Please enter valid zip code";
      } else {
        errors["zipUser"] = "";
      }
    }

    setFields({
      ...fields,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleReset = (data) => {
    setIsResetPass(data);
  };

  const roleChange = (e) => {
    setFields((prev) => ({
      ...prev,
      roleId: e.target.value,
    }));
    if (!e.target.value) {
      errors["userRole"] = "Please select User Role";
    } else {
      errors["userRole"] = "";
    }
  };

  const validateLogin = () => {
    let error = {};
    let formIsValid = true;
    let namePattern = /^[a-zA-Z,.'-]+$/;
    var mailPattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

    if (!fields.userID || fields.userID.trim() === "") {
      formIsValid = false;
      error["userID"] = "Please enter valid user ID";
    }
    if (!fields.firstName || fields.firstName.trim() === "") {
      formIsValid = false;
      error["firstName"] = "Please enter valid email";
    }
    if (!namePattern.test(fields.firstName)) {
      formIsValid = false;
      error["firstName"] = "Please enter valid first name";
    }
    if (!fields.lastName || fields.lastName.trim() === "") {
      formIsValid = false;
      error["lastName"] = "Please enter your last name";
    }
    if (!namePattern.test(fields.lastName)) {
      formIsValid = false;
      error["lastName"] = "Please enter valid last name";
    }
    if (
      !fields.email ||
      fields.email.trim() === "" ||
      !mailPattern.test(fields.email)
    ) {
      formIsValid = false;
      error["email"] = "Please enter valid email";
    }
    if (fields.roleId === "") {
      formIsValid = false;
      error["userRole"] = "Please select User Role";
    }
    if (fields.enableAuth === true) {
      if (
        !fields.twoFactorAuthenticationEmail ||
        fields.twoFactorAuthenticationEmail.trim() === "" ||
        !mailPattern.test(fields.twoFactorAuthenticationEmail)
      ) {
        formIsValid = false;
        error["twoFactorAuthenticationEmail"] =
          "Please enter Two Factor Authentication Email";
      }
      if (AuthenticationPhone?.length <= 9 && AuthenticationPhone !== "") {
        formIsValid = false;
        error["twoFactorAuthenticationPhone"] = "Please enter valid Phone";
      } else {
        error["twoFactorAuthenticationPhone"] = "";
      }
    }

    return {
      errors: error,
      formIsValid: formIsValid,
    };
  };

  const validateLoginForm = () => {
    let response = validateLogin();
    setErrors(response.errors);
    return response.formIsValid;
  };

  const uploadImg = async (image) => {
    if (!image) {
      return;
    }
    const formData = new FormData();
    formData.append("image", image);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Account/UploadImage`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {})
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const getImg = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Account/GetImage`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Domain: `${pathname}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        const reader = new FileReader();
        const imageBlob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        reader.onloadend = () => {
          setFile(reader.result);
          dispatch(set_user_img(reader.result));
        };
        reader.readAsDataURL(imageBlob);
      })
      .catch((error) => console.log(error));
  };

  const cancelEdit = () => {
    dispatch(setAdminSavePopup({ adminSavePopup: false }));
    setisEditable(false);
    setErrors({});
    getImg();
    getData();
    EntityList();
    GroupList();
    setimageUploadValidation("");
  };

  const roleList = async () => {
    await httpService
      .get("api/person/masterDropDownValuesGet", {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((val) => {
        setRoles(val.data.data.roles || []);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const setGroupTableData = (data) => {
    const arr = [];
    data.map((val, i) => {
      arr.push({
        "Group Name": val?.groupName,
        userId: val?.userId,
        groupId: val?.groupId,
        ID: i,
      });
    });
    return arr;
  };

  const AllGroupList = async () => {
    await httpService
      .get(`api/user/get-all-groups`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((val) => {
        setGroupData(setGroupTableData(val?.data.data));
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const GroupList = async () => {
    await httpService
      .get(`api/user-groups/get-all?userId=${gUserId}`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((val) => {
        setGroupResult(setGroupTableData(val?.data.data));
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const AddGroupList = async () => {
    let userId = gUserId;
    let modifiedGroupData = groupResult.map((item) => {
      return { ...item, userId };
    });
    if (modifiedGroupData.length === 0) {
      modifiedGroupData = [
        {
          groupId: "",
          userId: userId,
        },
      ];
    }
    await httpService
      .post(`api/user-groups/add`, modifiedGroupData, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((val) => {})
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const setEntityTableData = (data) => {
    const arr = [];
    data.map((val, i) => {
      arr.push({
        Entity: val?.entityName,
        userId: val?.userId,
        entityId: val?.entityId,
        Default: val?.isDefault,
        isDefault: val?.isDefault,
        ID: i,
      });
    });
    return arr;
  };

  const AllEntityList = async () => {
    await httpService
      .get(`api/user/get-all-entities`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((val) => {
        setEntityData(setEntityTableData(val?.data.data));
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const EntityList = async () => {
    await httpService
      .get(`api/user-entities/get-all?userId=${gUserId}`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((val) => {
        setEntityResult(setEntityTableData(val?.data.data));
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const AddEntityList = async () => {
    let userId = gUserId;
    let modifiedEntityData = entityResult.map((item) => {
      return { ...item, userId };
    });
    if (modifiedEntityData.length === 0) {
      modifiedEntityData = [
        {
          entityId: "",
          userId: userId,
        },
      ];
    }
    await httpService
      .post(`api/user-entities/add`, modifiedEntityData, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((val) => {})
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const getData = async () => {
    const pathname = handleGetLocal("pathURL");
    setLoading(true);
    await httpService
      .get(`api/user/getUserById?id=${gUserId}`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((val) => {
        if (val.data.isSuccess) {
          setpreviousRoleName(val?.data?.data?.gRoleId);
          setFields({
            ...fields,
            email: val?.data?.data?.sEmail,
            lastName: val?.data?.data?.sLastName
              ? val?.data?.data?.sLastName
              : "",
            firstName: val?.data?.data?.sFirstName
              ? val?.data?.data?.sFirstName
              : "",
            middleName: val?.data?.data?.sMiddleName
              ? val?.data?.data?.sMiddleName
              : "",
            userID: val?.data?.data?.sUserId ? val?.data?.data?.sUserId : "",
            password: val?.data?.data?.sPassword,
            roleId: val?.data?.data?.gRoleId || "",
            userRole: val?.data?.data?.roleName,
            employeeID: val?.data?.data?.sEmployeeId
              ? val?.data?.data?.sEmployeeId
              : "",
            activeUser: val?.data?.data?.bActive,
            courtUser: val?.data?.data?.bCourt,
            resourceUser: val?.data?.data?.bResource,
            stateUser: val?.data?.data?.sState ? val?.data?.data?.sState : "",
            cityUser: val?.data?.data?.sCity ? val?.data?.data?.sCity : "",
            zipUser: val?.data?.data?.sZip ? val?.data?.data?.sZip : "",
            addressUser: val?.data?.data?.sAddress1
              ? val?.data?.data?.sAddress1
              : "",
            enableAuth: val?.data?.data?.bTwoFactorEnabled,
            twoFactorAuthenticationEmail: val?.data?.data?.sTwoFactorEmail
              ? val?.data?.data?.sTwoFactorEmail
              : "",
            twoFactorAuthenticationPhone: val?.data?.data?.sTwoFactorTextNumber
              ? val?.data?.data?.sTwoFactorTextNumber
              : "",
          });
          setLoading(false);
          dispatch(
            AddAuthenticationPhone({
              AuthenticationPhone: val?.data?.data?.sTwoFactorTextNumber,
            })
          );
        }
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const saveUserData = async () => {
    const data = {
      gUserId: gUserId,
      dDoe: "0001-01-01T00:00:00",
      gEntryUserId: null,
      sUserId: fields.userID,
      sPassword: fields.password,
      sFirstName: fields.firstName,
      sLastName: fields.lastName,
      sMiddleName: fields.middleName,
      bActive: fields.activeUser,
      sProfileSkin: null,
      iColor: null,
      mPhoto: null,
      bShowCookie: false,
      gRoleId: fields.roleId,
      bSysop: false,
      bResource: fields.resourceUser,
      sEmployeeId: fields.employeeID,
      sEmail: fields.email,
      dLastLogin: null,
      sAddress1: fields.addressUser,
      sAddress2: null,
      sCity: fields.cityUser,
      sState: fields.stateUser,
      sZip: fields.zipUser,
      sPhone: null,
      sNote: null,
      bCourt: fields.courtUser,
      gCashDrawerId: null,
      sReceiptPrinter: null,
      sTwoFactorTextNumber: AuthenticationPhone,
      sTwoFactorEmail: fields.twoFactorAuthenticationEmail,
      bTwoFactorEnabled: fields.enableAuth,
      sTwoFactorVc: null,
      sTwoFactorVccreated: null,
      sTwoFactorVcexpired: null,
      defaultLocation: null,
      roleName: fields.userRole,
    };
    try {
      setLoading(true);
      const response = await httpService.post(
        "api/User/SaveAndEditUserDetail",
        data,
        { headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` } }
      );
      if (response.data.isSuccess) {
        await uploadImg(image);
        await AddGroupList();
        await AddEntityList();
        await getImg();
        await getData();
        await EntityList();
        await GroupList();
        if (previousRoleName !== fields.roleId) {
          logout();
          window.location.reload();
        }
        dispatch(setAdminSavePopup({ adminSavePopup: false }));
        dispatch(
          set_user_name(
            `${fields.firstName ? fields.firstName : ""} ${
              fields.lastName ? fields.lastName : ""
            }`
          )
        );
        setLoading(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Successfully Updated!",
          type: "success",
        });
        // dispatch(
        //   AddAuthenticationPhone({
        //     AuthenticationPhone: "",
        //   })
        // );
      }
    } catch (error) {
      const errorMessage = error.response.data.Message;
      if (errorMessage.includes("User ID already exist")) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          userID: errorMessage,
        }));
      } else if (errorMessage.includes("Email already exist")) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: errorMessage,
        }));
      }
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    if (validateLoginForm()) {
      saveUserData();
      setisEditable(!isEditable);
    }
  };

  useEffect(() => {
    getImg();
    getData();
    EntityList();
    GroupList();
    roleList();
    AllGroupList();
    AllEntityList();
  }, []);

  return (
    <>
      <Toast
        visible={toastConfig.isVisible}
        position={"top"}
        maxWidth={400}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
          });
        }}
        displayTime={600}
      />
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Popup
        visible={isResetPass}
        onHiding={() => {
          handleReset(false);
        }}
        showTitle={false}
        width={650}
        height={455}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <ResetPassword
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          isResetPass={isResetPass}
          handleReset={handleReset}
        />
      </Popup>
      <div className="mainTabWrapper admin-profile-section">
        <div className="tabwapper">
          <DynamicTabb />
          <div className="admin-profile-information-container">
            <div className="utf">
              <p className="utf_heading">US.10</p>
            </div>
            <div className="admin-profile-information-buttons">
              <div
                className="reset-button"
                onClick={() => setIsResetPass(!isResetPass)}
              >
                Reset Password
                <svg
                  className="svg-blue-dark"
                  width="16"
                  height="21"
                  viewBox="0 0 16 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12ZM14 7H13V5C13 2.24 10.76 0 8 0C5.72 0 3.73 1.54 3.16 3.75C3.02 4.29 3.34 4.83 3.88 4.97C4.41 5.11 4.96 4.79 5.1 4.25C5.44 2.93 6.63 2 8 2C9.65 2 11 3.35 11 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM14 18C14 18.55 13.55 19 13 19H3C2.45 19 2 18.55 2 18V10C2 9.45 2.45 9 3 9H13C13.55 9 14 9.45 14 10V18Z"
                    fill="#424167"
                  />
                </svg>
              </div>
              {isEditable === false ? (
                <button
                  className="primary_btn"
                  onClick={() => {
                    setisEditable(true);
                  }}
                >
                  Edit
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                  >
                    <path
                      d="M0 15.4601V18.5001C0 18.7801 0.22 19.0001 0.5 19.0001H3.54C3.67 19.0001 3.8 18.9501 3.89 18.8501L14.81 7.94006L11.06 4.19006L0.15 15.1001C0.0500001 15.2001 0 15.3201 0 15.4601ZM17.71 5.04006C18.1 4.65006 18.1 4.02006 17.71 3.63006L15.37 1.29006C14.98 0.900059 14.35 0.900059 13.96 1.29006L12.13 3.12006L15.88 6.87006L17.71 5.04006Z"
                      fill="white"
                    />
                  </svg>
                </button>
              ) : (
                <div className="buttons">
                  <button
                    className="btn"
                    onClick={() => {
                      cancelEdit();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="primary_btn"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M16 10V15C16 15.55 15.55 16 15 16H3C2.45 16 2 15.55 2 15V10C2 9.45 1.55 9 1 9C0.45 9 0 9.45 0 10V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V10C18 9.45 17.55 9 17 9C16.45 9 16 9.45 16 10ZM10 9.67L11.88 7.79C12.27 7.4 12.9 7.4 13.29 7.79C13.68 8.18 13.68 8.81 13.29 9.2L9.7 12.79C9.31 13.18 8.68 13.18 8.29 12.79L4.7 9.2C4.31 8.81 4.31 8.18 4.7 7.79C5.09 7.4 5.72 7.4 6.11 7.79L8 9.67V1C8 0.45 8.45 0 9 0C9.55 0 10 0.45 10 1V9.67Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              )}
            </div>
            <div className="admin-profile-information-content">
              <div className="first-edit-content">
                <div className="profilePic">
                  <div className="avatar-upload">
                    <div className="avatar-edit">
                      <input
                        disabled={!isEditable}
                        type="file"
                        onChange={(e) => {
                          handleImgChange(e.target.files[0]);
                        }}
                        id="imageUpload"
                        ref={inputRef}
                        name="image"
                        accept=".png, .jpg, .jpeg"
                      />
                      {isEditable && <label for="imageUpload"></label>}
                    </div>
                    <div
                      onDragOver={(e) => {
                        isEditable && handleDragOver(e);
                      }}
                      onDrop={(e) => {
                        isEditable && handleDrop(e);
                      }}
                      className="avatar-preview"
                    >
                      {fields.image !== "" ? (
                        <div id="imagePreview">
                          {file && <img src={file} alt="profile" />}
                        </div>
                      ) : (
                        <div
                          id="imagePreview"
                          style={{
                            backgroundImage: `url(${file ? file : image5})`,
                            border: "1px solid #dbdbdb",
                            backgroundColor: "#dbdbdb",
                          }}
                        ></div>
                      )}
                      {imageUploadValidation && (
                        <p style={{ color: "red" }}>{imageUploadValidation}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="inputs-container">
                    <div className="edit-input">
                      <label className="go-to-label-filed">
                        User ID <span className="edit-input-span">*</span>
                      </label>
                      <input
                        className="edit-input-field"
                        type="text"
                        placeholder="Enter User ID"
                        name="userID"
                        id="userID"
                        onChange={(e) => {
                          handleChange(e);
                          dispatch(setAdminSavePopup({ adminSavePopup: true }));
                        }}
                        value={fields.userID}
                        disabled={true}
                      />
                      <p className="error" style={{ color: "red" }}>
                        {errors.userID}
                      </p>
                    </div>
                    <div className="edit-input">
                      <label className="go-to-label-filed">
                        First Name <span className="edit-input-span">*</span>
                      </label>
                      <input
                        className="edit-input-field"
                        type="text"
                        placeholder="Enter First Name"
                        name="firstName"
                        id="firstName"
                        onChange={(e) => {
                          handleChange(e);
                          dispatch(setAdminSavePopup({ adminSavePopup: true }));
                        }}
                        value={fields.firstName}
                        disabled={!isEditable}
                      />
                      <p className="error" style={{ color: "red" }}>
                        {errors.firstName}
                      </p>
                    </div>
                    <div className="edit-input">
                      <label className="go-to-label-filed">Middle Name</label>
                      <input
                        className="edit-input-field"
                        type="text"
                        placeholder="Enter Middle Name"
                        name="middleName"
                        id="middleName"
                        onChange={(e) => {
                          handleChange(e);
                          dispatch(setAdminSavePopup({ adminSavePopup: true }));
                        }}
                        value={fields.middleName}
                        disabled={!isEditable}
                      />
                    </div>
                    <div className="edit-input">
                      <label className="go-to-label-filed">
                        Last Name <span className="edit-input-span">*</span>
                      </label>
                      <input
                        className="edit-input-field"
                        type="text"
                        placeholder="Enter Last Name"
                        name="lastName"
                        id="lastName"
                        onChange={(e) => {
                          handleChange(e);
                          dispatch(setAdminSavePopup({ adminSavePopup: true }));
                        }}
                        value={fields.lastName}
                        disabled={!isEditable}
                      />
                      <p className="error" style={{ color: "red" }}>
                        {errors.lastName}
                      </p>
                    </div>
                    <div className="edit-input">
                      <label className="go-to-label-filed">
                        Email <span className="edit-input-span">*</span>
                      </label>
                      <input
                        className="edit-input-field"
                        type="email"
                        placeholder="Enter Email"
                        name="email"
                        id="email"
                        onChange={(e) => {
                          handleChange(e);
                          dispatch(setAdminSavePopup({ adminSavePopup: true }));
                        }}
                        value={fields.email}
                        disabled={true}
                      />
                      <p className="error" style={{ color: "red" }}>
                        {errors.email}
                      </p>
                    </div>
                    <div className="edit-input">
                      <label className="go-to-label-filed">Employee ID</label>
                      <input
                        className="edit-input-field"
                        type="text"
                        placeholder="Enter Employee ID"
                        name="employeeID"
                        id="employeeID"
                        onChange={(e) => {
                          handleChange(e);
                          dispatch(setAdminSavePopup({ adminSavePopup: true }));
                        }}
                        value={fields.employeeID}
                        disabled={!isEditable}
                      />
                    </div>
                    <div className="edit-input">
                      <label className="go-to-label-filed">
                        Role <span className="edit-input-span">*</span>
                      </label>
                      <div>
                        <select
                          onChange={(e) => {
                            roleChange(e);
                            dispatch(
                              setAdminSavePopup({ adminSavePopup: true })
                            );
                          }}
                          className="admin-role-select"
                          disabled={!isEditable}
                        >
                          <option value="" selected={fields.roleId === ""}>
                            -- Select --
                          </option>
                          {roles.map((role) => (
                            <option
                              key={role.gRoleId}
                              selected={fields.roleId === role.gRoleId}
                              value={role.gRoleId}
                            >
                              {role.sName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <p className="error" style={{ color: "red" }}>
                        {errors.userRole}
                      </p>
                    </div>
                  </div>
                  <div className="edit-checkbox-container">
                    <div className="edit-checkbox">
                      <input
                        type="checkbox"
                        name="activeUser"
                        id="activeUser"
                        onChange={(e) => {
                          handleChange(e);
                          dispatch(setAdminSavePopup({ adminSavePopup: true }));
                        }}
                        checked={fields.activeUser}
                        disabled={!isEditable}
                      />
                      <label htmlFor="activeUser" className="go-to-label-filed">
                        Active User
                      </label>
                    </div>
                    <div className="edit-checkbox">
                      <input
                        type="checkbox"
                        name="resourceUser"
                        id="resourceUser"
                        onChange={(e) => {
                          handleChange(e);
                          dispatch(setAdminSavePopup({ adminSavePopup: true }));
                        }}
                        checked={fields.resourceUser}
                        disabled={!isEditable}
                      />
                      <label
                        htmlFor="resourceUser"
                        className="go-to-label-filed"
                      >
                        Resource
                      </label>
                    </div>
                    <div className="edit-checkbox">
                      <input
                        type="checkbox"
                        name="courtUser"
                        id="courtUser"
                        onChange={(e) => {
                          handleChange(e);
                          dispatch(setAdminSavePopup({ adminSavePopup: true }));
                        }}
                        checked={fields.courtUser}
                        disabled={!isEditable}
                      />
                      <label htmlFor="courtUser" className="go-to-label-filed">
                        Court
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="second-edit-content">
                <div className="enable-auth">
                  <input
                    type="checkbox"
                    name="enableAuth"
                    id="enableAuth"
                    checked={fields.enableAuth}
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(setAdminSavePopup({ adminSavePopup: true }));
                    }}
                    disabled={!isEditable}
                  />
                  <label htmlFor="enableAuth" className="go-to-label-filed">
                    Enable Two factor Authentication
                  </label>
                </div>
                {fields.enableAuth && (
                  <>
                    <div className="enable_auth_line"></div>
                    <p className="enable_auth_para">
                      Select where you want to get Two factor Authentication
                      code.
                    </p>
                    <div className="enable_div">
                      <div className="enable_auth_email">
                        <div className="enable_auth_email_data">
                          <div className="radio-group">
                            <label
                              className="go-to-label-filed"
                              htmlFor="twoFactorEmail"
                            >
                              Mail <span>*</span>
                            </label>
                          </div>
                          <input
                            type="email"
                            placeholder="Enter Email address"
                            name="twoFactorAuthenticationEmail"
                            id="twoFactorAuthenticationEmail"
                            onChange={(e) => {
                              handleChange(e);
                              dispatch(
                                setAdminSavePopup({ adminSavePopup: true })
                              );
                            }}
                            value={fields.twoFactorAuthenticationEmail}
                            disabled={!isEditable || !fields.enableAuth}
                          />
                          <p className="error" style={{ color: "red" }}>
                            {errors.twoFactorAuthenticationEmail}
                          </p>
                        </div>
                      </div>
                      <div className="enable_auth_email">
                        <div className="enable_auth_email_data">
                          <div className="radio-group">
                            <label
                              className="go-to-label-filed"
                              htmlFor="twoFactorPhone"
                            >
                              Mobile number
                            </label>
                          </div>
                          <TextBox
                            validationError={false}
                            showMaskMode="always"
                            name="twoFactorAuthenticationPhone"
                            id="twoFactorAuthenticationPhone"
                            maskRules={{ H: /^[0-9*]$/ }}
                            mask="(HHH) HHH-HHHH"
                            isValid={true}
                            value={String(AuthenticationPhone).replace(
                              /\s/g,
                              ""
                            )}
                            onValueChange={(e) => {
                              dispatch(
                                setAdminSavePopup({ adminSavePopup: true })
                              );
                              const sanitizedPhoneNumber = e.replace(/\s/g, "");
                              if (
                                sanitizedPhoneNumber.length <= 9 &&
                                sanitizedPhoneNumber.length !== 0
                              ) {
                                errors["twoFactorAuthenticationPhone"] =
                                  "Please enter valid Phone";
                              } else {
                                errors["twoFactorAuthenticationPhone"] = "";
                              }
                              dispatch(
                                AddAuthenticationPhone({
                                  AuthenticationPhone: e.replace(/\s/g, ""),
                                })
                              );
                            }}
                            disabled={!isEditable || !fields.enableAuth}
                          />
                          <p className="error" style={{ color: "red" }}>
                            {errors.twoFactorAuthenticationPhone}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="third-edit-content">
                <div className="admin-tabs">
                  <div
                    className={
                      activeTab === "tab1" ? "active admin-tab" : "admin-tab"
                    }
                    onClick={() => handleTabClick("tab1")}
                  >
                    Address
                  </div>
                  <div
                    className={
                      activeTab === "tab3" ? "active admin-tab" : "admin-tab"
                    }
                    onClick={() => handleTabClick("tab3")}
                  >
                    Groups
                  </div>
                  <div
                    className={
                      activeTab === "tab4" ? "active admin-tab" : "admin-tab"
                    }
                    onClick={() => handleTabClick("tab4")}
                  >
                    Entities
                  </div>
                </div>
                <div className="admin_tab_content">
                  {activeTab === "tab1" && (
                    <div className="admin-tabs-content-data">
                      <div className="admin-tabs-content-data-input">
                        <label className="go-to-label-filed">Address</label>
                        <input
                          className="edit-input-field"
                          type="text"
                          placeholder="Enter Address"
                          name="addressUser"
                          id="addressUser"
                          onChange={(e) => {
                            handleChange(e);
                            dispatch(
                              setAdminSavePopup({ adminSavePopup: true })
                            );
                          }}
                          value={fields.addressUser}
                          disabled={!isEditable}
                        />
                        <p className="error" style={{ color: "red" }}>
                          {errors.addressUser}
                        </p>
                      </div>
                      <div className="admin-tabs-content-data-input">
                        <label className="go-to-label-filed">City</label>
                        <input
                          className="edit-input-field"
                          type="text"
                          placeholder="Enter City"
                          name="cityUser"
                          id="cityUser"
                          onChange={(e) => {
                            handleChange(e);
                            dispatch(
                              setAdminSavePopup({ adminSavePopup: true })
                            );
                          }}
                          value={fields.cityUser}
                          disabled={!isEditable}
                        />
                        <p className="error" style={{ color: "red" }}>
                          {errors.cityUser}
                        </p>
                      </div>
                      <div className="admin-tabs-content-data-input">
                        <label className="go-to-label-filed">State</label>
                        <input
                          className="edit-input-field"
                          type="text"
                          placeholder="Enter State"
                          name="stateUser"
                          id="stateUser"
                          onChange={(e) => {
                            handleChange(e);
                            dispatch(
                              setAdminSavePopup({ adminSavePopup: true })
                            );
                          }}
                          value={fields.stateUser}
                          disabled={!isEditable}
                        />
                        <p className="error" style={{ color: "red" }}>
                          {errors.stateUser}
                        </p>
                      </div>
                      <div className="admin-tabs-content-data-input">
                        <label className="go-to-label-filed">Zip</label>
                        <input
                          className="edit-input-field"
                          type="number"
                          placeholder="Enter Zip"
                          name="zipUser"
                          id="zipUser"
                          onChange={(e) => {
                            handleChange(e);
                            dispatch(
                              setAdminSavePopup({ adminSavePopup: true })
                            );
                          }}
                          value={fields.zipUser}
                          disabled={!isEditable}
                        />
                        <p className="error" style={{ color: "red" }}>
                          {errors.zipUser}
                        </p>
                      </div>
                    </div>
                  )}
                  {activeTab === "tab3" && (
                    <div className="admin-tabs-content-data">
                      <Groups
                        checkEditable={isEditable}
                        gropuData={gropuData}
                        setGroupData={setGroupData}
                        setGroupTableData={setGroupTableData}
                        setGroupResult={setGroupResult}
                        groupResult={groupResult}
                      />
                    </div>
                  )}
                  {activeTab === "tab4" && (
                    <div className="admin-tabs-content-data">
                      <Entities
                        checkEditable={isEditable}
                        setEntityData={setEntityData}
                        entityData={entityData}
                        setEntityTableData={setEntityTableData}
                        setEntityResult={setEntityResult}
                        entityResult={entityResult}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
