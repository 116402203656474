import { ChecklistTab } from "../../../Dockect/overview/ChecklistTab";

export const GOTOObligationCHeckLIst = ({ dockerValue }) => {
  return (
    <>
      <ChecklistTab
        person={3}
        getTopDynamicDocketid={dockerValue}
        AdCHeckLIst={'Obligation'}
      />
    </>
  );
};
