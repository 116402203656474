import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table2 } from "../../../../../components/Table2/Table2";
import { ApiError } from "../../../../Error/ApiError";
import { LoadPanel } from "devextreme-react";

const ActivityTableColumn = [
  { title: "d DOE", date: true },
  { title: "s Last First" },
  { title: "s Activity" },
  { title: "s Name" },
  { title: "s Sequence" },
  { title: "s Item" },
];

export const ShowACtivityCHecklist = ({ setShowACtivitypopup, activityData, errorhandling3, loading2 }) => {
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  return (
    <>
      <LoadPanel shadingColor="true" visible={loading2} delay={10} />
      {errorhandling3 ? (
        <ApiError />
      ) : (
        <div
          style={{
            width: "100%",
            height: "498px",
            border: "3px solid var(--button2)",
          }}
        >
          <div className="CreateAppointmentPopup">
            <div className="RecivePaymentModel_header">
              <div style={{ width: "200px" }} className="RecivePaymentModel_header_name">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C13.91 14.69 13.28 14.69 12.89 14.3L10 11.41L7.11 14.3C6.72 14.69 6.09 14.69 5.7 14.3C5.31 13.91 5.31 13.28 5.7 12.89L8.59 10L5.7 7.11C5.31 6.72 5.31 6.09 5.7 5.7C6.09 5.31 6.72 5.31 7.11 5.7L10 8.59L12.89 5.7C13.28 5.31 13.91 5.31 14.3 5.7C14.69 6.09 14.69 6.72 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z"
                    fill="white"
                  />
                </svg>

                <p style={{ marginLeft: "0px" }}>Show Activity</p>
                <span
                  style={{
                    padding: "2px 9px",
                    gap: "8px",
                    width: "60px",
                    height: "24px",
                    background: "#FEF2F2",
                    borderRadius: "4px",
                  }}
                >
                  <p
                    style={{
                      color: "#DC2626",
                      fontFamily: "Readex Pro",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "10px",
                      lineHeight: "20px",
                      width: "21px",
                      height: "20px",
                    }}
                  >
                    UT.13
                  </p>
                </span>
              </div>

              <svg
                onClick={() => {
                  setShowACtivitypopup(false);
                }}
                style={{
                  cursor: "pointer",
                  marginTop: "17px",
                  marginRight: "2%",
                }}
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                  fill="white"
                />
              </svg>
            </div>

            <div className="ChecklistTabbuttons">
              <button
                onClick={() => {
                  setexportPopupOpen(true);
                }}
                className="btn"
              >
                Export
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_5796_5470)">
                    <path
                      d="M16.59 9H15V4C15 3.45 14.55 3 14 3H10C9.45 3 9 3.45 9 4V9H7.41C6.52 9 6.07 10.08 6.7 10.71L11.29 15.3C11.68 15.69 12.31 15.69 12.7 15.3L17.29 10.71C17.92 10.08 17.48 9 16.59 9ZM5 19C5 19.55 5.45 20 6 20H18C18.55 20 19 19.55 19 19C19 18.45 18.55 18 18 18H6C5.45 18 5 18.45 5 19Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5796_5470">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button className="btn" onClick={() => {}}>
                Print
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_5985_7014)">
                    <path
                      d="M19 8H5C3.34 8 2 9.34 2 11V15C2 16.1 2.9 17 4 17H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V17H20C21.1 17 22 16.1 22 15V11C22 9.34 20.66 8 19 8ZM15 19H9C8.45 19 8 18.55 8 18V14H16V18C16 18.55 15.55 19 15 19ZM19 12C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10C19.55 10 20 10.45 20 11C20 11.55 19.55 12 19 12ZM17 3H7C6.45 3 6 3.45 6 4V6C6 6.55 6.45 7 7 7H17C17.55 7 18 6.55 18 6V4C18 3.45 17.55 3 17 3Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5985_7014">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button
                onClick={() => {
                  setShowACtivitypopup(false);
                }}
                className="btn"
              >
                Close
              </button>
            </div>
            <div style={{ margin: "10px" }} className="ShowActivitytable">
              <Table2
                Tablecoloum={ActivityTableColumn}
                tableRow={activityData}
                editTable={false}
                load={false}
                coloumWidthText={"ShowActivitytable"}
                height={300}
                mandatoryColoum={"d DOE"}
                showTotalBlock={false}
                exporttitle={"Checklist Activity"}
                exportPopupOpen={exportPopupOpen}
                setexportPopupOpen={setexportPopupOpen}
                exportInDifferentFormate={true}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
