import { useState } from "react";
import "./paymentCredits.css";
import axios from "axios";
import { Table1 } from "../../../../../Administration/Table1/Table1";
import { paymentTabelColumn } from "../Data";
import { LoadPanel, Popup } from "devextreme-react";
import { VoidCashPayments } from "./paymentsCreditsPopup/VoidCashPayments";
import { EditVoidCashPayment } from "./paymentsCreditsPopup/EditVoidCashPayment";
import { VoidRefundCCPayment } from "./paymentsCreditsPopup/VoidRefundCCPayment";
import ReallocatePaymentPopup from "../../../Financial/components/overviewPopUp/ReallocatePaymentPopup";
import { handleGetLocal } from "../../../../../../services/auth-service";

export const PaymentCredits = ({
  paymentCreditsTableData,
  toastConfig,
  setToastConfig,
  refreshGetPaymentCreditsTableData,
  paymentCreditsDropdownData,
  userInfoDetails,
  userData,
}) => {
  const pathname = handleGetLocal("pathURL");
  const [loading, setLoading] = useState(false);
  const [rowInfoData, setRowInfoData] = useState([]);
  const [voidCashPaymentPopup, setVoidCashPaymentPopup] = useState(false);
  const [editVoidCashPaymentPopup, setEditVoidCashPaymentPopup] =
    useState(false);
  const [voidRefundCCPaymentPopup, setVoidRefundCCPaymentPopup] =
    useState(false);
  const [reallocatePaymentPopup, setReallocatePaymentPopup] = useState(false);

  const handleReceiptNumber = (receiptNumber) => {
    console.log('here---', receiptNumber);
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_CRYSTAL_API_URL}/reports-api/payment/get-report-by-rn?receiptNumber=${receiptNumber}&reportFolder=${null}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
          responseType: "blob",
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.size > 20) {
          const pdfBlob = new Blob([val.data], {
            type: "application/pdf",
          });
          const pdfUrl = URL.createObjectURL(pdfBlob);
          const downloadLink = document.createElement("a");
          downloadLink.href = pdfUrl;
          downloadLink.download = "PY1_Receipt" + ".pdf";
          downloadLink.style.display = "none";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      })
      .catch((val) => {
        setLoading(false);
      });
  }

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Popup
        visible={voidCashPaymentPopup}
        onHiding={() => {
          setVoidCashPaymentPopup(false);
        }}
        showTitle={false}
        width={800}
        height={450}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <VoidCashPayments
          voidCashPaymentPopup={voidCashPaymentPopup}
          setVoidCashPaymentPopup={setVoidCashPaymentPopup}
          rowInfoData={rowInfoData}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          setLoading={setLoading}
          refreshGetPaymentCreditsTableData={refreshGetPaymentCreditsTableData}
        />
      </Popup>
      <Popup
        visible={editVoidCashPaymentPopup}
        onHiding={() => {
          setEditVoidCashPaymentPopup(false);
        }}
        showTitle={false}
        width={768}
        height={670}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <EditVoidCashPayment
          editVoidCashPaymentPopup={editVoidCashPaymentPopup}
          setEditVoidCashPaymentPopup={setEditVoidCashPaymentPopup}
          rowInfoData={rowInfoData}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          setLoading={setLoading}
          refreshGetPaymentCreditsTableData={refreshGetPaymentCreditsTableData}
          paymentCreditsDropdownData={paymentCreditsDropdownData}
        />
      </Popup>
      <Popup
        visible={voidRefundCCPaymentPopup}
        onHiding={() => {
          setVoidRefundCCPaymentPopup(false);
        }}
        showTitle={false}
        width={768}
        height={650}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <VoidRefundCCPayment
          voidRefundCCPaymentPopup={voidRefundCCPaymentPopup}
          setVoidRefundCCPaymentPopup={setVoidRefundCCPaymentPopup}
          rowInfoData={rowInfoData}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          setLoading={setLoading}
          refreshGetPaymentCreditsTableData={refreshGetPaymentCreditsTableData}
          paymentCreditsDropdownData={paymentCreditsDropdownData}
          userInfoDetails={userInfoDetails}
          userData={userData}
        />
      </Popup>
      <Popup
        visible={reallocatePaymentPopup}
        onHiding={() => {
          setReallocatePaymentPopup(false);
        }}
        showTitle={false}
        width={768}
        height={410}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <ReallocatePaymentPopup
          reallocatePaymentPopup={reallocatePaymentPopup}
          setReallocatePaymentPopup={setReallocatePaymentPopup}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          fieldID={rowInfoData?.gPaymentId}
          typePopup={"payment"}
          refreshApi={refreshGetPaymentCreditsTableData}
        />
      </Popup>
      <div className="payment-credits-tab">
        <div
          className="payment-credits-tab-data"
          style={{ width: "calc(100% - 17px)" }}
        >
          <Table1
            editTable={false}
            height={400}
            Tablecoloum={paymentTabelColumn}
            tableRow={paymentCreditsTableData?.map((val, i) => {
              return { ...val, ID: i };
            })}
            load={loading}
            coloumWidthText={"Payment"}
            mandatoryColoum={"DOE"}
            PaymentCreditsMenu={true}
            setRowInfoData={setRowInfoData}
            setVoideFalse={true}
            setVoidCashPaymentPopup={setVoidCashPaymentPopup}
            setEditVoidCashPaymentPopup={setEditVoidCashPaymentPopup}
            setVoidRefundCCPaymentPopup={setVoidRefundCCPaymentPopup}
            setReallocatePaymentPopup={setReallocatePaymentPopup}
            handleReceiptNumber={handleReceiptNumber}
          />
        </div>
      </div>
    </>
  );
};
