import { useEffect, useState } from "react";
import { Table2 } from "../../../../components/Table2/Table2";
import axios from "axios";
import { handleGetLocal } from "../../../../services/auth-service";
import { useNavigate, useParams } from "react-router-dom";
import LoadPanel from "devextreme-react/load-panel";
import { setcontectInfoTabWhileAddParticipent, setcontectInfoTableCount } from "../../../../reducer/ParticipateReducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { logOut } from "../../../../components/Logout";
import { ApiError } from "../../../Error/ApiError";

const Tablecoloum = [
  {
    title: "Primary",
    bool: true,
    editable: true,
  },
  {
    title: "Contact Information",
    requiredColoumWhileEdit: true,
    editable: true,
  },
  {
    title: "Contact Type",
    dropdown: true,
    dropdownData: [{ name: "Email" }, { name: "Phone" }],
    editable: true,
  },
  { title: "Comment", editable: true },
  {
    title: "Effective",
    date: true,
    editable: true,
  },
  {
    title: "End",
    date: true,
    editable: true,
  },
];

export const Detail_Contact_Tab = ({
  userinfo,
  editable,
  editParticipant,
  errorhandling,
  saveContact,
  setSaveContact,
  setGoodToSaveParticipant,
  AddParticipant,
}) => {
  const pathname = handleGetLocal("pathURL");
  const [data, setdata] = useState([]);
  const idd = useParams();
  const { contectInfoTabWhileAddParticipent, contectTableAddData } = useSelector((store) => store.participate);

  const [loading, setLoading] = useState(false);

  const [tabledata, newrowData] = useState({ data: [], name: "" });

  const setTableData = (dataa) => {
    const arr = [];
    dataa.map((val) => {
      arr.push({
        Primary: val?.bPrimary,
        "Contact Information": val?.sInformation.replace(/^\+1/, "").replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"),
        "Contact Type": val?.sContactInfoTypeName,
        Comment: val?.sComment,
        Effective: val?.dEffective,
        End: val?.dEnd,
        ID: val?.gPersonContactInfoId,
        gContactInfoTypeID: val?.gContactInfoTypeId,
      });
    });

    return arr;
  };
  const setTableDataa = (dataa) => {
    const arr = [];
    dataa.map((val) => {
      arr.push({
        Primary: val?.Primary,
        "Contact Information": val["Contact Information"]
          ? val["Contact Information"]?.replace(/^\+1/, "")?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
          : "",

        "Contact Type": val["Contact Type"] ? val["Contact Type"] : "",
        Comment: val?.Comment,
        Effective: val?.Effective,
        End: val?.End,
        ID: val?.ID,
      });
    });

    return arr;
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [contactInfoType, setcontactInfoType] = useState([]);

  const getalldropdownn = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Person/masterDropDownValuesGet`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setcontactInfoType(
          val.data.data.contactInfoType.map((val) => {
            return {
              id: val?.gContactInfoTypeId,
              name: val?.sName,
            };
          })
        );
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const getalldropdown = async (gPersonId) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/User/personDetailContactInfo?gPersonId=${gPersonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setdata(setTableData(val.data.data));
        dispatch(setcontectInfoTableCount(val.data.data.length));

        getalldropdownn();
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const saveTable = async (body) => {
    if (editParticipant) {
      setLoading(true);
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/Person/editSaveAndDeletePersonContactInfoDetail`, body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        })
        .then((val) => {
          setLoading(false);
          getalldropdown(userinfo.gPersonId);
        })
        .catch((val) => {
          setLoading(false);
          getalldropdown(userinfo.gPersonId);
          if (val?.response?.status == 401) {
            logOut();
          } else {
            if (val?.response?.status) {
              navigate(`/error/500`);
            } else {
              navigate("/error/500");
            }
          }
        });
    }
  };

  useEffect(() => {
    if (editParticipant) {
      userinfo.gPersonId && getalldropdown(userinfo.gPersonId);
    } else {
      setdata(setTableDataa(contectTableAddData));
    }
  }, [idd.id, userinfo?.gPersonId]);

  const ContectTypeDropDown = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Person/masterDropDownValuesGet`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setcontactInfoType(
          val.data.data.contactInfoType.map((val) => {
            return {
              id: val?.gContactInfoTypeId,
              name: val?.sName,
            };
          })
        );
      });
  };

  useEffect(() => {
    if (!editParticipant) {
      ContectTypeDropDown();
    }
  }, []);

  const saveContactData = (contactData) => {
    if (editParticipant) {
      if (contactData.name === "edit") {
        saveTable({
          gPersonContactInfoID: contactData?.data?.oldData?.ID,
          gPersonID: userinfo?.gPersonId,
          sInformation: contactData?.data?.newData["Contact Information"]
            ? /\w*[a-zA-Z]\w*/.test(contactData?.data?.newData["Contact Information"])
              ? contactData?.data?.newData["Contact Information"]
              : contactData?.data?.newData["Contact Information"].replace(/\D/g, "")
            : contactData?.data?.oldData["Contact Information"] !== null
            ? /\w*[a-zA-Z]\w*/.test(contactData?.data?.oldData["Contact Information"])
              ? contactData?.data?.oldData["Contact Information"]
              : contactData?.data?.oldData["Contact Information"].replace(/\D/g, "")
            : "",
          bPrimary: contactData?.data?.newData.Primary
            ? contactData?.data?.newData.Primary
            : contactData?.data?.oldData.Primary !== null
            ? contactData?.data?.oldData.Primary
            : "",
          gContactInfoTypeID: contactInfoType.find((val) => {
            if (
              val.name === contactData?.data?.newData["Contact Type"] ||
              val.name === contactData?.data?.oldData["Contact Type"]
            ) {
              return val;
            }
          })?.id,
          dEffective: contactData?.data?.newData.Effective
            ? contactData?.data?.newData.Effective
            : contactData?.data?.oldData.Effective !== null
            ? contactData?.data?.oldData.Effective
            : "",
          dEnd: contactData?.data?.newData.End
            ? contactData?.data?.newData.End
            : contactData?.data?.oldData.End !== null
            ? contactData?.data?.oldData.End
            : "",
          sComment: contactData?.data?.newData.Comment
            ? contactData?.data?.newData.Comment
            : contactData?.data?.oldData.Comment !== null
            ? contactData?.data?.oldData.Comment
            : "",
          isDelete: "no",
        });
      } else if (contactData.name === "insert") {
        saveTable({
          gPersonContactInfoID: "",
          gPersonID: userinfo?.gPersonId,
          sInformation: contactData?.data?.data["Contact Information"]
            ? /\w*[a-zA-Z]\w*/.test(contactData?.data?.data["Contact Information"])
              ? contactData?.data?.data["Contact Information"]
              : contactData?.data?.data["Contact Information"].replace(/\D/g, "")
            : "",
          bPrimary: contactData?.data?.data?.Primary ? contactData?.data?.data?.Primary : false,
          gContactInfoTypeID: contactInfoType.find((val) => {
            if (val.name === contactData?.data?.data["Contact Type"]) {
              return val;
            }
          })?.id,
          dEffective: contactData?.data?.data?.Effective ? contactData?.data?.data?.Effective : "",
          dEnd: contactData?.data?.data?.End ? contactData?.data?.data?.End : "",
          sComment: contactData?.data?.data?.Comment ? contactData?.data?.data?.Comment : "",
          isDelete: "no",
        });
      } else if (contactData.name === "delete") {
        saveTable({
          gPersonContactInfoID: contactData?.data?.data?.ID,
          gPersonID: userinfo?.gPersonId,
          sInformation: contactData?.data?.data["Contact Information"],
          bPrimary: contactData?.data?.data?.Primary,
          gContactInfoTypeID:
            contactData?.data?.data["Contact Type"]?.toLowerCase().trim() === "email"
              ? "DCBDE1D1-8789-E011-89ED-00508D98A9EE"
              : "DEBDE1D1-8789-E011-89ED-00508D98A9EE",
          dEffective: contactData?.data?.data?.Effective ? contactData?.data?.data?.Effective : "",
          dEnd: contactData?.data?.data?.End ? contactData?.data?.data?.End : "",
          sComment: contactData?.data?.data?.Comment,
          isDelete: "Yes",
        });
      }

      if (saveContact) setGoodToSaveParticipant(true);
    } else {
      if (saveContact) setGoodToSaveParticipant(true);
      dispatch(
        setcontectInfoTabWhileAddParticipent(
          contectTableAddData.map((val) => {
            return {
              gPersonContactInfoID: "",
              gPersonID: "",
              sInformation: val["Contact Information"] ? val["Contact Information"] : "",
              bPrimary: val.Primary ? val.Primary : false,
              gContactInfoTypeID: contactInfoType.find((vall) => {
                if (vall.name === val["Contact Type"]) {
                  return val;
                }
              })?.id,
              dEffective: val.Effective
                ? new Date(new Date(val.Effective).toLocaleDateString()).toLocaleDateString("en-CA")
                : "",
              dEnd: val.End ? new Date(new Date(val.End).toLocaleDateString()).toLocaleDateString("en-CA") : "",
              sComment: val.Comment ? val.Comment : "",
              isDelete: "no",
            };
          })
        )
      );
    }
  };

  useEffect(() => {
    // if (editParticipant) {
    //   if (tabledata.name === "edit") {
    //     saveTable({
    //       gPersonContactInfoID: tabledata?.data?.oldData?.ID,
    //       gPersonID: userinfo?.gPersonId,
    //       sInformation: tabledata?.data?.newData["Contact Information"]
    //         ? /\w*[a-zA-Z]\w*/.test(tabledata?.data?.newData["Contact Information"])
    //           ? tabledata?.data?.newData["Contact Information"]
    //           : tabledata?.data?.newData["Contact Information"].replace(/\D/g, "")
    //         : tabledata?.data?.oldData["Contact Information"] !== null
    //         ? /\w*[a-zA-Z]\w*/.test(tabledata?.data?.oldData["Contact Information"])
    //           ? tabledata?.data?.oldData["Contact Information"]
    //           : tabledata?.data?.oldData["Contact Information"].replace(/\D/g, "")
    //         : "",
    //       bPrimary: tabledata?.data?.newData.Primary
    //         ? tabledata?.data?.newData.Primary
    //         : tabledata?.data?.oldData.Primary !== null
    //         ? tabledata?.data?.oldData.Primary
    //         : "",
    //       gContactInfoTypeID: contactInfoType.find((val) => {
    //         if (
    //           val.name === tabledata?.data?.newData["Contact Type"] ||
    //           val.name === tabledata?.data?.oldData["Contact Type"]
    //         ) {
    //           return val;
    //         }
    //       })?.id,
    //       dEffective: tabledata?.data?.newData.Effective
    //         ? tabledata?.data?.newData.Effective
    //         : tabledata?.data?.oldData.Effective !== null
    //         ? tabledata?.data?.oldData.Effective
    //         : "",
    //       dEnd: tabledata?.data?.newData.End
    //         ? tabledata?.data?.newData.End
    //         : tabledata?.data?.oldData.End !== null
    //         ? tabledata?.data?.oldData.End
    //         : "",
    //       sComment: tabledata?.data?.newData.Comment
    //         ? tabledata?.data?.newData.Comment
    //         : tabledata?.data?.oldData.Comment !== null
    //         ? tabledata?.data?.oldData.Comment
    //         : "",
    //       isDelete: "no",
    //     });
    //   } else if (tabledata.name === "insert") {
    //     saveTable({
    //       gPersonContactInfoID: "",
    //       gPersonID: userinfo?.gPersonId,
    //       sInformation: tabledata?.data?.data["Contact Information"]
    //         ? /\w*[a-zA-Z]\w*/.test(tabledata?.data?.data["Contact Information"])
    //           ? tabledata?.data?.data["Contact Information"]
    //           : tabledata?.data?.data["Contact Information"].replace(/\D/g, "")
    //         : "",
    //       bPrimary: tabledata?.data?.data?.Primary ? tabledata?.data?.data?.Primary : false,
    //       gContactInfoTypeID: contactInfoType.find((val) => {
    //         if (val.name === tabledata?.data?.data["Contact Type"]) {
    //           return val;
    //         }
    //       })?.id,
    //       dEffective: tabledata?.data?.data?.Effective ? tabledata?.data?.data?.Effective : "",
    //       dEnd: tabledata?.data?.data?.End ? tabledata?.data?.data?.End : "",
    //       sComment: tabledata?.data?.data?.Comment ? tabledata?.data?.data?.Comment : "",
    //       isDelete: "no",
    //     });
    //   } else if (tabledata.name === "delete") {
    //     saveTable({
    //       gPersonContactInfoID: tabledata?.data?.data?.ID,
    //       gPersonID: userinfo?.gPersonId,
    //       sInformation: tabledata?.data?.data["Contact Information"],
    //       bPrimary: tabledata?.data?.data?.Primary,
    //       gContactInfoTypeID:
    //         tabledata?.data?.data["Contact Type"]?.toLowerCase().trim() === "email"
    //           ? "DCBDE1D1-8789-E011-89ED-00508D98A9EE"
    //           : "DEBDE1D1-8789-E011-89ED-00508D98A9EE",
    //       dEffective: tabledata?.data?.data?.Effective ? tabledata?.data?.data?.Effective : "",
    //       dEnd: tabledata?.data?.data?.End ? tabledata?.data?.data?.End : "",
    //       sComment: tabledata?.data?.data?.Comment,
    //       isDelete: "Yes",
    //     });
    //   }
    // } else {
    //   if (saveContact) setGoodToSaveParticipant(true);
    //   dispatch(
    //     setcontectInfoTabWhileAddParticipent(
    //       contectTableAddData.map((val) => {
    //         return {
    //           gPersonContactInfoID: "",
    //           gPersonID: "",
    //           sInformation: val["Contact Information"] ? val["Contact Information"] : "",
    //           bPrimary: val.Primary ? val.Primary : false,
    //           gContactInfoTypeID: contactInfoType.find((vall) => {
    //             if (vall.name === val["Contact Type"]) {
    //               return val;
    //             }
    //           })?.id,
    //           dEffective: val.Effective
    //             ? new Date(new Date(val.Effective).toLocaleDateString()).toLocaleDateString("en-CA")
    //             : "",
    //           dEnd: val.End ? new Date(new Date(val.End).toLocaleDateString()).toLocaleDateString("en-CA") : "",
    //           sComment: val.Comment ? val.Comment : "",
    //           isDelete: "no",
    //         };
    //       })
    //     )
    //   );
    // }
    saveContactData(tabledata);
  }, [tabledata, contectTableAddData]);

  return (
    <>
      <div>
        <LoadPanel shadingColor="true" visible={loading} delay={10} />

        <div className="contect_info_tab">
          {errorhandling ? (
            <ApiError />
          ) : (
            <Table2
              Tablecoloum={Tablecoloum}
              tableRow={data}
              load={false}
              coloumWidthText={"DetailContactTab"}
              height={300}
              editTable={true}
              newrowData={saveContactData}
              tabledata={tabledata}
              mandatoryColoum={"Contact Type"}
              contactInfoType={contactInfoType}
              editParticipant={editable}
              showTotalBlock={false}
              saveContact={saveContact}
              setSaveContact={setSaveContact}
              setGoodToSaveParticipant={setGoodToSaveParticipant}
              AddParticipant={AddParticipant}
            />
          )}
        </div>
      </div>
    </>
  );
};
