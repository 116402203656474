import { Attachment } from "../../Attachment/Attachment";

export const GoTOObligationAttachment = ({
  dataa,
  loadinggg,
  userinfo,
  attachmentfunction,
}) => {
  return (
    <>
      <div style={{ margin: "-1.5%" }}>
        <Attachment
          dataa={dataa}
          attachmentfunction={attachmentfunction}
          loadinggg={loadinggg}
          userinfo={userinfo}
        />
      </div>
    </>
  );
};
