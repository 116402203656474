import { useEffect, useState } from "react";

export const TopTab = ({ data, setdocketId }) => {
  const [tab, setTab] = useState(0);
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    if (data.length) {
      const arr = [];
      let newArray = [];

      data.map((val) => {
        if (!arr.includes(val.gSupervisionStatusId)) {
          arr.push(val.gSupervisionStatusId);
          newArray.push({ ...val, count: 1 });
        } else {
          newArray = newArray.map((vall) => {
            if (vall.gSupervisionStatusId === val.gSupervisionStatusId) {
              return { ...val, count: vall.count + 1 };
            } else {
              return vall;
            }
          });
        }
      });
      setNewData(
        newArray.sort((a, b) =>
          a.sSupervisionStatusName > b.sSupervisionStatusName
            ? 1
            : b.sSupervisionStatusName > a.sSupervisionStatusName
            ? -1
            : 0
        )
      );
      setdocketId(newArray[0].gSupervisionStatusId);
    }
    setTab(0)
  }, [data]);

  return (
    <>
      <div className="topTabb">
        <div className="topTab">
          {data.length
            ? newData.map((val, i) => {
                
                return (
                  <>
                    <div
                      onClick={() => {
                        setTab(i);
                        setdocketId(val.gSupervisionStatusId);
                      }}
                      style={{
                        borderBottom: tab === i && "4px solid rgb(66, 65, 103)",
                        backgroundColor: val.bActiveStatus ? "rgb(144,144,199)" : "",
                        borderRadius: '4px'
                      }}
                    >
                      <p
                        style={{
                          color: tab === i ? (val.bActiveStatus? "var(--input-white-black)" : "var(--tab-blue-white)") : "",
                          padding: "0 1px",
                          margin: "16px",
                          fontWeight:'bold'
                        }}
                      >
                        {val?.sSupervisionStatusName?val?.sSupervisionStatusName:'NO STATUS'}({val?.count})
                      </p>
                    </div>
                  </>
                );
              })
            : ""}
        </div>
      </div>
    </>
  );
};
