import { useEffect, useState } from "react";
// import { TopDynamicTab } from "../../TopDynamicTab";
// import { useSelector } from "react-redux";
import { GoTODocketHeader } from "./GoTODocketHeader";

import "./GoToDocket.css";
import { Toster } from "../../../../components/Toster/Toster";
import { DynamicTabb } from "../../../../components/DynamicTab/DynamicTab";

export const GOToDocket = () => {
  // const [tabSwitch, setTabSwitch] = useState(2);
  // const { dynamicParticipatTab } = useSelector((store) => store.participate);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  return (
    <>
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <div className="particcipentPage">
        {/* <TopDynamicTab
          setTabSwitch={setTabSwitch}
          tabSwitch={tabSwitch}
          text={dynamicParticipatTab}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
        /> */}
        <DynamicTabb />
        <GoTODocketHeader toastConfig={toastConfig} setToastConfig={setToastConfig} />
      </div>
    </>
  );
};
