import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import httpService from "../../../services/http-service";
import { getAuthToken } from "../../../services/auth-service";
import { useDispatch } from "react-redux";
import { set_admin_table } from "../../../reducer/AdminReadReducer";
import { Toast } from "devextreme-react";

export const Delete = ({ setIsPopupVisible, tableDataApi }) => {
  const {
    selected_row,
    table_name,
    table_id,
    replace_headers_title,
    headers_title,
    foreignKeyData,
  } = useSelector((store) => store.AdminTable);
  let domainName = (url) =>
    url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
  let domailURL = window.location.href;
  let pathURL = domainName(domailURL);
  const token = getAuthToken();
  const [errorMessage, setErrorMessage] = useState(null);

  const dispatch = useDispatch();
  const tableDataId = table_id;
  const transformedDatas = {};
  let deleteRowData = selected_row[0];
  const fields = [];
  const componentRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setErrorMessage(null);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const dataChange = () => {
    const caption = replace_headers_title;
    const columns = headers_title;
    Object.keys(deleteRowData).forEach((key) => {
      const captionIndex = caption.indexOf(key);
      if (captionIndex !== -1) {
        transformedDatas[columns[captionIndex]] = deleteRowData[key];
      } else {
        transformedDatas[key] = deleteRowData[key];
      }
    });
    delete transformedDatas.ID;
    for (const key in transformedDatas) {
      fields.push({
        FieldName: key,
        FieldValue: transformedDatas[key],
      });
    }
  };

  const deleteData = async () => {
    try {
      dataChange();
      await httpService
        .post(
          `api/Administration/BDECRUD`,
          {
            TableName: table_name,
            EntityState: 3,
            Fields: fields,
          },
          {
            headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` },
          }
        )
        .then((response) => {
          if (!response.data.isSuccess === false) {
            setIsPopupVisible(false);
            tableDataApi(tableDataId);
            setToastConfig({
              ...toastConfig,
              isVisible: true,
              message: "Successfully Deleted!",
              type: "success",
            });
          } else {
            setErrorMessage(response.data.errors);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Toast
        visible={toastConfig.isVisible}
        position={"top"}
        width={"auto"}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
          });
        }}
        displayTime={600}
      />
      <div className="admin_Add_Table" ref={componentRef}>
        <div className="admin_Add_Table_head">
          <div className="admin_Add_Table_head_title">
            <div className="admin_Add_Table_head_title_data">
              {errorMessage === null ? (
                <>
                  <svg
                    width="14"
                    height="18"
                    viewBox="0 0 14 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginTop: "13px", marginRight: "2%" }}
                  >
                    <path
                      d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
                      fill="white"
                    />
                  </svg>
                  <p>Delete</p>
                </>
              ) : (
                <span>Record could not be deleted!</span>
              )}
            </div>
            {!errorMessage && (
              <p className="admin_Add_Table_head_para">UT.11</p>
            )}
          </div>
          <svg
            onClick={() => {
              setIsPopupVisible(false);
              setErrorMessage(null);
            }}
            style={{ cursor: "pointer", marginTop: "17px", marginRight: "2%" }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        {errorMessage === null ? (
          <>
            <p className="delete_content_sub_heading">
              Are you sure, you want to Delete?
            </p>
            <div
              className="popup_buttons"
              style={{ marginTop: "20px", marginRight: "20px" }}
            >
              <button
                className="btn"
                onClick={() => {
                  setIsPopupVisible(false);
                }}
              >
                No
              </button>
              <button className="primary_btn" onClick={deleteData}>
                Yes, Delete
              </button>
            </div>
          </>
        ) : (
          <p className="erorr_delete">{errorMessage}</p>
        )}
      </div>
    </>
  );
};
