import axios from "axios";
import { logOut } from "../../../../../../components/Logout";
import { handleGetLocal } from "../../../../../../services/auth-service";

export class GoToDocketPaymentPlanFunction {
  constructor() {
    this.pathname = handleGetLocal("pathURL");
  }

  async goToDocketPaymentPlanApiCall(
    setLoad,
    seterrorhandling,
    setData,
    navigate,
    docketValue
  ) {
    setLoad(true);

    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Financial/getPaymentPlan?gDocketId=${docketValue?.getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoad(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          setData(this.setPaymentTableData(val?.data?.data));
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoad(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  }

  convertDateFormat(dateString) {
    if (dateString) {
      const dateObj = new Date(dateString);

      return dateObj.toLocaleDateString("en-US");
    } else {
      return "";
    }
  }
  setPaymentTableData(data) {
    const arr = [];

    data.map((val, i) => {
      arr.push({
        "Pay Date": this.convertDateFormat(val?.dPay),

        "Payment Due": val?.fAmount,

        Paid: val?.fPaid,

        "Total Paid": val?.fTotalPaid,

        "Total Due": val?.fTotalDue,

        Balance: val?.fBalance,

        Status: val?.bIsTolled,

        bStrikeOut: val.bStrikeOut,

        ID: i,
      });
    });

    return arr;
  }
}
