
export function getLastDayOfMonth(year, month) {
 
  // These are the last days of each month on index based
  const lastDays = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
 
  if(month != 2) {
    return lastDays[month]
  }
 
  return isLeapYear(year) ? 29 : 28;
}
 
 
 
 
export function isLeapYear(year) {
  // A year is a leap year if it is divisible by 4,
  // but not divisible by 100 unless it is also divisible by 400.
  return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
}