const convertDateFormat = (dateString) => {
  if (dateString) {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleDateString("en-US");
  } else {
    return "";
  }
};

export const drugTestScheduleTableTitle = [
  { title: "Test Date", date: true },
  { title: "Test Time" },
  { title: "Test Slot" },
  { title: "Location" },
  { title: "Offender Number" },
  { title: "Person Name" },
  { title: "Test Frequency" },
  { title: "Supervisor" },
  { title: "Last Name" },
  { title: "DOB", date: true },
  { title: "Last Log Message" },
  { title: "Gender" },
  { title: "Phone" },
  { title: "Warrant" },
  { title: "Void", bool: true },
];

export const createsTestDatesTableTitle = [
  { title: "Date", date: true },
  { title: "Day" },
  { title: "Slot 1", bool: true, testDateCheckBox1: true },
  { title: "Slot 2", bool: true, testDateCheckBox2: true },
  { title: "Slot 3", bool: true, testDateCheckBox3: true },
  { title: "Slot 4", bool: true, testDateCheckBox4: true },
  { title: "Week" },
];

export const setDrugTestScheduleTableColumnData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Test Date": convertDateFormat(val?.sTestDate),
      "Test Time": val?.sTestTime,
      "Test Slot": val?.iSlot,
      Location: val?.sTestLocation,
      "Offender Number": val?.sOffenderNumber,
      "Person Name": val?.sLastFirst,
      "Test Frequency": val?.sFrequencyName,
      Supervisor: val?.sPrimarySupervisorName,
      "Last Name": val?.sLastName,
      DOB: convertDateFormat(val?.dDOB),
      "Last Log Message": val?.sLogMessage,
      Gender: val?.sGender,
      Phone: val?.sPhone,
      Warrant: val?.a,
      Void: val?.bVoid,
      gDocketID: val?.gDocketID,
      gDrugTestRandomizerId: val?.gDrugTestRandomizerId,
      gDrugTestRandomizerScheduleId: val?.gDrugTestRandomizerScheduleId,
      gPersonID: val?.gPersonID,
      gTestDateId: val?.gTestDateId,
      gEntryUserId: val?.gEntryUserId,
      ID: i,
    });
  });
  return arr;
};
