import { DateBox, LoadPanel, Popup, SelectBox } from "devextreme-react";
import MonitoringCrumbs from "./components/MonitoringCrumbs";
import { Toster } from "../../components/Toster/Toster";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MonitoringFunction } from "./MonitoringFunction";
import { useNavigate } from "react-router-dom";
import { ApiError } from "../Error/ApiError";
import { Table1 } from "../Administration/Table1/Table1";
import { agencyInvoicesTableColumn } from "./Data";
import ConfirmPopup from "./Popups/ConfirmPopup";
import { DynamicTabb } from "../../components/DynamicTab/DynamicTab";
import { getAccessData } from "../../utils/getAccessPermission";
import { ReportFunction } from "../Report/REportFunction";

const AgencyInvoices = () => {
  const [viewAccess, setViewAccess] = useState(true);
  const [editAccess, setEditAccess] = useState(false);
  const [addAccess, setAddAccess] = useState(false);
  const { userAccessData } = useSelector((store) => store.Home);
  const [agencyInvoicesData, setAgencyInvoicesData] = useState([]);
  const [agencyInvoicesRowData, setAgencyInvoicesRowData] = useState([]);
  const [errorhandling, setErrorhandling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const ApiClass = new MonitoringFunction();
  const reportApis = new ReportFunction();
  const navigate = useNavigate();
  const [date, setdate] = useState({
    startDate: new Date(new Date().setDate(1)).toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
  });
  const [quickDatevalue, setquickDatevalue] = useState("Current Month");
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmPopupType, setConfirmPopupType] = useState("");
  let apiUrl = "";
  let typePara = "";
  const [exportInvoiceToExcelData, setExportInvoiceToExcelData] = useState([]);
  const [selectedRowCheckbox, setSelectedRowCheckbox] = useState([]);

  const refreshApi = () => {
    ApiClass?.getAgencyInvoices(
      setLoading,
      navigate,
      setAgencyInvoicesData,
      setErrorhandling,
      date?.startDate,
      date?.endDate
    );
  };

  useEffect(() => {
    refreshApi();
  }, [date?.startDate, date?.endDate]);

  switch (confirmPopupType) {
    case "Void Invoice":
      apiUrl = `api/monitoring/void-invoice?gBillingID=${agencyInvoicesRowData?.gBillingId}`;
      typePara = "Void Invoice";
      break;
    case "Reallocate Payment":
      apiUrl = `api/monitoring/reallocate-payment?gBillingID=${agencyInvoicesRowData?.gBillingId}`;
      typePara = "Reallocate Payment";
      break;
    default:
      apiUrl = "";
      typePara = "";
  }

  const exportInvoiceToExcelApi = () => {
    if (agencyInvoicesRowData?.[`Invoice #`]) {
      ApiClass.getExportInvoiceToExcelApi(
        setLoading,
        navigate,
        setExportInvoiceToExcelData,
        agencyInvoicesRowData?.[`Invoice #`]
      );
    }
  };

  const handleSelectAll = () => {
    setSelectedRowCheckbox(agencyInvoicesData);
  };

  const handleDeselectAll = () => {
    setSelectedRowCheckbox([]);
  };
  const getUserAccessPermissions = () => {
    const accessData = getAccessData(userAccessData, "OB15");
    setAddAccess(accessData?.Add);
    setEditAccess(accessData?.Edit);
    setViewAccess(accessData?.View);
  };

  useEffect(() => {
    getUserAccessPermissions();
  }, []);

  const printInvoice = () => {
    reportApis.print(
      navigate,
      setLoading,
      {
        sReportName: "EM14_Invoice2.rpt",
        param: [{
          sParameterName: "sInvoiceNumber",
          sParameterValue: agencyInvoicesRowData['Invoice #'],
        }],
      },
      setToastConfig,
      toastConfig
    );
  }

  return (
    <>
      <Popup
        visible={confirmPopup}
        onHiding={() => {
          setConfirmPopup(false);
        }}
        showTitle={false}
        width={450}
        height={170}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <ConfirmPopup
          confirmPopup={confirmPopup}
          setConfirmPopup={setConfirmPopup}
          setLoading={setLoading}
          navigate={navigate}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          refreshApi={refreshApi}
          apiURl={apiUrl}
          typePara={typePara}
        />
      </Popup>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <MonitoringCrumbs title={"Agency Invoices"} />
      <div className="financial-page">
        {/* <MonitoringTabs /> */}
        <DynamicTabb />
        <div className="financial-page-section">
          <p className="page-info-number">OB.15</p>
          {viewAccess ? (
            <div
              style={{
                pointerEvents: editAccess || addAccess ? "auto" : "none",
                opacity: editAccess || addAccess ? 1 : 0.6,
                cursor: editAccess || addAccess ? "default" : "not-allowed",
              }}
            >
              <div className="page-info-buttons">
                <div className="buttons">
                  <button className="btn" onClick={handleSelectAll}>
                    Select All
                  </button>
                  <button className="btn" onClick={handleDeselectAll}>
                    Deselect All
                  </button>
                  <button
                    className="btn"
                    onClick={() => {
                      setexportPopupOpen(true);
                    }}
                  >
                    Export
                    <svg
                      className="svg-blue-white"
                      width="14"
                      height="17"
                      viewBox="0 0 14 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                        fill="#424167"
                      />
                    </svg>
                  </button>
                  <button
                    className="btn"
                    onClick={() => {
                      refreshApi();
                      setErrorhandling(false);
                    }}
                  >
                    Refresh
                    <svg
                      className="svg-blue-white"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_6247_15093)">
                        <path
                          d="M17.6493 6.34902C16.0193 4.71902 13.7093 3.77902 11.1693 4.03902C7.49929 4.40902 4.47929 7.38902 4.06929 11.059C3.51929 15.909 7.26929 19.999 11.9993 19.999C15.1893 19.999 17.9293 18.129 19.2093 15.439C19.5293 14.769 19.0493 13.999 18.3093 13.999C17.9393 13.999 17.5893 14.199 17.4293 14.529C16.2993 16.959 13.5893 18.499 10.6293 17.839C8.40929 17.349 6.61929 15.539 6.14929 13.319C5.30929 9.43902 8.25929 5.99902 11.9993 5.99902C13.6593 5.99902 15.1393 6.68902 16.2193 7.77902L14.7093 9.28902C14.0793 9.91902 14.5193 10.999 15.4093 10.999H18.9993C19.5493 10.999 19.9993 10.549 19.9993 9.99902V6.40902C19.9993 5.51902 18.9193 5.06902 18.2893 5.69902L17.6493 6.34902Z"
                          fill="#424167"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6247_15093">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
              </div>
              <div className="page-details-search-content">
                <div className="display-flex-div">
                  <label className="edit_container_label">Start Date</label>
                  <DateBox
                    useMaskBehavior={true}
                    value={date.startDate}
                    onValueChange={(e) => {
                      if (e) {
                        const initialDateString = e;
                        const initialDate = new Date(initialDateString);
                        const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                        const utcFormatted = new Date(utcTime).toISOString();
                        setdate({
                          ...date,
                          startDate: e ? utcFormatted : null,
                        });
                      }
                    }}
                  />
                </div>
                <div className="display-flex-div">
                  <label className="edit_container_label">End Date</label>
                  <DateBox
                    useMaskBehavior={true}
                    value={date.endDate}
                    onValueChange={(e) => {
                      if (e) {
                        const initialDateString = e;
                        const initialDate = new Date(initialDateString);
                        const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                        const utcFormatted = new Date(utcTime).toISOString();
                        setdate({
                          ...date,
                          endDate: e ? utcFormatted : null,
                        });
                      }
                    }}
                  />
                </div>
                <div className="display-flex-div">
                  <label className="edit_container_label">Quick Date</label>
                  <SelectBox
                    dataSource={[{ name: "Current Month" }, { name: "Prior Month" }]}
                    valueExpr="name"
                    displayExpr="name"
                    searchEnabled={true}
                    searchExpr={"name"}
                    value={quickDatevalue}
                    dropDownOptions={{ height: "180px" }}
                    onItemClick={(e) => {
                      setquickDatevalue(e?.itemData?.name);
                      let startDate = null;
                      let endDate = null;
                      if (e && e.itemData && e.itemData.name) {
                        switch (e.itemData.name) {
                          case "Current Month":
                            startDate = new Date();
                            startDate.setDate(1);
                            endDate = new Date();
                            break;
                          case "Prior Month":
                            startDate = new Date();
                            startDate.setMonth(startDate.getMonth() - 1);
                            startDate.setDate(1); // Set to the first day of the previous month
                            endDate = new Date(startDate);
                            endDate.setMonth(endDate.getMonth() + 1);
                            endDate.setDate(0); // Set to the last day of the previous month
                            break;
                          default:
                            startDate = new Date();
                            endDate = new Date();
                            break;
                        }
                      } else {
                        // Handle the case when e, e.itemData, or e.itemData.name is null or undefined
                        startDate = new Date();
                        endDate = new Date();
                      }

                      setdate({
                        startDate: startDate.toISOString().slice(0, 10),
                        endDate: endDate.toISOString().slice(0, 10),
                      });
                    }}
                  />
                </div>
              </div>
              <div className="table-section">
                {errorhandling ? (
                  <ApiError />
                ) : (
                  <Table1
                    editTable={false}
                    height={800}
                    Tablecoloum={agencyInvoicesTableColumn}
                    tableRow={agencyInvoicesData}
                    load={loading}
                    coloumWidthText={"Agency Invoices"}
                    mandatoryColoum={"Agency"}
                    exportInDifferentFormate={true}
                    exportPopupOpen={exportPopupOpen}
                    setexportPopupOpen={setexportPopupOpen}
                    exportwidth={27}
                    exporttitle={"Agency Invoices"}
                    monitoringAgencyInvoicesMenu={true}
                    setVoideFalse={true}
                    setAgencyInvoicesRowData={setAgencyInvoicesRowData}
                    setConfirmPopup={setConfirmPopup}
                    setConfirmPopupType={setConfirmPopupType}
                    exportInvoiceToExcelApi={exportInvoiceToExcelApi}
                    setSelectedRowCheckbox={setSelectedRowCheckbox}
                    selectedRowCheckbox={selectedRowCheckbox}
                    printInvoice={printInvoice}
                  />
                )}
              </div>
            </div>
          ) : (
            "No view access"
          )}
        </div>
      </div>
    </>
  );
};

export default AgencyInvoices;
