import { DateBox, LoadPanel, NumberBox, Popup, ScrollView, TextBox } from "devextreme-react";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { handleGetLocal } from "../../../../../services/auth-service";
import { logOut } from "../../../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { ApiError } from "../../../../Error/ApiError";
import { headersidebarfunction } from "../../../../HeaderSideBar/headersidebarfunction";
import HelpPopup from "../../../../HeaderSideBar/HelpPopup";
import { useDispatch } from "react-redux";
import { setRecentObligation, setRecentDocket } from "../../../../../reducer/AddParticipantWizardReducer";

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  fieldNmae,
  reset,
  QuickAddObligationFeeCode,
  setgObligationTypeID,
  setgFeeScheduleCategoryID,
  setgDisburseEntityID,
  setgDisburseMethodID,
  setgDisburseFrequencyID,
  setgBankAccountID,
  setiDisbursePercent,
  setsLocalLegalCodeExtra,
  setfFee,
  setFeeDecription,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        reset={reset}
        margin={"7px"}
        QuickAddObligationFeeCode={QuickAddObligationFeeCode}
        setgObligationTypeID={setgObligationTypeID}
        setgFeeScheduleCategoryID={setgFeeScheduleCategoryID}
        setgDisburseEntityID={setgDisburseEntityID}
        setgDisburseMethodID={setgDisburseMethodID}
        setgDisburseFrequencyID={setgDisburseFrequencyID}
        setgBankAccountID={setgBankAccountID}
        setiDisbursePercent={setiDisbursePercent}
        setsLocalLegalCodeExtra={setsLocalLegalCodeExtra}
        setfFee={setfFee}
        setFeeDecription={setFeeDecription}
      />
    </>
  );
};

export const QuickaddObligationPopup = ({
  setgetQuickaddObligationPopup,
  getQuickaddObligationPopup,
  dropdownData,
  docketId,
  toastConfig,
  setToastConfig,
  header,
  callObligationApi,
  setCallObligationApi,
  updateDocketManagement,
}) => {
  const { resetReportDates, on_Save_Edit } = useSelector((store) => store.participateNotes);

  const dispatch = useDispatch();

  const [FeeCode, setFeeCode] = useState([]);
  const [Financialstatus, setFinancialstatus] = useState([]);
  const [PaymentAgency, setPaymentAgency] = useState([]);
  const [DisburseFrequency, setDisburseFrequency] = useState([]);
  const [BankAccount, setBankAccount] = useState([]);
  const [Obligationtype, setObligationtype] = useState([]);
  const [RecipientCategory, setRecipientCategory] = useState([]);
  const [FeeDecription, setFeeDecription] = useState([]);
  const [Entity, setEntity] = useState([]);
  const [DisburseMethod, setDisburseMethod] = useState([]);
  const pathname = handleGetLocal("pathURL");

  const [BankAccountvalidaton, setBankAccountvalidaton] = useState("");
  const [Entityvalidaton, setEntityvalidaton] = useState("");
  const [PaymentAgencyvalidaton, setPaymentAgencyvalidaton] = useState("");
  const [DisburseFrequencyvalidaton, setDisburseFrequencyvalidaton] = useState("");
  const [DisburseMethodvalidaton, setDisburseMethodvalidaton] = useState("");
  const [Obligationtypevalidaton, setObligationtypevalidaton] = useState("");

  const [recipientCategoryValidation, setRecipientCategoryValidation] = useState("");
  const [feeCodeValidation, setFeeCodeValidation] = useState("");

  const [errorhandling, seterrorhandling] = useState(false);
  const [loading, setLoading] = useState(false);

  const [helpPopup, sethelpPopup] = useState(false);

  const [fields, setFields] = useState({
    "Fee Code": { name: "", id: "" },
    "Obligation type": { name: "", id: "" },
    "Obligation Ref #": 0.0,
    "Recipient Category": { name: "", id: "" },
    "Financial status": {
      name: "Active",
      id: "342EC677-C5C0-E111-BE66-00508D98A9EE",
    },
    "Obligation Start Date": new Date().toISOString().split("T")[0] + "T00:00:00",
    "Original Amount": 0,
    "Adjusted Amount": 0.0,
    "Payment Agency (Entity)": { name: "", id: "" },
    "Sentence Notes": "",
    "Disburse Frequency": { name: "", id: "" },
    "Disburse Method": { name: "", id: "" },
    "Disburse %": 0.0,
    "Bank Account": { name: "", id: "" },
    Entity: { name: "", id: "" },
    feediscription: "",
  });
  const navigate = useNavigate();

  const OnSave = async (body) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/docket/save-quick-obligation`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((res) => {
        const obligation = res?.data?.data;

        dispatch(setRecentObligation(obligation));

        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Successfully Added!",
          type: "success",
        });

        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Successfully Added!",
            type: "success",
          });
        }, 1400);

        Reset();

        if (setCallObligationApi) {
          setCallObligationApi(!callObligationApi);
        }

        updateDocketManagement();

        setgetQuickaddObligationPopup(false);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (!err?.response) {
          navigate(`/error/500`);
        } else if (err?.response?.status == 401) {
          logOut();
        }
      });
  };

  const Save = () => {
    if (
      fields?.["Fee Code"].id &&
      fields?.["Recipient Category"].id &&
      fields?.["Disburse Frequency"].id &&
      fields?.["Disburse Method"].id &&
      fields?.["Bank Account"].id &&
      fields?.Entity.id &&
      fields?.["Payment Agency (Entity)"].id &&
      fields?.["Obligation type"].id
    ) {
      setDisburseFrequencyvalidaton("");
      setDisburseMethodvalidaton("");
      setBankAccountvalidaton("");
      setPaymentAgencyvalidaton("");
      setObligationtypevalidaton("");
      setEntityvalidaton("");

      OnSave({
        gFeeScheduleFeeID: fields?.["Fee Code"].id,
        fFeeAmount: fields?.["Original Amount"], //
        sFeeRemarks: "string", //
        sReference: fields?.["Obligation Ref #"],
        gDocketID: docketId,
        gObligationTypeId: fields?.["Obligation type"].id,
        gRecipientCategory: fields?.["Recipient Category"].id,
        gFinancialStatus: fields?.["Financial status"].id,
        dObligationStartDate: fields?.["Obligation Start Date"],
        sOriginalAmount: fields?.["Original Amount"],
        gPaymentAgencyId: fields?.["Payment Agency (Entity)"].id,
        gDisburseFrequencyId: fields?.["Disburse Frequency"].id,
        gDisburseMethodId: fields?.["Disburse Method"].id,
        disbursePercentage: fields?.["Disburse %"],
        gBankAccountId: fields?.["Bank Account"].id,
        gEntityId: fields?.Entity.id,
        sPaymentAlert: "",
      });
    } else {
      !fields?.["Fee Code"].id && setFeeCodeValidation("Please Select Fee Code");
      !fields?.["Recipient Category"].id && setRecipientCategoryValidation("Please Select Recipient Category");
      !fields?.["Disburse Frequency"].id && setDisburseFrequencyvalidaton("Disburse Frequency");
      !fields?.["Disburse Method"].id && setDisburseMethodvalidaton("Disburse Method");
      !fields?.["Bank Account"].id && setBankAccountvalidaton("Please Select Bank Account");
      !fields?.Entity.id && setEntityvalidaton(" Please Select Entity");
      !fields?.["Payment Agency (Entity)"].id && setPaymentAgencyvalidaton("Please Select Payment Agency");
      !fields?.["Obligation type"].id && setObligationtypevalidaton("Please Select Obligation type");
    }
  };

  const [gObligationTypeID, setgObligationTypeID] = useState("");
  const [gFeeScheduleCategoryID, setgFeeScheduleCategoryID] = useState("");
  const [gDisburseEntityID, setgDisburseEntityID] = useState("");
  const [gDisburseMethodID, setgDisburseMethodID] = useState("");
  const [gDisburseFrequencyID, setgDisburseFrequencyID] = useState("");
  const [gBankAccountID, setgBankAccountID] = useState("");
  const [iDisbursePercent, setiDisbursePercent] = useState("");
  const [sLocalLegalCodeExtra, setsLocalLegalCodeExtra] = useState("");
  const [fFee, setfFee] = useState("");

  const FeeCodeGEtDropDownApi = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/feecode-dropdown?docketId=${docketId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          if (val?.data?.data?.length)
            setFeeCode(
              val?.data?.data?.map((vall) => {
                return {
                  name: vall?.sQuickAddNameOrLocalLegal,
                  id: vall?.gFeeScheduleID,
                  gObligationTypeID: vall?.gObligationTypeID,
                  gFeeScheduleCategoryID: vall?.gFeeScheduleCategoryID,
                  fFee: vall?.fFee,
                  gDisburseEntityID: vall?.gDisburseEntityID,
                  gDisburseMethodID: vall?.gDisburseMethodID,
                  gDisburseFrequencyID: vall?.gDisburseFrequencyID,
                  iDisbursePercent: vall?.iDisbursePercent,
                  gBankAccountID: vall?.gBankAccountID,
                  sLocalLegalCodeExtra: vall?.sLocalLegalCodeExtra,
                  sLocalViolationDescription: vall?.sLocalViolationDescription,
                  sDDSViolationDescription: vall?.sDDSViolationDescription,
                };
              })
            );
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };
  const getPaymentAgencyDropdown = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=Billing Agency`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.data?.length) {
          setPaymentAgency(
            val?.data?.data?.map((vall) => {
              return {
                name: vall.value,
                id: vall.id ? vall.id : "00000000-0000-0000-0000-000000000000",
              };
            })
          );
        }
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    PaymentAgency.forEach((val) => {
      if (val.name === "_Offender")
        setFields({
          ...fields,
          "Payment Agency (Entity)": val,
        });
    });
  }, [PaymentAgency, getQuickaddObligationPopup]);

  useEffect(() => {
    if (fields?.["Fee Code"]?.id) {
      let data = { name: "", id: "" };
      Obligationtype.map((val) => {
        if (val?.id?.toLowerCase() === gObligationTypeID) {
          data = val;
        }
      });
      let data2 = { name: "", id: "" };
      RecipientCategory.map((val) => {
        if (val?.id?.toLowerCase() === gFeeScheduleCategoryID) {
          data2 = val;
        }
      });
      let data3 = { name: "", id: "" };
      Entity.map((val) => {
        if (val?.id?.toLowerCase() === gDisburseEntityID) {
          data3 = val;
        }
      });
      let data4 = { name: "", id: "" };
      DisburseMethod.map((val) => {
        if (val?.id?.toLowerCase() === gDisburseMethodID) {
          data4 = val;
        }
      });
      let data5 = { name: "", id: "" };
      DisburseFrequency.map((val) => {
        if (val?.id?.toLowerCase() === gDisburseFrequencyID) {
          data5 = val;
        }
      });
      let data6 = { name: "", id: "" };
      BankAccount.map((val) => {
        if (val?.id?.toLowerCase() === gBankAccountID) {
          data6 = val;
        }
      });

      !data5?.id ? setDisburseFrequencyvalidaton("Disburse Frequency") : setDisburseFrequencyvalidaton("");
      !data4?.id && setDisburseMethodvalidaton("Disburse Method");
      !data6?.id && setBankAccountvalidaton("Please Select Bank Account");
      !data3?.id && setEntityvalidaton(" Please Select Entity");
      !data.id && setObligationtypevalidaton("Please Select Obligation type");
      setFields({
        ...fields,
        "Obligation type": data,
        "Recipient Category": data2,
        Entity: data3,
        "Disburse Method": data4,
        "Disburse Frequency": data5,
        "Bank Account": data6,
        "Disburse %": iDisbursePercent,
        feediscription: sLocalLegalCodeExtra,
        "Adjusted Amount": fFee,
        "Fee Decription": FeeDecription,
        "Original Amount": fFee ? fFee : 0,
      });
    }
  }, [fields?.["Fee Code"]?.id]);

  useEffect(() => {
    dropdownData?.recipientCategory?.length &&
      setRecipientCategory(
        dropdownData?.recipientCategory?.map((val) => {
          return { name: val.value, id: val.key };
        })
      );
    dropdownData?.obligationTypes?.length &&
      setObligationtype(
        dropdownData?.obligationTypes?.map((val) => {
          return { name: val.value, id: val.key };
        })
      );
    dropdownData?.financialStatuses?.length &&
      setFinancialstatus(
        dropdownData?.financialStatuses.map((val) => {
          return { name: val.value, id: val.key };
        })
      );

    dropdownData?.disbursedFrequencies?.length &&
      setDisburseFrequency(
        dropdownData?.disbursedFrequencies.map((val) => {
          return { name: val.value, id: val.key };
        })
      );
    dropdownData?.disbursedMethods?.length &&
      setDisburseMethod(
        dropdownData?.disbursedMethods.map((val) => {
          return { name: val.value, id: val.key };
        })
      );
    dropdownData?.bankAccounts?.length &&
      setBankAccount(
        dropdownData?.bankAccounts.map((val) => {
          return { name: val.value, id: val.key };
        })
      );
    dropdownData?.entities?.length &&
      setEntity(
        dropdownData?.entities.map((val) => {
          return { name: val.value, id: val.key };
        })
      );
  }, [dropdownData]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/User/get-company-options?sName=${"Default Financial Status"}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((res) => {
        const defaultFinancialStatus = res.data?.data[0]?.sValue;
        Financialstatus.forEach((status) => {
          if (status.name === defaultFinancialStatus) {
            setFields({ ...fields, "Financial status": status });
          }
        });
      });
  }, [Financialstatus]);

  useEffect(() => {
    docketId && FeeCodeGEtDropDownApi();
  }, [docketId]);

  const Reset = () => {
    setDisburseFrequencyvalidaton("");
    setDisburseMethodvalidaton("");
    setBankAccountvalidaton("");
    setPaymentAgencyvalidaton("");
    setObligationtypevalidaton("");
    setEntityvalidaton("");
    setFields({
      "Fee Code": { name: "", id: "" },
      "Obligation type": { name: "", id: "" },
      "Obligation Ref #": 0.0,
      "Recipient Category": { name: "", id: "" },
      "Financial status": {
        name: "Active",
        id: "342EC677-C5C0-E111-BE66-00508D98A9EE",
      },
      "Obligation Start Date": new Date().toISOString().split("T")[0] + "T00:00:00",
      "Original Amount": 0,
      "Adjusted Amount": 0.0,
      "Payment Agency (Entity)": { name: "", id: "" },
      "Sentence Notes": header?.sSentenceNotes,
      "Disburse Frequency": { name: "", id: "" },
      "Disburse Method": { name: "", id: "" },
      "Disburse %": 0.0,
      "Bank Account": { name: "", id: "" },
      Entity: { name: "", id: "" },
    });
  };

  useEffect(() => {
    Reset();
  }, [resetReportDates]);

  const clas = new headersidebarfunction();
  const [markupid, setmarkupid] = useState();
  const [markup, setmarkup] = useState("");
  const [markupsCaption, setmarkupisCaption] = useState("");
  const [markupsScreenName, setmarkupissScreenNamu] = useState("");
  const [helpPopUp, setHelpPopUp] = useState(false);

  useEffect(() => {
    helpPopUp &&
      clas.getHelp(setLoading, navigate, setmarkup, setmarkupid, "OB7", setmarkupissScreenNamu, setmarkupisCaption);
  }, [helpPopUp]);

  useEffect(() => {
    getPaymentAgencyDropdown();
  }, []);

  return (
    <>
      {markupid && (
        <Popup
          visible={markupid && helpPopUp}
          onHiding={() => {
            setHelpPopUp(false);
          }}
          showTitle={false}
          width={1200}
          height={670}
          showCloseButton={true}
          hideOnOutsideClick={true}
        >
          <HelpPopup
            markup={markup}
            markupid={markupid}
            helpPopUp={helpPopUp}
            setHelpPopUp={setHelpPopUp}
            markupsCaption={markupsCaption}
            markupsScreenName={markupsScreenName}
          />
        </Popup>
      )}
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div
        style={{
          width: "100%",
          height: "848px",
          border: "3px solid var(--button2)",
        }}
        className="CreateAppointmentPopup"
      >
        {errorhandling ? (
          <ApiError />
        ) : (
          <ScrollView>
            <div className="RecivePaymentModel_header">
              <div style={{ width: "220px" }} className="RecivePaymentModel_header_name">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="add_task" clipPath="url(#clip0_5501_7881)">
                    <path
                      id="Vector"
                      d="M21.2931 5.89019L11.2931 15.8902C10.9031 16.2802 10.2731 16.2802 9.88305 15.8902L7.05305 13.0602C6.66305 12.6702 6.66305 12.0402 7.05305 11.6502C7.44305 11.2602 8.07305 11.2602 8.46305 11.6502L10.5831 13.7702L19.8731 4.48019C20.2631 4.09019 20.8931 4.09019 21.2831 4.48019C21.6831 4.87019 21.6831 5.50019 21.2931 5.89019ZM12.0031 20.0002C7.29305 20.0002 3.52305 15.9102 4.05305 11.1002C4.44305 7.58019 7.17305 4.69019 10.6631 4.11019C12.4731 3.81019 14.1931 4.13019 15.6531 4.89019C16.0431 5.09019 16.5131 5.02019 16.8231 4.71019C17.3031 4.23019 17.1831 3.42019 16.5831 3.11019C15.1131 2.36019 13.4531 1.95019 11.6831 2.00019C6.54305 2.16019 2.27305 6.34019 2.01305 11.4702C1.72305 17.2402 6.30305 22.0002 12.0031 22.0002C13.2031 22.0002 14.3431 21.7902 15.4131 21.4002C16.0931 21.1502 16.2831 20.2702 15.7631 19.7502C15.4931 19.4802 15.0831 19.3802 14.7231 19.5202C13.8731 19.8302 12.9531 20.0002 12.0031 20.0002ZM19.0031 15.0002H17.0031C16.4531 15.0002 16.0031 15.4502 16.0031 16.0002C16.0031 16.5502 16.4531 17.0002 17.0031 17.0002H19.0031V19.0002C19.0031 19.5502 19.4531 20.0002 20.0031 20.0002C20.5531 20.0002 21.0031 19.5502 21.0031 19.0002V17.0002H23.0031C23.5531 17.0002 24.0031 16.5502 24.0031 16.0002C24.0031 15.4502 23.5531 15.0002 23.0031 15.0002H21.0031V13.0002C21.0031 12.4502 20.5531 12.0002 20.0031 12.0002C19.4531 12.0002 19.0031 12.4502 19.0031 13.0002V15.0002Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5501_7881">
                      <rect width="24" height="24" fill="black" />
                    </clipPath>
                  </defs>
                </svg>
                <p style={{ marginLeft: "-10px" }}>Quick Add Obligation </p>
                <span
                  style={{
                    padding: "2px 9px",
                    gap: "8px",
                    width: "45px",
                    height: "24px",
                    background: "#FEF2F2",
                    borderRadius: "4px",
                  }}
                >
                  <p
                    style={{
                      color: "#DC2626",
                      fontFamily: "Readex Pro",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "10px",
                      lineHeight: "20px",
                      width: "21px",
                      height: "20px",
                    }}
                  >
                    OB.7
                  </p>
                </span>
              </div>
              <svg
                onClick={() => {
                  setgetQuickaddObligationPopup(false);
                }}
                style={{
                  cursor: "pointer",
                  marginTop: "17px",
                  marginRight: "2%",
                }}
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                  fill="white"
                />
              </svg>
            </div>

            <div style={{ width: "94%", marginLeft: "3%" }}>
              <div className="quickAddObligationn">
                <div>
                  <p>Offender</p>
                  <p
                    style={{
                      marginLeft: "15px",
                    }}
                  >
                    {header?.sPersonNameLastFirst}
                  </p>
                </div>
                <div>
                  <p>Docket</p>
                  <p
                    style={{
                      marginLeft: "15px",
                    }}
                  >
                    {header?.sDocketNumberCourtCounts}
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "25px",
                  marginTop: "3%",
                  marginBottom: "3%",
                }}
              >
                <p style={{ fontWeight: "bold", fontSize: "15px" }}>Obligation</p>
              </div>
              <div className="collectionpopupBLOCK2">
                <div className="collectionpopupBLOCK2inputt">
                  <p>Fee Code</p>
                  <div>
                    <DropDown
                      setfields={setFields}
                      fields={fields}
                      fieldNmae={"Fee Code"}
                      dropDownData={FeeCode}
                      QuickAddObligationFeeCode={true}
                      setgObligationTypeID={setgObligationTypeID}
                      setgFeeScheduleCategoryID={setgFeeScheduleCategoryID}
                      setgDisburseEntityID={setgDisburseEntityID}
                      setgDisburseMethodID={setgDisburseMethodID}
                      setgDisburseFrequencyID={setgDisburseFrequencyID}
                      setgBankAccountID={setgBankAccountID}
                      setiDisbursePercent={setiDisbursePercent}
                      setsLocalLegalCodeExtra={setsLocalLegalCodeExtra}
                      setfFee={setfFee}
                      setFeeDecription={setFeeDecription}
                      reset={setFeeCodeValidation}
                    />
                    <p
                      style={{
                        color: "red",
                        fontWeight: "400",
                        position: "absolute",
                      }}
                    >
                      {feeCodeValidation}
                    </p>
                  </div>
                </div>
                <div className="collectionpopupBLOCK2inputt">
                  <p>Obligation Ref #</p>
                  <div>
                    <NumberBox
                      onKeyDown={(e) => {
                        const { event } = e;
                        const str = event.key || String.fromCharCode(event.which);
                        if (/^[.,e]$/.test(str)) {
                          event.preventDefault();
                        }
                      }}
                      style={{ marginTop: "2%" }}
                      value={fields["Obligation Ref #"]}
                      min={0}
                      disabled={true}
                      onValueChange={(e) => {
                        setFields({ ...fields, "Obligation Ref #": e });
                      }}
                      placeholder={"Obligation Ref #"}
                    />
                  </div>
                </div>
              </div>
              <div className="collectionpopupBLOCK2" style={{ display: "none" }}>
                <div className="collectionpopupBLOCK2inputt">
                  <p>Obligation type</p>
                  <div>
                    <DropDown
                      setfields={setFields}
                      fields={fields}
                      fieldNmae={"Obligation type"}
                      dropDownData={Obligationtype}
                      reset={setObligationtypevalidaton}
                    />
                    <p
                      style={{
                        color: "red",
                        fontWeight: "400",
                        position: "absolute",
                      }}
                    >
                      {Obligationtypevalidaton}
                    </p>
                  </div>
                </div>
                <div className="collectionpopupBLOCK2inputt">
                  <p>Recipient Category</p>
                  <div>
                    <DropDown
                      setfields={setFields}
                      fields={fields}
                      fieldNmae={"Recipient Category"}
                      dropDownData={RecipientCategory}
                      reset={setRecipientCategoryValidation}
                    />
                    <p
                      style={{
                        color: "red",
                        fontWeight: "400",
                        position: "absolute",
                      }}
                    >
                      {recipientCategoryValidation}
                    </p>
                  </div>
                </div>
              </div>
              <div className="collectionpopupBLOCK2">
                <div className="collectionpopupBLOCK2inputt">
                  <p>Financial status</p>
                  <div>
                    <DropDown
                      setfields={setFields}
                      fields={fields}
                      fieldNmae={"Financial status"}
                      dropDownData={Financialstatus}
                    />
                  </div>
                </div>
                <div className="collectionpopupBLOCK2inputt">
                  <p>Obligation Start Date</p>
                  <div>
                    <DateBox
                      style={{ marginTop: "2%" }}
                      value={fields["Obligation Start Date"]}
                      onValueChange={(e) => {
                        setFields({ ...fields, ["Obligation Start Date"]: e });
                      }}
                      useMaskBehavior={true}
                    />
                  </div>
                </div>
              </div>
              <div className="collectionpopupBLOCK2">
                <div className="collectionpopupBLOCK2inputt">
                  <p>Original Amount</p>
                  <div>
                    <NumberBox
                      onKeyDown={(e) => {
                        const { event } = e;
                        const str = event.key || String.fromCharCode(event.which);
                        if (/^[.,e]$/.test(str)) {
                          event.preventDefault();
                        }
                      }}
                      style={{ marginTop: "2%" }}
                      value={fields?.["Original Amount"]}
                      onValueChange={(e) => {
                        setFields({ ...fields, "Original Amount": e });
                      }}
                      min={0}
                    />
                  </div>
                </div>

                <div className="collectionpopupBLOCK2inputt">
                  <p>Fee Description</p>

                  <TextBox
                    style={{ marginTop: "2%" }}
                    value={fields?.feediscription}
                    disabled={true}
                    isValid={true}
                    placeholder={"Fee Decription"}
                  />
                </div>
                {/* <div className="collectionpopupBLOCK2inputt">
                  <p>Adjusted Amount</p>
                  <div>
                    <NumberBox
                      onKeyDown={(e) => {
                        const { event } = e;
                        const str =
                          event.key || String.fromCharCode(event.which);
                        if (/^[.,e]$/.test(str)) {
                          event.preventDefault();
                        }
                      }}
                      style={{ marginTop: "2%" }}
                      value={fields["Adjusted Amount"]}
                      min={0}
                      format={{ type: "currency", precision: 2 }}
                      onValueChange={(e) => {
                        setFields({ ...fields, "Adjusted Amount": e });
                      }}
                      placeholder={"Adjusted Amount"}
                    />
                  </div>
                </div> */}
              </div>
              <div className="collectionpopupBLOCK2">
                <div style={{ width: "100%" }}>
                  <p style={{ color: "var(--TextColor)", fontWeight: "bold" }}>
                    Payment Agency (Entity)
                    <span style={{ fontSize: "15px" }}>*</span>
                  </p>
                  <div>
                    <DropDown
                      setfields={setFields}
                      fields={fields}
                      fieldNmae={"Payment Agency (Entity)"}
                      dropDownData={PaymentAgency}
                      reset={setPaymentAgencyvalidaton}
                    />
                    <p
                      style={{
                        color: "red",
                        fontWeight: "400",
                        position: "absolute",
                      }}
                    >
                      {PaymentAgencyvalidaton}
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "4%" }}>
                <p style={{ color: "var(--TextColor)", fontWeight: "bold" }}>Obligation Note / Remarks</p>
                <textarea
                  value={fields?.["Sentence Notes"]}
                  onChange={(e) => {
                    setFields({ ...fields, "Sentence Notes": e.target.value });
                  }}
                  style={{ width: "100%", height: "150px", marginTop: "1.4%" }}
                />
              </div>
              {/* <div
                style={{
                  width: "100%",
                  height: "20px",
                  marginTop: "3%",
                  marginBottom: "3%",
                }}
              >
                <p style={{ fontWeight: "bold", fontSize: "15px" }}>
                  Disburse Setup
                </p>
              </div>

              <div className="collectionpopupBLOCK2">
                <div className="collectionpopupBLOCK2inputt">
                  <p>Disburse Frequency</p>
                  <div>
                    <DropDown
                      setfields={setFields}
                      fields={fields}
                      fieldNmae={"Disburse Frequency"}
                      dropDownData={DisburseFrequency}
                      reset={setDisburseFrequencyvalidaton}
                    />
                    <p
                      style={{
                        color: "red",
                        fontWeight: "400",
                        position: "absolute",
                      }}
                    >
                      {DisburseFrequencyvalidaton}
                    </p>
                  </div>
                </div>
                <div className="collectionpopupBLOCK2inputt">
                  <p>Disburse Method</p>
                  <div>
                    <DropDown
                      setfields={setFields}
                      fields={fields}
                      fieldNmae={"Disburse Method"}
                      dropDownData={DisburseMethod}
                      reset={setDisburseMethodvalidaton}
                    />
                    <p
                      style={{
                        color: "red",
                        fontWeight: "400",
                        position: "absolute",
                      }}
                    >
                      {DisburseMethodvalidaton}
                    </p>
                  </div>
                </div>
              </div>
              <div className="collectionpopupBLOCK2">
                <div className="collectionpopupBLOCK2inputt">
                  <p>Disburse %</p>
                  <div>
                    <NumberBox
                      disabled={true}
                      onKeyDown={(e) => {
                        const { event } = e;
                        const str =
                          event.key || String.fromCharCode(event.which);
                        if (/^[.,e]$/.test(str)) {
                          event.preventDefault();
                        }
                      }}
                      style={{ marginTop: "2%" }}
                      value={fields?.["Disburse %"]}
                      min={0}
                      onValueChange={(e) => {
                        setFields({ ...fields, "Disburse %": e });
                      }}
                      placeholder={"ZIP"}
                    />
                  </div>
                </div>
                <div className="collectionpopupBLOCK2inputt">
                  <p>
                    Bank Account <span style={{ fontSize: "15px" }}>*</span>
                  </p>
                  <div>
                    <DropDown
                      setfields={setFields}
                      fields={fields}
                      fieldNmae={"Bank Account"}
                      dropDownData={BankAccount}
                      reset={setBankAccountvalidaton}
                    />
                    <p
                      style={{
                        color: "red",
                        fontWeight: "400",
                        position: "absolute",
                      }}
                    >
                      {BankAccountvalidaton}
                    </p>
                  </div>
                </div>
              </div>
              <div className="collectionpopupBLOCK2">
                <div className="collectionpopupBLOCK2inputt">
                  <p>
                    Entity <span style={{ fontSize: "15px" }}>*</span>
                  </p>
                  <div>
                    <DropDown
                      setfields={setFields}
                      fields={fields}
                      fieldNmae={"Entity"}
                      dropDownData={Entity}
                      reset={setEntityvalidaton}
                    />
                    <p
                      style={{
                        color: "red",
                        fontWeight: "400",
                        position: "absolute",
                      }}
                    >
                      {Entityvalidaton}
                    </p>
                  </div>
                </div>
              </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "2%",
                  marginBottom: "2%",
                }}
              >
                <button
                  style={{
                    backgroundColor: "var(--button2)",
                    color: "white",
                    width: "15%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Save();
                  }}
                >
                  Done
                </button>
                <button
                  style={{
                    backgroundColor: "var(--button2)",
                    color: "white",
                    width: "15%",
                    marginLeft: "1%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setHelpPopUp(true);
                  }}
                >
                  Help
                </button>
              </div>
            </div>
          </ScrollView>
        )}
      </div>
    </>
  );
};
