import { useNavigate, useParams } from "react-router-dom";

export const Error = () => {
  const { statusCode } = useParams();
  const Navigation = useNavigate();
  return (
    <>
      <div style={{ margin: "5%" }}>
        <h1 style={{ fontSize: "70px", fontWeight: "bold" }}>
          Error {statusCode}
        </h1>
        <p style={{ fontSize: "40px", marginTop: "8px" }}>
          Oops! Something went wrong.
        </p>
        <button
          style={{
            marginTop: "8%",
            boxShadow: "0px 2px 10px 2px rgba(0, 0, 0, 0.2)",
            fontWeight: "bold",
            cursor: "pointer",
          }}
          onClick={() => {
            Navigation("/home");
          }}
        >
          Back
        </button>
      </div>
    </>
  );
};
