import { DateBox, LoadPanel, NumberBox, TextBox } from "devextreme-react";
import { ColumnDropdown } from "../../../../components/Dropdownn";
import { useEffect, useState } from "react";
import { Popup } from "devextreme-react";
import { GoToDocketHeaderFunction } from "./GoToDocketHeaderFunction";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { GoToDocketTopButtons } from "./GoToDocketTopButtons";
import { ChangeSuperVisorDate } from "./ChangeSuperVisorDate";
import { Subtab } from "./GoToDocketSubtabs/Subtab";
import { Drugtextcondition } from "../Dockect/overview/Drugtextcondition";
import { PersonFind } from "../../../PersonFind/PersonFind";
import { setGoToDocketIsChange, setgoToDocketPerson } from "../../../../reducer/ParticipateReducer";
import { useDispatch } from "react-redux";
import { AlertDAteCHangePopupmessage } from "./AlertDAteCHangePopupmessage";
import { headersidebarfunction } from "../../../HeaderSideBar/headersidebarfunction";
import HelpPopup from "../../../HeaderSideBar/HelpPopup";
import { handleGetLocal } from "../../../../services/auth-service";
import axios from "axios";
import { getAccessData } from "../../../../utils/getAccessPermission";

const CustomFLeg = ({ val, dispatch, setGoToDocketIsChange, EditTogel, customFlag, setcustomFlag }) => {
  const [temp, settemp] = useState(Number(val?.bValue?.trim()) == 1 ? true : false);
  return (
    <div style={{ display: "flex", marginTop: "2.3%" }}>
      <input
        checked={temp}
        onClick={() => {
          if (EditTogel) {
            dispatch(setGoToDocketIsChange(true));
            let data = customFlag.map((vall) => {
              if (vall.gCustomFlagID == val?.gCustomFlagID) {
                return {
                  ...vall,
                  bValue: !temp ? "1" : "0",
                };
              } else {
                return vall;
              }
            });
            setcustomFlag(data);
            settemp(!temp);
          }
        }}
        type="checkbox"
      />
      <p style={{ color: "var(--TextColor)", fontWeight: "bold" }}>{val?.sName}</p>
    </div>
  );
};
const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled, onChange, setGoToDocketIsChange }) => {
  return (
    <>
      <ColumnDropdown
        disabled={disabled}
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        reset={reset}
        margin={"7px"}
        onChange={onChange}
        IsChangefunction={setGoToDocketIsChange}
      />
    </>
  );
};

export const ChangeSupervisionDate = ({
  ChangesuperviserDatePOpup,
  setChangesuperviserDatePOpup,
  data,
  SupervisionStatusdropdown,
  setresetPOpup,
  resetPOpup,
  changeReasonDRopDown,
  dockerValue,
  setToastConfig,
  toastConfig,
  getDcket,
  refreshUserInfo,
}) => {
  return (
    <Popup
      visible={ChangesuperviserDatePOpup}
      onHiding={() => {
        setChangesuperviserDatePOpup(false);
      }}
      showTitle={false}
      width={670}
      height={600}
      showCloseButton={true}
      hideOnOutsideClick={true}
    >
      <ChangeSuperVisorDate
        setChangesuperviserDatePOpup={setChangesuperviserDatePOpup}
        data={data}
        SupervisionStatusdropdown={SupervisionStatusdropdown}
        resetPOpup={resetPOpup}
        changeReasonDRopDown={changeReasonDRopDown}
        dockerValue={dockerValue}
        setToastConfig={setToastConfig}
        toastConfig={toastConfig}
        getDcket={getDcket}
        refreshUserInfo={refreshUserInfo}
      />
    </Popup>
  );
};

const EditDRuogTextPOpup = ({
  EditDRuogTextPOpuptogal,
  setEditDRuogTextPOpuptogal,
  reset,
  toastConfig,
  setToastConfig,
  getTopDynamicDocketid,
  drugDropdownData,
  setreset,
}) => {
  return (
    <>
      <Popup
        visible={EditDRuogTextPOpuptogal}
        onHiding={() => {
          setEditDRuogTextPOpuptogal(false);
        }}
        showTitle={false}
        width={500}
        height={470}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <Drugtextcondition
          setEditDRuogTextPOpuptogal={setEditDRuogTextPOpuptogal}
          reset={reset}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          getTopDynamicDocketid={getTopDynamicDocketid}
          drugDropdownData={drugDropdownData}
          setreset={setreset}
        />
      </Popup>
    </>
  );
};
const AlertDAteCHange = ({
  AlertDAteCHangepopup,
  setAlertDAteCHangepopup,
  lastdate,
  currentdate,
  setaceptworning,
  onSave,
}) => {
  return (
    <>
      <Popup
        visible={AlertDAteCHangepopup}
        onHiding={() => {
          setAlertDAteCHangepopup(false);
        }}
        showTitle={false}
        width={500}
        height={300}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AlertDAteCHangePopupmessage
          setAlertDAteCHangepopup={setAlertDAteCHangepopup}
          lastdate={lastdate}
          currentdate={currentdate}
          setaceptworning={setaceptworning}
          onSave={onSave}
        />
      </Popup>
    </>
  );
};

export const GoTODocketHeader = ({ toastConfig, setToastConfig }) => {
  const pathname = handleGetLocal("pathURL");
  const navigate = useNavigate();
  const clas = new GoToDocketHeaderFunction(navigate);
  const [ChangesuperviserDatePOpup, setChangesuperviserDatePOpup] = useState(false);
  const [loading, setLoading] = useState(false);
  const { dockerValue } = useSelector((store) => store.participateNotes);
  const [data, setData] = useState();
  const [fields, setfields] = useState({
    Location: { name: "", id: "" },
    "Docket Number": "",
    "Docket Number2": "",
    Type: { name: "", id: "" },
    "Create Date": "",
    Person: "",
    Court: { name: "", id: "" },
    Judge: { name: "", id: "" },
    Offense: { name: "", id: "" },
    "Supervision Status": { name: "", id: "" },
    "First Offender": false,
    "Conditional Discharge": false,
    "Random Drug Testing": false,
    "Electronic Monitoring": false,
    "Pay Only": false,
    sDocketNumberCourtCounts: "",
    sSentenceNotes: "",
  });
  const [refreshsupervision, setrefreshsupervision] = useState(true);
  const [LocationdropDown, setLocationdropDown] = useState([]);
  const [TyperopDown, setTyperopDown] = useState([]);
  const [CourtropDown, setCourtropDown] = useState([]);
  const [JudgedropDown, setJudgedropDown] = useState([]);
  const [OffensedropDown, setOffensedropDown] = useState([]);
  const [SupervisionStatusdropdown, setSupervisionStatusdropdown] = useState([]);
  const [DocketSupervisionDropdowndata, setDocketSupervisionDropdowndata] = useState([]);
  const param = useParams();
  const { goToDocketPerson } = useSelector((store) => store.participate);
  const [EditDRuogTextPOpuptogal, setEditDRuogTextPOpuptogal] = useState(false);
  const [changeReasonDRopDown, setchangeReasonDRopDown] = useState([]);
  const [EditTogel, setEditTogel] = useState(false);
  const [resetPOpup, setresetPOpup] = useState(false);
  const [reset, setreset] = useState(false);
  const [drugDropdownData, setDrugDropdownData] = useState([]);
  const [popupOPen, setpopupOPen] = useState();
  const [AlertDAteCHangepopup, setAlertDAteCHangepopup] = useState(false);
  const [aceptworning, setaceptworning] = useState(false);
  const [judgevalidation, setjudgevalidation] = useState("");
  const [data2deopdown, setdata2deopdown] = useState([]);
  const { userAccessData } = useSelector((store) => store.Home);

  const [viewAccess, setViewAccess] = useState(true);
  const [editAccess, setEditAccess] = useState(false);
  const [addAccess, setAddAccess] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setgoToDocketPerson({}));
  }, []);

  const { dynamicParticipatTab } = useSelector((store) => store.participate);
  const [customFlag, setcustomFlag] = useState([]);

  const onSave = () => {
    const isValid = 1;
    if (isValid) {
      setjudgevalidation("");
      setTimeout(() => {
        clas.Save(
          fields,
          navigate,
          setLoading,
          setToastConfig,
          toastConfig,
          goToDocketPerson,
          dockerValue?.getTopDynamicDocketid,
          dockerValue,
          setData,
          dispatch,
          data?.[0],
          param,
          dynamicParticipatTab,
          setcustomFlag,
          customFlag,
          data2deopdown?.map((val) => {
            return {
              GDocketSupervisorId: val?.gDocketSupervisorId
                ? val?.gDocketSupervisorId
                : "00000000-0000-0000-0000-000000000000",
              GEntryUserId: localStorage.getItem("gUserId"),
              GDocketId: dockerValue?.getTopDynamicDocketid,
              GUserId: val?.ID,
              GSupervisorTypeId: DocketSupervisionDropdowndata?.supervisorType?.find((vall) => {
                return vall?.value == val.Type;
              })?.key
                ? DocketSupervisionDropdowndata?.supervisorType?.find((vall) => {
                    return vall?.value == val.Type;
                  })?.key
                : "00000000-0000-0000-0000-000000000000",
            };
          }),
          setrefreshsupervision,
          refreshsupervision,
          setEditTogel
        );
      }, 1000);
    } else {
      setjudgevalidation("Please select judge");
    }
    dispatch(setGoToDocketIsChange(false));
  };
  const [text, settext] = useState();

  useEffect(() => {
    if (fields?.Court?.id) {
      clas.getJugge(setLoading, fields?.Court?.id, setJudgedropDown, data, setfields, fields);
    }
  }, [fields?.Court?.id]);

  useEffect(() => {
    clas.DocketSupervisionDropdown(navigate, setLoading, setDocketSupervisionDropdowndata);
  }, []);

  const saveCookieCrum = async (id) => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/cookie-crumbs/save-cookie-crumb?recordId=${id}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {})
      .catch(() => {
        console.log("catch error");
      });
  };

  useEffect(() => {
    clas.resetField(setfields, data, goToDocketPerson, JudgedropDown);
  }, [data, goToDocketPerson]);

  useEffect(() => {
    if (!dockerValue?.userinfo) {
      navigate("/");
      return;
    }
    if (dockerValue?.getTopDynamicDocketid) {
      clas.getDocket(setLoading, dockerValue, setData, dispatch, param, dynamicParticipatTab, setcustomFlag);
      clas.getDropDown(
        setLoading,
        setLocationdropDown,
        setTyperopDown,
        setCourtropDown,
        setJudgedropDown,
        setOffensedropDown,
        setSupervisionStatusdropdown,
        setchangeReasonDRopDown
      );
    }
    saveCookieCrum(dockerValue?.getTopDynamicDocketid);
  }, [dockerValue?.getTopDynamicDocketid, param?.id]);

  const getDcket = () => {
    dockerValue?.getTopDynamicDocketid &&
      clas.getDocket(setLoading, dockerValue, setData, dispatch, param, dynamicParticipatTab, setcustomFlag);
  };

  const oncancel = () => {
    setEditTogel(false);
    setjudgevalidation("");
    dispatch(setGoToDocketIsChange(false));
    clas.getDocket(setLoading, dockerValue, setData, dispatch, param, dynamicParticipatTab, setcustomFlag);
  };

  useEffect(() => {
    dockerValue?.getTopDynamicDocketid &&
      clas.getDrugTestDropdownData(settext, dockerValue?.getTopDynamicDocketid, setDrugDropdownData, navigate);
    dockerValue?.getTopDynamicDocketid && saveCookieCrum(dockerValue?.getTopDynamicDocketid);
  }, [dockerValue?.getTopDynamicDocketid, reset]);

  const clas2 = new headersidebarfunction();
  const [markupid, setmarkupid] = useState("");
  const [markup, setmarkup] = useState("");
  const [markupsCaption, setmarkupisCaption] = useState("");
  const [markupsScreenName, setmarkupissScreenNamu] = useState("");
  const [helpPopUp, setHelpPopUp] = useState(false);

  useEffect(() => {
    clas2.getHelp(setLoading, navigate, setmarkup, setmarkupid, "DK1", setmarkupissScreenNamu, setmarkupisCaption);
  }, []);

  useEffect(() => {
    const getUserAccessPermissions = () => {
      const accessData = getAccessData(userAccessData, "DK1");
      setAddAccess(accessData?.Add);
      setEditAccess(accessData?.Edit);
      setViewAccess(accessData?.View);
    };

    getUserAccessPermissions();
  }, []);

  return (
    <>
      {viewAccess ? (
        <>
          <LoadPanel shadingColor="true" visible={loading} delay={10} />
          {markupid && (
            <Popup
              visible={markupid && helpPopUp}
              onHiding={() => {
                setHelpPopUp(false);
              }}
              showTitle={false}
              width={1200}
              height={670}
              showCloseButton={true}
              hideOnOutsideClick={true}
            >
              <HelpPopup
                markup={markup}
                markupid={markupid}
                helpPopUp={helpPopUp}
                setHelpPopUp={setHelpPopUp}
                markupsCaption={markupsCaption}
                markupsScreenName={markupsScreenName}
              />
            </Popup>
          )}
          <ChangeSupervisionDate
            ChangesuperviserDatePOpup={ChangesuperviserDatePOpup}
            setChangesuperviserDatePOpup={setChangesuperviserDatePOpup}
            data={data}
            SupervisionStatusdropdown={SupervisionStatusdropdown}
            setresetPOpup={setresetPOpup}
            resetPOpup={resetPOpup}
            changeReasonDRopDown={changeReasonDRopDown}
            dockerValue={dockerValue}
            setToastConfig={setToastConfig}
            toastConfig={toastConfig}
            getDcket={getDcket}
          />
          <PersonFind nojump={true} isPopupVisible={popupOPen} setIsPopupVisible={setpopupOPen} />
          <EditDRuogTextPOpup
            EditDRuogTextPOpuptogal={EditDRuogTextPOpuptogal}
            setEditDRuogTextPOpuptogal={setEditDRuogTextPOpuptogal}
            reset={reset}
            toastConfig={toastConfig}
            setToastConfig={setToastConfig}
            getTopDynamicDocketid={dockerValue?.getTopDynamicDocketid}
            drugDropdownData={drugDropdownData}
            setreset={setreset}
          />
          <AlertDAteCHange
            AlertDAteCHangepopup={AlertDAteCHangepopup}
            setAlertDAteCHangepopup={setAlertDAteCHangepopup}
            lastdate={fields?.["Create Date"]}
            currentdate={data?.[0]?.dCreate}
            setaceptworning={setaceptworning}
            onSave={onSave}
          />
          <div style={{ display: "flex" }}>
            <div className="screen_num" style={{ margin: ".5%", display: "flex" }}>
              <p>DK.1</p>
            </div>
            {markupid && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28px"
                height="28px"
                viewBox="0 0 24 24"
                fill="none"
                style={{ cursor: "pointer" }}
                className="svg-blue-black"
                onClick={() => {
                  setHelpPopUp(true);
                }}
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 7.75C11.3787 7.75 10.875 8.25368 10.875 8.875C10.875 9.28921 10.5392 9.625 10.125 9.625C9.71079 9.625 9.375 9.28921 9.375 8.875C9.375 7.42525 10.5503 6.25 12 6.25C13.4497 6.25 14.625 7.42525 14.625 8.875C14.625 9.58584 14.3415 10.232 13.883 10.704C13.7907 10.7989 13.7027 10.8869 13.6187 10.9708C13.4029 11.1864 13.2138 11.3753 13.0479 11.5885C12.8289 11.8699 12.75 12.0768 12.75 12.25V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V12.25C11.25 11.5948 11.555 11.0644 11.8642 10.6672C12.0929 10.3733 12.3804 10.0863 12.6138 9.85346C12.6842 9.78321 12.7496 9.71789 12.807 9.65877C13.0046 9.45543 13.125 9.18004 13.125 8.875C13.125 8.25368 12.6213 7.75 12 7.75ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                  fill="white"
                />
              </svg>
            )}
          </div>
          <div>
            <div className="gotodockerheader">
              <div className="gotodockerheaderedit">
                <div>
                  <GoToDocketTopButtons
                    data={data}
                    toastConfig={toastConfig}
                    setToastConfig={setToastConfig}
                    getDcket={getDcket}
                    addAccess={addAccess}
                  />
                </div>
                {EditTogel ? (
                  <>
                    <div className="buttons">
                      <button
                        onClick={() => {
                          oncancel();
                        }}
                        className="btn"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          if (fields?.["Create Date"] !== data?.[0]?.dCreate) {
                            setAlertDAteCHangepopup(true);
                            return;
                          }
                          onSave();
                        }}
                        className="primary_btn"
                      >
                        Save
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M16.5 10V15C16.5 15.55 16.05 16 15.5 16H3.5C2.95 16 2.5 15.55 2.5 15V10C2.5 9.45 2.05 9 1.5 9C0.95 9 0.5 9.45 0.5 10V16C0.5 17.1 1.4 18 2.5 18H16.5C17.6 18 18.5 17.1 18.5 16V10C18.5 9.45 18.05 9 17.5 9C16.95 9 16.5 9.45 16.5 10ZM10.5 9.67L12.38 7.79C12.77 7.4 13.4 7.4 13.79 7.79C14.18 8.18 14.18 8.81 13.79 9.2L10.2 12.79C9.81 13.18 9.18 13.18 8.79 12.79L5.2 9.2C4.81 8.81 4.81 8.18 5.2 7.79C5.59 7.4 6.22 7.4 6.61 7.79L8.5 9.67V1C8.5 0.45 8.95 0 9.5 0C10.05 0 10.5 0.45 10.5 1V9.67Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>
                  </>
                ) : (
                  <button
                    className="primary_btn"
                    onClick={() => {
                      setEditTogel(true);
                    }}
                    disabled={!editAccess}
                  >
                    Edit
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0 15.4601V18.5001C0 18.7801 0.22 19.0001 0.5 19.0001H3.54C3.67 19.0001 3.8 18.9501 3.89 18.8501L14.81 7.94006L11.06 4.19006L0.15 15.1001C0.0500001 15.2001 0 15.3201 0 15.4601ZM17.71 5.04006C18.1 4.65006 18.1 4.02006 17.71 3.63006L15.37 1.29006C14.98 0.900059 14.35 0.900059 13.96 1.29006L12.13 3.12006L15.88 6.87006L17.71 5.04006V5.04006Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                )}
              </div>
              <div>
                <div className="gotodockerheadeoffender">
                  <div>
                    <p style={{ color: "var(--greyColor)" }}>Offender #</p>
                    <p style={{ marginLeft: "10px" }}>{data?.[0]?.sOffenderNumber}</p>
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <p style={{ color: "var(--greyColor)" }}>Reference #</p>
                    <p style={{ marginLeft: "10px" }}>{data?.[0]?.sReferenceNumber}</p>
                  </div>
                </div>
                <div className="gotodockerheadeheader">
                  <div>
                    <div className="generatePaymentPlandiv_gotodoctet">
                      <p>Location</p>
                      <div>
                        <DropDown
                          disabled={true}
                          dropDownData={LocationdropDown}
                          fieldNmae={"Location"}
                          fields={fields}
                          setfields={setfields}
                          onChange={true}
                          setGoToDocketIsChange={setGoToDocketIsChange}
                        />
                      </div>
                    </div>
                    <div className="generatePaymentPlandiv_gotodoctet">
                      <p>Docket Number</p>
                      <div style={{ display: "flex" }}>
                        <TextBox
                          disabled={!EditTogel}
                          style={{ marginTop: "3%", width: "70%" }}
                          value={fields?.["Docket Number"]}
                          onValueChange={(e) => {
                            dispatch(setGoToDocketIsChange(true));
                            setfields({
                              ...fields,
                              "Docket Number": e,
                            });
                          }}
                          isValid={true}
                          placeholder={"Docket Number"}
                        />
                        <NumberBox
                          disabled={!EditTogel}
                          onKeyDown={(e) => {
                            const { event } = e;
                            const str = event.key || String.fromCharCode(event.which);
                            if (/^[.,e]$/.test(str)) {
                              event.preventDefault();
                            }
                          }}
                          style={{
                            marginTop: "2%",
                            mariginLeft: "1%",
                            width: "30%",
                          }}
                          value={fields?.["Docket Number2"]}
                          min={0}
                          onValueChange={(e) => {
                            dispatch(setGoToDocketIsChange(true));
                            setfields({
                              ...fields,
                              "Docket Number2": e,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="generatePaymentPlandiv_gotodoctet">
                      <p>Type</p>
                      <div>
                        <DropDown
                          disabled={!EditTogel}
                          dropDownData={TyperopDown}
                          fieldNmae={"Type"}
                          fields={fields}
                          setfields={setfields}
                          onChange={true}
                          setGoToDocketIsChange={setGoToDocketIsChange}
                        />
                      </div>
                    </div>
                    <div className="generatePaymentPlandiv_gotodoctet">
                      <p>Create Date</p>
                      <div>
                        <DateBox
                          disabled={!EditTogel}
                          style={{ marginTop: "2%" }}
                          value={fields?.["Create Date"]}
                          onValueChange={(e) => {
                            dispatch(setGoToDocketIsChange(true));
                            setfields({
                              ...fields,
                              "Create Date": e,
                            });
                          }}
                          useMaskBehavior={true}
                        />
                      </div>
                    </div>
                    <div className="generatePaymentPlandiv_gotodoctet">
                      <p>Person</p>
                      <div style={{ display: "flex" }}>
                        <TextBox
                          disabled={true}
                          style={{ marginTop: "3%", width: "88%" }}
                          value={fields?.Person}
                          onValueChange={(e) => {
                            dispatch(setGoToDocketIsChange(true));
                            setfields({
                              ...fields,
                              Person: e,
                            });
                          }}
                          isValid={true}
                          placeholder={"Person"}
                        />
                        <button
                          onClick={() => {
                            EditTogel && setpopupOPen(true);
                          }}
                          style={{
                            display: "flex",
                            marginTop: "2%",
                            marginLeft: "2%",
                            width: "10%",
                            cursor: "pointer",
                            background: "var(--button-lightblack-lightblue)",
                            color: "var(--TextColor)",
                          }}
                        >
                          <svg
                            className="svg-black-white"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="arrow_forward" clipPath="url(#clip0_5440_11577)">
                              <path
                                id="Vector"
                                d="M5 13.0007H16.17L11.29 17.8807C10.9 18.2707 10.9 18.9107 11.29 19.3007C11.68 19.6907 12.31 19.6907 12.7 19.3007L19.29 12.7107C19.68 12.3207 19.68 11.6907 19.29 11.3007L12.71 4.7007C12.32 4.3107 11.69 4.3107 11.3 4.7007C10.91 5.0907 10.91 5.7207 11.3 6.1107L16.17 11.0007H5C4.45 11.0007 4 11.4507 4 12.0007C4 12.5507 4.45 13.0007 5 13.0007Z"
                                fill="#424167"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_5440_11577">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="generatePaymentPlandiv_gotodoctet">
                      <p>Sentence Notes</p>
                      <textarea
                        disabled={!EditTogel}
                        style={{
                          marginTop: "3%",
                          width: "300px",
                          resize: "none",
                          height: "50px",
                        }}
                        value={fields?.sSentenceNotes}
                        onChange={(e) => {
                          dispatch(setGoToDocketIsChange(true));
                          setfields({
                            ...fields,
                            sSentenceNotes: e.target.value,
                          });
                        }}
                        className="dark-mode-textarea"
                        placeholder={"Sentence Notes"}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="generatePaymentPlandiv_gotodoctet">
                      <p>Court</p>
                      <div>
                        <DropDown
                          disabled={!EditTogel}
                          dropDownData={CourtropDown}
                          fieldNmae={"Court"}
                          fields={fields}
                          setfields={setfields}
                          onChange={true}
                          setGoToDocketIsChange={setGoToDocketIsChange}
                        />
                      </div>
                    </div>
                    <div className="generatePaymentPlandiv_gotodoctet">
                      <p>Judge</p>
                      <div>
                        <DropDown
                          disabled={!EditTogel}
                          dropDownData={JudgedropDown}
                          fieldNmae={"Judge"}
                          fields={fields}
                          setfields={setfields}
                          onChange={true}
                          setGoToDocketIsChange={setGoToDocketIsChange}
                          reset={setjudgevalidation}
                        />
                      </div>
                      <p
                        style={{
                          color: "red",
                          position: "absolute",
                          fontWeight: "400",
                        }}
                      >
                        {judgevalidation}
                      </p>
                    </div>
                    <div className="generatePaymentPlandiv_gotodoctet">
                      <p>Offense</p>

                      <DropDown
                        disabled={!EditTogel}
                        dropDownData={OffensedropDown}
                        fieldNmae={"Offense"}
                        fields={fields}
                        setfields={setfields}
                        onChange={true}
                        setGoToDocketIsChange={setGoToDocketIsChange}
                      />
                    </div>
                    <div className="generatePaymentPlandiv_gotodoctet">
                      <p>Supervision Status</p>
                      <div>
                        <DropDown
                          disabled={true}
                          dropDownData={SupervisionStatusdropdown}
                          fieldNmae={"Supervision Status"}
                          fields={fields}
                          setfields={setfields}
                          onChange={true}
                          setGoToDocketIsChange={setGoToDocketIsChange}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <p>
                        {data?.[0]?.dSupervisionStatus &&
                          new Date(data?.[0]?.dSupervisionStatus).toLocaleDateString("en-US")}
                      </p>
                      <button
                        // disabled={!EditTogel}
                        style={{
                          cursor: "pointer",
                          width: "76%",
                          display: "flex",
                          alignItems: "center",
                          background: "var(--button)",
                          color: "var(--TextColor)",
                        }}
                        onClick={() => {
                          setresetPOpup(!resetPOpup);
                          setChangesuperviserDatePOpup(true);
                        }}
                      >
                        Change Supervision Status
                        <svg
                          className="svg-blue-white"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_6306_6425)">
                            <path
                              d="M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z"
                              fill="#424167"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6306_6425">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div style={{ marginTop: "18px" }}>
                    <div>
                      {customFlag?.map((val) => {
                        return (
                          <>
                            <CustomFLeg
                              val={val}
                              dispatch={dispatch}
                              setGoToDocketIsChange={setGoToDocketIsChange}
                              EditTogel={EditTogel}
                              customFlag={customFlag}
                              setcustomFlag={setcustomFlag}
                            />
                          </>
                        );
                      })}
                    </div>

                    <div className="gotodockerheadeblock3">
                      <div>
                        <p style={{ color: "var(--greyColor)" }}>Amount</p>
                        <p style={{ marginLeft: "10px" }}>
                          ${data?.[0]?.fAmount ? Number(data?.[0]?.fAmount).toFixed(2) : 0}
                        </p>
                      </div>
                      <div>
                        <p style={{ color: "var(--greyColor)" }}>Paid</p>
                        <p style={{ marginLeft: "10px" }}>
                          ${data?.[0]?.fPaid ? Number(data?.[0]?.fPaid).toFixed(2) : 0}
                        </p>
                      </div>
                      <div>
                        <p style={{ color: "var(--greyColor)" }}>Balance</p>
                        <p style={{ marginLeft: "10px" }}>
                          ${data?.[0]?.fBalance ? Number(data?.[0]?.fBalance).toFixed(2) : 0}
                        </p>
                      </div>
                      <div>
                        <p style={{ color: "var(--greyColor)" }}>Arrears</p>
                        <p style={{ marginLeft: "10px" }}>
                          ${data?.[0]?.fArrears ? Number(data?.[0]?.fArrears).toFixed(2) : 0}
                        </p>
                      </div>
                      <div>
                        <p style={{ color: "var(--greyColor)" }}>Amount Disbursed</p>
                        <p style={{ marginLeft: "10px" }}>
                          ${data?.[0]?.fAmountDisbursed ? Number(data?.[0]?.fAmountDisbursed).toFixed(2) : 0}
                        </p>
                      </div>
                      <div>
                        <p style={{ color: "var(--greyColor)" }}>Amount on Hand</p>
                        <p style={{ marginLeft: "10px" }}>
                          ${data?.[0]?.fBalanceEligible ? Number(data?.[0]?.fBalanceEligible).toFixed(2) : 0}
                        </p>
                      </div>
                    </div>

                    <div style={{ marginTop: "7%" }}>
                      {text && (
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            fontWeight: "bold",
                            color: "var(--TextColor)",
                          }}
                        >
                          {text}
                          <svg
                            className="svg-blue-white"
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="18"
                            viewBox="0 0 19 18"
                            fill="none"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setreset(!reset);
                              setEditDRuogTextPOpuptogal(true);
                            }}
                          >
                            <g clipPath="url(#clip0_3919_390729)">
                              <path
                                d="M2.75 13.093V15.373C2.75 15.583 2.915 15.748 3.125 15.748H5.405C5.5025 15.748 5.6 15.7105 5.6675 15.6355L13.8575 7.45297L11.045 4.64047L2.8625 12.823C2.7875 12.898 2.75 12.988 2.75 13.093ZM16.0325 5.27797C16.325 4.98547 16.325 4.51297 16.0325 4.22047L14.2775 2.46547C13.985 2.17297 13.5125 2.17297 13.22 2.46547L11.8475 3.83797L14.66 6.65047L16.0325 5.27797Z"
                                fill="black"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_3919_390729">
                                <rect width="18" height="18" fill="white" transform="translate(0.5)" />
                              </clipPath>
                            </defs>
                          </svg>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Subtab
              dockerValue={dockerValue}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              dockertDetail={{
                name: fields?.["Docket Number"],
                id: dockerValue?.getTopDynamicDocketid,
              }}
              setdata2deopdown={setdata2deopdown}
              dockerHeaderData={data?.[0]}
              EditTogel={EditTogel}
              data2deopdown={data2deopdown}
              Docketid={dockerValue?.getTopDynamicDocketid}
              DocketSupervisionDropdowndata={DocketSupervisionDropdowndata}
              refreshsupervision={refreshsupervision}
            />
          </div>
        </>
      ) : (
        "No DK.1 View Access"
      )}
    </>
  );
};
