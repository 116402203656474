import { useEffect, useState } from "react";
import { Feetab } from "./feeschedulatab/fee/feetab";
import { Surcharge } from "./feeschedulatab/Surcharge/Surcharge";
import { Entities } from "./feeschedulatab/Entities/Entities";
import { InUseBy } from "./feeschedulatab/InUseBy/InUseBy";
import { DDS } from "./feeschedulatab/DDS/DDS";
import { ShuduleAttachment } from "./feeschedulatab/ShuduleAttachment/ShuduleAttachment";
import { ShuduleNote } from "./feeschedulatab/ShuduleNote/ShuduleNote";
import { feeshudulefunctions } from "./feeshudulefunctions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const FeeScheduleSubTab = ({
  isEditableButton,
  aftersave,
  feeScheduleResponseData,
  setentitidata,
  EntitiesTabledata2,
  setEntitiesTabledata2,
  entitidata,
}) => {
  const [activeTab, setActiveTab] = useState(1);
  const clas = new feeshudulefunctions();
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const { on_Save_Edit } = useSelector((store) => store.participateNotes);
  const [attachmentcount, setattachmentcount] = useState(0);
  const tabTitles = [
    "Fees",
    "Surcharge",
    `Attachment (${attachmentcount})`,
    "Notes",
    "Note",
    "Entities",
    "In Use By",
    "DDS",
  ];
  const [attachmentTabledata, setattachmentTabledata] = useState([]);
  const [EntitiesTabledata, setEntitiesTabledata] = useState([]);

  const [loadingg, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      feeScheduleResponseData[0]?.gFeeScheduleId ||
      feeScheduleResponseData?.gFeeScheduleId
    ) {
      clas.getattachmentTableData(
        setattachmentTabledata,
        setLoading,
        navigate,
        {
          feeshuduleid: feeScheduleResponseData?.gFeeScheduleId
            ? feeScheduleResponseData?.gFeeScheduleId
            : feeScheduleResponseData[0]?.gFeeScheduleId,
        },
        setattachmentcount
      );

      clas.getEntitiesTableData2(
        setEntitiesTabledata2,
        setLoading,
        navigate,
        {
          feeshuduleid: feeScheduleResponseData?.gFeeScheduleId
            ? feeScheduleResponseData?.gFeeScheduleId
            : feeScheduleResponseData[0]?.gFeeScheduleId,
        },
        setentitidata
      );
    }
  }, [
    feeScheduleResponseData[0]?.gFeeScheduleId ||
      feeScheduleResponseData?.gFeeScheduleId,
  ]);

  useEffect(() => {
    clas.getEntitiesTableData(setEntitiesTabledata, setLoading, navigate);
  }, [
    feeScheduleResponseData[0]?.gFeeScheduleId ||
      feeScheduleResponseData?.gFeeScheduleId,
  ]);

  useEffect(() => {
    feeScheduleResponseData[0]?.gFeeScheduleId ||
      (feeScheduleResponseData?.gFeeScheduleId &&
        clas.getattachmentTableData(
          setattachmentTabledata,
          setLoading,
          navigate,
          {
            feeshuduleid: feeScheduleResponseData?.gFeeScheduleId
              ? feeScheduleResponseData?.gFeeScheduleId
              : feeScheduleResponseData[0]?.gFeeScheduleId,
          },
          setattachmentcount
        ));
  }, [on_Save_Edit]);

  return (
    <>
      <div className="goto-obligation-sub-tab-container">
        <div className="goto-obligation-sub-tab">
          {tabTitles.map((title, index) => (
            <button
              key={index}
              className={`goto-obligation-button ${
                activeTab === index + 1 ? "goto-obligation-button-active" : ""
              }`}
              onClick={() => handleTabClick(index + 1)}
            >
              {title}
            </button>
          ))}
        </div>
        <div className="commonBlock_bottom_border"></div>
        <div style={{ margin: "1%" }}>{activeTab === 1 && <Feetab />}</div>
        <div style={{ margin: "1%" }}>{activeTab === 2 && <Surcharge />}</div>
        <div style={{ margin: "1%" }}>
          {activeTab === 6 && (
            <Entities
              isEditableButton={isEditableButton}
              EntitiesTabledata={EntitiesTabledata}
              aftersave={aftersave}
              EntitiesTabledata2={EntitiesTabledata2}
              setentitidata={setentitidata}
              setEntitiesTabledata={setEntitiesTabledata}
              entitidata={entitidata}
            />
          )}
        </div>
        <div style={{ margin: "1%" }}>{activeTab === 7 && <InUseBy />}</div>
        <div style={{ margin: "1%" }}>{activeTab === 8 && <DDS />}</div>
        <div style={{ margin: "1%" }}>
          {activeTab === 3 && (
            <ShuduleAttachment
              dataa={attachmentTabledata}
              loadinggg={loadingg}
              userinfo={{
                gPersonId: feeScheduleResponseData?.gFeeScheduleId
                  ? feeScheduleResponseData?.gFeeScheduleId
                  : feeScheduleResponseData[0]?.gFeeScheduleId,
              }}
            />
          )}
        </div>
        <div style={{ margin: "1%" }}>
          {activeTab === 4 && (
            <ShuduleNote
              feeshudule={true}
              dockerValue={{
                getTopDynamicDocketid: feeScheduleResponseData?.gFeeScheduleId
                  ? feeScheduleResponseData?.gFeeScheduleId
                  : feeScheduleResponseData[0]?.gFeeScheduleId,
              }}
              userinfo={{
                gPersonId: feeScheduleResponseData?.gFeeScheduleId
                  ? feeScheduleResponseData?.gFeeScheduleId
                  : feeScheduleResponseData[0]?.gFeeScheduleId,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
