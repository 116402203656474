import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getAuthToken,
  handleGetLocal,
  handleSetleLocal,
} from "../../../services/auth-service";
import httpService from "../../../services/http-service";
import { SelectMedium } from "./SelectMedium";
import { LoadPanel } from "devextreme-react";

export default function TwoFactorAuth(props) {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [isDisable, setIsDisable] = useState(true);
  const navigate = useNavigate();
  const [counter, setCounter] = React.useState(0);
  const [sendCheck, setSendCheck] = useState(false);
  const [resendBtn, setResendBtn] = useState(false);
  const [responceError, setresponceError] = useState("");
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const validateLogin = () => {
    let error = {},
      formIsValid = true;
    if (!fields["TwoFactCode"] || fields["TwoFactCode"].trim() == "") {
      formIsValid = false;
      error["TwoFactCode"] = "*Please enter the code";
    }
    if (typeof fields["TwoFactCode"] !== "undefined") {
      var pattern = /^[0-9]{6,15}$/;
      if (!pattern.test(fields["TwoFactCode"])) {
        formIsValid = false;
        error["TwoFactCode"] = "*Please enter valid email address";
      }
    }
    return {
      errors: error,
      formIsValid: formIsValid,
    };
  };
  const validateLoginForm = () => {
    let response = validateLogin();
    setErrors(response.errors);
    return response.formIsValid;
  };

  const handleChange = (event) => {
    let field = fields;
    setresponceError("");
    if (event.target.name == "TwoFactCode") {
      if (event.target.value.length < 6) {
        setIsDisable(true);
      } else {
        setIsDisable(false);
      }
    }
    field[event.target.name] = /^[0-9]+$/.test(event.target.value)
      ? event.target.value
      : "";
    setFields({ ...field });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateLoginForm()) {
      verifyCodeClick();
    }
  };
  const verifyCodeClick = async (e) => {
    let uName = localStorage.getItem("username");
    const pathname = handleGetLocal("pathURL");
    const token = getAuthToken();
    setLoading(true);
    try {
      const response = await httpService.get(
        `api/Account/TwoFactorVCVerify?username=${uName}&vc=${fields.TwoFactCode}`,
        { headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` } }
      );

      if (response.status == 200) {
        setLoading(false);
        if (response.data.isSuccess) {
          handleSetleLocal("email", props.email);
          window.location.reload();
        } else {
          if (response?.data?.data?.vcStatus == "Verification Code Expired") {
            setresponceError("Verification Code Expired");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            setresponceError("Please Enter Valid Code");
          }
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const clickResendCode = async () => {
    let domainName = (url) =>
      url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
    let domailURL = window.location.href;
    let pathURL = domainName(domailURL);
    let selectMediumm = handleGetLocal("selectOption");
    setLoading(true);
    try {
      const response = await httpService.post(
        `api/Account/ResendVC?codeMedium=${selectMediumm}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathURL}`,
          },
        }
      );
      if (response?.status == 200) {
        setLoading(false);
        if (response?.data?.data?.isSuccess) {
          navigate("/");
        }
      }else{
        setLoading(false)
      }
    } catch (error) {
      setLoading(false);
    }
  };

  let a = true;
  function startCounter() {
    if (a) {
      a = false;
      setCounter(59);
      setSendCheck(true);
      setResendBtn(false);
    }
  }
  const handleResend = () => {
    setResendBtn(true);
    clickResendCode();
  };

  const [selectMedium, setselectMedium] = useState(true);

  return (
    <>
     <LoadPanel shadingColor="true" visible={loading} delay={10} />
      {selectMedium ? (
        <SelectMedium props={props} setselectMedium={setselectMedium} />
      ) : (
        <>
          <h1 style={{ fontSize: "22px", color: "#000000" }}>
            Two factor authentication
          </h1>
          <p style={{ fontSize: "14px" }} className="msg">
            We have sent a code to your registered email address/contact number
          </p>
          <form>
            <div
              className={`fieldWrap ${errors?.TwoFactCode ? " error " : ""} `}
            >
              <label style={{ fontSize: "12px" }}>Enter Code</label>
              <input
                style={{ outline: responceError && "2px solid red" }}
                type="text"
                name="TwoFactCode"
                placeholder="Enter your code"
                onChange={(e) => handleChange(e)}
                value={fields?.TwoFactCode}
              />
              {responceError && (
                <p
                  className="errorText"
                  style={{ color: "red", marginTop: "4px" }}
                >
                  {responceError}
                </p>
              )}
              <div className="codeResent">
                <p>
                  <Link onClick={startCounter}>Didn't receive the code?</Link>
                </p>
                {sendCheck ? (
                  <>
                    {counter == 0 ? (
                      <button
                        className="btn_link"
                        onClick={handleResend}
                        disabled={resendBtn}
                      >
                        Resend
                      </button>
                    ) : (
                      <em>
                        {" "}
                        Resend 00:{counter < 10 ? "0" : ""}
                        {counter}
                      </em>
                    )}
                  </>
                ) : (
                  " "
                )}
              </div>
            </div>
            <div className="fieldWrap">
              <button
                className="btn btn-default"
                disabled={isDisable}
                onClick={handleSubmit}
              >
                Verify
              </button>
            </div>
          </form>
        </>
      )}
    </>
  );
}
