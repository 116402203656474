import { LoadPanel, ScrollView } from "devextreme-react";
import { handleGetLocal } from "../../../../services/auth-service";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { seton_Save_Edit } from "../../../../reducer/participantNoteReducer";
import { useSelector } from "react-redux";
import { ColumnDropdown } from "../../../../components/Dropdownn";
import { logOut } from "../../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { ApiError } from "../../../Error/ApiError";

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  setNoteTypeValidation,
  fieldNmae,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        reset={setNoteTypeValidation}
      />
    </>
  );
};

export const EditTable = ({
  setNoteEditTable,
  addDataIngTable,
  userinfo,
  getEditData,
  setToastConfig,
  toastConfig,
  GoTONote,
  dockerValue,
}) => {
  const componentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const pathname = handleGetLocal("pathURL");
  const [activityNoteType, setactivityNoteType] = useState([]);

  const { on_Save_Edit, resetValue } = useSelector(
    (store) => store.participateNotes
  );
  const [NoteTypeValidation, setNoteTypeValidation] = useState("");
  const [NoteValidation, setNoteValidation] = useState("");
  const navigate = useNavigate();
  const [errorhandling, seterrorhandling] = useState(false);

  const Add_Update_RowInTable = async (body, action) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/activity/save`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: action,
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: action,
            type: "success",
          });
        }, 1400);
        dispatch(seton_Save_Edit(!on_Save_Edit));
        setNoteEditTable(false);
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const getLastNote = async () => {
    setLoading(true);
    await axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/PersonReportDates/CopyLastNote?gPersonID=${
          dockerValue?.getTopDynamicDocketid
            ? dockerValue?.getTopDynamicDocketid
            : userinfo?.gPersonId
        }&gActivityTypeID=${fields?.BankAccount.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setfields({
            ...fields,
            notes: val?.data?.data?.sMemo ? val?.data?.data?.sMemo : "",
          });
        }
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const getDropDown = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/PersonReportDates/masterDropdownValues`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          setactivityNoteType(
            val.data.data.activityNoteType.map((val) => {
              return { name: val?.value, id: val?.key };
            })
          );
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  const [fields, setfields] = useState({
    notes: "",
    BankAccount: { name: "", id: "" },
    ArrearageRelated: false,
    onCall: false,
  });
  const [copyicon, setcopyicon] = useState(true);

  useEffect(() => {
    getDropDown();
  }, []);

  const RestFields = () => {
    setfields({
      notes: getEditData.Note ? getEditData.Note : "",
      BankAccount: {
        name: getEditData.Type ? getEditData.Type : "",
        id: getEditData.gActivityTypeId ? getEditData.gActivityTypeId : "",
      },
      ArrearageRelated: getEditData["Arrearage ?"]
        ? getEditData["Arrearage ?"]
        : false,
      onCall: getEditData["On call ?"] ? getEditData["On call ?"] : false,
    });
    setNoteTypeValidation("");
    setNoteValidation("");
  };

  useEffect(() => {
    RestFields();
  }, [resetValue]);

  const dispatch = useDispatch();

  const AddFunction = () => {
    if (fields.notes && fields.BankAccount.id) {
      setNoteTypeValidation("");
      setNoteValidation("");
      Add_Update_RowInTable(
        {
          gActivityId: "",
          gRecordId: dockerValue?.getTopDynamicDocketid
            ? dockerValue?.getTopDynamicDocketid
            : userinfo?.gPersonId,
          sMemo: fields.notes,
          bSystem: false,
          gActivityTypeId: fields.BankAccount.id,
          gDocketId: GoTONote ? dockerValue?.getTopDynamicDocketid : "",
          sSource: "",
          bArrearageRelated: fields.ArrearageRelated,
          bOnCall: fields.onCall,
        },
        "Successfully Added!"
      );
    } else {
      !fields.BankAccount.id
        ? setNoteTypeValidation("Please select Note type")
        : setNoteTypeValidation("");
      !fields.notes
        ? setNoteValidation("Please enter Note")
        : setNoteValidation("");
    }
  };

  const editFunction = () => {
    if (fields.notes && fields.BankAccount.id) {
      Add_Update_RowInTable(
        {
          gActivityId: getEditData.gActivityId,
          gRecordId: dockerValue?.getTopDynamicDocketid
            ? dockerValue?.getTopDynamicDocketid
            : userinfo?.gPersonId,
          sMemo: fields.notes,
          bSystem: false,
          gActivityTypeId: fields.BankAccount.id,
          gDocketId: GoTONote ? dockerValue?.getTopDynamicDocketid : "",
          sSource: "",
          bArrearageRelated: fields.ArrearageRelated,
          bOnCall: fields.onCall,
        },
        "Successfully Updated!"
      );
    } else {
      !fields.BankAccount.id
        ? setNoteTypeValidation("Please select Note type")
        : setNoteTypeValidation("");
      !fields.notes
        ? setNoteValidation("Please enter Note")
        : setNoteValidation("");
    }
  };

  const CopyLastNoteFunction = () => {
    if (fields?.BankAccount.id && userinfo?.gPersonId) {
      setNoteTypeValidation("");
      setcopyicon(false);
      setTimeout(() => {
        setcopyicon(true);
      }, 5000);
      getLastNote();
    } else {
      setNoteTypeValidation("Please select Note type");
    }
  };

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />

      <ScrollView style={{ width: "100%", height: "500px" }}>
        <div ref={componentRef} className="notes_edit_popup">
          <div className="RecivePaymentModel_header">
            <div
              style={{ width: "150px" }}
              className="RecivePaymentModel_header_name"
            >
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.41 5.41L14.58 0.58C14.21 0.21 13.7 0 13.17 0H2C0.9 0 0 0.9 0 2V14.01C0 15.11 0.89 16 1.99 16H18C19.1 16 20 15.1 20 14V6.83C20 6.3 19.79 5.79 19.41 5.41ZM13 1.5L18.5 7H14C13.45 7 13 6.55 13 6V1.5Z"
                  fill="white"
                />
              </svg>
              <p style={{ marginLeft: "-10px" }}>
                {addDataIngTable ? "Add" : "Edit"} Note{" "}
              </p>
              <span
                style={{
                  padding: "2px 9px",
                  gap: "8px",
                  width: "45px",
                  height: "24px",
                  background: "#FEF2F2",
                  borderRadius: "4px",
                }}
              >
                <p
                  style={{
                    color: "#DC2626",
                    fontFamily: "Readex Pro",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "10px",
                    lineHeight: "20px",
                    width: "21px",
                    height: "20px",
                  }}
                >
                  AE.1
                </p>
              </span>
            </div>
            <svg
              onClick={() => {
                RestFields();
                setNoteEditTable(false);
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          {errorhandling ? (
            <ApiError />
          ) : (
            <div className="editTable_notes">
              <div className="editTable_notes_testarea">
                <textarea
                  value={fields.notes}
                  placeholder="Note will be written here *"
                  onChange={(e) => {
                    if (e.target.value) {
                      setNoteValidation("");
                    }
                    setfields({
                      ...fields,
                      notes: e.target.value,
                    });
                  }}
                />
                <p style={{ color: "red" }}>{NoteValidation}</p>
              </div>
              <div className="editTable_notes_bottom">
                <div className="editTable_notes_bottom_dropdown">
                  <p className="go-to-label-filed">
                    Note type{" "}
                    <em style={{ color: "red", fontSize: "15px" }}>*</em>
                  </p>
                  <DropDown
                    dropDownData={activityNoteType}
                    fields={fields}
                    setfields={setfields}
                    setNoteTypeValidation={setNoteTypeValidation}
                    fieldNmae={"BankAccount"}
                  />
                  {NoteTypeValidation && (
                    <p style={{ color: "red" }}>{NoteTypeValidation}</p>
                  )}
                </div>
                <button
                  className="btn"
                  onClick={() => {
                    CopyLastNoteFunction();
                  }}
                >
                  Copy last note
                  {copyicon ? (
                    <svg
                      className="svg-blue-white"
                      width="19"
                      height="22"
                      viewBox="0 0 19 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 0H2C0.9 0 0 0.9 0 2V15C0 15.55 0.45 16 1 16C1.55 16 2 15.55 2 15V3C2 2.45 2.45 2 3 2H13C13.55 2 14 1.55 14 1C14 0.45 13.55 0 13 0ZM13.59 4.59L18.42 9.42C18.79 9.79 19 10.3 19 10.83V20C19 21.1 18.1 22 17 22H5.99C4.89 22 4 21.1 4 20L4.01 6C4.01 4.9 4.9 4 6 4H12.17C12.7 4 13.21 4.21 13.59 4.59ZM13 11H17.5L12 5.5V10C12 10.55 12.45 11 13 11Z"
                        fill="#424167"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="14"
                      height="16"
                      style={{ marginLeft: "5px" }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill="#26d12a"
                      ></path>
                    </svg>
                  )}
                </button>
                <div className="editTable_notes_bottom_dropdown_input">
                  <input
                    checked={fields.ArrearageRelated}
                    onClick={() => {
                      setfields({
                        ...fields,
                        ArrearageRelated: !fields.ArrearageRelated,
                      });
                    }}
                    type="checkbox"
                  />
                  <p className="go-to-label-filed">Arrearage Related</p>
                </div>
                <div
                  style={{ width: "15%" }}
                  className="editTable_notes_bottom_dropdown_input"
                >
                  <input
                    checked={fields.onCall}
                    onClick={() => {
                      setfields({
                        ...fields,
                        onCall: !fields.onCall,
                      });
                    }}
                    type="checkbox"
                  />
                  <p className="go-to-label-filed">on Call</p>
                </div>
              </div>
              <div className="editTable_notes_bottom_done_button">
                <button
                  onClick={() => {
                    if (addDataIngTable) {
                      AddFunction();
                    } else {
                      editFunction();
                    }
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          )}
        </div>
      </ScrollView>
    </>
  );
};
