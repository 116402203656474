import { useCallback, useEffect, useState } from "react";
import { ColumnDropdown } from "../../../../../../components/Dropdownn";
import "../../drugTestRandomizer.css";
import { DataGrid, DropDownBox } from "devextreme-react";
import { Column, FilterRow, Selection } from "devextreme-react/data-grid";
import axios from "axios";
import { handleGetLocal } from "../../../../../../services/auth-service";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

export const AddEditDrugTestSchedule = ({
  addEditDrugTestSchedulePopup,
  setAddEditDrugTestSchedulePopup,
  drugTestScheduleType,
  userDropdownValue,
  locationDropdownValues,
  drugTestScheduleRowData,
  testDateDropdownValue,
  toastConfig,
  setToastConfig,
  getReportDateRange,
}) => {
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    person: { name: "", id: "" },
    drugTestLocation: { name: "", id: "" },
  });
  const [selectedValue, setSelectedValue] = useState({
    "Test Time": "",
    Slot: "",
    "Test Date": "",
    gTestDateId: "",
    "Test Date Time": "",
  });
  const [dataSource, setDataSource] = useState([]);
  const [personValidationError, setPersonValidationError] = useState("");
  const [testDateSlotValidationError, setTestDateSlotValidationError] = useState("");
  const pathname = handleGetLocal("pathURL");
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);

  useEffect(() => {
    setTestDateSlotValidationError("");
    setPersonValidationError("");
    setDataSource(testDateDropdownValue);
    if (drugTestScheduleType === "Add") {
      setFields({
        person: { name: "", id: "" },
        drugTestLocation: { name: "", id: "" },
      });
      setSelectedValue({
        "Test Time": "",
        Slot: "",
        "Test Date": "",
        gTestDateId: "",
        "Test Date Time": "",
      });
    } else {
      const selectedDocket = dataSource?.find(
        (item) => item?.gTestDateId?.toLowerCase() === drugTestScheduleRowData?.gTestDateId
      );
      setSelectedValue(selectedDocket);
      function findDropdown(array, keyToFind) {
        const foundItem = array?.find((item) => item?.key?.toLowerCase() === keyToFind);
        return foundItem ? { name: foundItem.value, id: foundItem.key } : { name: "", id: "" };
      }
      setFields((prev) => ({
        ...prev,
        person: findDropdown(userDropdownValue, drugTestScheduleRowData?.gDrugTestRandomizerId),
      }));
    }
  }, [addEditDrugTestSchedulePopup]);

  const data = {
    GDrugTestRandomizerScheduleId:
      drugTestScheduleType === "Add"
        ? "00000000-0000-0000-0000-000000000000"
        : drugTestScheduleRowData?.gDrugTestRandomizerScheduleId,
    GDrugTestRandomizerId: fields?.person?.id,
    GEntryUserId: localStorage.getItem("gUserId"),
    GTestDateId: selectedValue?.gTestDateId,
  };

  const handleSave = async () => {
    let isValid = true;
    if (!selectedValue?.gTestDateId) {
      setTestDateSlotValidationError("Please Select Test Date/Slot");
      isValid = false;
    }
    if (!fields.person?.id) {
      setPersonValidationError("Please Select Person");
      isValid = false;
    }
    if (isValid) {
      setLoading(true);
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/RandomDrugTesting/add-edit-drug-test`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        })
        .then((val) => {
          setLoading(false);
          if (val?.data?.isSuccess) {
            setAddEditDrugTestSchedulePopup(false);
            getReportDateRange();
            setToastConfig({
              ...toastConfig,
              isVisible: true,
              message: drugTestScheduleType === "Add" ? "Successfully Added!" : "Successfully Updated!",
              type: "success",
            });
            setTimeout(() => {
              setToastConfig({
                ...toastConfig,
                isVisible: false,
                message: drugTestScheduleType === "Add" ? "Successfully Added!" : "Successfully Updated!",
                type: "success",
              });
            }, 1900);
          } else {
            console.error(val.data.responseMessage);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  };
  const onGridBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }, []);

  return (
    <>
      <div className="add-drug-test-schedule">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="add" clipPath="url(#clip0_5349_5505)">
                  <path
                    id="Vector"
                    d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                    fill="#fff"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5349_5505">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>Drug Test {drugTestScheduleType === "Add" ? "Add" : "Edit"}</p>
            </div>
            <p class="popup_header_title_para">DT.10</p>
          </div>
          <svg
            onClick={() => {
              setAddEditDrugTestSchedulePopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="add-drug-test-schedule-content">
          <div className="inputs-fields-container">
            <div className="inputs-field-content dropdown-inputs-field-content">
              <label className="edit_container_label">
                Person
                <span className="marks_mandatary">*</span>
              </label>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"person"}
                dropDownData={userDropdownValue?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
                reset={setPersonValidationError}
                disabled={drugTestScheduleType === "Edit"}
              />
              {personValidationError && <p className="error_validation">{personValidationError}</p>}
            </div>
            <div className="inputs-field-content dropdown-inputs-field-content">
              <label className="edit_container_label">Drug Test Location</label>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"location"}
                dropDownData={locationDropdownValues?.location?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
              />
            </div>
          </div>
          <div className="inputs-fields-container">
            <div className="inputs-field-content dropdown-inputs-field-content">
              <label className="edit_container_label">
                Test Date/Slot
                <span className="marks_mandatary">*</span>
              </label>
              <DropDownBox
                placeholder="Select..."
                dataSource={dataSource}
                value={selectedValue?.["Test Date Time"]}
                valueExpr={"Test Date Time"}
                displayExpr={"Test Date Time"}
                opened={isGridBoxOpened}
                onOptionChanged={onGridBoxOpened}
                contentRender={(value, element) => (
                  <DataGrid
                    dataSource={dataSource}
                    hoverStateEnabled={true}
                    height={180}
                    onRowClick={(e) => {
                      setSelectedValue(e?.data);
                      setTestDateSlotValidationError("");
                      setIsGridBoxOpened(false);
                    }}
                  >
                    <Selection mode="single" />
                    <FilterRow visible={true} />
                    <Column dataField="Test Date" caption="Test Date" width={"auto"} />
                    <Column dataField="Test Time" caption="Test Time" width={"auto"} />
                    <Column dataField="Slot" caption="Slot" width={"auto"} />
                  </DataGrid>
                )}
              />
              {testDateSlotValidationError && <p className="error_validation">{testDateSlotValidationError}</p>}
            </div>
          </div>
          <div className="popup_buttons">
            <button className="btn primary_btn" onClick={handleSave}>
              Save
            </button>
            <button
              className="btn"
              onClick={() => {
                setAddEditDrugTestSchedulePopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
