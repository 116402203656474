import { useEffect, useState } from "react";
import { Obligation } from "../../../Dockect/overview/Obligation";
import { GoToDocketSubTabFunction } from "../GoToDocketSubTabFunction";
import { useNavigate } from "react-router-dom";

export const GoToDocketObligation = ({
  dockerValue,
  dockerHeaderData,
  callObligationApi,
  setCallObligationApi,
  errorhandling,
  seterrorhandling,
  obligations,
  setobligations,
  loading,
  userinfo,
}) => {
  const clas = new GoToDocketSubTabFunction();
  const navigate = useNavigate();
  const [dropdownData, setDropdownData] = useState([]);

  useEffect(() => {
    clas.getDropdownDatas(setDropdownData, navigate);
  }, []);

  return (
    <>
      <Obligation
        callObligationApi={callObligationApi}
        setCallObligationApi={setCallObligationApi}
        obligations={obligations}
        loading={loading}
        dropdownData={dropdownData}
        getTopDynamicDocketid={dockerValue?.getTopDynamicDocketid}
        header={dockerHeaderData}
        errorhandling={errorhandling}
        userinfo={userinfo}
      />
    </>
  );
};
