import { Button, DateBox, DropDownBox, List, Popup, TextBox } from "devextreme-react";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as AccordionOpenImage } from "../../../../../../assets/images/icons/accordionOpen.svg";
import { ReactComponent as AccordionCloseImage } from "../../../../../../assets/images/icons/accordionClose.svg";
import { ReactComponent as EditImage } from "../../../../../../assets/images/icons/editPen.svg";
import { Navigate } from "react-router-dom";
import { GoTOCaseApiFunction } from "./GoTOCaseApiFunction";
import SelectListItem from "./SelectListItem";

const DropdownTextInput = ({ list, width, disabled, value, onChange, text }) => {
  const [selectListItemPopupOpen, setSelectListItemPopupOpen] = useState(false);

  const handleButtonClick = () => {
    setSelectListItemPopupOpen(true);
  };

  const handleInputChange = (e) => {
    onChange(e.value);
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <Button icon="chevrondown" onClick={handleButtonClick} disabled={disabled} stylingMode="text"></Button>
        <TextBox value={value} onValueChanged={handleInputChange} width={width} disabled={disabled} />
      </div>
      <Popup
        visible={selectListItemPopupOpen}
        onHiding={() => {
          setSelectListItemPopupOpen(false);
        }}
        showTitle={false}
        width={ 700}
        height={250}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <SelectListItem
          setSelectListItemPopupOpen={setSelectListItemPopupOpen}
          text={text}
          list={list}
          onChange={onChange}
        />
      </Popup>
    </>
  );
};

const AccordionItem = (props) => {
  const { parentIndex, name, isOpen, onClick, goals, factors,
    actionStep, criminogenicData, setCriminogenicData, setGoals, EditTogel, gCriminogenicNeedID } =
    props;
  console.log('accordian criminogenicData', criminogenicData)
  console.log('accordian CriminogenicNeedID', gCriminogenicNeedID)
  const contentHeight = useRef(null);
  const [loading, setLoading] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [errorhandling, seterrorhandling] = useState(false);
  const [goalListDropdown, setGoalListDropdown] = useState([]);
  const [actionStepListDropdown, setActionStepListDropdown] = useState([]);
  const apiClass = new GoTOCaseApiFunction();

  const handleGoalChange = (value, parentIndex, childIndex) => {
    const updatedData = [...criminogenicData];
    updatedData[parentIndex].goals[childIndex].sGoal = value;
    setCriminogenicData(updatedData);
  };

  const handleFactorChange = (e, parentIndex, childIndex) => {
    const updatedData = [...criminogenicData];
    updatedData[parentIndex].factors[childIndex].sFactor = e.target.value;
    setCriminogenicData(updatedData);
  };

  const handleActionStepChange = (value, parentIndex, childIndex) => {
    const updatedData = [...criminogenicData];
    updatedData[parentIndex].actionStep[childIndex].sActionStep = value;
    setCriminogenicData(updatedData);
  };

  const toggleDisable = (parentIndex, childIndex, type) => {
    const updatedData = [...criminogenicData];
    if (type === "goal") {
      updatedData[parentIndex].goals[childIndex].isDisable = !updatedData[parentIndex].goals[childIndex].isDisable;
    } else if (type === "factor") {
      updatedData[parentIndex].factors[childIndex].isDisable = !updatedData[parentIndex].factors[childIndex].isDisable;
    } else if (type === "actionStep") {
      updatedData[parentIndex].actionStep[childIndex].isDisable =
        !updatedData[parentIndex].actionStep[childIndex].isDisable;
    }
    setCriminogenicData(updatedData);
  };

  const handleDateChange = (newDate, parentIndex, childIndex, fieldName) => {
    const updatedData = [...criminogenicData];
    updatedData[parentIndex].actionStep[childIndex][fieldName] = newDate;
    setCriminogenicData(updatedData);
  };

  //Api Function
  const saveGoalData = (parentIndex, childIndex) => {
    const goalData = {
      gGoalId: criminogenicData[parentIndex].goals[childIndex].gGoalId,
      sGoal: criminogenicData[parentIndex].goals[childIndex].sGoal,
      gEntryUserId: criminogenicData[parentIndex].goals[childIndex].gEntryUserId,
      gCaseCriminogenicNeedID: criminogenicData[parentIndex].goals[childIndex].gCaseCriminogenicNeedId,
    };
    apiClass.editGoals(setLoading, seterrorhandling, Navigate, goalData);
  };
  const saveResponsivityFactorData = (parentIndex, childIndex) => {
    const responsivityData = {
      gCaseResponsivityFactorID: criminogenicData[parentIndex].factors[childIndex].gCaseResponsivityFactorId,
      sFactor: criminogenicData[parentIndex].factors[childIndex].sFactor,
    };
    apiClass.editResponsivityFactors(setLoading, seterrorhandling, Navigate, responsivityData);
  };

  const saveActionStepsData = (parentIndex, childIndex) => {
    console.log("saveActionStepsData", criminogenicData[parentIndex].actionStep[childIndex]);
    const actionStepsData = {
      gActionStepID: criminogenicData[parentIndex].actionStep[childIndex].gActionStepId,
      sActionStep: criminogenicData[parentIndex].actionStep[childIndex].sActionStep,
      dCompleteBy: criminogenicData[parentIndex].actionStep[childIndex].dCompleteBy,
      dCompleted: criminogenicData[parentIndex].actionStep[childIndex].dCompleted,
      gCaseCriminogenicNeedID: criminogenicData[parentIndex].goals[childIndex].gCaseCriminogenicNeedId,
    };
    console.log("actionStepsData", actionStepsData);
    apiClass.editActionSteps(setLoading, seterrorhandling, Navigate, actionStepsData);
  };

  const handleDeleteGoal = (parentIndex, childIndex) => {
    // Create a new copy of the state
    const updatedData = [...criminogenicData];
    // Remove the goal from the array using splice
    updatedData[parentIndex].goals.splice(childIndex, 1);
    // Update the state with the modified array
    setCriminogenicData(updatedData);
  };

  const handleDeleteActionStep = (parentIndex, childIndex) => {
    // Create a new copy of the state
    const updatedData = [...criminogenicData];
    // Remove the goal from the array using splice
    updatedData[parentIndex].actionStep.splice(childIndex, 1);
    // Update the state with the modified array
    setCriminogenicData(updatedData);
  };

  useEffect(() => {
    apiClass.GetCasePlanGoalList(setLoading, Navigate, seterrorhandling, setGoalListDropdown, gCriminogenicNeedID);
    apiClass.GetCasePlanActionStepList(setLoading, Navigate, seterrorhandling, setActionStepListDropdown, gCriminogenicNeedID);
  }, [gCriminogenicNeedID]);

  // for add more goals
  const handleAddGoals = () => {
    const newGoal = {
      gGoalId: "00000000-0000-0000-0000-000000000000",
      dDoe: "",
      gEntryUserId: localStorage.getItem("gUserId"),
      sGoal: "",
      gCaseCriminogenicNeedId: criminogenicData[parentIndex].gCaseCriminogenicNeedID,
      isDisable: false,
    };
    const updatedData = [...criminogenicData];
    updatedData[parentIndex].goals.push(newGoal);
    setCriminogenicData(updatedData);
  };

  // for add more action step
  const handleAddActionStep = () => {
    const newActionStep = {
      gActionStepId: "00000000-0000-0000-0000-000000000000",
      gCaseCriminogenicNeedId: criminogenicData[parentIndex].gCaseCriminogenicNeedID,
      sActionStep: "",
      dCompleteBy: null,
      dCompleted: null,
      isDisable: false,
    };
    const updatedData = [...criminogenicData];
    updatedData[parentIndex].actionStep.push(newActionStep);
    setCriminogenicData(updatedData);
  };

  return (
    <>
      <div className="accordion-wrapper">
        <div className={`wrapper-name-container ${isOpen ? "active" : ""}`} onClick={onClick}>
          <p className="wrapper-name">{name}</p>
          {!isOpen ? <AccordionOpenImage /> : <AccordionCloseImage />}
        </div>
        {isOpen && (
          <div
            ref={contentHeight}
            style={isOpen ? { height: contentHeight?.current?.scrollHeight } : { height: "0px" }}
          >
            <div className="accordion-open-container">
              <div className="container-data">
                <div className="container-heading">Goals</div>
                {goals?.map((item, index) => {
                  return (
                    <div className="container-inputs-fields" key={index}>
                      {/* {item.isDisable ? (
                        <EditImage
                          className="svg-blue-white"
                          onClick={() => toggleDisable(parentIndex, index, "goal")}
                        />
                      ) : */}
                      {/* ( */}
                      <>
                        <button
                          className="btn"
                          // onClick={() => {
                          //   toggleDisable(parentIndex, index, "goal");
                          //   saveGoalData(parentIndex, index);
                          // }}
                          onClick={() => handleDeleteGoal(parentIndex, index)}
                          disabled={!EditTogel}
                        >
                         Delete
                        </button>
                      </>
                      {/* )} */}
                      <DropdownTextInput
                        list={goalListDropdown.map((goalList) => goalList.sGoal)}
                        width={700}
                        //disabled={item.isDisable}
                        value={item.sGoal}
                        onChange={(val) => handleGoalChange(val, parentIndex, index)}
                        text={"Select Goal"}
                        disabled={!EditTogel}
                      />
                    </div>
                  );
                })}
                <div className="buttons" style={{ marginTop: "10px" }}>
                  <button
                    className="btn"
                    onClick={() => {
                      handleAddGoals();
                    }}
                    disabled={!EditTogel}
                  >
                    Add Goal
                  </button>
                </div>
                <div className="border-bottom"></div>
              </div>
              {/* <div className="container-data">
                <div className="container-heading">Responsivity Factors</div>
                {factors?.map((item, index) => {
                  return (
                    <>
                      <div>{item.factorName}</div>
                      <div className="container-inputs-fields" key={index}>

                        {item.isDisable ? (
                          <EditImage
                            className="svg-blue-white"
                            onClick={() => toggleDisable(parentIndex, index, "factor")}
                          />
                        ) : (
                          <button
                            className="btn"
                            onClick={() => {
                              toggleDisable(parentIndex, index, "factor");
                              saveResponsivityFactorData(parentIndex, index);
                            }}
                          >
                            Save
                          </button>
                        )}
                        <input
                          type="text"
                          value={item.sFactor}
                          name={item.sFactor}
                          id={item.gCaseResponsivityFactorId}
                          onChange={(e) => {
                            handleFactorChange(e, parentIndex, index);
                          }}
                          className="input-field"
                          disabled={item.isDisable}
                        />
                      </div>
                    </>

                  );
                })}
                <div className="border-bottom"></div>
              </div> */}
              <div className="container-data">
                <div className="multiple-columns">
                  <div className="action_steps">
                    <p>Action Steps</p>
                  </div>
                  <div className="date">
                    <p>Complete By Date</p>
                    <p>Complete Date</p>
                  </div>

                </div>
                {actionStep?.map((item, index) => {
                  return (
                    <div className="container-inputs-fields">
                      {/* {item.isDisable ? (
                        <EditImage
                          className="svg-blue-white"
                          onClick={() => toggleDisable(parentIndex, index, "actionStep")}
                        />
                      ) : ( */}
                      <>
                        <button
                          className="btn"
                          // onClick={() => {
                          //   toggleDisable(parentIndex, index, "actionStep");
                          //   saveActionStepsData(parentIndex, index);
                          // }}

                          onClick={() => handleDeleteActionStep(parentIndex, index)}
                          disabled={!EditTogel}
                        >
                          Delete 
                        </button>
                      </>
                      {/* )} */}
                      <DropdownTextInput
                        list={actionStepListDropdown.map((actionStepList) => actionStepList.sActionStep)}
                        width={1132}
                        //disabled={item.isDisable}
                        value={item.sActionStep}
                        onChange={(val) => handleActionStepChange(val, parentIndex, index)}
                        text={"Select ActionStep"}
                        disabled={!EditTogel}
                      />
                      <DateBox
                        useMaskBehavior={true}
                        value={item.dCompleteBy}
                        //disabled={item.isDisable}
                        onValueChange={(e) => handleDateChange(e, parentIndex, index, "dCompleteBy")}
                        disabled={!EditTogel}
                      />
                      <DateBox
                        useMaskBehavior={true}
                        value={item.dCompleted}
                        // disabled={item.isDisable}
                        onValueChange={(e) => handleDateChange(e, parentIndex, index, "dCompleted")}
                        disabled={!EditTogel}
                      />
                    </div>
                  );
                })}
                <div className="buttons" style={{ marginTop: "10px" }}>
                  <button
                    className="btn"
                    onClick={() => {
                      handleAddActionStep();
                    }}
                    disabled={!EditTogel}
                  >
                    Add Action Step
                  </button>
                </div>
                <div className="border-bottom"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AccordionItem;
