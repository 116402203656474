import React, { useEffect, useRef, useState } from 'react';
import { create, createOptions, ViewType, RichEditUnit, DocumentFormat } from 'devexpress-richedit';
import 'devextreme/dist/css/dx.light.css';

const DWRichEditComponent = ({
    documentWizardOutputData,
    documentAsBase64, setdocumentAsBase64,
    childDocumentWizardOutputData,
    // childdocumentAsBase64,
    // setchilddocumentAsBase64,
    childHtmlData,
    setChildHtmlData,
    editToggle,
    setHtmlData,
}) => {
    const richRef = useRef(null);
    const containerRef = useRef(null);
    const childRichRefs = useRef([]);
    const [field, setField] = useState(documentAsBase64);
    const [activeTab, setActiveTab] = useState(1);
    const [childContents, setChildContents] = useState({});

    // const handleTabClick = (tab) => {
    //     setActiveTab(tab);
    // };


    const handleTabClick = async (tab) => {
        // Save the current document content before switching tabs
        if (activeTab === 1 && richRef.current) {
            const content = await richRef.current.saveDocument(DocumentFormat.Rtf);
            setField(content);
            setdocumentAsBase64(content);
        } else if (activeTab > 1) {
            const content = await childRichRefs.current[childHtmlData[activeTab - 2].gParentId].saveDocument(DocumentFormat.Rtf);
            setChildContents((prev) => ({
                ...prev,
                [childHtmlData[activeTab - 2].gParentId]: content
            }));
            handleChildHtmlChange(content, childHtmlData[activeTab - 2].gParentId);
        }
        setActiveTab(tab);
    };

    const handleChildHtmlChange = (value, gParentId) => {
        const updatedChildHtmlData = childHtmlData.map((child) => {
            if (child.gParentId === gParentId) {
                return { ...child, sReportRtf: value };
            }
            return child;
        });
        setChildHtmlData(updatedChildHtmlData);
    };


    // const isValidBase64 = (str) => {
    //     if (!str || str.trim() === "") {
    //         return false;
    //     }
    //     const base64Pattern = /^(?:[A-Z0-9+/]{4})*(?:[A-Z0-9+/]{2}==|[A-Z0-9+/]{3}=)?$/i;
    //     return base64Pattern.test(str);
    // };

    const htmlToBase64 = (html) => {
        const utf8Bytes = new TextEncoder().encode(html);
        let binaryString = '';
        utf8Bytes.forEach(byte => {
            binaryString += String.fromCharCode(byte);
        });
        return btoa(binaryString);
    };

    const rtfToBase64=(rtf) =>{
        // Convert RTF string to a byte array
        const byteArray = new TextEncoder().encode(rtf);
    
        // Convert the byte array to Base64
        const base64String = btoa(String.fromCharCode(...byteArray));
    
        return base64String;
    }

    useEffect(() => {
        if(activeTab===1)
        {
            if (richRef.current) return;

            const options = createOptions();
            options.bookmarks.visibility = true;
            options.bookmarks.color = '#ff0000';
            options.confirmOnLosingChanges.enabled = false;
            // options.confirmOnLosingChanges.message = 'Are you sure you want to perform the action? All unsaved document data will be lost.';
            options.fields.updateFieldsBeforePrint = true;
            options.fields.updateFieldsOnPaste = true;
            options.mailMerge.activeRecord = 2;
            options.mailMerge.viewMergedData = true;
            options.mailMerge.dataSource = [
                { Name: 'Indy', age: 32 },
                { Name: 'Andy', age: 28 },
            ];
            options.events.documentChanged = () => {
                if (richRef.current) {
                    const content = richRef.current.saveDocument(DocumentFormat.Rtf);
                    if (content && typeof content.then === 'function') {
                        content.then((result) => {
                            console.log('Document content after change:', result);
                            setField(result);
                        }).catch((error) => {
                            console.log('Error saving document:', error);
                        });
                    }
                }
            };
    
            // Events setup
            options.events.lostFocus = (s, e) => {
                // richRef.current.saveDocument();
            };
    
            options.events.saving = (s, e) => {
                setField(e.base64);
                // SaveData();
            };
    
            options.events.saved = (s, e) => {
            }
    
    
            options.unit = RichEditUnit.Inch;
            options.view.viewType = ViewType.PrintLayout;
            options.view.simpleViewSettings.paddings = { left: 15, top: 15, right: 15, bottom: 15 };
            options.exportUrl = 'https://siteurl.com/api/';
            options.readOnly = false;
            options.width = '100%';
            options.height = '980px';
    
            richRef.current = create(containerRef.current, options);
            richRef.current.openDocument(documentAsBase64, documentWizardOutputData.sName, DocumentFormat.Rtf);
    
            return () => {
                if (richRef.current) {
                    richRef.current.dispose();
                    richRef.current = null;
                }
            };
        }
        
    }, [activeTab]);


    useEffect(() => {
        if (richRef.current && documentAsBase64 !== field) {
            if (richRef.current.openDocument) {
                richRef.current.openDocument(documentAsBase64, 'reportname', DocumentFormat.Rtf);
            }
        }
        else {
            richRef.current.openDocument(documentAsBase64, 'reportname', DocumentFormat.Rtf);
        }
    }, [documentAsBase64]);

    useEffect(() => {
        if (field !== documentAsBase64) {
            setdocumentAsBase64(field);
        }
    }, [field]);

    useEffect(() => {
            childHtmlData.forEach((child, index) => {
                if (!childRichRefs.current[child.gParentId] && activeTab === index + 2) {
                    const options = createOptions();
                    options.unit = RichEditUnit.Inch;
                    options.confirmOnLosingChanges.enabled = false;
                    options.view.viewType = ViewType.PrintLayout;
                    options.width = '100%';
                    options.height = '980px';
                    options.readOnly = false;

                    options.events.documentChanged = () => {
                        if (childRichRefs.current[child.gParentId]) {
                            const content = childRichRefs.current[child.gParentId].saveDocument(DocumentFormat.Rtf);
                            if (content && typeof content.then === 'function') {
                                content.then((result) => {
                                    handleChildHtmlChange(result, child.gParentId);
                                }).catch((error) => {
                                    console.log('Error saving child document:', error);
                                });
                            }
                        }
                    };

                    const containerRefChild = document.getElementById(`richEditChild-${child.gParentId}`);
                    if (containerRefChild) {
                        childRichRefs.current[child.gParentId] = create(containerRefChild, options);
                        childRichRefs.current[child.gParentId].openDocument(rtfToBase64(child.sReportRtf), childHtmlData.sName, DocumentFormat.Rtf);
                    }
                }
            });

    }, [activeTab, childHtmlData]);

    useEffect(() => {
        if (activeTab > 1 && childHtmlData[activeTab - 2]) {
            const currentChildContent = childContents[childHtmlData[activeTab - 2].gParentId] || rtfToBase64(childHtmlData[activeTab - 2].sReportRtf);
            if (childRichRefs.current[childHtmlData[activeTab - 2].gParentId]) {
                childRichRefs.current[childHtmlData[activeTab - 2].gParentId].openDocument(currentChildContent, 'childReport', DocumentFormat.Rtf);
            }
        }
    }, [activeTab, childContents]);



    // console.log('field', field)
    // console.log('documentAsBase64', documentAsBase64)

    // return (
    //     <div>
    //         <div id="richEdit" ref={containerRef}></div>
    //     </div>
    // );
    return (
        <>
            <div className="document-wizard-output-tab">
                <p className={documentWizardOutputData?.sName ? "html-data-tab-name" : ""} onClick={() => handleTabClick(1)} >
                    {documentWizardOutputData?.sName ? documentWizardOutputData?.sName : ""}
                </p>
                {childDocumentWizardOutputData &&
                    childDocumentWizardOutputData?.map((item, index) => {
                        return (
                            <p className={item?.sName ? "html-data-tab-name" : ""} onClick={() => handleTabClick(index + 2)}>
                                {item?.sName ? item?.sName : ""}
                            </p>
                        );
                    })}
            </div >
            <div className="document-wizardOutput-active-tab">
                {activeTab === 1 && (
                    <div className="editor-container">
                        <div id="richEdit" ref={containerRef}></div>
                    </div>
                )}
                {childHtmlData &&
                    childHtmlData?.map((item, index) => (
                        activeTab === index + 2 && (
                            <div className="editor-container" key={item?.gParentId}>
                                <div id={`richEditChild-${item.gParentId}`}></div>
                            </div>
                        )
                    ))}
            </div>
        </>
    );
};

export default DWRichEditComponent;







