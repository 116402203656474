import React, { useState } from "react";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { jailCompleteservicesTablecoloumn } from "../Data";
import { ApiError } from "../../../../Error/ApiError";

export const CompletedJailService = ({
  setVoidPopup,
  setAddJailServiceLogPopup,
  jailCompletedData,
  setJailRowData,
  setJailCompletedType,
  jailCompletedErorrHandling,
}) => {
  const [load, setLoad] = useState(false);

  return (
    <div className="planned_jail_service">
      {" "}
      {jailCompletedErorrHandling ? (
        <ApiError />
      ) : (
        <Table1
          editTable={false}
          height={400}
          Tablecoloum={jailCompleteservicesTablecoloumn}
          tableRow={jailCompletedData?.map((val, i) => {
            return { ...val, ID: i };
          })}
          load={load}
          coloumWidthText={"JailCompletedPlannedservices"}
          mandatoryColoum={"User"}
          setAddJailServiceLogPopup={setAddJailServiceLogPopup}
          jailMenu={true}
          setVoidPopup={setVoidPopup}
          setJailRowData={setJailRowData}
          jailCompletedMenu={true}
          setJailCompletedType={setJailCompletedType}
          setVoideFalse={true}
        />
      )}
    </div>
  );
};
