import React, { useEffect, useState } from "react";
import EmPeriod from "./MonitoringSubtab/EmPeriod";
import EmEquipmentog from "./MonitoringSubtab/EmEquipmentog";
import { useNavigate } from "react-router-dom";
import { EmFunction } from "../../../EM/EmFunction";
import "./monitoring.css";
import { GoTOObligationFunction } from "../../GoTOObligationFunction";
import { CreateInvoice } from "./MonitoringSubtab/CreateInvoice";

const Monitoring = ({
  toastConfig,
  setToastConfig,
  gObligationID,
  emPeriodTableData,
  emEquipmentLogTableData,
  emEquipmentLogDataApi,
  emPeriodDataApi,
  gotooblogationValue,
  isAccrual,
}) => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [loading, setLoading] = useState(false);
  const [equipmentDropdown, setEquipmentDropdown] = useState([]);
  const navigate = useNavigate();
  const [errorhandling, setErrorhandling] = useState(false);
  const emApiClass = new EmFunction();
  const apiClass = new GoTOObligationFunction();
  const [returnStatusDropdown, setReturnStatusDropdown] = useState([]);
  const [equipmentStatusDropdown, setEquipmentStatusDropdown] = useState([]);
  const [emTypeDropdownData, setEmTypeDropdownData] = useState([]);
  const [frequencyDropdownData, setFrequencyDropdownData] = useState([]);
  const [paymentTypeDropdownData, setPaymentTypeDropdownData] = useState([]);
  const [equipmentTypeDropdownData, setEquipmentTypeDropdownData] = useState([]);
  const [obligationDropdownData, setObligationDropdownData] = useState([]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    emApiClass.getEquimentLogDropdownData(setLoading, navigate, setErrorhandling, setEquipmentDropdown);
    emApiClass.getEqipmentDropdownApi(setLoading, navigate, setReturnStatusDropdown, setEquipmentStatusDropdown);
    apiClass.getEmPeriodDropdownData(
      navigate,
      setFrequencyDropdownData,
      setEmTypeDropdownData,
      setPaymentTypeDropdownData,
      setEquipmentTypeDropdownData
    );
    apiClass.getObligationDropdownData(navigate, gotooblogationValue?.gPersonId, setObligationDropdownData);
  }, []);

  return (
    <>
      <div className="payment-credits-tab">
        <div className="payment-credits-tab-nav">
          <button
            className={
              activeTab === "tab1"
                ? "payment-credits-tab-active payment-credits-tab-nav-button"
                : "payment-credits-tab-nav-button"
            }
            onClick={() => handleTabClick("tab1")}
          >
            EM Period
          </button>
          <button
            className={
              activeTab === "tab2"
                ? "payment-credits-tab-active payment-credits-tab-nav-button"
                : "payment-credits-tab-nav-button"
            }
            onClick={() => handleTabClick("tab2")}
          >
            EM Equipment Log
          </button>
          {/* <button
            className={
              activeTab === "tab3"
                ? "payment-credits-tab-active payment-credits-tab-nav-button"
                : "payment-credits-tab-nav-button"
            }
            onClick={() => handleTabClick("tab3")}
          >
            Create Invoice
          </button> */}
        </div>
        <div className="payment-credits-tab-data">
          {activeTab === "tab1" && (
            <EmPeriod
              emPeriodTableData={emPeriodTableData}
              loading={loading}
              setLoading={setLoading}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              emPeriodDataApi={emPeriodDataApi}
              gObligationID={gObligationID}
              emTypeDropdownData={emTypeDropdownData}
              frequencyDropdownData={frequencyDropdownData}
              gotooblogationValue={gotooblogationValue}
              paymentTypeDropdownData={paymentTypeDropdownData}
              obligationDropdownData={obligationDropdownData}
              equipmentTypeDropdownData={equipmentTypeDropdownData}
              isAccrual={isAccrual}
            />
          )}
          {activeTab === "tab2" && (
            <EmEquipmentog
              emEquipmentLogTableData={emEquipmentLogTableData}
              loading={loading}
              setLoading={setLoading}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              equipmentDropdown={equipmentDropdown}
              returnStatusDropdown={returnStatusDropdown}
              equipmentStatusDropdown={equipmentStatusDropdown}
              refreshApi={emEquipmentLogDataApi}
              gObligationID={gObligationID}
            />
          )}
          {activeTab === "tab3" && <CreateInvoice />}
        </div>
      </div>
    </>
  );
};

export default Monitoring;
