import { ChecklistTab } from "../../../Dockect/overview/ChecklistTab";

export const GOToDocketChecklist = ({ dockerValue }) => {
  return (
    <>
      <ChecklistTab
        person={2}
        getTopDynamicDocketid={dockerValue?.getTopDynamicDocketid}
        AdCHeckLIst={'Docket'}
      />
    </>
  );
};
