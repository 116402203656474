import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  headers_title: [],
  table_data: [],
  table_name: "",
  selected_row: [],
  table_id: "",
  replace_headers_title: [],
  transformedData: [],
  adminTabs: [{ title: "Dashboard", link: "/home" }],
  userListID: "",
  AuthenticationPhone: "",
  tableExpandData: [],
  foreignKeyData: [],
  drugAddDocket: [],
  adminSavePopup: false,
  formsTabs: [{ title: "Dashboard", link: "/home" }],
  taskssTabs: [{ title: "Dashboard", link: "/home" }],
  schedulingTabs: [{ title: "Dashboard", link: "/home" }],
  ProntactEntitiesdynamicTab: [],
  testDateSlotCount: [],
  addcreatetestdate: {},
  resetcreatetestdate: true,
  ProntactEntitieschange: false,
  ProntactContactsdynamicTab: [],
  ProntactEntitiesSubTabAddresData: {
    MailingAddress: {
      AddressLine1: "",
      AddressLine2: "",
      City: "",
      State: "",
      ZIP: "",
      Country: "",
      Comment: "",
    },
    HomeAddress: {
      AddressLine1: "",
      AddressLine2: "",
      City: "",
      State: "",
      ZIP: "",
      Country: "",
      Comment: "",
    },
    WorkAddress: {
      AddressLine1: "",
      AddressLine2: "",
      City: "",
      State: "",
      ZIP: "",
      Country: "",
      Comment: "",
    },
    isChange: false,
  },
  ProntactEntitiesSubTabinvoicingData: {
    EMailAddressList: "",
    BCCAddressList: "",
    InvoiceTemplet1: { name: "", id: "" },
    InvoiceTemplet2: { name: "", id: "" },
    InvoiceTemplet3: { name: "", id: "" },
  },
  ProntactEntitiesSubTabDockumentWizarddata: {
    LegalNameLine1: "",
    LegalNameLine2: "",
  },
  ContectEntityListTableAddData: [],
  ContectEntityListTableAddData2: [],
  addContactsInfoSaveData: [],
  financialTabs: [{ title: "Dashboard", link: "/home" }],
  monitoringTabs: [{ title: "Dashboard", link: "/home" }],
  disbursementCheckObligationListDate: [],
  emTableExpandData: [],
};

const slice = createSlice({
  name: "AdminTable",
  initialState,
  reducers: {
    set_admin_table(state, action) {
      state.headers_title = action.payload.columnHeaders;
      state.table_data = action.payload.data;
      state.replace_headers_title = action.payload.replace_headers_title;
      state.table_id = action.payload.table_id;
      state.foreignKeyData = action.payload.foreignKeyData;
      //change table_data
      const caption = state.replace_headers_title;
      const columns = state.headers_title;

      let transformedData = [];
      if (state.foreignKeyData) {
        transformedData = action.payload.data.map((obj) => {
          const newObj = {};
          Object.keys(obj).forEach((key) => {
            const columnIndex = columns.indexOf(key);
            if (columnIndex !== -1) {
              const foreignKeyColumn = state.foreignKeyData.find(
                (fk) => fk.columnname === key
              );
              if (foreignKeyColumn) {
                const matchingData = foreignKeyColumn.data.find(
                  (data) => data[foreignKeyColumn.columnname] === obj[key]
                );
                if (matchingData) {
                  const uniquePropertyName = Object.keys(matchingData)
                    .filter((prop) => prop !== foreignKeyColumn.columnname)
                    .map((prop) => matchingData[prop])
                    .join(" ");
                  newObj[caption[columnIndex]] = uniquePropertyName;
                  newObj[uniquePropertyName] = obj[key];
                } else {
                  newObj[caption[columnIndex]] = obj[key];
                }
              } else {
                newObj[caption[columnIndex]] = obj[key];
              }
            } else {
              newObj[key] = obj[key];
            }
          });
          return newObj;
        });
      } else {
        transformedData = action.payload.data.map((obj) => {
          const newObj = {};
          Object.keys(obj).forEach((key) => {
            const columnIndex = columns.indexOf(key);
            if (columnIndex !== -1) {
              newObj[caption[columnIndex]] = obj[key];
            } else {
              newObj[key] = obj[key];
            }
          });
          return newObj;
        });
      }

      state.transformedData = transformedData;
    },
    set_admin_table_name(state, action) {
      state.table_name = action.payload.tableName;
    },
    set_selected_row(state, action) {
      state.selected_row = action.payload.selected_row;
    },
    reset_table_data(state) {
      state.table_data = [];
      state.headers_title = [];
      state.table_id = "";
    },
    setAdmionTabs(state, action) {
      if (!state.adminTabs.some((obj) => obj?.link === action.payload.link)) {
        state.adminTabs = [...state.adminTabs, action.payload];
      } else {
        state.adminTabs = state.adminTabs.map((val) => {
          if (val.link == action.payload.link) {
            return {
              ...val,
              title: action.payload.title,
            };
          } else {
            return val;
          }
        });
      }
    },
    setresetAdmionTabs(state, action) {
      state.adminTabs = state.adminTabs.map((val) => {
        if (val?.link === action.payload) {
          return { ...val, title: "Fee Schedule" };
        } else {
          return val;
        }
      });
    },

    setresetbackAdmionTabs(state, action) {
      state.adminTabs = state.adminTabs.map((val) => {
        if (val?.link === action.payload.link) {
          return { ...val, title: `Fee Schedule: ${action.payload.title}` };
        } else {
          return val;
        }
      });
    },

    removeAdminTab(state, action) {
      state.adminTabs = state.adminTabs.filter(
        (item) => item.title !== action.payload
      );
    },
    AdminUserListID(state, action) {
      state.userListID = action.payload.userListID;
    },
    AddAuthenticationPhone(state, action) {
      state.AuthenticationPhone = action.payload.AuthenticationPhone;
    },
    OverviewTableExpand(state, action) {
      state.tableExpandData = action.payload.tableExpandData;
    },
    setDrugDropdown(state, action) {
      state.drugAddDocket = action.payload.drugAddDocket;
    },
    setAdminSavePopup(state, action) {
      state.adminSavePopup = action.payload.adminSavePopup;
    },
    setFormsTabs(state, action) {
      if (!state.formsTabs.some((obj) => obj?.title === action.payload.title)) {
        state.formsTabs = [...state.formsTabs, action.payload];
      }
    },
    setTaksTabs(state, action) {
      if (!state.tasksTabs.some((obj) => obj?.title === action.payload.title)) {
        state.tasksTabs = [...state.tasksTabs, action.payload];
      }
    },
    removeFormsTab(state, action) {
      state.formsTabs = state.formsTabs.filter(
        (item) => item.title !== action.payload
      );
    },
    setTasksTabs(state, action) {
      if (
        !state.taskssTabs.some((obj) => obj?.title === action.payload.title)
      ) {
        state.taskssTabs = [...state.taskssTabs, action.payload];
      }
    },
    setSchedulingTabs(state, action) {
      if (
        !state.schedulingTabs.some((obj) => obj?.title === action.payload.title)
      ) {
        state.schedulingTabs = [...state.schedulingTabs, action.payload];
      }
    },
    removeSchedulingTabs(state, action) {
      state.schedulingTabs = state.schedulingTabs.filter(
        (item) => item.title !== action.payload
      );
    },

    removeTasksTabs(state, action) {
      state.taskssTabs = state.taskssTabs.filter(
        (item) => item.title !== action.payload
      );
    },
    setProntactEntitiesdynamicTab(state, action) {
      if (
        !state.ProntactEntitiesdynamicTab.some(
          (obj) => obj?.Offender === action.payload.Offender
        )
      ) {
        state.ProntactEntitiesdynamicTab = [
          ...state.ProntactEntitiesdynamicTab,
          action.payload,
        ];
      }
    },
    removeProntactEntitiesdynamicTab(state, action) {
      state.ProntactEntitiesdynamicTab =
        state.ProntactEntitiesdynamicTab.filter(
          (item) => item.Offender !== action.payload
        );
    },
    addnewProntactEntitiesdynamicTab(state, action) {
      state.ProntactEntitiesdynamicTab = state.ProntactEntitiesdynamicTab.map(
        (val) => {
          if (action.payload == val?.Offender) {
            return {
              ...val,
              title: `Entity : [ New ]`,
            };
          } else {
            return val;
          }
        }
      );
    },
    removeaddProntactEntitiesdynamicTab(state, action) {
      state.ProntactEntitiesdynamicTab = state.ProntactEntitiesdynamicTab.map(
        (val) => {
          if (action.payload == val?.Offender) {
            return {
              ...val,
              title: `Entity`,
            };
          } else {
            return val;
          }
        }
      );
    },
    setaddcreatetestdate(state, action) {
      state.addcreatetestdate = action.payload;
    },
    setresetcreatetestdate(state, action) {
      state.resetcreatetestdate = action.payload;
    },
    setProntactEntitieschange(state, action) {
      state.ProntactEntitieschange = action.payload;
    },
    setProntactContactsdynamicTab(state, action) {
      if (
        !state.ProntactContactsdynamicTab.some(
          (obj) => obj?.link === action.payload.link
        )
      ) {
        state.ProntactContactsdynamicTab = [
          ...state.ProntactContactsdynamicTab,
          action.payload,
        ];
      } else {
        state.ProntactContactsdynamicTab = state.ProntactContactsdynamicTab.map(
          (val) => {
            if (val.link === action.payload.link) {
              return {
                ...val,
                title: action.payload.title,
              };
            } else {
              return val;
            }
          }
        );
      }
    },
    removeProntactContactsdynamicTab(state, action) {
      state.ProntactContactsdynamicTab =
        state.ProntactContactsdynamicTab.filter(
          (item) => item.title !== action.payload
        );
    },
    setResetProntactContactsdynamicTab(state, action) {
      state.ProntactContactsdynamicTab = state.ProntactContactsdynamicTab.map(
        (val) => {
          if (val?.link === action.payload) {
            return { ...val, title: "Contacts" };
          } else {
            return val;
          }
        }
      );
    },
    setResetBackProntactContactsdynamicTab(state, action) {
      state.ProntactContactsdynamicTab = state.ProntactContactsdynamicTab.map(
        (val) => {
          if (val?.link === action.payload.link) {
            return { ...val, title: `Contacts: ${action.payload.title}` };
          } else {
            return val;
          }
        }
      );
    },
    setProntactEntitiesSubTabAddresData(state, action) {
      state.ProntactEntitiesSubTabAddresData = action.payload;
    },
    setProntactEntitiesSubTabinvoicingData(state, action) {
      state.ProntactEntitiesSubTabinvoicingData = action.payload;
    },
    setProntactEntitiesSubTabDockumentWizarddata(state, action) {
      state.ProntactEntitiesSubTabDockumentWizarddata = action.payload;
    },
    setContectEntityListTableAddData(state, action) {
      state.ContectEntityListTableAddData = action.payload?.map((val) => {
        const { ID, ...vall } = val;
        return { ...val };
      });
    },
    setContectEntityListTableAddData2(state, action) {
      state.ContectEntityListTableAddData2 = action.payload?.map((val) => {
        const { ID, ...vall } = val;
        return { ...val };
      });
    },
    setAddContactsInfoSaveData(state, action) {
      state.addContactsInfoSaveData = action.payload;
    },
    setFinancialTabs(state, action) {
      if (
        !state.financialTabs.some((obj) => obj?.title === action.payload.title)
      ) {
        state.financialTabs = [...state.financialTabs, action.payload];
      }
    },
    removeFinancialTabs(state, action) {
      state.financialTabs = state.financialTabs.filter(
        (item) => item.title !== action.payload
      );
    },
    setMonitoringTabs(state, action) {
      if (
        !state.monitoringTabs.some((obj) => obj?.title === action.payload.title)
      ) {
        state.monitoringTabs = [...state.monitoringTabs, action.payload];
      }
    },
    removeMonitoringTabs(state, action) {
      state.monitoringTabs = state.monitoringTabs.filter(
        (item) => item.title !== action.payload
      );
    },
    setdisbursementCheckObligationListDate(state, action) {
      state.disbursementCheckObligationListDate = action.payload;
    },
    setEmOverviewTableExpand(state, action) {
      state.emTableExpandData = action.payload.emTableExpandData;
    },
  },
});

export const {
  set_admin_table,
  set_admin_table_name,
  set_selected_row,
  reset_table_data,
  setAdmionTabs,
  removeAdminTab,
  AdminUserListID,
  AddAuthenticationPhone,
  OverviewTableExpand,
  setDrugDropdown,
  setAdminSavePopup,
  setFormsTabs,
  setTaksTabs,
  removeFormsTab,
  setresetAdmionTabs,
  setresetbackAdmionTabs,
  setTasksTabs,
  setSchedulingTabs,
  removeSchedulingTabs,
  removeTasksTabs,
  setProntactEntitiesdynamicTab,
  removeProntactEntitiesdynamicTab,
  addnewProntactEntitiesdynamicTab,
  removeaddProntactEntitiesdynamicTab,
  getTestDateSlotCount,
  setaddcreatetestdate,
  setresetcreatetestdate,
  setProntactEntitieschange,
  setProntactContactsdynamicTab,
  removeProntactContactsdynamicTab,
  setResetProntactContactsdynamicTab,
  setResetBackProntactContactsdynamicTab,
  setProntactEntitiesSubTabAddresData,
  setProntactEntitiesSubTabinvoicingData,
  setProntactEntitiesSubTabDockumentWizarddata,
  setContectEntityListTableAddData,
  setContectEntityListTableAddData2,
  setAddContactsInfoSaveData,
  setFinancialTabs,
  removeFinancialTabs,
  setMonitoringTabs,
  removeMonitoringTabs,
  setdisbursementCheckObligationListDate,
  setEmOverviewTableExpand,
} = slice.actions;

export default slice.reducer;
