import React, { useEffect, useState, useRef } from "react";
import { List, LoadPanel, ScrollView } from "devextreme-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../components/Logout";
import { handleGetLocal } from "../../services/auth-service";

function ItemTemplate(data) {
  return <div>{data.name}</div>;
}

export const ReassignTasksPopup = ({
  setReassignTasksPopup,
  assignToOptions,
  selectedRowCheckbox,
  rowInfoData,
  toastConfig,
  setToastConfig,
  refreshApi,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [error, setError] = useState("");
  const [assignValue, setAssignValue] = useState({});
  const componentRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        setSelectedValue("");
        setError("");
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const navigate = useNavigate();

  const data = {
    gTaskIdList: selectedRowCheckbox,
    GUserId: assignValue,
  };

  const handleSave = async () => {
    // if (selectedValue === "") {
    //     setError("Please select a value");
    //     return;
    // }
    setError("");
    setLoading(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/task/reassign-tasks`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setReassignTasksPopup(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Task reassign successfully",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Task reassign successfully",
            type: "success",
          });
        }, 1900);

        if (refreshApi) refreshApi();
      })
      .catch((val) => {
        setLoading(false);
        setReassignTasksPopup(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div className="void_payment void_change_payment" ref={componentRef}>
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <p>Assign Tasks To </p>
            </div>
            <p class="popup_header_title_para">UT.5</p>
          </div>
          <svg
            onClick={() => {
              setReassignTasksPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="select_Cash_drawer">
          <ScrollView style={{ height: "270px" }}>
            <div className="select_Cash_drawer_content">
              {/* {listData?.map((val, index) => {
                return (
                  <div className="select_Cash_drawer_content_list" key={index}>
                    <label htmlFor="radio-button">{val.value}</label>
                    <input
                      type="radio"
                      id={val.key}
                      name={val.value}
                      value={val.key}
                      checked={selectedValue === val.key}
                    //   onChange={handleRadioChange}
                    />
                  </div>
                );
              })} */}
              {/* <List className={'dx-toolbar-menu-action'} items={assignToOptions} /> */}
              <List
                dataSource={assignToOptions}
                height={400}
                itemRender={ItemTemplate}
                onItemClick={(e) => {
                  setAssignValue(e.itemData.id);
                }}
                searchExpr="name"
              />
              {/* <div>
                                {assignToOptions?.map((val, index) => {
                                    return (
                                        <div className="select_Cash_drawer_content_list" key={index}>
                                            <p style={{ cursor: "pointer" }}>{val.name}</p>
                                        </div>
                                    );
                                })}
                            </div> */}
            </div>
          </ScrollView>
        </div>
        <p className="error_validation error_fix">{error}</p>
        <button
          className="void_payment_btn"
          onClick={() => {
            handleSave();
          }}
        >
          Ok
        </button>
      </div>
    </>
  );
};
