import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recentObligation: {},
  recentDocket: {},
};

const slice = createSlice({
  name: "AddParticipantWizardReducer",
  initialState,
  reducers: {
    setRecentObligation(state, action) {
      state.recentObligation = action.payload;
    },
    setRecentDocket(state, action) {
      state.recentDocket = action.payload;
    },
  },
});

export const { setRecentDocket, setRecentObligation } = slice.actions;

export default slice.reducer;
