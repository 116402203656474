import axios from "axios";
import { handleGetLocal, logout } from "../services/auth-service";

const pathname = handleGetLocal("pathURL");

export const logOut = async () => {
  await axios
    .post(
      `${
        process.env.REACT_APP_API_URL
      }/api/Account/userLogout?userid=${localStorage.getItem("username")}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      }
    )
    .then((val) => {
      logout();
      window.location.reload();
    })
    .catch(() => {
      logout();
      window.location.reload();
    });

  logout();
  window.location.reload();
};
