import "../../drugTestRandomizer.css";
import { DateBox, Popup } from "devextreme-react";
import { ColumnDropdown } from "../../../../../../components/Dropdownn";
import { useEffect, useState } from "react";
import { handleGetLocal } from "../../../../../../services/auth-service";
import { CommentVoidTest } from "./CommentVoidTest";

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  fieldNmae,
  reset,
  disabled,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};
export const slotDropdown = [
  { key: "-all-", value: "-all-" },
  { key: "1", value: "1" },
  { key: "2", value: "2" },
  { key: "3", value: "3" },
  { key: "4", value: "4" },
];

export const VoidTestDates = ({
  voidTestDatePopup,
  setVoidTestDatePopup,
  toastConfig,
  setToastConfig,
  locationDropdownValues,
}) => {
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    location: { name: "", id: "" },
    testDate: new Date(Date.now() + 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10),
    maxValidDate: new Date(Date.now() + 24 * 60 * 60 * 1000),
    slot: { name: "", id: "" },
  });
  const [locationValidationError, setLocationValidationError] = useState("");
  const [slotValidationError, setSlotValidationError] = useState("");
  const pathname = handleGetLocal("pathURL");
  const [voidPopup, setVoidPopup] = useState(false);
  const [testDateError, setTestDateError] = useState("");

  useEffect(() => {
    setLocationValidationError("");
    setSlotValidationError("");
    setTestDateError("");
    setFields({
      location: { name: "", id: "" },
      testDate: new Date(Date.now() + 24 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 10),
      maxValidDate: new Date(Date.now() + 24 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 10),
      slot: { name: "", id: "" },
    });
  }, [voidTestDatePopup]);

  const data = {
    TestDate: fields?.testDate,
    gEntityID: fields?.location?.id,
    gEntryUserID: localStorage.getItem("gUserId"),
    Slot: fields?.slot?.id,
  };

  const handleSave = async () => {
    let isValid = true;
    if (!fields.location?.id) {
      setLocationValidationError("Please Select Drug Test Location");
      isValid = false;
    }
    if (!fields.slot?.id) {
      setSlotValidationError("Please Select Slot");
      isValid = false;
    }
    if (fields?.testDate < fields?.maxValidDate) {
      setTestDateError("Date Must Be After Today");
      isValid = false;
    }
    if (isValid) {
      setVoidPopup(true);
    }
  };

  return (
    <>
      <Popup
        visible={voidPopup}
        onHiding={() => {
          setVoidPopup(false);
        }}
        showTitle={false}
        width={800}
        height={450}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <CommentVoidTest
          voidPopup={voidPopup}
          setVoidPopup={setVoidPopup}
          voidHeading="Void Test Date"
          voidSubHeading="Void Test Date"
          setLoading={setLoading}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          apiURl="RandomDrugTesting/add-edit-drug-test"
          apiData={data}
          setVoidTestDatePopup={setVoidTestDatePopup}
        />
      </Popup>
      <div className="void-test-date-popup">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g id="warning">
                  <path
                    id="Vector"
                    d="M4.47012 20.9997H19.5301C21.0701 20.9997 22.0301 19.3297 21.2601 17.9997L13.7301 4.98969C12.9601 3.65969 11.0401 3.65969 10.2701 4.98969L2.74012 17.9997C1.97012 19.3297 2.93012 20.9997 4.47012 20.9997ZM12.0001 13.9997C11.4501 13.9997 11.0001 13.5497 11.0001 12.9997V10.9997C11.0001 10.4497 11.4501 9.99969 12.0001 9.99969C12.5501 9.99969 13.0001 10.4497 13.0001 10.9997V12.9997C13.0001 13.5497 12.5501 13.9997 12.0001 13.9997ZM13.0001 17.9997H11.0001V15.9997H13.0001V17.9997Z"
                    fill="#ffff"
                  />
                </g>
              </svg>
              <p>Void Random Drug Test Date</p>
            </div>
            <p class="popup_header_title_para">DT.11</p>
          </div>
          <svg
            onClick={() => {
              setVoidTestDatePopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="void-test-date-popup-content">
          <div className="inputs-fields-container">
            <div className="inputs-field-content dropdown-inputs-field-content">
              <label className="edit_container_label">
                Drug Test Location
                <span className="marks_mandatary">*</span>
              </label>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"location"}
                dropDownData={locationDropdownValues?.location?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
                reset={setLocationValidationError}
              />
              {locationValidationError && (
                <p className="error_validation">{locationValidationError}</p>
              )}
            </div>
            <div className="inputs-field-content dropdown-inputs-field-content">
              <label className="edit_container_label">Test Date</label>
              <DateBox
                useMaskBehavior={true}
                value={fields.testDate}
                onValueChange={(e) => {
                  setFields((prev) => ({
                    ...prev,
                    testDate: e,
                  }));
                  setTestDateError("");
                }}
              />
              {testDateError && (
                <p className="error_validation">{testDateError}</p>
              )}
            </div>
          </div>
          <div className="inputs-fields-container">
            <div className="inputs-field-content dropdown-inputs-field-content">
              <label className="edit_container_label">
                Slot
                <span className="marks_mandatary">*</span>
              </label>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"slot"}
                dropDownData={slotDropdown?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
                reset={setSlotValidationError}
              />
              {slotValidationError && (
                <p className="error_validation">{slotValidationError}</p>
              )}
            </div>
          </div>
          <p className="edit_container_label">
            This operation will Void the selected Test Date, Any offenders
            currently scheduled for testing on this date will automatically be
            rescheduled.
          </p>
          <div className="popup_buttons">
            <button className="btn primary_btn" onClick={handleSave}>
              Save
            </button>
            <button
              className="btn"
              onClick={() => {
                setVoidTestDatePopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
