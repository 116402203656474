import axios from "axios";
import { useState } from "react";
import { handleSetleLocal } from "../../../services/auth-service";
import { logOut } from "../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { LoadPanel } from "devextreme-react";
export const SelectMedium = ({ setselectMedium, props }) => {
  const [selectOption, setselectOption] = useState(
    props.userDetail.sTwoFactorEmail ? "email" : "phone"
  );

  const [loading,setLoading]=useState(false)

  const navigate = useNavigate();
  const SendCode = async () => {
    let domainName = (url) =>
      url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
    let domailURL = window.location.href;
    let pathURL = domainName(domailURL);
    setLoading(true)
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Account/sendTwoFactorVerificationCode?codeMedium=${selectOption}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathURL}`,
          },
        }
      )
      .then((val) => {
        setLoading(false)
        if (val?.data?.isSuccess) {
          setselectMedium(false);
        }
      })
      .catch((val) => {
        setLoading(false)
        if (!val?.response) {

        navigate(`/error/500`);

      }else if (val?.response?.status == 401) {

        logOut();

      }
      });
  };
  handleSetleLocal("selectOption", selectOption);
  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <h1 style={{ fontSize: "22px", color: "#000000" }}>
        Two factor authentication
      </h1>
      <p style={{ fontSize: "14px" }} className="msg">
        Please choose how you'd like to receive your code.
      </p>
      <form>
        <div className="method">
          <ul className="radioWithField">
            <li className="option active" style={{ margin: "3%" }}>
              <div className="lable-info user-radio-btn">
                {!(
                  props.userDetail.sTwoFactorEmail &&
                  !props.userDetail.sTwoFactorTextNumber
                ) && (
                  <input
                    id="Email"
                    type="radio"
                    name="option"
                    style={{ transform: "scale(2)" }}
                    onClick={() => {
                      setselectOption("email");
                    }}
                    defaultChecked={
                      props.userDetail.sTwoFactorEmail ? true : false
                    }
                  />
                )}
                <label style={{ marginLeft: "5%" }} for="mobile">
                  Email to {props.userDetail.sTwoFactorEmail}
                </label>
              </div>
            </li>
            {props.userDetail.sTwoFactorTextNumber && (
              <li className="option" style={{ margin: "3%", marginTop: "5%" }}>
                <div className="lable-info">
                  <input
                    id="phone"
                    type="radio"
                    name="option"
                    style={{ transform: "scale(2)" }}
                    onClick={() => {
                      setselectOption("phone");
                    }}
                    disabled={
                      props.userDetail.sTwoFactorTextNumber ? false : true
                    }
                    defaultChecked={
                      props.userDetail.sTwoFactorEmail
                        ? false
                        : props.userDetail.sTwoFactorTextNumber
                        ? true
                        : false
                    }
                  />
                  <label style={{ marginLeft: "5%" }} for="mail">
                    Text to {props.userDetail.sTwoFactorTextNumber}
                  </label>
                </div>
              </li>
            )}
          </ul>
        </div>
        <div className="fieldWrap">
          <button
            className={`btn btn-default `}
            onClick={(e) => {
              e.preventDefault();
              SendCode();
            }}
          >
            Send Code
          </button>
        </div>
      </form>
    </>
  );
};
