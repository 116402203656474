export const formsTableColumn = [
  { title: " ", moreoption: true },
  { title: "Status" },
  { title: "First Name" },
  { title: "Last Name" },
  { title: "Subject" },
  { title: "File Name" },
  { title: "Date Received", date: true },
  { title: "Sender" },
  { title: "Date Processed", date: true },
];

const convertDateFormat = (dateString) => {
  if (dateString) {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleDateString("en-US");
  } else {
    return "";
  }
};

export const setFormsTableColumnData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      Status: val?.s,
      "First Name": val?.sFirstName,
      "Last Name": val?.sLastName,
      Subject: val?.sSubject,
      "File Name": val?.sFileName,
      "Date Received": convertDateFormat(val?.dDateReceived),
      Sender: val?.sSender,
      "Date Processed": convertDateFormat(val?.s),
      gFormId: val?.gFormId,
      sOffenderNumber: val?.sOffenderNumber,
      sFormId: val?.sFormId,
      ID: i,
    });
  });
  return arr;
};
