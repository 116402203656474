export const AlertDAteCHangePopupmessage = ({
  setAlertDAteCHangepopup,
  lastdate,
  currentdate,
  setaceptworning,
  onSave,
}) => {
  return (
    <>
      <div style={{ height: "298px" }} className="alertpopup">
        <div className="alertpopup_head">
          <div className="alertpopup_head_title">
            <div className="alertpopup_head_title_data">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g id="warning">
                  <path
                    id="Vector"
                    d="M4.47012 20.9997H19.5301C21.0701 20.9997 22.0301 19.3297 21.2601 17.9997L13.7301 4.98969C12.9601 3.65969 11.0401 3.65969 10.2701 4.98969L2.74012 17.9997C1.97012 19.3297 2.93012 20.9997 4.47012 20.9997ZM12.0001 13.9997C11.4501 13.9997 11.0001 13.5497 11.0001 12.9997V10.9997C11.0001 10.4497 11.4501 9.99969 12.0001 9.99969C12.5501 9.99969 13.0001 10.4497 13.0001 10.9997V12.9997C13.0001 13.5497 12.5501 13.9997 12.0001 13.9997ZM13.0001 17.9997H11.0001V15.9997H13.0001V17.9997Z"
                    fill="#ffff"
                  />
                </g>
              </svg>
              <p>Alert</p>
            </div>
          </div>
          <svg
            onClick={() => {
              setAlertDAteCHangepopup(false);
            }}
            style={{
              cursor: "pointer",
              marginTop: "17px",
              marginRight: "2%",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div
          style={{
            marginTop: "-15px",
            width: "95%",
            marginLeft: "3%",
            height: "139px",
          }}
        >
          <p style={{ width: "100%" }}>
            You have modified the create date from{" "}
            {new Date(currentdate)
              .toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "2-digit",
              })
              .replace(/\//g, "/")}{" "}
            to{" "}
            {new Date(lastdate)
              .toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "2-digit",
              })
              .replace(/\//g, "/")}{" "}
            <br />
            Are you sure want to do this?{" "}
          </p>
          <button
            onClick={() => {
              onSave();
              setAlertDAteCHangepopup(false);
            }}
            style={{
              backgroundColor: "rgb(67, 66, 102)",
              color: "white",
              marginTop: "25%",
              width: "80px",
              float: "right",
              cursor: "pointer",
            }}
          >
            OK
          </button>
        </div>
      </div>
    </>
  );
};
