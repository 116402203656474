import React, { useState } from "react";
import { ReactComponent as AddIcon } from "../../../../../assets/images/icons/add.svg";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { tollingTablecoloumn } from "../Data";
import { Popup } from "devextreme-react";
import { AddTolling, EditAdd } from "./AddTolling";
import { VoidPopup } from "../VoidPopup";
import { EditTolling } from "./EditTolling";
import { ApiError } from "../../../../Error/ApiError";

const EditPopup = ({
  EditpopupTogall,
  setEditpopupTogall,
  getData,
  setReset,
  reset,
  toastConfig,
  setToastConfig,
  dropdownData,
  docketDropdownData,
  userinfo,
  setTollingreset,
  Tollingreset,
}) => {
  return (
    <Popup
      visible={EditpopupTogall}
      onHiding={() => {
        setReset(!reset);
        setEditpopupTogall(false);
      }}
      showTitle={false}
      width={608}
      height={440}
      showCloseButton={true}
      hideOnOutsideClick={true}
    >
      <EditTolling
        setEditpopupTogall={setEditpopupTogall}
        text={"Edit Tolling"}
        getData={getData}
        reset={reset}
        setReset={setReset}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        dropdownData={dropdownData}
        docketDropdownData={docketDropdownData}
        userinfo={userinfo}
        Tollingreset={Tollingreset}
        setTollingreset={setTollingreset}
      />
    </Popup>
  );
};
const AddPopup = ({
  AddpopupTogall,
  setAddpopupTogall,
  setReset,
  reset,
  toastConfig,
  setToastConfig,
  dropdownData,
  docketDropdownData,
  userinfo,
  Tollingreset,
  setTollingreset,
  goToTollingDockerDasible,
  dockertDetail
}) => {
  return (
    <Popup
      visible={AddpopupTogall}
      onHiding={() => {
        setReset(!reset);
        setAddpopupTogall(false);
      }}
      showTitle={false}
      width={608}
      height={440}
      showCloseButton={true}
      hideOnOutsideClick={true}
    >
      <AddTolling
        setAddpopupTogall={setAddpopupTogall}
        reset={reset}
        setReset={setReset}
        text={"Add Tolling"}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        dropdownData={dropdownData}
        docketDropdownData={docketDropdownData}
        userinfo={userinfo}
        Tollingreset={Tollingreset}
        setTollingreset={setTollingreset}
        goToTollingDockerDasible={goToTollingDockerDasible}
        dockertDetail={dockertDetail}
      />
    </Popup>
  );
};

const Void = ({
  setVoidPopup,
  voidPopup,
  toastConfig,
  setToastConfig,
  setLoad,
  getTollingDataApi,
  userinfo,
  getData,
}) => {
  return (
    <Popup
      visible={voidPopup}
      onHiding={() => {
        setVoidPopup(false);
      }}
      showTitle={false}
      width={800}
      height={450}
      showCloseButton={true}
      hideOnOutsideClick={true}
    >
      <VoidPopup
        setVoidPopup={setVoidPopup}
        voidHeading="Void Payment"
        voidSubHeading="Void Tolling"
        setLoad={setLoad}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        filedId={getData?.gTollingId}
        fieldName="recordId"
        apiURl="/tolling/void-tolling"
        refreshApi={getTollingDataApi}
        Tollingheading={`Void Tolling ${getData?.Start} - ${getData?.End}`}
        userinfo={userinfo}
        comment="Comments"
      />
    </Popup>
  );
};

export const Tolling = ({
  tollingData,
  toastConfig,
  setToastConfig,
  dropdownData,
  docketDropdownData,
  userinfo,
  Tollingreset,
  setTollingreset,
  getTollingDataApi,
  TollingErorrHandling,
  goToTollingDockerDasible,
  dockertDetail,
}) => {
  const [load, setLoad] = useState(false);
  const [voidPopup, setVoidPopup] = useState(false);
  const [EditpopupTogall, setEditpopupTogall] = useState(false);
  const [AddpopupTogall, setAddpopupTogall] = useState(false);
  const [reset, setReset] = useState(false);
  const [getData, setgeteditdata] = useState("");

  return (
    <>
      {TollingErorrHandling ? (
        <ApiError />
      ) : (
        <div>
          <EditPopup
            setEditpopupTogall={setEditpopupTogall}
            EditpopupTogall={EditpopupTogall}
            getData={getData}
            reset={reset}
            setReset={setReset}
            toastConfig={toastConfig}
            setToastConfig={setToastConfig}
            dropdownData={dropdownData}
            docketDropdownData={docketDropdownData}
            userinfo={userinfo}
            Tollingreset={Tollingreset}
            setTollingreset={setTollingreset}
          />
          <AddPopup
            AddpopupTogall={AddpopupTogall}
            setAddpopupTogall={setAddpopupTogall}
            reset={reset}
            setReset={setReset}
            toastConfig={toastConfig}
            setToastConfig={setToastConfig}
            dropdownData={dropdownData}
            docketDropdownData={docketDropdownData}
            userinfo={userinfo}
            Tollingreset={Tollingreset}
            setTollingreset={setTollingreset}
            goToTollingDockerDasible={goToTollingDockerDasible}
            dockertDetail={dockertDetail}
          />
          <Void
            setVoidPopup={setVoidPopup}
            voidPopup={voidPopup}
            toastConfig={toastConfig}
            setToastConfig={setToastConfig}
            setLoad={setLoad}
            getTollingDataApi={getTollingDataApi}
            userinfo={userinfo}
            getData={getData}
          />
          <div className="tolling">
            <div className="buttons">
              <button
                className="btn"
                onClick={() => {
                  setReset(!reset);
                  setAddpopupTogall(true);
                }}
                style={{ width: "auto" }}
              >
                Add Tolling  <AddIcon className="svg-blue-white" />
              </button>
            </div>
            <div className="tolling_table">
              <Table1
                editTable={false}
                height={400}
                Tablecoloum={tollingTablecoloumn}
                tableRow={tollingData?.map((val, i) => {
                  return { ...val, ID: i };
                })}
                load={load}
                coloumWidthText={"JailCompletedPlannedservices"}
                mandatoryColoum={"Docket"}
                exportInDifferentFormate={true}
                exportwidth={27}
                exporttitle={"JailCompletedPlannedservices"}
                tollingMenu={true}
                setEditpopupTogall={setEditpopupTogall}
                setVoidPopup={setVoidPopup}
                setgeteditdata={setgeteditdata}
                setVoideFalse={true}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
