import { Tolling } from "../../../Dockect/Tolling/Tolling";

export const GoToDocketTolling = ({
  toastConfig,
  setToastConfig,
  userinfo,
  dropdownData,
  docketDropdownData,
  getTollingDataApi,
  tollingData,
  Tollingreset,
  setTollingreset,
  TollingErorrHandling,
  dockertDetail
}) => {
  return (
    <>
      <Tolling
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        userinfo={userinfo}
        dropdownData={dropdownData}
        docketDropdownData={docketDropdownData}
        getTollingDataApi={getTollingDataApi}
        tollingData={tollingData}
        Tollingreset={Tollingreset}
        setTollingreset={setTollingreset}
        TollingErorrHandling={TollingErorrHandling}
        goToTollingDockerDasible={true}
        dockertDetail={dockertDetail}
      />
    </>
  );
};
