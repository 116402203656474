import axios from "axios";
import { logOut } from "../../../components/Logout";
import { handleGetLocal } from "../../../services/auth-service";
import { setDocumentWizardConditionsViolationsColumnData, setDocumentWizardDocketTableColumnData } from "./Data";

export class DocumentWizardFunction {
  constructor() {
    this.pathname = handleGetLocal("pathURL");
  }
  getDocumentWizardDropdown = async (navigate, setReportsDropdownData, setLoading) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=Document Wizard Document`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        val?.data?.data?.length &&
          setReportsDropdownData(
            val?.data?.data?.map((val) => {
              return {
                name: val?.value,
                id: val?.id,
              };
            })
          );
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  getDocumentWizardSupervisorDropdown = async (navigate, setSupervisorsDropdownData, setLoading) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=Supervisor`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        val?.data?.data?.length &&
          setSupervisorsDropdownData(
            val?.data?.data?.map((val) => {
              return {
                name: val?.value,
                id: val?.id,
              };
            })
          );
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  getDocumentWizardData = async (
    gReportCatalogID,
    navigate,
    setDocumentWizardOutputData,
    setChildDocumentWizardOutputData,
    setHtmlData,
    setChildHtmlData,
    setLoading,
    getDocumentWizardUpdateRtfDocument,
    documentWizardOutputData,
  ) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/document-wizard/get-document?gReportCatalogID=${gReportCatalogID}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        function modifiedRtf(originalRtf) {
          return originalRtf?.replace(/&lt;upper&gt;(.*?)&lt;\/upper&gt;/g, (match, content) => {
            const uppercaseContent = content.toUpperCase();
            return `<span style="text-transform: uppercase;">${uppercaseContent}</span>`;
          });
        }
        setDocumentWizardOutputData({
          ...val.data.data?.parent,
          sReportRtf: modifiedRtf(val?.data?.data?.parent?.sReportRtf),
        });
        const modifiedChildData = val.data.data?.child.map((child) => ({
          ...child,
          sReportRtf: modifiedRtf(child?.sReportRtf),
        }));
        setChildDocumentWizardOutputData(modifiedChildData);
        setHtmlData(modifiedRtf(val?.data?.data?.parent?.sReportRtf));
        const modifiedChildHtmlData = modifiedChildData?.map((child) => ({
          gParentId: child?.gParentId,
          sName: child?.sName,
          sReportRtf: modifiedRtf(child?.sReportRtf),
        }));
        setChildHtmlData(modifiedChildHtmlData);
        getDocumentWizardUpdateRtfDocument(modifiedRtf(val?.data?.data?.parent?.sReportRtf));
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  saveDocumentWizardData = async (documentWizardSaveData, setLoading, navigate, setToastConfig, toastConfig) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/document-wizard/save-document`, documentWizardSaveData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Successfully Updated!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Successfully Updated!",
            type: "success",
          });
        }, 1900);
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  };
  getDocumentWizardDocketTableData = async (personId, navigate, setDocumentWizardDocketsTableData, setLoading) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/get-dockets?personId=${personId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setDocumentWizardDocketsTableData(setDocumentWizardDocketTableColumnData(val?.data?.data));
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  docConversion = async (convHtmlData, setLoading, navigate, setToastConfig, toastConfig, rtfFileName) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/document-wizard/doc-conversion`, convHtmlData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        const blob = new Blob([val?.data?.data?.document], {
          type: "application/rtf",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${rtfFileName}.rtf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoading(false);
        // setToastConfig({
        //   ...toastConfig,
        //   isVisible: true,
        //   message: "Successfully Updated!",
        //   type: "success",
        // });
        // setTimeout(() => {
        //   setToastConfig({
        //     ...toastConfig,
        //     isVisible: false,
        //     message: "Successfully Updated!",
        //     type: "success",
        //   });
        // }, 1900);
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  };
  getConditionsAndViolationsData = async (
    docketsSelectedData,
    setLoading,
    navigate,
    setConditionsViolationsTableData
  ) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/document-wizard/conditions-violations`, docketsSelectedData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setConditionsViolationsTableData(
          setDocumentWizardConditionsViolationsColumnData(val?.data?.documentWizardVerbiages)
        );
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  };
  findConditionsAndViolationsData = async (docketsFindData, setLoading, navigate, setConditionsViolationsTableData) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/document-wizard/conditions-violations`, docketsFindData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setConditionsViolationsTableData(
          setDocumentWizardConditionsViolationsColumnData(val?.data?.documentWizardVerbiages)
        );
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  };
  getShowCourtDocumentWizardDropdown = async (navigate, setReportsDropdownData, setLoading, gSentenceCourtEntityId) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=Document Wizard Document&supplementalData=${gSentenceCourtEntityId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        val?.data?.data?.length
          ? setReportsDropdownData(
            val?.data?.data?.map((val) => {
              return {
                name: val?.value,
                id: val?.id,
              };
            })
          )
          : setReportsDropdownData([]);
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };
  getDocumentWizardUpdateRtfDocument = async (navigate, payload, setLoading, setParametersFields, replaceFieldsWithValues,
    parametersFields) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/document-wizard/update-rtf-document`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        const parameterList = val?.data?.parameterList || [];
        const parameters = parameterList.reduce((acc, { parameterName, parameterValue }) => {
          acc[`[${parameterName}]`] = parameterValue || "";
          return acc;
        }, {});
        setParametersFields((prev) => ({
          ...prev,
          ...parameters,
        }));
        setLoading(false);
        //if (parametersFields) { replaceFieldsWithValues(parametersFields) }

      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  };
}
