import axios from "axios";
import { handleGetLocal } from "../../../services/auth-service";
import { logOut } from "../../../components/Logout";

export class AllocationAdjustmentfunction {
  constructor() {
    this.pathname = handleGetLocal("pathURL");
  }

  async AllocationAdjustmenttabledata(
    tablename,
    personid,
    setLoading,
    navigate,
    setgrid1,
    setgrid2,
    setgridalldata1,
    setgridalldata2
  ) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/obligations/get-allocation-adjustment?personId=${personid}&Grid=${tablename}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (tablename == "Grid1") {
          setgrid1(this.setsetgrid1TableData(val?.data?.data));
          setgridalldata1(
            val?.data?.data?.map((val) => {
              return { ...val, AmountToDallocate: 0 };
            })
          );
        } else {
          setgrid2(this.setsetgrid2TableData(val?.data?.data));
          setgridalldata2(
            val?.data?.data?.map((val) => {
              return { ...val, AmountToAllocate: 0 };
            })
          );
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  setsetgrid1TableData = (dataa) => {
    const arr = [];
    dataa.map((val, i) => {
      arr.push({
        Reference: val?.sReference,
        Docket: val?.sDocketNumber,
        Court: val?.sCourtName,
        Fee: val?.sDdsviolationDescription,
        Amount: val?.fAmount,
        Paid: val?.fPaid,
        "Write Off": val?.fWriteOff,
        Balance: val?.fBalance,
        "On Hand": val?.fBalanceEligible,
        "Amount to Deallocate": 0,
        ID: i + 1,
        gObligationId: val?.gObligationId,
      });
    });

    return arr;
  };

  setsetgrid2TableData = (dataa) => {
    const arr = [];
    dataa.map((val, i) => {
      arr.push({
        Reference: val?.sReference,
        Docket: val?.sDocketNumber,
        Court: val?.sCourtName,
        Fee: val?.sDdsviolationDescription,
        Amount: val?.fAmount,
        Paid: val?.fPaid,
        "Write Off": val?.fWriteOff,
        Balance: val?.fBalance,
        "On Hand": val?.fBalanceEligible,
        "Amount to Allocate": 0,
        ID: i + 1,
        gObligationId: val?.gObligationId,
      });
    });

    return arr;
  };

  async saveAllocationAdjustment(
    body,
    setLoading,
    navigate,
    setToastConfig,
    toastConfig
  ) {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/obligations/obligation-allocation-adjustment`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Successfully Updated!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Successfully Updated!",
            type: "success",
          });
        }, 1900);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  async allocationadjustmentapprovals(
    setLoading,
    navigate,
    setApproveddropdown
  ) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/obligations/allocation-adjustment-approvals`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        val?.data?.data &&
          setApproveddropdown(
            val?.data?.data.map((vall) => {
              return {
                name: vall?.value,
                id: vall?.id,
              };
            })
          );
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }
}
