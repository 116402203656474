import { Index } from "../../../Notes";

export const GoTONote = ({ userinfo, dockerValue }) => {
  return (
    <>
      <Index
        userinfo={userinfo}
        dockerValue={dockerValue}
        GoTONote={true}
        showchecklist={false}
      />
    </>
  );
};
