import { useEffect, useState } from "react";

import { Toster } from "../../../../components/Toster/Toster";
import { ProntactEntitiesSubTabAddres } from "./ProntactEntitiesSubTabAddres/ProntactEntitiesSubTabAddres";
import { ProntactEntitiesSubTabInvoice } from "./ProntactEntitiesSubTabInvoice/ProntactEntitiesSubTabInvoice";
import { ProntactEntitiesSubTabContectLIst } from "./ProntactEntitiesSubTabContectLIst/ProntactEntitiesSubTabContectLIst";
import { ProntactEntitiesSubTabAttachment } from "./ProntactEntitiesSubTabAttachment/ProntactEntitiesSubTabAttachment";
import { useNavigate, useParams } from "react-router-dom";
import { ProntactEntityFunction } from "../ProntactEntityFunction";
import { useSelector } from "react-redux";
import { ProntactEntitiesSubTabEntity } from "./ProntactEntitiesSubTabEntity/ProntactEntitiesSubTabEntity";
import { ProntactEntitiesSubTabDockumentWizard } from "./ProntactEntitiesSubTabDockumentWizard/ProntactEntitiesSubTabDockumentWizard";
import { useDispatch } from "react-redux";
import { setcontectTableAddDataaa2 } from "../../../../reducer/ParticipateReducer";
const setTableDataa = (dataa) => {
  const arr = [];
  dataa.map((val) => {
    arr.push({
      Contact: val?.Contact,
      Position: val?.Position,
      Activity: val?.Activity,
      Start: val?.Start,
      End: val?.End,
      "Contact Information": val?.["Contact Information"],
      "Contact Type": val?.["Contact Type"],
      ID: val?.ID,
      gEntityContactId: val?.gEntityContactId,
    });
  });

  return arr;
};

export const ProntactEntitiesSubTab = ({
  EditTogel,
  addProntactEntities,
  setentitidata,
  entitidata,
  entitydatadropdown,
  ContactPositiondropdown,
  Contactdropdown,
  getDRopDown,
  entitydatadropdown2,
  fields2,
  setfields2,
}) => {
  const clas = new ProntactEntityFunction();
  const [activeTab, setActiveTab] = useState(1);
  const [loadingg, setLoading] = useState(false);
  const [attachmentCount, setattachmentCount] = useState(0);
  const [data, setdata] = useState([]);
  const tabTitles = [
    "Address",
    "Invoicing",
    `Contact List (${data.length})`,
    `Entity Types (${entitidata.length})`,
    `Attachment(${attachmentCount})`,
    "Document Wizard",
  ];
  const navigate = useNavigate();

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [attachmentTabledata, setattachmentTabledata] = useState([]);
  const { Entitiesid } = useParams();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [loading, setloading] = useState(false);

  const dispatch = useDispatch();
  const { contectTableAddData2 } = useSelector((store) => store.participate);

  useEffect(() => {
    if (Entitiesid) {
      setActiveTab(1);
      clas.getattachmentTableData(
        setattachmentTabledata,
        setLoading,
        navigate,
        Entitiesid,
        setattachmentCount
      );
      clas.get_entity_contact(
        setloading,
        navigate,
        Entitiesid,
        setdata,
        dispatch,
        contectTableAddData2
      );
    } else {
      setdata(setTableDataa([]));
      dispatch(setcontectTableAddDataaa2([]));
    }
  }, [Entitiesid]);

  const getattachmentTableData = () => {
    if (Entitiesid) {
      clas.getattachmentTableData(
        setattachmentTabledata,
        setLoading,
        navigate,
        Entitiesid,
        setattachmentCount
      );
    }
  };

  return (
    <>
      <Toster
        message={toastConfig.message}
        type={toastConfig.type}
        visible={toastConfig.isVisible}
      />
      <div className="goto-obligation-sub-tab-container">
        <div className="goto-obligation-sub-tab">
          {tabTitles.map((title, index) => (
            <button
              key={index}
              className={`goto-obligation-button ${
                activeTab === index + 1 ? "goto-obligation-button-active" : ""
              }`}
              onClick={() => handleTabClick(index + 1)}
            >
              {title}
            </button>
          ))}
        </div>
        <div className="commonBlock_bottom_border"></div>
        <div className="goto-subtabs ">
          {activeTab === 1 && (
            <ProntactEntitiesSubTabAddres EditTogel={EditTogel} />
          )}
          {activeTab === 2 && (
            <ProntactEntitiesSubTabInvoice EditTogel={EditTogel} />
          )}
          {activeTab === 3 && (
            <ProntactEntitiesSubTabContectLIst
              ContactPositiondropdown={ContactPositiondropdown}
              Contactdropdown={Contactdropdown}
              getDRopDown={getDRopDown}
              EditTogel={EditTogel}
              addProntactEntities={addProntactEntities}
              Entitiesid={Entitiesid}
              data={data}
              setdata={setdata}
              setloading={setloading}
              loading={loading}
            />
          )}
          {activeTab === 4 && (
            <ProntactEntitiesSubTabEntity
              EditTogel={EditTogel}
              addProntactEntities={addProntactEntities}
              setentitidata={setentitidata}
              entitidata={entitidata}
              entitydatadropdown={entitydatadropdown}
              entitydatadropdown2={entitydatadropdown2}
              fields2={fields2}
              setfields2={setfields2}
            />
          )}
          {activeTab === 5 && (
            <ProntactEntitiesSubTabAttachment
              dataa={attachmentTabledata}
              loadinggg={loadingg}
              userinfo={{ gPersonId: Entitiesid }}
              attachmentfunction={getattachmentTableData}
            />
          )}

          {activeTab === 6 && (
            <ProntactEntitiesSubTabDockumentWizard EditTogel={EditTogel} />
          )}
        </div>
      </div>
    </>
  );
};
