import React from "react";
import { Table1 } from "../Table1/Table1";
import { groupsColumn } from "../Data";

export const Groups = ({
  gropuData,
  setGroupResult,
  groupResult,
  checkEditable,
}) => {
  localStorage.removeItem("entitiesList");
  return (
    <>
      <div className="admin_tabs_content_data">
        <Table1
          editTable={false}
          height={400}
          Tablecoloum={["Select", ...groupsColumn].map((val) => {
            if (val === "Select") {
              return { title: val, select: true };
            } else {
              return { title: val };
            }
          })}
          tableRow={gropuData.map((val, i) => {
            return { ...val, ID: i };
          })}
          coloumWidthText={"groupsList"}
          mandatoryColoum={"Group Name"}
          setGroupResult={setGroupResult}
          groupResult={groupResult}
          checkEditable={checkEditable}
        />
      </div>
    </>
  );
};
