import { useState } from "react";
import "../participante.css";
import { useSelector } from "react-redux";
import { TopDynamicTab } from "../TopDynamicTab";
import { ParticipentHeader } from "../ParticipantHeader";
import { AddPersonalInfo } from "./AddPersonalInfo/AddPersonalInfo";
import { Detail_Name_Tab } from "../ParticipantSubTabs/DetailTab/Detail_Name_Tab";
import { Detail_Contact_Tab } from "../ParticipantSubTabs/DetailTab/Detail_Contact_Tab";
import { Detail_Address_Tab } from "../ParticipantSubTabs/DetailTab/Detail_Address_Tab";

export const AddParticipant = () => {
  const [tabSwitch, setTabSwitch] = useState(2);
  const [editable, setEditable] = useState(true);

  const { dynamicParticipatTab } = useSelector((store) => store.participate);
  return (
    <>
      <div
        style={{
          marginTop: "1.1%",
          backgroundColor: "var(--color2)",
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <TopDynamicTab
          setTabSwitch={setTabSwitch}
          tabSwitch={tabSwitch}
          text={dynamicParticipatTab}
        />
        <AddPersonalInfo
          editable={editable}
          setEditable={setEditable}
          editParticipant={false}
        />
        {/* <ParticipentHeader
          editable={editable}
          setEditable={setEditable}
          editParticipant={false}
        /> */}
      </div>
    </>
  );
};
