import axios from "axios";
import { handleGetLocal } from "../../../services/auth-service";
import { logOut } from "../../../components/Logout";

export class feeshudulefunctions {
  constructor() {
    this.pathname = handleGetLocal("pathURL");
  }

  getattachmentTableData = async (
    setattachmentTabledata,
    setLoading,
    navigate,
    feeshudule,
    setattachmentcount
  ) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/attachments/get-attachments?recordId=${feeshudule?.feeshuduleid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        setattachmentcount(val?.data?.data?.length);
        setattachmentTabledata(this.setTableData(val?.data?.data));
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  setTableData = (dataa) => {
    const arr = [];
    dataa.map((val) => {
      arr.push({
        Filename: val?.filename,
        Category: val?.attachmentCateogoryName,
        Description: val?.description,
        ID: val?.recordId,
        "Entered By": val?.enteredByUserName,
        DOE: val?.doe,
        attachment: val?.attachment,
        attachmentCategoryId: val?.attachmentCategoryId,
        binaryDataId: val?.binaryDataId,
        image: val?.image,
      });
    });

    return arr;
  };

  getEntitiesTableData = async (setEntitiesTabledata, setLoading, navigate) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/FeeSchedule/feeschedule-dropdown`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.IsSuccess || val?.data?.isSuccess) {
          setEntitiesTabledata(
            this.setEntitiesTableData(val?.data?.data?.entity)
          );
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  setEntitiesTableData = (dataa) => {
    const arr = [];
    dataa.map((val, i) => {
      arr.push({
        Location: val?.value,
        id: val?.key,
        ID: i,
      });
    });

    return arr;
  };

  getEntitiesTableData2 = async (
    setEntitiesTabledata2,
    setLoading,
    navigate,
    feeshuduleid,
    setentitidata
  ) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/FeeSchedule/get-entity?gFeeScheduleId=${feeshuduleid?.feeshuduleid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        setEntitiesTabledata2(this.setEntitiesTableData2(val?.data?.data));
        setentitidata(this.setEntitiesTableData2(val?.data?.data));
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  setEntitiesTableData2 = (dataa) => {
    const arr = [];
    dataa.map((val, i) => {
      arr.push({
        Location: val?.sName,
        id: val?.gEntityId,
        ID: i,
      });
    });

    return arr;
  };

  async saveentity(
    navigate,
    setLoading,
    feeshuduleid,
    entitidata,
    setEntitiesTabledata2,
    setentitidata
  ) {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/FeeSchedule/add-entity-feeschedule`,
        entitidata.map((val) => {
          return {
            GEntityFeeScheduleId: "00000000-0000-0000-0000-000000000000",
            GEntryUserId: localStorage.getItem("gUserId"),
            GEntityId: val?.id,
            GFeeScheduleId: feeshuduleid?.gFeeScheduleId,
          };
        }),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        this.getEntitiesTableData2(
          setEntitiesTabledata2,
          setLoading,
          navigate,
          { feeshuduleid: feeshuduleid?.gFeeScheduleId },
          setentitidata
        );
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  getEntitiesTablefind = async (
    setEntitiesTabledata,
    setLoading,
    navigate,
    e
  ) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/feeschedule/find-entity?searchentity=${e}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        setEntitiesTabledata(this.setEntitiesTablefind2(val?.data?.data));
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  setEntitiesTablefind2 = (dataa) => {
    const arr = [];
    dataa.map((val, i) => {
      arr.push({
        Location: val?.sReportingEntityName,
        id: val?.gEntityId,
        ID: i,
      });
    });

    return arr;
  };
}
