import { DateBox, LoadPanel, TextBox } from "devextreme-react";
import { ColumnDropdown } from "../../../../components/Dropdownn";
import { useEffect, useState } from "react";
import { GoTOObligationFunction } from "./GoTOObligationFunction";
import { Toster } from "../../../../components/Toster/Toster";
import { useNavigate } from "react-router-dom";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        // reset={reset}
        margin={"7px"}
      />
    </>
  );
};

export const GoToDocketChangeFinatialSTatus = ({
  ChangeFinatialSTatusPopup,
  setChangeFinatialSTatusPopup,
  data,
  FinancialStatus,
  dropdowndata,
  gObligationId,
  getObligation,
}) => {
  const clas = new GoTOObligationFunction();
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [changeReason, setchangeReason] = useState([]);
  const [field, setField] = useState({
    "Financial Status": { name: "", id: "" },
    "Effective Date": "",
    "Change Reason": { name: "", id: "" },
    "Please add a detailed comment ": "",
    "Apply to all obligation in this docket": false,
  });
  const [Loading2, setLoading2] = useState(false);
  const [effectivedatevalidation, seteffectivedatevalidation] = useState("");
  const [finatialstatusvalidation, setfinatialstatusvalidation] = useState("");
  const [ChangeReasonvalidation, setChangeReasonvalidation] = useState("");
  const [commentvalidation, setcommentvalidation] = useState("");

  useEffect(() => {
    dropdowndata?.financialStatusChangeReason?.length &&
      setchangeReason(
        dropdowndata?.financialStatusChangeReason?.map((val) => {
          return { name: val?.value, id: val?.key };
        })
      );
  }, [dropdowndata]);
  const navigate = useNavigate();

  const onSave = () => {
    if (
      field?.["Effective Date"] &&
      field?.["Financial Status"].id &&
      field?.["Please add a detailed comment "] &&
      field?.["Change Reason"].id
    ) {
      console.log(field?.changeReason);
      seteffectivedatevalidation("");
      setfinatialstatusvalidation("");
      setChangeReasonvalidation("");
      setcommentvalidation("");
      clas.changesupervisionstatus(
        setField,
        setLoading2,
        {
          recordId: data?.gObligationId,
          obligationReference: data?.sReference,
          statusId: field?.["Financial Status"].id,
          effectiveDate: field?.["Effective Date"],
          statusChangeReasonId: field?.["Change Reason"].id,
          comment: field?.["Please add a detailed comment "],
          isDocket: false,
        },
        setToastConfig,
        toastConfig,
        navigate,
        setChangeFinatialSTatusPopup,
        getObligation
      );
    } else {
      !field?.["Effective Date"]
        ? seteffectivedatevalidation("Please enter Effective Date")
        : seteffectivedatevalidation("");
      !field?.["Financial Status"].id
        ? setfinatialstatusvalidation("Please select Financial Status")
        : setfinatialstatusvalidation("");
      !field?.["Change Reason"].id
        ? setChangeReasonvalidation("Please select Change Reason")
        : setChangeReasonvalidation("");
      !field?.["Please add a detailed comment "]
        ? setcommentvalidation("Please enter detailed comment")
        : setcommentvalidation("");
    }
  };

  useEffect(() => {
    setField({
      "Financial Status": {
        name: data?.sFinancialStatusName,
        id: data?.gFinancialStatusId,
      },
      "Effective Date": "",
      "Change Reason": { name: "", id: "" },
      "Please add a detailed comment ": "",
      "Apply to all obligation in this docket": false,
    });
    seteffectivedatevalidation("");
    setfinatialstatusvalidation("");
    setChangeReasonvalidation("");
    setcommentvalidation("");
  }, [ChangeFinatialSTatusPopup]);

  useEffect(() => {
    if (field?.["Change Reason"].id) setChangeReasonvalidation("");
  }, [field?.["Change Reason"].id]);

  return (
    <>
      <LoadPanel shadingColor="true" visible={Loading2} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <div style={{ height: "605px" }} className="create-concurrent-docket-container">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_974_363096)">
                  <path d="M0 0H24V24H0V0Z" fill="var(--button2)" />
                  <path
                    d="M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_974_363096">
                    <rect width="24" height="24" fill="rgb(67, 66, 102)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Change Supervision Status</p>
            </div>
            <p class="popup_header_title_para">DK.7</p>
          </div>
          <svg
            onClick={() => {
              setChangeFinatialSTatusPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div style={{ width: "94%", marginLeft: "3%" }}>
          <div className="quickAddObligationn">
            <div>
              <p>Case/Docket #</p>
              <p
                style={{
                  marginLeft: "15px",
                  color: "var(--TextColor)",
                }}
              >
                {data?.sDocketNumberCourtCounts}
              </p>
            </div>
            <div>
              <p>Person</p>
              <p
                style={{
                  marginLeft: "15px",
                  color: "var(--TextColor)",
                }}
              >
                {data?.sPersonNameLastFirst}
              </p>
            </div>
          </div>
          <div className="collectionpopupBLOCK2">
            <div className="collectionpopupBLOCK2inputt">
              <p>
                Obligation Reference <span>*</span>
              </p>
              <div>
                <TextBox
                  disabled={true}
                  style={{ marginTop: "3%" }}
                  value={data?.sReference}
                  isValid={true}
                  placeholder={"Docket Number"}
                />
              </div>
            </div>
            <div className="collectionpopupBLOCK2inputt">
              <p>
                Financial Status <span>*</span>
              </p>
              <div>
                <DropDown
                  setfields={setField}
                  fields={field}
                  fieldNmae={"Financial Status"}
                  dropDownData={FinancialStatus}
                  reset={setfinatialstatusvalidation}
                />
                <p
                  style={{
                    color: "red",
                    fontWeight: "400",
                    position: "absolute",
                  }}
                >
                  {finatialstatusvalidation}
                </p>
              </div>
            </div>
          </div>
          <div className="collectionpopupBLOCK2">
            <div className="collectionpopupBLOCK2inputt">
              <p>Effective Date</p>
              <div>
                <DateBox
                  style={{ marginTop: "2%" }}
                  value={field?.["Effective Date"]}
                  onValueChange={(e) => {
                    seteffectivedatevalidation("");
                    // const initialDateString = e;
                    // const initialDate = new Date(initialDateString);
                    // const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                    const utcFormatted = new Date(e).toISOString();
                    setField({
                      ...field,
                      "Effective Date": e ? utcFormatted : null,
                    });
                  }}
                  useMaskBehavior={true}
                />
                <p
                  style={{
                    color: "red",
                    fontWeight: "400",
                    position: "absolute",
                  }}
                >
                  {effectivedatevalidation}
                </p>
              </div>
            </div>
            <div className="collectionpopupBLOCK2inputt">
              <p>Change Reason</p>
              <div>
                <DropDown
                  setfields={setField}
                  fields={field}
                  fieldNmae={"Change Reason"}
                  dropDownData={changeReason}
                  reset={setChangeReasonvalidation}
                />
                <p
                  style={{
                    color: "red",
                    fontWeight: "400",
                    position: "absolute",
                  }}
                >
                  {ChangeReasonvalidation}
                </p>
              </div>
            </div>
          </div>
          {/* <div style={{ display: "flex", marginTop: "2%" }}>
            <input
              checked={field?.["Apply to all obligation in this docket"]}
              type="checkbox"
            />
            <p>Apply to all obligation in this docket.</p>
          </div> */}
          <div style={{ marginTop: "4%" }}>
            <p style={{ color: "var(--TextColor)", fontWeight: "bold" }}>
              Please add a detailed comment <span>*</span>
            </p>
            <textarea
              value={field?.["Please add a detailed comment "]}
              onChange={(e) => {
                setcommentvalidation("");
                setField({
                  ...field,
                  "Please add a detailed comment ": e.target.value,
                });
              }}
              style={{ width: "100%", height: "150px", marginTop: "1.4%" }}
            />
            <p
              style={{
                color: "red",
                fontWeight: "400",
                position: "absolute",
              }}
            >
              {commentvalidation}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1%",
              marginBottom: "2%",
            }}
          >
            <button
              style={{
                backgroundColor: "var(--button2)",
                color: "white",
                width: "15%",
                cursor: "pointer",
              }}
              onClick={() => {
                onSave();
              }}
            >
              Done
            </button>
            <button
              style={{
                width: "15%",
                marginLeft: "1%",
                cursor: "pointer",
                background: "var(--button-lightblack-lightblue)",
                color: "var(--TextColor)",
              }}
              onClick={() => {
                setChangeFinatialSTatusPopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
