import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import TwoFactorAuth from "../twoFactorAuth/twoFactorAuth.js";
import httpService from "../../../services/http-service";
import {
  setAuthToken,
  getAuthToken,
  handleSetleLocal,
} from "../../../services/auth-service";
import Cookies from "js-cookie";
import { LoadPanel } from "devextreme-react";
import { logOut } from "../../../components/Logout";

export default function SignIn() {
  const [checkTwoFact, setCheckTwoFact] = useState(false);
  const [fields, setFields] = useState({
    gUserID: "",
    sUserID: "",
    sFirstLast: "",
    email: "",
    sTwoFactorTextNumber: "",
    bTwoFactorEnabled: "",
    sTwoFactorEmail: "",
    sDefaultLocation: "",
    mPhoto: "",
    gRoleID: "",
    sProfile_Skin: "",
    sRoleName: "",
  });
  const [errors, setErrors] = useState({});
  const [isEyeOpen, setIsEyeOpen] = useState(false);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [rememberMe, setRememberMe] = useState(
    Cookies.get("username") ? true : false
  );
  const [responceError, setResponceError] = useState({
    email: "",
    password: "",
  });

  var passPattern = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%^&$*()_+\\-=[\\]{}|;':\",./<>?~`])(?=.{10,20})"
  );

  const handleChange = (event) => {
    setResponceError({ ...responceError, email: "", password: "" });
    let field = fields,
      fieldName = event.target.name,
      fieldValue = event.target.value;
    if (fieldName == "password") {
      if (!passPattern.test(fields[fieldName])) {
        errors[fieldName] = "Please enter valid password.";
      } else {
        errors[fieldName] = "";
      }
    }
    field[fieldName] = fieldValue;

    setFields({ ...field });
  };

  useEffect(() => {
    setFields({
      ...fields,
      email:
        Cookies.get("username") && Cookies.get("username") !== "undefined"
          ? Cookies.get("username")
          : "",
      password:
        Cookies.get("password") && Cookies.get("password") !== "undefined"
          ? Cookies.get("password")
          : "",
    });
  }, []);
  const validateLogin = () => {
    let error = {},
      formIsValid = true,
      emailField = "email",
      passField = "password";

    if (!fields[emailField] || fields[emailField].trim() == "") {
      formIsValid = false;
      error[emailField] = "*Please enter your email";
    }
    if (!fields[passField] || fields[passField].trim() == "") {
      formIsValid = false;
      error[passField] = "Please enter your password";
    }
    if (typeof fields[passField] !== "undefined") {
      if (!passPattern.test(fields[passField])) {
        formIsValid = false;
        error[passField] = "Please enter valid password.";
        setResponceError({
          ...responceError,
          password: `Username / Password doesn't match`,
        });
      }
    }

    return {
      errors: error,
      formIsValid: formIsValid,
    };
  };
  const validateLoginForm = () => {
    let response = validateLogin();
    setErrors(response.errors);
    return response.formIsValid;
  };

  const loginToken = async () => {
    let domainName = (url) =>
      url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
    let domailURL = window.location.href;
    let pathURL = domainName(domailURL);
    handleSetleLocal("pathURL", pathURL);
    {
      rememberMe && Cookies.set("username", fields.email);
    }
    {
      rememberMe && Cookies.set("password", fields.password);
    }
    setLoading(true);
    try {
      const response = await httpService.get(
        `api/Account/getToken?username=${fields.email}&password=${fields.password}&domain=${pathURL}`,
        { headers: { Domain: `${pathURL}` } }
      );
      if (response?.status === 200) {
        var token = response.data?.token;
        setAuthToken(token);
        handleSetleLocal("username", fields.email);
        handleSetleLocal("password", fields.password);
        handleLoginClick();
      } else {
        setLoading(false);
      }
    } catch (val) {
      setLoading(false);
      if (val?.response?.status === 401) {
        setResponceError({
          ...responceError,
          password: `Username / Password doesn't match`,
        });
      } else {
        if (val?.response?.status) {
          navigate(`/error/500`);
        } else {
          navigate("/error/500");
        }
      }
    }
  };

  const [userDetail, setUserDetail] = useState();

  const handleLoginClick = async (e) => {
    const token = getAuthToken("token");
    let domainName = (url) =>
      url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
    let domailURL = window.location.href;
    let pathURL = domainName(domailURL);
    try {
      const response = await httpService.get(
        `/api/Account/userLogin?username=${fields.email}&password=${fields.password}`,
        { headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` } }
      );
      if (response?.data?.isSuccess) {
        setLoading(false);
        let data = response?.data?.data;
        setUserDetail(response?.data?.data);
        setFields({
          ...fields,
          gUserID: data.gUserID,
          sUserID: data.sUserID,
          sFirstLast: data.sFirstLast,
          email: data.sEMail,
          sTwoFactorTextNumber: data.sTwoFactorTextNumber,
          sTwoFactorEmail: data.sTwoFactorEmail,
          bTwoFactorEnabled: data.bTwoFactorEnabled,
          sDefaultLocation: data.sDefaultLocation,
          mPhoto: data.mPhoto,
          gRoleID: data.gRoleID,
          sProfile_Skin: data.sProfile_Skin,
          sRoleName: data.sRoleName,
        });
      } else {
        setLoading(false);
      }
      handleSetleLocal("user_designation", response?.data?.data?.sRoleName);
      if (response?.data?.data?.bTwoFactorEnabled) {
        setLoading(false);
        setCheckTwoFact(!checkTwoFact);
        handleSetleLocal("user_designation", response?.data?.data?.sRoleName);
      } else {
        setLoading(false);
        handleSetleLocal("email", token);
        window.location.reload();
        navigate("/");
      }
    } catch (val) {
      if (val?.response?.status == 401) {
        logOut();
      } else {
        if (val?.response?.status) {
          navigate(`/error/500`);
        } else {
          navigate("/error/500");
        }
      }
    }
  };
  const RememberMe = () => {
    setRememberMe(!rememberMe);
    rememberMe && Cookies.remove("username");
    rememberMe && Cookies.remove("password");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateLoginForm()) {
      loginToken();
    }
  };
  function togglePwd() {
    setIsEyeOpen(!isEyeOpen);
  }

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div
        style={{
          height: "100vh",
          backgroundImage:
            "linear-gradient(to bottom, #0077b7 0%, #004469 100%)",
        }}
      >
        <div className="signPage">
          <div className="signInner" style={{ background: "white" }}>
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
            {!checkTwoFact ? (
              <>
                <h1>Log in</h1>
                <form>
                  <div className="fieldWrap">
                    <label>Username / Registered Email Address</label>
                    <input
                      type="text"
                      name="email"
                      value={fields.email}
                      placeholder="Enter your username / email address"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="fieldWrap">
                    <label>Password </label>
                    <input
                      type={isEyeOpen ? "text" : "password"}
                      name="password"
                      placeholder="Enter your password"
                      value={fields.password}
                      onChange={(e) => handleChange(e)}
                    />
                    {responceError.password && (
                      <p className="errorIcon" style={{ color: "red" }}>
                        {responceError.password}
                      </p>
                    )}
                    <span
                      onClick={togglePwd}
                      className={`eyebtn ${!isEyeOpen ? " " : " eyeopen"}`}
                    ></span>
                  </div>
                  <div className="fieldWrap">
                    <div className="checkBox">
                      <input
                        type="checkbox"
                        id="Remember"
                        name="remember"
                        placeholder="Enter your password"
                        onChange={(e) => {
                          RememberMe();
                        }}
                        value={fields?.remember}
                        defaultChecked={rememberMe}
                      />
                      <label htmlFor="Remember">Remember me</label>
                    </div>
                    <p className="error">{errors?.remember}</p>
                  </div>
                  <div className="fieldWrap">
                    <button className="btn btn-default" onClick={handleSubmit}>
                      Sign in
                    </button>
                  </div>
                  <div className="fieldWrap ">
                    <div className="linkWrap">
                      <Link to="/forgot-password" className="link">
                        Forgot password ?
                      </Link>
                    </div>
                  </div>
                </form>
              </>
            ) : (
              <TwoFactorAuth
                email={fields?.email}
                userDetail={userDetail}
                data={fields}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
