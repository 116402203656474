import { useState } from "react";
import "./participante.css";
import "./HeaderPopupComponent/models.css";
import { TopDynamicTab } from "./TopDynamicTab";
import { useSelector } from "react-redux";
import { ParticipentHeader } from "./ParticipantHeader";
import { DynamicTabb } from "../../components/DynamicTab/DynamicTab";

export const Participate = () => {
  const [tabSwitch, setTabSwitch] = useState(2);
  const [editable, setEditable] = useState(false);

  // const { dynamicParticipatTab } = useSelector((store) => store.participate);

  return (
    <>
      <div className="particcipentPage">
        {/* <TopDynamicTab
          setTabSwitch={setTabSwitch}
          tabSwitch={tabSwitch}
          text={dynamicParticipatTab}
        /> */}
        <DynamicTabb />

        <ParticipentHeader
          editable={editable}
          setEditable={setEditable}
          editParticipant={true}
        />
      </div>
    </>
  );
};
