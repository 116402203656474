const convertDateFormat = (dateString) => {
  if (dateString) {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleDateString("en-US");
  } else {
    return "";
  }
};

export const DocumentWizardDocketsColoumn = [
  { title: "Docket/Count" },
  { title: "Offense" },
  { title: "Court Name" },
  { title: "Status" },
  { title: "Sentence Date", date: true },
  { title: "Adj End Date", date: true },
  { title: "Primary Supervisor" },
];
export const conditionsViolationsColumn = [
  { title: "Sort" },
  { title: "Condition" },
  { title: "Violations" },
  { title: "Third Perosn Violations" },
  { title: "First Perosn Violations" },
];

export const setDocumentWizardDocketTableColumnData = (data) => {
  const arr = [];
  data.map((val, i) => {
    const dateParts = val?.sSentenceDateRange?.split(" - ");
    const sentenceDate = dateParts[0];
    const adjEndDate = dateParts[1];
    arr.push({
      "Docket/Count": val?.sDocketNumberCourtCounts,
      Offense: val?.sOffenseDescription,
      "Court Name": val?.sCourtName,
      Status: val?.sSupervisionStatusName,
      "Sentence Date": convertDateFormat(sentenceDate),
      "Adj End Date": convertDateFormat(adjEndDate),
      "Primary Supervisor": val?.sPrimarySupervisor,
      gDocketId: val?.gDocketId,
      gDocketTypeId: val?.gDocketTypeId,
      gEntryUserId: val?.gEntryUserId,
      gSentenceCourtEntityId: val?.gSentenceCourtEntityId,
      ID: i,
    });
  });
  return arr;
};

export const setDocumentWizardConditionsViolationsColumnData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      Sort: val?.iSort,
      Condition: val?.sCondition,
      Violations: val?.sViolation,
      "Third Perosn Violations": val?.sThirdPersonViolation,
      "First Perosn Violations": val?.sFirstPersonViolation,
      gEntityId: val?.gEntityId,
      gVerbiageId: val?.gVerbiageId,
      sCourtName: val?.sCourtName,
      ID: i,
    });
  });
  return arr;
};
