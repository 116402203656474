import { DateBox, ScrollView } from "devextreme-react";
import { ColumnDropdown } from "../../../components/Dropdownn";
import "../monitoring.css";
import { useState } from "react";
import { useEffect } from "react";
import { MonitoringFunction } from "../MonitoringFunction";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
      />
    </>
  );
};

const AddEquipment = ({
  addEquipmentPopup,
  setAddEquipmentPopup,
  setLoading,
  setToastConfig,
  toastConfig,
  refreshApi,
  vendorDropdownData,
  equipmentTypeDropdownData,
  equipmentStatusDropdownData,
  equipmentLocationDropdownData,
  addEquipmentPopupType,
  navigate,
  equimentRowData,
}) => {
  const [fields, setFields] = useState({
    vendor: { name: "", id: "" },
    equipmentType: { name: "", id: "" },
    status: { name: "", id: "" },
    location: { name: "", id: "" },
    serialNumber: "",
    dateReceivedFromVendor: "",
    dateReceivedToVendor: "",
    notes: "",
    latestVendorStatus: "",
  });
  const ApiClass = new MonitoringFunction();
  const [vendorErrorValidation, setVendorErrorValidation] = useState("");
  const [equipmentTypeErrorValidation, setEquipmentTypeErrorValidation] =
    useState("");
  const [statusErrorValidation, setStatusErrorValidation] = useState("");
  const [locationErrorValidation, setLocationErrorValidation] = useState("");
  const [serialNumberErrorValidation, setSerialNumberErrorValidation] =
    useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "serialNumber") {
      if (fields?.serialNumber.charAt(0) === " ") {
        setSerialNumberErrorValidation("Please Enter Valid Serial Number.");
      } else {
        setSerialNumberErrorValidation("");
      }
    }
  };

  useEffect(() => {
    setVendorErrorValidation("");
    setEquipmentTypeErrorValidation("");
    setStatusErrorValidation("");
    setLocationErrorValidation("");
    setSerialNumberErrorValidation("");
    if (addEquipmentPopupType === "Add") {
      setFields({
        vendor: { name: "", id: "" },
        equipmentType: { name: "", id: "" },
        status: { name: "", id: "" },
        location: { name: "", id: "" },
        serialNumber: "",
        dateReceivedFromVendor: "",
        dateReceivedToVendor: "",
        notes: "",
        latestVendorStatus: "",
      });
    } else {
      setFields((prev) => ({
        ...prev,
        vendor: {
          name: equimentRowData?.Vendor,
          id: equimentRowData?.gEmVendorId ? equimentRowData?.gEmVendorId : "",
        },
        equipmentType: {
          name: equimentRowData?.[`Equipment Type`],
          id: equimentRowData?.gEmEquipmentTypeId
            ? equimentRowData?.gEmEquipmentTypeId
            : "",
        },
        status: {
          name: equimentRowData?.Status,
          id: equimentRowData?.gEquipmentStatusId
            ? equimentRowData?.gEquipmentStatusId
            : "",
        },
        location: {
          name: equimentRowData?.Location,
          id: equimentRowData?.gLocationId ? equimentRowData?.gLocationId : "",
        },
        serialNumber: equimentRowData?.[`Serial Number`]
          ? equimentRowData?.[`Serial Number`]
          : "",
        dateReceivedFromVendor: equimentRowData?.[`Date Received From Vendor`]
          ? equimentRowData?.[`Date Received From Vendor`]
          : "",
        dateReceivedToVendor: equimentRowData?.[`Date Return To Vendor`]
          ? equimentRowData?.[`Date Return To Vendor`]
          : "",
        notes: equimentRowData?.Note ? equimentRowData?.Note : "",
        latestVendorStatus: equimentRowData?.sVendorNote
          ? equimentRowData?.sVendorNote
          : "",
      }));
    }
  }, [addEquipmentPopup]);

  const data = {
    GEmEquipmentId:
      addEquipmentPopupType === "Add"
        ? "00000000-0000-0000-0000-000000000000"
        : equimentRowData?.gEmEquipmentId,
    GEmVendorId: fields?.vendor?.id,
    GEmEquipmentTypeId: fields?.equipmentType?.id,
    SSerialNumber: fields?.serialNumber,
    DReceived: fields?.dateReceivedFromVendor,
    DReturned: fields?.dateReceivedToVendor,
    SNote: fields?.notes,
    GEquipmentStatusId: fields?.status?.id,
    GLocationId: fields?.location?.id,
  };

  const handleSave = () => {
    let isValid = true;
    if (fields?.vendor?.id === "") {
      isValid = false;
      setVendorErrorValidation("Please Select Vendor.");
    }
    if (fields?.equipmentType?.id === "") {
      isValid = false;
      setEquipmentTypeErrorValidation("Please Select Equipment Type.");
    }
    if (fields?.status?.id === "") {
      isValid = false;
      setStatusErrorValidation("Please Select Status.");
    }
    if (fields?.location?.id === "") {
      isValid = false;
      setLocationErrorValidation("Please Select Location.");
    }
    if (!fields?.serialNumber || fields?.serialNumber.charAt(0) === " ") {
      isValid = false;
      setSerialNumberErrorValidation("Please Enter Valid Serial Number.");
    }
    if (isValid) {
      ApiClass.saveAddEditEquiment(
        setAddEquipmentPopup,
        navigate,
        setLoading,
        setToastConfig,
        toastConfig,
        refreshApi,
        addEquipmentPopupType,
        data
      );
    }
  };

  return (
    <>
      <div className="add-Equipment">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <p>EM Equipment</p>
            </div>
            <p class="popup_header_title_para">EM.8</p>
          </div>
          <svg
            onClick={() => {
              setAddEquipmentPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <ScrollView style={{ height: "560px" }}>
          <div className="popup-container-data">
            <div className="edit_container_first">
              <div className="edit_content_flex edit_content_space">
                <p className="edit_container_label">
                  Vendor <span className="marks_mandatary">*</span>
                </p>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"vendor"}
                  dropDownData={vendorDropdownData}
                  reset={setVendorErrorValidation}
                />
                {vendorErrorValidation && (
                  <p
                    className="error_validation"
                    style={{ position: "absolute", top: "93px" }}
                  >
                    {vendorErrorValidation}
                  </p>
                )}
              </div>
              <div className="edit_content_flex edit_content_space">
                <p className="edit_container_label">
                  Equipment Type <span className="marks_mandatary">*</span>
                </p>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"equipmentType"}
                  dropDownData={equipmentTypeDropdownData}
                  reset={setEquipmentTypeErrorValidation}
                />
                {equipmentTypeErrorValidation && (
                  <p
                    className="error_validation"
                    style={{ position: "absolute", top: "93px" }}
                  >
                    {equipmentTypeErrorValidation}
                  </p>
                )}
              </div>
            </div>
            <div className="edit_container_first">
              <div className="edit_content_flex">
                <p className="edit_container_label">
                  Serial Number <span className="marks_mandatary">*</span>
                </p>
                <input
                  id="serialNumber"
                  name="serialNumber"
                  type="text"
                  placeholder="Enter Serial Number"
                  className="edit_container_input"
                  value={fields?.serialNumber}
                  onChange={handleInputChange}
                />
                {serialNumberErrorValidation && (
                  <p
                    className="error_validation"
                    style={{ position: "absolute", top: "182px" }}
                  >
                    {serialNumberErrorValidation}
                  </p>
                )}
              </div>
              <div className="edit_content_flex edit_content_space">
                <p className="edit_container_label">
                  Status <span className="marks_mandatary">*</span>
                </p>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"status"}
                  dropDownData={equipmentStatusDropdownData}
                  reset={setStatusErrorValidation}
                />
                {statusErrorValidation && (
                  <p
                    className="error_validation"
                    style={{ position: "absolute", top: "182px" }}
                  >
                    {statusErrorValidation}
                  </p>
                )}
              </div>
            </div>
            <div className="edit_container_first">
              <div className="edit_content_flex">
                <p className="edit_container_label">
                  Date Received from Vendor
                </p>
                <DateBox
                  useMaskBehavior={true}
                  value={fields.dateReceivedFromVendor}
                  onValueChange={(e) => {
                    if (e) {
                      const initialDateString = e;
                      const initialDate = new Date(initialDateString);
                      const utcTime =
                        initialDate.getTime() -
                        initialDate.getTimezoneOffset() * 60000;
                      const utcFormatted = new Date(utcTime).toISOString();
                      setFields((prev) => ({
                        ...prev,
                        dateReceivedFromVendor: e ? utcFormatted : null,
                      }));
                    }
                  }}
                />
              </div>
              <div className="edit_content_flex">
                <p className="edit_container_label">Date Returned to Vendor</p>
                <DateBox
                  useMaskBehavior={true}
                  value={fields.dateReceivedToVendor}
                  onValueChange={(e) => {
                    if (e) {
                      const initialDateString = e;
                      const initialDate = new Date(initialDateString);
                      const utcTime =
                        initialDate.getTime() -
                        initialDate.getTimezoneOffset() * 60000;
                      const utcFormatted = new Date(utcTime).toISOString();
                      setFields((prev) => ({
                        ...prev,
                        dateReceivedToVendor: e ? utcFormatted : null,
                      }));
                    }
                  }}
                />
              </div>
            </div>
            <div className="edit_container_first">
              <div className="edit_content_flex edit_content_space">
                <p className="edit_container_label">
                  Location <span className="marks_mandatary">*</span>
                </p>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"location"}
                  dropDownData={equipmentLocationDropdownData}
                  reset={setLocationErrorValidation}
                />
                {locationErrorValidation && (
                  <p
                    className="error_validation"
                    style={{ position: "absolute", top: "362px" }}
                  >
                    {locationErrorValidation}
                  </p>
                )}
              </div>
            </div>
            <div className="edit_container_first">
              <div className="edit_container_second edit_content_flex">
                <p className="edit_container_label">Notes</p>
                <textarea
                  name="notes"
                  id="notes"
                  placeholder="Example notes"
                  className="dark-mode-textarea"
                  style={{ width: "340px" }}
                  value={fields?.notes}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <div className="edit_container_second edit_content_flex">
                <p className="edit_container_label">Latest Vendor Status</p>
                <textarea
                  name="latestVendorStatus"
                  id="latestVendorStatus"
                  placeholder="Write status"
                  className="dark-mode-textarea"
                  style={{ width: "340px" }}
                  disabled={true}
                  value={fields?.latestVendorStatus}
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </div>
            <div className="popup_buttons">
              <button className="btn primary_btn" onClick={handleSave}>
                Save
              </button>
              <button
                className="btn"
                onClick={() => {
                  setAddEquipmentPopup(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </ScrollView>
      </div>
    </>
  );
};

export default AddEquipment;
