const convertDateFormat = (dateString) => {
  if (dateString) {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleDateString("en-US");
  } else {
    return "";
  }
};

export const schedulingDateRanges = [
  { name: "Last 3 Days" },
  { name: "Last 7 Days" },
  { name: "Month to Date" },
  { name: "Previous Month" },
  { name: "Next Month from Date" },
  { name: "Next 3 Month from Date" },
  { name: "last 3 Month to Date" },
  { name: "last 6 Month to Date" },
  { name: "Year to Date" },
  { name: "Previous Year" },
  { name: "Today" },
];

export const disbursementObligationListTableTitle = [
  { title: "Check Date", date: true },
  { title: "Bank Account" },
  { title: "Check Number" },
  { title: "Memo" },
  { title: "Person" },
  { title: "Offender #" },
  { title: "Fee Code" },
  { title: "Amount in Check", currency: true },
  { title: "Obligation Amount", currency: true },
  { title: "Amount on Hand", currency: true },
  { title: "Amount to Disburse", currency: true },
  { title: "Amount Disbursed", currency: true },
  { title: "Recipient Balance", currency: true },
];
export const disbursementObligationCheckListTableTitle = [
  { title: "Check Date", date: true },
  { title: "Batch Number" },
  { title: "Bank Account" },
  { title: "Check Number" },
  { title: "Amount", currency: true },
  { title: "Entity / Receipt" },
  { title: "Memo" },
  { title: "Created", date: true },
  { title: "Obligation Count" },
  { title: "Void", bool: true },
];
export const setDisbursementChecklistTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Check Date": convertDateFormat(val?.dCheck),
      "Batch Number": val?.sBatchNumber,
      "Bank Account": val?.sBankAccountNumber,
      "Check Number": val?.iCheckNumber,
      Amount: val?.fAmount,
      "Entity / Receipt": val?.sPayToTheOrderOf,
      Memo: val?.sMemo,
      Created: convertDateFormat(val?.dDoe),
      "Obligation Count": val?.iObligationCount,
      Void: val?.bVoid,
      gEntryUserId: val?.gEntryUserId,
      gEntityId: val?.gEntityId,
      gDisbursementCheckId: val?.gDisbursementCheckId,
      gDisbursementCheckBatchId: val?.gDisbursementCheckBatchId,
      ID: i,
    });
  });
  return arr;
};
export const setDisbursementCheckObligationlistTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Check Date": convertDateFormat(val?.dCheck),
      "Bank Account": val?.sBankAccountNumber,
      "Check Number": val?.iCheckNumber,
      "Entity / Receipt": val?.sPayToTheOrderOf,
      Memo: val?.sMemo,
      Person: val?.sPersonNameLastFirst,
      "Offender #": val?.sOffenderNumber,
      "Fee Code": val?.sLocalLegalCodeFull,
      "Amount in Check": val?.fDisbursementAmount,
      "Obligation Amount": val?.fObligationAmount,
      "Amount on Hand": val?.fBalanceEligible,
      "Amount to Disburse": val?.fAmountToDisburse,
      "Amount Disbursed": val?.fAmountDisbursed,
      "Recipient Balance": val?.fBalanceRecipient,
      gDisbursementCheckBatchId: val?.gDisbursementCheckBatchId,
      gObligationID: val?.gObligationID,
      gPersonID: val?.gPersonID,
      ID: i,
    });
  });
  return arr;
};
