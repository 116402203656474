import React, { useState, useEffect } from "react";
import { DateBox, LoadPanel, Popup } from "devextreme-react";
import "../financialPop.css";
import { handleGetLocal } from "../../../../../../services/auth-service";
import axios from "axios";
import { ColumnDropdown } from "../../../../../../components/Dropdownn";
import { EditConfirmMessage } from "./EditConfirmMessage";
import { logOut } from "../../../../../../components/Logout";
import { useNavigate } from "react-router-dom";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, paymentMethod }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name || paymentMethod}
      // reset={reset}
      />
    </>
  );
};

export const EditPopup = ({
  setEditPopup,
  listData,
  paymnetMethodData,
  rowInfoData,
  setCallApi,
  callApi,
  toastConfig,
  setToastConfig,
  paymentDate,
  transactionId,
  paymentMethod,
  cashDrawer
}) => {
  const [loading, setLoading] = useState(false);
  const [dateValidation, setDateValidation] = useState("");
  const [editConfirmPopup, setEditConfirmPopup] = useState(false);
  const [fields, setfields] = useState({
    paymentDate: "",
    cashDrawer: {
      name: rowInfoData?.[`Cash Drawer`],
      id: rowInfoData?.gCashDrawerId,
    },
    paymentMethod: {
      name: rowInfoData?.[`Payment Method`],
      id: rowInfoData?.gPaymentMethodId,
    },
    reference: "",
    transactionID: "",
    manualReceipt: "",
    description: "",
  });

  useEffect(() => {
    setfields((prev) => ({
      ...prev,
      paymentDate: rowInfoData?.[`Date of Receipt`],
      cashDrawer: {
        name: rowInfoData?.[`Cash Drawer`],
        id: rowInfoData?.gCashDrawerId,
      },
      paymentMethod: {
        name: rowInfoData?.[`Payment Method`],
        id: rowInfoData?.gPaymentMethodId,
      },
      reference: rowInfoData?.Reference,
      transactionID: transactionId ? transactionId : rowInfoData?.[`Transaction ID`],
      manualReceipt: rowInfoData?.sManualReceiptNumber,
      description: rowInfoData?.sReceiptNote,
    }));
  }, [rowInfoData, transactionId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setfields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const data = {
    paymentID: rowInfoData?.gPaymentId,
    dReceipt: fields.paymentDate,
    paymentMethodID: fields.paymentMethod?.id,
    reference: fields.reference,
    cC_TransactionID: fields.transactionID,
    manualReceiptNumber: fields.manualReceipt,
    cashDrawerID: fields.cashDrawer?.id,
    receiptNote: fields.description,
  };

  const handleSave = () => {
    const selectedDate = new Date(fields.paymentDate);
    const currentDate = new Date();
    const currentDateWithoutTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    const minAllowedDate = new Date();
    minAllowedDate.setDate(currentDateWithoutTime.getDate() - 30);

    if (selectedDate > currentDateWithoutTime) {
      setDateValidation("Payment Date cannot be in the future");
    } else if (selectedDate < minAllowedDate) {
      setEditConfirmPopup(true);
    } else {
      saveApi();
      setDateValidation("");
    }
  };

  const navigate = useNavigate();

  const saveApi = async () => {
    const pathname = handleGetLocal("pathURL");
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/Payment/update-payment`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setEditPopup(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: val.data.responseMessage,
          type: val.data.responseIcon?.toLowerCase(),
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: val.data.responseMessage,
            type: val.data.responseIcon?.toLowerCase(),
          });
        }, 1900);
        setCallApi(!callApi);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div className="edit_payment">
        <Popup
          visible={editConfirmPopup}
          onHiding={() => {
            setEditConfirmPopup(false);
          }}
          showTitle={false}
          width={590}
          height={230}
          showCloseButton={true}
          hideOnOutsideClick={true}
        >
          <EditConfirmMessage setEditConfirmPopup={setEditConfirmPopup} saveApi={saveApi} />
        </Popup>
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 15.4586V18.4986C0 18.7786 0.22 18.9986 0.5 18.9986H3.54C3.67 18.9986 3.8 18.9486 3.89 18.8486L14.81 7.93859L11.06 4.18859L0.15 15.0986C0.0500001 15.1986 0 15.3186 0 15.4586ZM17.71 5.03859C18.1 4.64859 18.1 4.01859 17.71 3.62859L15.37 1.28859C14.98 0.898594 14.35 0.898594 13.96 1.28859L12.13 3.11859L15.88 6.86859L17.71 5.03859Z"
                  fill="white"
                />
              </svg>
              <p>Edit Payment</p>
            </div>
            <p class="popup_header_title_para">PY.4</p>
          </div>
          <svg
            onClick={() => {
              setEditPopup(false);
              setfields((prev) => ({
                ...prev,
                paymentDate: "",
                cashDrawer: "",
                paymentMethod: "",
                reference: "",
                transactionID: "",
                manualReceipt: "",
                description: "",
              }));
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="edit_container">
          <div className="edit_container_first">
            <div className="edit_content_flex">
              <p className="edit_container_label">Payment Date</p>
              <DateBox
                value={fields.paymentDate || paymentDate}
                onValueChange={(e) => {
                  setfields((prev) => ({
                    ...prev,
                    paymentDate: e,
                  }));
                  setDateValidation("");
                }}
                useMaskBehavior={true}
              />
              <p className="error_validation customize_error">{dateValidation}</p>
            </div>
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">Payment Method</p>
              <DropDown
                paymentMethod={paymentMethod}
                setfields={setfields}
                fields={fields}
                fieldNmae={"paymentMethod"}
                dropDownData={paymnetMethodData?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
              />
            </div>
          </div>
          <div className="edit_container_first">
            <div className="edit_content_flex">
              <p className="edit_container_label">Reference</p>
              <input
                id="reference"
                name="reference"
                type="text"
                placeholder="Enter reference number"
                className="edit_container_input"
                value={fields.reference}
                onChange={handleInputChange}
              />
            </div>
            <div className="edit_content_flex">
              <p className="edit_container_label">Transaction ID</p>
              <input
                id="transactionID"
                name="transactionID"
                type="number"
                placeholder="Enter transaction ID"
                className="edit_container_input"
                value={fields.transactionID}
                onChange={handleInputChange}
                disabled
              />
            </div>
          </div>
          <div className="edit_container_first">
            <div className="edit_content_flex">
              <p className="edit_container_label">Manual Receipt #</p>
              <input
                id="manualReceipt"
                name="manualReceipt"
                type="text"
                className="edit_container_input"
                value={fields.manualReceipt}
                onChange={handleInputChange}
                placeholder="Enter Manual Receipt"
              />
            </div>
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">Cash Drawer</p>
              <DropDown
                setfields={setfields}
                fields={fields}
                paymentMethod={cashDrawer}
                fieldNmae={"cashDrawer"}
                dropDownData={listData?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
              />
            </div>
          </div>
          <div className="edit_container_second edit_content_flex">
            <p className="edit_container_label">Receipt Note</p>
            <textarea
              name="description"
              id="description"
              placeholder="Write Receipt Note"
              value={fields.description}
              onChange={handleInputChange}
            ></textarea>
          </div>
          <div className="edit_container_second_alert">
            NOTE : Changes made to this payment may effect previously printed reports. Please use caution when making
            changes
          </div>
        </div>
        <div className="popup_buttons">
          <button
            className="btn primary_btn"
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </button>
          <button
            className="btn edit_payment_btn"
            onClick={() => {
              setEditPopup(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};
