import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { SignIn, ForgotPsswd, Home, User, ChangePsswd } from "./pages";
import { SingleCard } from "./layouts";
import { Main } from "./pages/Main";
import { Participate } from "./pages/Participants/Participate";
import { useEffect, useState } from "react";
import { DocketList } from "./pages/docket-list/DocketList";
import { PersonList } from "./pages/person-list/PersonList";
import { Administration } from "./pages/Administration/Administration";
import UserList from "./pages/Administration/UserList";
import { AddParticipant } from "./pages/Participants/AddParticipant/AddParticipant";
import UserAdd from "./pages/Administration/UserAdd";
import UserEdit from "./pages/Administration/UserEdit";
import { ImageViewer } from "./components/ImageViewer/ImageViewer";
import { Error } from "./pages/Error/Error";
import { AdminUser } from "./pages/User/AdminUser";
import { GOToDocket } from "./pages/Participants/ParticipantSubTabs/GoToDocket/GoToDocket";
import { G0ToObligation } from "./pages/Participants/ParticipantSubTabs/G0ToObligation/G0ToObligation";
import { FeeSchedule } from "./pages/Administration/FeeSchedule/FeeSchedule";
import { Forms } from "./pages/Forms/Forms";
import { ReportWizard } from "./pages/ReportWizard/ReportWizard";
import { Report } from "./pages/Report/Report";
import { Scheduling } from "./pages/Scheduling/Scheduling";
import { ProntactEntities } from "./pages/prontact/prontactEntities/ProntactEntities";
import Contacts from "./pages/prontact/contacts/Contacts";
import ReportCatalog from "./pages/prontact/reportcatalog/ReportCatalog";
import { AllocationAdjustment } from "./pages/Participants/AllocationAdjustment/AllocationAdjustment";
import DocumentWizard from "./pages/Participants/DocumentWizard/DocumentWizard";
import DisbursementObligationList from "./pages/Financial/DisbursementObligationList";
import PreDisbursementsReport from "./pages/Financial/PreDisbursementsReport";
import DisbursementChecksList from "./pages/Financial/DisbursementChecksList";
import Equiment from "./pages/Monitoring/Equiment";
import EquimentLog from "./pages/Monitoring/EquimentLog";
import VendorInventory from "./pages/Monitoring/VendorInventory";
import RateEditor from "./pages/Monitoring/RateEditor";
import MonitoringReferral from "./pages/Monitoring/MonitoringReferral";
import AgencyInvoices from "./pages/Monitoring/AgencyInvoices";
import ReceiveAgencyPayment from "./pages/Monitoring/ReceiveAgencyPayment";
import { ArrearageMinder } from "./pages/arrearage-minder/ArrearageMinder";
import { RevenueReport } from "./pages/revenue-report/RevenueReport";
import { Tasks } from "./pages/Tasks/Tasks";
import { ReportEditor } from "./pages/Administration/ReportEditor";
import { AgingReport } from "./pages/ar-aging-report/AgingReport";
import { ProntactEntitiesList } from "./pages/prontact/prontactEntities/ProntactEntitiesList";
import MissingEMRates from "./pages/Monitoring/MissingEMRates";
import PeriodSummary from "./pages/Monitoring/PeriodSummary";
import { OnlinePayment } from "./pages/Participants/ParticipantSubTabs/Financial/components/OnlinePayment";
import { ExternalPendingPayments } from "./pages/Participants/ParticipantSubTabs/Financial/components/ExternalPendingpayments";
import { CreateInvoice } from "./pages/Participants/ParticipantSubTabs/G0ToObligation/GoTOObligationSubTabs/Monitoring/MonitoringSubtab/CreateInvoice";
import RevenueSummary from "./pages/RevenueSummary/RevenueSummary";

export default function UnauthenticatedContent() {
  const [confirmClose, setConfirmClose] = useState(false);

  useEffect(() => {
    const handleWindowClose = (event) => {
      if (confirmClose) {
        // Allow the window to close
        return;
      }

      // Cancel the close event
      event.preventDefault();
      // Show confirmation dialog
      const confirmationMessage = "Are you sure you want to close Pronto Application?";
      event.returnValue = confirmationMessage; // Standard way for most browsers
      return confirmationMessage; // For legacy browsers
    };

    // Add event listener when the component mounts
    // window.addEventListener("beforeunload", handleWindowClose);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleWindowClose);
    };
  }, [confirmClose]);

  const handleCloseConfirmation = () => {
    setConfirmClose(true);
    // Close the browser window
    window.close();
  };

  const [user, setUser] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    let a = localStorage.getItem("email");
    if (a) {
      navigate("/");
    }
    setUser(a);
  }, []);
  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <SingleCard title="Sign In">
              <SignIn />
            </SingleCard>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <SingleCard
              title="Forgot password"
              description="Please enter registered email address. We will send a link to this email address"
            >
              <ForgotPsswd />
            </SingleCard>
          }
        />

        <Route
          path="/change-password"
          element={
            <SingleCard
              title="Forgot password"
              description="Please enter registered email address. We will send a link to this email address"
            >
              <ChangePsswd />
            </SingleCard>
          }
        />

        {user && (
          <Route
            path="/"
            element={
              <SingleCard title="Main">
                <Main />
              </SingleCard>
            }
          >
            <Route path="/" element={<Navigate to="/home" />} />
            <Route
              path="/home"
              element={
                <SingleCard title="Home">
                  <Home />
                </SingleCard>
              }
            />
            <Route
              path="/participant/:id"
              element={
                <SingleCard title="participant">
                  <Participate />
                </SingleCard>
              }
            />
            <Route
              path="/add-participant"
              element={
                <SingleCard title="add-participant">
                  <AddParticipant />
                </SingleCard>
              }
            />
            <Route
              path="/image-viewer"
              element={
                <SingleCard title="image-viewer">
                  <ImageViewer />
                </SingleCard>
              }
            />
            <Route
              path="/administration/basic-data-editor"
              element={
                <SingleCard title="Administration">
                  <Administration />
                </SingleCard>
              }
            />
            <Route
              path="/administration/report-editor"
              element={
                <SingleCard title="Administration">
                  <ReportEditor />
                </SingleCard>
              }
            />
            <Route
              path="/administration/user-list"
              element={
                <SingleCard title="User List">
                  <UserList />
                </SingleCard>
              }
            />
            <Route
              path="/administration/users"
              element={
                <SingleCard title="Users">
                  <UserAdd />
                </SingleCard>
              }
            />
            <Route
              path="/administration/users/:id"
              element={
                <SingleCard title="Users/:id">
                  <UserEdit />
                </SingleCard>
              }
            />
            <Route
              path="/administration/users/feeschedule"
              element={
                <SingleCard title="Fee Schedule">
                  <FeeSchedule />
                </SingleCard>
              }
            />
            <Route
              path="/administration/users/feeschedule/:id"
              element={
                <SingleCard title="Fee Schedule">
                  <FeeSchedule />
                </SingleCard>
              }
            />
            <Route
              path="/forms"
              element={
                <SingleCard title="Forms">
                  <Forms />
                </SingleCard>
              }
            />
            <Route
              path="/scheduling"
              element={
                <SingleCard title="Scheduling">
                  <Scheduling />
                </SingleCard>
              }
            />
            <Route
              path="/tasks"
              element={
                <SingleCard title="Tasks">
                  <Tasks />
                </SingleCard>
              }
            />
            <Route
              path="/user-information"
              element={
                <SingleCard title="User">
                  {localStorage.getItem("user_designation") === "Administrator" ? <AdminUser /> : <User />}
                </SingleCard>
              }
            />
            <Route
              path="/personlist"
              element={
                <SingleCard title="Person List">
                  <PersonList />
                </SingleCard>
              }
            />
            <Route
              path="/revenuesummary"
              element={
                <SingleCard title="Revenue Summary">
                  <RevenueSummary />
                </SingleCard>
              }
            />
            <Route
              path="/docketlist"
              element={
                <SingleCard title="Docket List">
                  <DocketList />
                </SingleCard>
              }
            />
            <Route
              path="/arrearageminder"
              element={
                <SingleCard title="Arrearage Minder">
                  <ArrearageMinder />
                </SingleCard>
              }
            />
            <Route
              path="/revenuereport"
              element={
                <SingleCard title="Revenue Report">
                  <RevenueReport />
                </SingleCard>
              }
            />
            <Route
              path="/aragingreport"
              element={
                <SingleCard title="AR Aging Report">
                  <AgingReport />
                </SingleCard>
              }
            />
            <Route
              path="/docket/:id"
              element={
                <SingleCard title="docket">
                  <GOToDocket />
                </SingleCard>
              }
            />

            <Route
              path="/report/wizard"
              element={
                <SingleCard title="Report Wizard">
                  <ReportWizard />
                </SingleCard>
              }
            />
            <Route
              path="/prontact/entity"
              element={
                <SingleCard title="Prontact Entities">
                  <ProntactEntities />
                </SingleCard>
              }
            />
            <Route
              path="/prontact/entity-list"
              element={
                <SingleCard title="Prontact Entities">
                  <ProntactEntitiesList />
                </SingleCard>
              }
            />
            <Route
              path="/prontact/entity/:Entitiesid"
              element={
                <SingleCard title="Prontact Entities">
                  <ProntactEntities />
                </SingleCard>
              }
            />
            <Route
              path="/prontact/contacts"
              element={
                <SingleCard title="Prontact Contacts">
                  <Contacts />
                </SingleCard>
              }
            />
            <Route
              path="/prontact/contacts/:id"
              element={
                <SingleCard title="Prontact Contacts">
                  <Contacts />
                </SingleCard>
              }
            />
            <Route
              path="/prontact/reportcatalog"
              element={
                <SingleCard title="Report Catalog">
                  <ReportCatalog />
                </SingleCard>
              }
            />
            <Route
              path="/prontact/reportcatalog/:id"
              element={
                <SingleCard title="Report Catalog">
                  <ReportCatalog />
                </SingleCard>
              }
            />
            <Route
              path="/allocationadjustment"
              element={
                <SingleCard title="Allocation Adjustment">
                  <AllocationAdjustment />
                </SingleCard>
              }
            />
            <Route
              path="/documentwizard"
              element={
                <SingleCard title="Document Wizard">
                  <DocumentWizard />
                </SingleCard>
              }
            />
            <Route
              path="/report"
              element={
                <SingleCard title="Report">
                  <Report />
                </SingleCard>
              }
            />
            <Route
              path="/financial/disbursement/pre-disbursements-report"
              element={
                <SingleCard title="Pre-Disbursements Report">
                  <PreDisbursementsReport />
                </SingleCard>
              }
            />
            <Route
              path="/financial/disbursement/check-list"
              element={
                <SingleCard title="Disbursement Check List">
                  <DisbursementChecksList />
                </SingleCard>
              }
            />
            <Route
              path="/financial/disbursement/obligation-list"
              element={
                <SingleCard title="Disbursement Check Obligation List">
                  <DisbursementObligationList />
                </SingleCard>
              }
            />
            <Route
              path="/financial/onlinePayment"
              element={
                <SingleCard title="Online Payments">
                  <OnlinePayment />
                </SingleCard>
              }
            />
             <Route
              path="/financial/externalPendingPayments"
              element={
                <SingleCard title="External Pending Payments">
                  <ExternalPendingPayments />
                </SingleCard>
              }
            />
            <Route
              path="/monitoring/equipment"
              element={
                <SingleCard title="Monitoring Equipment">
                  <Equiment />
                </SingleCard>
              }
            />
            <Route
              path="/monitoring/equipment-log"
              element={
                <SingleCard title="Monitoring Equipment Log">
                  <EquimentLog />
                </SingleCard>
              }
            />
            <Route
              path="/monitoring/rate-editor"
              element={
                <SingleCard title="Rate Editor">
                  <RateEditor />
                </SingleCard>
              }
            />
            <Route
              path="/monitoring/missing-rates"
              element={
                <SingleCard title="Missing EM Rates">
                  <MissingEMRates />
                </SingleCard>
              }
            />
            <Route
              path="/monitoring/vendor-inventory"
              element={
                <SingleCard title="Vendor Inventory">
                  <VendorInventory />
                </SingleCard>
              }
            />
            <Route
              path="/monitoring/referral"
              element={
                <SingleCard title="Referral">
                  <MonitoringReferral />
                </SingleCard>
              }
            />
            <Route
              path="/monitoring/agency-invoices"
              element={
                <SingleCard title="Agency Invoices">
                  <AgencyInvoices />
                </SingleCard>
              }
            />
            <Route
              path="/monitoring/receive-agency-payment"
              element={
                <SingleCard title="Receive Agency Payment">
                  <ReceiveAgencyPayment />
                </SingleCard>
              }
            />
             <Route
              path="/monitoring/create-agency-invoice"
              element={
                <SingleCard title="Create Agency Invoice">
                   <CreateInvoice/>
                </SingleCard>
              }
            />
            <Route
              path="/monitoring/period-summary"
              element={
                <SingleCard title="Monitoring Period Summary">
                  <PeriodSummary />
                </SingleCard>
              }
            />
            <Route
              path="/obligation/:obligationid/:obligationid2"
              element={
                <SingleCard title="obligation">
                  <G0ToObligation />
                </SingleCard>
              }
            />
            <Route path="*" element={<Navigate to="/home" replace={true} />} />
          </Route>
        )}
        <Route
          path="/error/:statusCode"
          element={
            <SingleCard title="Error">
              <Error />
            </SingleCard>
          }
        />
        <Route path="*" element={<Navigate to={"/login"} />}></Route>
      </Routes>
    </>
  );
}
