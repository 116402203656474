import { LoadPanel, ScrollView } from "devextreme-react";
import axios from "axios";
import { logOut } from "../../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { handleGetLocal } from "../../../../services/auth-service";
import { setaddquickadd } from "../../../../reducer/ParticipateReducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const PendingPOpup = ({
  setPendingPOpuptogal,
  setToastConfig,
  toastConfig,
  reset,
  setreset,
  gettaskEditDAta,
  checklist,
  getTopDynamicDocketid,
  getTableChecklist,
  ChecklistNOteTExt,
}) => {
  const [loading2, setLoading2] = useState(false);
  const navigate = useNavigate();
  const [validation, setvalidation] = useState("");
  const pathname = handleGetLocal("pathURL");
  const dispatch = useDispatch();
  const { addquickadd } = useSelector((store) => store.participate);
  const delay = async () => {
    setLoading2(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/task/delay-task?taskId=${gettaskEditDAta?.taskId}&comment=${fields?.comment}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading2(false);
        if (val?.data?.isSuccess) {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Delayed Successfully!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Delayed Successfully!",
              type: "success",
            });
          }, 1400);
          dispatch(setaddquickadd(!addquickadd));
          setPendingPOpuptogal(false);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Network Error!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Network Error!",
              type: "error",
            });
          }, 1400);
        }
      })
      .catch((val) => {
        setLoading2(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const getCompletTask = async () => {
    setLoading2(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/checklist/update-record`,
        [{
          recordId: getTopDynamicDocketid,
          itemRecordId: gettaskEditDAta?.gCheckListItemRecordId
            ? gettaskEditDAta?.gCheckListItemRecordId
            : null,
          itemId: gettaskEditDAta?.gCheckListItemId,
          note: fields?.comment,
          complete: gettaskEditDAta?.["Completed ?"],
        }],
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading2(false);

        if (val?.data?.isSuccess) {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Note Edit Successfully!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Note Edit Successfully!",
              type: "success",
            });
          }, 1400);
          getTableChecklist();
          setPendingPOpuptogal(false);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Network Error!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Network Error!",
              type: "error",
            });
          }, 1400);
        }
      })
      .catch((val) => {
        setLoading2(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const [fields, setfields] = useState({
    comment: "",
  });

  const resetFUnc = () => {
    setvalidation("");
    setfields({
      comment: ChecklistNOteTExt
        ? gettaskEditDAta?.Note
          ? gettaskEditDAta?.Note
          : ""
        : "",
    });
  };

  const onsave = () => {
    if (fields.comment) {
      setvalidation("");
      if (checklist) {
        getCompletTask();
      } else {
        delay();
      }
    } else {
      !fields.comment && setvalidation("Please Enter Comment");
    }
  };

  useEffect(() => {
    resetFUnc();
  }, [reset]);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading2} delay={10} />
      <div
        style={{
          width: "100%",
          height: "448px",
          border: "3px solid var(--button2)",
        }}
      >
        <div>
          <div className="RecivePaymentModel_header">
            <div
              style={{ width: "150px" }}
              className="RecivePaymentModel_header_name"
            >
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.41 5.41L14.58 0.58C14.21 0.21 13.7 0 13.17 0H2C0.9 0 0 0.9 0 2V14.01C0 15.11 0.89 16 1.99 16H18C19.1 16 20 15.1 20 14V6.83C20 6.3 19.79 5.79 19.41 5.41ZM13 1.5L18.5 7H14C13.45 7 13 6.55 13 6V1.5Z"
                  fill="white"
                />
              </svg>
              <p style={{ marginLeft: "-10px" }}>Enter Note </p>
              <span
                style={{
                  padding: "2px 9px",
                  gap: "8px",
                  width: "45px",
                  height: "24px",
                  background: "#FEF2F2",
                  borderRadius: "4px",
                }}
              >
                <p
                  style={{
                    color: "#DC2626",
                    fontFamily: "Readex Pro",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "10px",
                    lineHeight: "20px",
                    width: "21px",
                    height: "20px",
                  }}
                >
                  UT.2
                </p>
              </span>
            </div>
            <svg
              onClick={() => {
                setPendingPOpuptogal(false);
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>

          <div style={{ width: "96%", marginLeft: "2%" }}>
            <div style={{ marginTop: "2%" }}>
              <h2 style={{ color: "var(--TextColor)" }}>Enter Note</h2>
              {!ChecklistNOteTExt && <p
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  marginTop: "10px",
                }}
              >
                You must enter a reason for this delay
              </p>}
            </div>
            <div style={{ marginTop: "20px" }}>
              <p style={{ color: "var(--TextColor)", fontWeight: "bold" }}>
                Please enter a detailed comment{" "}
                <span style={{ fontSize: "15px" }}>*</span>
              </p>
              <textarea
                value={fields.comment}
                onChange={(e) => {
                  setvalidation("");
                  setfields({ ...fields, comment: e.target.value });
                }}
                style={{ height: "180px", width: "100%", marginTop: "3%" }}
              />
              <p style={{ color: "red", position: "absolute" }}>{validation}</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "2%",
                marginBottom: "2%",
              }}
            >
              <button
                style={{
                  backgroundColor: "var(--button2)",
                  color: "white",
                  width: "15%",
                  cursor: "pointer",
                  marginRight: "1%",
                }}
                onClick={() => {
                  onsave();
                }}
              >
                Save
              </button>
              <button
                style={{
                  color: "var(--TextColor)",
                  width: "15%",
                  cursor: "pointer",
                  background: "var(--button-lightblack-lightblue)",
                }}
                onClick={() => {
                  resetFUnc();
                  setreset(!reset);
                  setPendingPOpuptogal(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
