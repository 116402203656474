import { useEffect, useState } from "react";
import { ReactComponent as AddIcon } from "../../../../../assets/images/icons/add.svg";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { emObligationTableColumn } from "../Data";
import { Popup } from "devextreme-react";
import { AddObligation } from "./AddObligation";
import { EmFunction } from "../EmFunction";
import { ApiError } from "../../../../Error/ApiError";
import ConfirmPopup from "../../../../Monitoring/Popups/ConfirmPopup";

export const ObligationTab = ({
  setLoading,
  loading,
  setToastConfig,
  toastConfig,
  navigate,
  paymentTypeDropdownData,
  paymentAgencyDropdownData,
  frequencyDropdownData,
  rowleveldata,
  setEMPriodpopshow,
  parentRowData,
}) => {
  const [emObligationData, setEmObligationData] = useState([]);
  const [addObligationPopup, setAddObligationPopup] = useState(false);
  const [emObligationErrorhandling, setEmObligationErrorhandling] = useState(false);
  const [typeTab, SetTypeTab] = useState("");
  const [obligationDataSource, setObligationDataSource] = useState([]);
  const [rowLevelData, setRowLevelData] = useState([]);
  const ApiClass = new EmFunction();
  const [confirmPopup, setConfirmPopup] = useState(false);

  const refreshApi = () => {
    rowleveldata?.gPersonId &&
      ApiClass.getEmObligationData(
        setLoading,
        navigate,
        setEmObligationData,
        setEmObligationErrorhandling,
        rowleveldata?.gPersonId,
        rowleveldata?.gEmPeriodId
      );
  };

  useEffect(() => {
    refreshApi();
    ApiClass.getEmObligationDropdownApi(setLoading, navigate, setObligationDataSource, rowleveldata?.gDocketId);
  }, []);

  return (
    <>
      <Popup
        visible={addObligationPopup}
        onHiding={() => {
          setAddObligationPopup(false);
        }}
        showTitle={false}
        width={768}
        height={620}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddObligation
          addObligationPopup={addObligationPopup}
          setAddObligationPopup={setAddObligationPopup}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          setLoading={setLoading}
          navigate={navigate}
          typeTab={typeTab}
          paymentTypeDropdownData={paymentTypeDropdownData}
          paymentAgencyDropdownData={paymentAgencyDropdownData}
          frequencyDropdownData={frequencyDropdownData}
          obligationDataSource={obligationDataSource}
          refreshApi={refreshApi}
          emRowLevelData={rowleveldata}
          rowLevelData={rowLevelData}
          parentRowData={parentRowData}
        />
      </Popup>
      <Popup
        visible={confirmPopup}
        onHiding={() => {
          setConfirmPopup(false);
        }}
        showTitle={false}
        width={450}
        height={170}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <ConfirmPopup
          confirmPopup={confirmPopup}
          setConfirmPopup={setConfirmPopup}
          setLoading={setLoading}
          navigate={navigate}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          refreshApi={refreshApi}
          apiURl={
            rowLevelData?.gPeriodObligationId
              ? `api/em/void-period-obligation?gPeriodObligationId=${rowLevelData?.gPeriodObligationId}`
              : ""
          }
          typePara={"Void"}
        />
      </Popup>
      <div className="buttons">
        <button
          className="btn"
          onClick={() => {
            SetTypeTab("Add");
            setAddObligationPopup(true);
          }}
        >
          Add Obligation <AddIcon className="svg-blue-white" />
        </button>
      </div>
      {emObligationErrorhandling ? (
        <ApiError />
      ) : (
        <div className="table-input-width">
          <Table1
            editTable={false}
            height={400}
            Tablecoloum={emObligationTableColumn}
            tableRow={emObligationData}
            load={loading}
            coloumWidthText={"Em-Obligation"}
            mandatoryColoum={"Type"}
            SetTypeTab={SetTypeTab}
            EMObligationMenu={true}
            setEMAddObligationPopup={setAddObligationPopup}
            setRowLevelData={setRowLevelData}
            setConfirmPopup={setConfirmPopup}
            setVoideFalse={true}
          />
        </div>
      )}
      <div className="popup_buttons">
        <button
          className="btn"
          onClick={() => {
            setEMPriodpopshow(false);
          }}
        >
          Cancel
        </button>
      </div>
    </>
  );
};
