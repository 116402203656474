const convertDateFormat = (dateString) => {
  if (dateString) {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleDateString("en-US");
  } else {
    return "";
  }
};
export const emTableColumn = [
  { title: "Docket #" },
  { title: "Offense Description" },
  { title: "Supervision Status" },
  { title: "Court" },
];
export const emObligationTableColumn = [
  { title: "Reference" },
  { title: "Fee Name" },
  { title: "Type" },
  { title: "Rate", currency: true },
  { title: "Rate Start", date: true },
  { title: "Rate End", date: true },
  { title: "Duration" },
  { title: "Days Active" },
  { title: "Frequency" },
  { title: "Court" },
  { title: "Billing Agency" },
  { title: "Offender" },
  { title: "Void", bool: true },
];
export const emEquipmentLogTableColumn = [
  { title: "Hookup Date", date: true },
  { title: "Type" },
  { title: "Serial No" },
  { title: "Return Status", date: true },
  { title: "Removal Date" },
];

export const setEmTableDatas = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Docket #": val?.sDocketNumberCourtCounts,
      "Offense Description": val?.sOffenseDescription,
      "Supervision Status": val?.sSupervisionStatusName,
      Court: val?.sCourtName,
      sOffenderNumber: val?.sOffenderNumber,
      sPersonNameLastFirst: val?.sPersonNameLastFirst,
      gPersonId: val?.gPersonId,
      gOffenseId: val?.gOffenseId,
      gEntryUserId: val?.gEntryUserId,
      gDocketId: val?.gDocketId,
      userinfo: val,
      sDocketNumber: val?.sDocketNumber,
      iCourtCounts: val?.iCourtCounts,
      ID: i,
    });
  });
  return arr;
};
export const setEmExpnadTableData = (data) => {
  const expandData = [];
  data.map((val, i) => {
    expandData.push({
      "EM Type": val?.sEmType,
      "Start Date": convertDateFormat(val?.dStart),
      "End Date": convertDateFormat(val?.dEnd),
      Duration: val?.iDuration,
      "Days Active": val?.iDaysActive,
      "End Reason": val?.sEndReason,
      Void: val?.bVoid,
      Comment: val?.sComment,
      gDocketId: val?.gDocketId,
      gEntryUserId: val?.gEntryUserId,
      gEmTypeId: val?.gEmTypeId,
      gEmPeriodId: val?.gEmPeriodId,
      bVoid: val?.bVoid,
      gPersonId: val?.gPersonId,
      gEmEndReasonId: val?.gEmEndReasonId,
      gEmPaymentTypeId: val?.gEmPaymentTypeId,
      userInfo: val,
      ID: i,
    });
  });
  return expandData;
};
export const setEmObligationTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      Reference: val?.sReference,
      "Fee Name": val?.sDdsviolationDescription,
      Type: val?.sPaymentTypeName,
      Rate: val?.fRate,
      "Rate Start": convertDateFormat(val?.dRateStart),
      "Rate End": convertDateFormat(val?.dRateEnd),
      Duration: val?.iDuration,
      "Days Active": val?.iDaysActive,
      Frequency: val?.sRateFrequencyName,
      Court: val?.sCourtName,
      "Billing Agency": val?.sBillingAgency,
      Void: val?.bVoid,
      Offender: val?.sLastFirst,
      gPersonId: val?.gPersonId,
      gBillingEntityId: val?.gBillingEntityId,
      gDocketId: val?.gDocketId,
      gEmPaymentTypeId: val?.gEmPaymentTypeId,
      gEmPeriodId: val?.gEmPeriodId,
      gEntryUserId: val?.gEntryUserId,
      gObligationId: val?.gObligationId,
      gPeriodObligationId: val?.gPeriodObligationId,
      gRateFrequencyId: val?.gRateFrequencyId,
      ID: i,
    });
  });
  return arr;
};
export const setEmEquipmentTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Hookup Date": convertDateFormat(val?.dHookup),
      Type: val?.sEquipmentTypeName,
      "Serial No": val?.sSerialNumber,
      "Return Status": val?.sEquipmentReturnCondition,
      "Removal Date": convertDateFormat(val?.dRemoval),
      Offender: val?.sLastFirst,
      gPersonId: val?.gPersonId,
      gEmEquipmentLogId: val?.gEmEquipmentLogId,
      gEmEquipmentId: val?.gEmEquipmentId,
      gEntryUserId: val?.gEntryUserId,
      gEmPeriodId: val?.gEmPeriodId,
      gEmEquipmentReturnedConditionId: val?.gEmEquipmentReturnedConditionId,
      sNote: val?.sNote,
      sEquipmentStatusName: val?.sEquipmentStatusName,
      ID: i,
    });
  });
  return arr;
};
export const setEmEquipmentDropdownData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      Type: val?.sEquipmentTypeName,
      Vendor: val?.sVendorName,
      "Serial #": val?.sSerialNumber,
      Location: val?.s,
      Available: val?.s,
      gPersonId: val?.gPersonId,
      gEmEquipmentId: val?.gEmEquipmentId,
      ID: i,
    });
  });
  return arr;
};
