import { Index } from "../../../../Participants/ParticipantSubTabs/Notes";

export const ShuduleNote = ({ dockerValue, userinfo,feeshudule }) => {
  return (
    <>
      <Index
        userinfo={userinfo}
        dockerValue={dockerValue}
        GoTONote={false}
        showchecklist={false}
        feeshedule={true}
        feeshudule={feeshudule}
      />
    </>
  );
};
