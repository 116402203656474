import axios from "axios";
import { handleGetLocal } from "../../../services/auth-service";

export const CreateNewPerson = ({
  createNewPersonPopup,
  setCreateNewPersonPopup,
  formRowData,
  setLoading,
  setNoteConfirmationPopup,
}) => {
  const pathname = handleGetLocal("pathURL");

  const data = { gFormID: formRowData?.gFormId };

  const handleSave = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Forms/createperson-fromform`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setCreateNewPersonPopup(false);
          setNoteConfirmationPopup(true);
        } else {
          console.error(val.data.responseMessage);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <>
      <div className="create-new-person">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg
                width="15"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z"
                  fill="white"
                />
              </svg>
              <p>Add Person</p>
            </div>
            <p class="popup_header_title_para">PS.13</p>
          </div>
          <svg
            onClick={() => {
              setCreateNewPersonPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="confirmation-forms-container">
          <div className="input-flex">
            <p className="edit_container_label">Offender Infromation</p>
            <div className="input-flex-column">
              <p className="edit_container_label">
                {formRowData?.sFirstName} {formRowData?.sLastName}
              </p>
              <p className="edit_container_label">
                {formRowData?.["Date Received"]}
              </p>
            </div>
          </div>
          <div className="popup_buttons">
            <button className="btn primary_btn" onClick={handleSave}>
              Create New Person
            </button>
            <button
              className="btn"
              onClick={() => {
                setCreateNewPersonPopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
