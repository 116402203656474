export const setTableData = (data) => {
    const arr = [];
    data.map((val) => {
      arr.push({
        ID: val?.gPersonID,
        Category: val?.sCategory,
        "Start Date": val?.dStart,
        "End Date": val?.dEnd,
        "Client Id": val?.sOffenderNumber,
        "Client Name": val?.sLastFirst,
        Days: val?.iDaysInPeriod,
        Rate: val?.fRate,
        Revenue: val?.fRevenue,
        Collections: val?.fCollections,
        "Fee Name": val?.sFeeName,
        Region: val?.sRegionName,
        Location: val?.sLocation,
        "Court/Agency": val?.sCourtName,
        "Billing Agency": val?.bIsBilling,
        "Fee Type": val?.sFeeScheduleType,
        gPersonId: val?.gPersonID,
      });
    });
    return arr;
  };
  