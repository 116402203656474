import React, { useEffect, useReducer, useRef, useState } from "react";
import { userAccessListColumn } from "../Data";
import { DataGrid, LoadPanel, Popup, SelectBox, Toast } from "devextreme-react";
import { Column, Editing, HeaderFilter, Lookup, RequiredRule } from "devextreme-react/data-grid";
import { getAuthToken } from "../../../services/auth-service";
import httpService from "../../../services/http-service";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../components/Logout";
import CopyUserList from "./CopyUserList";
import { RefundCCPayment } from "../../Participants/ParticipantSubTabs/Financial/components/overviewPopUp/RefundCCPayment";

const accessSource = {
  store: {
    type: "array",
    data: ["None", "Add", "View", "Edit", "Full"],
    key: "id",
  },
};

export const UserAccess = ({ userAccessData, userId, setUserAccessData, saveUser, setSaveUser, setGoodToSaveUser }) => {
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openCopyUserList, setOpenCopyUserList] = useState(false);
  const [userList, setUserList] = useState([]);
  const [mimicUserList, setMimicUserList] = useState([]);
  const [mimicUser, setMimicUser] = useState(null);
  const [userAccessGrid, setUserAccessGrid] = useState([]);
  const ref = useRef(null);

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const token = getAuthToken();

  let domainName = (url) => url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
  let domailURL = window.location.href;
  let pathname = domainName(domailURL);

  const navigate = useNavigate();

  const saveData = async () => {
    await ref.current.instance().saveEditData();
    if (ref.current.instance().hasEditData()) {
      setSaveUser(false);
    } else {
      setGoodToSaveUser(true);
    }
  };

  useEffect(() => {
    if (saveUser === true) {
      saveData();
    }
  }, [saveUser]);

  useEffect(() => {
    const getForms = async () => {
      httpService
        .get("api/User/get-user-access-forms", {
          headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
        })
        .then((res) => {
          setForms(
            res.data.data
              .map((form) => ({
                sFormDescription: `${form.sFormName} - ${form.sFormDescription}`,
                sFormName: form.sFormName,
              }))
              .sort((a, b) => a.sFormName.localeCompare(b.sFormName))
          );
        })
        .catch((err) => {
          if (!err?.response) {
            navigate(`/error/500`);
          } else if (err?.response?.status == 401) {
            logOut();
          }
        });
    };

    const getAccessUsers = async () => {
      httpService
        .get("api/User/GetUserDetailList", {
          headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
        })
        .then((res) => {
          setMimicUserList(res.data.data);
        })
        .catch((err) => {
          if (!err?.response) {
            navigate(`/error/500`);
          } else if (err?.response?.status == 401) {
            logOut();
          }
        });
    };

    const getMimicUserId = async () => {
      httpService
        .get(`api/user/getUserById?id=${userId}`, {
          headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
        })
        .then((res) => {
          setMimicUser(res.data?.data?.gMimicUserId);
        })
        .catch((err) => {
          if (!err?.response) {
            navigate(`/error/500`);
          } else if (err?.response?.status == 401) {
            logOut();
          }
        });
    };

    const getUserAccessGrid = async () => {
      httpService
        .get(`api/user/get-user-access-forms-by-id?gUserId=${userId}`, {
          headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
        })
        .then((res) => {
          setUserAccessGrid(res.data?.data);
        })
        .catch((err) => {
          if (!err?.response) {
            navigate(`/error/500`);
          } else if (err?.response?.status == 401) {
            logOut();
          }
        });
    };
    getForms();
    getAccessUsers();
    getMimicUserId();
    getUserAccessGrid();
  }, [userAccessData]);

  const updateUserAccess = (changes) => {
    httpService
      .post("api/User/update-user-access", changes, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((res) => {
        if (res.data.data === true) {
          setToastConfig({
            isVisible: true,
            type: "success",
            message: "User Access Updated!",
          });
        }
      })
      .catch((err) => {
        if (!err?.response) {
          navigate(`/error/500`);
        } else if (err?.response?.status == 401) {
          logOut();
        }
      });
  };

  const onCopyAnotherUser = () => {
    setLoading(true);
    httpService
      .get("api/User/GetUserDetailList", {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setLoading(false);
          setUserList(val?.data?.data);
          setMimicUser(null);
          updateMimicUser(null);
          setOpenCopyUserList(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        }
      });
  };

  const updateMimicUser = (mimicUserId) => {
    const body = {
      gUserId: userId,
      gMimicUserId: mimicUserId,
    };
    setLoading(true);
    httpService
      .post(
        `api/User/update-mimic-user-id?gUserId=${userId}&gMimicuserId=${mimicUserId === null ? "" : mimicUserId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
        }
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        }
      });
  };

  const setCopiedData = (dataCopied) => {
    const modifiedArray1 = userAccessGrid.map(item1 => {
      const matchedItem = dataCopied.find(item2 => item2.sForm === item1.sFormname);
      if (matchedItem) {
        return {
          ...item1,
          sAccess: matchedItem.sAccess 
        };
      }
      return item1;
    });
    setUserAccessGrid(modifiedArray1);
    const updatedUserAccessBody = modifiedArray1.map((item) => {
      return {
        "sForm": item.sFormname,
        "sAccess": item.sAccess,
        "gUserId": item.gUserId
      }
    })
    updateUserAccess(updatedUserAccessBody);
  }

  return (
    <>
      <Popup
        visible={openCopyUserList}
        onHiding={() => {
          setOpenCopyUserList(false);
        }}
        showTitle={false}
        width={700}
        height={600}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <CopyUserList
          userList={userList}
          setOpenCopyUserList={setOpenCopyUserList}
          setCopiedData={setCopiedData}
        />
      </Popup>
      <Toast
        visible={toastConfig.isVisible}
        position={"top"}
        maxWidth={400}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
          });
        }}
        displayTime={600}
      />
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div style={{ display: "flex", gap: "-100px", flexDirection: "column" }}>
        <div style={{ display: "flex", gap: "40px", justifyContent: "center", alignItems: "center" }}>
          <button onClick={onCopyAnotherUser}>Copy from Another User</button>
          <div> Mimic Id: </div>
          <SelectBox
            dataSource={mimicUserList
              .filter((user) => user?.bActive)
              .sort((user1, user2) => {
                if (user1?.sLastFirst < user2?.sLastFirst) return -1;
                if (user1?.sLastFirst > user2?.sLastFirst) return 1;
                return 0;
              })}
            displayExpr="sLastFirst"
            valueExpr="gUserId"
            searchEnabled={true}
            searchMode={"contains"}
            value={mimicUser}
            onValueChange={(value) => {
              setMimicUser(value);
              updateMimicUser(value);
            }}
          />
          <button
            onClick={() => {
              setMimicUser(null);
              updateMimicUser(null);
            }}
          >
            Clear Mimic User
          </button>
        </div>
        <div style={{ maxWidth: "1200px" }}>
          <DataGrid
            dataSource={userAccessGrid}
            onSaved={(e) => {
              updateUserAccess(
                e.changes.map((change) => {
                  const data = change.data;
                  return { sForm: data.sFormname, sAccess: data.sAccess, gUserId: userId };
                })
              );
            }}
            disabled={mimicUser}
            ref={ref}
          >
            <HeaderFilter visible={true} allowSearch />
            <Editing mode="batch" allowUpdating={true} />
            <Column dataField="sFormDescription" caption="Form Name" allowEditing={false} />
            <Column dataField="sFormname" caption="Form Id" allowEditing={false} />
            {/* <Column dataField="sElement" caption="Element" /> */}
            <Column dataField="sAccess" caption="Access">
              <RequiredRule />
              <Lookup dataSource={accessSource} />
            </Column>
          </DataGrid>
        </div>
      </div>
    </>
  );
};
