import { LoadPanel } from "devextreme-react";
import MonitoringCrumbs from "./components/MonitoringCrumbs";
import MonitoringTabs from "./components/MonitoringTabs";
import { Toster } from "../../components/Toster/Toster";
import { useState } from "react";
import { ApiError } from "../Error/ApiError";
import { Table1 } from "../Administration/Table1/Table1";
import { vendorInventoryTableColumn } from "./Data";
import { MonitoringFunction } from "./MonitoringFunction";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { DynamicTabb } from "../../components/DynamicTab/DynamicTab";

const VendorInventory = () => {
  const [vendorInventoryData, setVendorInventoryData] = useState([]);
  const [errorhandling, setErrorhandling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const ApiClass = new MonitoringFunction();
  const navigate = useNavigate();
  const [vendorInventoryRowData, setVendorInventoryRowData] = useState([]);

  const refreshApi = () => {
    ApiClass.getVendorInventoryData(setLoading, navigate, setVendorInventoryData, setErrorhandling);
  };

  const createPersonVendorInventoryAPi = () => {
    if (vendorInventoryRowData?.gInventoryId) {
      ApiClass.createPersonVendorInventory(
        navigate,
        setLoading,
        setToastConfig,
        toastConfig,
        refreshApi,
        vendorInventoryRowData?.gInventoryId
      );
    }
  };

  useEffect(() => {
    refreshApi();
  }, []);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <MonitoringCrumbs title={"Vendor Inventory"} />
      <div className="financial-page">
        {/* <MonitoringTabs /> */}
        <DynamicTabb />
        <div className="financial-page-section">
          <p className="page-info-number">EM.9</p>
          <div className="page-info-buttons">
            <div className="buttons">
              <button
                className="btn"
                onClick={() => {
                  setexportPopupOpen(true);
                }}
              >
                Export
                <svg
                  className="svg-blue-white"
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                    fill="#424167"
                  />
                </svg>
              </button>
              <button
                className="btn"
                onClick={() => {
                  refreshApi();
                  setErrorhandling(false);
                }}
              >
                Refresh
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_6247_15093)">
                    <path
                      d="M17.6493 6.34902C16.0193 4.71902 13.7093 3.77902 11.1693 4.03902C7.49929 4.40902 4.47929 7.38902 4.06929 11.059C3.51929 15.909 7.26929 19.999 11.9993 19.999C15.1893 19.999 17.9293 18.129 19.2093 15.439C19.5293 14.769 19.0493 13.999 18.3093 13.999C17.9393 13.999 17.5893 14.199 17.4293 14.529C16.2993 16.959 13.5893 18.499 10.6293 17.839C8.40929 17.349 6.61929 15.539 6.14929 13.319C5.30929 9.43902 8.25929 5.99902 11.9993 5.99902C13.6593 5.99902 15.1393 6.68902 16.2193 7.77902L14.7093 9.28902C14.0793 9.91902 14.5193 10.999 15.4093 10.999H18.9993C19.5493 10.999 19.9993 10.549 19.9993 9.99902V6.40902C19.9993 5.51902 18.9193 5.06902 18.2893 5.69902L17.6493 6.34902Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6247_15093">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
          </div>
          <div className="table-section">
            {errorhandling ? (
              <ApiError />
            ) : (
              <Table1
                editTable={false}
                height={800}
                Tablecoloum={vendorInventoryTableColumn}
                tableRow={vendorInventoryData}
                load={loading}
                coloumWidthText={"Monitoring Vendor Inventory"}
                mandatoryColoum={"Vendor"}
                exportInDifferentFormate={true}
                exportPopupOpen={exportPopupOpen}
                setexportPopupOpen={setexportPopupOpen}
                exportwidth={27}
                exporttitle={"Monitoring Vendor Inventory"}
                vendorInventoryMenu={true}
                vendorInventoryRowData={vendorInventoryRowData}
                setVendorInventoryRowData={setVendorInventoryRowData}
                createPersonVendorInventoryAPi={createPersonVendorInventoryAPi}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorInventory;
