import { ContextMenu, DataGrid } from "devextreme-react";
import { Column, HeaderFilter } from "devextreme-react/data-grid";
import { useSelector } from "react-redux";

const EmDetailTemplate = ({
  props,
  setrowlevelchilddata,
  setParentRowData,
}) => {
  const { emTableExpandData } = useSelector((store) => store.AdminTable);
  const desired_gPaymentId = props?.data?.data?.gDocketId;
  const filteredData = [];

  for (const item of emTableExpandData) {
    if (item?.gDocketId === desired_gPaymentId) {
      filteredData.push(item);
    }
  }
  return (
    <>
      <DataGrid
        dataSource={filteredData}
        showBorders={true}
        onCellPrepared={(e) => {
          if (e.isAltRow) {
            e.cellElement.style.backgroundColor =
              "var(--table-second-row-light-black)";
          }
        }}
        onContextMenuPreparing={(e) => {
          setrowlevelchilddata(e?.row?.data);
          setParentRowData(props?.data?.data);
        }}
        allowColumnResizing={true}
        columnResizingMode={"nextColumn"}
      >
        <Column dataField="EM Type" width={"auto"} />
        <Column dataField="Start Date" width={"auto"} />
        <Column dataField="End Date" width={"auto"} />
        <Column dataField="Duration" width={"auto"} />
        <Column dataField="Days Active" width={"auto"} />
        <Column dataField="End Reason" width={"auto"} />
        <Column dataField="Comment" width={"auto"} />
        <HeaderFilter visible={true} allowSearch={true} />
      </DataGrid>
    </>
  );
};

export default EmDetailTemplate;
