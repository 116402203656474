export const ProductionDBAlertPopup = ({ setOpenProductionDBAlertPopup }) => {
  return (
    <>
      <div
        style={{ border: "3px solid var(--button2)", height: "100%" }}
        className="PersonModalbody"
      >
        <div className="PersonModal">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: ".8%",
            }}
            className="modelHeader"
          >
            <p
              style={{
                marginTop: "3%",
                color: "white",
                fontWeight: "500",
                fontSize: "13px",
              }}
            >
              Alert
            </p>
          </div>
          <span
            style={{
              fontSize: "30px",
              marginRight: "2%",
              cursor: "pointer",
              color: "white",
            }}
            onClick={() => {
              setOpenProductionDBAlertPopup(false);
            }}
          >
            &times;
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            padding: "20px",
            fontSize: "25px",
            gap: "20px",
          }}
        >
          <div>
            You are currently accessing a{" "}
            <strong style={{ color: "red" }}>non-production</strong> database.
            Any transactions or changes you make will be lost.{" "}
          </div>
          <div>Please consult your manager if you have any questions.</div>
          <button
            style={{ backgroundColor: "var(--button2)", color: "white" }}
            onClick={() => {
              setOpenProductionDBAlertPopup(false);
            }}
          >
            Acknowledge
          </button>
        </div>
      </div>
    </>
  );
};
