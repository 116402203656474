import { useState } from "react";
import { RecipientsTable } from "./RecipientsTable";
import { DisbursementsTable } from "./DisbursementsTable";

export const RecipientsDisbursements = ({
  toastConfig,
  setToastConfig,
  userinfo,
  recipientsTableData,
  refreshGetRecipientsTableData,
  disbursementsTableData,
  refreshGetDisbursementTableData,
  recipientsCount,
  disbursementsCount,
  recipientsDropdownData,
  recipientsPaymentDropdownData,
  recipientsEntityDropdownData,
  gObligationID,
}) => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="payment-credits-tab">
        <div className="payment-credits-tab-nav">
          <button
            className={
              activeTab === "tab1"
                ? "payment-credits-tab-active payment-credits-tab-nav-button"
                : "payment-credits-tab-nav-button"
            }
            onClick={() => handleTabClick("tab1")}
          >
            Recipients ({recipientsCount})
          </button>
          <button
            className={
              activeTab === "tab2"
                ? "payment-credits-tab-active payment-credits-tab-nav-button"
                : "payment-credits-tab-nav-button"
            }
            onClick={() => handleTabClick("tab2")}
          >
            Disbursements ({disbursementsCount})
          </button>
        </div>
        <div className="payment-credits-tab-data">
          {activeTab === "tab1" && (
            <RecipientsTable
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              recipientsTableData={recipientsTableData}
              refreshGetRecipientsTableData={refreshGetRecipientsTableData}
              recipientsDropdownData={recipientsDropdownData}
              userinfo={userinfo}
              recipientsPaymentDropdownData={recipientsPaymentDropdownData}
              recipientsEntityDropdownData={recipientsEntityDropdownData}
              gObligationID={gObligationID}
            />
          )}
          {activeTab === "tab2" && (
            <DisbursementsTable
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              disbursementsTableData={disbursementsTableData}
              refreshGetDisbursementTableData={refreshGetDisbursementTableData}
            />
          )}
        </div>
      </div>
    </>
  );
};
