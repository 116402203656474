import { useEffect, useState } from "react";
import { ColumnDropdown } from "../../../../components/Dropdownn";
import { LoadPanel, TextBox } from "devextreme-react";
import { GoTOObligationFunction } from "./GoTOObligationFunction";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
      />
    </>
  );
};

export const GoToObligationClone = ({
  setcloneObligation,
  cloneObligation,
  RecipientCategory,
  setToastConfig,
  toastConfig,
  gotooblogationValue,
}) => {
  const clas = new GoTOObligationFunction();
  const [field, setField] = useState({
    "Recipient Category": { name: "", id: "" },
    "Obligation Ref #": "",
  });

  const [ObligationRefvalidation, setObligationRefvalidation] = useState("");
  const [RecipientCategoryvalidation, setRecipientCategoryvalidation] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const Save = () => {
    if (gotooblogationValue?.gObligationId) {
      if (field?.["Obligation Ref #"]?.trim() && field?.["Recipient Category"]?.id) {
        setObligationRefvalidation("");
        setRecipientCategoryvalidation("");
        clas.Cloneobligation(
          setLoading,
          toastConfig,
          setToastConfig,
          navigate,
          gotooblogationValue,
          {
            obligationId: gotooblogationValue?.gObligationId,
            obligationRef: field?.["Obligation Ref #"],
            recipientCategoryId: field?.["Recipient Category"]?.id,
          },
          setcloneObligation,
          dispatch
        );
      } else {
        !field?.["Obligation Ref #"]?.trim()
          ? setObligationRefvalidation("Please Enter Obligation Ref")
          : setObligationRefvalidation("");
        !field?.["Recipient Category"]?.id
          ? setRecipientCategoryvalidation("Please select Recipient Category")
          : setRecipientCategoryvalidation("");
      }
    }
  };
  useEffect(() => {
    setObligationRefvalidation("");
    setRecipientCategoryvalidation("");
    setField({
      "Recipient Category": { name: "", id: "" },
      "Obligation Ref #": "",
    });
  }, [cloneObligation]);
  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div style={{ height: "338px" }} className="create-concurrent-docket-container">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_974_363073)">
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM16 13H13V16C13 16.55 12.55 17 12 17C11.45 17 11 16.55 11 16V13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H11V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V11H16C16.55 11 17 11.45 17 12C17 12.55 16.55 13 16 13Z"
                    fill="#fff"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_974_363073">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>Clone Obligation</p>
            </div>
            <p class="popup_header_title_para">OB.14</p>
          </div>
          <svg
            onClick={() => {
              setcloneObligation(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <section className="go-to-docket-popup-content">
          <div>
            <div className="go-to-inputs-field">
              <label htmlFor="docketNumber" className="go-to-label-filed">
                Obligation Ref #<span className="go-to-mark-input">*</span>
              </label>
              <TextBox
                style={{ marginTop: "3%", width: "78%" }}
                value={field?.["Obligation Ref #"]}
                onValueChange={(e) => {
                  setObligationRefvalidation("");
                  setField({
                    ...field,
                    "Obligation Ref #": e,
                  });
                }}
                isValid={true}
                placeholder={"Obligation Ref #"}
              />
              <p style={{ color: "red" }}>{ObligationRefvalidation}</p>
            </div>
            <div className="go-to-inputs-field edit_content_space">
              <label htmlFor="" className="go-to-label-filed">
                Recipient Category<span className="go-to-mark-input">*</span>
              </label>
              <div style={{ width: "78%" }}>
                <DropDown
                  dropDownData={RecipientCategory}
                  fieldNmae={"Recipient Category"}
                  fields={field}
                  setfields={setField}
                  reset={setRecipientCategoryvalidation}
                />
                <p
                  style={{
                    color: "red",
                    position: "absolute",
                    fontWeight: "400",
                  }}
                >
                  {RecipientCategoryvalidation}
                </p>
              </div>
            </div>
          </div>
          <div className="popup_buttons">
            <button
              className="btn primary_btn"
              onClick={() => {
                Save();
              }}
            >
              Save
            </button>
            <button
              className="btn"
              onClick={() => {
                setcloneObligation(false);
              }}
            >
              Cancel
            </button>
          </div>
        </section>
      </div>
    </>
  );
};
