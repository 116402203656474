import axios from "axios";
import { useEffect, useState } from "react";
import { handleGetLocal } from "../../../services/auth-service";
import { logOut } from "../../../components/Logout";
import { useNavigate } from "react-router-dom";

const ChangeCheckNumberPopup = ({
  changeCheckNumberPopup,
  setChangeCheckNumberPopup,
  disbursementRowCheckData,
  setLoading,
  setToastConfig,
  toastConfig,
  refreshApi,
}) => {
  const navigate = useNavigate();
  const [checkNumber, setcheckNumber] = useState();
  const [checkNumberErrorValidation, setCheckNumberErrorValidation] =
    useState("");

  useEffect(() => {
    setCheckNumberErrorValidation("");
    setcheckNumber(
      disbursementRowCheckData?.[`Check Number`]
        ? disbursementRowCheckData?.[`Check Number`]
        : 0
    );
  }, [changeCheckNumberPopup]);

  const data = {
    checkNumber: checkNumber,
    gDisbursementCheckID: disbursementRowCheckData?.gDisbursementCheckId,
  };

  const handleSave = async () => {
    let isValid = true;
    if (!checkNumber) {
      isValid = false;
      setCheckNumberErrorValidation("Please Enter Valid New Check Number");
    }
    if (isValid) {
      setLoading(true);
      const pathname = handleGetLocal("pathURL");
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/disbursement/change-check-number`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              Domain: `${pathname}`,
            },
          }
        )
        .then((val) => {
          setChangeCheckNumberPopup(false);
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Updated!",
              type: "success",
            });
          }, 1900);
          refreshApi();
          setLoading(false);
        })
        .catch((val) => {
          setLoading(false);
          if (!val?.response) {
            navigate(`/error/500`);
          } else if (val?.response?.status === 401) {
            logOut();
          }
        });
    }
  };

  return (
    <>
      <div className="change-check-number">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <p>Enter Information</p>
            </div>
            <p class="popup_header_title_para">UT.3</p>
          </div>
          <svg
            onClick={() => {
              setChangeCheckNumberPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="popup-container-data">
          <div className="go-to-inputs-field">
            <label htmlFor="" className="go-to-label-filed">
              New Check Number
            </label>
            <input
              type="number"
              name="checkNumber"
              id="checkNumber"
              value={checkNumber}
              placeholder="New Check Number"
              className="fee-payment-content-info-input"
              onChange={(e) => {
                setcheckNumber(e.target.value);
                setCheckNumberErrorValidation("");
              }}
              style={{ width: "100%" }}
            />
            {checkNumberErrorValidation && (
              <p
                className="error_validation"
                style={{ position: "absolute", top: "138px" }}
              >
                {checkNumberErrorValidation}
              </p>
            )}
          </div>
          <div className="popup_buttons">
            <button
              className="btn primary_btn"
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </button>
            <button
              className="btn"
              onClick={() => {
                setChangeCheckNumberPopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeCheckNumberPopup;
