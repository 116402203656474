import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { TopTab } from "./TopTab";
import "./Overview.css";
import { CommonOverViewTab } from "./CommonOverViewTab";
import { LoadPanel } from "devextreme-react";
import { ApiError } from "../../../../Error/ApiError";

const setTableData = (dataa) => {
  const arr = [];
  dataa.map((val, i) => {
    arr.push({
      ID: i,
      sSupervisionStatusName: val?.sSupervisionStatusName,
      sOffenseDescription: val?.sOffenseDescription,
      dDoe: val?.dDoe,
      gSupervisionStatusId: val?.gSupervisionStatusId,
      bActiveStatus: val?.bActiveStatus,
      gDocketId: val?.gDocketId,
      sDocketNumberCourtCounts: val?.sDocketNumberCourtCounts,
      sReferenceNumber: val?.sReferenceNumber,
      sPrimarySupervisor: val?.sPrimarySupervisor,
      sDocketTypeName: val?.sDocketTypeName,
      fAmount: val?.fAmount,
      fBalance: val?.fBalance,
      fArrearsAdjusted: val?.fArrearsAdjusted,
      dCourtSentence: val?.dCourtSentence,
      dSupervisionStatus: val?.dSupervisionStatus,
      sJudgeName: val?.sJudgeName,
      sCourtName: val?.sCourtName,
      sSentenceDateRange: val?.sSentenceDateRange,
      sDocketNumber: val?.sDocketNumber,
      dDoe: val?.dDoe,
      sGpmlocationEntityName: val?.sGpmlocationEntityName,
      dReportDateTime: val?.dReportDateTime,
      sPersonNameLastFirst: val?.sPersonNameLastFirst,
      gDocketLinkId: val?.gDocketLinkId,
      sSentenceNotes: val?.sSentenceNotes,
    });
  });

  return arr;
};

export const Overview = ({ userinfo, data, loading, dropdownData, errorhandling }) => {
  const [Data, setdata] = useState([]);
  const [docketId, setdocketId] = useState("");

  useEffect(() => {
    if (data.length) {
      setdata(setTableData(data));
    }
  }, [data]);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      {errorhandling ? (
        <ApiError />
      ) : (
        <div>
          {Data.length ? <TopTab setdocketId={setdocketId} data={Data} /> : ""}
          {Data.length ? (
            <CommonOverViewTab data={Data} userinfo={userinfo} docketId={docketId} dropdownData={dropdownData} />
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};
