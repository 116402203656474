import { useEffect, useState } from "react";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import { Table2 } from "../../../../../components/Table2/Table2";
import { EditCheckistPopupFunction } from "./EditCheckistPopupFunction";
import { EditCHecklistEDitPopup } from "./EditCHecklistEDitPopup";
import { Popup } from "devextreme-react";
import { AddChecklistPOpup } from "./AddChecklist";
import { AddCHecklistEDittext } from "./AddCHecklistEDittext";
import { overVIewFunction } from "./overVIewFunction";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAccessData } from "../../../../../../src/utils/getAccessPermission";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        // reset={reset}
      />
    </>
  );
};
const EditCHecklistEDitPopup2 = ({ popup, setpopup, editdata, setToastConfig, toastConfig, dropdownId, table }) => {
  return (
    <>
      <Popup
        visible={editdata?.ID !== undefined && popup}
        onHiding={() => {
          setpopup(false);
        }}
        showTitle={false}
        width={500}
        height={350}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <EditCHecklistEDitPopup
          editdata={editdata}
          setpopup={setpopup}
          popup={popup}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          dropdownId={dropdownId}
          table={table}
        />
      </Popup>
    </>
  );
};

const AddCHecklistEDitPopup = ({ popup, setpopup, setToastConfig, toastConfig, getDRopDown, AdCHeckLIst }) => {
  return (
    <>
      <Popup
        visible={popup}
        onHiding={() => {
          setpopup(false);
        }}
        showTitle={false}
        width={500}
        height={250}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddChecklistPOpup
          setpopup={setpopup}
          popup={popup}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          getDRopDown={getDRopDown}
          AdCHeckLIst={AdCHeckLIst}
        />
      </Popup>
    </>
  );
};

const AddCHecklistEDittextPopup = ({
  popup,
  setpopup,
  setToastConfig,
  toastConfig,
  table,
  dropdownId,
  AdCHeckLIst,
}) => {
  return (
    <>
      <Popup
        visible={popup}
        onHiding={() => {
          setpopup(false);
        }}
        showTitle={false}
        width={500}
        height={350}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddCHecklistEDittext
          setpopup={setpopup}
          popup={popup}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          dropdownId={dropdownId}
          table={table}
          AdCHeckLIst={AdCHeckLIst}
        />
      </Popup>
    </>
  );
};

export const EditCheckListPOPUP = ({
  seteditCHeckoutPOpup,
  dataEditCheclist,
  setToastConfig,
  toastConfig,
  getTableChecklist2,
  fieldsdropdown,
  editCHeckoutPOpup,
  AdCHeckLIst,
}) => {
  const clas = new EditCheckistPopupFunction();
  const clas2 = new overVIewFunction();
  const [viewAccess, setViewAccess] = useState(true);
  const [editAccess, setEditAccess] = useState(false);
  const [addAccess, setAddAccess] = useState(false);
  const {userAccessData } = useSelector((store) => store.Home);
  const [editdata, seteditdata] = useState();
  const [popup, setpopup] = useState(false);
  const [addcheclistpopup, setaddcheclistpopup] = useState(false);
  const [addcheclisttextpopup, setaddcheclisttextpopup] = useState(false);
  const [fields, setfields] = useState({
    dropdown: { name: "", id: "" },
  });

  const table = () => {
    getTableChecklist2(fields?.dropdown?.id);
  };

  const navigate = useNavigate();
  const [dropdown, setdropdown] = useState([]);

  const getDRopDown = () => {
    clas2.getCheckLIstDropDown2(navigate, setdropdown);
  };

  useEffect(() => {
    fields?.dropdown?.id && table();
  }, [fields?.dropdown?.id]);

  useEffect(() => {
    setfields({
      dropdown: { name: fieldsdropdown?.name, id: fieldsdropdown?.id },
    });
    getDRopDown();
  }, [editCHeckoutPOpup]);
  
  const getUserAccessPermissions = () => {
    const accessData = getAccessData(userAccessData, "CL1");
    setAddAccess(accessData?.Add);
    setEditAccess(accessData?.Edit);
    setViewAccess(accessData?.View);
  };

  useEffect(() => {
    getUserAccessPermissions();
  }, []);

  return (
    <>
      <EditCHecklistEDitPopup2
        editdata={editdata}
        popup={popup}
        setpopup={setpopup}
        setToastConfig={setToastConfig}
        toastConfig={toastConfig}
        dropdownId={fields?.dropdown}
        table={table}
      />
      <AddCHecklistEDitPopup
        popup={addcheclistpopup}
        setpopup={setaddcheclistpopup}
        setToastConfig={setToastConfig}
        toastConfig={toastConfig}
        getDRopDown={getDRopDown}
        AdCHeckLIst={AdCHeckLIst}
      />

      <AddCHecklistEDittextPopup
        popup={addcheclisttextpopup}
        setpopup={setaddcheclisttextpopup}
        setToastConfig={setToastConfig}
        toastConfig={toastConfig}
        table={table}
        dropdownId={fields?.dropdown}
        AdCHeckLIst={AdCHeckLIst}
      />
      <div
        style={{
          width: "100%",
          height: "598px",
          border: "3px solid rgb(67, 66, 102)",
        }}
        className="CreateAppointmentPopup"
      >
        <div className="RecivePaymentModel_header">
          <div style={{ width: "170px" }} className="RecivePaymentModel_header_name">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clipPath="url(#clip0_5796_5656)">
                <path
                  d="M3 17.4605V20.5005C3 20.7805 3.22 21.0005 3.5 21.0005H6.54C6.67 21.0005 6.8 20.9505 6.89 20.8505L17.81 9.94055L14.06 6.19055L3.15 17.1005C3.05 17.2005 3 17.3205 3 17.4605ZM20.71 7.04055C21.1 6.65055 21.1 6.02055 20.71 5.63055L18.37 3.29055C17.98 2.90055 17.35 2.90055 16.96 3.29055L15.13 5.12055L18.88 8.87055L20.71 7.04055Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_5796_5656">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p style={{ marginLeft: "-10px" }}>Edit Checklist</p>
            <span
              style={{
                padding: "2px 9px",
                gap: "8px",
                width: "45px",
                height: "24px",
                background: "#FEF2F2",
                borderRadius: "4px",
              }}
            >
              <p
                style={{
                  color: "#DC2626",
                  fontFamily: "Readex Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "10px",
                  lineHeight: "20px",
                  width: "21px",
                  height: "20px",
                }}
              >
                CL.1
              </p>
            </span>
          </div>
          <svg
            onClick={() => {
              seteditCHeckoutPOpup(false);
            }}
            style={{
              cursor: "pointer",
              marginTop: "17px",
              marginRight: "2%",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        {viewAccess ? (
          <div
            style={{
              pointerEvents: editAccess || addAccess ? "auto" : "none",
              opacity: editAccess || addAccess ? 1 : 0.6,
              cursor: editAccess || addAccess ? "default" : "not-allowed",
            }}
          >
            <div
              style={{
                width: "96%",
                marginLeft: "2%",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div style={{ width: "35%", marginTop: "3px" }}>
                  <DropDown setfields={setfields} fields={fields} fieldNmae={"dropdown"} dropDownData={dropdown} />
                </div>
                <div className="ChecklistTabbuttons">
                  <button
                    onClick={() => {
                      fields?.dropdown?.id && setaddcheclisttextpopup(true);
                    }}
                    className="btn"
                  >
                    Add
                  </button>
                  <button
                    onClick={() => {
                      setaddcheclistpopup(true);
                    }}
                    className="btn"
                  >
                    New Checklist
                  </button>
                  <button
                    onClick={() => {
                      seteditCHeckoutPOpup(false);
                    }}
                    className="btn"
                  >
                    Close
                  </button>
                </div>
              </div>
              <div style={{ marginTop: "2%" }}>
                <Table2
                  Tablecoloum={clas.Tablecoloum}
                  tableRow={dataEditCheclist}
                  editTable={false}
                  load={false}
                  coloumWidthText={"EditCheclistTable"}
                  height={420}
                  mandatoryColoum={"Allow Check"}
                  showTotalBlock={false}
                  editCheclListOptionrightClist={true}
                  editchecklistoptionsetpopup={setpopup}
                  seteditdatachecklist={seteditdata}
                />
              </div>
            </div>
          </div>
        ) : (
          "No view access"
        )}
      </div>
    </>
  );
};
