import { useState } from "react";
import "../../feeSchedule.css";
import { ColumnDropdown } from "../../../../../components/Dropdownn";

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  fieldNmae,
  reset,
  disabled,
}) => {
  return (
    <>
      <ColumnDropdown
      // data={dropDownData}
      // setfields={setfields}
      // fields={fields}
      // fieldNmae={fieldNmae}
      // value={fields[fieldNmae]?.name}
      // reset={reset}
      // disabled={disabled}
      />
    </>
  );
};

export const DDS = () => {
  const [fields, setFields] = useState({
    checkInputs: [],
    commericalDQ: { name: "", id: "" },
  });

  const handleCheckInputChange = (e) => {
    const { id, checked } = e.target;
    if (checked) {
      setFields((prevFields) => ({
        ...prevFields,
        checkInputs: [...prevFields.checkInputs, id],
      }));
    } else {
      setFields((prevFields) => ({
        ...prevFields,
        checkInputs: prevFields.checkInputs.filter((inputId) => inputId !== id),
      }));
    }
  };
  return (
    <>
      <div className="dds-tab">
        <p className="large-para">DDS via GECPS</p>
        <div className="dds-content">
          <div className="dds-fields">
            <input
              type="checkbox"
              name=""
              id="Not FTA Eligible"
              onChange={handleCheckInputChange}
            />
            <label
              htmlFor="Not FTA Eligible"
              className="fee-payment-content-info-para"
            >
              Not FTA Eligible
            </label>
          </div>
          <div className="dds-fields">
            <input
              type="checkbox"
              name=""
              id="Suspend Licence"
              onChange={handleCheckInputChange}
            />
            <label
              htmlFor="Suspend Licence"
              className="fee-payment-content-info-para"
            >
              Suspend Licence
            </label>
          </div>
          <div className="dds-fields">
            <input
              type="checkbox"
              name=""
              id="Suspend Licence if under 21"
              onChange={handleCheckInputChange}
            />
            <label
              htmlFor="Suspend Licence if under 21"
              className="fee-payment-content-info-para"
            >
              Suspend Licence if under 21
            </label>
          </div>
          <div className="dds-fields">
            <input
              type="checkbox"
              name=""
              id="Submit only if validation in CMV"
              onChange={handleCheckInputChange}
            />
            <label
              htmlFor="Submit only if validation in CMV"
              className="fee-payment-content-info-para"
            >
              Submit only if validation in CMV
            </label>
          </div>
          <div className="go-to-inputs-field edit-content-space">
            <label htmlFor="" className="go-to-label-filed">
              Commerical DQ
            </label>
            <DropDown />
          </div>
        </div>
      </div>
    </>
  );
};
