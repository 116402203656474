import { LoadPanel, ScrollView, Toast } from "devextreme-react";
import { ColumnDropdown } from "../../../../components/Dropdownn";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { handleGetLocal } from "../../../../services/auth-service";
import { seton_Save_Edit } from "../../../../reducer/participantNoteReducer";
import { useDispatch } from "react-redux";
import { logOut } from "../../../../components/Logout";
import { useNavigate } from "react-router-dom";

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  setNoteTypeValidation,
  fieldNmae,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        // reset={setNoteTypeValidation}
      />
    </>
  );
};

export const EditAttachment = ({
  seteditAttachmentPopupOpen,
  AttachmenteditData,
  attachmentCategories,
  userinfo,
  toastConfig,
  setToastConfig,
  attachmentfunction,
  getphotoTableData,
}) => {
  const { resetValue, on_Save_Edit } = useSelector(
    (store) => store.participateNotes
  );
  const [fields, setfields] = useState({
    Category: {
      name: AttachmenteditData.Category,
      id: AttachmenteditData.attachmentCategoryId,
    },
    Description: AttachmenteditData?.Description
      ? AttachmenteditData?.Description
      : "",
  });
  const [catigoryValidation, setcatigoryValidation] = useState("");
  const [loading, setLoading] = useState(false);
  const pathname = handleGetLocal("pathURL");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const OnSave = async (body) => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/attachments/save-attachments`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val.data.isSuccess) {
          attachmentfunction();
          getphotoTableData();
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Updated!",
              type: "success",
            });
          }, 1400);
        }

        reset();
        seteditAttachmentPopupOpen(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const save = () => {
    if (fields.Category.id) {
      setcatigoryValidation("");
      OnSave({
        binaryDataId: AttachmenteditData?.binaryDataId,
        recordId: userinfo?.gPersonId,
        doe: AttachmenteditData?.DOE,
        filename: AttachmenteditData?.Filename,
        description: fields.Description,
        attachmentCategoryId: fields.Category.id,
        attachedFiles: [],
        attachment: AttachmenteditData?.attachment,
        enteredByUserName: AttachmenteditData?.["Entered By"],
        attachmentCateogoryName: fields.Category.name,
        image: AttachmenteditData?.image,
      });
    } else {
      !fields.Category.id
        ? setcatigoryValidation("Please select the Category")
        : setcatigoryValidation("");
    }
  };

  const reset = () => {
    setcatigoryValidation("");
    setfields({
      Category: {
        name: AttachmenteditData.Category,
        id: AttachmenteditData.attachmentCategoryId,
      },
      Description: AttachmenteditData?.Description
        ? AttachmenteditData?.Description
        : "",
    });
  };

  useEffect(() => {
    reset();
  }, [resetValue, AttachmenteditData]);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />

      <div
        style={{
          width: "100%",
          height: "418px",
          border: "3px solid var(--button2)",
        }}
        className="CreateAppointmentPopup"
      >
        <div className="RecivePaymentModel_header">
          <div
            style={{ width: "150px" }}
            className="RecivePaymentModel_header_name"
          >
            <svg
              width="8"
              height="16"
              viewBox="0 0 8 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.9974 4.49977V11.5531C6.9974 12.9464 5.9774 14.1864 4.59073 14.3198C2.9974 14.4731 1.66406 13.2264 1.66406 11.6664V3.42644C1.66406 2.55311 2.29073 1.75977 3.1574 1.67311C4.1574 1.57311 4.9974 2.35311 4.9974 3.33311V10.3331C4.9974 10.6998 4.6974 10.9998 4.33073 10.9998C3.96406 10.9998 3.66406 10.6998 3.66406 10.3331V4.49977C3.66406 4.22644 3.4374 3.99977 3.16406 3.99977C2.89073 3.99977 2.66406 4.22644 2.66406 4.49977V10.2398C2.66406 11.1131 3.29073 11.9064 4.1574 11.9931C5.1574 12.0931 5.9974 11.3131 5.9974 10.3331V3.44644C5.9974 2.05311 4.9774 0.813105 3.59073 0.679772C2.00406 0.526438 0.664062 1.77311 0.664062 3.33311V11.5131C0.664062 13.4264 2.06406 15.1398 3.97073 15.3198C6.16406 15.5198 7.9974 13.8131 7.9974 11.6664V4.49977C7.9974 4.22644 7.77073 3.99977 7.4974 3.99977C7.22406 3.99977 6.9974 4.22644 6.9974 4.49977Z"
                fill="white"
              />
            </svg>
            <p style={{ marginLeft: "-10px" }}>Attach File</p>
            <span
              style={{
                padding: "2px 9px",
                gap: "8px",
                width: "45px",
                height: "24px",
                background: "#FEF2F2",
                borderRadius: "4px",
              }}
            >
              <p
                style={{
                  color: "#DC2626",
                  fontFamily: "Readex Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "10px",
                  lineHeight: "20px",
                  width: "21px",
                  height: "20px",
                }}
              >
                AF.1
              </p>
            </span>
          </div>
          <svg
            onClick={() => {
              seteditAttachmentPopupOpen(false);
              reset();
            }}
            style={{
              cursor: "pointer",
              marginTop: "17px",
              marginRight: "2%",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>

        <div className="AttachFiles_Popup">
          <div className="AttachFiles_Popup_dropdown">
            <p>
              Category <span style={{ fontSize: "14px" }}>*</span>
            </p>
            <div style={{ marginTop: "-15px", position: "relative" }}>
              <DropDown
                dropDownData={attachmentCategories}
                setfields={setfields}
                fields={fields}
                fieldNmae={"Category"}
              />
              <p style={{ position: "absolute", color: "red" }}>
                {catigoryValidation}
              </p>
            </div>
          </div>
          <div className="AttachFiles_Popup_discription">
            <p>Description</p>
            <textarea
              value={fields.Description}
              onChange={(e) => {
                setfields({
                  ...fields,
                  Description: e.target.value,
                });
              }}
              style={{ width: "100%", height: "160px" }}
            />
          </div>
          <div className="popup_buttons" style={{ marginTop: "10px" }}>
            <button
              className="btn primary_btn"
              onClick={() => {
                save();
              }}
            >
              Save
            </button>
            <button
              onClick={() => {
                seteditAttachmentPopupOpen(false);
                reset();
              }}
              className="btn"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
