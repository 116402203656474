import React, { useState } from "react";
import "./community-service.css";
import { Plannedservices } from "./Plannedservices";
import { CompletedService } from "./CompletedService";
import { ReactComponent as AddIcon } from "../../../../../assets/images/icons/add.svg";
import { Popup } from "devextreme-react";
import { AddCommunityServicePopup } from "./AddCommunityServicePopup";
import { AddCommunityServiceLogPopup } from "./AddCommunityServiceLogPopup";
import { VoidPopup } from "../VoidPopup";

export const CommunityService = ({
  userinfo,
  toastConfig,
  setToastConfig,
  dropdownData,
  docketDropdownData,
  communityCompletedServiceData,
  communityPlannedServicesData,
  getCommunityCompletedServiceListApi,
  getCommunityPlannedServiceListApi,
  communityServiceJailtimelogDropdown,
  getCommunityServiceJailtimelogDropdown,
  communityCompletedErorrHandling,
  communityPlannedErorrHandling,
  gotoCommunity,
  dockertDetail,
}) => {
  const [load, setLoad] = useState(false);
  const [communityTab, setCommunityTab] = useState(1);
  const [addCommunityServicePopup, setAddCommunityServicePopup] =
    useState(false);
  const [addCommunityServiceLogPopup, setAddCommunityServiceLogPopup] =
    useState(false);
  const [voidPopup, setVoidPopup] = useState(false);
  const [plannedRowData, setPlannedRowData] = useState([]);
  const [completedRowData, setCompletedRowData] = useState([]);
  const [addCommunityServiceLogType, setAddCommunityServiceLogType] =
    useState("");
  const [gotoAddCommunity, setGotoAddCommunity] = useState("");

  return (
    <>
      <Popup
        visible={addCommunityServicePopup}
        onHiding={() => {
          setAddCommunityServicePopup(false);
        }}
        showTitle={false}
        width={768}
        height={520}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddCommunityServicePopup
          addCommunityServicePopup={addCommunityServicePopup}
          setAddCommunityServicePopup={setAddCommunityServicePopup}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          dropdownData={dropdownData}
          docketDropdownData={docketDropdownData}
          getCommunityPlannedServiceListApi={getCommunityPlannedServiceListApi}
          setLoad={setLoad}
          userinfo={userinfo}
          plannedRowData={plannedRowData}
          getCommunityServiceJailtimelogDropdown={
            getCommunityServiceJailtimelogDropdown
          }
          gotoAddCommunity={gotoAddCommunity}
          dockertDetail={dockertDetail}
          gotoCommunity={gotoCommunity}
        />
      </Popup>
      <Popup
        visible={addCommunityServiceLogPopup}
        onHiding={() => {
          setAddCommunityServiceLogPopup(false);
        }}
        showTitle={false}
        width={768}
        height={620}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddCommunityServiceLogPopup
          addCommunityServiceLogPopup={addCommunityServiceLogPopup}
          setAddCommunityServiceLogPopup={setAddCommunityServiceLogPopup}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          dropdownData={dropdownData}
          docketDropdownData={docketDropdownData}
          getCommunityCompletedServiceListApi={
            getCommunityCompletedServiceListApi
          }
          setLoad={setLoad}
          userinfo={userinfo}
          completedRowData={completedRowData}
          addCommunityServiceLogType={addCommunityServiceLogType}
          setCommunityTab={setCommunityTab}
          communityServiceJailtimelogDropdown={
            communityServiceJailtimelogDropdown
          }
          dockertDetail={dockertDetail}
          gotoCommunity={gotoCommunity}
          communityPlannedServicesData={communityPlannedServicesData}
        />
      </Popup>
      <Popup
        visible={voidPopup}
        onHiding={() => {
          setVoidPopup(false);
        }}
        showTitle={false}
        width={800}
        height={450}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        {communityTab === 1 ? (
          <VoidPopup
            setVoidPopup={setVoidPopup}
            voidHeading="Void Community Service"
            voidSubHeading="Void Community Service"
            setLoad={setLoad}
            toastConfig={toastConfig}
            setToastConfig={setToastConfig}
            refreshApi={getCommunityPlannedServiceListApi}
            filedId={plannedRowData?.gCommunityServiceId}
            fieldName="GCommunityServiceId"
            apiURl="CommunityService/voidCommunityService"
            comment="Comments"
          />
        ) : (
          <VoidPopup
            setVoidPopup={setVoidPopup}
            voidHeading="Void Community Service Log"
            voidSubHeading="Void Community Service Log"
            setLoad={setLoad}
            toastConfig={toastConfig}
            setToastConfig={setToastConfig}
            refreshApi={getCommunityCompletedServiceListApi}
            filedId={completedRowData?.gCommunityServiceLogId}
            fieldName="GCommunityServiceLogId"
            apiURl="CommunityService/voidCommunityServiceLog"
            comment="Comments"
          />
        )}
      </Popup>
      <div className="community_service">
        <div className="buttons">
          {gotoCommunity && (
            <button
              className="btn"
              onClick={() => {
                setAddCommunityServicePopup(true);
                setGotoAddCommunity("ADD");
              }}
            >
              Add Community Service <AddIcon className="svg-blue-white" />
            </button>
          )}
          <button
            className="btn"
            onClick={() => {
              setAddCommunityServiceLogPopup(true);
              setAddCommunityServiceLogType("ADD");
            }}
          >
            Add Community Service Log <AddIcon className="svg-blue-white" />
          </button>
        </div>
        <div className="community_service_tabs">
          <p
            className={
              communityTab === 1
                ? "community_service_tab community_service_tab_active"
                : "community_service_tab"
            }
            onClick={() => {
              setCommunityTab(1);
            }}
          >
            Planned
          </p>
          <p
            className={
              communityTab === 2
                ? "community_service_tab community_service_tab_active"
                : "community_service_tab"
            }
            onClick={() => {
              setCommunityTab(2);
            }}
          >
            Completed
          </p>
        </div>
        <div className="community_service_content">
          {communityTab === 1 && (
            <Plannedservices
              setAddCommunityServicePopup={setAddCommunityServicePopup}
              setVoidPopup={setVoidPopup}
              communityPlannedServicesData={communityPlannedServicesData}
              load={load}
              setPlannedRowData={setPlannedRowData}
              communityPlannedErorrHandling={communityPlannedErorrHandling}
              setGotoAddCommunity={setGotoAddCommunity}
            />
          )}
          {communityTab === 2 && (
            <CompletedService
              setAddCommunityServiceLogPopup={setAddCommunityServiceLogPopup}
              setVoidPopup={setVoidPopup}
              communityCompletedServiceData={communityCompletedServiceData}
              load={load}
              setCompletedRowData={setCompletedRowData}
              setAddCommunityServiceLogType={setAddCommunityServiceLogType}
              communityCompletedErorrHandling={communityCompletedErorrHandling}
            />
          )}
        </div>
      </div>
    </>
  );
};
