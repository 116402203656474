import { DateBox } from "devextreme-react";
import { Table2 } from "../../../../../components/Table2/Table2";
const Tablecoloum = [
  {
    title: "GPM Entity Location",
    Action: true,
  },
  {
    title: "Active Date",
    Action: true,
  },
  {
    title: "Person",
    Action: true,
  },
  {
    title: "Fee Code",
    Action: true,
  },
  {
    title: "Amount",
    Action: true,
  },
];

export const InUseBy = () => {
  return (
    <>
      <div className="tab-main-box">
        <div className="tab-box" id="tab-2" style={{ display: "block" }}>
          <div className="tabcontent">
            <div style={{ display: "flex" }}>
              <button
                onClick={() => {}}
                id="myBtn"
                className="btn btnlightblue"
              >
                Refresh
                <svg
                  className="svg-blue-white"
                  width="16"
                  height="21"
                  viewBox="0 0 16 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.99905 4.00003V1.21003C7.99905 0.760031 7.45905 0.540031 7.14905 0.860031L3.34905 4.65003C3.14905 4.85003 3.14905 5.16003 3.34905 5.36003L7.13905 9.15003C7.45905 9.46003 7.99905 9.24003 7.99905 8.79003V6.00003C11.7291 6.00003 14.6791 9.42003 13.8591 13.29C13.3891 15.56 11.5491 17.39 9.28905 17.86C5.71905 18.61 2.53905 16.16 2.05905 12.85C1.98905 12.37 1.56905 12 1.07905 12C0.479051 12 -0.000948563 12.53 0.0790514 13.13C0.699051 17.52 4.87905 20.77 9.60905 19.85C12.7291 19.24 15.2391 16.73 15.8491 13.61C16.8391 8.48003 12.9391 4.00003 7.99905 4.00003Z"
                    fill="#424167"
                  />
                </svg>
              </button>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  marginLeft: "2%",
                }}
              >
                <p className="go-to-label-filed">Create Date</p>
                <DateBox
                  style={{ height: "35px", width: "180px" }}
                  useMaskBehavior={true}
                  // value={date.startDate}
                  onValueChange={(e) => {
                    if (e) {
                      // setdate({
                      //   ...date,
                      //   startDate: new Date(
                      //     new Date(e).toLocaleDateString()
                      //   ).toLocaleDateString("en-CA"),
                      // });
                    }
                  }}
                />
                <p className="go-to-label-filed">To</p>
                <DateBox
                  useMaskBehavior={true}
                  style={{ height: "35px", width: "180px" }}
                  // value={date.endDate}
                  onValueChange={(e) => {
                    if (e) {
                      // setdate({
                      //   ...date,
                      //   endDate: new Date(
                      //     new Date(e).toLocaleDateString()
                      //   ).toLocaleDateString("en-CA"),
                      // });
                    }
                  }}
                />
                <p className="go-to-label-filed">Quick Date</p>
                <DateBox
                  useMaskBehavior={true}
                  style={{ height: "35px", width: "180px" }}
                  // value={date.endDate}
                  onValueChange={(e) => {
                    if (e) {
                      // setdate({
                      //   ...date,
                      //   endDate: new Date(
                      //     new Date(e).toLocaleDateString()
                      //   ).toLocaleDateString("en-CA"),
                      // });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="Detail_Contact_Tab">
          <div style={{ marginTop: "1.5%" }}>
            <Table2
              Tablecoloum={Tablecoloum}
              tableRow={[]}
              load={false}
              coloumWidthText={"DocketListTableWidth"}
              height={620}
              editTable={false}
              mandatoryColoum={"InUseBytable"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
