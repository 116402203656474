import { CommunityService } from "../../../Dockect/CommunityService/CommunityService";

export const GoToCommunityService = ({
  userinfo,
  toastConfig,
  setToastConfig,
  dropdownData,
  docketDropdownData,
  communityCompletedServiceData,
  communityPlannedServicesData,
  getCommunityCompletedServiceListApi,
  getCommunityPlannedServiceListApi,
  communityServiceJailtimelogDropdown,
  getCommunityServiceJailtimelogDropdown,
  communityCompletedErorrHandling,
  communityPlannedErorrHandling,
  dockertDetail,
}) => {
  return (
    <>
      <div className="goto-community-service-tab">
        <CommunityService
          userinfo={userinfo}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          dropdownData={dropdownData}
          docketDropdownData={docketDropdownData}
          communityPlannedServicesData={communityPlannedServicesData}
          communityCompletedServiceData={communityCompletedServiceData}
          getCommunityCompletedServiceListApi={
            getCommunityCompletedServiceListApi
          }
          getCommunityPlannedServiceListApi={getCommunityPlannedServiceListApi}
          communityServiceJailtimelogDropdown={
            communityServiceJailtimelogDropdown
          }
          getCommunityServiceJailtimelogDropdown={
            getCommunityServiceJailtimelogDropdown
          }
          communityCompletedErorrHandling={communityCompletedErorrHandling}
          communityPlannedErorrHandling={communityPlannedErorrHandling}
          gotoCommunity={true}
          dockertDetail={dockertDetail}
        />
      </div>
    </>
  );
};
