export const ProgramTablecoloumn = [
  {title:"", moreoption:true, customwidth:"50px"},
  { title: "Docket #" },
  { title: "Court" },
  { title: "Program Code" },
  { title: "Referral Date", date: true },
  { title: "Contact Person" },
  { title: "Initial Appointment", date: true },
  { title: "Program Start", date: true },
  { title: "Complete By", date: true },
  { title: "Program End", date: true },
  { title: "End Reason" },
  { title: "Evaluation Date", date: true },
  { title: "Evaluation Received", date: true },
  { title: "Comments" },
  { title: "DOE", date: true },
  { title: "Void", bool: true },
];

export const plannedservicesTablecoloumn = [
{title:"", moreoption:true, customwidth:"50px"},
  { title: "Docket" },
  { title: "Reason for CS" },
  { title: "Hours Ordered", decimalFormat: true },
  { title: "Hours Completed", decimalFormat: true },
  { title: "Hours Remaining", decimalFormat: true },
  { title: "Work Site" },
  { title: "Start Date", date: true },
  { title: "Completed By Date", date: true },
  { title: "Completion Date" },
  { title: "Void", bool: true },
];

export const completedServiceTablecoloumn = [
  {title:"", moreoption:true, customwidth:"50px"},
  { title: "Write off ?", writeOFF: true, bool: true },
  { title: "Hours Completed", decimalFormat: true },
  { title: "Date Completed", date: true },
  { title: "Work Site" },
  { title: "Reason" },
  { title: "DOE", date: true },
  { title: "User" },
  { title: "Void", bool: true },
  { title: "Write Off Reason" },
  { title: "Note" },
];
export const jailPlannedservicesTablecoloumn = [
  {title:"", moreoption:true, customwidth:"50px"},
  { title: "Docket" },
  { title: "Reason for JT" },
  { title: "Days Ordered", decimalFormat: true },
  { title: "Days Completed", decimalFormat: true },
  { title: "Days Remaining", decimalFormat: true },
  { title: "Jail Site" },
  { title: "Start Date" },
  { title: "Completed By Date", date: true },
  { title: "Completion Date", date: true },
  { title: "Void", bool: true },
];

export const jailCompleteservicesTablecoloumn = [
  {title:"", moreoption:true, customwidth:"50px"},
  { title: "Write Off ?", writeOFF: true, bool: true },
  { title: "Days Completed", decimalFormat: true },
  { title: "Date Completed", date: true },
  { title: "Jail Site" },
  { title: "Reason" },
  { title: "DOE" },
  { title: "User" },
  { title: "Void", bool: true },
  { title: "Write Off Reason" },
];

export const tollingTablecoloumn = [
  {title:"", moreoption:true, customwidth:"50px"},
  { title: "Docket" },
  { title: "Type" },
  { title: "Start", date: true },
  { title: "End", date: true },
  { title: "Active", bool: true },
  { title: "Days", decimalFormat: true },
  { title: "Void", bool: true },
];
export const warrentsTablecoloumn = [
  { title: "", moreoption: true, customwidth: "50px" },
  { title: "Docket" },
  { title: "Issue", date: true },
  { title: "Served", date: true },
  { title: "Court" },
  { title: "Judge" },
  { title: "Status" },
  { title: "Status Date", date: true },
  { title: "Remarks" },
  { title: "Void", bool: true },
];

export const drugTestTablecoloumn = [
  {title:"", moreoption:true, customwidth:"50px"},
  { title: "Docket #" },
  { title: "Drug Test Type" },
  { title: "Served", date: true },
  { title: "Test Result" },
  { title: "Result Date", date: true },
  { title: "Test Reason" },
  { title: "Test By" },
  { title: "Void", bool: true },
  { title: "Requisition" },
  { title: "Location" },
  { title: "Note" },
];

export const randomTestingTablecoloumn = [
  { title: "Log Date", date: true },
  { title: "Event Type" },
  { title: "Message" },
  { title: "Confirmation Code" },
  { title: "IP Address" },
];

const convertDateFormat = (dateString) => {
  if (dateString) {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleDateString("en-US");
  } else {
    return "";
  }
};

export const setProgramTableRowData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Docket #": val?.sDocketNumberCourtCounts,
      Court: val?.sCourtName,
      "Program Code": val?.sProgramCodeName,
      "Referral Date": convertDateFormat(val?.dReferral),
      "Contact Person": val?.sContact,
      "Initial Appointment": convertDateFormat(val?.dAppointment),
      "Program Start": convertDateFormat(val?.dStart),
      "Complete By": convertDateFormat(val?.dCompleteBy),
      "Program End": convertDateFormat(val?.dEnd),
      "End Reason": val?.sProgramEndReasonName,
      "Evaluation Date": convertDateFormat(val?.dEvaluation),
      "Evaluation Received": convertDateFormat(val?.dEvaluationReceived),
      Comments: val?.sComment,
      DOE: convertDateFormat(val?.dDoe),
      Void: val?.bVoid,
      gDerivedPersonId: val?.gDerivedPersonId,
      gProgramId: val?.gProgramId,
      gDocketId: val?.gDocketId,
      gEntryUserId: val?.gEntryUserId,
      ID: i,
    });
  });
  return arr;
};

export const setJailPlannedDatas = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      Docket: val?.sDocketNumberCourtCounts,
      "Reason for JT": val?.jailTimeReason,
      "Days Ordered": val?.daysOrdered,
      "Days Completed": val?.daysCompleted,
      "Days Remaining": val?.daysRemaining,
      "Jail Site": val?.sJailSite,
      "Start Date": convertDateFormat(val?.start),
      "Completed By Date": convertDateFormat(val?.completeBy),
      "Completion Date": convertDateFormat(val?.dComplete),
      Void: val?.bVoid,
      ID: i,
      jailTimeReasonId: val?.jailTimeReasonId,
      entityId: val?.entityId,
      docketId: val?.docketId,
      jailTimeId: val?.jailTimeId,
      workSiteId: val?.workSiteId,
    });
  });
  return arr;
};

export const setJailCompletedDatas = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Days Completed": val?.daysCompleted,
      "Date Completed": convertDateFormat(val?.dComplete),
      "Jail Site": val?.sJailSite,
      Reason: val?.sJailTimeReasonName,
      DOE: convertDateFormat(val?.dDoe),
      User: val?.sEntryUserName,
      Void: val?.bVoid,
      "Write Off Reason": val?.sWriteOffReason,
      ID: i,
      docketId: val?.docketId,
      gJailTimeReasonId: val?.gJailTimeReasonId,
      gEntityId: val?.gEntityId,
      jailTimeLogId: val?.jailTimeLogId,
      workSiteId: val?.workSiteId,
    });
  });
  return arr;
};

export const setPlannedservicesTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      Docket: val?.sDocketNumberCourtCounts,
      "Reason for CS": val?.sCommunityServiceReason,
      "Hours Ordered": val?.fHoursOrdered,
      "Hours Completed": val?.fHoursCompleted,
      "Hours Remaining": val?.fHoursRemaining,
      "Work Site": val?.sWorkSite,
      "Start Date": convertDateFormat(val?.dStart),
      "Completed By Date": convertDateFormat(val?.dCompleteBy),
      "Completion Date": convertDateFormat(val?.dComplete),
      Void: val?.bVoid,
      gDocketId: val?.gDocketId,
      gCommunityServiceReasonId: val?.gCommunityServiceReasonId,
      gPersonId: val?.gPersonId,
      gCommunityServiceId: val?.gCommunityServiceId,
      ID: i,
    });
  });
  return arr;
};

export const setcompletedServiceTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Write off ?": val?.a,
      "Hours Completed": val?.fHours,
      "Date Completed": convertDateFormat(val?.dComplete),
      "Work Site": val?.sWorkSite,
      Reason: val?.sCommunityServiceReasonName,
      DOE: convertDateFormat(val?.dDoe),
      User: val?.sEntryUserName,
      Void: val?.bVoid,
      "Write Off Reason": val?.sWriteOffReason,
      Note: val?.sNote,
      gCommunityServiceLogId: val?.gCommunityServiceLogId,
      gCommunityServiceReasonId: val?.gCommunityServiceReasonId,
      gPersonId: val?.gPersonId,
      gEntryUserId: val?.gEntryUserId,
      ID: i,
      gDocketId: val?.gDocketId,
    });
  });
  return arr;
};

export const setWarrantTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      Docket: val?.sDocketNumberCourtCounts,
      Issue: convertDateFormat(val?.dIssue),
      Served: convertDateFormat(val?.dServed),
      Court: val?.sCourtName,
      Judge: val?.sJudgeName,
      Status: val?.sWarrantStatusName,
      "Status Date": convertDateFormat(val?.dStatus),
      Remarks: val?.sComment,
      Void: val?.bVoid,
      ID: i,
      gDocketId: val?.gDocketId,
      gEntryUserId: val?.gEntryUserId,
      gWarrantId: val?.gWarrantId,
      gCourtEntityId: val?.gCourtEntityId,
      gJudgeContactId: val?.gJudgeContactId,
      gWarrantStatusId: val?.gWarrantStatusId,
    });
  });
  return arr;
};

export const setTollingTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      Docket: val?.sDocketNumberCourtCounts,
      Type: val?.sTollingTypeName,
      Start: convertDateFormat(val?.dStart),
      End: convertDateFormat(val?.dEnd),
      Active: val?.bActive,
      Days: val?.iDays,
      Void: val?.bVoid,
      ID: i,
      gDocketId: val?.gDocketId,
      gEntryUserId: val?.gEntryUserId,
      gTollingTypeId: val?.gTollingTypeId,
      gTollingId: val?.gTollingId,
    });
  });
  return arr;
};

export const setrevocationsTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Appearance Type": val?.sCourtAppearanceName,
      Docket: val?.sDocketNumberCourtCounts,
      "Hearing Date": val?.dHearing,
      Court: val?.sCourtName,
      Judge: val?.sJudgeName,
      Disposition: val?.sDispositionName,
      Violation: val?.sViolation,
      Void: val?.bVoid,
      gCourtAppearanceTypeId: val?.gCourtAppearanceTypeId,
      gDocketId: val?.gDocketId,
      gCourtEntityId: val?.gCourtEntityId,
      gJudgeContactId: val?.gJudgeContactId,
      gDispositionId: val?.gDispositionId,
      gRevocationId: val?.gRevocationId,
      ID: i,
    });
  });
  return arr;
};

export const setDrugTestTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Docket #": val?.sDocketNumberCourtCounts,
      "Drug Test Type": val?.sDrugTestTypeName,
      Served: convertDateFormat(val?.dTest),
      "Test Result": val?.sDrugTestResultName,
      "Result Date": convertDateFormat(val?.dResult),
      "Test Reason": val?.sDrugTestReasonName,
      "Test By": val?.sDrugTestByName,
      Void: val?.bVoid,
      Requisition: val?.sRequisitionNumber,
      Location: val?.sLocationName,
      Note: val?.sNote,
      ID: i,
      gDocketId: val?.gDocketId,
      gDrugTestReasonId: val?.gDrugTestReasonId,
      gDrugTestById: val?.gDrugTestById,
      gEntryUserId: val?.gEntryUserId,
      gDrugTestId: val?.gDrugTestId,
      gDrugTestTypeId: val?.gDrugTestTypeId,
      gDrugTestResultId: val?.gDrugTestResultId,
      gEntityId: val?.gEntityId,
      gPersonId: val?.gPersonId,
      bDiluted: val?.bDiluted,
      bMissedTest: val?.bMissedTest,
    });
  });
  return arr;
};

export const setRandomTestingTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Log Date": convertDateFormat(val?.dDOE),
      "Event Type": val?.sEventType,
      Message: val?.sNote,
      "Confirmation Code": val?.sCode,
      "IP Address": val?.sIpAddress,
      gEntryUserID: val?.gEntryUserID,
      iWebLogId: val?.iWebLogId,
      gDrugTestRandomizerLogID: val?.gDrugTestRandomizerLogID,
      ID: i,
    });
  });
  return arr;
};
