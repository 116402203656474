import { LoadPanel, ScrollView, Toast } from "devextreme-react";
import { useEffect, useState } from "react";
import { handleGetLocal } from "../../../../services/auth-service";
import {
  seton_Save_Edit,
  setresetVoideFalseFilter,
} from "../../../../reducer/participantNoteReducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../../components/Logout";

export const VoidPopup = ({
  setvoidPopup,
  userinfo,
  reportDataId,
  toastConfig,
  setToastConfig,
}) => {
  const [detail, setdetail] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [commentValidation, setcommentValidation] = useState("");
  const { on_Save_Edit, resetReportDates, resetVoideFalseFilter } = useSelector(
    (store) => store.participateNotes
  );

  const navigate = useNavigate();

  const voidApi = async (body) => {
    setLoading(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/PersonReportDates/void-appointment`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        dispatch(seton_Save_Edit(!on_Save_Edit));
        dispatch(setresetVoideFalseFilter(!resetVoideFalseFilter));
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Successfully Removed!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Successfully Removed!",
            type: "success",
          });
        }, 1400);
        setLoading(false);
        setvoidPopup(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const onSave = () => {
    if (detail) {
      voidApi({
        comment: detail,
        reportDateScheduleId: reportDataId,
      });
    } else {
      !detail
        ? setcommentValidation("Please enter comment")
        : setcommentValidation("");
    }
  };

  const ResetValue = () => {
    setdetail("");
    setcommentValidation("");
  };

  useEffect(() => {
    ResetValue();
  }, [resetReportDates]);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <ScrollView
        style={{
          width: "100%",
          height: "448px",
          border: "3px solid var(--button2)",
        }}
      >
        <div className="CreateAppointmentPopup">
          <div className="RecivePaymentModel_header">
            <div
              style={{ width: "200px" }}
              className="RecivePaymentModel_header_name"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C13.91 14.69 13.28 14.69 12.89 14.3L10 11.41L7.11 14.3C6.72 14.69 6.09 14.69 5.7 14.3C5.31 13.91 5.31 13.28 5.7 12.89L8.59 10L5.7 7.11C5.31 6.72 5.31 6.09 5.7 5.7C6.09 5.31 6.72 5.31 7.11 5.7L10 8.59L12.89 5.7C13.28 5.31 13.91 5.31 14.3 5.7C14.69 6.09 14.69 6.72 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z"
                  fill="white"
                />
              </svg>
              <p>Void Appointment</p>
              <span
                style={{
                  padding: "2px 9px",
                  gap: "8px",
                  width: "45px",
                  height: "24px",
                  background: "#FEF2F2",
                  borderRadius: "4px",
                }}
              >
                <p
                  style={{
                    color: "#DC2626",
                    fontFamily: "Readex Pro",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "10px",
                    lineHeight: "20px",
                    width: "21px",
                    height: "20px",
                  }}
                >
                  VD.1
                </p>
              </span>
            </div>
            <svg
              onClick={() => {
                setvoidPopup(false);
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "3%",
              width: "95%",
              marginLeft: "2.5%",
            }}
          >
            <h2
              style={{
                fontSize: "22px",
                fontWeight: "600",
                color: "var(--TextColor)",
              }}
            >
              Void Report Date
            </h2>
            <span
              style={{
                padding: "2px 9px",
                gap: "8px",
                width: "177px",
                height: "32px",
                background: "#FEF2F2",
                borderRadius: "8px",
                marginLeft: "5%",
              }}
            >
              <p
                style={{
                  color: "#DC2626",
                  fontFamily: "Readex Pro",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "20px",
                  width: "177px",
                  height: "32px",
                  marginTop: "5px",
                }}
              >
                This can not be undone
              </p>
            </span>
          </div>
          <div
            style={{
              marginTop: "2%",
              width: "95%",
              marginLeft: "2.5%",
            }}
          >
            <p>This should only be used to correct error</p>
          </div>
          <div
            style={{
              marginTop: "3%",
              width: "95%",
              marginLeft: "2.5%",
            }}
          >
            <p className="go-to-label-filed">
              Please enter a detailed comment <span>*</span>
            </p>
            <textarea
              value={detail}
              onChange={(e) => {
                setdetail(e.target.value);
                if (e.target.value) {
                  setcommentValidation("");
                }
                if (!e.target.value) {
                  setcommentValidation("Please enter comment");
                }
              }}
              style={{ width: "100%", height: "160px" }}
              placeholder="Write comment here"
            />
            <p style={{ color: "red" }}>{commentValidation}</p>
          </div>
          <div
            className="popup_buttons"
            style={{ marginTop: "20px", marginRight: "20px" }}
          >
            <button
              onClick={() => {
                onSave();
              }}
              className="btn primary_btn"
            >
              Save
            </button>
            <button
              onClick={() => {
                setvoidPopup(false);
              }}
              className="btn"
            >
              Cancel
            </button>
          </div>
        </div>
      </ScrollView>
    </>
  );
};
