
export function logout() {
  //do not use localstorage.clear()
   //do not use localstorage.clear()
    localStorage.removeItem('selectOption')
    localStorage.removeItem('authToken')
    localStorage.removeItem('username')
    localStorage.removeItem('email')
    localStorage.removeItem('user_designation')
    localStorage.removeItem('password')
    localStorage.removeItem('pathURL')
    localStorage.removeItem('emperiod')
    localStorage.removeItem('gUserId')
     //do not use localstorage.clear()
  }
  
  export function setAuthToken(token) {
    localStorage.setItem("authToken", token);
  }
  
  export function getAuthToken() {
    return localStorage.getItem("authToken");
  }

  export function  handleSetleLocal(key,value){
    return localStorage.setItem(key,value);
  } 
  export function handleGetLocal (key){
    return localStorage.getItem(key);
  } 
  
  export default {
    logout,
    setAuthToken,
    getAuthToken,
    handleGetLocal,
    handleSetleLocal
  };
  