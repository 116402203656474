export const logTablecoloumn = [
  { title: "Date", date: true },
  { title: "User" },
  { title: "Note" },
];

export const firstTablecoloumn = [
  { title: "Reference" },
  { title: "Docket" },
  { title: "Court" },
  { title: "Fee" },
  { title: "Amount" },
  { title: "Paid" },
  { title: "Write Off" },
  { title: "Balance" },
  { title: "On Hand" },
  { title: "Amount to Deallocate" },
];
