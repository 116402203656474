import { useDispatch } from "react-redux";
import { setAdminSavePopup } from "../../../../../reducer/AdminReadReducer";

const DetailsAddress = ({
  isEditableButton,
  toastConfig,
  setToastConfig,
  contactData,
  fields,
  setFields,
}) => {
  const dispatch = useDispatch();
  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    dispatch(setAdminSavePopup({ adminSavePopup: true }));
  };

  return (
    <>
      <div className="details-address">
        <div className="page-section-fileds" style={{ flexWrap: "wrap" }}>
          <div className="card-container">
            <p className="card-container-header">
              <svg
                className="svg-black-white"
                width="20"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 4H14V2C14 0.89 13.11 0 12 0H8C6.89 0 6 0.89 6 2V4H2C0.89 4 0.00999999 4.89 0.00999999 6L0 17C0 18.11 0.89 19 2 19H18C19.11 19 20 18.11 20 17V6C20 4.89 19.11 4 18 4ZM12 4H8V2H12V4Z"
                  fill="black"
                />
              </svg>
              Physical Address
            </p>
            <div className="card-container-header-content">
              <div className="inputs-fields-container">
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <label htmlFor="" className="edit_container_label">
                    Address Line 1
                  </label>
                  <input
                    type="text"
                    name="physicalAddressLine1"
                    className="fee-payment-content-info-input"
                    disabled={!isEditableButton}
                    placeholder="Address Line 1"
                    value={fields?.physicalAddressLine1}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="inputs-fields-container">
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <label htmlFor="" className="edit_container_label">
                    Address Line 2
                  </label>
                  <input
                    type="text"
                    name="physicalAddressLine2"
                    className="fee-payment-content-info-input"
                    disabled={!isEditableButton}
                    placeholder="Address Line 2"
                    value={fields?.physicalAddressLine2}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="inputs-fields-container">
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <label
                    htmlFor="physicalAddressCity"
                    className="edit_container_label"
                  >
                    City
                  </label>
                  <input
                    type="text"
                    name="physicalAddressCity"
                    id="physicalAddressCity"
                    className="fee-payment-content-info-input input-custom-width"
                    disabled={!isEditableButton}
                    placeholder="City"
                    value={fields?.physicalAddressCity}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <label
                    htmlFor="physicalAddressState"
                    className="edit_container_label"
                  >
                    State
                  </label>
                  <input
                    type="text"
                    name="physicalAddressState"
                    id="physicalAddressState"
                    className="fee-payment-content-info-input input-custom-width"
                    disabled={!isEditableButton}
                    placeholder="State"
                    value={fields?.physicalAddressState}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div
                className="inputs-fields-container"
                style={{ alignItems: "flex-end" }}
              >
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <label
                    htmlFor="physicalAddressZip"
                    className="edit_container_label"
                  >
                    Zip
                  </label>
                  <input
                    type="number"
                    name="physicalAddressZip"
                    id="physicalAddressZip"
                    className="fee-payment-content-info-input input-custom-width"
                    disabled={!isEditableButton}
                    placeholder="Zip"
                    value={fields?.physicalAddressZip}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <input
                    type="number"
                    name="physicalAddressZipCode"
                    className="fee-payment-content-info-input input-custom-width"
                    disabled={!isEditableButton}
                    value={fields?.physicalAddressZipCode}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="inputs-fields-container">
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <label
                    htmlFor="physicalAddressCountry"
                    className="edit_container_label"
                  >
                    Country
                  </label>
                  <input
                    type="text"
                    name="physicalAddressCountry"
                    id="physicalAddressCountry"
                    className="fee-payment-content-info-input"
                    disabled={!isEditableButton}
                    placeholder="Country"
                    value={fields?.physicalAddressCountry}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-container">
            <p className="card-container-header">
              <svg
                className="svg-black-white"
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM17.6 4.25L11.06 8.34C10.41 8.75 9.59 8.75 8.94 8.34L2.4 4.25C2.15 4.09 2 3.82 2 3.53C2 2.86 2.73 2.46 3.3 2.81L10 7L16.7 2.81C17.27 2.46 18 2.86 18 3.53C18 3.82 17.85 4.09 17.6 4.25Z"
                  fill="black"
                />
              </svg>
              Mailing Address
            </p>
            <div className="card-container-header-content">
              <div className="inputs-fields-container">
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <label htmlFor="" className="edit_container_label">
                    Address Line 1
                  </label>
                  <input
                    type="text"
                    name="mailingAddressLine1"
                    className="fee-payment-content-info-input"
                    disabled={!isEditableButton}
                    placeholder="Address Line 1"
                    value={fields?.mailingAddressLine1}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="inputs-fields-container">
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <label htmlFor="" className="edit_container_label">
                    Address Line 2
                  </label>
                  <input
                    type="text"
                    name="mailingAddressLine2"
                    className="fee-payment-content-info-input"
                    disabled={!isEditableButton}
                    placeholder="Address Line 2"
                    value={fields?.mailingAddressLine2}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="inputs-fields-container">
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <label
                    htmlFor="mailingAddressCity"
                    className="edit_container_label"
                  >
                    City
                  </label>
                  <input
                    type="text"
                    name="mailingAddressCity"
                    id="mailingAddressCity"
                    className="fee-payment-content-info-input input-custom-width"
                    disabled={!isEditableButton}
                    placeholder="City"
                    value={fields?.mailingAddressCity}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <label
                    htmlFor="mailingAddressState"
                    className="edit_container_label"
                  >
                    State
                  </label>
                  <input
                    type="text"
                    name="mailingAddressState"
                    id="mailingAddressState"
                    className="fee-payment-content-info-input input-custom-width"
                    disabled={!isEditableButton}
                    placeholder="State"
                    value={fields?.mailingAddressState}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div
                className="inputs-fields-container"
                style={{ alignItems: "flex-end" }}
              >
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <label
                    htmlFor="mailingAddressZip"
                    className="edit_container_label"
                  >
                    Zip
                  </label>
                  <input
                    type="number"
                    name="mailingAddressZip"
                    id="mailingAddressZip"
                    className="fee-payment-content-info-input input-custom-width"
                    disabled={!isEditableButton}
                    placeholder="Zip"
                    value={fields?.mailingAddressZip}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <input
                    type="number"
                    name="mailingAddressZipCode"
                    className="fee-payment-content-info-input input-custom-width"
                    disabled={!isEditableButton}
                    value={fields?.mailingAddressZipCode}
                    onChange={handleInputChange}
                    maxLength={4}
                  />
                </div>
              </div>
              <div className="inputs-fields-container">
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <label
                    htmlFor="mailingAddressCountry"
                    className="edit_container_label"
                  >
                    Country
                  </label>
                  <input
                    type="text"
                    name="mailingAddressCountry"
                    id="mailingAddressCountry"
                    className="fee-payment-content-info-input"
                    disabled={!isEditableButton}
                    placeholder="Country"
                    value={fields?.mailingAddressCountry}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-container">
            <p className="card-container-header">
              <svg
                className="svg-black-white"
                width="20"
                height="17"
                viewBox="0 0 20 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00254 16.0002V11.0002H12.0025V16.0002C12.0025 16.5502 12.4525 17.0002 13.0025 17.0002H16.0025C16.5525 17.0002 17.0025 16.5502 17.0025 16.0002V9.00021H18.7025C19.1625 9.00021 19.3825 8.43022 19.0325 8.13022L10.6725 0.600215C10.2925 0.260215 9.71254 0.260215 9.33254 0.600215L0.972539 8.13022C0.632539 8.43022 0.842539 9.00021 1.30254 9.00021H3.00254V16.0002C3.00254 16.5502 3.45254 17.0002 4.00254 17.0002H7.00254C7.55254 17.0002 8.00254 16.5502 8.00254 16.0002Z"
                  fill="black"
                />
              </svg>
              Home Address
            </p>
            <div className="card-container-header-content">
              <div className="inputs-fields-container">
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <label htmlFor="" className="edit_container_label">
                    Address Line 1
                  </label>
                  <input
                    type="text"
                    name="homeAddressLine1"
                    className="fee-payment-content-info-input"
                    disabled={!isEditableButton}
                    placeholder="Address Line 1"
                    value={fields?.homeAddressLine1}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="inputs-fields-container">
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <label htmlFor="" className="edit_container_label">
                    Address Line 2
                  </label>
                  <input
                    type="text"
                    name="homeAddressLine2"
                    className="fee-payment-content-info-input"
                    disabled={!isEditableButton}
                    placeholder="Address Line 2"
                    value={fields?.homeAddressLine2}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="inputs-fields-container">
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <label
                    htmlFor="homeAddressCity"
                    className="edit_container_label"
                  >
                    City
                  </label>
                  <input
                    type="text"
                    name="homeAddressCity"
                    id="homeAddressCity"
                    className="fee-payment-content-info-input input-custom-width"
                    disabled={!isEditableButton}
                    placeholder="City"
                    value={fields?.homeAddressCity}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <label
                    htmlFor="homeAddressState"
                    className="edit_container_label"
                  >
                    State
                  </label>
                  <input
                    type="text"
                    name="homeAddressState"
                    id="homeAddressState"
                    className="fee-payment-content-info-input input-custom-width"
                    disabled={!isEditableButton}
                    placeholder="State"
                    value={fields?.homeAddressState}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div
                className="inputs-fields-container"
                style={{ alignItems: "flex-end" }}
              >
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <label
                    htmlFor="homeAddressZip"
                    className="edit_container_label"
                  >
                    Zip
                  </label>
                  <input
                    type="number"
                    name="homeAddressZip"
                    id="homeAddressZip"
                    className="fee-payment-content-info-input input-custom-width"
                    disabled={!isEditableButton}
                    placeholder="Zip"
                    value={fields?.homeAddressZip}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <input
                    type="number"
                    name="homeAddressZipCode"
                    className="fee-payment-content-info-input input-custom-width"
                    disabled={!isEditableButton}
                    value={fields?.homeAddressZipCode}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="inputs-fields-container">
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <label
                    htmlFor="homeAddressCountry"
                    className="edit_container_label"
                  >
                    Country
                  </label>
                  <input
                    type="text"
                    name="homeAddressCountry"
                    id="homeAddressCountry"
                    className="fee-payment-content-info-input"
                    disabled={!isEditableButton}
                    placeholder="Address Line 2"
                    value={fields?.homeAddressCountry}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsAddress;
