import { DataGrid, DateBox, DropDownBox } from "devextreme-react";
import React, { useCallback, useEffect, useState } from "react";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import { Column, FilterRow, Selection } from "devextreme-react/data-grid";
import { handleGetLocal } from "../../../../../services/auth-service";
import axios from "axios";
const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
      />
    </>
  );
};

export const AddJailServicePopup = ({
  addJailServicePopup,
  setAddJailServicePopup,
  userinfo,
  dropdownData,
  docketDropdownData,
  jailPlainType,
  jailRowData,
  toastConfig,
  setToastConfig,
  setLoading,
  getJailPlannedServiceListApi,
  setJailTimeTab,
  getJailtimelogDropdown,
  gotodocker,
  dockertDetail,
  dockerHeaderData,
}) => {
  const [selectedValue, setSelectedValue] = useState({
    Court: "",
    Docket: "",
    "Sentence Date": "",
    "Supervision Status": "",
    gDocketId: "",
  });
  const [dataSource, setDataSource] = useState([]);
  const [fields, setFields] = useState({
    reason: { name: "", id: "" },
    workSite: { name: "", id: "" },
    daysOrdered: "1",
    startDate: "",
    completeBy: "",
  });
  const [docketvalidation, setDocketvalidation] = useState("");
  const [workSitevalidation, setWorkSitevalidation] = useState("");
  const [reasonvalidation, setReasonvalidation] = useState("");
  const [daysOrderedvalidation, SetDaysOrderedvalidation] = useState("");
  const [startDateValidation, SetstartDateValidation] = useState("");
  const [completeByValidation, setcompleteByValidation] = useState("");
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);
  const [workSiteDropdown, setWorkSiteDropdown] = useState([]);

  const pathname = handleGetLocal("pathURL");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/Entity/entity-find?search=jail`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((res) => {
        setWorkSiteDropdown(
          res.data.data?.map((entity) => {
            return {
              value: entity?.sName,
              key: entity?.gEntityId,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (fields.daysOrdered[0] === "") {
      SetDaysOrderedvalidation("Enter Days Ordered");
    } else {
      SetDaysOrderedvalidation("");
    }
  };

  const fetchedDataSource = docketDropdownData?.map((val) => {
    const convertDateFormat = (dateString) => {
      const dateObj = new Date(dateString);
      return dateObj.toLocaleDateString("en-US");
    };
    return {
      Court: val?.sCourtName,
      Docket: val?.sDocketNumberCourtCounts,
      "Sentence Date": convertDateFormat(val?.dSentence),
      "Supervision Status": val?.sSupervisionStatusName,
      gDocketId: val?.gDocketId,
    };
  });

  useEffect(() => {
    if (jailPlainType === "ADD") {
      const defaultReason = dropdownData?.jailReasons?.find(
        (reason) => reason.value.toLowerCase() === "original sentence"
      );
      setFields({
        reason: { name: defaultReason?.value, id: defaultReason?.key },
        workSite: { name: workSiteDropdown[0]?.value, id: workSiteDropdown[0]?.key },
        daysOrdered: "1",
        startDate: "",
        completeBy: dockerHeaderData?.dSentenceAdjustedEnd,
      });
      const selectedDocket = dataSource?.find((item) => item.gDocketId === dockertDetail?.id);
      setSelectedValue(selectedDocket);
      setDocketvalidation("");
      setWorkSitevalidation("");
      setReasonvalidation("");
      SetDaysOrderedvalidation("");
      SetstartDateValidation("");
      setcompleteByValidation("");
      setDataSource(fetchedDataSource);
    } else {
      const findKeyById = (id, data) => {
        const item = data?.find((item) => item?.key.toLowerCase() === id?.toLowerCase());
        return item ? item.value : "";
      };
      const reasonName = findKeyById(jailRowData?.jailTimeReasonId, dropdownData?.jailReasons);
      const worksiteName = findKeyById(jailRowData?.workSiteId, workSiteDropdown);
      setFields((prevFields) => ({
        ...prevFields,
        reason: {
          ...prevFields.reason,
          name: reasonName,
          id: jailRowData?.jailTimeReasonId,
        },
        workSite: {
          ...prevFields.workSite,
          name: worksiteName,
          id: jailRowData?.workSiteId,
        },
      }));
      const selectedDocket = dataSource?.find((item) => item.gDocketId === jailRowData?.docketId);
      setSelectedValue(selectedDocket);
      setDataSource(fetchedDataSource);
      setFields((prev) => ({
        ...prev,
        daysOrdered: jailRowData?.[`Days Ordered`],
        startDate: jailRowData?.[`Start Date`],
        completeBy: jailRowData?.[`Completed By Date`],
      }));
    }
  }, [jailRowData, addJailServicePopup]);

  const data = {
    jailTimeId: jailPlainType === "ADD" ? "" : jailRowData?.jailTimeId,
    docketId: selectedValue?.gDocketId,
    startDate: fields?.startDate,
    completeBy: fields?.completeBy,
    daysOrdered: fields?.daysOrdered,
    workSiteId: fields?.workSite?.id,
    reasonId: fields?.reason?.id,
  };

  const handleSave = async () => {
    var startDate = new Date(fields.startDate);
    var completeBy = new Date(fields.completeBy);
    let isValid = true;
    if (!selectedValue?.gDocketId) {
      setDocketvalidation("Please Select Docket");
      isValid = false;
    }
    if (!fields.startDate) {
      SetstartDateValidation("Please Start Date");
      isValid = false;
    }
    if (!fields.completeBy) {
      setcompleteByValidation("Please Complete By");
      isValid = false;
    }
    if (!fields.reason.id) {
      setReasonvalidation("Please Select Reason");
      isValid = false;
    }
    if (!fields.workSite.id) {
      setWorkSitevalidation("Please Select Work Site");
      isValid = false;
    }
    if (!fields.daysOrdered) {
      SetDaysOrderedvalidation("Enter Days Ordered");
    }
    if (completeBy <= startDate) {
      setcompleteByValidation("Complete By Date should be greater than Start Date");
      isValid = false;
    }
    if (isValid) {
      setLoading(true);
      const pathname = handleGetLocal("pathURL");
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/jail-time/save-jailtime`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        })
        .then((val) => {
          setLoading(false);
          setAddJailServicePopup(false);
          getJailPlannedServiceListApi();
          getJailtimelogDropdown();
          setJailTimeTab(1);
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: jailPlainType === "ADD" ? "Successfully Added!" : "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: jailPlainType === "ADD" ? "Successfully Added!" : "Successfully Updated!",
              type: "success",
            });
          }, 1900);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  };

  const onGridBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }, []);

  return (
    <div className="add_jail_service">
      <div className="popup_header">
        <div className="popup_header_title">
          <div class="popup_header_title_data">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="add" clipPath="url(#clip0_5349_5505)">
                <path
                  id="Vector"
                  d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                  fill="#fff"
                />
              </g>
              <defs>
                <clipPath id="clip0_5349_5505">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p>Jail Time</p>
          </div>
          <p class="popup_header_title_para">JT.1</p>
        </div>
        <svg
          onClick={() => {
            setAddJailServicePopup(false);
          }}
          style={{
            cursor: "pointer",
          }}
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="add_community_service_content">
        <div className="edit_container_first">
          <div className="edit_content_flex">
            <p className="edit_container_label">
              Docket <span className="marks_mandatary">*</span>
            </p>
            <DropDownBox
              placeholder="Select..."
              disabled={gotodocker ? true : false}
              dataSource={dataSource}
              value={selectedValue?.Docket}
              valueExpr={"Docket"}
              displayExpr={"Docket"}
              opened={isGridBoxOpened}
              onOptionChanged={onGridBoxOpened}
              contentRender={(value, element) => (
                <DataGrid
                  dataSource={dataSource}
                  hoverStateEnabled={true}
                  height={180}
                  onRowClick={(e) => {
                    setSelectedValue(e?.data);
                    setDocketvalidation("");
                    setIsGridBoxOpened(false);
                  }}
                >
                  <Selection mode="single" />
                  <FilterRow visible={true} />
                  <Column dataField="Court" caption="Court" width={"auto"} />
                  <Column dataField="Docket" caption="Docket" width={"auto"} />
                  <Column dataField="Sentence Date" caption="Sentence Date" width={"auto"} />
                  <Column dataField="Supervision Status" caption="Supervision Status" width={"auto"} />
                </DataGrid>
              )}
            />
            {docketvalidation && (
              <p className="error_validation" style={{ position: "absolute", top: "154px" }}>
                {docketvalidation}
              </p>
            )}
          </div>
          <div className="edit_content_flex edit_content_space">
            <p className="edit_container_label">
              Reason <span className="marks_mandatary">*</span>
            </p>
            <DropDown
              setfields={setFields}
              fields={fields}
              fieldNmae={"reason"}
              dropDownData={dropdownData?.jailReasons?.map((vall) => {
                return {
                  name: vall?.value,
                  id: vall?.key,
                };
              })}
              reset={setReasonvalidation}
            />
            {reasonvalidation && (
              <p className="error_validation" style={{ position: "absolute", top: "154px" }}>
                {reasonvalidation}
              </p>
            )}
          </div>
        </div>
        <div className="edit_container_first">
          <div className="edit_content_flex">
            <p className="edit_container_label">
              Days Ordered <span className="marks_mandatary">*</span>
            </p>
            <input
              id="daysOrdered"
              name="daysOrdered"
              type="number"
              placeholder="Enter Days Ordered"
              className="edit_container_input"
              value={fields.daysOrdered}
              onChange={handleInputChange}
            />
            {daysOrderedvalidation && (
              <p className="error_validation" style={{ position: "absolute", top: "243px" }}>
                {daysOrderedvalidation}
              </p>
            )}
          </div>
          <div className="edit_content_flex edit_content_space">
            <p className="edit_container_label">
              Work Site <span className="marks_mandatary">*</span>
            </p>
            <DropDown
              setfields={setFields}
              fields={fields}
              fieldNmae={"workSite"}
              dropDownData={workSiteDropdown?.map((vall) => {
                return {
                  name: vall?.value,
                  id: vall?.key,
                };
              })}
              reset={setWorkSitevalidation}
            />
            {workSitevalidation && (
              <p className="error_validation" style={{ position: "absolute", top: "246px" }}>
                {workSitevalidation}
              </p>
            )}
          </div>
        </div>
        <div className="edit_container_first">
          <div className="edit_content_flex">
            <p className="edit_container_label">
              Start Date <span className="marks_mandatary">*</span>
            </p>
            <DateBox
              useMaskBehavior={true}
              value={fields.startDate}
              onValueChange={(e) => {
                setFields((prev) => ({
                  ...prev,
                  startDate: e,
                }));
                SetstartDateValidation("");
              }}
            />
            {startDateValidation && (
              <p className="error_validation" style={{ position: "absolute", top: "333px" }}>
                {startDateValidation}
              </p>
            )}
          </div>
          <div className="edit_content_flex">
            <p className="edit_container_label">
              Complete By <span className="marks_mandatary">*</span>
            </p>
            <DateBox
              useMaskBehavior={true}
              value={fields.completeBy}
              onValueChange={(e) => {
                setFields((prev) => ({
                  ...prev,
                  completeBy: e,
                }));
                setcompleteByValidation("");
              }}
            />
            {completeByValidation && (
              <p className="error_validation" style={{ position: "fixed", top: "335px" }}>
                {completeByValidation}
              </p>
            )}
          </div>
        </div>
        <div className="popup_buttons" style={{ marginTop: "10px" }}>
          <button className="btn primary_btn" onClick={handleSave}>
            Save
          </button>
          <button
            className="btn"
            onClick={() => {
              setAddJailServicePopup(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
