import React from "react";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { jailPlannedservicesTablecoloumn } from "../Data";
import { ApiError } from "../../../../Error/ApiError";

export const PlannedJailservices = ({
  setVoidPopup,
  setAddJailServicePopup,
  jailPlannedData,
  loading,
  setJailRowData,
  setJailPlainType,
  jailPlannedErrorHandling,
}) => {
  return (
    <div className="planned_jail_service">
      {jailPlannedErrorHandling ? (
        <ApiError />
      ) : (
        <Table1
          editTable={false}
          height={400}
          Tablecoloum={jailPlannedservicesTablecoloumn}
          tableRow={jailPlannedData?.map((val, i) => {
            return { ...val, ID: i };
          })}
          load={loading}
          coloumWidthText={"JailTimePlannedservices"}
          mandatoryColoum={"Docket"}
          setAddJailServicePopup={setAddJailServicePopup}
          jailMenu={true}
          setVoidPopup={setVoidPopup}
          setJailRowData={setJailRowData}
          jailPlannedMenu={true}
          setJailPlainType={setJailPlainType}
          setVoideFalse={true}
        />
      )}
    </div>
  );
};
