import axios from "axios";
import { handleGetLocal } from "../../../services/auth-service";
import { Navigate } from "react-router-dom";
import { logOut } from "../../../components/Logout";
import { setContectEntityListTableAddData2 } from "../../../reducer/AdminReadReducer";

export class ContactsFunction {
  constructor() {
    this.pathname = handleGetLocal("pathURL");
  }

  setTableData = (dataa) => {
    const arr = [];
    dataa.map((val) => {
      arr.push({
        Primary: val?.bPrimary ? val?.bPrimary : false,
        "Contact Information": val?.sInformation
          .replace(/^\+1/, "")
          .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"),
        "Contact Type": val?.sContactType,
        Comment: val?.sComment,
        ID: val?.gContactInfoId,
        gContactInfoTypeId: val?.gContactInfoTypeId,
      });
    });
    return arr;
  };

  setContactEntityListData = (data) => {
    const arr = [];
    data.map((val) => {
      arr.push({
        Entity: val?.sEntityName,
        Position: val?.sPositionName,
        Active: val?.bActive,
        Start: val?.dStart ? val?.dStart : "",
        End: val?.dEnd ? val?.dEnd : "",
        Primary: val?.bPrimary,
        ID: val?.gContactId,
        gEntityContactId: val?.gEntityContactId,
        gEntityId: val?.gEntityId,
        gContactId: val?.gContactId,
        gContactPositionId: val?.gContactPositionId,
        sContactLastName: val?.sContactLastName,
        sEntityName: val?.sEntityName,
      });
    });
    return arr;
  };
  setContactEntityListRightData = (data) => {
    const arr = [];
    data.map((val) => {
      arr.push({
        Report: val?.sReportCatalogName,
        "Report Type": val?.sReportTypeName,
        ID: val?.gContactId,
        gEntityContactReportCatalogId: val?.gEntityContactReportCatalogId,
        gReportCatalogId: val?.gReportCatalogId,
        gEntityContactId: val?.gEntityContactId,
        gContactId: val?.gContactId,
        gEntryUserId: val?.gEntryUserId,
      });
    });
    return arr;
  };

  setContactEntityAllListRightData = (data) => {
    const arr = [];
    data.map((val) => {
      arr.push({
        Report: val?.value,
        "Report Type": val?.reportType,
        GReportCatalogId: val?.key,
      });
    });
    return arr;
  };

  getContactInfoDetails = async (
    gContactId,
    setLoading,
    setdata,
    setContactInfoTypeCount
  ) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Contacts/contacts-info-get?GContactId=${gContactId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setdata(this.setTableData(val.data.data));
        setContactInfoTypeCount(val.data.data.length);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          Navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  ContectTypeDropDown = async (setcontactInfoType) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Person/masterDropDownValuesGet`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setcontactInfoType(
          val.data.data.contactInfoType.map((val) => {
            return {
              id: val?.gContactInfoTypeId,
              name: val?.sName,
            };
          })
        );
      });
  };

  getContactsEntityListDropdown = async (
    SetContactsEntityListDropdown,
    setReportCatalogData
  ) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Contacts/dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        SetContactsEntityListDropdown(val?.data?.data);
        setReportCatalogData(
          this.setContactEntityAllListRightData(val?.data?.data?.reportCatalog)
        );
      });
  };

  getContactEntityListData = async (
    params,
    setLoading,
    setEntityListLeftData,
    setEntityListRightData,
    setContactEntityListCount,
    dispatch
  ) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Contacts/entity-list-get?gEntityContactId=${params?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setEntityListLeftData(
          this.setContactEntityListData(val?.data?.data?.entityList)
        );
        setEntityListRightData(
          this.setContactEntityListRightData(val?.data?.data?.reportCatalog)
        );
        setContactEntityListCount(val?.data?.data?.entityList?.length);
        dispatch(
          setContectEntityListTableAddData2(
            val?.data?.data?.entityList?.map((vall, i) => {
              return {
                Entity: vall?.gEntityId ? vall?.gEntityId.toUpperCase() : "",
                Position: vall?.gContactPositionId
                  ? vall?.gContactPositionId.toUpperCase()
                  : "",
                Active: vall?.bActive ? vall?.bActive : false,
                Start: vall?.dStart ? vall?.dStart : "",
                End: vall?.dEnd ? vall?.dEnd : "",
                Primary: vall?.bPrimary ? vall?.bPrimary : false,
                ID: i,
                gEntityContactId: vall?.gEntityContactId,
                gEntityId: vall?.gEntityId,
                gContactId: vall?.gContactId,
                gContactPositionId: vall?.gContactPositionId,
                sContactLastName: vall?.sContactLastName,
                sEntityName: vall?.sEntityName,
              };
            })
          )
        );
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          Navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  saveContactsContactsList = async (body, setLoading, navigate) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Contacts/add-edit-contact-info`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
        } else {
          console.error(val?.data?.errors);
        }
      })
      .catch((val) => {
        setLoading(false);

        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  };

  saveContactsEntityList = async (
    body,
    setLoading,
    navigate,
    entityListRightData,
    typeFeeSchedule,
    params,
    setEntityListLeftData,
    setEntityListRightData,
    setContactEntityListCount,
    dispatch
  ) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Contacts/entity-list-add-edit`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          if (typeFeeSchedule === "Edit") {
            const primaryEntity = val?.data?.data?.find(
              (item) => item?.bPrimary === true
            );
            if (primaryEntity) {
              const extractedData = entityListRightData?.map((item) => {
                return {
                  GEntityContactReportCatalogId:
                    item?.gEntityContactReportCatalogId,
                  GEntryUserId: item?.gEntryUserId,
                  GReportCatalogId: item?.gReportCatalogId,
                  GEntityContactId: primaryEntity?.gEntityContactId,
                };
              });
              this.saveContactsEntityReportCatalogList(
                extractedData,
                setLoading,
                navigate
              );
              this.getContactEntityListData(
                params,
                setLoading,
                setEntityListLeftData,
                setEntityListRightData,
                setContactEntityListCount,
                dispatch
              );
            }
          }
        } else {
          console.error(val?.data?.errors);
        }
      })
      .catch((val) => {
        setLoading(false);

        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  };

  saveContactsEntityReportCatalogList = async (body, setLoading, navigate) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Contacts/report-catalog-add-edit`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
        } else {
          console.error(val?.data?.errors);
        }
      })
      .catch((val) => {
        setLoading(false);

        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  };
}
