import React, { useEffect, useRef, useState } from "react";
import "../user.css";
import axios from "axios";
import errorIcon from "../../../assets/images/errorIcon.svg";
import { Toast } from "devextreme-react";
import { logOut } from "../../../components/Logout";
import { useNavigate } from "react-router-dom";

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};
const smallTextStyle = {
  fontSize: "10px",
  lineHeight: "13px",
};

const Header = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  },
};

export default function ResetPassword(props) {
  const [passwordResponce, setpasswordResponce] = useState({
    status: "error",
    message: "",
  });
  const [checkPasswordApi, setcheckPasswordApi] = useState(false);

  const [field, setField] = useState({
    currentPassword: "",
    newPassword: "",
    reNewPassword: "",
  });
  const [fieldValidation, setFieldValidation] = useState({
    currentPassword: "",
    newPassword: "",
    reNewPassword: "",
  });
  const [eyeTogel, seteyesTogel] = useState({
    currentPassword: false,
    newPassword: false,
    reNewPassword: false,
  });
  const [isPasswordCorrect, setIsPasswordCorrect] = useState({
    upper: false,
    lower: false,
    special: false,
    numeric: false,
    isValidPassword: false,
  });

  const [disable, setdisable] = useState(true);

  var passPattern = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})");
  const upperCaseRegex = new RegExp("[A-Z]");
  const lowerCaseRegex = new RegExp("[a-z]");
  const specialCharRegex = new RegExp("[!@#%^&$*()_+\\-=[\\]{}|;':\",./<>?~`]");
  const numericRegex = new RegExp(`[0-9]`);

  const CheckPasswordValidation = (event) => {
    setIsPasswordCorrect({
      upper: upperCaseRegex.test(event.target.value) ? true : false,
      lower: lowerCaseRegex.test(event.target.value) ? true : false,
      special: specialCharRegex.test(event.target.value) ? true : false,
      numeric: numericRegex.test(event.target.value) ? true : false,
      isValidPassword: passPattern.test(event.target.value) ? true : false,
    });
  };

  const Reset = () => {
    seteyesTogel({
      currentPassword: false,
      newPassword: false,
      reNewPassword: false,
    });
    setFieldValidation({
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
    });
    setcheckPasswordApi(false);
    setField({
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
    });

    setIsPasswordCorrect({
      upper: false,
      lower: false,
      special: false,
      numeric: false,
      isValidPassword: false,
    });

    setpasswordResponce({
      status: "error",
      message: "",
    });
    setdisable(true);
  };

  const componentRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        Reset();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const resetSubmit = async () => {
    if (
      !fieldValidation.newPassword &&
      !fieldValidation.reNewPassword &&
      !fieldValidation.currentPassword &&
      field.currentPassword &&
      field.newPassword &&
      field.reNewPassword
    ) {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/Account/passwordValidate?password=${field.currentPassword}`, Header)
        .then(async (val) => {
          if (val.data.isSuccess) {
            await axios
              .get(
                `${process.env.REACT_APP_API_URL}/api/Account/resetPassword?newpassword=${field.newPassword}`,
                Header
              )
              .then((vall) => {
                if (vall.data.isSuccess) {
                  props.setToastConfig({
                    ...props.toastConfig,
                    isVisible: true,
                    message: vall.data.data.vcStatus,
                    type: "success",
                  });

                  setTimeout(() => {
                    props.setToastConfig({
                      ...props.toastConfig,
                      isVisible: false,
                      message: vall.data.data.vcStatus,
                      type: "success",
                    });
                  }, 1400);

                  handleReset2();
                }
              });
          } else {
            setpasswordResponce({
              ...passwordResponce,
              message: val.data.data.vcStatus,
              status: "error",
            });
          }
        });
    }
  };

  const handleReset2 = () => {
    Reset();

    return props.handleReset(!props.isResetPass);
  };

  const ref = useRef();
  const navigate = useNavigate();
  useOutsideClick(ref, async () => {
    if (checkPasswordApi && field.currentPassword) {
      setcheckPasswordApi(false);
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/Account/passwordValidate?password=${field.currentPassword}`, Header)
        .then((val) => {
          setcheckPasswordApi(false);
          if (!val.data.isSuccess) {
            setpasswordResponce({
              ...passwordResponce,
              message: "Incorrect password",
              status: "error",
            });
            setdisable(true);
          } else {
            setpasswordResponce({
              ...passwordResponce,
              message: "",
              status: "",
            });
            setdisable(false);
          }
        })
        .catch((val) => {
          setcheckPasswordApi(false);
          if (val?.response?.status == 401) {
            logOut();
          } else {
            if (val?.response?.status) {
              navigate(`/error/500`);
            } else {
              navigate("/error/500");
            }
          }
        });
    }
  });

  return (
    <>
      <div>
        <div className="mainTabWrapper">
          <div
            id="myModal"
            className="modal"
            style={{
              display: props?.isResetPass ? "block" : "none",
            }}
            ref={componentRef}
          >
            <div className="modalWrapper modelSizeMedium">
              <div className="modelHeader">
                <div className="modelHeader_content">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1450_386627)">
                      <path
                        d="M18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM12 17C10.9 17 10 16.1 10 15C10 13.9 10.9 13 12 13C13.1 13 14 13.9 14 15C14 16.1 13.1 17 12 17ZM9 8V6C9 4.34 10.34 3 12 3C13.66 3 15 4.34 15 6V8H9Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1450_386627">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="modelHeaderTitle">Reset Password</div>
                  <div className="modelHeaderSubTitle">US.4</div>
                </div>
                <svg
                  onClick={handleReset2}
                  style={{
                    cursor: "pointer",
                  }}
                  width="12"
                  height="12"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="modelContent">
                <form action="">
                  <div className={`fieldWrap ${fieldValidation.currentPassword ? " error " : ""} `}>
                    <label className="go-to-label-filed">
                      Current Password
                      {field.currentPassword && fieldValidation.currentPassword ? (
                        <>
                          {
                            <img
                              className="errorIcon popup-label"
                              src={errorIcon}
                              alt=""
                              style={{ marginLeft: "2%", marginBottom: "-5px" }}
                            />
                          }
                          <div className="popup-content">
                            <span style={smallTextStyle}>
                              <p
                                style={{
                                  fontSize: "10px",
                                  lineHeight: "13px",
                                  color: isPasswordCorrect.isValidPassword ? "green" : "red",
                                }}
                                className="small-text"
                              >
                                Password should be 10 digits alphanumeric with at Least:
                              </p>
                              <li
                                style={{
                                  color: isPasswordCorrect.upper ? "green" : "red",
                                }}
                                className="small-text"
                              >
                                1 Upper Case Character,
                              </li>
                              <li
                                style={{
                                  color: isPasswordCorrect.lower ? "green" : "red",
                                }}
                                className="small-text"
                              >
                                1 Lower Case Character,
                              </li>
                              <li
                                style={{
                                  color: isPasswordCorrect.numeric ? "green" : "red",
                                }}
                                className="small-text"
                              >
                                1 Numeric Character and
                              </li>
                              <li
                                style={{
                                  color: isPasswordCorrect.special ? "green" : "red",
                                }}
                                className="small-text"
                              >
                                1 Special Character.
                              </li>
                            </span>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </label>
                    <input
                      type={eyeTogel.currentPassword ? "text" : "password"}
                      name="cPassword"
                      placeholder="Current Password"
                      value={field.currentPassword}
                      onChange={(e) => {
                        CheckPasswordValidation(e);
                        setcheckPasswordApi(true);
                        setField({ ...field, currentPassword: e.target.value });
                        setFieldValidation({
                          ...fieldValidation,
                          currentPassword: !passPattern.test(e.target.value) ? "*Please enter valid password" : "",
                        });
                      }}
                      ref={ref}
                      style={{ outline: fieldValidation.currentPassword }}
                    />
                    <p
                      className="error"
                      style={{
                        color: fieldValidation.currentPassword ? "red" : passwordResponce.status === "error" && "red",
                      }}
                    >
                      {passwordResponce.message}
                    </p>

                    <span
                      onClick={() => {
                        seteyesTogel({
                          ...eyeTogel,
                          currentPassword: !eyeTogel.currentPassword,
                        });
                      }}
                      className={`eyebtn ${!eyeTogel.currentPassword ? " " : " eyeopen"}`}
                    ></span>
                  </div>
                  <div className={`fieldWrap ${fieldValidation.newPassword ? " error " : ""} `}>
                    <label className="go-to-label-filed">
                      Enter New Password
                      {field.newPassword && fieldValidation.newPassword ? (
                        <>
                          {
                            <img
                              className="errorIcon popup-label"
                              src={errorIcon}
                              alt=""
                              style={{ marginLeft: "2%", marginBottom: "-5px" }}
                            />
                          }
                          <div className="popup-content">
                            <span style={smallTextStyle}>
                              <p
                                style={{
                                  fontSize: "10px",
                                  lineHeight: "13px",
                                  color: isPasswordCorrect.isValidPassword ? "green" : "red",
                                }}
                                className="small-text"
                              >
                                Password should be 10 digits alphanumeric with at Least:
                              </p>
                              <li
                                style={{
                                  color: isPasswordCorrect.upper ? "green" : "red",
                                }}
                                className="small-text"
                              >
                                1 Upper Case Character,
                              </li>
                              <li
                                style={{
                                  color: isPasswordCorrect.lower ? "green" : "red",
                                }}
                                className="small-text"
                              >
                                1 Lower Case Character,
                              </li>
                              <li
                                style={{
                                  color: isPasswordCorrect.numeric ? "green" : "red",
                                }}
                                className="small-text"
                              >
                                1 Numeric Character and
                              </li>
                              <li
                                style={{
                                  color: isPasswordCorrect.special ? "green" : "red",
                                }}
                                className="small-text"
                              >
                                1 Special Character.
                              </li>
                            </span>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </label>
                    <input
                      type={eyeTogel.newPassword ? "text" : "password"}
                      placeholder="Enter New Password"
                      onChange={(e) => {
                        CheckPasswordValidation(e);
                        setField({ ...field, newPassword: e.target.value });
                        setFieldValidation({
                          ...fieldValidation,
                          newPassword: !passPattern.test(e.target.value) ? "*Please enter valid password" : "",
                          currentPassword: !field.currentPassword && "*Please enter current password",
                          reNewPassword:
                            field.reNewPassword && e.target.value !== field.reNewPassword && "*Password does not match",
                        });
                      }}
                      value={field.newPassword}
                      disabled={disable}
                      //disabled={passwordResponce.status == "error"}
                    />
                    <span
                      onClick={() => {
                        seteyesTogel({
                          ...eyeTogel,
                          newPassword: !eyeTogel.newPassword,
                        });
                      }}
                      className={`eyebtn ${!eyeTogel.newPassword ? " " : " eyeopen"}`}
                    ></span>
                  </div>
                  <div className={`fieldWrap ${fieldValidation.reNewPassword ? " error " : ""} `}>
                    <label className="go-to-label-filed">Re-Enter New Password</label>
                    <input
                      type={eyeTogel.reNewPassword ? "text" : "password"}
                      name="cNPassword"
                      placeholder="Re-Enter New Password"
                      onChange={(e) => {
                        setField({ ...field, reNewPassword: e.target.value });
                        setFieldValidation({
                          ...fieldValidation,
                          reNewPassword: e.target.value !== field.newPassword ? "*Password does not match" : "",
                          currentPassword: !field.currentPassword && "*Please enter current password",
                        });
                      }}
                      value={field.reNewPassword}
                      disabled={disable}
                      //disabled={passwordResponce.status == "error"}
                    />
                    <p className="error" style={{ color: "red" }}>
                      {fieldValidation.reNewPassword}
                    </p>
                    <span
                      onClick={() => {
                        seteyesTogel({
                          ...eyeTogel,
                          reNewPassword: !eyeTogel.reNewPassword,
                        });
                      }}
                      className={`eyebtn ${!eyeTogel.reNewPassword ? " " : " eyeopen"}`}
                    ></span>
                  </div>
                </form>
                <div className="actionButton">
                  <button
                    className="btn btnlightblue"
                    onClick={handleReset2}
                    style={{
                      width: "77px",
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={resetSubmit}
                    style={{
                      width: "190px",
                    }}
                    className={`btn btnblue largebuton ${
                      disable ||
                      !field.currentPassword ||
                      !field.newPassword ||
                      !field.reNewPassword ||
                      fieldValidation.newPassword ||
                      fieldValidation.reNewPassword ||
                      fieldValidation.currentPassword
                        ? " disabled "
                        : ""
                    }`}
                  >
                    Change Password{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
