import { useEffect, useState } from "react";
import DocumentWizardDockets from "./DocumentWizardTab/DocumentWizardDockets";
import ConditionsViolations from "./DocumentWizardTab/ConditionsViolations";
import DocumentWizardParameters from "./DocumentWizardTab/DocumentWizardParameters";
import { DocumentWizardFunction } from "./DocumentWizardFunction";
import { useNavigate } from "react-router-dom";

const DocumentWizardSubTab = ({
  documentWizardInfo,
  loading,
  setLoading,
  editToggle,
  htmlData,
  setHtmlData,
  parametersFields,
  setParametersFields,
  sharequeryshow,
  personId,
  setSelectedDocketsValidation,
  fields,
  documentWizardOutputData,
  changeChildDocument,
  childHtmlData,
  setReportsDropdownData,
}) => {
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [documentWizardDocketsTableData, setDocumentWizardDocketsTableData] =
    useState([]);
  const [docketsRowTableData, setDocketsRowTableData] = useState([]);
  const [conditionsViolationsTableData, setConditionsViolationsTableData] =
    useState([]);
  const [
    conditionsViolationsRowTableData,
    setConditionsViolationsRowTableData,
  ] = useState([]);
  const [gSentenceCourtEntityId, setGSentenceCourtEntityId] = useState("");
  const ApiClass = new DocumentWizardFunction();
  const navigate = useNavigate();

  useEffect(() => {
    ApiClass.getDocumentWizardDocketTableData(
      documentWizardInfo?.gPersonId,
      navigate,
      setDocumentWizardDocketsTableData,
      setLoading
    );
  }, []);

  useEffect(() => {
    if (docketsRowTableData?.length > 0) {
      const docketsSelectedData = {
        personID: personId,
        selectedDockets: docketsRowTableData?.map((item) => {
          return item?.gSentenceCourtEntityId;
        }),
      };
      const latestData = docketsRowTableData[docketsRowTableData?.length - 1];
      setGSentenceCourtEntityId(latestData?.gSentenceCourtEntityId);
      setParametersFields((prev) => ({
        ...prev,
        "[COURTNAME]": latestData?.["Court Name"],
        "[DocketList]": latestData?.["Docket/Count"]?.replace(/-+\d*$/, ""),
        "[OffenseList]": latestData?.["Offense"],
      }));
      const uniqueOffenseValues = new Set(
        docketsRowTableData?.map((item) => item?.gSentenceCourtEntityId)
      );
      if (uniqueOffenseValues?.size === 1) {
        setSelectedDocketsValidation("");
        ApiClass.getConditionsAndViolationsData(
          docketsSelectedData,
          setLoading,
          navigate,
          setConditionsViolationsTableData
        );
      } else {
        setSelectedDocketsValidation(
          "Cannot select Dockets from multiple Courts"
        );
      }
    } else {
      setParametersFields((prev) => ({
        ...prev,
        "[COURTNAME]": "",
        "[DocketList]": "",
        "[OffenseList]": "",
      }));
      setConditionsViolationsTableData([]);
      setSelectedDocketsValidation("");
      setGSentenceCourtEntityId("");
    }
  }, [docketsRowTableData]);

  useEffect(() => {
    if (conditionsViolationsRowTableData?.length > 0) {
      const conditions = conditionsViolationsRowTableData?.map(
        (item) => item.Condition
      );
      const violations = conditionsViolationsRowTableData?.map(
        (item) => item.Violations
      );
      setParametersFields((prev) => ({
        ...prev,
        "[ConditionList]": conditions?.join("<br>"),
        "[ViolationList]": violations?.join("<br>"),
      }));
    } else {
      setParametersFields((prev) => ({
        ...prev,
        "[ConditionList]": "",
        "[ViolationList]": "",
      }));
    }
  }, [conditionsViolationsRowTableData]);

  useEffect(() => {
    if (documentWizardOutputData && activeTab !== 3) {
      var html2 = documentWizardOutputData?.sReportRtf;
      for (const fieldName in parametersFields) {
        const fieldValue = parametersFields[fieldName];
        var replacement = fieldValue ? fieldValue : fieldName;
        var escapedPattern = fieldName?.replace(
          /[-\/\\^$*+?.()|[\]{}]/g,
          "\\$&"
        );
        var regex = new RegExp(escapedPattern, "g");
        html2 = html2?.replace(regex, replacement);
      }
      setHtmlData(html2);
      if (childHtmlData) {
        changeChildDocument(parametersFields);
      }
    }
  }, [parametersFields, documentWizardOutputData]);

  useEffect(() => {
    if (fields?.showCourt) {
      const uniqueOffenseValues = new Set(
        docketsRowTableData?.map((item) => item?.gSentenceCourtEntityId)
      );
      if (uniqueOffenseValues?.size === 1) {
        ApiClass.getShowCourtDocumentWizardDropdown(
          navigate,
          setReportsDropdownData,
          setLoading,
          gSentenceCourtEntityId
        );
      } else {
        const allIds = documentWizardDocketsTableData?.map(
          (item) => item?.gSentenceCourtEntityId
        );
        ApiClass.getShowCourtDocumentWizardDropdown(
          navigate,
          setReportsDropdownData,
          setLoading,
          allIds
        );
      }
    }
  }, [fields?.showCourt]);

  useEffect(() => {
    if (conditionsViolationsRowTableData?.length > 0) {
      var regex = /\[([^\]]*)\]/g;
      var matches = [];
      var match;
      var uniqueNames = new Set();
      while ((match = regex.exec(htmlData)) !== null) {
        var name = match[1];
        if (!uniqueNames.has(name)) {
          matches.push(match[0]);
          uniqueNames.add(name);
        }
      }
      const parametersFieldsObject = matches.reduce((acc, item) => {
        acc[item] = "";
        return acc;
      }, {});
      setParametersFields((prev) => ({
        ...prev,
        ...parametersFieldsObject,
      }));
    }
  }, [htmlData]);
  return (
    <>
      <div className="goto-obligation-sub-tab-container">
        <div className="goto-obligation-sub-tab">
          <button
            className={`goto-obligation-button ${
              activeTab === 1 ? "goto-obligation-button-active" : ""
            }`}
            onClick={() => handleTabClick(1)}
          >
            Dockets
          </button>
          {(conditionsViolationsTableData?.length > 0 ||
            fields?.reports?.id === "") && (
            <button
              className={`goto-obligation-button ${
                activeTab === 2 ? "goto-obligation-button-active" : ""
              }`}
              onClick={() => handleTabClick(2)}
            >
              Conditions and Violations
            </button>
          )}
          <button
            className={`goto-obligation-button ${
              activeTab === 3 ? "goto-obligation-button-active" : ""
            }`}
            onClick={() => handleTabClick(3)}
          >
            Parameters
          </button>
        </div>
        <div className="commonBlock_bottom_border"></div>
        <div className="document-wizard-sub-tab">
          {activeTab === 1 && (
            <DocumentWizardDockets
              documentWizardDocketsTableData={documentWizardDocketsTableData}
              setDocketsRowTableData={setDocketsRowTableData}
              editToggle={editToggle}
              sharequeryshow={sharequeryshow}
              fields={fields}
            />
          )}
          {activeTab === 2 && (
            <ConditionsViolations
              conditionsViolationsTableData={conditionsViolationsTableData}
              setConditionsViolationsTableData={
                setConditionsViolationsTableData
              }
              setConditionsViolationsRowTableData={
                setConditionsViolationsRowTableData
              }
              editToggle={editToggle}
              sharequeryshow={sharequeryshow}
              fields={fields}
              docketsRowTableData={docketsRowTableData}
              personId={personId}
            />
          )}
          {activeTab === 3 && (
            <DocumentWizardParameters
              parametersFields={parametersFields}
              setParametersFields={setParametersFields}
              htmlData={htmlData}
              personId={personId}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DocumentWizardSubTab;
