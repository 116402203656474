import {
  LoadPanel,
  NumberBox,
  ScrollView,
  TextBox,
  Toast,
} from "devextreme-react";
import { handleGetLocal } from "../../../services/auth-service";
import { useRef, useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setrecevePaymentReference } from "../../../reducer/ParticipateReducer";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../components/Logout";

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

const DropDown = ({ dropDownData }) => {
  const [togal, setTogal] = useState(false);
  const [State, setState] = useState("Select");

  const ref = useRef();
  useOutsideClick(ref, () => {
    setTogal(false);
  });

  return (
    <>
      <div style={{ marginTop: "3%" }}>
        <div style={{ position: "relative" }}>
          <div
            ref={ref}
            onClick={() => {
              setTogal(!togal);
            }}
            style={{
              display: "flex",
              border: "1px solid #e6e6e6",
              borderRadius: "3px",
              cursor: "pointer",
            }}
          >
            <div
              className="select_input_block"
              style={{ webkitAppearance: "none" }}
            >
              <p style={{ marginTop: "5%" }}>{State}</p>
            </div>
            <svg
              width="8"
              style={{ marginTop: "16px", marginRight: "5%" }}
              height="5"
              viewBox="0 0 8 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z"
                fill="#0F172A"
              />
            </svg>
          </div>
          {togal && (
            <div
              style={{
                height: dropDownData?.length <= 4 && "80px",
                width: "100%",
              }}
              className="dropdownn"
            >
              <ScrollView style={{ height: "130px", width: "100%" }}>
                {dropDownData?.map((val) => {
                  return (
                    <>
                      <p
                        onClick={() => {
                          setState(val.name);
                          setTogal(false);
                        }}
                      >
                        {val.name}
                      </p>
                    </>
                  );
                })}
              </ScrollView>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const ProcessCCPayment = ({
  editParticipant,
  settopPopup,
  userinfo,
  setRecivePaymentModel,
  toastConfig,
  setToastConfig,
}) => {
  const componentRef = useRef(null);
  const pathname = handleGetLocal("pathURL");

  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    firstName: userinfo?.sFirstName,
    LastName: userinfo?.sLastName,
    Address: userinfo?.sHomeAddress1,
    City: userinfo?.sHomeCity,
    State: userinfo?.sHomeState,
    ZipCode: userinfo?.sHomeZip,
    Cardnumber: "",
    Expirymonth: "",
    ExpiryYear: "",
    CVV: "",
    AmounttoCharge: "",
    Noconveniencefee: null,
  });

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setFields({
          firstName: userinfo?.sFirstName,
          LastName: userinfo?.sLastName,
          Address: userinfo?.sHomeAddress1,
          City: userinfo?.sHomeCity,
          State: userinfo?.sHomeState,
          ZipCode: userinfo?.sHomeZip,
          Cardnumber: "",
          Expirymonth: "",
          ExpiryYear: "",
          CVV: "",
          AmounttoCharge: "",
          Noconveniencefee: "",
        });
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [userinfo]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const sendPayment = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Payment/ProcessCreditCardTransaction`,
        {
          gPersonID: userinfo?.gPersonId,
          gCashDrawer: null,
          gBankAccountID: null,
          sFirstName: fields.firstName,
          sLastName: fields.LastName,
          sAddress1: fields.Address,
          sAddress2: null,
          sState: fields.State,
          sZip: fields.ZipCode,
          sCreditCard: fields.Cardnumber,
          sExpiration: `${fields.ExpiryYear}/${fields.Expirymonth}`,
          sCVV: fields.CVV,
          fAmount: fields.AmounttoCharge,
          bNoConvenieneFee: fields.Noconveniencefee,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val.data.isSuccess) {
          dispatch(
            setrecevePaymentReference(val.data.data.sAuthorizationNumber)
          );
          settopPopup(1);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: val.data.data.sErrorMessage,
            type: "error",
          });

          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: val.data.data.sErrorMessage,
              type: "error",
            });
          }, 1400);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />

      <ScrollView style={{ width: "100%", height: "647px" }}>
        <div ref={componentRef} className="RecivePaymentModel">
          <div className="RecivePaymentModel_header">
            <div
              style={{ width: "35%" }}
              className="RecivePaymentModel_header_name"
            >
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM17 14H3C2.45 14 2 13.55 2 13V8H18V13C18 13.55 17.55 14 17 14ZM18 4H2V2H18V4Z"
                  fill="white"
                />
              </svg>

              <p>Process CC payment</p>
              <span
                style={{
                  padding: "2px 9px",
                  gap: "8px",
                  width: "45px",
                  height: "24px",
                  background: "#FEF2F2",
                  borderRadius: "4px",
                }}
              >
                <p
                  style={{
                    color: "#DC2626",
                    fontFamily: "Readex Pro",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "10px",
                    lineHeight: "20px",
                    width: "21px",
                    height: "20px",
                  }}
                >
                  PY.6
                </p>
              </span>
            </div>
            <svg
              onClick={() => {
                setRecivePaymentModel(false);
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="paymentcc">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="RecivePaymentModel_input_block_">
                <p>First Name</p>

                <TextBox
                  style={{ marginTop: "3%" }}
                  onValueChange={(e) => {
                    setFields({
                      ...fields,
                      firstName: e,
                    });
                  }}
                  isValid={true}
                  value={fields.firstName}
                  placeholder={"First Name"}
                />
              </div>
              <div className="RecivePaymentModel_input_block_">
                <p>Last Name / Reference</p>

                <TextBox
                  style={{ marginTop: "3%" }}
                  isValid={true}
                  placeholder={"Last Name / Reference"}
                  value={fields.LastName}
                  onValueChange={(e) => {
                    setFields({
                      ...fields,
                      LastName: e,
                    });
                  }}
                />
              </div>
            </div>
            <div
              style={{ width: "100%" }}
              className="RecivePaymentModel_input_block_"
            >
              <p>Address</p>

              <TextBox
                style={{ marginTop: "3%" }}
                isValid={true}
                placeholder={"Address"}
                value={fields.Address}
                onValueChange={(e) => {
                  setFields({
                    ...fields,
                    Address: e,
                  });
                }}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="RecivePaymentModel_input_block_">
                <p>City</p>

                <TextBox
                  style={{ marginTop: "3%" }}
                  isValid={true}
                  placeholder={"City"}
                  value={fields.City}
                  onValueChange={(e) => {
                    setFields({
                      ...fields,
                      City: e,
                    });
                  }}
                />
              </div>
              <div className="RecivePaymentModel_input_block_">
                <p>State</p>

                <TextBox
                  style={{ marginTop: "3%" }}
                  isValid={true}
                  placeholder={"State"}
                  value={fields.State}
                  onValueChange={(e) => {
                    setFields({
                      ...fields,
                      State: e,
                    });
                  }}
                />
              </div>
            </div>
            <div
              style={{ width: "48%" }}
              className="RecivePaymentModel_input_block_"
            >
              <p>Zip Code</p>

              <NumberBox
                onKeyDown={(e) => {
                  const { event } = e;
                  const str = event.key || String.fromCharCode(event.which);
                  if (/^[.,e]$/.test(str)) {
                    event.preventDefault();
                  }
                }}
                value={fields.ZipCode}
                min={0}
                placeholder={"Zip Code"}
              />
            </div>
          </div>
          <div className="paymentcc_cardentry">
            <div
              style={{
                width: "96%",
                marginLeft: "2%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1.6%",
              }}
            >
              <div style={{ width: "28%", marginTop: "2px" }}>
                <p
                  style={{
                    color: "var(--TextColor)",
                    fontWeight: "530",
                    fontSize: "12.7px",
                  }}
                >
                  Card number
                </p>
                <TextBox
                  style={{ marginTop: "3%" }}
                  isValid={true}
                  placeholder={"Card number"}
                  value={fields.Cardnumber}
                  onValueChange={(e) => {
                    setFields({
                      ...fields,
                      Cardnumber: e,
                    });
                  }}
                />
              </div>
              <div
                style={{
                  width: "70%",
                  display: "flex",
                  marginTop: ".5%",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "32%" }}>
                  <p
                    style={{
                      color: "var(--TextColor)",
                      fontWeight: "530",
                      fontSize: "12.7px",
                    }}
                  >
                    Expiry month
                  </p>
                  <TextBox
                    style={{ marginTop: "3%" }}
                    onValueChange={(e) => {
                      setFields({
                        ...fields,
                        Expirymonth: e,
                      });
                    }}
                    isValid={true}
                    placeholder={"Expiry month"}
                    value={fields.Expirymonth}
                  />
                </div>
                <div style={{ width: "32%" }}>
                  <p
                    style={{
                      color: "var(--TextColor)",
                      fontWeight: "530",
                      fontSize: "12.7px",
                    }}
                  >
                    Expiry year
                  </p>
                  <TextBox
                    style={{ marginTop: "3%" }}
                    onValueChange={(e) => {
                      setFields({
                        ...fields,
                        ExpiryYear: e,
                      });
                    }}
                    isValid={true}
                    placeholder={"Expiry year"}
                    value={fields.ExpiryYear}
                  />
                </div>
                <div style={{ width: "32%" }}>
                  <p
                    style={{
                      color: "var(--TextColor)",
                      fontWeight: "530",
                      fontSize: "12.7px",
                    }}
                  >
                    CVV
                  </p>
                  <TextBox
                    style={{ marginTop: "3%" }}
                    onValueChange={(e) => {
                      setFields({
                        ...fields,
                        CVV: e,
                      });
                    }}
                    isValid={true}
                    placeholder={"CVV"}
                    value={fields.CVV}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", margin: "1.5%" }}>
            <div style={{ width: "30%" }}>
              <p
                style={{
                  color: "var(--TextColor)",
                  fontWeight: "530",
                  fontSize: "12.7px",
                }}
              >
                Amount to Charge
              </p>
              <TextBox
                style={{ marginTop: "3%" }}
                onValueChange={(e) => {
                  setFields({
                    ...fields,
                    AmounttoCharge: e,
                  });
                }}
                isValid={true}
                placeholder={"Amount to Charge"}
                value={fields.AmounttoCharge}
              />
            </div>
            <div style={{ width: "30%", marginLeft: "3%" }}>
              <div
                style={{
                  marginTop: "18%",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <input
                  type="checkbox"
                  onClick={() => {
                    setFields({
                      ...fields,
                      Noconveniencefee: !fields.Noconveniencefee,
                    });
                  }}
                />
                <label style={{ color: "var(--TextColor)" }}>
                  No convenience fee
                </label>
              </div>
            </div>
          </div>
          <div
            className="popup_buttons"
            style={{ marginTop: "20px", marginRight: "20px" }}
          >
            <button
              className="btn"
              onClick={() => {
                setRecivePaymentModel(false);
              }}
            >
              Cancel
            </button>
            <button
              className="primary_btn"
              onClick={() => {
                sendPayment();
              }}
            >
              Process payment
            </button>
          </div>
        </div>
      </ScrollView>
    </>
  );
};
