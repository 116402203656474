import { DateBox } from "devextreme-react";
import { ColumnDropdown } from "../../../components/Dropdownn";
import { useState } from "react";
import { useEffect } from "react";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
      />
    </>
  );
};

const ImportEquipment = ({
  importEquipmentPopup,
  setImportEquipmentPopup,
  vendorDropdownData,
  equipmentTypeDropdownData,
  equipmentStatusDropdownData,
  equipmentLocationDropdownData,
}) => {
  const [file, setFile] = useState(null);
  const [fields, setFields] = useState({
    vendor: { name: "", id: "" },
    equipmentType: { name: "", id: "" },
    status: { name: "", id: "" },
    location: { name: "", id: "" },
    description: "",
    dateReceived: "",
    spreadsheet: false,
  });
  const [vendorErrorValidation, setVendorErrorValidation] = useState("");
  const [equipmentTypeErrorValidation, setEquipmentTypeErrorValidation] = useState("");
  const [statusErrorValidation, setStatusErrorValidation] = useState("");
  const [locationErrorValidation, setLocationErrorValidation] = useState("");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleFileRemove = () => {
    setFile(null);
  };

  useEffect(() => {
    setVendorErrorValidation("");
    setEquipmentTypeErrorValidation("");
    setStatusErrorValidation("");
    setLocationErrorValidation("");
    setFields({
      vendor: { name: "", id: "" },
      equipmentType: { name: "", id: "" },
      status: { name: "", id: "" },
      location: { name: "", id: "" },
      description: "",
      dateReceived: "",
      spreadsheet: false,
    });
    setFile(null);
  }, [importEquipmentPopup]);

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSave = () => {
    let isValid = true;
    if (fields?.vendor?.id === "") {
      isValid = false;
      setVendorErrorValidation("Please Select Vendor.");
    }
    if (fields?.equipmentType?.id === "") {
      isValid = false;
      setEquipmentTypeErrorValidation("Please Select Equipment Type.");
    }
    if (fields?.status?.id === "") {
      isValid = false;
      setStatusErrorValidation("Please Select Status.");
    }
    if (fields?.location?.id === "") {
      isValid = false;
      setLocationErrorValidation("Please Select Location.");
    }
  };

  return (
    <>
      <div className="import-equipment">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_287_323334)">
                  <path d="M0 0H24V24H0V0Z" fill="" />
                  <path
                    d="M10 16.0002H14C14.55 16.0002 15 15.5502 15 15.0002V10.0002H16.59C17.48 10.0002 17.93 8.92021 17.3 8.29021L12.71 3.70021C12.32 3.31021 11.69 3.31021 11.3 3.70021L6.71 8.29021C6.08 8.92021 6.52 10.0002 7.41 10.0002H9V15.0002C9 15.5502 9.45 16.0002 10 16.0002ZM6 18.0002H18C18.55 18.0002 19 18.4502 19 19.0002C19 19.5502 18.55 20.0002 18 20.0002H6C5.45 20.0002 5 19.5502 5 19.0002C5 18.4502 5.45 18.0002 6 18.0002Z"
                    fill="#fff"
                  />
                </g>
              </svg>
              <p>Equipment Import</p>
            </div>
            <p class="popup_header_title_para">EM.23</p>
          </div>
          <svg
            onClick={() => {
              setImportEquipmentPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="popup-container-data">
          <div className="import-field-flex">
            <div className="edit_content_flex">
              <div className="edit_content_flex edit_content_space">
                <p className="edit_container_label">
                  Vendor <span className="marks_mandatary">*</span>
                </p>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"vendor"}
                  dropDownData={vendorDropdownData}
                  reset={setVendorErrorValidation}
                />
                {vendorErrorValidation && (
                  <p className="error_validation" style={{ position: "absolute", top: "145px" }}>
                    {vendorErrorValidation}
                  </p>
                )}
              </div>
              <div className="edit_content_flex edit_content_space import-margin-top">
                <p className="edit_container_label">
                  Status <span className="marks_mandatary">*</span>
                </p>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"status"}
                  dropDownData={equipmentStatusDropdownData}
                  reset={setStatusErrorValidation}
                />
                {statusErrorValidation && (
                  <p className="error_validation" style={{ position: "absolute", top: "245px" }}>
                    {statusErrorValidation}
                  </p>
                )}
              </div>
            </div>
            <div className="edit_content_flex">
              <div className="edit_content_flex edit_content_space">
                <p className="edit_container_label">
                  Equipment Type <span className="marks_mandatary">*</span>
                </p>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"equipmentType"}
                  dropDownData={equipmentTypeDropdownData}
                  reset={setEquipmentTypeErrorValidation}
                />
                {equipmentTypeErrorValidation && (
                  <p className="error_validation" style={{ position: "absolute", top: "145px" }}>
                    {equipmentTypeErrorValidation}
                  </p>
                )}
              </div>
              <div className="edit_content_flex edit_content_space import-margin-top">
                <p className="edit_container_label">
                  Location <span className="marks_mandatary">*</span>
                </p>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"location"}
                  dropDownData={equipmentLocationDropdownData}
                  reset={setLocationErrorValidation}
                />
                {locationErrorValidation && (
                  <p className="error_validation" style={{ position: "absolute", top: "245px" }}>
                    {locationErrorValidation}
                  </p>
                )}
              </div>
            </div>
            <div className="edit_container_second edit_content_flex">
              <p className="edit_container_label">Description</p>
              <textarea
                name="description"
                id="description"
                placeholder="Write description"
                className="dark-mode-textarea"
                style={{ width: "284px", height: "145px" }}
                value={fields?.description}
                onChange={handleInputChange}
              ></textarea>
            </div>
          </div>
          <div className="import-field-flex" style={{ alignItems: "baseline" }}>
            <div className="edit_content_flex edit_content_space import-margin-top">
              <p className="edit_container_label">Date Received</p>
              <DateBox
                useMaskBehavior={true}
                value={fields.dateReceived}
                onValueChange={(e) => {
                  if (e) {
                    const initialDateString = e;
                    const initialDate = new Date(initialDateString);
                    const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                    const utcFormatted = new Date(utcTime).toISOString();
                    setFields((prev) => ({
                      ...prev,
                      dateReceived: e ? utcFormatted : null,
                    }));
                  }
                }}
              />
            </div>
            <div className="edit_content_flex import-margin-top">
              <p className="edit_container_label">
                File to import <span className="marks_mandatary">*</span>
              </p>
              <div className="file-upload">
                <div className="file-select">
                  <div className="file-select-name">{file ? file.name : "No File Chosen"}</div>
                  <div>
                    <input
                      type="file"
                      name="files[]"
                      className="profileimg"
                      title={file ? file.name : ""}
                      onChange={handleFileChange}
                    />
                    <svg
                      className="svg-blue-white"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_287_323334)">
                        <path
                          d="M10 16.0002H14C14.55 16.0002 15 15.5502 15 15.0002V10.0002H16.59C17.48 10.0002 17.93 8.92021 17.3 8.29021L12.71 3.70021C12.32 3.31021 11.69 3.31021 11.3 3.70021L6.71 8.29021C6.08 8.92021 6.52 10.0002 7.41 10.0002H9V15.0002C9 15.5502 9.45 16.0002 10 16.0002ZM6 18.0002H18C18.55 18.0002 19 18.4502 19 19.0002C19 19.5502 18.55 20.0002 18 20.0002H6C5.45 20.0002 5 19.5502 5 19.0002C5 18.4502 5.45 18.0002 6 18.0002Z"
                          fill="#424167"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                {file && (
                  <div className="file-remove" onClick={handleFileRemove}>
                    <svg
                      className="svg-blue-white"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_287_323337)">
                        <path
                          d="M13.7241 4.28254C13.4316 3.99004 12.9591 3.99004 12.6666 4.28254L8.99906 7.94254L5.33156 4.27504C5.03906 3.98254 4.56656 3.98254 4.27406 4.27504C3.98156 4.56754 3.98156 5.04004 4.27406 5.33254L7.94156 9.00004L4.27406 12.6675C3.98156 12.96 3.98156 13.4325 4.27406 13.725C4.56656 14.0175 5.03906 14.0175 5.33156 13.725L8.99906 10.0575L12.6666 13.725C12.9591 14.0175 13.4316 14.0175 13.7241 13.725C14.0166 13.4325 14.0166 12.96 13.7241 12.6675L10.0566 9.00004L13.7241 5.33254C14.0091 5.04754 14.0091 4.56754 13.7241 4.28254Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_287_323337">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                )}
              </div>
              <p className="edit_container_label" style={{ color: "red", fontWeight: "400" }}>
                Note : First column must contain serial number
              </p>
              <div className="import-field-flex">
                <input
                  id="spreadsheet"
                  name="spreadsheet"
                  type="checkbox"
                  checked={fields?.spreadsheet}
                  onChange={handleInputChange}
                />
                <label htmlFor="spreadsheet" className="edit_container_label">
                  Spreadsheet has header row
                </label>
              </div>
            </div>
          </div>
          <div className="popup_buttons">
            <button className="btn primary_btn" onClick={handleSave}>
              Save
            </button>
            <button
              className="btn"
              onClick={() => {
                setImportEquipmentPopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportEquipment;
