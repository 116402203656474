import axios from "axios";
import { logOut } from "../../../../../../components/Logout";
import { handleGetLocal } from "../../../../../../services/auth-service";
export class GoToSupervisorFunction{

    constructor(){
        this.pathname = handleGetLocal("pathURL");
    }




async goToSupervisorApiCall(
    setLoad,
    seterrorhandling,
    setData,
    navigate,
    docketValue
  ) {
    setLoad(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/docket/get-docket-supervisors?docketId=${docketValue?.getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoad(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          setData(this.setSupervisorData(val?.data?.data));
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoad(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  }

  setSupervisorData(data) {
    const arr = [];

    data.map((val, i) => {
      arr.push({
        
        Type: val?.supervisorType,

        Supervisor: val?.supervisorName,

        ID: val?.supervisorId,
        gDocketSupervisorId:val?.gDocketSupervisorId
      });
    });

    return arr;
  }
}
