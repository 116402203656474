import axios from "axios";
import { ColumnDropdown } from "../../components/Dropdownn";
import { handleGetLocal } from "../../services/auth-service";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LoadPanel } from "devextreme-react";
import { seton_Save_Edit } from "../../reducer/participantNoteReducer";
import { useDispatch } from "react-redux";
import { logOut } from "../../components/Logout";
import { useNavigate } from "react-router-dom";

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  setCategoryValidaition,
  fieldNmae,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        reset={setCategoryValidaition}
      />
    </>
  );
};

export const SelectLocationPopup = ({ setSelectLocationPopupopen, text }) => {
  const [loading, setLoading] = useState(false);
  const { user_data } = useSelector((store) => store.Home);
  const [locationEntity, setlocationEntity] = useState([]);
  const pathname = handleGetLocal("pathURL");
  const [cashDrawers, setcashDrawers] = useState([]);
  const [fields, setfields] = useState({
    locationEntity: {
      name: user_data?.sLocationName ? user_data?.sLocationName : "",
      id: user_data?.gLocationId ? user_data?.gLocationId : "",
    },
    cashDrawers: {
      name: user_data?.sCashDrawerName ? user_data?.sCashDrawerName : "",
      id: user_data?.gCashDrawerId ? user_data?.gCashDrawerId : "",
    },
  });
  const { resetReportDates, on_Save_Edit } = useSelector(
    (store) => store.participateNotes
  );

  const [validation, setvalidation] = useState("");
  const navigate = useNavigate();

  const getDropDown = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/PersonReportDates/masterDropdownValues`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setlocationEntity(
          val.data.data.locationEntity.map((vall) => {
            return { name: vall.value, id: vall.key };
          })
        );
        setcashDrawers(
          val.data.data.cashDrawers.map((vall) => {
            return { name: vall.value, id: vall.key };
          })
        );
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };
  const dispatch = useDispatch();

  const OnSave = async () => {
    setLoading(true);
    await axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/Account/user-location-or-cashdrawer?userId=${user_data.gUserId}${
          fields.locationEntity.id && `&locationId=${fields.locationEntity.id}`
        }${fields.cashDrawers.id && `&cashDrawerId=${fields.cashDrawers.id}`}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        dispatch(seton_Save_Edit(!on_Save_Edit));
        ResetValue();
        setSelectLocationPopupopen(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const Save = () => {
    if (text === "Select Cash Drawer") {
      if (fields.cashDrawers.id) {
        setvalidation("");
        OnSave();
      } else {
        setvalidation("Please Select Cash Drawer");
      }
    } else {
      if (fields.locationEntity.id) {
        setvalidation("");
        OnSave();
      } else {
        setvalidation("Please Select Location");
      }
    }
  };

  useEffect(() => {
    getDropDown();
  }, []);

  const ResetValue = () => {
    setvalidation("");
    setfields({
      locationEntity: {
        name: user_data?.sLocationName ? user_data?.sLocationName : "",
        id: user_data?.gLocationId ? user_data?.gLocationId : "",
      },
      cashDrawers: {
        name: user_data?.sCashDrawerName ? user_data?.sCashDrawerName : "",
        id: user_data?.gCashDrawerId ? user_data?.gCashDrawerId : "",
      },
    });
  };

  useEffect(() => {
    ResetValue();
  }, [resetReportDates]);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div
        style={{ border: "3px solid var(--button2)", height: "248px" }}
        className="PersonModalbody"
      >
        <div className="PersonModal">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: text === "Select Cash Drawer" ? "37%" : "33%",
              margin: ".8%",
            }}
            className="modelHeader"
          >
            <p
              style={{
                marginTop: "3%",
                color: "white",
                fontWeight: "500",
                fontSize: "13px",
              }}
            >
              {text}{" "}
            </p>
            <span
              style={{
                padding: "2px 9px",
                gap: "8px",
                width: "45px",
                height: "24px",
                background: "#FEF2F2",
                borderRadius: "4px",
                marginTop: "2.5%",
              }}
            >
              <p
                style={{
                  color: "#DC2626",
                  fontFamily: "Readex Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "10px",
                  lineHeight: "20px",
                  width: "21px",
                  height: "20px",
                }}
              >
                LG.2
              </p>
            </span>
          </div>
          <span
            style={{
              fontSize: "30px",
              marginRight: "2%",
              cursor: "pointer",
              color: "white",
            }}
            onClick={() => {
              ResetValue();
              setSelectLocationPopupopen(false);
            }}
          >
            &times;
          </span>
        </div>
        <div style={{ width: "94%", marginLeft: "3%" }}>
          <div style={{ marginTop: "5%" }}>
            <p className="go-to-label-filed">{text}</p>
            <DropDown
              dropDownData={
                text == "Select Cash Drawer" ? cashDrawers : locationEntity
              }
              setfields={setfields}
              fields={fields}
              fieldNmae={
                text !== "Select Cash Drawer" ? "locationEntity" : "cashDrawers"
              }
              setCategoryValidaition={setvalidation}
            />
            <p style={{ color: "red", position: "absolute" }}>{validation}</p>
          </div>
          <div className="popup_buttons" style={{ marginTop: "50px" }}>
            <button
              className="btn primary_btn"
              onClick={() => {
                Save();
              }}
            >
              Done
            </button>
            <button
              onClick={() => {
                setSelectLocationPopupopen(false);
              }}
              className="btn"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
