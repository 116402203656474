import React, { useEffect, useState } from "react";
import { Table2 } from "../../../../../../components/Table2/Table2";

const ChildDocketColumn = [{ title: "Docket #" }];

export const GoToChildDocket = ({ checklistData, loadingg }) => {
  return (
    <>
      <div style={{ marginBottom: "2%" }} className="go-to-child-docket">
        <Table2
          Tablecoloum={ChildDocketColumn}
          tableRow={checklistData}
          load={loadingg}
          coloumWidthText={"goToChildDocket"}
          height={400}
          editTable={false}
          mandatoryColoum={"Docket #"}
          GoToChildDocketrightClick={true}
        />
      </div>
    </>
  );
};
