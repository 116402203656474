import React, { useState } from "react";
import ChangePsswd from "../changePsswd/changePsswd.js";
import logo from "../../../assets/images/logo.png";
import httpService from "../../../services/http-service.js";
import { useNavigate } from "react-router-dom";
import { Toster } from "../../../components/Toster/Toster.js";
import { LoadPanel } from "devextreme-react";

export default function ForgotPsswd() {
  const [fields, setFields] = useState({});
  const [isDisable, setIsDisable] = useState(true);
  const [emailresponce, setResponce] = useState("");
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    let field = fields;
    if (event.target.name == "email") {
      var mailPattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (!mailPattern.test(event.target.value)) {
        setIsDisable(true);
      } else {
        setIsDisable(false);
      }
    }
    field[event.target.name] = event.target.value;
    setFields({ ...field });
  };

  const [errormessage, seterrormessage] = useState("");

  const validateLogin = () => {
    let error = {};
    let formIsValid = true;

    if (!fields["email"] || fields["email"].trim() == "") {
      formIsValid = false;
      error["email"] = "*Please enter your email address";
    }
    if (typeof fields["email"] !== "undefined") {
      var pattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        error["email"] = "*Please enter valid email address";
      }
    }
    return {
      errors: error,
      formIsValid: formIsValid,
    };
  };
  const validateLoginForm = () => {
    let response = validateLogin();
    return response.formIsValid;
  };

  const navigation = useNavigate();
  const forgotPswdData = async (e) => {
    let domainName = (url) =>
      url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
    let domailURL = window.location.href;
    let pathURL = domainName(domailURL);
    setLoading(true);
    try {
      const response = await httpService.get(
        `api/Account/ForgotPasswordEmailVerify?username=${fields.email}&Email=${fields.email}`,
        { headers: { Domain: `${pathURL}` } }
      );
      if (response.data.isSuccess) {
        setLoading(false);
        setResponce("Email is submitted successfully");

        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Email is submitted successfully!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Email is submitted successfully!",
            type: "success",
          });
        }, 1400);
        setTimeout(() => {
          navigation("/login");
        }, 1000);
      } else {
        setLoading(false);
        seterrormessage(response.data.data.vcStatus);
        setTimeout(() => {
          seterrormessage("");
        }, 1700);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateLoginForm() && !isDisable) {
      forgotPswdData();
    }
  };
  return (
    <div className="signPage">
       <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster
        message={toastConfig.message}
        type={toastConfig.type}
        visible={toastConfig.isVisible}
      />
      <div
        style={{
          height: "100vh",
          backgroundImage:
            "linear-gradient(to bottom, #0077b7 0%, #004469 100%)",
        }}
      >
        <div style={{ backgroundColor: "white" }} className="signInner">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          {!false ? (
            <>
              <h1>Forgot password</h1>
              <p className="msg">
                Please enter registered Email Address. We will send a link to
                this Email Address.
              </p>
              <form>
                <div className="fieldWrap">
                  <label>Email</label>
                  <input
                    disabled={emailresponce}
                    type="text"
                    name="email"
                    placeholder="Enter registered email address"
                    onChange={(e) => handleChange(e)}
                    value={fields.email}
                  />
                  {errormessage && (
                    <p style={{ color: "red" }}>{errormessage}</p>
                  )}
                </div>
                <div className="fieldWrap">
                  <button
                    className={`btn btn-default ${
                      isDisable || emailresponce ? " disabled " : ""
                    }`}
                    disabled={isDisable || emailresponce}
                    onClick={handleSubmit}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </>
          ) : (
            <ChangePsswd />
          )}
        </div>
      </div>
    </div>
  );
}
