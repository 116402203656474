import { useState } from "react";
import { Warrents } from "../../../Dockect/Warrents/Warrents";

export const GoToWarrents = ({
  warrantData,
  getWarrantsDataApi,
  setToastConfig,
  toastConfig,
  dropdownData,
  docketDropdownData,
  userinfo,
  warrentErrorHandling,
  dockertDetail,
  dockerHeaderData,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className="goto-warrents-tab">
        <Warrents
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          userinfo={userinfo}
          dropdownData={dropdownData}
          docketDropdownData={docketDropdownData}
          warrantData={warrantData}
          loading={loading}
          setLoading={setLoading}
          getWarrantsDataApi={getWarrantsDataApi}
          warrentErrorHandling={warrentErrorHandling}
          gotoWarrents={true}
          dockertDetail={dockertDetail}
          dockerHeaderData={dockerHeaderData}
        />
      </div>
    </>
  );
};
