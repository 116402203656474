import { LoadPanel, Popup } from "devextreme-react";

import { SelectReportCriteria } from "../SelectReportCriteria/SelectReportCriteria";
import { useEffect, useState } from "react";
import { ReportFunction } from "../REportFunction";
import { useNavigate } from "react-router-dom";
import { date } from "@progress/kendo-react-dateinputs/dist/npm/messages";

const SelectReportCriteriaPopup = ({
  SelectReportCriteriaPopupopen,
  setSelectReportCriteriaPopupopen,
  data,
  sReportPathname,
  sReportName,
  startDate,
  endDate,
  userName,
}) => {
  return (
    <>
      <Popup
        visible={SelectReportCriteriaPopupopen}
        onHiding={() => {
          setSelectReportCriteriaPopupopen(false);
        }}
        showTitle={false}
        width={900}
        height={700}
        showCloseButton={true}
        hideOnOutsideClick={true}

      >
        <SelectReportCriteria
          SelectReportCriteriaPopupopen={SelectReportCriteriaPopupopen}
          setSelectReportCriteriaPopupopen={setSelectReportCriteriaPopupopen}
          data={data}
          sReportPathname={sReportPathname}
          sReportName={sReportName}
          startDate={startDate}
          endDate={endDate}
          userName={userName}
        />
      </Popup>
    </>
  );
};

export const Supervision = ({ SupervisionData, name, Image }) => {
  const clas = new ReportFunction();
  const [SelectReportCriteriaPopupopen, setSelectReportCriteriaPopupopen] =
    useState(false);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const [data, setdata] = useState();
  const [sReportPathname, setsReportPathname] = useState("");
  const [sReportName, setsReportName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userName, setuserName] = useState("");

  const buttonapi = (id) => {
    clas.getparamete(
      navigate,
      setloading,
      id,
      setdata,
      setSelectReportCriteriaPopupopen
    );
  };
  const formatDate = (date) => {
    // Adjusting for the local time zone offset
    const offset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
    const localDate = new Date(date.getTime() - offset);
  
    // Formatting the local date
    return localDate.toISOString().split('T')[0];
  };

  console.log('data supervision', data)
  useEffect(() => {
    data?.map((val) => {
      const currentDate = new Date();
      console.log('currentDate', currentDate)
      if (val.sParameterType == "Date" && val.sParameterName=="StartDate") {
        if (val.sDefaultValue == "%SOCM") {
          const firstDayOfMonth = formatDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
          setStartDate(firstDayOfMonth);
        }
        else if (val.sDefaultValue == "%EOCM") {
          const lastDayOfMonth = formatDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));
          setStartDate(lastDayOfMonth);
        }
        else if (val.sDefaultValue == "%SOCY") {
          const firstDayOfYear = formatDate(new Date(currentDate.getFullYear(), 0, 1));
          setStartDate(firstDayOfYear);
        }
        else if (val.sDefaultValue == "%EOCY") {
          const lastDayOfYear = formatDate(new Date(currentDate.getFullYear(), 12, 0));
          setStartDate(lastDayOfYear);
        }
      }
      else if (val.sParameterType == "Date" && val.sParameterName=="EndDate") {
        if (val.sDefaultValue == "%SOCM") {
          const firstDayOfMonth = formatDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
          setEndDate(firstDayOfMonth);
        }
        else if (val.sDefaultValue == "%EOCM") {
          const lastDayOfMonth = formatDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));
          setEndDate(lastDayOfMonth);
        }
        else if (val.sDefaultValue == "%SOCY") {
          const firstDayOfYear = formatDate(new Date(currentDate.getFullYear(), 0, 1));
          setEndDate(firstDayOfYear);
        }
        else if (val.sDefaultValue == "%EOCY") {
          const lastDayOfYear = formatDate(new Date(currentDate.getFullYear(), 12, 0));
          setEndDate(lastDayOfYear);
        }
      }
      if(val.sParameterName=="UserName")
        {
          setuserName(localStorage.getItem("username"));
        }
    });
  }, [data]);

  return (
    <>
      <SelectReportCriteriaPopup
        SelectReportCriteriaPopupopen={SelectReportCriteriaPopupopen}
        setSelectReportCriteriaPopupopen={setSelectReportCriteriaPopupopen}
        data={data}
        sReportPathname={sReportPathname}
        sReportName={sReportName}
        startDate={startDate}
        endDate={endDate}
        userName={userName}
      />
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div className="supervision">
        <div className="image">
          <p className="edit_container_label">{name}</p>
          <img src={Image} alt="" />
        </div>
        <div className="buttons">
          {SupervisionData?.map((val) => {
            return (
              <>
                <button
                  style={{
                    background: "var(--button-lightblack-lightblue)",
                    color: "var(--TextColor)",
                    whiteSpace: "nowrap",
                    width: "240px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  title={val?.sReportName}
                  onClick={() => {
                    buttonapi(val?.gReportId);
                    setsReportPathname(val?.sReportPath);
                    setsReportName(val?.sReportName);
                  }}
                >
                  {val?.sReportName}
                </button>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};
