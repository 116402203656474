import errorIcon from "../../assets/images/close.png";

export const ApiError = () => {
  return (
    <>
      <div
        style={{
          textAlign: "center",
          backgroundColor: "#e8a2a4",
          marginTop: "7%",
          width: "90%",
          marginLeft: "5%",
          padding: "10px",
        }}
      >
        <img style={{ width: "5%" }} src={errorIcon} />
        <p style={{ fontWeight: "bold", fontSize: "15px" }}>
          Oops! Something went wrong.
        </p>
        <p>Failed to load data..</p>
      </div>
    </>
  );
};
