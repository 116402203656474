import { useEffect, useState } from "react";
import Details from "./contactsSubTabs/details/Details";
import { Attachment } from "../../Participants/ParticipantSubTabs/Attachment/Attachment";
import { ProntactEntityFunction } from "../prontactEntities/ProntactEntityFunction";
import { useNavigate } from "react-router-dom";
import EntityList from "./contactsSubTabs/entityList/EntityList";

const ContactsSubTabs = ({
  isEditableButton,
  toastConfig,
  setToastConfig,
  contactData,
  fields,
  setFields,
  lastNamevalidation,
  setLastNamevalidation,
  firstNamevalidation,
  setFirstNamevalidation,
  titleDropdown,
  contactInfoType,
  data,
  setdata,
  contactInfoTypeCount,
  setContactInfoTypeCount,
  contactsEntityListDropdown,
  SetContactsEntityListDropdown,
  entityListRightData,
  setEntityListRightData,
  entityListLeftData,
  setEntityListLeftData,
  contactEntityListCount,
  typeFeeSchedule,
  reportCatalogData,
  allData,
  setAllData,
}) => {
  const [activeTab, setActiveTab] = useState(1);
  const clas = new ProntactEntityFunction();

  const [attachmentTabledata, setattachmentTabledata] = useState([]);
  const [attachmentCount, setattachmentCount] = useState(0);
  const [loadingg, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const tabTitles = [
    "Details",
    `Attachment (${attachmentCount})`,
    `Entity List (${contactEntityListCount})`,
  ];
  useEffect(() => {
    if (contactData.gContactId) {
      setActiveTab(1);
      clas.getattachmentTableData(
        setattachmentTabledata,
        setLoading,
        navigate,
        contactData.gContactId,
        setattachmentCount
      );
    }
  }, [contactData.gContactId]);

  const getattachmentTableData = () => {
    if (true) {
      clas.getattachmentTableData(
        setattachmentTabledata,
        setLoading,
        navigate,
        contactData.gContactId,
        setattachmentCount
      );
    }
  };

  return (
    <>
      <div className="goto-obligation-sub-tab-container">
        <div className="goto-obligation-sub-tab">
          {tabTitles.map((title, index) => (
            <button
              key={index}
              className={`goto-obligation-button ${
                activeTab === index + 1 ? "goto-obligation-button-active" : ""
              }`}
              onClick={() => handleTabClick(index + 1)}
            >
              {title}
            </button>
          ))}
        </div>
        <div className="commonBlock_bottom_border"></div>
        <div className="common-block-active-sub-tabs">
          {activeTab === 1 && (
            <Details
              isEditableButton={isEditableButton}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              contactData={contactData}
              fields={fields}
              setFields={setFields}
              lastNamevalidation={lastNamevalidation}
              setLastNamevalidation={setLastNamevalidation}
              firstNamevalidation={firstNamevalidation}
              setFirstNamevalidation={setFirstNamevalidation}
              titleDropdown={titleDropdown}
              contactInfoType={contactInfoType}
              data={data}
              setdata={setdata}
              contactInfoTypeCount={contactInfoTypeCount}
              setContactInfoTypeCount={setContactInfoTypeCount}
              typeFeeSchedule={typeFeeSchedule}
              allData={allData}
              setAllData={setAllData}
            />
          )}
          {activeTab === 2 && (
            <Attachment
              dataa={attachmentTabledata}
              loadinggg={loadingg}
              userinfo={{ gPersonId: contactData.gContactId }}
              attachmentfunction={getattachmentTableData}
            />
          )}
          {activeTab === 3 && (
            <EntityList
              isEditableButton={isEditableButton}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              contactData={contactData}
              contactsEntityListDropdown={contactsEntityListDropdown}
              SetContactsEntityListDropdown={SetContactsEntityListDropdown}
              entityListRightData={entityListRightData}
              setEntityListRightData={setEntityListRightData}
              entityListLeftData={entityListLeftData}
              setEntityListLeftData={setEntityListLeftData}
              typeFeeSchedule={typeFeeSchedule}
              reportCatalogData={reportCatalogData}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ContactsSubTabs;
