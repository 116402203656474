import { useEffect, useState } from "react";
import { handleGetLocal } from "../../../../../../services/auth-service";
import { logOut } from "../../../../../../components/Logout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LoadPanel } from "devextreme-react";
import { Table2 } from "../../../../../../components/Table2/Table2";
import "../financialPop.css";

const Tablecoloum = [
    { title: "Reference", editable: false },
    { title: "Docket", editable: false },
    { title: "Court", editable: false },
    { title: "Fee", editable: false },
    { title: "Amount", editable: false, current: true, currency: true },
    { title: "Balance", editable: false, current: true, currency: true },
    { title: "Allocation Amount", number: true, editable: true, currency: true },
];

const setTableData = (dataa) => {
    const arr = [];
    dataa?.map((val, i) => {
        arr.push({
            Reference: val?.sReference,
            sReference: val?.sReference,
            Docket: val?.sDocketNumber,
            sDocket: val?.sDocketNumber,
            Court: val?.sCourtName,
            sCourt: val?.sCourtName,
            Fee: val?.sFee,
            sFee: val?.sFee,
            Amount: val?.fAmount,
            fAmount: val?.fAmount,
            Balance: val?.fBalance,
            fBalance: val?.fBalance,
            "Allocation Amount": val?.fAllocation,
            fAllocation: val?.fAllocation,
            gPaymentID: val?.gPaymentID,
            gObligationID: val?.gObligationID,
            gPaymentAllocationTypeID: val?.gPaymentAllocationTypeID,
            ID: i,
        });
    });

    return arr;
};

const NewReallocatePaymentPopup = ({
    reallocatePaymentPopup,
    setReallocatePaymentPopup,
    toastConfig,
    setToastConfig,
    fieldID,
    refreshApi,
    setCallApi,
    callApi,
    typePopup,
    rowInfoData,
}) => {
    const [notes, setNotes] = useState("");
    const [notesValidation, setNotesValidation] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [visibleRows, setVisibleRows] = useState([]);
    const pathname = handleGetLocal("pathURL");
    const [data, setdata] = useState([]);
    const [totalPay, setTotalPay] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);

    const clearAllocation = () => {
        const newData = data.map(item => ({
            ...item,
            fAllocation: 0.0,
            ["Allocation Amount"]: 0.0,
        }));
        setdata(newData);
    };

    const getTableData = async (gPaymentId) => {
        setLoading(true);
        await axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/Financial/get-payment-reallocation-grid?gPaymentID=${gPaymentId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                        Domain: `${pathname}`,
                    },
                }
            )
            .then((val) => {
                setLoading(false);
                setdata(setTableData(val?.data?.data));
            })
            .catch((val) => {
                setLoading(false);
                if (!val?.response) {
                    navigate(`/error/500`);
                } else if (val?.response?.status == 401) {
                    logOut();
                }
            });
    };

    // const handleSave = async () => {
    //     let temp = 0;
    //     data.map((val, i) => {
    //         temp = temp + val?.["Allocation Amount"];
    //     });
    //     if (Number(temp.toFixed(2)) === Number(rowInfoData?.Amount.toFixed(2))) {
    //         console.log('data',data) 
    //         await axios
    //             .post(`${process.env.REACT_APP_API_URL}/api/Financial/payment-reallocation-apply`, data.filter(val => val?.fAmount !== 0).map((val, i) => {
    //                 return {
    //                     "sDocketNumber": val?.sDocket,
    //                     "ReceiptNumber":rowInfoData?.["Receipt #"],
    //                     "sReference": val?.sReference,                        
    //                     "sCourtName": val?.sCourtName,
    //                     "sFee": val?.sFee,
    //                     "fAmount": val?.fAmount,
    //                     "fBalance": val?.fBalance,
    //                     "fAllocation": val?.["Allocation Amount"],
    //                     "gPaymentID": val?.gPaymentID,
    //                     "gObligationID": val?.gObligationID,
    //                     "gPaymentAllocationTypeID": val?.gPaymentAllocationTypeID
    //                 };
    //             }), {
    //                 headers: {
    //                     Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    //                     Domain: `${pathname}`,
    //                 },
    //             })
    //             .then((val) => {
    //                 setReallocatePaymentPopup(false);
    //                 setToastConfig({
    //                     ...toastConfig,
    //                     isVisible: true,
    //                     message: val?.data?.responseMessage,
    //                     type: "success",
    //                 });
    //                 setTimeout(() => {
    //                     setToastConfig({
    //                         ...toastConfig,
    //                         isVisible: false,
    //                         message: val?.data?.responseMessage,
    //                         type: "success",
    //                     });
    //                 }, 1900);
    //                 setLoading(false);
    //             })
    //             .catch((val) => {
    //                 setLoading(false);
    //                 if (!val?.response) {
    //                     navigate(`/error/500`);
    //                 } else if (val?.response?.status === 401) {
    //                     logOut();
    //                 }
    //             });
    //     }
    //     else {
    //         setToastConfig({
    //             ...toastConfig,
    //             isVisible: true,
    //             message: "Allocation Amount and Payment Amount should be equal",
    //             type: 'error',
    //         });
    //         setTimeout(() => {
    //             setToastConfig({
    //                 ...toastConfig,
    //                 isVisible: false,
    //                 message: "Allocation Amount and Payment Amount should be equal",
    //                 type: 'error',
    //             });
    //         }, 1900);
    //     }

    // }
    const handleSave = async () => {
        let totalAllocation = data.reduce((sum, val) => sum + val["Allocation Amount"], 0);
    
        if (Number(totalAllocation.toFixed(2)) === Number(rowInfoData.Amount.toFixed(2))) {
            console.log('All rows data:', data); // Log all rows to confirm
            
            // Post all rows without filtering by fAmount !== 0
            await axios
                .post(
                    `${process.env.REACT_APP_API_URL}/api/Financial/payment-reallocation-apply`,
                    data.map((val) => ({
                        sDocketNumber: val.sDocket,
                        ReceiptNumber: rowInfoData["Receipt #"],
                        sReference: val.sReference,
                        sCourtName: val.sCourt,
                        sFee: val.sFee,
                        fAmount: val.fAmount,
                        fBalance: val.fBalance,
                        fAllocation: val["Allocation Amount"],
                        gPaymentID: val.gPaymentID,
                        gObligationID: val.gObligationID,
                        gPaymentAllocationTypeID: val.gPaymentAllocationTypeID,
                    })),
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                            Domain: pathname,
                        },
                    }
                )
                .then((response) => {
                    setReallocatePaymentPopup(false);
                    setToastConfig({
                        ...toastConfig,
                        isVisible: true,
                        message: response.data.responseMessage,
                        type: "success",
                    });
                    setTimeout(() => {
                        setToastConfig({
                            ...toastConfig,
                            isVisible: false,
                            message: response.data.responseMessage,
                            type: "success",
                        });
                    }, 1900);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    if (!error.response) {
                        navigate(`/error/500`);
                    } else if (error.response.status === 401) {
                        logOut();
                    }
                });
        } else {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                message: "Allocation Amount and Payment Amount should be equal",
                type: 'error',
            });
            setTimeout(() => {
                setToastConfig({
                    ...toastConfig,
                    isVisible: false,
                    message: "Allocation Amount and Payment Amount should be equal",
                    type: 'error',
                });
            }, 1900);
        }
    };
    

    useEffect(() => {
        if (rowInfoData?.gPaymentId) {
            getTableData(rowInfoData?.gPaymentId);
        }
    }, [reallocatePaymentPopup]);

    return (
        <>
            <LoadPanel shadingColor="true" visible={loading} delay={10} />
            <div className="new-reallocate-payment-popup">
                <div className="popup_header">
                    <div className="popup_header_title">
                        <div class="popup_header_title_data">
                            <p>Reallocate Payment</p>
                        </div>
                    </div>
                    <svg
                        onClick={() => {
                            setReallocatePaymentPopup(false);
                        }}
                        style={{
                            cursor: "pointer",
                        }}
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                            fill="white"
                        />
                    </svg>
                </div>
                <div className="program_void_content">
                    <div className="flex-container">
                        <p className="go-to-label-filed">
                            Payment Amount : <label> ${rowInfoData?.Amount}</label>
                        </p>
                        <button 
                                className="btn"
                                onClick={
                                    clearAllocation
                                }
                            >
                                Clear Allocation
                                <svg
                                    className="svg-black-white"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.2987 0.709727C12.9087 0.319727 12.2787 0.319727 11.8887 0.709727L6.99875 5.58973L2.10875 0.699727C1.71875 0.309727 1.08875 0.309727 0.69875 0.699727C0.30875 1.08973 0.30875 1.71973 0.69875 2.10973L5.58875 6.99973L0.69875 11.8897C0.30875 12.2797 0.30875 12.9097 0.69875 13.2997C1.08875 13.6897 1.71875 13.6897 2.10875 13.2997L6.99875 8.40973L11.8887 13.2997C12.2787 13.6897 12.9087 13.6897 13.2987 13.2997C13.6887 12.9097 13.6887 12.2797 13.2987 11.8897L8.40875 6.99973L13.2987 2.10973C13.6787 1.72973 13.6787 1.08973 13.2987 0.709727Z"
                                        fill="#424167"
                                    />
                                </svg>
                            </button>
                    </div>
                    <div className="flex-container">
                        <p className="go-to-label-filed">
                            Receipt Number : <label> {rowInfoData?.["Receipt #"]}</label>
                        </p>

                    </div>
                    <div>
                        <p className="go-to-label-filed">
                            Receipt Date : <label> {rowInfoData?.["Date of Receipt"]}</label>
                        </p>

                    </div>
                </div>

                <div style={{ marginLeft: "1.5%", marginTop: "2.5%" }}>
                    <p className="go-to-label-filed">Current Allocation</p>
                    <Table2
                        editTable={false}
                        height={400}
                        Tablecoloum={Tablecoloum}
                        tableRow={data}
                        load={false}
                        coloumWidthText={"Court"}
                        mandatoryColoum={"Reference"}
                        showTotalBlock={true}
                        recivePayment={true}
                        editableAllocationAmount={true}
                        setCheckedFalse={true}
                        setVisibleRows={setVisibleRows}
                        visibleRows={visibleRows}
                        reallocatePaymentTotal={true}
                        setTotalPay={setTotalPay}
                        setTotalCredit={setTotalCredit}
                    />
                </div>
                <div className="popup_buttons" style={{ marginTop: "10px", marginRight: "15px" }}>
                    <button className="btn primary_btn" onClick={handleSave}>
                        Save
                    </button>
                    <button
                        className="btn"
                        onClick={() => {
                            setReallocatePaymentPopup(false);
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </>
    );
};
export default NewReallocatePaymentPopup;
