import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  on_Save_Edit: true,
  resetValue: true,
  resetReportDates: true,
  resetVoideFalseFilter: true,
  dockerValue: {},
  gotooblogationValue: {},
  AddObligation: false,
  openMonitoringTab: false,
};

//slice

const slice = createSlice({
  name: "ParticipateReducer",
  initialState,
  reducers: {
    seton_Save_Edit(state, action) {
      state.on_Save_Edit = action.payload;
    },
    setresetValue(state, action) {
      state.resetValue = action.payload;
    },
    setresetReportDates(state, action) {
      state.resetReportDates = action.payload;
    },
    setresetVoideFalseFilter(state, action) {
      state.resetVoideFalseFilter = action.payload;
    },
    setdockerValue(state, action) {
      state.dockerValue = action.payload;
    },
    setgotooblogationValue(state, action) {
      state.gotooblogationValue = {
        ...state.gotooblogationValue,
        ...action.payload,
      };
    },

    setAddObligation(state, action) {
      state.AddObligation = action.payload;
    },

    setOpenMonitoringTab(state, action) {
      state.openMonitoringTab = action.payload;
    },
  },
});

export const {
  seton_Save_Edit,
  setresetValue,
  setresetReportDates,
  setresetVoideFalseFilter,
  setdockerValue,
  setgotooblogationValue,
  setAddObligation,
  setOpenMonitoringTab,
} = slice.actions;

export default slice.reducer;
