import { JailTime } from "../../../Dockect/JailTime/JailTime";

export const GoToDocketJailTime = ({
  toastConfig,
  setToastConfig,
  userinfo,
  dropdownData,
  docketDropdownData,
  jailPlannedData,
  jailCompletedData,
  getJailCopletedServiceListApi,
  getJailPlannedServiceListApi,
  JailtimelogDropdown,
  getJailtimelogDropdown,
  jailPlannedErrorHandling,
  jailCompletedErorrHandling,
  dockertDetail,
  dockerHeaderData,
}) => {
  return (
    <>
      <JailTime
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        userinfo={userinfo}
        dropdownData={dropdownData}
        docketDropdownData={docketDropdownData}
        jailPlannedData={jailPlannedData}
        jailCompletedData={jailCompletedData}
        getJailCopletedServiceListApi={getJailCopletedServiceListApi}
        getJailPlannedServiceListApi={getJailPlannedServiceListApi}
        JailtimelogDropdown={JailtimelogDropdown}
        getJailtimelogDropdown={getJailtimelogDropdown}
        jailPlannedErrorHandling={jailPlannedErrorHandling}
        jailCompletedErorrHandling={jailCompletedErorrHandling}
        gotodocker={true}
        dockertDetail={dockertDetail}
        dockerHeaderData={dockerHeaderData}
      />
    </>
  );
};
