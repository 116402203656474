import { Popup, ScrollView } from "devextreme-react";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import { useState } from "react";
import { ReportDateFindpopup } from "./ReportDateFindpopup";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        // setfields={setfields}
        // fields={fields}
        // fieldNmae={fieldNmae}
        // value={fields[fieldNmae].name}
        // reset={reset}
        width={"230px"}
      />
    </>
  );
};
const ReportDatefindpopup = ({ setReportDatefind, ReportDatefind }) => {
  return (
    <Popup
      visible={ReportDatefind}
      onHiding={() => {
        setReportDatefind(false);
      }}
      showTitle={false}
      width={650}
      height={600}
      showCloseButton={true}
      hideOnOutsideClick={true}
    >
      <ReportDateFindpopup setReportDatefind={setReportDatefind} />
    </Popup>
  );
};

export const ChangeReportDate = ({ setreportdate }) => {
  const [ReportDatefind, setReportDatefind] = useState(false);
  return (
    <>
      <ReportDatefindpopup setReportDatefind={setReportDatefind} ReportDatefind={ReportDatefind} />
      <div
        style={{
          width: "100%",
          height: "598px",
          border: "3px solid var(--button2)",
        }}
        className="CreateAppointmentPopup"
      >
        <div className="RecivePaymentModel_header">
          <div style={{ width: "220px" }} className="RecivePaymentModel_header_name">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clipPath="url(#clip0_1331_396199)">
                <path d="M0 0H24V24H0V0Z" fill="rgb(67, 66, 102)" />
                <path
                  d="M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1331_396199">
                  <rect width="24" height="24" fill="blue" />
                </clipPath>
              </defs>
            </svg>
            <p style={{ marginLeft: "-10px" }}>Change Report Date</p>
            <span
              style={{
                padding: "2px 9px",
                gap: "8px",
                width: "45px",
                height: "24px",
                background: "#FEF2F2",
                borderRadius: "4px",
              }}
            >
              <p
                style={{
                  color: "#DC2626",
                  fontFamily: "Readex Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "10px",
                  lineHeight: "20px",
                  width: "21px",
                  height: "20px",
                }}
              >
                RD.6
              </p>
            </span>
          </div>
          <svg
            onClick={() => {
              setreportdate(false);
            }}
            style={{
              cursor: "pointer",
              marginTop: "17px",
              marginRight: "2%",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div style={{ width: "94%", marginLeft: "3%" }}>
          <div className="quickAddObligationn">
            <div>
              <p>Location</p>
              <p
                style={{
                  marginLeft: "15px",
                  color: "black",
                }}
              >
                {"header?.sPersonNameLastFirst"}
              </p>
            </div>
            <div>
              <p>Resource</p>
              <p
                style={{
                  marginLeft: "15px",
                  color: "black",
                }}
              >
                {"header?.sDocketNumberCourtCounts"}
              </p>
            </div>
          </div>
          <div className="collectionpopupBLOCK2">
            <div className="collectionpopupBLOCK2inputt">
              <p>
                Docket Reference<span>*</span>
              </p>
              <div>
                <DropDown
                  // setfields={setFields}
                  // fields={fields}
                  // fieldNmae={"Fee Code"}
                  dropDownData={[]}
                />
              </div>
            </div>
            <div className="collectionpopupBLOCK2inputt">
              <p>
                Report Date<span>*</span>
              </p>
              <div style={{ display: "flex", alignItems: "center" }}>
                <DropDown
                  // setfields={setFields}
                  // fields={fields}
                  // fieldNmae={"Fee Code"}
                  dropDownData={[]}
                />
                <button
                  onClick={() => {
                    setReportDatefind(true);
                  }}
                  style={{
                    width: "14%",
                    cursor: "pointer",
                    marginLeft: "10px",
                    height: "37px",
                    marginTop: "8px",
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_5667_11381)">
                      <path
                        d="M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z"
                        fill="#424167"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5667_11381">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="collectionpopupBLOCK2">
            <div className="collectionpopupBLOCK2inputt">
              <p>Report Time</p>
              <div>
                <DropDown
                  // setfields={setFields}
                  // fields={fields}
                  // fieldNmae={"Fee Code"}
                  dropDownData={[]}
                />
              </div>
            </div>
            <div className="collectionpopupBLOCK2inputt">
              <p>Change Reason</p>
              <div>
                <DropDown
                  // setfields={setFields}
                  // fields={fields}
                  // fieldNmae={"Fee Code"}
                  dropDownData={[]}
                />
              </div>
            </div>
          </div>
          <div className="quickAddObligationn">
            <div>
              <p>Docket #</p>
              <p
                style={{
                  marginLeft: "15px",
                  color: "black",
                }}
              >
                {"header?.sPersonNameLastFirst"}
              </p>
            </div>
            <div>
              <p>Person</p>
              <p
                style={{
                  marginLeft: "15px",
                  color: "black",
                }}
              >
                {"header?.sDocketNumberCourtCounts"}
              </p>
            </div>
          </div>
          <div style={{ marginTop: "4%" }}>
            <p style={{ color: "black", fontWeight: "bold" }}>
              Please add a detailed comment<span>*</span>
            </p>
            <textarea style={{ width: "100%", height: "150px", marginTop: "1.4%" }} />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "2%",
              marginBottom: "2%",
            }}
          >
            <button
              style={{
                backgroundColor: "rgb(67, 66, 102)",
                color: "white",
                width: "15%",
              }}
              onClick={() => {}}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
