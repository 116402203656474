import { DateBox, Popup } from "devextreme-react";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { useEffect, useState } from "react";
import { handleGetLocal } from "../../../../../services/auth-service";
import { ApiError } from "../../../../Error/ApiError";
import { drugTestScheduleTableTitle, setDrugTestScheduleTableColumnData } from "../Data";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import { logOut } from "../../../../../components/Logout";
import axios from "axios";
import { Navigate } from "react-router-dom";
import VoidTest from "./Popups/VoidTest";
import { AddEditDrugTestSchedule } from "./Popups/AddEditDrugTestSchedule";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

export const DrugTestSchedule = ({
  userinfo,
  locationDropdownValues,
  setToastConfig,
  toastConfig,
  setDrugTestScheduleData,
  drugTestScheduleData,
  setFields,
  fields,
  setdate,
  date,
  drugTestScheduleMenu,
}) => {
  const [loading, setLoading] = useState(false);
  const [drugTestScheduleErrorHandling, setDrugTestScheduleErrorHandling] = useState(false);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const pathname = handleGetLocal("pathURL");
  const [drugTestScheduleRowData, setDrugTestScheduleRowData] = useState([]);
  const [voidPopup, setVoidPopup] = useState(false);
  const [addEditDrugTestSchedulePopup, setAddEditDrugTestSchedulePopup] = useState(false);
  const [drugTestScheduleType, setDrugTestScheduleType] = useState("Add");
  const [userDropdownValue, setUserDropdownValue] = useState([]);
  const [testDateDropdownValue, setTestDateDropdownValue] = useState([]);

  const getReportDateRange = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/RandomDrugTesting/get-drugtest-schedule?Location=${fields.location.id}&dStart=${date.startDate}&dEnd=${date.endDate}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setDrugTestScheduleData(setDrugTestScheduleTableColumnData(val?.data?.data));
        } else {
          setDrugTestScheduleErrorHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          Navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setDrugTestScheduleErrorHandling(true);
        }
      });
  };

  const getUserDropdownValue = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/RandomDrugTesting/drugtest-schedule-person-dropdown?Location=${fields.location.id}&dStart=${date.startDate}&dEnd=${date.endDate}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setUserDropdownValue(
          val?.data?.data?.map((item) => {
            return {
              key: item?.gDrugTestRandomizerId,
              value: item?.sLastFirst,
            };
          })
        );
      })
      .catch((val) => {
        if (!val?.response) {
          Navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const getTestDateDropdownValue = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/RandomDrugTesting/get-test-date?Location=${fields.location.id}&dStart=${date.startDate}&dEnd=${date.endDate}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setTestDateDropdownValue(
          val?.data?.data?.map((item) => {
            return {
              "Test Time": item?.sTestTime,
              Slot: item?.iSlot,
              "Test Date": item?.sTestDate,
              gTestDateId: item?.gTestDateId,
              "Test Date Time": item?.sTestDateTime,
            };
          })
        );
      })
      .catch((val) => {
        if (!val?.response) {
          Navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    if (fields.location.id) {
      if (date.endDate !== "Invalid Date" && date.startDate !== "Invalid Date") {
        getReportDateRange();
        getUserDropdownValue();
        getTestDateDropdownValue();
      }
    }
  }, [date, userinfo, fields.location?.id]);

  return (
    <>
      <Popup
        visible={voidPopup}
        onHiding={() => {
          setVoidPopup(false);
        }}
        showTitle={false}
        width={800}
        height={450}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <VoidTest
          voidPopup={voidPopup}
          setVoidPopup={setVoidPopup}
          voidHeading="Void Test Date"
          voidSubHeading="Void Test Date"
          setLoading={setLoading}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          rowData={drugTestScheduleRowData}
          apiURl="RandomDrugTesting/void-drugtest-schedule"
          refreshApi={getReportDateRange}
        />
      </Popup>
      <Popup
        visible={addEditDrugTestSchedulePopup}
        onHiding={() => {
          setAddEditDrugTestSchedulePopup(false);
        }}
        showTitle={false}
        width={680}
        height={350}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddEditDrugTestSchedule
          addEditDrugTestSchedulePopup={addEditDrugTestSchedulePopup}
          setAddEditDrugTestSchedulePopup={setAddEditDrugTestSchedulePopup}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          drugTestScheduleType={drugTestScheduleType}
          userDropdownValue={userDropdownValue}
          locationDropdownValues={locationDropdownValues}
          drugTestScheduleRowData={drugTestScheduleRowData}
          testDateDropdownValue={testDateDropdownValue}
          getReportDateRange={getReportDateRange}
        />
      </Popup>
      <div className="page-content" style={{ padding: "0px" }}>
        <p className="page-info-number">DT.9</p>
        <div className="buttons">
          <button
            className="btn"
            onClick={() => {
              setAddEditDrugTestSchedulePopup(true);
              setDrugTestScheduleType("Add");
            }}
          >
            Add Test
            <svg
              className="svg-blue-white"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_7045_74694)">
                <path
                  d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM16 13H13V16C13 16.55 12.55 17 12 17C11.45 17 11 16.55 11 16V13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H11V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V11H16C16.55 11 17 11.45 17 12C17 12.55 16.55 13 16 13Z"
                  fill="#424167"
                />
              </g>
              <defs>
                <clipPath id="clip0_7045_74694">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
          <button
            className="btn"
            onClick={() => {
              setexportPopupOpen(true);
            }}
          >
            Export
            <svg
              className="svg-blue-white"
              width="14"
              height="17"
              viewBox="0 0 14 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                fill="#424167"
              />
            </svg>
          </button>

          <div className="page-details-search-content">
            <div className="display-flex-div dropdown-space">
              <label className="edit_container_label">Drug Test Location</label>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"location"}
                dropDownData={locationDropdownValues?.location?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
              />
            </div>
            <div className="display-flex-div">
              <label className="edit_container_label">Period Start</label>
              <DateBox
                useMaskBehavior={true}
                value={date.startDate}
                onValueChange={(e) => {
                  if (e) {
                    const initialDateString = e;
                    const initialDate = new Date(initialDateString);
                    const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                    const utcFormatted = new Date(utcTime).toISOString();
                    setdate({
                      ...date,
                      startDate: e ? utcFormatted : null,
                    });
                  }
                }}
              />
            </div>
            <div className="display-flex-div">
              <label className="edit_container_label">Period End</label>
              <DateBox
                useMaskBehavior={true}
                value={date.endDate}
                onValueChange={(e) => {
                  if (e) {
                    const initialDateString = e;
                    const initialDate = new Date(initialDateString);
                    const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                    const utcFormatted = new Date(utcTime).toISOString();
                    setdate({
                      ...date,
                      endDate: e ? utcFormatted : null,
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="drug-test-schedule">
          {drugTestScheduleErrorHandling ? (
            <ApiError />
          ) : (
            <Table1
              editTable={false}
              height={400}
              Tablecoloum={drugTestScheduleTableTitle}
              tableRow={drugTestScheduleData?.map((val, i) => {
                return { ...val, ID: i };
              })}
              load={loading}
              coloumWidthText={"Drug-Test-Schedule"}
              mandatoryColoum={"Test Date"}
              exportInDifferentFormate={true}
              exportPopupOpen={exportPopupOpen}
              setexportPopupOpen={setexportPopupOpen}
              exportwidth={27}
              exporttitle={"Drug-Test-Schedule"}
              drugTestScheduleMenu={drugTestScheduleMenu}
              drugTestScheduleRowData={drugTestScheduleRowData}
              setDrugTestScheduleRowData={setDrugTestScheduleRowData}
              setVoidPopup={setVoidPopup}
              setVoideFalse={true}
              setAddEditDrugTestSchedulePopup={setAddEditDrugTestSchedulePopup}
              setDrugTestScheduleType={setDrugTestScheduleType}
            />
          )}
        </div>
      </div>
    </>
  );
};
