import axios from "axios";
import "../documentWizard.css";
import { logOut } from "../../../../components/Logout";
import { handleGetLocal } from "../../../../services/auth-service";

const AddAsAttachment = ({
  addAsAttachmentPopup,
  setAddAsAttachmentPopup,
  userinfo,
  toastConfig,
  setToastConfig,
  setLoading,
  navigate,
  fields,
  htmlData,
  documentWizardOutputData,
}) => {
  const pathname = handleGetLocal("pathURL");

  const addAsAttachmentData = {
    gRecordID: fields?.reports?.id,
    rtf: `${htmlData}`,
    sDescription: documentWizardOutputData?.sReportTypeName,
    sFileName: documentWizardOutputData?.sName,
  };

  const handleSave = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/attachments/add-attachment`,
        addAsAttachmentData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        setAddAsAttachmentPopup(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Successfully Added!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Successfully Added!",
            type: "success",
          });
        }, 1900);
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  };
  return (
    <>
      <div className="add-as-attachment">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <p>Add As Attachment</p>
            </div>
          </div>
          <svg
            onClick={() => {
              setAddAsAttachmentPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="add-as-attachment-content">
          <p>
            Document(s) have been added to &#160;
            {userinfo?.sOffenderNumber ? userinfo?.sOffenderNumber : ""} -
            &#160;
            {userinfo?.sLastFirst ? userinfo?.sLastFirst : ""}
          </p>
          <div className="popup_buttons">
            {fields?.reports?.id && (
              <button className="btn primary_btn" onClick={handleSave}>
                Save
              </button>
            )}
            <button
              className="btn"
              onClick={() => {
                setAddAsAttachmentPopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAsAttachment;
