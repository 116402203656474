import React, { useCallback, useEffect, useState } from "react";
import { DataGrid, DateBox, DropDownBox, Popup } from "devextreme-react";
import { Column, FilterRow, Selection } from "devextreme-react/data-grid";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import { handleGetLocal, logout } from "../../../../../services/auth-service";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../../../components/Logout";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setaddworentREfresh } from "../../../../../reducer/ParticipateReducer";
import WarrentsNotice from "./WarrentsNotice";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

export const AddWarrentsPopup = ({
  addWarrentsPopup,
  setAddWarrentsPopup,
  dropdownData,
  docketDropdownData,
  rowWarrentData,
  warrantType,
  toastConfig,
  setToastConfig,
  getWarrantsDataApi,
  setLoading,
  userinfo,
  gotoWarrents,
  dockertDetail,
  dockerHeaderData,
}) => {
  const [selectedValue, setSelectedValue] = useState({
    Court: "",
    Docket: "",
    "Sentence Date": "",
    "Supervision Status": "",
    gDocketId: "",
  });
  const [dataSource, setDataSource] = useState([]);
  const [fields, setFields] = useState({
    court: { name: "", id: "" },
    judge: { name: "", id: "" },
    status: { name: "", id: "" },
    issuedDate: "",
    servedDate: "",
    statusDate: "",
    remark: "",
  });
  const [issuedDatevalidation, setIssuedDatevalidation] = useState("");
  const [remarksvalidation, setRemarksvalidation] = useState("");
  const [courtvalidation, setCourtvalidation] = useState("");
  const [judgevalidation, setJudgevalidation] = useState("");
  const [statusvalidation, setStatusvalidation] = useState("");
  const [docketvalidation, setDocketvalidation] = useState("");
  const [warrantJailDropdown, setWarrantJailDropdown] = useState([]);
  const pathname = handleGetLocal("pathURL");
  const navigate = useNavigate();
  const { addworentREfresh } = useSelector((store) => store.participate);
  const dispatch = useDispatch();
  const [warningWarrentsPopup, setWarningWarrentsPopup] = useState(false);
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);

  const getWarrantWorkSiteDropdown = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/judges-dropdown?entityId=${fields?.court?.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        const judgeDropdownData = val?.data?.data;
        if (!judgeDropdownData.length) {
          setFields({
            ...fields,
            judge: {
              name: "",
              id: "",
            },
          });
        } else {
          setWarrantJailDropdown(
            judgeDropdownData?.map((item) => {
              return {
                value: item?.sContactLastName,
                key: item?.gContactID,
              };
            })
          );
          setFields({
            ...fields,
            judge: {
              name: val?.data?.data[0]?.sContactLastName,
              id: val?.data?.data[0]?.gContactID,
            },
          });
        }
        setJudgevalidation("");
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    fields?.court?.id && getWarrantWorkSiteDropdown();
  }, [fields?.court?.id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (fields?.remark?.trim() === "" || value[0] === " ") {
      setRemarksvalidation("Enter Valid Remarks");
    } else {
      setRemarksvalidation("");
    }
  };

  const fetchedDataSource = docketDropdownData?.map((val) => {
    const convertDateFormat = (dateString) => {
      const dateObj = new Date(dateString);
      return dateObj.toLocaleDateString("en-US");
    };
    return {
      Court: val?.sCourtName,
      Docket: val?.sDocketNumberCourtCounts,
      "Sentence Date": convertDateFormat(val?.dSentence),
      "Supervision Status": val?.sSupervisionStatusName,
      gDocketId: val?.gDocketId,
      Offense: val?.sOffenseDescription,
      gSentenceJudgeContactId: val?.gSentenceJudgeContactId,
      gSentenceCourtEntityId: val?.gSentenceCourtEntityId,
      sJudgeName: val?.sJudgeName,
      sCourtName: val?.sCourtName,
    };
  });

  useEffect(() => {
    if (warrantType === "ADD") {
      if (gotoWarrents) {
        setFields({
          court: {
            name: dockerHeaderData?.sCourtName,
            id: dockerHeaderData?.gSentenceCourtEntityId,
          },
          judge: {
            name: dockerHeaderData?.sJudgeName,
            id: dockerHeaderData?.gSentenceJudgeContactId,
          },
          status: { name: "", id: "" },
          issuedDate: "",
          servedDate: "",
          statusDate: "",
          remark: "",
        });
      } else {
        setFields({
          court: {
            name: "",
            id: "",
          },
          judge: {
            name: "",
            id: "",
          },
          status: { name: "", id: "" },
          issuedDate: "",
          servedDate: "",
          statusDate: "",
          remark: "",
        });
      }
      const selectedDocket = dataSource?.find((item) => item.gDocketId === dockertDetail?.id);
      setSelectedValue(selectedDocket);
      setIssuedDatevalidation("");
      setDocketvalidation("");
      setCourtvalidation("");
      setRemarksvalidation("");
      setStatusvalidation("");
      setJudgevalidation("");
      setDataSource(fetchedDataSource);
    } else {
      setIssuedDatevalidation("");
      setDocketvalidation("");
      setCourtvalidation("");
      setRemarksvalidation("");
      setStatusvalidation("");
      setJudgevalidation("");
      const findKeyById = (id, data) => {
        const item = data?.find((item) => item?.key.toLowerCase() === id?.toLowerCase());
        return item ? item.value : "";
      };
      const courtName = findKeyById(rowWarrentData?.gCourtEntityId, dropdownData?.courts);
      // const judgename = findKeyById(
      //   rowWarrentData?.gJudgeContactId,
      //   dropdownData?.judges
      // );
      const statusName = findKeyById(rowWarrentData?.gWarrantStatusId, dropdownData?.warrantStatus);
      setFields((prevFields) => ({
        ...prevFields,
        court: {
          ...prevFields.court,
          name: courtName,
          id: rowWarrentData?.gCourtEntityId,
        },
        judge: {
          ...prevFields.judge,
          name: rowWarrentData?.Judge,
          id: rowWarrentData?.gJudgeContactId,
        },
        status: {
          ...prevFields.status,
          name: statusName,
          id: rowWarrentData?.gWarrantStatusId,
        },
      }));

      setFields((prev) => ({
        ...prev,
        issuedDate: rowWarrentData?.Issue,
        servedDate: rowWarrentData?.Served,
        statusDate: rowWarrentData?.[`Status Date`],
        remark: rowWarrentData?.Remarks || "",
      }));
      const selectedDocket = dataSource?.find((item) => item.gDocketId === rowWarrentData?.gDocketId);
      setSelectedValue(selectedDocket);
      setDataSource(fetchedDataSource);
    }
  }, [rowWarrentData, addWarrentsPopup]);

  const data = {
    GWarrantId: warrantType === "ADD" ? "00000000-0000-0000-0000-000000000000" : rowWarrentData?.gWarrantId,
    GEntryUserId: userinfo?.gEntryUserId,
    GDocketId: selectedValue?.gDocketId,
    DIssue: fields?.issuedDate,
    DServed: fields?.servedDate,
    GCourtEntityId: fields?.court?.id,
    GJudgeContactId: fields?.judge?.id,
    GWarrantStatusId: fields?.status?.id,
    DStatus: fields?.statusDate,
    SComment: fields?.remark,
    SWarrantIdPin: null,
    SOfficerPin: null,
    SDocketNumberPin: null,
    SJudgeNamePin: null,
    SCourtNamePin: null,
  };

  const handleSave = async () => {
    let isValid = true;
    if (!selectedValue?.gDocketId) {
      setDocketvalidation("Please Select Docket");
      isValid = false;
    }
    if (!fields.issuedDate) {
      setIssuedDatevalidation("Please Select Issued Date");
      isValid = false;
    }
    if (!fields.court.id) {
      setCourtvalidation("Please Select Court");
      isValid = false;
    }
    if (!fields.judge.id) {
      setJudgevalidation("Please Select Judge");
      isValid = false;
    }
    if (!fields.status.id) {
      setStatusvalidation("Please Select Status");
      isValid = false;
    }
    if (!fields.remark) {
      setRemarksvalidation("Enter Remarks");
      isValid = false;
    }
    if (isValid) {
      setWarningWarrentsPopup(true);
    }
  };

  const saveApi = async () => {
    setLoading(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/Warrants/AddEditWarrants`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setAddWarrentsPopup(false);
        dispatch(setaddworentREfresh(!addworentREfresh));
        getWarrantsDataApi();
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: warrantType === "ADD" ? "Successfully Added!" : "Successfully Updated!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: warrantType === "ADD" ? "Successfully Added!" : "Successfully Updated!",
            type: "success",
          });
        }, 1900);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const onGridBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }, []);

  return (
    <>
      <Popup
        visible={warningWarrentsPopup}
        onHiding={() => {
          setWarningWarrentsPopup(false);
        }}
        showTitle={false}
        width={450}
        height={200}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <WarrentsNotice setWarningWarrentsPopup={setWarningWarrentsPopup} saveApi={saveApi} />
      </Popup>
      <div className="add_warrents_popup">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="add" clipPath="url(#clip0_5349_5505)">
                  <path
                    id="Vector"
                    d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                    fill="#fff"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5349_5505">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>{warrantType === "ADD" ? "Add" : "Edit"} Warrant</p>
            </div>
            <p class="popup_header_title_para">WR.1</p>
          </div>
          <svg
            onClick={() => {
              setAddWarrentsPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="add_community_service_content">
          <div className="edit_container_first">
            <div className="edit_content_flex">
              <p className="edit_container_label">
                Docket <span className="marks_mandatary">*</span>
              </p>
              <DropDownBox
                placeholder="Select..."
                disabled={gotoWarrents ? true : false}
                dataSource={dataSource}
                value={selectedValue?.Docket}
                valueExpr={"Docket"}
                displayExpr={"Docket"}
                opened={isGridBoxOpened}
                onOptionChanged={onGridBoxOpened}
                contentRender={(value, element) => (
                  <DataGrid
                    dataSource={dataSource}
                    hoverStateEnabled={true}
                    height={180}
                    onRowClick={(e) => {
                      setSelectedValue(e?.data);
                      setDocketvalidation("");
                      setFields((prev) => ({
                        ...prev,
                        court: {
                          name: e?.data?.sCourtName,
                          id: e?.data?.gSentenceCourtEntityId,
                        },
                        judge: {
                          name: e?.data?.sJudgeName,
                          id: e?.data?.gSentenceJudgeContactId,
                        },
                      }));
                      setCourtvalidation("");
                      setJudgevalidation("");
                      setIsGridBoxOpened(false);
                    }}
                  >
                    <Selection mode="single" />
                    <FilterRow visible={true} />
                    <Column dataField="Court" caption="Court" width={"auto"} />
                    <Column dataField="Docket" caption="Docket" width={"auto"} />
                    <Column dataField="Sentence Date" caption="Sentence Date" width={"auto"} />
                    <Column dataField="Supervision Status" caption="Supervision Status" width={"auto"} />
                    <Column dataField="Offense" caption="Offense" width={"auto"} />
                  </DataGrid>
                )}
              />
              {docketvalidation && (
                <p className="error_validation" style={{ position: "absolute", top: "22.2%" }}>
                  {docketvalidation}
                </p>
              )}
            </div>
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">
                Court <span className="marks_mandatary">*</span>
              </p>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"court"}
                dropDownData={dropdownData?.courts?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
                reset={setCourtvalidation}
                disabled={true}
              />
              {courtvalidation && (
                <p className="error_validation" style={{ position: "absolute", top: "148px" }}>
                  {courtvalidation}
                </p>
              )}
            </div>
          </div>
          <div className="edit_container_first">
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">
                Judge <span className="marks_mandatary">*</span>
              </p>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"judge"}
                dropDownData={warrantJailDropdown?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
                reset={setJudgevalidation}
                disabled={true}
              />
              {judgevalidation && (
                <p className="error_validation" style={{ position: "absolute", top: "35.5%" }}>
                  {judgevalidation}
                </p>
              )}
            </div>
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">
                Issued Date <span className="marks_mandatary">*</span>
              </p>
              <DateBox
                useMaskBehavior={true}
                value={fields.issuedDate}
                onValueChange={(e) => {
                  setFields((prev) => ({
                    ...prev,
                    issuedDate: e,
                  }));
                  setIssuedDatevalidation("");
                }}
              />
              {issuedDatevalidation && (
                <p className="error_validation" style={{ position: "absolute", top: "240px" }}>
                  {issuedDatevalidation}
                </p>
              )}
            </div>
          </div>
          <div className="edit_container_first">
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">Served Date</p>
              <DateBox
                useMaskBehavior={true}
                value={fields.servedDate}
                onValueChange={(e) => {
                  setFields((prev) => ({
                    ...prev,
                    servedDate: e,
                  }));
                }}
              />
            </div>
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">
                Status <span className="marks_mandatary">*</span>
              </p>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"status"}
                dropDownData={dropdownData?.warrantStatus?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
                reset={setStatusvalidation}
              />
              {statusvalidation && (
                <p className="error_validation" style={{ position: "absolute", top: "333px" }}>
                  {statusvalidation}
                </p>
              )}
            </div>
          </div>
          <div className="edit_container_first">
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">Status Date</p>
              <DateBox
                useMaskBehavior={true}
                value={fields.statusDate}
                onValueChange={(e) => {
                  setFields((prev) => ({
                    ...prev,
                    statusDate: e,
                  }));
                }}
              />
            </div>
          </div>
          <div className="edit_container_second edit_content_flex">
            <p className="edit_container_label">
              Remarks
              <span className="marks_mandatary">*</span>
            </p>
            <textarea
              name="remark"
              id="remark"
              placeholder="Write Remarks*"
              value={fields.remark}
              onChange={handleInputChange}
              className="dark-mode-textarea"
            ></textarea>
            {remarksvalidation && (
              <p className="error_validation" style={{ position: "absolute", top: "89%" }}>
                {remarksvalidation}
              </p>
            )}
          </div>
          <div className="popup_buttons">
            <button className="btn primary_btn" onClick={handleSave}>
              Save
            </button>
            <button
              className="btn"
              onClick={() => {
                setAddWarrentsPopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
