import { useState } from "react";
import { Program } from "../../../Dockect/Program/Program";

export const GoToProgram = ({
  userinfo,
  toastConfig,
  setToastConfig,
  dropdownData,
  docketDropdownData,
  programTableData,
  getProgramListApi,
  programErorrHandling,
  dockertDetail,
  dockerHeaderData,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className="goto-program-tab">
        <Program
          userinfo={userinfo}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          dropdownData={dropdownData}
          docketDropdownData={docketDropdownData}
          programTableData={programTableData}
          loading={loading}
          setLoading={setLoading}
          getProgramListApi={getProgramListApi}
          programErorrHandling={programErorrHandling}
          dockertDetail={dockertDetail}
          gotoProgram={true}
          dockerHeaderData={dockerHeaderData}
        />
      </div>
    </>
  );
};
