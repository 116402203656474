import { MonitoringFunction } from "../MonitoringFunction";

const LinkPersonReferralConfirm = ({
  linkPersonReferralConfirmPopup,
  setLinkPersonReferralConfirmPopup,
  monitoringReferralRowData,
  navigate,
  setLoading,
  setToastConfig,
  toastConfig,
  refreshApi,
  linkPersonRowData,
  setLinkPersonReferral,
}) => {
  const ApiClass = new MonitoringFunction();
  const data = {
    gReferralID: monitoringReferralRowData?.gReferralId,
    gPersonID: linkPersonRowData?.gPersonId,
  };

  const handleSave = () => {
    ApiClass.linkPersonMonitoringReferral(
      setLinkPersonReferralConfirmPopup,
      navigate,
      setLoading,
      setToastConfig,
      toastConfig,
      refreshApi,
      data,
      setLinkPersonReferral
    );
  };

  return (
    <>
      <div className="link-person-confirm">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <p>Confirmation</p>
            </div>
          </div>
          <svg
            onClick={() => {
              setLinkPersonReferralConfirmPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="popup-container-data">
          <div className="flex" style={{ gap: "5px", alignItems: "center" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28px"
              height="28px"
              viewBox="0 0 24 24"
              fill="none"
              style={{ cursor: "pointer" }}
              className="svg-blue-black"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 7.75C11.3787 7.75 10.875 8.25368 10.875 8.875C10.875 9.28921 10.5392 9.625 10.125 9.625C9.71079 9.625 9.375 9.28921 9.375 8.875C9.375 7.42525 10.5503 6.25 12 6.25C13.4497 6.25 14.625 7.42525 14.625 8.875C14.625 9.58584 14.3415 10.232 13.883 10.704C13.7907 10.7989 13.7027 10.8869 13.6187 10.9708C13.4029 11.1864 13.2138 11.3753 13.0479 11.5885C12.8289 11.8699 12.75 12.0768 12.75 12.25V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V12.25C11.25 11.5948 11.555 11.0644 11.8642 10.6672C12.0929 10.3733 12.3804 10.0863 12.6138 9.85346C12.6842 9.78321 12.7496 9.71789 12.807 9.65877C13.0046 9.45543 13.125 9.18004 13.125 8.875C13.125 8.25368 12.6213 7.75 12 7.75ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                fill="white"
              />
            </svg>
            <p className="edit_container_label">
              Are you sure you want to link this referral to{" "}
              {linkPersonRowData?.Name?.split(", ")?.reverse()?.join(" ")}?
            </p>
          </div>
          <div className="popup_buttons">
            <button className="btn primary_btn" onClick={handleSave}>
              Yes
            </button>
            <button
              className="btn"
              onClick={() => {
                setLinkPersonReferralConfirmPopup(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkPersonReferralConfirm;
