import axios from "axios";
import { logOut } from "../../components/Logout";

export const checkValidation_Detail_Tab_address = (Detail_Tab_address_getData) => {
  return (
    !Detail_Tab_address_getData.MailingAddress.AddressLine1 ||
    !Detail_Tab_address_getData.MailingAddress.AddressLine2 ||
    !Detail_Tab_address_getData.MailingAddress.City ||
    !Detail_Tab_address_getData.MailingAddress.State ||
    !Detail_Tab_address_getData.MailingAddress.ZIP ||
    !Detail_Tab_address_getData.MailingAddress.Country ||
    !Detail_Tab_address_getData.MailingAddress.Comment ||
    !Detail_Tab_address_getData.HomeAddress.AddressLine1 ||
    !Detail_Tab_address_getData.HomeAddress.AddressLine2 ||
    !Detail_Tab_address_getData.HomeAddress.City ||
    !Detail_Tab_address_getData.HomeAddress.State ||
    !Detail_Tab_address_getData.HomeAddress.ZIP ||
    !Detail_Tab_address_getData.HomeAddress.Country ||
    !Detail_Tab_address_getData.HomeAddress.Comment ||
    !Detail_Tab_address_getData.WorkAddress.Employer ||
    !Detail_Tab_address_getData.WorkAddress.AddressLine1 ||
    !Detail_Tab_address_getData.WorkAddress.AddressLine2 ||
    !Detail_Tab_address_getData.WorkAddress.City ||
    !Detail_Tab_address_getData.WorkAddress.State ||
    !Detail_Tab_address_getData.WorkAddress.ZIP ||
    !Detail_Tab_address_getData.WorkAddress.Country ||
    !Detail_Tab_address_getData.WorkAddress.Comment
  );
};

export const checkValidation_Detail_name_tab = (Detail_Tab_Name_getData, Detail_name_tab_Validation) => {
  return (
    Detail_Tab_Name_getData.LastName &&
    Detail_Tab_Name_getData.DateOfBirth &&
    Detail_Tab_Name_getData.FirstName &&
    Detail_Tab_Name_getData.EffectiveDate &&
    Detail_Tab_Name_getData.StatePreference.name
  );
};

export const checkValidation_Detail_Payment_Tab = (Detail_Payment_Tab_getData) => {
  return (
    !Detail_Payment_Tab_getData.PaymentNotes ||
    !Detail_Payment_Tab_getData.Frequency ||
    !Detail_Payment_Tab_getData.Amount ||
    !Detail_Payment_Tab_getData.NextPaymentDate ||
    !Detail_Payment_Tab_getData.ComplianceLevel ||
    !Detail_Payment_Tab_getData.ActionPlan
  );
};

export const set_checkValidation_Detail_Payment_Tab = (
  Detail_Payment_Tab_getData,
  dispatch,
  detail_Payment_Tab_Validation,
  Detail_Payment_Tab_Validation
) => {
  dispatch(
    detail_Payment_Tab_Validation({
      ...Detail_Payment_Tab_Validation,
      PaymentNotes: Detail_Payment_Tab_getData.PaymentNotes ? "" : "Payment notes is required",
      Frequency: Detail_Payment_Tab_getData.Frequency ? "" : "Frequency notes is required",
      Amount: Detail_Payment_Tab_getData.Amount ? "" : "Amount is required",
      NextPaymentDate: Detail_Payment_Tab_getData.NextPaymentDate ? "" : "Next payment date is required",
      ComplianceLevel: Detail_Payment_Tab_getData.ComplianceLevel ? "" : "Compliance level is required",
      ActionPlan: Detail_Payment_Tab_getData.ActionPlan ? "" : "Action plan is required",
    })
  );
};

const datail_switch = (Detail_Tab_Name_getData) => {
  const data = {};

  switch (true) {
    case !Detail_Tab_Name_getData.LastName:
      data.LastName = "Last Name is required";
      break;
    case Detail_Tab_Name_getData.LastName:
      data.LastName = "";
      break;
    default:
      data.LastName = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_Name_getData.DateOfBirth:
      data.DateOfBirth = "DateOfBirth is required";
      break;
    case Detail_Tab_Name_getData.DateOfBirth:
      data.DateOfBirth = "";
      break;
    default:
      data.DateOfBirth = "";
      break;
  }

  return data;
};

export const set_checkValidation_Detail_name_tab = (
  Detail_Tab_Name_getData,
  dispatch,
  detail_name_tab_Validation,
  Detail_name_tab_Validation
) => {
  dispatch(
    detail_name_tab_Validation({
      ...Detail_name_tab_Validation,
      ...datail_switch(Detail_Tab_Name_getData),
    })
  );
};

const a = (Detail_Tab_address_getData) => {
  let MailingAddress_data = {};

  switch (true) {
    case !Detail_Tab_address_getData.MailingAddress.AddressLine1:
      MailingAddress_data.AddressLine1 = "Address line 1 is required";
      break;
    case Detail_Tab_address_getData.MailingAddress.AddressLine1:
      MailingAddress_data.AddressLine1 = "";
      break;
    default:
      MailingAddress_data.AddressLine1 = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_address_getData.MailingAddress.AddressLine2:
      MailingAddress_data.AddressLine2 = "Address line 2 is required";
      break;
    case Detail_Tab_address_getData.MailingAddress.AddressLine2:
      MailingAddress_data.AddressLine2 = "";
      break;
    default:
      MailingAddress_data.AddressLine2 = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_address_getData.MailingAddress.City:
      MailingAddress_data.City = "City is required";
      break;
    case Detail_Tab_address_getData.MailingAddress.City:
      MailingAddress_data.City = "";
      break;
    default:
      MailingAddress_data.City = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_address_getData.MailingAddress.State:
      MailingAddress_data.State = "State is required";
      break;
    case Detail_Tab_address_getData.MailingAddress.State:
      MailingAddress_data.State = "";
      break;
    default:
      MailingAddress_data.State = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_address_getData.MailingAddress.ZIP:
      MailingAddress_data.ZIP = "ZIP is required";
      break;
    case Detail_Tab_address_getData.MailingAddress.ZIP:
      MailingAddress_data.ZIP = "";
      break;
    default:
      MailingAddress_data.ZIP = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_address_getData.MailingAddress.Country:
      MailingAddress_data.Country = "Country is required";
      break;
    case Detail_Tab_address_getData.MailingAddress.Country:
      MailingAddress_data.Country = "";
      break;
    default:
      MailingAddress_data.Country = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_address_getData.MailingAddress.Comment:
      MailingAddress_data.Comment = "Comment is required";
      break;
    case Detail_Tab_address_getData.MailingAddress.Comment:
      MailingAddress_data.Comment = "";
      break;
    default:
      MailingAddress_data.Comment = "";
      break;
  }

  return MailingAddress_data;
};
const b = (Detail_Tab_address_getData) => {
  let HomeAddress_data = {};
  switch (true) {
    case !Detail_Tab_address_getData.HomeAddress.AddressLine1:
      HomeAddress_data.AddressLine1 = "Address line 1 is required";
      break;
    case Detail_Tab_address_getData.HomeAddress.AddressLine1:
      HomeAddress_data.AddressLine1 = "";
      break;
    default:
      HomeAddress_data.AddressLine1 = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_address_getData.HomeAddress.AddressLine2:
      HomeAddress_data.AddressLine2 = "Address line 2 is required";
      break;
    case Detail_Tab_address_getData.HomeAddress.AddressLine2:
      HomeAddress_data.AddressLine2 = "";
      break;
    default:
      HomeAddress_data.AddressLine2 = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_address_getData.HomeAddress.City:
      HomeAddress_data.City = "City is required";
      break;
    case Detail_Tab_address_getData.HomeAddress.City:
      HomeAddress_data.City = "";
      break;
    default:
      HomeAddress_data.City = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_address_getData.HomeAddress.State:
      HomeAddress_data.State = "State is required";
      break;
    case Detail_Tab_address_getData.HomeAddress.State:
      HomeAddress_data.State = "";
      break;
    default:
      HomeAddress_data.State = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_address_getData.HomeAddress.ZIP:
      HomeAddress_data.ZIP = "ZIP is required";
      break;
    case Detail_Tab_address_getData.HomeAddress.ZIP:
      HomeAddress_data.ZIP = "";
      break;
    default:
      HomeAddress_data.ZIP = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_address_getData.HomeAddress.Country:
      HomeAddress_data.Country = "Country is required";
      break;
    case Detail_Tab_address_getData.HomeAddress.Country:
      HomeAddress_data.Country = "";
      break;
    default:
      HomeAddress_data.Country = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_address_getData.HomeAddress.Comment:
      HomeAddress_data.Comment = "Comment is required";
      break;
    case Detail_Tab_address_getData.HomeAddress.Comment:
      HomeAddress_data.Comment = "";
      break;
    default:
      HomeAddress_data.Comment = "";
      break;
  }

  return HomeAddress_data;
};
const c = (Detail_Tab_address_getData) => {
  const WorkAddress_data = {};
  switch (true) {
    case !Detail_Tab_address_getData.WorkAddress.Employer:
      WorkAddress_data.Employer = "Employer is required";
      break;
    case Detail_Tab_address_getData.WorkAddress.Employer:
      WorkAddress_data.Employer = "";
      break;
    default:
      WorkAddress_data.Employer = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_address_getData.WorkAddress.AddressLine1:
      WorkAddress_data.AddressLine1 = "Address line 1 is required";
      break;
    case Detail_Tab_address_getData.WorkAddress.AddressLine1:
      WorkAddress_data.AddressLine1 = "";
      break;
    default:
      WorkAddress_data.AddressLine1 = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_address_getData.WorkAddress.AddressLine2:
      WorkAddress_data.AddressLine2 = "Address line 2 is required";
      break;
    case Detail_Tab_address_getData.WorkAddress.AddressLine2:
      WorkAddress_data.AddressLine2 = "";
      break;
    default:
      WorkAddress_data.AddressLine2 = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_address_getData.WorkAddress.City:
      WorkAddress_data.City = "City is required";
      break;
    case Detail_Tab_address_getData.WorkAddress.City:
      WorkAddress_data.City = "";
      break;
    default:
      WorkAddress_data.City = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_address_getData.WorkAddress.State:
      WorkAddress_data.State = "State is required";
      break;
    case Detail_Tab_address_getData.WorkAddress.State:
      WorkAddress_data.State = "";
      break;
    default:
      WorkAddress_data.State = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_address_getData.WorkAddress.ZIP:
      WorkAddress_data.ZIP = "ZIP is required";
      break;
    case Detail_Tab_address_getData.WorkAddress.ZIP:
      WorkAddress_data.ZIP = "";
      break;
    default:
      WorkAddress_data.ZIP = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_address_getData.WorkAddress.Country:
      WorkAddress_data.Country = "Country is required";
      break;
    case Detail_Tab_address_getData.WorkAddress.Country:
      WorkAddress_data.Country = "";
      break;
    default:
      WorkAddress_data.Country = "";
      break;
  }

  switch (true) {
    case !Detail_Tab_address_getData.WorkAddress.Comment:
      WorkAddress_data.Comment = "Comment is required";
      break;
    case Detail_Tab_address_getData.WorkAddress.Comment:
      WorkAddress_data.Comment = "";
      break;
    default:
      WorkAddress_data.Comment = "";
      break;
  }

  return WorkAddress_data;
};

const address_switch = (Detail_Tab_address_getData) => {
  return {
    MailingAddress: {
      ...a(Detail_Tab_address_getData),
    },
    HomeAddress: {
      ...b(Detail_Tab_address_getData),
    },
    WorkAddress: {
      ...c(Detail_Tab_address_getData),
    },
  };
};

export const set_detail_Tab_address_Validation = (
  Detail_Tab_address_getData,
  dispatch,
  detail_Tab_address_Validation,
  Detail_Tab_address_Validation
) => {
  dispatch(
    detail_Tab_address_Validation({
      ...Detail_Tab_address_Validation,
      ...address_switch(Detail_Tab_address_getData),
    })
  );
};

export const checkValidation_Detail_Notification_Tab = (Detail_Notification_Tab_getData) => {
  return !Detail_Notification_Tab_getData.PaymentNote;
};

export const set_checkValidation_Detail_Notification_Tab = (
  Detail_Notification_Tab_getData,
  dispatch,
  detail_co_obligor_Tab_Validation,
  Detail_co_obligor_Tab_Validation
) => {
  dispatch(
    detail_co_obligor_Tab_Validation({
      ...Detail_co_obligor_Tab_Validation,
      PaymentNote: Detail_Notification_Tab_getData.PaymentNote ? "" : "Payment note is required",
    })
  );
};

export const checkValidation_co_obligor_Tab_Tab = (Detail_co_obligor_Tab_getData) => {
  return (
    !Detail_co_obligor_Tab_getData.FirstNmae ||
    !Detail_co_obligor_Tab_getData.MiddleName ||
    !Detail_co_obligor_Tab_getData.LastName ||
    !Detail_co_obligor_Tab_getData.AddressLine1 ||
    !Detail_co_obligor_Tab_getData.AddressLine2 ||
    !Detail_co_obligor_Tab_getData.City ||
    !Detail_co_obligor_Tab_getData.State ||
    !Detail_co_obligor_Tab_getData.ZIP ||
    !Detail_co_obligor_Tab_getData.SSN
  );
};

function maskString(input) {
  const remainingLength = Math.max(9 - input.length, 0);
  const mask = " ".repeat(remainingLength);
  return input + mask;
}

export const set_checkValidation_co_obligor_Tab_Tab = (
  Detail_co_obligor_Tab_getData,
  dispatch,
  detail_co_obligor_Tab_Validation,
  Detail_co_obligor_Tab_Validation
) => {
  return dispatch(
    detail_co_obligor_Tab_Validation({
      ...Detail_co_obligor_Tab_Validation,
      FirstNmae: Detail_co_obligor_Tab_getData.FirstNmae ? "" : "First name is required",
      MiddleName: Detail_co_obligor_Tab_getData.MiddleName ? "" : "Middle name is required",
      LastName: Detail_co_obligor_Tab_getData.LastName ? "" : "Last name is required",
      AddressLine1: Detail_co_obligor_Tab_getData.AddressLine1 ? "" : "Address Line 1 is required",
      AddressLine2: Detail_co_obligor_Tab_getData.AddressLine2 ? "" : "Address Line 2 is required",
      City: Detail_co_obligor_Tab_getData.City ? "" : "City is required",
      State: Detail_co_obligor_Tab_getData.State ? "" : "State is required",
      ZIP: Detail_co_obligor_Tab_getData.ZIP ? "" : "ZIP is required",
      SSN: Detail_co_obligor_Tab_getData.SSN ? "" : "SSN is required",
    })
  );
};

export const SaveBody = (
  Detail_Tab_Name_getData,
  gPersonId,
  Detail_Tab_address_getData,
  Detail_co_obligor_Tab_getData,
  Detail_Payment_Tab_getData,
  Detail_Notification_Tab_getData,
  GPersonTextContactInfoId,
  GPersonEmailContactInfoId
) => {
  return {
    gPersonId: gPersonId,
    gLanguageID: Detail_Tab_Name_getData?.Language?.id,

    gRaceID: Detail_Tab_Name_getData?.Race?.id,

    gHairColorID: Detail_Tab_Name_getData?.HairColor?.id,
    gEyeColorID: Detail_Tab_Name_getData?.EyeColor?.id,

    gComplianceLevelID: Detail_Payment_Tab_getData?.ComplianceLevel?.id,

    bPaymentBlock: false,
    bOnlineReporting: false,
    bCreditCard: false,
    bFeeBlock: false,
    sOffenderNumberState: Detail_Tab_Name_getData?.StatePreference?.id,
    sOffenderNumberPIN: Detail_Tab_Name_getData?.AltID,
    sSSN: true ? maskString(String(Detail_Tab_Name_getData?.SSN)) : Detail_Tab_Name_getData?.SSN,
    sFirstName: Detail_Tab_Name_getData?.FirstName,
    sLastName: Detail_Tab_Name_getData?.LastName,
    sMiddleName: Detail_Tab_Name_getData?.MiddleName,
    iHeightFoot: Detail_Tab_Name_getData?.Height?.feet ? Detail_Tab_Name_getData?.Height?.feet : "0",
    iHeightInch: Detail_Tab_Name_getData?.Height?.inch ? Detail_Tab_Name_getData?.Height?.inch : "0",
    iWeight: Detail_Tab_Name_getData?.Weight ? Detail_Tab_Name_getData?.Weight : "0",
    dDob: Detail_Tab_Name_getData?.DateOfBirth,
    SGender: Detail_Tab_Name_getData?.Gender?.id,

    dEffective: Detail_Tab_Name_getData?.EffectiveDate,

    sMailingAddress1: Detail_Tab_address_getData?.MailingAddress?.AddressLine1,
    sMailingAddress2: Detail_Tab_address_getData?.MailingAddress?.AddressLine2,
    sMailingState: Detail_Tab_address_getData?.MailingAddress?.State,
    sMailingZip: Detail_Tab_address_getData?.MailingAddress?.ZIP,
    sMailingAddressComment: Detail_Tab_address_getData?.MailingAddress?.Comment,
    sHomeAddress1: Detail_Tab_address_getData?.HomeAddress?.AddressLine1,
    sHomeAddress2: Detail_Tab_address_getData?.HomeAddress?.AddressLine2,
    sHomeState: Detail_Tab_address_getData?.HomeAddress?.State,
    sHomeZip: Detail_Tab_address_getData?.HomeAddress?.ZIP,
    sHomeAddressComment: Detail_Tab_address_getData?.HomeAddress?.Comment,
    sWorkEmployer: Detail_Tab_address_getData?.WorkAddress?.Employer,
    sWorkAddress1: Detail_Tab_address_getData?.WorkAddress?.AddressLine1,
    sWorkAddress2: Detail_Tab_address_getData?.WorkAddress?.AddressLine2,
    sWorkState: Detail_Tab_address_getData?.WorkAddress?.State,
    sWorkZip: Detail_Tab_address_getData?.WorkAddress?.ZIP,
    sWorkAddressComment: Detail_Tab_address_getData?.WorkAddress?.Comment,

    sMailingCity: Detail_Tab_address_getData?.MailingAddress?.City,
    sHomeCity: Detail_Tab_address_getData?.HomeAddress?.City,
    sWorkCity: Detail_Tab_address_getData?.WorkAddress?.City,
    sWorkCounty: Detail_Tab_address_getData?.WorkAddress?.Country,
    sHomeCounty: Detail_Tab_address_getData?.HomeAddress?.Country,
    sMailingCounty: Detail_Tab_address_getData?.MailingAddress?.Country,

    sGuarantorFirstName: Detail_co_obligor_Tab_getData?.FirstNmae,

    sGuarantorMiddleName: Detail_co_obligor_Tab_getData?.MiddleName,

    sGuarantorLastName: Detail_co_obligor_Tab_getData?.LastName,

    sGuarantorAddress1: Detail_co_obligor_Tab_getData?.AddressLine1,

    sGuarantorAddress2: Detail_co_obligor_Tab_getData?.AddressLine2,

    sGuarantorCity: Detail_co_obligor_Tab_getData?.City,
    sGuarantorState: Detail_co_obligor_Tab_getData?.State,

    sGuarantorZip: Detail_co_obligor_Tab_getData?.ZIP,

    SGuarantorHomePhone: String(Detail_co_obligor_Tab_getData?.HomePhone).replace(/[()\-\s]/g, ""),
    SGuarantorWorkPhone: String(Detail_co_obligor_Tab_getData?.WorkPhone).replace(/[()\-\s]/g, ""),
    SGuarantorOtherPhone: String(Detail_co_obligor_Tab_getData?.CellPhone).replace(/[()\-\s]/g, ""),
    sGuarantorDOB: Detail_co_obligor_Tab_getData?.DOB,
    sGuarantorCardOnFile: Detail_co_obligor_Tab_getData?.CardOnFile,

    sGuarantorSSN: Detail_co_obligor_Tab_getData?.SSN,

    sAlertNote: Detail_Notification_Tab_getData?.AlertNote,

    dNextPaymentOverride: Detail_Payment_Tab_getData?.NextPaymentDate,

    sActionPlan: Detail_Payment_Tab_getData?.ActionPlan,

    sPaymentAmount: Detail_Payment_Tab_getData?.Amount,

    sPaymentFrequency: Detail_Payment_Tab_getData?.Frequency?.name,

    sPaymentNote: Detail_Payment_Tab_getData?.PaymentNotes,
    GPersonTextContactInfoId: GPersonTextContactInfoId,
    GPersonEmailContactInfoId: GPersonEmailContactInfoId,
  };
};

export const AddBody = (
  Detail_Tab_Name_getData,
  gPersonId,
  sOffenderNumber,
  Detail_Tab_address_getData,
  PaymentBlock,
  OnlineReporting,
  RecurringCreditCard,
  Feeblock,
  Detail_co_obligor_Tab_getData,
  Detail_Payment_Tab_getData,
  Detail_Notification_Tab_getData,
  contectInfoTabWhileAddParticipent
) => {
  return {
    GPersonId: null,
    DDoe: null,
    GEntryUserId: localStorage.getItem("gUserId"),
    SSsn: Detail_Tab_Name_getData?.SSN ? maskString(String(Detail_Tab_Name_getData?.SSN)) : null,
    SFirstName: Detail_Tab_Name_getData?.FirstName ? Detail_Tab_Name_getData?.FirstName : null,
    SMiddleName: Detail_Tab_Name_getData?.MiddleName ? Detail_Tab_Name_getData?.MiddleName : null,
    SLastName: Detail_Tab_Name_getData?.LastName ? Detail_Tab_Name_getData?.LastName : null,
    SOffenderNumberPin: Detail_Tab_Name_getData?.AltID ? Detail_Tab_Name_getData?.AltID : null,
    SOffenderNumber: null,
    SOffenderNumberState: Detail_Tab_Name_getData?.StatePreference?.id,
    SOffenderNumberSequence: null,
    IOffenderNumberSequence: null,
    DDob: Detail_Tab_Name_getData?.DateOfBirth ? Detail_Tab_Name_getData?.DateOfBirth : null,
    GLanguageId: Detail_Tab_Name_getData?.Language?.id ? Detail_Tab_Name_getData?.Language?.id : null,
    SWorkAddress1: Detail_Tab_address_getData?.WorkAddress?.AddressLine1
      ? Detail_Tab_address_getData?.WorkAddress?.AddressLine1
      : null,
    SWorkAddress2: Detail_Tab_address_getData?.WorkAddress?.AddressLine2
      ? Detail_Tab_address_getData?.WorkAddress?.AddressLine2
      : null,
    GWorkCityId: null,
    SWorkState: Detail_Tab_address_getData?.WorkAddress?.State ? Detail_Tab_address_getData?.WorkAddress?.State : null,
    SWorkZip: Detail_Tab_address_getData?.WorkAddress?.ZIP ? Detail_Tab_address_getData?.WorkAddress?.ZIP : null,
    SWorkZip4: null,
    GWorkCountyId: null,
    IWorkAddressChange: null,
    SMailingAddress1: Detail_Tab_address_getData?.MailingAddress?.AddressLine1
      ? Detail_Tab_address_getData?.MailingAddress?.AddressLine1
      : null,
    SMailingAddress2: Detail_Tab_address_getData?.MailingAddress?.AddressLine2
      ? Detail_Tab_address_getData?.MailingAddress?.AddressLine2
      : null,
    GMailingCityId: null,
    SMailingState: Detail_Tab_address_getData?.MailingAddress?.State
      ? Detail_Tab_address_getData?.MailingAddress?.State
      : null,
    SMailingZip: Detail_Tab_address_getData?.MailingAddress?.ZIP
      ? Detail_Tab_address_getData?.MailingAddress?.ZIP
      : null,
    SMailingZip4: null,
    GMailingCountyId: null,
    IMailingAddressChange: null,
    SHomeAddress1: Detail_Tab_address_getData?.HomeAddress?.AddressLine1
      ? Detail_Tab_address_getData?.HomeAddress?.AddressLine1
      : null,
    SHomeAddress2: Detail_Tab_address_getData?.HomeAddress?.AddressLine2
      ? Detail_Tab_address_getData?.HomeAddress?.AddressLine2
      : null,
    GHomeCityId: null,
    SHomeState: Detail_Tab_address_getData?.HomeAddress?.State ? Detail_Tab_address_getData?.HomeAddress?.State : null,
    SHomeZip: Detail_Tab_address_getData?.HomeAddress?.ZIP ? Detail_Tab_address_getData?.HomeAddress?.ZIP : null,
    SHomeZip4: null,
    GHomeCountyId: null,
    IHomeAddressChange: null,
    DDriverLicenseIssue: null,
    SDriverLicenseNumber: null,
    SDriverLicenseState: null,
    DDriverLicenseExpires: null,
    SDriverLicenseClass: null,
    GRaceId: Detail_Tab_Name_getData?.Race?.id ? Detail_Tab_Name_getData?.Race?.id : null,
    GHairColorId: Detail_Tab_Name_getData?.HairColor?.id ? Detail_Tab_Name_getData?.HairColor?.id : null,
    IHeightFoot: Detail_Tab_Name_getData?.Height?.feet ? Detail_Tab_Name_getData?.Height?.feet : "0",
    IHeightInch: Detail_Tab_Name_getData?.Height?.inch ? Detail_Tab_Name_getData?.Height?.inch : "0",
    IWeight: Detail_Tab_Name_getData?.Weight ? Detail_Tab_Name_getData?.Weight : "0",
    SGender: Detail_Tab_Name_getData?.Gender?.id ? Detail_Tab_Name_getData?.Gender?.id : null,
    GEyeColorId: Detail_Tab_Name_getData?.EyeColor?.id ? Detail_Tab_Name_getData?.EyeColor?.id : null,
    SDriverLicenseEndorsement: null,
    DEffective: Detail_Tab_Name_getData?.EffectiveDate ? Detail_Tab_Name_getData?.EffectiveDate : null,
    SCcFirstName: null,
    SCcLastName: null,
    SCcBillingZipcode: null,
    SCcEmail: null,
    GPinId: null,
    GPhotoId: null,
    SOffenderNumberPinSequence: null,
    SSsnlast4: null,
    FAmountOwedPin: null,
    FAmountPaidPin: null,
    FAmountOnHandPin: null,
    FAmountDisbursedPin: null,
    FCreditBalancePin: null,
    SSupervisionStatusPin: null,
    DDoePin: null,
    FCreditBalance: 0.0,
    FBalanceEligible: 0.0,
    FArrears: 0.0,
    GSupervisionStatusId: null,
    GFinancialStatusId: null,
    DSupervisionStatusPin: null,
    DFinancialStatusPin: null,
    SFinancialStatusPin: null,
    SWorkEmployer: Detail_Tab_address_getData?.WorkAddress?.Employer
      ? Detail_Tab_address_getData?.WorkAddress?.Employer
      : null,
    SWorkAddressComment: Detail_Tab_address_getData?.WorkAddress?.Comment
      ? Detail_Tab_address_getData?.WorkAddress?.Comment
      : null,
    SMailingAddressComment: Detail_Tab_address_getData?.MailingAddress?.Comment
      ? Detail_Tab_address_getData?.MailingAddress?.Comment
      : null,
    SHomeAddressComment: Detail_Tab_address_getData?.HomeAddress?.Comment
      ? Detail_Tab_address_getData?.HomeAddress?.Comment
      : null,
    IWarrantActiveCount: null,
    FPaidUnsettled: 0.0,
    BAutoAdded: false,
    BSendToProntoPay: null,
    SLastNameSpaceFirstName: null,
    TsVersion: null,
    FAmount: 0.0,
    FPaid: 0.0,
    FWriteOff: 0.0,
    FBalance: null,
    FArrearsAdjusted: 0.0,
    STextNumber: null,
    GCarrierId: null,
    GPrimarySupervisorId: null,
    GGpmEntityId: null,
    GPersonSupervisionStatusId: null,
    GPersonTextContactInfoId: null,
    GPersonEmailContactInfoId: null,
    GComplianceLevelId: Detail_Payment_Tab_getData?.ComplianceLevel?.id
      ? Detail_Payment_Tab_getData?.ComplianceLevel?.id
      : null,
    BPaymentBlock: PaymentBlock ? PaymentBlock : false,
    SAlertNote: Detail_Notification_Tab_getData?.AlertNote ? Detail_Notification_Tab_getData?.AlertNote : null,
    BOnlineReporting: OnlineReporting ? OnlineReporting : false,
    SGuarantorFirstName: Detail_co_obligor_Tab_getData?.FirstNmae ? Detail_co_obligor_Tab_getData?.FirstNmae : null,
    SGuarantorMiddleName: Detail_co_obligor_Tab_getData?.MiddleName ? Detail_co_obligor_Tab_getData?.MiddleName : null,
    SGuarantorLastName: Detail_co_obligor_Tab_getData?.LastName ? Detail_co_obligor_Tab_getData?.LastName : null,
    SGuarantorAddress1: Detail_co_obligor_Tab_getData?.AddressLine1
      ? Detail_co_obligor_Tab_getData?.AddressLine1
      : null,
    SGuarantorAddress2: Detail_co_obligor_Tab_getData?.AddressLine2
      ? Detail_co_obligor_Tab_getData?.AddressLine2
      : null,
    SGuarantorCity: Detail_co_obligor_Tab_getData?.City ? Detail_co_obligor_Tab_getData?.City : null,
    SGuarantorState: Detail_co_obligor_Tab_getData?.State ? Detail_co_obligor_Tab_getData?.State : null,
    SGuarantorZip: Detail_co_obligor_Tab_getData?.ZIP ? Detail_co_obligor_Tab_getData?.ZIP : null,
    SGuarantorSsn: Detail_co_obligor_Tab_getData?.SSN ? Detail_co_obligor_Tab_getData?.SSN : null,
    DGuarantorDob: Detail_co_obligor_Tab_getData?.DOB ? Detail_co_obligor_Tab_getData?.DOB : null,
    SGuarantorHomePhone: Detail_co_obligor_Tab_getData?.HomePhone
      ? String(Detail_co_obligor_Tab_getData?.HomePhone).replace(/[()\-\s]/g, "")
      : null,
    SGuarantorWorkPhone: Detail_co_obligor_Tab_getData?.WorkPhone
      ? String(Detail_co_obligor_Tab_getData?.WorkPhone).replace(/[()\-\s]/g, "")
      : null,
    SGuarantorOtherPhone: Detail_co_obligor_Tab_getData?.CellPhone
      ? String(Detail_co_obligor_Tab_getData?.CellPhone).replace(/[()\-\s]/g, "")
      : null,
    BCreditCard: RecurringCreditCard ? RecurringCreditCard : false,
    DNextPaymentOverride: Detail_Payment_Tab_getData?.NextPaymentDate
      ? Detail_Payment_Tab_getData?.NextPaymentDate
      : null,
    SActionPlan: Detail_Payment_Tab_getData?.ActionPlan ? Detail_Payment_Tab_getData?.ActionPlan : null,
    SPaymentAmount: Detail_Payment_Tab_getData?.Amount ? Detail_Payment_Tab_getData?.Amount : null,
    SPaymentFrequency: Detail_Payment_Tab_getData?.Frequency?.name ? Detail_Payment_Tab_getData?.Frequency?.name : null,
    SPaymentNote: Detail_Payment_Tab_getData?.PaymentNotes ? Detail_Payment_Tab_getData?.PaymentNotes : null,
    BGuarantorCardOnFile: Detail_co_obligor_Tab_getData?.CardOnFile ? Detail_co_obligor_Tab_getData?.CardOnFile : null,
    BFeeBlock: Feeblock ? Feeblock : false,
    SWorkCity: Detail_Tab_address_getData?.WorkAddress?.City ? Detail_Tab_address_getData?.WorkAddress?.City : null,
    SWorkCounty: Detail_Tab_address_getData?.WorkAddress?.Country
      ? Detail_Tab_address_getData?.WorkAddress?.Country
      : null,
    SMailingCity: Detail_Tab_address_getData?.MailingAddress?.City
      ? Detail_Tab_address_getData?.MailingAddress?.City
      : null,
    SMailingCounty: Detail_Tab_address_getData?.MailingAddress?.Country
      ? Detail_Tab_address_getData?.MailingAddress?.Country
      : null,
    SHomeCity: Detail_Tab_address_getData?.HomeAddress?.City ? Detail_Tab_address_getData?.HomeAddress?.City : null,
    SHomeCounty: Detail_Tab_address_getData?.HomeAddress?.Country
      ? Detail_Tab_address_getData?.HomeAddress?.Country
      : null,
    ContactInfo: contectInfoTabWhileAddParticipent,
  };
};

export const getCustomFlag = async (navigate, pathname, recordId, customflagval) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/CustomFlag/getCustomFlagData?recordId=${recordId}&Type=Person`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        Domain: `${pathname}`,
      },
    })
    .then((val) => {
      customflagval(val?.data?.data);
    })
    .catch((val) => {
      if (!val?.response) {
        navigate(`/error/500`);
      } else if (val?.response?.status == 401) {
        logOut();
      }
    });
};

export const setCustomFlag = async (navigate, pathname, body) => {
  await axios
    .post(`${process.env.REACT_APP_API_URL}/api/CustomFlag/updateCustomFlagData`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        Domain: `${pathname}`,
      },
    })
    .then((val) => {
      console.log("done");
    })
    .catch((val) => {
      if (!val?.response) {
        navigate(`/error/500`);
      } else if (val?.response?.status == 401) {
        logOut();
      }
    });
};
