import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import '../src/assets/css/index.scss';
import '../src/style.scss';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider} from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import UnauthenticatedContent from './UnauthenticatedContent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return <UnauthenticatedContent />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </NavigationProvider>
      </AuthProvider>
      <ToastContainer />
    </Router>
  );
}
