import React from "react";
import "./appointmentTooltip.css";

const AppointmentTooltip = (props) => {
  const convertDateFormat = (dateString) => {
    if (dateString) {
      const dateObj = new Date(dateString);
      return dateObj.toLocaleDateString("en-US");
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="tooltip">
        <div className="tooltip-header">
          {props?.data?.appointmentData?.toolTipName}
        </div>
        <div className="tooltip-content-info">
          <div className="tooltip-info">
            <p className="">Start:</p>
            <p>{convertDateFormat(props?.data?.appointmentData?.startDate)}</p>
            <p>{props?.data?.appointmentData?.toolTipTime}</p>
          </div>
          <div className="tooltip-info">
            <p className="">End: &nbsp;</p>
            <p>{convertDateFormat(props?.data?.appointmentData?.endDate)}</p>
            <p>{props?.data?.appointmentData?.toolTipTime}</p>
          </div>
        </div>
        <div className="tooltip-content-info">
          {props?.data?.appointmentData?.location && (
            <div className="tooltip-info">
              <p className="">Location:</p>
              <p>{props?.data?.appointmentData?.location}</p>
            </div>
          )}
          <div className="tooltip-info">
            <p className="">Reminder:</p>
            <p>None</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentTooltip;
