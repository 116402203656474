import { useState, useEffect } from "react";
import image1 from "../../assets/images/image5.jpg";
import uploadimage from "../../assets/images/icons/upload.svg";
import "./HeaderPopupComponent/models.css";
import { useDispatch, useSelector } from "react-redux";
import LoadPanel from "devextreme-react/load-panel";
import { Popup } from "devextreme-react/popup";
import {
  changeCancel,
  detail_Notification_Tab_getData,
  detail_Payment_Tab_getData,
  detail_Tab_Name_getData,
  detail_Tab_address_getData,
  detail_co_obligor_Tab_getData,
  detail_name_tab_Validation,
  headerCheckBoc,
  remove_dynamicParticipatTab,
  setAltertPopup,
  setcontectInfoTabWhileAddParticipent,
  setefffectiveData,
  setfirstnamevalidation,
  setheaderCheckBoxChange,
  setparticipantdata,
  setstateparticipent,
  updateset_dynamicParticipatTab,
} from "../../reducer/ParticipateReducer";
import {
  AddBody,
  SaveBody,
  checkValidation_Detail_name_tab,
  getCustomFlag,
  setCustomFlag,
} from "./ParticipantsValidationFunction";
import { useNavigate, useParams } from "react-router-dom";
import { handleGetLocal } from "../../services/auth-service";
import axios from "axios";
import { DetailData, DetailData1, DetailData2, DetailData3 } from "./ParticipantHeaderComponent";
import { useRef } from "react";
import { ParticipentHeaderPopup } from "./ParticipentHeaderPopup";
import { Toster } from "../../components/Toster/Toster";
import { logOut } from "../../components/Logout";
import { ApiError } from "../Error/ApiError";
import { ParticipentSubTabs } from "./ParticipantSubTabs/ParticipentSubTabs";
import { headersidebarfunction } from "../HeaderSideBar/headersidebarfunction";
import HelpPopup from "../HeaderSideBar/HelpPopup";
import { getAccessData } from "../../utils/getAccessPermission";
import { setDynamicTab } from "../../reducer/HomePageReducer";

const BottomCheckbox = ({ userinfo }) => {
  return (
    <>
      <div className="bottom_checkbox_2">
        {userinfo?.bWarrant ? (
          <div className="headerBottomCheckbox">
            <input checked={userinfo?.bWarrant} type={"checkbox"} />
            <svg viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.6667 4.16667H1.33333C0.875 4.16667 0.5 4.54167 0.5 5C0.5 5.45833 0.875 5.83333 1.33333 5.83333H14.6667C15.125 5.83333 15.5 5.45833 15.5 5C15.5 4.54167 15.125 4.16667 14.6667 4.16667ZM1.33333 10H9.66667C10.125 10 10.5 9.625 10.5 9.16667C10.5 8.70833 10.125 8.33333 9.66667 8.33333H1.33333C0.875 8.33333 0.5 8.70833 0.5 9.16667C0.5 9.625 0.875 10 1.33333 10ZM14.6667 0H1.33333C0.875 0 0.5 0.375 0.5 0.833333V0.841667C0.5 1.3 0.875 1.675 1.33333 1.675H14.6667C15.125 1.675 15.5 1.3 15.5 0.841667V0.833333C15.5 0.375 15.125 0 14.6667 0Z"
                fill="red"
              />
            </svg>
            <p style={{ color: "red" }}>Warrant</p>
          </div>
        ) : (
          ""
        )}
        {userinfo?.bJuvenile ? (
          <div className="headerBottomCheckbox">
            <input checked={userinfo?.bJuvenile} type={"checkbox"} />
            <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.0026 7.00016C8.84427 7.00016 10.3359 5.5085 10.3359 3.66683C10.3359 1.82516 8.84427 0.333496 7.0026 0.333496C5.16094 0.333496 3.66927 1.82516 3.66927 3.66683C3.66927 5.5085 5.16094 7.00016 7.0026 7.00016ZM7.0026 8.66683C4.7776 8.66683 0.335938 9.7835 0.335938 12.0002V12.8335C0.335938 13.2918 0.710937 13.6668 1.16927 13.6668H12.8359C13.2943 13.6668 13.6693 13.2918 13.6693 12.8335V12.0002C13.6693 9.7835 9.2276 8.66683 7.0026 8.66683Z"
                fill="#424167"
              />
            </svg>
            <p>Juvenile</p>
          </div>
        ) : (
          ""
        )}
        {userinfo?.bEm ? (
          <div className="headerBottomCheckbox">
            <input checked={userinfo?.bEm} type={"checkbox"} />
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V8C0.5 8.91667 1.25 9.66667 2.16667 9.66667H13.8333C14.75 9.66667 15.5 8.91667 15.5 8V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5ZM13.8333 5.5H11.2167C10.825 5.5 10.5167 5.775 10.4083 6.15C10.1083 7.2 9.125 8 8 8C6.875 8 5.89167 7.2 5.59167 6.15C5.48333 5.775 5.175 5.5 4.78333 5.5H2.16667V3C2.16667 2.54167 2.54167 2.16667 3 2.16667H13C13.4583 2.16667 13.8333 2.54167 13.8333 3V5.5ZM11.225 11.3333H14.6667C15.125 11.3333 15.5 11.7083 15.5 12.1667V13.8333C15.5 14.75 14.75 15.5 13.8333 15.5H2.16667C1.25 15.5 0.5 14.75 0.5 13.8333V12.1667C0.5 11.7083 0.875 11.3333 1.33333 11.3333H4.775C5.16667 11.3333 5.48333 11.6167 5.59167 12C5.88333 13.0583 6.85 13.8333 8 13.8333C9.15 13.8333 10.1167 13.0583 10.4083 12C10.5167 11.6167 10.8333 11.3333 11.225 11.3333Z"
                fill="#424167"
              />
            </svg>
            <p>EM</p>
          </div>
        ) : (
          ""
        )}
        {userinfo?.bMissingPaymentPlan ? (
          <div className="headerBottomCheckbox">
            <input checked={userinfo?.bMissingPaymentPlan} type={"checkbox"} />
            <svg viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19.1693 3.66683V12.0002C19.1693 12.9168 18.4193 13.6668 17.5026 13.6668H4.16927C3.71094 13.6668 3.33594 13.2918 3.33594 12.8335C3.33594 12.3752 3.71094 12.0002 4.16927 12.0002H17.5026V3.66683C17.5026 3.2085 17.8776 2.8335 18.3359 2.8335C18.7943 2.8335 19.1693 3.2085 19.1693 3.66683ZM3.33594 10.3335C1.9526 10.3335 0.835938 9.21683 0.835938 7.8335V2.8335C0.835938 1.45016 1.9526 0.333496 3.33594 0.333496H13.3359C14.7193 0.333496 15.8359 1.45016 15.8359 2.8335V8.66683C15.8359 9.5835 15.0859 10.3335 14.1693 10.3335H3.33594ZM5.83594 5.3335C5.83594 6.71683 6.9526 7.8335 8.33594 7.8335C9.71927 7.8335 10.8359 6.71683 10.8359 5.3335C10.8359 3.95016 9.71927 2.8335 8.33594 2.8335C6.9526 2.8335 5.83594 3.95016 5.83594 5.3335Z"
                fill="#424167"
              />
            </svg>
            <p>Missing Payment Plan</p>
          </div>
        ) : (
          ""
        )}
        {userinfo?.iActiveTasks ? (
          <div className="headerBottomCheckbox">
            <input checked={userinfo?.iActiveTasks} type={"checkbox"} />
            <svg width="20" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.8391 4.84183L12.8141 0.816829C12.5057 0.508496 12.0807 0.333496 11.6391 0.333496H2.33073C1.41406 0.333496 0.664062 1.0835 0.664062 2.00016V12.0085C0.664062 12.9252 1.40573 13.6668 2.3224 13.6668H15.6641C16.5807 13.6668 17.3307 12.9168 17.3307 12.0002V6.02516C17.3307 5.5835 17.1557 5.1585 16.8391 4.84183ZM11.4974 1.5835L16.0807 6.16683H12.3307C11.8724 6.16683 11.4974 5.79183 11.4974 5.3335V1.5835Z"
                fill="#424167"
              />
            </svg>
            <p>Active Task</p>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export const ParticipentHeader = ({ setEditable, editable, editParticipant }) => {
  const pathname = handleGetLocal("pathURL");
  const {
    Detail_name_tab_Validation,
    Detail_Tab_Name_getData,
    Detail_Tab_address_getData,
    cancel,
    PaymentBlock,
    OnlineReporting,
    RecurringCreditCard,
    Feeblock,
    dynamicParticipatTab,
    Detail_co_obligor_Tab_getData,
    Detail_Payment_Tab_getData,
    Detail_Notification_Tab_getData,
    contectInfoTabWhileAddParticipent,
    addworentREfresh,
    entityperson,
    GPersonTextContactInfoId,
    GPersonEmailContactInfoId,
  } = useSelector((store) => store.participate);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userAccessData } = useSelector((store) => store.Home);

  const [userinfo, setUserinfo] = useState({});
  const [hideOnhandUnsettled, setHideOnhandUnsettled] = useState(false);
  const [scram, setScram] = useState(false);
  const [scramUrls, setScramUrls] = useState({
    cam: "",
    gps: "",
    equipment: "",
  });
  const [saveContact, setSaveContact] = useState(false);
  const [goodToSaveParticipant, setGoodToSaveParticipant] = useState(false);
  const [loading, setLoding] = useState(false);
  const idd = useParams();
  const [errorHandling, seterrorHandling] = useState(false);
  const [errorHandling2, seterrorHandling2] = useState(false);
  const [errorHandling3, seterrorHandling3] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [customflagval, setcustomflagval] = useState([]);
  const inputRef = useRef(null);
  const [image, setImage] = useState(null);
  const [getimage, setgetimage] = useState(null);
  const [viewAccess, setViewAccess] = useState(true);
  const [editAccess, setEditAccess] = useState(false);

  const getHideOnhandUnsettledOption = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/Account/getCompanyOption?Name=Hide OnHand / Unsettled`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      );
      // setHideOnhandUnsettled(response?.data?.data === "False");
      setHideOnhandUnsettled(response?.data?.data.trim().toLowerCase() === "true");
     
    } catch (error) {
      console.error("Error fetching Hide OnHand / Unsettled option:", error);
    }
  };

  useEffect(() => {
    getHideOnhandUnsettledOption();
  }, []);

  const updateTheDatafunctionApi = async () => {
    setLoding(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Person/editPersonInfoDetail`,
        SaveBody(
          Detail_Tab_Name_getData,
          userinfo?.gPersonId,
          Detail_Tab_address_getData,
          Detail_co_obligor_Tab_getData,
          Detail_Payment_Tab_getData,
          Detail_Notification_Tab_getData,
          GPersonTextContactInfoId,
          GPersonEmailContactInfoId
        ),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((vall) => {
        dispatch(setheaderCheckBoxChange(false));
        if (vall?.data?.isSuccess) {
          setCustomFlag(navigate, pathname, customflagval);
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Updated!",
            type: "success",
          });

          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Updated!",
              type: "success",
            });
          }, 1400);

          dispatch(
            updateset_dynamicParticipatTab(
              dynamicParticipatTab.map((val) => {
                if (val.Offender === idd.id) {
                  return {
                    Offender: idd.id,
                    title: `${Detail_Tab_Name_getData?.LastName}`,
                  };
                } else {
                  return val;
                }
              })
            )
          );

          setLoding(false);
          getpersonInfo(idd.id, false);
        } else {
          setLoding(false);
          getpersonInfo(idd.id, false);
        }
      })
      .catch((val) => {
        getpersonInfo(idd.id, false);
        setLoding(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
        }
      });
  };

  const uploadImg = async (imagee) => {
    setLoding(true);
    const formData = new FormData();
    formData.append("image", imagee);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/Person/UploadImage?gPersonId=${userinfo?.gPersonId}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then(() => {
        setImage();
        updateTheDatafunctionApi();
      })
      .catch((val) => {
        updateTheDatafunctionApi();
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
        }
      });
  };

  const uploadImgWhileAddParticipant = async (imagee, personid, offenderNo) => {
    setLoding(true);
    const formData = new FormData();
    formData.append("image", imagee);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/Person/UploadImage?gPersonId=${personid}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then(() => {
        setLoding(false);
        dispatch(remove_dynamicParticipatTab("/add-participant"));
        dispatch(
          headerCheckBoc({
            PaymentBlock: false,
            OnlineReporting: false,
            RecurringCreditCard: false,
            Feeblock: false,
          })
        );
        dispatch(
          detail_Tab_Name_getData({
            StatePreference: { name: "", id: "" },
            LastName: "",
            Gender: { name: "", id: "" },
            EyeColor: { name: "", id: "" },
            AltID: "",
            SSN: "",
            Height: { feet: "", inch: "" },
            Language: { name: "", id: "" },
            FirstName: "",
            DateOfBirth: "",
            Weight: "",
            ComplianceLevel: { name: "", id: "" },
            MiddleName: "",
            Race: { name: "", id: "" },
            HairColor: { name: "", id: "" },
            EffectiveDate: "",
            isChange: false,
          })
        );
        dispatch(
          detail_Tab_address_getData({
            MailingAddress: {
              AddressLine1: "",
              AddressLine2: "",
              City: "",
              State: "",
              ZIP: "",
              Country: "",
              Comment: "",
            },
            HomeAddress: {
              AddressLine1: "",
              AddressLine2: "",
              City: "",
              State: "",
              ZIP: "",
              Country: "",
              Comment: "",
            },
            WorkAddress: {
              Employer: "",
              AddressLine1: "",
              AddressLine2: "",
              City: "",
              State: "",
              ZIP: "",
              Country: "",
              Comment: "",
            },
            isChange: false,
          })
        );
        dispatch(
          detail_Payment_Tab_getData({
            PaymentNotes: "",
            Frequency: "",
            Amount: "",
            NextPaymentDate: "",
            ComplianceLevel: "",
            ActionPlan: "",
            isChange: false,
          })
        );
        dispatch(
          detail_Notification_Tab_getData({
            AlertNote: "",
            email: "",
            phone: "",
            isChange: false,
          })
        );
        dispatch(
          detail_co_obligor_Tab_getData({
            FirstNmae: "",
            MiddleName: "",
            LastName: "",
            AddressLine1: "",
            AddressLine2: "",
            City: "",
            State: "",
            ZIP: "",
            SSN: "",

            DOB: "",
            HomePhone: "",
            WorkPhone: "",
            CellPhone: "",
            CardOnFile: "",

            isChange: false,
          })
        );
        navigate(`/participant/${offenderNo}`);
      })
      .catch((val) => {
        setLoding(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
        }
      });
  };

  const saveCookieCrum = async (perdonId) => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/cookie-crumbs/save-cookie-crumb?recordId=${perdonId}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        //console.log(val.data.data);
      })
      .catch(() => {
        console.log("kjh");
      });
  };

  const AddParticipant = async () => {
    setLoding(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Person/SaveParticipant`,
        AddBody(
          Detail_Tab_Name_getData,
          userinfo?.gPersonId,
          userinfo?.sOffenderNumber,
          Detail_Tab_address_getData,
          PaymentBlock,
          OnlineReporting,
          RecurringCreditCard,
          Feeblock,
          Detail_co_obligor_Tab_getData,
          Detail_Payment_Tab_getData,
          Detail_Notification_Tab_getData,
          contectInfoTabWhileAddParticipent
        ),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        dispatch(setheaderCheckBoxChange(false));
        if (val.data.isSuccess) {
          saveCookieCrum(val.data.data.id);
          if (image) {
            dispatch(
              setDynamicTab({
                Offender: val.data.data.offenderNo,
                title: `${Detail_Tab_Name_getData?.LastName},${Detail_Tab_Name_getData?.FirstName} ${Detail_Tab_Name_getData?.MiddleName}`,
              })
            );
            uploadImgWhileAddParticipant(image, val.data.data.id, val.data.data.offenderNo);
          } else {
            setLoding(false);
            dispatch(remove_dynamicParticipatTab("/add-participant"));
            dispatch(
              headerCheckBoc({
                PaymentBlock: false,
                OnlineReporting: false,
                RecurringCreditCard: false,
                Feeblock: false,
              })
            );
            dispatch(
              detail_Tab_Name_getData({
                StatePreference: { name: "", id: "" },
                LastName: "",
                Gender: { name: "", id: "" },
                EyeColor: { name: "", id: "" },
                AltID: "",
                SSN: "",
                Height: { feet: "", inch: "" },
                Language: { name: "", id: "" },
                FirstName: "",
                DateOfBirth: "",
                Weight: "",
                ComplianceLevel: { name: "", id: "" },
                MiddleName: "",
                Race: { name: "", id: "" },
                HairColor: { name: "", id: "" },
                EffectiveDate: "",
                isChange: false,
              })
            );
            dispatch(
              detail_Tab_address_getData({
                MailingAddress: {
                  AddressLine1: "",
                  AddressLine2: "",
                  City: "",
                  State: "",
                  ZIP: "",
                  Country: "",
                  Comment: "",
                },
                HomeAddress: {
                  AddressLine1: "",
                  AddressLine2: "",
                  City: "",
                  State: "",
                  ZIP: "",
                  Country: "",
                  Comment: "",
                },
                WorkAddress: {
                  Employer: "",
                  AddressLine1: "",
                  AddressLine2: "",
                  City: "",
                  State: "",
                  ZIP: "",
                  Country: "",
                  Comment: "",
                },
                isChange: false,
              })
            );
            dispatch(
              detail_Payment_Tab_getData({
                PaymentNotes: "",
                Frequency: "",
                Amount: "",
                NextPaymentDate: "",
                ComplianceLevel: "",
                ActionPlan: "",
                isChange: false,
              })
            );
            dispatch(
              detail_Notification_Tab_getData({
                AlertNote: "",
                email: "",
                phone: "",
                isChange: false,
              })
            );
            dispatch(
              detail_co_obligor_Tab_getData({
                FirstNmae: "",
                MiddleName: "",
                LastName: "",
                AddressLine1: "",
                AddressLine2: "",
                City: "",
                State: "",
                ZIP: "",
                SSN: "",

                DOB: "",
                HomePhone: "",
                WorkPhone: "",
                CellPhone: "",
                CardOnFile: "",

                isChange: false,
              })
            );
            dispatch(
              setDynamicTab({
                Offender: val.data.data.offenderNo,
                title: `${Detail_Tab_Name_getData?.LastName}`,
              })
            );
            navigate(`/participant/${val.data.data.offenderNo}`);
          }
        } else {
          setLoding(false);
        }
      })
      .catch((val) => {
        setLoding(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
        }
      });
  };

  useEffect(() => {
    // console.log(contectTableAddData);
    // console.log(contectInfoTabWhileAddParticipent);
    if (goodToSaveParticipant === true) {
      if (editParticipant) {
        setEditable(false);
        setSaveContact(false);
        setGoodToSaveParticipant(false);
        if (image) {
          uploadImg(image);
        } else {
          updateTheDatafunctionApi();
        }
      }
    }
  }, [goodToSaveParticipant]);

  const OnSave = async () => {
    if (
      checkValidation_Detail_name_tab(Detail_Tab_Name_getData, Detail_name_tab_Validation) &&
      !imageUploadValidation
    ) {
      if (editParticipant) {
        setSaveContact(true);
        // setEditable(false);
        // if (image) {
        //   uploadImg(image);
        // } else {
        //   updateTheDatafunctionApi();
        // }
      } else {
        if (
          Detail_Tab_Name_getData?.EffectiveDate &&
          Detail_Tab_Name_getData?.StatePreference?.id &&
          Detail_Tab_Name_getData?.FirstName
        ) {
          AddParticipant();
          setEditable(false);
        } else {
          if (!editParticipant) {
            !Detail_Tab_Name_getData?.EffectiveDate
              ? dispatch(setefffectiveData("Please select Effective Date"))
              : dispatch(setefffectiveData(""));
            !Detail_Tab_Name_getData?.StatePreference?.id
              ? dispatch(setstateparticipent("Please select State"))
              : dispatch(setstateparticipent(""));
            !Detail_Tab_Name_getData?.FirstName
              ? dispatch(setfirstnamevalidation("Please Enter First Name"))
              : dispatch(setfirstnamevalidation(""));
          }
        }
      }
    } else {
      dispatch(
        detail_name_tab_Validation({
          ...Detail_name_tab_Validation,
          DateOfBirth: !Detail_Tab_Name_getData.DateOfBirth ? "Please select date of birth" : "",
          LastName: !Detail_Tab_Name_getData.LastName ? "Please Enter Last Name" : "",
        })
      );
      if (true) {
        !Detail_Tab_Name_getData?.EffectiveDate && dispatch(setefffectiveData("Please select Effective Date"));
        !Detail_Tab_Name_getData?.StatePreference?.name && dispatch(setstateparticipent("Please select State"));
        !Detail_Tab_Name_getData?.FirstName && dispatch(setfirstnamevalidation("Please Enter First Name"));
      }
    }
  };
  const [imageUploadValidation, setimageUploadValidation] = useState("");
  const onCancel = () => {
    dispatch(setheaderCheckBoxChange(false));
    dispatch(
      detail_name_tab_Validation({
        LastName: "",
        DateOfBirth: "",
      })
    );
    dispatch(setefffectiveData(""));
    dispatch(setstateparticipent(""));
    if (editParticipant) {
      setimageUploadValidation("");
      setEditable(false);
      inputRef.current.value = "";
      dispatch(changeCancel());
    } else {
      dispatch(setcontectInfoTabWhileAddParticipent([]));
      setimageUploadValidation("");
      setEditable(false);
      inputRef.current.value = "";
      dispatch(
        headerCheckBoc({
          PaymentBlock: false,
          OnlineReporting: false,
          RecurringCreditCard: false,
          Feeblock: false,
        })
      );
      dispatch(
        detail_Tab_Name_getData({
          StatePreference: { name: "", id: "" },
          LastName: "",
          Gender: { name: "", id: "" },
          EyeColor: { name: "", id: "" },
          AltID: "",
          SSN: "",
          Height: { feet: "", inch: "" },
          Language: { name: "", id: "" },
          FirstName: "",
          DateOfBirth: "",
          Weight: "",
          ComplianceLevel: { name: "", id: "" },
          MiddleName: "",
          Race: { name: "", id: "" },
          HairColor: { name: "", id: "" },
          EffectiveDate: "",
          isChange: false,
        })
      );
      dispatch(
        detail_Tab_address_getData({
          MailingAddress: {
            AddressLine1: "",
            AddressLine2: "",
            City: "",
            State: "",
            ZIP: "",
            Country: "",
            Comment: "",
          },
          HomeAddress: {
            AddressLine1: "",
            AddressLine2: "",
            City: "",
            State: "",
            ZIP: "",
            Country: "",
            Comment: "",
          },
          WorkAddress: {
            Employer: "",
            AddressLine1: "",
            AddressLine2: "",
            City: "",
            State: "",
            ZIP: "",
            Country: "",
            Comment: "",
          },
          isChange: false,
        })
      );
      dispatch(
        detail_Payment_Tab_getData({
          PaymentNotes: "",
          Frequency: "",
          Amount: "",
          NextPaymentDate: "",
          ComplianceLevel: "",
          ActionPlan: "",
          isChange: false,
        })
      );
      dispatch(
        detail_Notification_Tab_getData({
          AlertNote: "",
          email: "",
          phone: "",
          isChange: false,
        })
      );
      dispatch(
        detail_co_obligor_Tab_getData({
          FirstNmae: "",
          MiddleName: "",
          LastName: "",
          AddressLine1: "",
          AddressLine2: "",
          City: "",
          State: "",
          ZIP: "",
          SSN: "",

          DOB: "",
          HomePhone: "",
          WorkPhone: "",
          CellPhone: "",
          CardOnFile: "",

          isChange: false,
        })
      );
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];

    if (file) {
      var fileSize = file.size;
      let a = file.name;
      if (!(a.includes("jpg") || a.includes("png") || a.includes("jpeg"))) {
        setimageUploadValidation("Upload valid image");
        return;
      }

      if (fileSize > 60000) {
        setimageUploadValidation("Image size must be 60 KB or smaller.");
        return false;
      }

      setimageUploadValidation("");

      setImage(file);

      const url = URL.createObjectURL(file);
      document.querySelector(".participate_block1_sub1_div_imag").src = url;
    }
  };

  const getpersonInfo = async (id, bool = true) => {
    setLoding(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Account/getPersonDetail?${
          entityperson ? `soffendernumberpin=${id}` : `offenderId=${id}`
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoding(false);
        seterrorHandling3(false);
        getCustomFlag(navigate, pathname, val.data.data?.gPersonId, setcustomflagval);
        dispatch(
          headerCheckBoc({
            PaymentBlock: val.data?.data?.bPaymentBlock,
            OnlineReporting: val.data?.data?.bOnlineReporting,
            RecurringCreditCard: val.data?.data?.bCreditCard,
            Feeblock: val.data?.data?.bFeeBlock,
          })
        );

        if (bool) {
          if (val.data?.data?.sAlertNote !== null && val.data?.data?.sAlertNote) {
            // dispatch(
            //   setAltertPopup({
            //     condition: true,
            //     offender: idd.id,
            //   })
            // );
          } else {
            dispatch(
              setAltertPopup({
                condition: false,
                count: 0,
              })
            );
          }
        }

        dispatch(setparticipantdata(val?.data?.data));

        if (!val?.data?.data?.sOffenderNumber) {
          navigate(`/participant/${dynamicParticipatTab[dynamicParticipatTab.length - 1]?.Offender}`);
        }

        setUserinfo(val?.data?.data);
        getImg(val.data.data?.gPersonId);
      })
      .catch((val) => {
        setLoding(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorHandling3(true);
        }
      });
  };

  const getImg = async (eee) => {
    setLoding(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Person/GetImage?gPersonId=${eee}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        setLoding(false);
        seterrorHandling2(false);
        if (response.data.type !== "application/json") {
          const reader = new FileReader();
          const imageBlob = new Blob([response.data], {
            type: response.headers["content-type/jpeg"],
          });

          reader.onloadend = () => {
            if (document.querySelector(".participate_block1_sub1_div_imag")) {
              document.querySelector(".participate_block1_sub1_div_imag").src = reader.result;
              setgetimage(reader.result);
            }
          };
          reader.readAsDataURL(imageBlob);
        } else {
          document.querySelector(".participate_block1_sub1_div_imag").src = image1;
        }
      })
      .catch((val) => {
        setLoding(false);
        setImage();
        setgetimage();
        if (document.querySelector(".participate_block1_sub1_div_imag")) {
          document.querySelector(".participate_block1_sub1_div_imag").src = image1;
        }
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorHandling2(true);
        }
      });
  };

  useEffect(() => {
    inputRef.current.value = "";
    if (editParticipant) {
      dispatch(
        detail_name_tab_Validation({
          LastName: "",
          DateOfBirth: "",
        })
      );
      dispatch(setefffectiveData(""));
      dispatch(setstateparticipent(""));
      setEditable(false);
      getpersonInfo(idd.id, true);
    }
  }, [idd.id, addworentREfresh]);

  const reloadUserInfo = () => {
    getpersonInfo(idd.id, true);
  };

  useEffect(() => {
    if (editParticipant) {
      if (cancel !== null) {
        getpersonInfo(idd.id, false);
        setImage("");
        setgetimage("");
        getImg(userinfo?.gPersonId);
      }
    } else {
      dispatch(
        headerCheckBoc({
          PaymentBlock: false,
          OnlineReporting: false,
          RecurringCreditCard: false,
          Feeblock: false,
        })
      );
      dispatch(
        detail_Tab_Name_getData({
          StatePreference: { name: "", id: "" },
          LastName: "",
          Gender: { name: "", id: "" },
          EyeColor: { name: "", id: "" },
          AltID: "",
          SSN: "",
          Height: { feet: "", inch: "" },
          Language: { name: "", id: "" },
          FirstName: "",
          DateOfBirth: "",
          Weight: "",
          ComplianceLevel: { name: "", id: "" },
          MiddleName: "",
          Race: { name: "", id: "" },
          HairColor: { name: "", id: "" },
          EffectiveDate: new Date().toISOString().split("T")[0],
          isChange: false,
        })
      );
      dispatch(
        detail_Tab_address_getData({
          MailingAddress: {
            AddressLine1: "",
            AddressLine2: "",
            City: "",
            State: "",
            ZIP: "",
            Country: "",
            Comment: "",
          },
          HomeAddress: {
            AddressLine1: "",
            AddressLine2: "",
            City: "",
            State: "",
            ZIP: "",
            Country: "",
            Comment: "",
          },
          WorkAddress: {
            Employer: "",
            AddressLine1: "",
            AddressLine2: "",
            City: "",
            State: "",
            ZIP: "",
            Country: "",
            Comment: "",
          },
          isChange: false,
        })
      );
      dispatch(
        detail_Payment_Tab_getData({
          PaymentNotes: "",
          Frequency: "",
          Amount: "",
          NextPaymentDate: "",
          ComplianceLevel: "",
          ActionPlan: "",
          isChange: false,
        })
      );
      dispatch(
        detail_Notification_Tab_getData({
          AlertNote: "",
          email: "",
          phone: "",
          isChange: false,
        })
      );
      dispatch(
        detail_co_obligor_Tab_getData({
          FirstNmae: "",
          MiddleName: "",
          LastName: "",
          AddressLine1: "",
          AddressLine2: "",
          City: "",
          State: "",
          ZIP: "",
          SSN: "",

          DOB: "",
          HomePhone: "",
          WorkPhone: "",
          CellPhone: "",
          CardOnFile: "",

          isChange: false,
        })
      );
    }
  }, [cancel]);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const clas = new headersidebarfunction();
  const [markupid, setmarkupid] = useState("");
  const [markup, setmarkup] = useState("");
  const [markupsCaption, setmarkupisCaption] = useState("");
  const [markupsScreenName, setmarkupissScreenNamu] = useState("");
  const [helpPopUp, setHelpPopUp] = useState(false);

  useEffect(() => {
    clas.getHelp(setLoding, navigate, setmarkup, setmarkupid, "PS7", setmarkupissScreenNamu, setmarkupisCaption);
  }, []);

  useEffect(() => {
    const getScramLinks = async () => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/User/get-company-options?gUserID=${
            userinfo.gPersonId
          }&sName=${"Show SCRAMnet Link"}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              Domain: `${pathname}`,
            },
          }
        );

        const { data } = result;

        if (data.data[0]?.sValue.toUpperCase() === "TRUE") {
          setScram(true);
        }
      } catch {}
    };

    getScramLinks();
  }, [userinfo]);

  const fetchScramUrl = async (sType) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/em/get-scram-url?gPersonID=${userinfo.gPersonId}&sType=${sType}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      );

      const { data } = result;
      if (data.isSuccess) {
        setScramUrls((prev) => ({
          ...prev,
          [sType.toLowerCase()]: data.data, // Set the URL from the response
        }));
      } else {
        console.error("Failed to fetch SCRAM URL:", data.message);
      }
    } catch (error) {
      console.error(`Error fetching ${sType} URL:`, error);
    }
  };
  const handleCheckboxChange = (index) => {
    const updatedCustomFlagVal = [...customflagval];
    updatedCustomFlagVal[index].bValue = updatedCustomFlagVal[index].bValue === "1" ? "0" : "1";
    dispatch(setheaderCheckBoxChange(true));
    setcustomflagval(updatedCustomFlagVal);
  };

  useEffect(() => {
    const getUserAccessPermissions = () => {
      const accessData = getAccessData(userAccessData, "PS7");
      setEditAccess(accessData?.Edit);
      setViewAccess(accessData?.View);
      // const docketAccessData = getAccessData(userAccessData, "DK1");
      // setDocketAddAccess(docketAccessData?.Add);
    };

    getUserAccessPermissions();
  }, []);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />

      {errorHandling || errorHandling2 || errorHandling3 ? (
        <ApiError />
      ) : (
        <>
          <div style={{ visibility: !viewAccess ? "visible" : "hidden" }}>No PS.7 View Access</div>
          <div style={{ visibility: viewAccess ? "visible" : "hidden" }}>
            <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
            {markupid && (
              <Popup
                visible={markupid && helpPopUp}
                onHiding={() => {
                  setHelpPopUp(false);
                }}
                showTitle={false}
                width={1200}
                height={670}
                showCloseButton={true}
                hideOnOutsideClick={true}
              >
                <HelpPopup
                  markup={markup}
                  markupid={markupid}
                  helpPopUp={helpPopUp}
                  setHelpPopUp={setHelpPopUp}
                  markupsCaption={markupsCaption}
                  markupsScreenName={markupsScreenName}
                />
              </Popup>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="screen_num" style={{ margin: ".5%" }}>
                <p>PS.7</p>
              </div>
              {markupid && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28px"
                  height="28px"
                  viewBox="0 0 24 24"
                  fill="none"
                  style={{ cursor: "pointer" }}
                  className="svg-blue-black"
                  onClick={() => {
                    setHelpPopUp(true);
                  }}
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 7.75C11.3787 7.75 10.875 8.25368 10.875 8.875C10.875 9.28921 10.5392 9.625 10.125 9.625C9.71079 9.625 9.375 9.28921 9.375 8.875C9.375 7.42525 10.5503 6.25 12 6.25C13.4497 6.25 14.625 7.42525 14.625 8.875C14.625 9.58584 14.3415 10.232 13.883 10.704C13.7907 10.7989 13.7027 10.8869 13.6187 10.9708C13.4029 11.1864 13.2138 11.3753 13.0479 11.5885C12.8289 11.8699 12.75 12.0768 12.75 12.25V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V12.25C11.25 11.5948 11.555 11.0644 11.8642 10.6672C12.0929 10.3733 12.3804 10.0863 12.6138 9.85346C12.6842 9.78321 12.7496 9.71789 12.807 9.65877C13.0046 9.45543 13.125 9.18004 13.125 8.875C13.125 8.25368 12.6213 7.75 12 7.75ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                    fill="white"
                  />
                </svg>
              )}
            </div>
            {editable && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "97%",
                  marginLeft: "1.4%",
                }}
              >
                <div style={{ display: "flex", width: "60%" }}>
                  <p
                    style={{
                      fontWeight: "400",
                      fontSize: "24px",
                      color: "var(--TextColor)",
                      marginTop: "1.4%",
                    }}
                  >
                    {editParticipant ? "Edit Details" : "Fill Participant Details"}
                  </p>
                  {editParticipant && (
                    <div style={{ display: "flex", marginLeft: "2%", width: "40%" }}>
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: 600,
                          color: "var(--TextColor)",
                          textDecoration: "underLine",
                          marginTop: "4%",
                        }}
                      >
                        ({userinfo?.sOffenderNumber})
                      </p>
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: 600,
                          color: "var(--TextColor)",
                          marginTop: "4%",
                          marginLeft: "2%",
                        }}
                      >
                        {userinfo?.sLastFirst}
                      </p>
                    </div>
                  )}
                </div>
                <div className="buttons">
                  <button onClick={onCancel} className="btn">
                    Cancel
                  </button>
                  <button onClick={OnSave} className="primary_btn">
                    Save
                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16.5 10V15C16.5 15.55 16.05 16 15.5 16H3.5C2.95 16 2.5 15.55 2.5 15V10C2.5 9.45 2.05 9 1.5 9C0.95 9 0.5 9.45 0.5 10V16C0.5 17.1 1.4 18 2.5 18H16.5C17.6 18 18.5 17.1 18.5 16V10C18.5 9.45 18.05 9 17.5 9C16.95 9 16.5 9.45 16.5 10ZM10.5 9.67L12.38 7.79C12.77 7.4 13.4 7.4 13.79 7.79C14.18 8.18 14.18 8.81 13.79 9.2L10.2 12.79C9.81 13.18 9.18 13.18 8.79 12.79L5.2 9.2C4.81 8.81 4.81 8.18 5.2 7.79C5.59 7.4 6.22 7.4 6.61 7.79L8.5 9.67V1C8.5 0.45 8.95 0 9.5 0C10.05 0 10.5 0.45 10.5 1V9.67Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            )}
            <div className="fix_participant_header" style={{ overflowY: "scroll" }}>
              <div className="Buckner_block1">
                <div className="Buckner_block1_sub">
                  <div className="headerTopButton" style={{ display: "flex", position: "relative" }}>
                    <ParticipentHeaderPopup
                      editParticipant={editParticipant}
                      userinfo={userinfo}
                      name={userinfo?.sLastFirst}
                      setLoding={setLoding}
                      // docketAddAccess={docketAddAccess}
                    />
                    {!editable && (
                      <button
                        onClick={() => {
                          setEditable(true);
                        }}
                        style={{
                          width: "auto",
                          backgroundColor: editAccess ? "var(--button2)" : "gray",
                          color: "white",
                          cursor: "pointer",
                        }}
                        disabled={!editAccess}
                      >
                        Edit&nbsp;&nbsp;
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M0 15.4601V18.5001C0 18.7801 0.22 19.0001 0.5 19.0001H3.54C3.67 19.0001 3.8 18.9501 3.89 18.8501L14.81 7.94006L11.06 4.19006L0.15 15.1001C0.0500001 15.2001 0 15.3201 0 15.4601ZM17.71 5.04006C18.1 4.65006 18.1 4.02006 17.71 3.63006L15.37 1.29006C14.98 0.900059 14.35 0.900059 13.96 1.29006L12.13 3.12006L15.88 6.87006L17.71 5.04006V5.04006Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    )}
                  </div>

                  <div style={{ marginLeft: "1%", marginBottom: "1%" }} className="participate_block1_sub1">
                    <div className="participate_block1_sub1_div">
                      <div style={{ display: "flex", position: "relative" }}>
                        <div
                          onDragOver={(e) => {
                            editable && handleDragOver(e);
                          }}
                          onDrop={(e) => {
                            editable && handleDrop(e);
                          }}
                        >
                          <div
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <img className="participate_block1_sub1_div_imag" src={getimage ? getimage : image1} />
                            <label htmlFor="fileInput">
                              {editable && isHovered && <img className="upload-icon" src={uploadimage} />}
                            </label>
                          </div>
                          <p
                            style={{
                              color: "red",
                              fontSize: "12px",
                              width: "100%",
                              position: "absolute",
                            }}
                          >
                            {imageUploadValidation}
                          </p>
                          <input
                            id="fileInput"
                            type="file"
                            ref={inputRef}
                            accept="image/*"
                            style={{ display: "none" }}
                            disabled={!editable}
                            onChange={(e) => {
                              const file = e.target.files[0];

                              if (file) {
                                var fileSize = file?.size;
                                let a = file?.name;
                                if (!(a.includes("jpg") || a.includes("png") || a.includes("jpeg"))) {
                                  setimageUploadValidation("Upload valid image");
                                  return;
                                }
                                //fileSize > 60000
                                if (false) {
                                  setimageUploadValidation("Image size must be 60 KB or smaller.");
                                  return false;
                                }
                                setimageUploadValidation("");
                              }

                              setImage(file);

                              if (file) {
                                const url = URL.createObjectURL(file);
                                document.querySelector(".participate_block1_sub1_div_imag").src = url;
                              }
                            }}
                          />
                        </div>
                      </div>
                      <DetailData
                        data={[
                          {
                            text: `${userinfo?.sOffenderNumber ? userinfo?.sOffenderNumber : "-"}${
                              userinfo?.sOffenderNumberPin ? ` (${userinfo?.sOffenderNumberPin})` : ""
                            }`,
                            underLine: true,
                            copyIcon: true,
                          },
                          { text: userinfo?.sLastFirst },
                          { text: userinfo?.sSsnx4 ? userinfo?.sSsnx4 : "-" },
                          {
                            text: userinfo?.dDob
                              ? `${new Date(userinfo?.dDob)?.toLocaleDateString()} - ${Math.floor(
                                  (new Date() - new Date(userinfo?.dDob)) / 31557600000
                                )} yrs ${Math.floor(
                                  ((new Date() - new Date(userinfo?.dDob)) % 31557600000) / 86400000
                                )} days`
                              : "-",
                          },
                        ]}
                      />
                      <DetailData1
                        data1={[
                          "Next Report Date",
                          "Location/Type",
                          "Supervisor/Agent",
                          "Compliance Level",
                          "Active Supervision",
                        ]}
                        data2={[
                          {
                            text: userinfo?.dReport
                              ? new Date(userinfo?.dReport)
                                  ?.toLocaleString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    second: "numeric",
                                    hour12: true,
                                  })
                                  ?.replace(",", "")
                              : "-",
                            color: userinfo?.dReport ? new Date(userinfo?.dReport) < new Date() : false,
                          },
                          { text: userinfo?.sEntityName },
                          { text: userinfo?.sUserLastFirst },
                          {
                            text: userinfo?.sComplianceLevelName,
                            perticularcolor: true,
                          },
                          {
                            text: userinfo?.sPerson_ActiveSupervisionDateRange,
                          },
                        ]}
                      />
                      <DetailData2
                        data1={
                          hideOnhandUnsettled
                            ? ["Offender Balance", "Offender Arrears", "Credit Balance", "Unallocated"] // Don't include "Unsettled" if hideOnhandUnsettled is true
                            : ["Offender Balance", "Offender Arrears", "Credit Balance", "Unallocated", "Unsettled"] // Include "Unsettled" if false
                        }
                        data2={
                          hideOnhandUnsettled
                            ? [
                                { text: userinfo?.fBalance },
                                { text: userinfo?.fArrears },
                                { text: userinfo?.fCreditBalance },
                                { text: userinfo?.unallocated },
                              ] // Don't include fPaidUnsettled if hideOnhandUnsettled is true
                            : [
                                { text: userinfo?.fBalance },
                                { text: userinfo?.fArrears },
                                { text: userinfo?.fCreditBalance },
                                { text: userinfo?.unallocated },
                                { text: userinfo?.fPaidUnsettled }, // Include fPaidUnsettled if false
                              ]
                        }
                        userinfo={userinfo}
                      />

                      <DetailData3
                        data1={["Supervisor/Agent ", "Location", "Status"]}
                        data2={[
                          { text: userinfo?.sPrimarySupervisorName },
                          { text: userinfo?.sGpmEntityName },
                          { text: userinfo?.sPersonSupervisionStatusName },
                        ]}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        // marginTop: "3%",
                        marginBottom: "1%",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex", gap: "15px" }}>
                        {customflagval.map((val, index) => {
                          return (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  name={val?.sName}
                                  id={val?.sName}
                                  checked={Number(val?.bValue) === 1 ? true : false}
                                  onChange={() => {
                                    if (!editable) {
                                      return;
                                    }
                                    handleCheckboxChange(index);
                                  }}
                                />
                                <label htmlFor={val?.sName} className="custom-flag-label">
                                  {val?.sName}
                                </label>
                              </div>
                            </>
                          );
                        })}
                      </div>

                      <BottomCheckbox userinfo={userinfo} />
                    </div>

                    {scram && (
                      <div style={{ display: "flex", gap: "20px" }}>
                        {/* <a href={clientLink} target="_blank">
                          <button>SCRAM Client</button>
                        </a> */}

                        <a href={scramUrls.cam} target="_blank" rel="noopener noreferrer">
                          <button onClick={() => fetchScramUrl("CAM")}>SCRAM CAM</button>
                        </a>
                        <a href={scramUrls.equipment} target="_blank" rel="noopener noreferrer">
                          <button onClick={() => fetchScramUrl("Equipment")}>SCRAM Equipment</button>
                        </a>
                        <a href={scramUrls.gps} target="_blank" rel="noopener noreferrer">
                          <button onClick={() => fetchScramUrl("GPS")}>SCRAM GPS</button>
                        </a>
                        {/* <a href={clientEquipmentLink} target="_blank">
                          <button>SCRAM Equipment</button>
                        </a>
                         */}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className={userinfo?.sAlertNote && "alert-animation-text-content"}>
                <p className={userinfo?.sAlertNote && "alert-animation-text"}>{userinfo?.sAlertNote}</p>
              </div>
              <ParticipentSubTabs
                editParticipant={editParticipant}
                userinfo={userinfo}
                editable={editable}
                reloadUserInfo={reloadUserInfo}
                saveContact={saveContact}
                setSaveContact={setSaveContact}
                setGoodToSaveParticipant={setGoodToSaveParticipant}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
