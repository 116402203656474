import { Table2 } from "../../../../../components/Table2/Table2";
import { GoToDocketSupervision } from "../../GoToDocket/GoToDocketSubtabs/GoToDocketSupervision/GoToDocketSupervision";

export const SupervisionTab = ({ getTopDynamicDocketid }) => {
  return (
    <>
      <GoToDocketSupervision
        dockertDetail={{ getTopDynamicDocketid: getTopDynamicDocketid }}
      />
    </>
  );
};
