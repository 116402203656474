import { useEffect, useState } from "react";
import { GoTOObligationAttachment } from "./GoTOObligationSubTabs/GoTOObligationAttachment";
import { useNavigate } from "react-router-dom";
import { GoTOObligationFunction } from "./GoTOObligationFunction";
import { useSelector } from "react-redux";
import { GoTOObligatiobNote } from "./GoTOObligationSubTabs/GoTOObligatiobNote/GoTOObligatiobNote";
import { GOTOObligationCHeckLIst } from "./GoTOObligationSubTabs/GOTOObligationCHeckLIst/GOTOObligationCHeckLIst";
import { FeePaymentPlan } from "./GoTOObligationSubTabs/FeePaymentPlan/FeePaymentPlan";
import { PaymentCredits } from "./GoTOObligationSubTabs/PaymentCredits/PaymentCredits";
import { RecipientsDisbursements } from "./GoTOObligationSubTabs/RecipientsDisbursements/RecipientsDisbursements";
import { Toster } from "../../../../components/Toster/Toster";
import { useDispatch } from "react-redux";
import Monitoring from "./GoTOObligationSubTabs/Monitoring/Monitoring";
import FeePaymentPlanB from "./GoTOObligationSubTabs/FeePaymentPlan/FeePaymentPlanB";
import { GoToObligationDetail } from "../GoToDocket/GoToDocketSubtabs/GoToObligationDetail/GoToObligationDetail";
import { setOpenMonitoringTab } from "../../../../reducer/participantNoteReducer";

export const GoToObligationSubTab = ({
  data,
  dropdowndata,
  addobligation,
  EditTogel,
  refreshApiButton,
  getObligation,
  feePaymentPlanData,
  setFeePaymentPlanData,
  isAccrual,
  isAccrued,
  selectedFeeCode,
  feecodedropdown
}) => {
  const { gotooblogationValue, openMonitoringTab } = useSelector((store) => store.participateNotes);
  const [activeTab, setActiveTab] = useState(1);
  const [attachmentTabledata, setattachmentTabledata] = useState([]);
  const [loadingg, setLoading] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const { AddParticipateUserInfo } = useSelector((store) => store.participate);
  const clas = new GoTOObligationFunction();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const navigate = useNavigate();
  const [attachmentCount, setattachmentCount] = useState(0);
  const [recipientsCount, setRecipientsCount] = useState(0);
  const [disbursementsCount, setDisbursementsCount] = useState(0);
  const [recipientsTableData, setRecipientsTableData] = useState([]);
  const [disbursementsTableData, setDisbursementsTableData] = useState([]);
  const [paymentCreditsCount, setPaymentCreditsCount] = useState(0);
  const [paymentCreditsTableData, setPaymentCreditsTableData] = useState([]);
  const [recipientsDropdownData, setRecipientsDropdownData] = useState([]);
  const [recipientsPaymentDropdownData, setRecipientsPaymentDropdownData] = useState([]);
  const [recipientsEntityDropdownData, setRecipientsEntityDropdownData] = useState([]);
  const [paymentCreditsDropdownData, setPaymentCreditsDropdownData] = useState([]);
  const [feeViolationDropdown, setFeeViolationDropdown] = useState([]);
  const [paymentAgencyEntity, setPaymentAgencyEntity] = useState("");
  const dispatch = useDispatch();
  const gFeeScheduleID = data?.gFeeScheduleId;
  const [generatePaymentPlanTableData, setGeneratePaymentPlanTableData] = useState([]);
  const [monthStartDropdown, setMonthStartDropdown] = useState([]);
  const [emPeriodTableData, setEmPeriodTableData] = useState([]);
  const [emEquipmentLogTableData, setEmEquipmentLogTableData] = useState([]);
  const [obligationid, setObligationId] = useState("");

  const tabTitles = [
    "Payment Plan",
    `Payment/Credits (${paymentCreditsCount})`,
    `Recipients/Disbursements (${recipientsCount}/${disbursementsCount})`,
    `Attachments (${attachmentCount})`,
    "Notes/Events",
    "Checklist",
    "Obligation Detail",
  ];

  useEffect(() => {
    if (gotooblogationValue?.gObligationId) {
      if (openMonitoringTab) {
        setActiveTab(2);
        dispatch(setOpenMonitoringTab(false));
      }
      clas.getattachmentTableData(
        setattachmentTabledata,
        setLoading,
        navigate,
        gotooblogationValue,
        setattachmentCount
      );
      clas.getRecipientsTableData(
        setLoading,
        navigate,
        gotooblogationValue,
        setRecipientsTableData,
        setRecipientsCount
      );
      clas.getDisbursementTableData(
        setLoading,
        navigate,
        gotooblogationValue,
        setDisbursementsTableData,
        setDisbursementsCount
      );
      clas.getPaymentCreditsTableData(
        setLoading,
        navigate,
        gotooblogationValue,
        setPaymentCreditsTableData,
        setPaymentCreditsCount
      );
      clas.getRecipientsDropdownData(
        setLoading,
        navigate,
        setRecipientsDropdownData,
        setRecipientsPaymentDropdownData,
        setRecipientsEntityDropdownData
      );
      clas.getpaymentCreditsDropdownData(setLoading, navigate, setPaymentCreditsDropdownData);
      clas.getFeeViolationDropdown(navigate, setFeeViolationDropdown);
      clas.getViolationFeeFineDropdown(gFeeScheduleID, navigate, setPaymentAgencyEntity);
      clas.getGeneratePaymentPlanTableData(setLoading, navigate, gotooblogationValue, setGeneratePaymentPlanTableData);
      clas.getPaymentPlanDropdownData(gotooblogationValue, setMonthStartDropdown, setLoading, navigate);
      emPeriodDataApi();
      emEquipmentLogDataApi();
      setObligationId(gotooblogationValue?.gObligationId);
    }
  }, [gotooblogationValue?.gObligationId, refreshApiButton]);

  const getattachmentTableData = () => {
    gotooblogationValue?.gObligationId &&
      clas.getattachmentTableData(
        setattachmentTabledata,
        setLoading,
        navigate,
        gotooblogationValue,
        setattachmentCount
      );
  };
  const refreshGetRecipientsTableData = () => {
    gotooblogationValue?.gObligationId &&
      clas.getRecipientsTableData(
        setLoading,
        navigate,
        gotooblogationValue,
        setRecipientsTableData,
        setRecipientsCount
      );
    gotooblogationValue?.gObligationId &&
      clas.getRecipientsDropdownData(
        setLoading,
        navigate,
        setRecipientsDropdownData,
        setRecipientsPaymentDropdownData,
        setRecipientsEntityDropdownData
      );
  };
  const refreshGetDisbursementTableData = () => {
    gotooblogationValue?.gObligationId &&
      clas.getDisbursementTableData(
        setLoading,
        navigate,
        gotooblogationValue,
        setDisbursementsTableData,
        setDisbursementsCount
      );
  };
  const refreshGetPaymentCreditsTableData = () => {
    gotooblogationValue?.gObligationId &&
      clas.getPaymentCreditsTableData(
        setLoading,
        navigate,
        gotooblogationValue,
        setPaymentCreditsTableData,
        setPaymentCreditsCount
      );
  };
  const refreshGetGeneratePaymentPlanTableData = () => {
    clas.getGeneratePaymentPlanTableData(setLoading, navigate, gotooblogationValue, setGeneratePaymentPlanTableData);
    getObligation();
  };
  const emPeriodDataApi = () => {
    clas.getEmPeriodObligationTableData(setLoading, navigate, gotooblogationValue?.gObligationId, setEmPeriodTableData);
  };

  const emEquipmentLogDataApi = () => {
    clas.getEmEquipmentLogObligationTableData(
      setLoading,
      navigate,
      gotooblogationValue?.gObligationId,
      setEmEquipmentLogTableData
    );
  };

  useEffect(() => {
    if (addobligation) {
      setEmEquipmentLogTableData([]);
      setEmPeriodTableData([]);
      setGeneratePaymentPlanTableData([]);
      setPaymentCreditsTableData([]);
      setDisbursementsTableData([]);
      setPaymentCreditsCount(0);
      setRecipientsCount(0);
      setattachmentCount(0);
      setRecipientsCount(0);
    }
  }, [addobligation]);

  useEffect(() => {
    if (activeTab === 1) {
      getObligation();
    }
  }, [activeTab]);

  return (
    <>
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <div className="goto-obligation-sub-tab-container">
        <div className="goto-obligation-sub-tab">
          {tabTitles.map((title, index) => (
            <button
              key={index}
              className={`goto-obligation-button ${activeTab === index + 1 ? "goto-obligation-button-active" : ""}`}
              onClick={() => handleTabClick(index + 1)}
            >
              {title}
            </button>
          ))}
        </div>
        <div className="commonBlock_bottom_border"></div>
        <div className="goto-subtabs ">
          {activeTab === 1 && (
            // <FeePaymentPlanB
            //   data={data}
            //   dropdowndata={dropdowndata}
            //   EditTogel={EditTogel}
            //   addobligation={addobligation}
            //   feePaymentPlanData={feePaymentPlanData}
            //   setFeePaymentPlanData={setFeePaymentPlanData}
            //   isAccrual={isAccrual}
            // />
            <>
              {isAccrued ? (
                <Monitoring
                  gObligationID={gotooblogationValue?.gObligationId}
                  toastConfig={toastConfig}
                  setToastConfig={setToastConfig}
                  emPeriodTableData={emPeriodTableData}
                  emEquipmentLogTableData={emEquipmentLogTableData}
                  emEquipmentLogDataApi={emEquipmentLogDataApi}
                  emPeriodDataApi={emPeriodDataApi}
                  gotooblogationValue={gotooblogationValue.userinfo || AddParticipateUserInfo}
                  isAccrual={isAccrual}
                />
              ) : isAccrual ? (
                <FeePaymentPlanB
                  data={data}
                  dropdowndata={dropdowndata}
                  EditTogel={EditTogel}
                  addobligation={addobligation}
                  feePaymentPlanData={feePaymentPlanData}
                  setFeePaymentPlanData={setFeePaymentPlanData}
                  isAccrual={isAccrual}
                  gObligationID={gotooblogationValue?.gObligationId}
                  getObligation={getObligation}
                />
              ) : (
                <FeePaymentPlan
                  data={data}
                  dropdowndata={dropdowndata}
                  toastConfig={toastConfig}
                  setToastConfig={setToastConfig}
                  addobligation={addobligation}
                  EditTogel={EditTogel}
                  feeViolationDropdown={feeViolationDropdown}
                  paymentAgencyEntity={paymentAgencyEntity}
                  generatePaymentPlanTableData={generatePaymentPlanTableData}
                  refreshGetGeneratePaymentPlanTableData={refreshGetGeneratePaymentPlanTableData}
                  gObligationID={gotooblogationValue?.gObligationId}
                  setFeePaymentPlanData={setFeePaymentPlanData}
                  feePaymentPlanData={feePaymentPlanData}
                  setGeneratePaymentPlanTableData={setGeneratePaymentPlanTableData}
                  getObligation={getObligation}
                  monthStartDropdown={monthStartDropdown}
                  selectedFeeCode={selectedFeeCode}
                  feecodedropdown={feecodedropdown}
                />
              )}

              {/* {isAccrual ? (

                <FeePaymentPlanB
                  data={data}
                  dropdowndata={dropdowndata}
                  EditTogel={EditTogel}
                  addobligation={addobligation}
                  feePaymentPlanData={feePaymentPlanData}
                  setFeePaymentPlanData={setFeePaymentPlanData}
                  isAccrual={isAccrual}
                />
              ) :
                (
                  <FeePaymentPlan
                    data={data}
                    dropdowndata={dropdowndata}
                    toastConfig={toastConfig}
                    setToastConfig={setToastConfig}
                    addobligation={addobligation}
                    EditTogel={EditTogel}
                    feeViolationDropdown={feeViolationDropdown}
                    paymentAgencyEntity={paymentAgencyEntity}
                    generatePaymentPlanTableData={generatePaymentPlanTableData}
                    refreshGetGeneratePaymentPlanTableData={refreshGetGeneratePaymentPlanTableData}
                    gObligationID={gotooblogationValue?.gObligationId}
                    setFeePaymentPlanData={setFeePaymentPlanData}
                    setGeneratePaymentPlanTableData={setGeneratePaymentPlanTableData}
                    getObligation={getObligation}
                    monthStartDropdown={monthStartDropdown}
                  />
                )} */}
            </>
          )}
          {/* {activeTab === 2 && (
            <Monitoring
              gObligationID={gotooblogationValue?.gObligationId}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              emPeriodTableData={emPeriodTableData}
              emEquipmentLogTableData={emEquipmentLogTableData}
              emEquipmentLogDataApi={emEquipmentLogDataApi}
              emPeriodDataApi={emPeriodDataApi}
              gotooblogationValue={gotooblogationValue.userinfo || AddParticipateUserInfo}
              isAccrual={isAccrual}
            />
          )} */}
          {activeTab === 2 && (
            <PaymentCredits
              paymentCreditsTableData={paymentCreditsTableData}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              refreshGetPaymentCreditsTableData={refreshGetPaymentCreditsTableData}
              paymentCreditsDropdownData={paymentCreditsDropdownData}
              userInfoDetails={gotooblogationValue?.userinfo}
              userData={data}
            />
          )}
          {activeTab === 3 && (
            <RecipientsDisbursements
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
              userinfo={gotooblogationValue?.userinfo}
              recipientsTableData={recipientsTableData}
              refreshGetRecipientsTableData={refreshGetRecipientsTableData}
              disbursementsTableData={disbursementsTableData}
              refreshGetDisbursementTableData={refreshGetDisbursementTableData}
              recipientsCount={recipientsCount}
              disbursementsCount={disbursementsCount}
              recipientsDropdownData={recipientsDropdownData}
              recipientsPaymentDropdownData={recipientsPaymentDropdownData}
              recipientsEntityDropdownData={recipientsEntityDropdownData}
              gObligationID={gotooblogationValue?.gObligationId}
            />
          )}
          {activeTab === 4 && (
            <GoTOObligationAttachment
              dataa={attachmentTabledata}
              loadinggg={loadingg}
              userinfo={{ gPersonId: gotooblogationValue?.gObligationId }}
              attachmentfunction={getattachmentTableData}
            />
          )}
          {activeTab === 5 && (
            <GoTOObligatiobNote
              dockerValue={{
                getTopDynamicDocketid: gotooblogationValue?.gObligationId,
                userinfo: gotooblogationValue?.userinfo,
              }}
              userinfo={gotooblogationValue?.userinfo}
            />
          )}
          {activeTab === 6 && <GOTOObligationCHeckLIst dockerValue={gotooblogationValue?.gObligationId} />}
          {activeTab === 7 && <GoToObligationDetail 
            gObligationId={obligationid} 
            data={data} 
            getObligation={getObligation} 
          />}
        </div>
      </div>
    </>
  );
};
