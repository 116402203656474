import { useEffect } from "react";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import { setAdminSavePopup } from "../../../../../reducer/AdminReadReducer";
import { useDispatch } from "react-redux";

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  fieldNmae,
  reset,
  disabled,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

const DetailsName = ({
  isEditableButton,
  toastConfig,
  setToastConfig,
  contactData,
  fields,
  setFields,
  lastNamevalidation,
  setLastNamevalidation,
  firstNamevalidation,
  setFirstNamevalidation,
  titleDropdown,
}) => {
  const dispatch = useDispatch();
  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    dispatch(setAdminSavePopup({ adminSavePopup: true }));
    if (name === "firstName") {
      if (fields?.firstName.charAt(0) === " ") {
        setFirstNamevalidation("Enter First Name");
      } else {
        setFirstNamevalidation("");
      }
    }
    if (name === "lastName") {
      if (fields?.lastName.charAt(0) === " ") {
        setLastNamevalidation("Enter Last Name");
      } else {
        setLastNamevalidation("");
      }
    }
  };

  return (
    <>
      <div className="details-name">
        <div
          className="page-section-fileds"
          style={{ flexDirection: "column", gap: "0px" }}
        >
          <div className="fee-schedule-fileds-content">
            <div className="go-to-inputs-field">
              <label htmlFor="firstName" className="go-to-label-filed">
                First Name
                <span className="marks_mandatary">*</span>
              </label>
              <input
                type="text"
                className="fee-payment-content-info-input"
                name="firstName"
                id="firstName"
                value={fields?.firstName}
                onChange={handleInputChange}
                disabled={!isEditableButton}
                placeholder="First Name"
              />
              {firstNamevalidation && (
                <p className="error_validation">{firstNamevalidation}</p>
              )}
            </div>
            <div className="go-to-inputs-field">
              <label htmlFor="middleName" className="go-to-label-filed">
                Middle Name
              </label>
              <input
                type="text"
                className="fee-payment-content-info-input"
                name="middleName"
                id="middleName"
                value={fields?.middleName}
                onChange={handleInputChange}
                disabled={!isEditableButton}
                placeholder="Middle Name"
              />
            </div>
          </div>
          <div className="fee-schedule-fileds-content">
            <div className="go-to-inputs-field">
              <label htmlFor="middleName" className="go-to-label-filed">
                Last Name
                <span className="marks_mandatary">*</span>
              </label>
              <input
                type="text"
                className="fee-payment-content-info-input"
                name="lastName"
                id="lastName"
                value={fields?.lastName}
                onChange={handleInputChange}
                disabled={!isEditableButton}
                placeholder="Last Name"
              />
              {lastNamevalidation && (
                <p className="error_validation">{lastNamevalidation}</p>
              )}
            </div>
            <div className="go-to-inputs-field">
              <label htmlFor="prefix" className="go-to-label-filed">
                Prefix
              </label>
              <input
                type="text"
                className="fee-payment-content-info-input"
                name="prefix"
                id="prefix"
                value={fields?.prefix}
                onChange={handleInputChange}
                disabled={!isEditableButton}
                placeholder="Prefix"
              />
            </div>
          </div>
          <div className="fee-schedule-fileds-content">
            <div className="go-to-inputs-field">
              <label htmlFor="suffix" className="go-to-label-filed">
                Suffix
              </label>
              <input
                type="text"
                className="fee-payment-content-info-input"
                name="suffix"
                id="suffix"
                value={fields?.suffix}
                onChange={handleInputChange}
                disabled={!isEditableButton}
                placeholder="Suffix"
              />
            </div>
            <div className="go-to-inputs-field edit-content-space">
              <label htmlFor="" className="go-to-label-filed">
                Title
              </label>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"title"}
                dropDownData={titleDropdown?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
                disabled={!isEditableButton}
              />
            </div>
          </div>
          <div className="fee-schedule-fileds-content">
            <div className="go-to-inputs-field">
              <label htmlFor="salutation" className="go-to-label-filed">
                Salutation
              </label>
              <input
                type="text"
                className="fee-payment-content-info-input"
                name="salutation"
                id="salutation"
                value={fields?.salutation}
                onChange={handleInputChange}
                disabled={!isEditableButton}
                placeholder="Salutation"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsName;
