import axios from "axios";
import { logOut } from "../../../../../components/Logout";
import { handleGetLocal } from "../../../../../services/auth-service";

export class overVIewFunction {
  constructor() {
    this.pathname = handleGetLocal("pathURL");
  }

  getactivityTableData = async (
    setLoading,
    navigate,
    id,
    setActivityData,
    seterrorhandling3,
    id2
  ) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/checklist/get-checklist-activities?recordId=${id}&checklistId=${id2}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling3(false);
          setActivityData(this.setActivityData2(val.data.data));
        } else {
          seterrorhandling3(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling3(true);
        }
      });
  };
  getTableData = async (
    setLoading,
    seterrorhandling,
    setdata,
    navigate,
    getTopDynamicDocketid,
    dropDownId,
    setdataEditCheclist
  ) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/checklist/get-checklist-records?checklistId=${dropDownId}&recordId=${getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          const arrr1 = [];
          val.data.data.map((val) => {
            if (val?.checkListItemRecords.length) {
              val?.checkListItemRecords.map((vall) => {
                arrr1.push({
                  bAllowCheck: val?.bAllowCheck,
                  gCheckListItemId: val?.gCheckListItemId,
                  sItem: val?.sItem,
                  sSequence: val?.sSequence,
                  ...vall,
                });
              });
            } else {
              arrr1.push({
                bAllowCheck: val?.bAllowCheck,
                sSequence: val?.sSequence,
                sItem: val?.sItem,
                gCheckListItemId: val?.gCheckListItemId,
              });
            }
          });
          setdata(this.setTableData(arrr1));
          setdataEditCheclist(this.setTableData2(arrr1));
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };
  getTableData2 = async (
    setLoading,
    seterrorhandling,
    setdata,
    navigate,
    getTopDynamicDocketid,
    dropDownId,
    setdataEditCheclist
  ) => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/checklist/get-checklist-records?checklistId=${dropDownId}&recordId=${getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          const arrr1 = [];
          val.data.data.map((val) => {
            if (val?.checkListItemRecords.length) {
              val?.checkListItemRecords.map((vall) => {
                arrr1.push({
                  bAllowCheck: val?.bAllowCheck,
                  gCheckListItemId: val?.gCheckListItemId,
                  sItem: val?.sItem,
                  sSequence: val?.sSequence,
                  ...vall,
                });
              });
            } else {
              arrr1.push({
                bAllowCheck: val?.bAllowCheck,
                sSequence: val?.sSequence,
                sItem: val?.sItem,
                gCheckListItemId: val?.gCheckListItemId,
              });
            }
          });
          setdataEditCheclist(this.setTableData2(arrr1));
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  setTableData = (data) => {
    const arr = [];
    data.map((val, i) => {
      arr.push({
        Note: val.sNote,
        Sequence: val?.sSequence,
        Activity: val.sItem,
        "Completed ?": val.bComplete,
        Date: val.dChanged,
        User: val.sEnteredBy,
        ID: i,
        bAllowCheck: val?.bAllowCheck,
        gRecordId: val?.gRecordId,
        gCheckListItemId: val?.gCheckListItemId,
        gCheckListItemRecordId: val?.gCheckListItemRecordId,
      });
    });
    return arr;
  };
  setTableData2 = (data) => {
    const arr = [];
    data.map((val, i) => {
      arr.push({
        Sequence: val?.sSequence,
        Activity: val.sItem,
        "Allow Check": val?.bAllowCheck,
        ID: i,
        gCheckListItemId: val?.gCheckListItemId,
      });
    });
    return arr;
  };

  setActivityData2 = (data) => {
    const arr = [];
    data.map((val, i) => {
      arr.push({
        "d DOE": val.dDoe,
        "s Last First": val?.sLastFirst,
        "s Activity": val.sActivity,
        "s Name": val.sName,
        "s Sequence": val.sSequence,
        "s Item": val.sItem,
        ID: i,
      });
    });
    return arr;
  };

  Tablecoloum = [
    {title:"", moreoption:true, customwidth:"50px"},
    { title: "Completed ?", bool: true, showBool: true },
    {
      title: "Sequence",
    },
    {
      title: "Activity",
    },
    {
      title: "Note",
    },
    {
      title: "Date",
      date: true,
    },
    {
      title: "User",
    },
  ];

  async getCheckLIstDropDown(seterrorhandling, navigate, setdropdown, person) {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/checklist/get-dropdown?option=${person}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          val?.data?.data?.length &&
            setdropdown(
              val?.data?.data.map((val) => {
                return {
                  name: val?.sName,
                  id: val?.gCheckListId,
                };
              })
            );
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  }
  async getCheckLIstDropDown2(navigate, setdropdown) {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/checklist/get-dropdown?all=true`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          val?.data?.data?.length &&
            setdropdown(
              val?.data?.data.map((val) => {
                return {
                  name: val?.sName,
                  id: val?.gCheckListId,
                };
              })
            );
        }
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }
}
