import { TextBox } from "devextreme-react";
import { useDispatch } from "react-redux";
import {
  setProntactEntitiesSubTabDockumentWizarddata,
  setProntactEntitieschange,
} from "../../../../../reducer/AdminReadReducer";
import { useSelector } from "react-redux";

export const ProntactEntitiesSubTabDockumentWizard = ({ EditTogel }) => {
  const dispatch = useDispatch();
  const { ProntactEntitiesSubTabDockumentWizarddata } = useSelector(
    (store) => store.AdminTable
  );
  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "30%",
            marginTop: "10px",
          }}
        >
          <p style={{ fontWeight: "bold" }}>Legal Name Line 1</p>
          <TextBox
            style={{ width: "60%" }}
            value={ProntactEntitiesSubTabDockumentWizarddata.LegalNameLine1}
            onValueChange={(e) => {
              dispatch(setProntactEntitieschange(true));
              dispatch(
                setProntactEntitiesSubTabDockumentWizarddata({
                  ...ProntactEntitiesSubTabDockumentWizarddata,
                  LegalNameLine1: e,
                })
              );
            }}
            isValid={true}
            disabled={!EditTogel}
            placeholder={"Legal Name Line 1"}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "30%",
            marginTop: "10px",
          }}
        >
          <p style={{ fontWeight: "bold" }}>Legal Name Line 2</p>
          <TextBox
            style={{ width: "60%" }}
            disabled={!EditTogel}
            value={ProntactEntitiesSubTabDockumentWizarddata.LegalNameLine2}
            onValueChange={(e) => {
              dispatch(setProntactEntitieschange(true));
              dispatch(
                setProntactEntitiesSubTabDockumentWizarddata({
                  ...ProntactEntitiesSubTabDockumentWizarddata,
                  LegalNameLine2: e,
                })
              );
            }}
            isValid={true}
            placeholder={"Legal Name Line 2"}
          />
        </div>
      </div>
    </>
  );
};
