import { Attachment } from "../../../Attachment/Attachment";

export const GoTODocketAttachment = ({
  dataa,
  loadinggg,
  userinfo,
  attachmentfunction,
}) => {
  return (
    <>
      <div style={{ margin: "-1.5%" }}>
        <Attachment
          dataa={dataa}
          loadinggg={loadinggg}
          userinfo={userinfo}
          attachmentfunction={attachmentfunction}
        />
      </div>
    </>
  );
};
