import { DataTab } from "../../../Dockect/overview/DataTab";

export const GoToDocketDate = ({ dockerValue }) => {
  return (
    <>
      <DataTab
        getTopDynamicDocketid={dockerValue?.getTopDynamicDocketid}
        userinfo={dockerValue?.userinfo}
      />
    </>
  );
};
