export const Tablecoloum = [
  { moreoption: true, docketListControl: true, customwidth: 50 },
  { title: "Select", bool: true, docketListControl: true, customwidth: 50 },
  { title: "Docket #", underLine: true },
  { title: "Cnt" },
  { title: "Person" },
  { title: "Docket Ref #" },
  { title: "Type" },
  { title: "Create Date" },
  { title: "Docket Court" },
  { title: "Supervision Status" },
  { title: "Status Date" },
  { title: "Report Date" },
  { title: "Attendance" },
  { title: "Supervisor" },
  { title: "PIN Docket" },
];

export const dateRanges = [
  { name: "Last 3 Days" },
  { name: "Last 7 Days" },
  { name: "Month to Date" },
  { name: "Previous Month" },
  { name: "Next Month from Date" },
  { name: "Next 3 Month from Date" },
  { name: "last 3 Month to Date" },
  { name: "last 6 Month to Date" },
  { name: "Year to Date" },
  { name: "Previous Year" },
  { name: "Today" },
];
