import { ChecklistTab } from "../Dockect/overview/ChecklistTab";

export const ParticipentChecklist = ({ userinfo }) => {
  return (
    <div style={{ width: "98%", marginLeft: "1%" }}>
      <ChecklistTab
        person={1}
        getTopDynamicDocketid={userinfo?.gPersonId}
        AdCHeckLIst={"Docket"}
      />
    </div>
  );
};
