import { ReportDashboard } from "./ReportDashboard/ReportDashboard";
import './report.css'
import { DynamicTabb } from "../../components/DynamicTab/DynamicTab";

export const Report = () => {
  return (
    <>
      <div className="breadCrumb">
        <ul style={{ width: "250px" }}>
          <li>Report</li>
          <li className="active"> Report Dashboard</li>
        </ul>
      </div>
      <div className="ReportWizard">
        <DynamicTabb />
        <ReportDashboard />
      </div>
    </>
  );
};
