import axios from "axios";
import { logOut } from "../../components/Logout";
import { handleGetLocal } from "../../services/auth-service";

export class headersidebarfunction {
  constructor() {
    this.pathname = handleGetLocal("pathURL");
  }

  async getHelp(
    setloading,
    navigate,
    sethelpdata,
    setmarkupid,
    ScreenName,
    setmarkupissScreenNamu,
    setmarkupisCaption
  ) {
    // setloading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Help/get?ScreenName=${ScreenName}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        // setloading(false);
        if (val?.data?.isSuccess) {
          sethelpdata(val?.data?.data?.sSystemHelp);
          setmarkupid(val?.data?.data?.gHelpId);
          setmarkupissScreenNamu(val?.data?.data?.sScreenName);
          setmarkupisCaption(val?.data?.data?.sCaption);
        } else {
        }
      })
      .catch((val) => {
        setloading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }
  async editHelp(setloading, navigate, body, setHelpPopUp) {
    setloading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/Help/edit`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setloading(false);
        if (val?.data?.isSuccess) {
          setHelpPopUp(false);
          // sethelpdata(val?.data?.data?.sCompanyHelp);
        } else {
        }
      })
      .catch((val) => {
        setloading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }
}
