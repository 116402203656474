import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ColumnDropdown } from "../../../components/Dropdownn";

const DropDown = ({
  dropDownData,
  // setfields,
  // fields,
  // fieldNmae,
  // reset,
  disabled,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        //   setfields={setfields}
        //   fields={fields}
        //   fieldNmae={fieldNmae}
        //   value={fields[fieldNmae]?.name}
        //   reset={reset}
        disabled={disabled}
      />
    </>
  );
};

export const HandOffPopup = ({ setProntactEntitieshandoff, ProntactEntitieshandoff }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [findvalue, setfindvalue] = useState("");
  const [data, setdata] = useState([]);
  useEffect(() => {
    setfindvalue("");
    // clas.getFIndTable(setLoading, "", setdata, navigate);
  }, [ProntactEntitieshandoff]);

  return (
    <>
      <div style={{ height: "498px" }} className="go-to-docket-find">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_6247_15088)">
                  <path
                    d="M15.5006 14.0006H14.7106L14.4306 13.7306C15.6306 12.3306 16.2506 10.4206 15.9106 8.39063C15.4406 5.61063 13.1206 3.39063 10.3206 3.05063C6.09063 2.53063 2.53063 6.09063 3.05063 10.3206C3.39063 13.1206 5.61063 15.4406 8.39063 15.9106C10.4206 16.2506 12.3306 15.6306 13.7306 14.4306L14.0006 14.7106V15.5006L18.2506 19.7506C18.6606 20.1606 19.3306 20.1606 19.7406 19.7506C20.1506 19.3406 20.1506 18.6706 19.7406 18.2606L15.5006 14.0006ZM9.50063 14.0006C7.01063 14.0006 5.00063 11.9906 5.00063 9.50063C5.00063 7.01063 7.01063 5.00063 9.50063 5.00063C11.9906 5.00063 14.0006 7.01063 14.0006 9.50063C14.0006 11.9906 11.9906 14.0006 9.50063 14.0006Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6247_15088">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>Hand Off</p>
            </div>
          </div>
          <svg
            onClick={() => {
              setProntactEntitieshandoff(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <section className="go-to-docket-popup-content">
          <div>
            <div className="go-to-inputs-field edit_content_space">
              <div style={{ display: "flex" }}>
                <input type="checkbox" />
                <p>Copy TO Self</p>
              </div>
              <label htmlFor="" className="go-to-label-filed">
                Hand to
              </label>

              <DropDown
                dropDownData={[]}
                fieldNmae={"Recipient Category"}
                //   fields={addobligation ? field2 : field}
                //   setfields={addobligation ? setField2 : setField}
                //   reset={setRecipientCategoryvalidation}
              />
            </div>
            <div>
              <p>Comment</p>
              <textarea style={{ width: "100%", height: "200px" }} />
            </div>
          </div>
          <div className="popup_buttons">
            <button
              className="btn"
              onClick={() => {
                setProntactEntitieshandoff(false);
              }}
            >
              Ok
            </button>
            <button
              className="btn"
              onClick={() => {
                setProntactEntitieshandoff(false);
              }}
            >
              Cancel
            </button>
          </div>
        </section>
      </div>
    </>
  );
};
