import { DataGrid, SelectBox } from "devextreme-react";
import "./models.css";
import { Column } from "devextreme-react/cjs/data-grid";
import { useNavigate } from "react-router-dom";
import { handleGetLocal } from "../../services/auth-service";
import { useEffect, useState } from "react";
import axios from "axios";
import { logOut } from "../../components/Logout";

export const SendEmail = ({ setSendEmailPopup, personIds }) => {
  const navigate = useNavigate();
  const pathname = handleGetLocal("pathURL");
  const [contactInfoTypes, setContactInfoTypes] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [selectedContactTypes, setSelectedContactTypes] = useState([]);
  const [sendFrom, setSendFrom] = useState("");
  const [subject, setSubject] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [textMessage, setTextMessage] = useState("");

  const [emailFilter, setEmailFilter] = useState(false);
  const [textFilter, setTextFilter] = useState(false);

  const [availableEmail, setAvailableEmail] = useState(0);
  const [availableText, setAvailableText] = useState(0);

  const [emailTemplates, setEmailTemplates] = useState([]);

  const getEmailTemplates = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Person/get-email-prefill?personId=00000000-0000-0000-0000-000000000000`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setEmailTemplates(
          val?.data?.data?.templates?.map((val) => {
            return {
              value: val?.sName,
              key: val?.gReportCatalogId,
              sEmailBody: val?.sEmailBody,
            };
          })
        );

        setSendFrom(val?.data?.data?.sendFrom);
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        }
      });
  };

  const getContactInfoTypes = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Person/contact-type`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setContactInfoTypes(val?.data?.data);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    getContactInfoTypes();
    getEmailTemplates();
  }, []);

  useEffect(() => {
    setDataSource(
      contactInfoTypes.filter((type) => {
        if (emailFilter && type.sType?.toLowerCase()?.includes("email")) return true;
        if (textFilter && type.sType?.toLowerCase()?.includes("phone")) return true;
        return false;
      })
    );
  }, [contactInfoTypes, emailFilter, textFilter]);

  useEffect(() => {
    getAvailableEmailText();
  }, [selectedContactTypes]);

  const getAvailableEmailText = () => {
    const body = {
      gPersonIds: personIds,
      gContactInfoTypeIds: selectedContactTypes,
      sendFrom,
      subject,
      emailMessage,
      textMessage,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/Person/available-emails-texts`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setAvailableEmail(val?.data?.data?.item1);
        setAvailableText(val?.data?.data?.item2);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const sendEmail = () => {
    const body = {
      gPersonIds: personIds,
      gContactInfoTypeIds: selectedContactTypes,
      sendFrom,
      subject,
      emailMessage,
      textMessage,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/Person/send-emails-texts`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        console.log(val?.data?.data);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });

    setSendEmailPopup(false);
  };

  return (
    <div>
      <div className="sendEmailPopup">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  id="Vector_2"
                  d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19.6 8.25L12.53 12.67C12.21 12.87 11.79 12.87 11.47 12.67L4.4 8.25C4.15 8.09 4 7.82 4 7.53C4 6.86 4.73 6.46 5.3 6.81L12 11L18.7 6.81C19.27 6.46 20 6.86 20 7.53C20 7.82 19.85 8.09 19.6 8.25Z"
                  fill="#fff"
                />
              </svg>
              <p>Send Email / Text</p>
            </div>
            <p class="popup_header_title_para">UT.7</p>
          </div>
          <svg
            onClick={() => {
              setSendEmailPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>

        <div className="sendEmailBody">
          <div class="content">
            <div className="input-field">
              <label htmlFor="sendFrom">Send From</label>
              <input
                className="input"
                id="sendFrom"
                name="sendFrom"
                type="email"
                placeholder="Enter Email Address"
                value={sendFrom}
                onChange={(e) => {
                  setSendFrom(e.target.value);
                }}
              />
            </div>

            <div className="checkbox-field">
              <div className="checkbox-flex">
                <input
                  type="checkbox"
                  name=""
                  id="email"
                  checked={emailFilter}
                  onChange={(e) => {
                    setEmailFilter(e.target.checked);
                  }}
                />
                <label htmlFor="email">Email</label>
              </div>
              <div className="checkbox-flex">
                <input
                  type="checkbox"
                  name=""
                  id="text"
                  checked={textFilter}
                  onChange={(e) => {
                    setTextFilter(e.target.checked);
                  }}
                />
                <label htmlFor="text">Text</label>
              </div>
            </div>

            <div>Recipient Count: {personIds?.length}</div>

            <div className="input-field">
              <label htmlFor="sendFrom">Email Subject</label>
              <input
                className="input"
                id="emailSubject"
                name="emailSubject"
                type="text"
                placeholder="Add a Subject"
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
              />
            </div>

            <div className="input-field">
              <label htmlFor="emailTemplate">Email Template</label>
              <SelectBox
                id="emailTemplate"
                className="selectBox"
                dataSource={emailTemplates}
                valueExpr={"key"}
                displayExpr={"value"}
                onValueChange={(val) => {
                  setEmailMessage(emailTemplates.find((x) => x.key === val)?.sEmailBody);
                }}
              />
            </div>

            <div className="input-field">
              <label htmlFor="emailMessage">Email Message</label>
              <textarea
                name="emailMessage"
                id="emailMessage"
                placeholder="Write message"
                value={emailMessage}
                onChange={(e) => {
                  setEmailMessage(e.target.value);
                }}
              />
            </div>

            <div>Selected Count: {availableEmail}</div>

            <div className="popup_buttons" style={{ justifyContent: "flex-start" }}>
              <button
                className="btn primary_btn"
                style={{
                  background: "var(--button2)",
                  color: "#fff",
                }}
                onClick={() => {
                  sendEmail();
                }}
              >
                Send
              </button>
            </div>
          </div>

          <div className="content">
            <div className="input-field">
              <label htmlFor="contactType">Contact Type</label>
              <DataGrid
                dataSource={dataSource}
                showBorders={true}
                allowColumnResizing={true}
                rowAlternationEnabled={true}
                showColumnLines={true}
                showRowLines={true}
                allowColumnReordering={true}
                height={280}
              >
                <Column
                  cellRender={(rowData) => {
                    const id = rowData.data.gContactInfoTypeId;
                    return (
                      <input
                        type="checkbox"
                        checked={selectedContactTypes?.includes(id)}
                        onChange={(e) => {
                          if (!selectedContactTypes?.includes(id)) {
                            setSelectedContactTypes([...selectedContactTypes, id]);
                          } else {
                            setSelectedContactTypes(
                              selectedContactTypes?.filter((val) => {
                                if (val !== id) return val;
                              })
                            );
                          }
                        }}
                      />
                    );
                  }}
                  width={50}
                  headerCellRender={() => {
                    return (
                      <input
                        type="checkbox"
                        checked={selectedContactTypes.length === dataSource.length}
                        onChange={(e) => {
                          if (selectedContactTypes.length !== dataSource.length) {
                            setSelectedContactTypes(dataSource?.map((val) => val?.gContactInfoTypeId));
                          } else {
                            setSelectedContactTypes([]);
                          }
                        }}
                      />
                    );
                  }}
                />
                <Column dataField="sName" caption="Name" />
                <Column dataField="sType" caption="Type" />
              </DataGrid>
            </div>

            <div className="input-field">
              <label htmlFor="textTemplate">Text Template</label>
              <SelectBox
                id="textTemplate"
                className="selectBox"
                dataSource={emailTemplates}
                valueExpr={"key"}
                displayExpr={"value"}
                onValueChange={(val) => {
                  setTextMessage(emailTemplates.find((x) => x.key === val)?.sEmailBody);
                }}
              />
            </div>

            <div className="input-field">
              <label htmlFor="textMessage">Text Message</label>
              <textarea
                name="textMessage"
                id="textMessage"
                placeholder="Write message"
                value={textMessage}
                onChange={(e) => {
                  setTextMessage(e.target.value);
                }}
              />
            </div>

            <div>Selected Count: {availableText}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
