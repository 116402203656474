import React, { useEffect, useState } from "react";
import {ReactComponent as DownloadIcon} from "../../../../../assets/images/icons/download.svg";
import { PaymentPlanTablecoloumn, setPaymentTableData } from "./Data";
import "./financialtab.css";
import { Table1 } from "../../../../Administration/Table1/Table1";
import { handleGetLocal } from "../../../../../services/auth-service";
import axios from "axios";
import { LoadPanel } from "devextreme-react";
import { logOut } from "../../../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { ApiError } from "../../../../Error/ApiError";

export const PaymentPlan = ({ userinfo }) => {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const navigate = useNavigate();
  const [errorhandling, seterrorhandling] = useState(false);

  const getPaymentList = async () => {
    setLoad(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Financial/getPaymentPlan?gPersonId=${userinfo?.gPersonId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoad(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          // setData(setPaymentTableData(val?.data?.data));
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoad(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  useEffect(() => {
    getPaymentList();
  }, [userinfo?.gPersonId]);

  return (
    <>
      <LoadPanel shadingColor="true" visible={load} delay={10} />
      <div className="payment_plan_tab">
        <button
          className="financial_overview_tab_button"
          onClick={() => {
            setexportPopupOpen(true);
          }}
        >
          Export <DownloadIcon className="svg-blue-white"/>
        </button>
        {errorhandling ? (
          <ApiError />
        ) : (
          <div className="financial_tab_table">
            <Table1
              editTable={false}
              height={400}
              Tablecoloum={PaymentPlanTablecoloumn}
              tableRow={data?.map((val, i) => {
                return { ...val, ID: i };
              })}
              load={load}
              coloumWidthText={"paymentPlanTable"}
              mandatoryColoum={"Balance"}
              exportInDifferentFormate={true}
              exportPopupOpen={exportPopupOpen}
              setexportPopupOpen={setexportPopupOpen}
              exportwidth={27}
              exporttitle={"paymentPlan"}
            />
          </div>
        )}
      </div>
    </>
  );
};
