import { DateBox, ScrollView } from "devextreme-react";
import { useEffect, useState } from "react";

export const PrintChecks = ({ printCheckPopup, setPrintCheckPopup }) => {
  const [fields, setFields] = useState({
    stringChecks: "",
    endingChecks: 0,
    checkDate: "",
    totalAmount: 0,
    memo: "",
    printFromChecks: "",
    printToChecks: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    setFields({
      stringChecks: "",
      endingChecks: 0,
      checkDate: "",
      totalAmount: 0,
      memo: "",
      printFromChecks: "",
      printToChecks: "",
    });
  }, [printCheckPopup]);

  return (
    <>
      <div className="print-check-popup">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <p>Disbursement Check</p>
            </div>
            <p class="popup_header_title_para">DC.5</p>
          </div>
          <svg
            onClick={() => {
              setPrintCheckPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>

        <ScrollView style={{ height: "612px" }}>
          <div className="popup-container-data">
            <div className="card-container" style={{ margin: "0" }}>
              <p className="card-container-header">Batch Information</p>
              <div className="card-container-header-content">
                <div className="edit_container_first">
                  <div className="edit_content_flex">
                    <p className="edit_container_label">String Checks#</p>
                    <input
                      id="stringChecks"
                      name="stringChecks"
                      type="number"
                      placeholder="Enter String Checks#"
                      className="edit_container_input"
                      value={fields?.stringChecks}
                      onChange={handleInputChange}
                    />
                  </div>
                  <p className="edit_container_label import-margin-top">Ending Checks# {fields?.endingChecks}</p>
                </div>
                <div className="edit_container_first import-margin-top">
                  <div className="edit_content_flex">
                    <p className="edit_container_label">Check Date</p>
                    <DateBox
                      useMaskBehavior={true}
                      value={fields?.checkDate}
                      onValueChange={(e) => {
                        if (e) {
                          const initialDateString = e;
                          const initialDate = new Date(initialDateString);
                          const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                          const utcFormatted = new Date(utcTime).toISOString();
                          setFields((prev) => ({
                            ...prev,
                            checkDate: e ? utcFormatted : null,
                          }));
                        }
                      }}
                    />
                  </div>
                  <p className="edit_container_label import-margin-top">Total Amount {fields?.totalAmount}</p>
                </div>
                <div className="edit_container_first import-margin-top">
                  <div className="edit_content_flex">
                    <p className="edit_container_label">Memo</p>
                    <input
                      id="memo"
                      name="memo"
                      type="text"
                      placeholder="Enter Memo"
                      className="edit_container_input"
                      style={{ width: "681px" }}
                      value={fields?.memo}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="buttons import-margin-top">
                  <button className="btn">
                    Create Checks
                    <svg
                      className="svg-blue-white"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_5985_7014)">
                        <path
                          d="M19 8H5C3.34 8 2 9.34 2 11V15C2 16.1 2.9 17 4 17H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V17H20C21.1 17 22 16.1 22 15V11C22 9.34 20.66 8 19 8ZM15 19H9C8.45 19 8 18.55 8 18V14H16V18C16 18.55 15.55 19 15 19ZM19 12C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10C19.55 10 20 10.45 20 11C20 11.55 19.55 12 19 12ZM17 3H7C6.45 3 6 3.45 6 4V6C6 6.55 6.45 7 7 7H17C17.55 7 18 6.55 18 6V4C18 3.45 17.55 3 17 3Z"
                          fill="#424167"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5985_7014">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="card-container" style={{ margin: "0" }}>
              <p className="card-container-header">Print Checks</p>
              <div className="card-container-header-content">
                <div className="edit_container_first">
                  <div className="edit_content_flex">
                    <p className="edit_container_label">Print From Checks#</p>
                    <input
                      id="printFromChecks"
                      name="printFromChecks"
                      type="number"
                      placeholder="Enter Print from Checks#"
                      className="edit_container_input"
                      value={fields?.printFromChecks}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="edit_content_flex edit_content_space">
                    <p className="edit_container_label">To</p>
                    <input
                      id="printToChecks"
                      name="printToChecks"
                      type="number"
                      placeholder="Enter Print To Checks#"
                      className="edit_container_input"
                      value={fields?.printToChecks}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="buttons import-margin-top">
                  <button className="btn">
                    Print Checks
                    <svg
                      className="svg-blue-white"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_5985_7014)">
                        <path
                          d="M19 8H5C3.34 8 2 9.34 2 11V15C2 16.1 2.9 17 4 17H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V17H20C21.1 17 22 16.1 22 15V11C22 9.34 20.66 8 19 8ZM15 19H9C8.45 19 8 18.55 8 18V14H16V18C16 18.55 15.55 19 15 19ZM19 12C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10C19.55 10 20 10.45 20 11C20 11.55 19.55 12 19 12ZM17 3H7C6.45 3 6 3.45 6 4V6C6 6.55 6.45 7 7 7H17C17.55 7 18 6.55 18 6V4C18 3.45 17.55 3 17 3Z"
                          fill="#424167"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5985_7014">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="popup_buttons">
              <button
                className="btn"
                onClick={() => {
                  setPrintCheckPopup(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </ScrollView>
      </div>
    </>
  );
};
