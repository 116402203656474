import React, { useState } from "react";
import { ReactComponent as AddIcon } from "../../../../../assets/images/icons/add.svg";
import { PlannedJailservices } from "./PlannedJailservices";
import { CompletedJailService } from "./CompletedJailService";
import { Popup } from "devextreme-react";
import { AddJailServicePopup } from "./AddJailServicePopup";
import { AddJailServiceLogPopup } from "./AddJailServiceLogPopup";
import { VoidPopup } from "../VoidPopup";

export const JailTime = ({
  userinfo,
  dropdownData,
  docketDropdownData,
  jailPlannedData,
  jailCompletedData,
  getJailCopletedServiceListApi,
  getJailPlannedServiceListApi,
  toastConfig,
  setToastConfig,
  JailtimelogDropdown,
  getJailtimelogDropdown,
  jailPlannedErrorHandling,
  jailCompletedErorrHandling,
  gotodocker,
  dockertDetail,
  dockerHeaderData,
}) => {
  const [jailTimeTab, setJailTimeTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [addJailServicePopup, setAddJailServicePopup] = useState(false);
  const [addJailServiceLogPopup, setAddJailServiceLogPopup] = useState(false);
  const [voidPopup, setVoidPopup] = useState(false);
  const [jailPlainType, setJailPlainType] = useState("");
  const [jailCompletedType, setJailCompletedType] = useState("");
  const [jailRowData, setJailRowData] = useState([]);

  return (
    <>
      <Popup
        visible={addJailServicePopup}
        onHiding={() => {
          setAddJailServicePopup(false);
        }}
        showTitle={false}
        width={768}
        height={420}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddJailServicePopup
          addJailServicePopup={addJailServicePopup}
          setAddJailServicePopup={setAddJailServicePopup}
          userinfo={userinfo}
          dropdownData={dropdownData}
          docketDropdownData={docketDropdownData}
          jailPlainType={jailPlainType}
          jailRowData={jailRowData}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          setLoading={setLoading}
          getJailPlannedServiceListApi={getJailPlannedServiceListApi}
          setJailTimeTab={setJailTimeTab}
          getJailtimelogDropdown={getJailtimelogDropdown}
          gotodocker={gotodocker}
          dockertDetail={dockertDetail}
          dockerHeaderData={dockerHeaderData}
        />
      </Popup>
      <Popup
        visible={addJailServiceLogPopup}
        onHiding={() => {
          setAddJailServiceLogPopup(false);
        }}
        showTitle={false}
        width={768}
        height={480}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddJailServiceLogPopup
          addJailServiceLogPopup={addJailServiceLogPopup}
          setAddJailServiceLogPopup={setAddJailServiceLogPopup}
          userinfo={userinfo}
          dropdownData={dropdownData}
          docketDropdownData={docketDropdownData}
          jailRowData={jailRowData}
          jailCompletedType={jailCompletedType}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          setLoading={setLoading}
          setJailTimeTab={setJailTimeTab}
          getJailCopletedServiceListApi={getJailCopletedServiceListApi}
          JailtimelogDropdown={JailtimelogDropdown}
          gotodocker={gotodocker}
          dockertDetail={dockertDetail}
        />
      </Popup>
      <Popup
        visible={voidPopup}
        onHiding={() => {
          setVoidPopup(false);
        }}
        showTitle={false}
        width={800}
        height={450}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <VoidPopup setVoidPopup={setVoidPopup} voidHeading="Void Jail Time" voidSubHeading="Void Jail Time" />
      </Popup>
      <Popup
        visible={voidPopup}
        onHiding={() => {
          setVoidPopup(false);
        }}
        showTitle={false}
        width={800}
        height={450}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        {jailTimeTab === 1 ? (
          <VoidPopup
            setVoidPopup={setVoidPopup}
            voidHeading="Void Jail Time"
            voidSubHeading="Void Jail Time"
            setLoad={setLoading}
            toastConfig={toastConfig}
            setToastConfig={setToastConfig}
            refreshApi={getJailPlannedServiceListApi}
            filedId={jailRowData?.jailTimeId}
            fieldName="recordId"
            apiURl="jail-time/void-jailtime?isLog=false"
            comment="comment"
          />
        ) : (
          <VoidPopup
            setVoidPopup={setVoidPopup}
            voidHeading="Void Jail Time Log"
            voidSubHeading="Void Jail Time Log"
            setLoad={setLoading}
            toastConfig={toastConfig}
            setToastConfig={setToastConfig}
            refreshApi={getJailCopletedServiceListApi}
            filedId={jailRowData?.jailTimeLogId}
            fieldName="recordId"
            apiURl="jail-time/void-jailtime?isLog=true"
            comment="comment"
          />
        )}
      </Popup>
      <div className="jail_time">
        <div className="buttons">
          <button
            className="btn"
            onClick={() => {
              setAddJailServicePopup(true);
              setJailPlainType("ADD");
            }}
            style={{ width: "auto" }}
          >
            Add Jail Time <AddIcon className="svg-blue-white" />
          </button>
          <button
            className="btn"
            onClick={() => {
              setAddJailServiceLogPopup(true);
              setJailCompletedType("ADD");
            }}
            style={{ width: "auto" }}
          >
            Add Jail Time Log <AddIcon className="svg-blue-white" />
          </button>
        </div>
        <div className="community_service_tabs">
          <p
            className={
              jailTimeTab === 1 ? "community_service_tab community_service_tab_active" : "community_service_tab"
            }
            onClick={() => {
              setJailTimeTab(1);
            }}
          >
            Planned
          </p>
          <p
            className={
              jailTimeTab === 2 ? "community_service_tab community_service_tab_active" : "community_service_tab"
            }
            onClick={() => {
              setJailTimeTab(2);
            }}
          >
            Completed
          </p>
        </div>
        <div className="community_service_content">
          {jailTimeTab === 1 && (
            <PlannedJailservices
              setAddJailServicePopup={setAddJailServicePopup}
              setVoidPopup={setVoidPopup}
              jailPlannedData={jailPlannedData}
              loading={loading}
              setJailRowData={setJailRowData}
              setJailPlainType={setJailPlainType}
              jailPlannedErrorHandling={jailPlannedErrorHandling}
            />
          )}
          {jailTimeTab === 2 && (
            <CompletedJailService
              addJailServiceLogPopup={addJailServiceLogPopup}
              setAddJailServiceLogPopup={setAddJailServiceLogPopup}
              setVoidPopup={setVoidPopup}
              jailCompletedData={jailCompletedData}
              setJailRowData={setJailRowData}
              setJailCompletedType={setJailCompletedType}
              jailCompletedErorrHandling={jailCompletedErorrHandling}
            />
          )}
        </div>
      </div>
    </>
  );
};
