import { useEffect, useState, useRef } from "react";
import { ScrollView, Toast } from "devextreme-react";
import { useSelector } from "react-redux";
import httpService from "../../../services/http-service";
import {
  set_admin_table,
  set_selected_row,
} from "../../../reducer/AdminReadReducer";
import { getAuthToken } from "../../../services/auth-service";
import { useDispatch } from "react-redux";

export const Edit = ({ setIsPopupVisible, tableDataApi }) => {
  const [formErrors, setFormErrors] = useState({});
  const {
    headers_title,
    selected_row,
    table_name,
    table_id,
    replace_headers_title,
    foreignKeyData,
  } = useSelector((store) => store.AdminTable);
  const [selectedData, setSelectedData] = useState(selected_row);
  let transformedDatas = {};
  const fields = [];

  let domainName = (url) =>
    url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
  let domailURL = window.location.href;
  let pathURL = domainName(domailURL);
  const token = getAuthToken();

  const dispatch = useDispatch();
  const tableDataId = table_id;

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const componentRef = useRef(null);

  useEffect(() => {
    setSelectedData(selected_row);
  }, [selected_row]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setFormErrors({});
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    const updatedData = {
      ...selectedData,
      [name]: type === "checkbox" ? checked : value,
    };
    setSelectedData(updatedData);
    setFormErrors({
      ...formErrors,
      [name]: undefined,
    });

    dispatch(
      set_selected_row({
        selected_row: updatedData,
      })
    );
  };

  let arrayData = [selectedData];
  let setKeyValue = {};
  if (foreignKeyData?.length > 0) {
    arrayData.forEach((item) => {
      Object.keys(item).forEach((key) => {
        const value = item[key];
        if (key !== value) {
          if (item[value]) {
            setKeyValue[key] = item[value];
            delete setKeyValue[value];
          } else {
            setKeyValue[key] = value;
          }
        }
      });
    });
  }

  const dataChange = () => {
    const caption = replace_headers_title;
    const columns = headers_title;
    const newData = {};
    if (foreignKeyData?.length > 0) {
      Object.keys(setKeyValue).forEach((key) => {
        const captionIndex = caption.indexOf(key);
        if (captionIndex !== -1) {
          transformedDatas[columns[captionIndex]] = setKeyValue[key];
        } else {
          transformedDatas[key] = setKeyValue[key];
        }
      });
      for (const key in transformedDatas) {
        if (key.endsWith("ID")) {
          newData[key] = transformedDatas[key];
        }
      }
      for (const key of columns) {
        if (transformedDatas.hasOwnProperty(key)) {
          newData[key] = transformedDatas[key];
        }
      }
      transformedDatas = newData;
    } else {
      Object.keys(selectedData).forEach((key) => {
        const captionIndex = caption.indexOf(key);
        if (captionIndex !== -1) {
          transformedDatas[columns[captionIndex]] = selectedData[key];
        } else {
          transformedDatas[key] = selectedData[key];
        }
      });
    }
    delete transformedDatas.ID;
    for (const key in transformedDatas) {
      fields.push({
        FieldName: key,
        FieldValue: transformedDatas[key],
      });
    }
  };

  const submitData = async () => {
    const errors = {};
    let hasErrors = false;

    replace_headers_title.map((row) => {
      return arrayData.map((col) => {
        if (typeof col[row] === "string" && !col[row]) {
          errors[row] = `Please Enter ${row}`;
          hasErrors = true;
        } else if (typeof col[row] === "string" && col[row].startsWith(" ")) {
          errors[row] = `Please Enter valid ${row} `;
          hasErrors = true;
        } else if (typeof row[col] === "boolean") {
          hasErrors = false;
        }
      });
    });

    if (hasErrors) {
      setFormErrors(errors);
      return;
    }
    dataChange();
    await httpService
      .post(
        `api/Administration/BDECRUD`,
        {
          TableName: table_name,
          EntityState: 2,
          Fields: fields,
        },
        {
          headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` },
        }
      )
      .then((response) => {
        if (!response.data.isSuccess === false) {
          setIsPopupVisible(false);
          tableDataApi(tableDataId);
          dispatch(
            set_selected_row({
              selected_row: {},
            })
          );
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Updated!",
            type: "success",
          });
        } else {
          // setToastConfig({
          //   ...toastConfig,
          //   isVisible: true,
          //   message: response.data.errors,
          //   type: "Error",
          // });
          console.error(response.data.errors);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Toast
        visible={toastConfig.isVisible}
        position={"top"}
        width={"auto"}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
          });
        }}
        displayTime={600}
      />
      <div className="admin_Add_Table">
        <div ref={componentRef}>
          <div className="admin_Add_Table_head">
            <div className="admin_Add_Table_head_title">
              <div className="admin_Add_Table_head_title_data">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginTop: "13px", marginRight: "2%" }}
                >
                  <path
                    d="M0 15.4586V18.4986C0 18.7786 0.22 18.9986 0.5 18.9986H3.54C3.67 18.9986 3.8 18.9486 3.89 18.8486L14.81 7.93859L11.06 4.18859L0.15 15.0986C0.0500001 15.1986 0 15.3186 0 15.4586ZM17.71 5.03859C18.1 4.64859 18.1 4.01859 17.71 3.62859L15.37 1.28859C14.98 0.898594 14.35 0.898594 13.96 1.28859L12.13 3.11859L15.88 6.86859L17.71 5.03859Z"
                    fill="white"
                  />
                </svg>
                <p>Edit</p>
              </div>
              <p className="admin_Add_Table_head_para">UT.10</p>
            </div>
            <svg
              onClick={() => {
                setIsPopupVisible(false);
                setFormErrors({});
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          <ScrollView style={{ height: "295px" }}>
            <div className="admin_Add_Table_Inputs">
              {replace_headers_title?.map((row) => {
                return (
                  row !== "ID" && (
                    <div>
                      <div className="admin_Add_Table_Inputs_field">
                        {typeof selectedData[row] === "boolean" ? (
                          <>
                            <label>{row}</label>
                            <input
                              className="input_checkbox"
                              type="checkbox"
                              name={row}
                              id={row}
                              checked={selectedData[row] ? true : false}
                              onChange={handleChange}
                            />
                          </>
                        ) : (
                          <>
                            {!foreignKeyData?.length ? (
                              <>
                                <label>{row}</label>
                                <input
                                  className="inputs"
                                  placeholder={row}
                                  name={row}
                                  id={row}
                                  type={typeof selectedData[row]}
                                  value={selectedData[row] || ""}
                                  onChange={handleChange}
                                />
                                {formErrors[row] && (
                                  <p className="error_Message">
                                    {formErrors[row]}
                                  </p>
                                )}
                              </>
                            ) : (
                              <>
                                {!foreignKeyData
                                  .map((val) => {
                                    return val.captionname;
                                  })
                                  .includes(row) && (
                                  <>
                                    <label>{row}</label>
                                    <input
                                      className="inputs"
                                      placeholder={row}
                                      name={row}
                                      id={row}
                                      type={typeof selectedData[row]}
                                      value={selectedData[row] || ""}
                                      onChange={handleChange}
                                    />
                                    {formErrors[row] && (
                                      <p className="error_Message">
                                        {formErrors[row]}
                                      </p>
                                    )}
                                  </>
                                )}
                                {foreignKeyData.map((val) => {
                                  return (
                                    <>
                                      {val.captionname === row && (
                                        <>
                                          <label>{row}</label>
                                          <select
                                            name={row}
                                            onChange={handleChange}
                                            className="inputs"
                                          >
                                            <option value="">Select</option>
                                            {val.data.map((item, idx) => {
                                              const dynamicKey =
                                                Object.keys(item)[0];
                                              const dynamicValue =
                                                item[dynamicKey];
                                              const dynamicLabel =
                                                Object.values(item)[1];
                                              return (
                                                <option
                                                  key={idx}
                                                  value={dynamicValue}
                                                  selected={
                                                    dynamicLabel ===
                                                    selectedData[row]
                                                  }
                                                >
                                                  {dynamicLabel}
                                                </option>
                                              );
                                            })}
                                          </select>
                                          {formErrors[row] && (
                                            <p className="error_Message">
                                              {formErrors[row]}
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )
                );
              })}
            </div>
            <div
              className="popup_buttons"
              style={{ marginTop: "20px", marginRight: "20px" }}
            >
              <button
                className="btn"
                onClick={() => {
                  dispatch(
                    set_selected_row({
                      selected_row: {},
                    })
                  );
                  setIsPopupVisible(false);
                  setFormErrors({});
                }}
              >
                Cancel
              </button>
              <button className="primary_btn" onClick={submitData}>
                Save
              </button>
            </div>
          </ScrollView>
        </div>
      </div>
    </>
  );
};
