import React, { useCallback, useEffect, useState } from "react";
import { DataGrid, DateBox, DropDownBox, ScrollView } from "devextreme-react";
import { Column, FilterRow, Selection } from "devextreme-react/data-grid";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import { handleGetLocal } from "../../../../../services/auth-service";
import axios from "axios";
import { logOut } from "../../../../../components/Logout";
import { useNavigate } from "react-router-dom";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
      />
    </>
  );
};

export const AddCommunityServiceLogPopup = ({
  addCommunityServiceLogPopup,
  setAddCommunityServiceLogPopup,
  toastConfig,
  setToastConfig,
  dropdownData,
  docketDropdownData,
  getCommunityCompletedServiceListApi,
  setLoad,
  completedRowData,
  userinfo,
  addCommunityServiceLogType,
  setCommunityTab,
  communityServiceJailtimelogDropdown,
  gotoAddCommunity,
  dockertDetail,
  gotoCommunity,
  communityPlannedServicesData,
}) => {
  const [selectedValue, setSelectedValue] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [fields, setFields] = useState({
    reason: { name: "", id: "" },
    workSite: { name: "", id: "" },
    hoursCompleted: "",
    note: "",
    hoursWrittenOff: "",
    date: "",
  });
  const [reasonvalidation, setreasonvalidation] = useState("");
  const [dateValidation, setDateValidation] = useState("");
  const [workSitevalidation, setWorkSitevalidation] = useState("");
  const [hoursWrittenOffValidation, setHoursWrittenOffValidation] = useState("");
  const [docketvalidation, setDocketvalidation] = useState("");
  const [serviceType, setServiceType] = useState("hoursServed");
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);

  const handleServiceTypeChange = (event) => {
    setServiceType(event.target.value);
    if (event.target.value === "hoursServed") {
      setFields((prevFields) => ({
        ...prevFields,
        hoursWrittenOff: "",
      }));
    }
  };

  const findEndReason = () => {
    const matchingStatus = dropdownData?.communityReason?.find(
      (status) => status.value === completedRowData?.[`Reason`]
    );
    const endReasonId = matchingStatus ? matchingStatus.key : "";
    setFields((prevFields) => ({
      ...prevFields,
      reason: {
        ...prevFields.reason,
        name: completedRowData?.[`Reason`],
        id: endReasonId,
      },
    }));
  };

  const findworkSiteCode = () => {
    const matchingStatus = communityServiceJailtimelogDropdown?.find(
      (status) => status.value === completedRowData?.[`Work Site`]
    );
    const programCodeId = matchingStatus ? matchingStatus.key : "";
    setFields((prevFields) => ({
      ...prevFields,
      workSite: {
        ...prevFields.workSite,
        name: completedRowData?.[`Work Site`],
        id: programCodeId,
      },
    }));
  };

  useEffect(() => {
    setDateValidation("");
    setWorkSitevalidation("");
    setreasonvalidation("");
    setHoursWrittenOffValidation("");
    setDocketvalidation("");
    if (addCommunityServiceLogType === "ADD") {
      setFields((prev) => ({
        ...prev,
        hoursCompleted: "1",
        reason: { name: "", id: "" },
        workSite: { name: "", id: "" },
        note: "",
        hoursWrittenOff: "",
        date: "",
      }));
      setServiceType("hoursServed");
      const selectedDocket = dataSource?.find((item) => item.gDocketId === dockertDetail?.id);
      setSelectedValue(selectedDocket);
    } else {
      let checkedType = completedRowData?.[`Write Off Reason`]?.length === 0 ? "hoursServed" : "hoursWrittenOff";
      setServiceType(checkedType);
      setFields((prev) => ({
        ...prev,
        hoursCompleted: completedRowData?.[`Hours Completed`] || "",
        reason: { name: completedRowData?.[`Reason`], id: "" },
        workSite: { name: completedRowData?.[`Work Site`], id: "" },
        note: completedRowData?.Note || "",
        hoursWrittenOff: completedRowData?.[`Write Off Reason`] || "",
        date: completedRowData?.[`Date Completed`],
      }));
      findEndReason();
      findworkSiteCode();
      const selectedDocket = dataSource?.find((item) => item.gDocketId === completedRowData?.gDocketId);
      setSelectedValue(selectedDocket);
      const fetchedDataSource = docketDropdownData?.map((val) => {
        const convertDateFormat = (dateString) => {
          const dateObj = new Date(dateString);
          return dateObj.toLocaleDateString("en-US");
        };
        return {
          Court: val?.sCourtName,
          Docket: val?.sDocketNumberCourtCounts,
          "Sentence Date": convertDateFormat(val?.dSentence),
          "Supervision Status": val?.sSupervisionStatusName,
          gDocketId: val?.gDocketId,
        };
      });
      setDataSource(fetchedDataSource);
    }
  }, [addCommunityServiceLogPopup, completedRowData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "hoursWrittenOff") {
      if (fields?.hoursWrittenOff?.trim() === "" || value[0] === " ") {
        setHoursWrittenOffValidation("Please enter valid Hours Written Off");
      } else {
        setHoursWrittenOffValidation("");
      }
    }
  };

  const data = {
    GCommunityServiceLogId:
      addCommunityServiceLogType === "ADD"
        ? "00000000-0000-0000-0000-000000000000"
        : completedRowData?.gCommunityServiceLogId,
    GEntryUserId: userinfo?.gEntryUserId,
    GDocketId: selectedValue?.gDocketId,
    FHours: fields?.hoursCompleted,
    DComplete: fields?.date,
    GEntityId: fields?.workSite?.id,
    GCommunityServiceReasonId: fields?.reason?.id,
    SWriteOffReason: fields?.hoursWrittenOff,
    sNote: fields?.note,
  };

  const navigate = useNavigate();

  const handleSave = async () => {
    let isValid = true;
    if (!selectedValue?.gDocketId) {
      setDocketvalidation("Please Select Docket");
      isValid = false;
    }
    if (!fields.date) {
      setDateValidation("Please Select Date.");
      isValid = false;
    }
    if (fields.workSite.id === "") {
      setWorkSitevalidation("Please Select Work Site.");
      isValid = false;
    }
    if (fields.reason.id === "") {
      setreasonvalidation("Please Select Reason.");
      isValid = false;
    }
    if (serviceType === "hoursWrittenOff" && !fields.hoursWrittenOff) {
      setHoursWrittenOffValidation("Please Enter Hours Written Off");
      isValid = false;
    }
    if (isValid) {
      setLoad(true);
      const pathname = handleGetLocal("pathURL");
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/CommunityService/AddEditCommunityServiceLog`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        })
        .then((val) => {
          setLoad(false);
          setAddCommunityServiceLogPopup(false);
          getCommunityCompletedServiceListApi();
          setCommunityTab(2);
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: addCommunityServiceLogType === "ADD" ? "Successfully Added!" : "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: addCommunityServiceLogType === "ADD" ? "Successfully Added!" : "Successfully Updated!",
              type: "success",
            });
          }, 1900);
        })
        .catch((val) => {
          setLoad(false);
          if (val?.response?.status === 401) {
            logOut();
          } else {
            if (val?.response?.status) {
              navigate(`/error/500`);
            } else {
              navigate("/error/500");
            }
          }
        });
    }
  };

  const onGridBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }, []);

  return (
    <>
      <div className="add_community_service_log_popup">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="add" clipPath="url(#clip0_5349_5505)">
                  <path
                    id="Vector"
                    d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                    fill="#fff"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5349_5505">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>Community Service Log</p>
            </div>
            <p class="popup_header_title_para">CM.2</p>
          </div>
          <svg
            onClick={() => {
              setAddCommunityServiceLogPopup(false);
              setDateValidation("");
              setWorkSitevalidation("");
              setreasonvalidation("");
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <ScrollView style={{ height: "560px" }}>
          <div className="add_community_service_content">
            <div className="edit_container_first">
              <div className="edit_content_flex">
                <p className="edit_container_label">
                  Docket <span className="marks_mandatary">*</span>
                </p>
                <DropDownBox
                  placeholder="Select..."
                  disabled={gotoCommunity ? true : false}
                  dataSource={dataSource}
                  opened={isGridBoxOpened}
                  onOptionChanged={onGridBoxOpened}
                  value={selectedValue?.Docket}
                  valueExpr={"Docket"}
                  displayExpr={"Docket"}
                  contentRender={(value, element) => (
                    <DataGrid
                      dataSource={dataSource}
                      hoverStateEnabled={true}
                      height={180}
                      onRowClick={(e) => {
                        setSelectedValue(e?.data);
                        setDocketvalidation("");
                        setIsGridBoxOpened(false);
                      }}
                    >
                      <Selection mode="single" />
                      <FilterRow visible={true} />
                      <Column dataField="Court" caption="Court" width={"auto"} />
                      <Column dataField="Docket" caption="Docket" width={"auto"} />
                      <Column dataField="Sentence Date" caption="Sentence Date" width={"auto"} />
                      <Column dataField="Supervision Status" caption="Supervision Status" width={"auto"} />
                    </DataGrid>
                  )}
                />
                {docketvalidation && (
                  <p className="error_validation" style={{ position: "absolute", top: "100px" }}>
                    {docketvalidation}
                  </p>
                )}
              </div>
              <div className="edit_content_flex">
                <p className="edit_container_label">Hours Completed</p>
                <input
                  id="hoursCompleted"
                  name="hoursCompleted"
                  type="number"
                  value={fields.hoursCompleted}
                  placeholder="Enter Hours Completed"
                  className="edit_container_input"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="edit_container_first">
              <div className="edit_content_flex">
                <p className="edit_container_label">
                  Date <span className="marks_mandatary">*</span>
                </p>
                <DateBox
                  useMaskBehavior={true}
                  value={fields.date}
                  onValueChange={(e) => {
                    const initialDateString = e;
                    const initialDate = new Date(initialDateString);
                    const utcTime = initialDate.getTime() - initialDate.getTimezoneOffset() * 60000;
                    const utcFormatted = new Date(utcTime).toISOString();
                    setFields((prev) => ({
                      ...prev,
                      date: e ? utcFormatted : null,
                    }));
                    setDateValidation("");
                  }}
                />
                {dateValidation && (
                  <p className="error_validation" style={{ position: "absolute", top: "191px" }}>
                    {dateValidation}
                  </p>
                )}
              </div>
              <div className="edit_content_flex edit_content_space">
                <p className="edit_container_label">
                  Work Site <span className="marks_mandatary">*</span>
                </p>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"workSite"}
                  dropDownData={
                    communityPlannedServicesData?.length > 0
                      ? communityServiceJailtimelogDropdown?.map((vall) => {
                          return {
                            name: vall?.value,
                            id: vall?.key,
                          };
                        })
                      : dropdownData?.workSite?.map((vall) => {
                          return {
                            name: vall?.value,
                            id: vall?.key,
                          };
                        })
                  }
                  reset={setWorkSitevalidation}
                />
                {workSitevalidation && (
                  <p className="error_validation" style={{ position: "absolute", top: "192px" }}>
                    {workSitevalidation}
                  </p>
                )}
              </div>
            </div>
            <div className="edit_container_first">
              <div className="edit_content_flex edit_content_space">
                <p className="edit_container_label">
                  Reason <span className="marks_mandatary">*</span>
                </p>
                <DropDown
                  setfields={setFields}
                  fields={fields}
                  fieldNmae={"reason"}
                  dropDownData={dropdownData?.communityReason?.map((vall) => {
                    return {
                      name: vall?.value,
                      id: vall?.key,
                    };
                  })}
                  reset={setreasonvalidation}
                />
                {reasonvalidation && (
                  <p className="error_validation" style={{ position: "absolute", top: "282px" }}>
                    {reasonvalidation}
                  </p>
                )}
              </div>
            </div>
            <div className="edit_container_second edit_content_flex">
              <p className="edit_container_label">Log Note</p>
              <textarea
                name="note"
                id="note"
                placeholder="Write Log Note"
                value={fields.note}
                onChange={handleInputChange}
                className="dark-mode-textarea"
              ></textarea>
            </div>
            <div className="edit_container_first space_removed">
              <p className="edit_container_label">Service Type</p>
              <input
                id="hoursServed"
                name="serviceType"
                type="radio"
                value="hoursServed"
                checked={serviceType === "hoursServed"}
                onChange={handleServiceTypeChange}
              />
              <label htmlFor="hoursServed" className="edit_container_label">
                Hours Served
              </label>
              <input
                id="hoursWrittenOff"
                name="serviceType"
                type="radio"
                value="hoursWrittenOff"
                checked={serviceType === "hoursWrittenOff"}
                onChange={handleServiceTypeChange}
              />
              <label htmlFor="hoursWrittenOff" className="edit_container_label">
                Hours Written Off
              </label>
            </div>
            {serviceType === "hoursWrittenOff" && (
              <div className="edit_container_second edit_content_flex">
                <textarea
                  name="hoursWrittenOff"
                  id="hoursWrittenOff"
                  placeholder="Write Hours Written Off"
                  value={fields.hoursWrittenOff}
                  onChange={handleInputChange}
                  className="dark-mode-textarea"
                ></textarea>
                {hoursWrittenOffValidation && (
                  <p className="error_validation" style={{ position: "absolute", top: "649px" }}>
                    {hoursWrittenOffValidation}
                  </p>
                )}
              </div>
            )}
            <div className="popup_buttons">
              <button className="btn primary_btn" onClick={handleSave}>
                Save
              </button>
              <button
                className="btn"
                onClick={() => {
                  setAddCommunityServiceLogPopup(false);
                  setDateValidation("");
                  setWorkSitevalidation("");
                  setreasonvalidation("");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </ScrollView>
      </div>
    </>
  );
};
