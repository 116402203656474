import { Help } from "../../components/Help/Help";

const HelpPopup = ({ helpPopUp, setHelpPopUp, markup, markupid, markupsCaption, markupsScreenName }) => {
  return (
    <>
      <div style={{ height: "668px", border: "3px solid var(--button2)" }} className="help-popup">
        <div className="popup_header">
          <div className="popup_header_title">
            <div className="popup_header_title_data">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_7120_83471)">
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 17C11.45 17 11 16.55 11 16V12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12V16C13 16.55 12.55 17 12 17ZM13 9H11V7H13V9Z"
                    fill="#fff"
                    fillOpacity="0.6"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7120_83471">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>{markupsCaption}</p>
            </div>
            <p className="popup_header_title_para">UT.9</p>
          </div>
          <svg
            onClick={() => {
              setHelpPopUp(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="">
          <Help
            markup={markup}
            markupid={markupid}
            helpPopUp={helpPopUp}
            markupsCaption={markupsCaption}
            markupsScreenName={markupsScreenName}
            setHelpPopUp={setHelpPopUp}
          />
        </div>
      </div>
    </>
  );
};

export default HelpPopup;
