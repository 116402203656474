import { DrugTest } from "../../../Dockect/DrugTest/DrugTest";

export const GoToDrugTest = ({
  userinfo,
  toastConfig,
  setToastConfig,
  dropdownData,
  docketDropdownData,
  drugTestData,
  drugTestErrorHandling,
  getrugTestDataApi,
  dockertDetail,
}) => {
  return (
    <>
      <div className="goto-drugtest-tab">
        <DrugTest
          userinfo={userinfo}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          dropdownData={dropdownData}
          docketDropdownData={docketDropdownData}
          drugTestData={drugTestData}
          drugTestErrorHandling={drugTestErrorHandling}
          getrugTestDataApi={getrugTestDataApi}
          dockertDetail={dockertDetail}
          gotoDrugTest={true}
        />
      </div>
    </>
  );
};
