export const setTableData = (data) => {
    const arr = [];
    data.map((val) => {
      arr.push({
        ID: val,
        "Client Id": val?.sOffenderNumber,
        "Client Name": val?.sLastFirst,
        Status: val?.sPersonSupervisionStatusName,
        Agency: val?.sAgencyName,
        Billing: val?.bIsBilling,
        Location: val?.sLocation,
        Region: val?.sRegionName,
        Location: val?.sLocation,
        "0-30": val?.fDue1,
        "31-60": val?.fDue2,
        "61-90": val?.fDue3,
        "91-120": val?.fDue4,
        "121-150": val?.fDue5,
        "151-180": val?.fDue6,
        ">181": val?.fDue7,
        "Total Due": val?.fTotalDue,
        "Last Payment Date": val?.dLastPayment,
        "Last Payment Days": val?.iDaysSinceLastPayment,
      });
    });
    return arr;
  };
  