import { useEffect, useState } from "react";
import { Table2 } from "../../../../components/Table2/Table2";
import { ChangeSupervisionDate } from "../GoToDocket/GoTODocketHeader";
import { GoToDocketHeaderFunction } from "../GoToDocket/GoToDocketHeaderFunction";
import { useNavigate } from "react-router-dom";
import { LoadPanel, Popup } from "devextreme-react";
import { Toster } from "../../../../components/Toster/Toster";
import ChangeFinancialStatus from "./ChangeFinancialStatus";
import { AddTollingPopup } from "./AddTollingPopup";
import ChangeSupervisor from "./ChangeSupervisor";

const Tablecoloum = [
  // {
  //   moreoption: true,
  // },
  // {
  //   addObligation: true,
  // },
  { title: "", moreoption: true, customwidth: "50px" },
  {
    title: "Select",
    DocketManagementSelect: true,
    bool: true,
  },
  { title: "Alerts" },
  {
    title: "Reference",
  },
  { title: "Docket/Count" },
  {
    title: "Count",
  },
  {
    title: "Offense",
  },
  {
    title: "Supervisor",
  },
  {
    title: "Supervisor Status",
  },
  {
    title: "Court Sentence",
    date: true,
  },
  {
    title: "Sentence Date Range",
    date: true,
  },
  {
    title: "Days Tolled",
  },
  {
    title: "Balance",
    currency: true,
  },
  {
    title: "Docket Link",
  },
  {
    title: "Total Days",
  },
];

export const DocketManagement = ({ docketmanagement, userinfo, getDockrtManagement }) => {
  const [data, setdata] = useState([]);
  const [dockets, setDockets] = useState([]);
  const [selectedRowCheckbox, setSelectedRowCheckbox] = useState([]);

  const [ChangesuperviserDatePOpup, setChangesuperviserDatePOpup] = useState(false);
  const [changeFinancialStatusPopup, setChangeFinancialStatusPopup] = useState(false);
  const [changeSupervisorPopup, setChangeSupervisorPopup] = useState(false);
  const [addTollingPopup, setAddTollingPopup] = useState(false);

  const [SupervisionStatusdropdown, setSupervisionStatusdropdown] = useState([]);
  const [changeReasonDRopDown, setchangeReasonDRopDown] = useState([]);

  const [loading, setLoading] = useState(false);
  const [LocationdropDown, setLocationdropDown] = useState([]);
  const [TyperopDown, setTyperopDown] = useState([]);
  const [CourtropDown, setCourtropDown] = useState([]);
  const [JudgedropDown, setJudgedropDown] = useState([]);
  const [OffensedropDown, setOffensedropDown] = useState([]);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const navigate = useNavigate();
  const clas = new GoToDocketHeaderFunction(navigate);

  useEffect(() => {
    clas.getDropDown(
      setLoading,
      setLocationdropDown,
      setTyperopDown,
      setCourtropDown,
      setJudgedropDown,
      setOffensedropDown,
      setSupervisionStatusdropdown,
      setchangeReasonDRopDown
    );
  }, []);

  useEffect(() => {
    setDockets(data.filter((item) => selectedRowCheckbox.includes(item.ID)).map((item) => item.userinfo));
  }, [selectedRowCheckbox]);

  useEffect(() => {
    setdata(
      docketmanagement?.map((val, i) => {
        return {
          Alerts: val?.sDocketAlert,
          Reference: val?.sReferenceNumber,
          "Docket/Count": val?.sDocketNumberCourtCounts,
          Count: val?.sCourtName,
          Offense: val?.sOffenseDescription,
          Supervisor: val?.sSupervisorNameLastFirst,
          "Supervisor Status": val?.sSupervisionStatusName,
          "Court Sentence": val?.dCourtSentence,
          "Sentence Date Range": val?.sSentenceDateRange,
          "Days Tolled": val?.iSentence_AdditionalDays,
          Balance: val?.fBalance,
          "Docket Link": val?.sDocketLinkTypeName,
          "Total Days": val?.iSentence_TotalDays,
          sDocketNumberCourtCounts: val?.sDocketNumberCourtCounts,
          userinfo: val,
          ID: i,
        };
      })
    );
  }, [docketmanagement]);
  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <ChangeSupervisionDate
        ChangesuperviserDatePOpup={ChangesuperviserDatePOpup}
        setChangesuperviserDatePOpup={setChangesuperviserDatePOpup}
        data={dockets}
        SupervisionStatusdropdown={SupervisionStatusdropdown}
        changeReasonDRopDown={changeReasonDRopDown}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        getDcket={getDockrtManagement}
        refreshUserInfo={true}
      />
      <Popup
        visible={changeFinancialStatusPopup}
        onHiding={() => {
          setChangeFinancialStatusPopup(false);
        }}
        showTitle={false}
        width={750}
        height={590}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <ChangeFinancialStatus
          showModal={changeFinancialStatusPopup}
          setShowModal={setChangeFinancialStatusPopup}
          dockets={dockets}
          setToastConfig={setToastConfig}
          setLoading={setLoading}
        />
      </Popup>
      <Popup
        visible={changeSupervisorPopup}
        onHiding={() => {
          setChangeSupervisorPopup(false);
        }}
        showTitle={false}
        width={500}
        height={220}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <ChangeSupervisor
          dockets={dockets}
          showModal={changeSupervisorPopup}
          setShowModal={setChangeSupervisorPopup}
          setLoading={setLoading}
          setToastConfig={setToastConfig}
          reloadApi={getDockrtManagement}
        />
      </Popup>
      <Popup
        visible={addTollingPopup}
        onHiding={() => {
          // setReset(!reset);
          setAddTollingPopup(false);
        }}
        showTitle={false}
        width={608}
        height={440}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddTollingPopup
          dockets={dockets}
          toastConfig={toastConfig}
          setToastConfig={setToastConfig}
          setAddTollingPopup={setAddTollingPopup}
        />
      </Popup>
      <div style={{ padding: "5px 15px 40px 15px" }}>
        <Table2
          Tablecoloum={Tablecoloum}
          tableRow={data}
          editTable={false}
          load={false}
          coloumWidthText={"DocketManagementTable"}
          height={520}
          mandatoryColoum={"DOE"}
          showTotalBlock={true}
          DocketManagementTotalshow={true}
          DocketManagementRightClick={true}
          selectedRowCheckbox={selectedRowCheckbox}
          setSelectedRowCheckbox={setSelectedRowCheckbox}
          setChangesuperviserDatePOpup={setChangesuperviserDatePOpup}
          setChangeFinancialStatusPopup={setChangeFinancialStatusPopup}
          setAddTollingPopup={setAddTollingPopup}
          setChangeSupervisorPopup={setChangeSupervisorPopup}
        />
      </div>
    </>
  );
};
