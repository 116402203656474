import { useEffect, useState } from "react";

const SaveAddRecipients = ({
  saveAddRecipientsPopup,
  setSaveAddRecipientsPopup,
  saveApi,
}) => {
  const [comments, setComments] = useState("");
  const [commentValidation, setCommentValidation] = useState("");

  useEffect(() => {
    setComments("");
    setCommentValidation("");
  }, [saveAddRecipientsPopup]);

  const handleSave = async () => {
    let isValid = true;
    if (!comments || comments.charAt(0) === " ") {
      setCommentValidation("Please enter comment");
      isValid = false;
    }
    if (isValid) {
      saveApi();
    }
  };

  return (
    <>
      <div className="program_voidpopup">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <p>Enter Note</p>
            </div>
            <p class="popup_header_title_para">UT.2</p>
          </div>
          <svg
            onClick={() => {
              setSaveAddRecipientsPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="program_void_content">
          <div className="program_void_content_info">
            <p className="sub_para">Note</p>
            <p className="highlight_para">
              Enter Payment Alert Note note for Hold in Trust Obligation.
            </p>
          </div>
          <p className="info_para">
            This should only be used to correct an error.
          </p>
          <div className="program_void_content_textarea">
            <label htmlFor="comments">
              Please enter a detailed comment
              <span className="marks_mandatary">*</span>
            </label>
            <textarea
              id="comments"
              value={comments}
              placeholder="Write comment here"
              onChange={(e) => {
                const value = e.target.value;
                setComments(value);
                if (value.charAt(0) === " ") {
                  setCommentValidation("Please enter comment");
                } else {
                  setCommentValidation("");
                }
              }}
              className="dark-mode-textarea"
            ></textarea>
            <p className="error_validation">{commentValidation}</p>
          </div>
          <div className="popup_buttons">
            <button onClick={handleSave} className="btn primary_btn">
              Save
            </button>
            <button
              className="btn"
              onClick={() => {
                setSaveAddRecipientsPopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SaveAddRecipients;
