export const Conformation = ({
  ConformationPopupshow,
  setConformationPopupshow,
  onSave,
}) => {
  return (
    <>
      <div style={{ height: "248px" }} className="em-period-popup">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <p>Confirmation</p>
            </div>
            <p class="popup_header_title_para">UT.12</p>
          </div>
          <svg
            onClick={() => {
              setConformationPopupshow(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div style={{ padding: "10px 20px 10px 20px" }}>
          <p style={{ fontSize: "20px", fontWeight: "400" }}>NOTICE</p>
          <p style={{ marginTop: "30px" }}>
            You have not enterd any Obligation and the client will not be billed
            for this monitoring periods. Do You want to continue anyway?
          </p>
          <div style={{ marginTop: "40px" }} className="popup_buttons">
            <button
              onClick={() => {
                onSave();
              }}
              className="btn primary_btn"
            >
              Save
            </button>
            <button
              className="btn"
              onClick={() => {
                setConformationPopupshow(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
